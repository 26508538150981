import { createContext, useContext, useEffect, useState } from 'react';
import {
  GoogleAuthProvider,
  User as FirebaseUser,
  inMemoryPersistence,
  onAuthStateChanged,
  sendPasswordResetEmail,
  setPersistence,
  signInWithEmailAndPassword,
  signInWithRedirect,
  signOut as firebaseSignOut,
  verifyPasswordResetCode,
  confirmPasswordReset,
} from 'firebase/auth';
import { setUserId, setUserProperties } from 'firebase/analytics';
import { Navigate, useLocation } from 'react-router-dom';
import { LoadingOverlay } from '../components/ui/LoadingOverlay/LoadingOverlay';
import { mtechnavi, RpcError } from '../libs/clientsdk';
import ErrorBoundary from '../components/error/ErrorBoundary';
import {
  getHelpJson,
  getNoticeList,
  getProgramOptions,
  selectMessages,
  workerReloads,
} from '~/shared/services';
import { InitialLoadMethods } from '~/shared/config';
import { IntlProvider } from 'react-intl';
import { InformationDialog } from '../components/ui/Dialog/InformationDialog';
import { FirebaseError } from 'firebase/app';

import {
  saveInformationDialogOpened,
  getInformationDialogOpened,
  deleteInformationDialogOpened,
} from '~/shared/utils';

interface AuthContextType {
  authorized: boolean;
  tenant?: mtechnavi.api.idp.ITenant;
  user?: mtechnavi.api.idp.IUser;
  roles?: mtechnavi.api.idp.IRole[];
  signInWithEmailAndPassword: (
    email: string,
    password: string
  ) => Promise<void>;
  signInWithGoogle: () => Promise<never>;
  signOut: () => Promise<void>;
  resetPassword: (email: string) => Promise<void>;
  newPassword: (oobCode: string, password: string) => Promise<void>;
}

const AuthContext = createContext<AuthContextType>({
  authorized: false,
  signInWithEmailAndPassword: async () => {
    throw new Error('logic error');
  },
  signInWithGoogle: async () => {
    throw new Error('logic error');
  },
  signOut: async () => {
    throw new Error('logic error');
  },
  resetPassword: async () => {
    throw new Error('logic error');
  },
  newPassword: async () => {
    throw new Error('logic error');
  },
});

const defaultContextValue: AuthContextType = {
  authorized: false,
  signInWithEmailAndPassword: async (email: string, password: string) => {
    const fbApp = window.App.firebaseApps.auth;
    await setPersistence(fbApp, inMemoryPersistence);
    await signInWithEmailAndPassword(fbApp, email, password);
  },
  signInWithGoogle: async (): Promise<never> => {
    const provider = new GoogleAuthProvider();
    provider.addScope('profile');
    provider.addScope('email');
    provider.addScope('openid');
    // See https://developers.google.com/identity/protocols/oauth2/openid-connect#authenticationuriparameters
    provider.setCustomParameters({
      hd: window.App.config.singleSignOn.allowedDomain ?? '',
    });
    const fbApp = window.App.firebaseApps.auth;
    await setPersistence(fbApp, inMemoryPersistence);
    await signInWithRedirect(fbApp, provider);
    throw new Error('unreachable');
  },
  signOut: async () => {
    try {
      await window.App.services.identity.deleteBrowserSession({});
    } catch (err) {
      console.error(err);
    }
    const fbApp = window.App.firebaseApps.auth;
    await firebaseSignOut(fbApp);
    // XXX: fbApp.currentUserがnullのとき、onAuthStateChangedが発火しないため、画面遷移が行われない
    // 無理矢理ではあるが、replaceして強制的にログアウト状態を反映させる
    deleteInformationDialogOpened();
    window.location.replace('/');
  },
  resetPassword: async (email: string) => {
    await sendPasswordResetEmail(window.App.firebaseApps.auth, email);
  },
  newPassword: async (oobCode: string, password: string) => {
    try {
      const fbApp = window.App.firebaseApps.auth;
      await verifyPasswordResetCode(fbApp, oobCode);
      await confirmPasswordReset(fbApp, oobCode, password);
    } catch (err) {
      console.error(err);
      if (err instanceof FirebaseError) {
        throw new FirebaseError(err.code, err.message);
      } else {
        throw new Error(`${err}`);
      }
    }
  },
};

async function handleAuthStateChanged(
  fbUser: FirebaseUser | null
): Promise<void> {
  console.info('onAuthStateChanged', fbUser);
  if (!fbUser) {
    return;
  }
  const idToken = await fbUser.getIdToken();
  await window.App.services.identity.createBrowserSession({
    idToken,
  });
}

interface AuthProviderProps {
  children: React.ReactNode;
}

export function AuthProvider({ children }: AuthProviderProps) {
  const [loading, setLoading] = useState(true);
  const [contextValue, setContextValue] = useState(defaultContextValue);
  const [dictionary, setDictionary] = useState<any>();
  const [openNoticeDialog, setOpenNoticeDialog] = useState(false);
  const [noticeDialogProps, setNoticeDialogProps] = useState<
    mtechnavi.api.admin.IInformation[]
  >([]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(
      window.App.firebaseApps.auth,
      async (fbUser: FirebaseUser | null) => {
        try {
          // 辞書情報の読込み(未認証の場合、後続処理の辞書情報取得に到達しないため)
          setDictionary(await selectMessages(!defaultContextValue.authorized));

          await handleAuthStateChanged(fbUser);
          const nextContextValue = {
            ...defaultContextValue,
          };
          setLoading(true);
          try {
            const res = await window.App.services.identity.verifyUser({});
            // 認証できたタイミングで名称マスタ取得
            const programOptions = await getProgramOptions();
            window.App.programoptions = programOptions;
            // ヘルプリストを取得
            const helpList = await getHelpJson();
            window.App.helpList = helpList;
            nextContextValue.authorized = true;
            nextContextValue.tenant = res.tenant!;
            nextContextValue.user = res.user!;
            nextContextValue.roles = res.roles!;
            // for firebase analytics
            setUserId(window.App.firebaseApps.analytics, res.user!.userId!, {
              global: true,
            });
            // テナントIDをfirebase analytics（GoogleAnalytics）のユーザプロパティに追加
            setUserProperties(
              window.App.firebaseApps.analytics,
              {
                tenant_id: res.tenant!.internalTenantId,
              },
              {
                global: true,
              }
            );
            // 共通の情報をワーカーにて一括リロード
            await workerReloads(InitialLoadMethods, 3);
            // 辞書情報の読込み
            setDictionary(await selectMessages(false));
            // お知らせ確認ダイアログの表示(通常テナントのみ)
            if (!window.App.config.adminMenu) {
              const notices = await getNoticeList();
              window.App.notices = notices;
              const openNotices: mtechnavi.api.admin.IInformation[] =
                notices.filter((v) => v.requiredRead);

              const initialInformation = getInformationDialogOpened();
              if (!initialInformation) {
                if (openNotices.length > 0) {
                  saveInformationDialogOpened();
                  setNoticeDialogProps(openNotices);
                  setOpenNoticeDialog(true);
                }
              }
            }
          } catch (err) {
            if (err instanceof RpcError) {
              console.warn(err);
              nextContextValue.authorized = false;
              nextContextValue.tenant = undefined;
              nextContextValue.user = undefined;
              nextContextValue.roles = undefined;
            }
            throw err;
          }
          setContextValue(nextContextValue);
        } catch (err) {
          console.error(err);
        } finally {
          setLoading(false);
        }
      }
    );
    return () => {
      unsubscribe();
    };
  }, []);
  if (!!loading) {
    return <LoadingOverlay />;
  }

  return (
    <>
      <IntlProvider locale={'ja'} messages={dictionary}>
        <AuthContext.Provider value={contextValue}>
          {children}
          <InformationDialog
            isOpen={openNoticeDialog}
            messageOption={{
              headerLabelId: {
                id: 'InformationDialog',
                prefixId: 'DIALOG_TITLE',
              },
            }}
            inputOption={{ informationList: noticeDialogProps }}
            onCancel={() => {
              setOpenNoticeDialog(false);
            }}
          />
        </AuthContext.Provider>
      </IntlProvider>
    </>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}

export function RequireAuth({ children }: { children: JSX.Element }) {
  const auth = useAuth();
  const location = useLocation();

  if (!auth.authorized) {
    deleteInformationDialogOpened();
    return <Navigate to="/sign-in" state={{ from: location }} replace />;
  }
  // XXX: ErrorBoundaryにて、認証済みユーザーによるアクションエラーを検知する
  return <ErrorBoundary>{children}</ErrorBoundary>;
}
