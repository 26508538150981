import { GetMessage, GetMessageWithIntl } from '../../parts/Message/Message';
import { DataFilterbox, DataFilterboxItem, FilterboxItem } from '../Filterbox';
import {
  ModalDialogComponent,
  ModalDialogComponentProps,
} from '../ModalDialog/ModalDialog';
import { useEffect, useRef, useState } from 'react';
import { CaptionButton } from '../Button/CaptionButton';
import { useIntl } from 'react-intl';
import { includeInputValidateError } from '~/shared/utils';
import { mtechnavi } from '~/shared/libs/clientsdk';

// API接続時に変更の可能性あり
export interface MainCOntactSettingDialogResult {
  mainContact: FilterboxItem;
}

export interface MainContactSettingDialogInputOption {
  contactList: mtechnavi.api.company.IBusinessUnitContact[];
  initialContactId: string;
}

export interface MainCOntactSettingDialogProps {
  isOpen: boolean;
  inputOption: MainContactSettingDialogInputOption;
  onDecision: (result: MainCOntactSettingDialogResult) => void;
  onCancel: () => void;
}

export const MainContactSettingDialog = (
  props: MainCOntactSettingDialogProps
) => {
  const { contactList, initialContactId } = props.inputOption;
  const intl = useIntl();
  const [mainContact, setMainContact] = useState<DataFilterboxItem[]>([]);
  const [contacts, setContacts] = useState<DataFilterboxItem[]>([]);

  // 入力チェック用
  const requiredArea = useRef(null);
  const [workingBlurChangedStaff, setWorkingBlurChangedStaff] =
    useState<Date>();

  useEffect(() => {
    if (!props.isOpen) {
      return;
    }
    const dataFIlterItem: DataFilterboxItem[] = [];
    contactList.map((v) => {
      dataFIlterItem.push({
        value: v.businessUnitContactId || '',
        displayName: v.displayName || '',
      });
    });
    setContacts(dataFIlterItem);
    setMainContact(dataFIlterItem.filter((v) => v.value === initialContactId));
    // isOpen変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isOpen]);

  const isInputError = (): boolean => {
    setWorkingBlurChangedStaff(new Date());

    const inputValidationCheckList = [
      {
        value: mainContact.length > 0 ? mainContact[0].value : '',
        ref: requiredArea,
      },
    ];

    const targetElm = document.querySelector('.main-contact-setting-dialog');
    if (includeInputValidateError(targetElm, intl, inputValidationCheckList)) {
      return true;
    }
    return false;
  };

  const elements = (
    <div className="main-contact-setting-dialog dialog-with-description">
      <div className="description-area">
        <p className="text-box">
          <span className="text">
            {GetMessage({
              prefixId: 'DIALOG_DESCRIPTION',
              id: 'main-contact-setting',
            })}
          </span>
        </p>
      </div>
      <div className="detail-area">
        <div className="comment-area">
          <div className="input-line extend-space-for-filterbox">
            <div className="item-group-100">
              <div className="w-50">
                <DataFilterbox
                  name="contacts"
                  data={contacts}
                  labelId="mainContactSettingDialog.contact"
                  columns={['contacts']}
                  value={mainContact ?? [{ value: '', displayName: '' }]}
                  validateOption={{ required: true }}
                  searchOption={{
                    targets: 'displayName',
                  }}
                  onChangeState={setMainContact}
                  workingBlur={workingBlurChangedStaff}
                />
              </div>
            </div>
          </div>
          <div className="button-area">
            <CaptionButton
              name="cancelBtn"
              buttonType="cancel"
              className="button"
              caption={GetMessageWithIntl(intl, { id: 'cancel' })}
              onClick={() => props.onCancel()}
            />
            <CaptionButton
              name="sendBtn"
              buttonType="basic"
              className="button"
              caption={GetMessageWithIntl(intl, {
                id: 'send',
              })}
              onClick={() => {
                if (isInputError()) {
                  return;
                }
                props.onDecision({
                  mainContact: mainContact[0],
                });
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );

  const openModalProps: ModalDialogComponentProps = {
    cancel: () => {
      props.onCancel();
    },
    send: () => {},
    modalIsOpen: props.isOpen,
    headerLabelId: {
      prefixId: 'DIALOG_TITLE',
      id: 'main_contact_setting',
    },
    messageLabelId: {},
    elements,
  };

  return <ModalDialogComponent {...openModalProps} />;
};
