import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface UpdateResultFormShareManagementRequest {
  actionName: 'updateResultFormShareManagement';
  request: mtechnavi.api.survey.IUpdateResultFormShareManagementRequest;
}

export const updateResultFormShareManagement = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.survey.IUpdateResultFormShareManagementRequest
) => {
  return worker.invokeListRequest<
    mtechnavi.api.survey.IUpdateResultFormShareManagementRequest,
    mtechnavi.api.survey.ResultFormShareManagement
  >(
    'mtechnavi.api.survey.SurveyReceiverService/UpdateResultFormShareManagement',
    req
  );
};
