import { mtechnavi, sharelib } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface DeleteBusinessUnitContact {
  actionName: 'deleteBusinessUnitContact';
  request: BusinessUnitContactParam;
}

interface BusinessUnitContactParam {
  tenantStatus?: sharelib.INameOption;
  businessUnitContact: mtechnavi.api.company.IBusinessUnitContact;
}

export const deleteBusinessUnitContact = async (
  worker: AppSaveWorker,
  req: BusinessUnitContactParam
) => {
  const deleteMethodName =
    'mtechnavi.api.company.BusinessUnitManagementService/DeleteBusinessUnitContact';

  return await worker.invokeListRequest(deleteMethodName, {
    businessUnitContact: req.businessUnitContact,
  });
};
