import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export const saveInformation = async (
  worker: AppSaveWorker,
  request: mtechnavi.api.admin.IInformation
) => {
  const createFullMethodName =
    'mtechnavi.api.admin.InformationService/CreateInformation';
  const updateFullMethodName =
    'mtechnavi.api.admin.InformationService/UpdateInformation';

  if (request.informationId) {
    await worker.invokeListRequest(updateFullMethodName, {
      information: request,
    });
    return;
  }
  await worker.invokeListRequest(createFullMethodName, {
    information: request,
  });
};
