import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface CompleteEstimateRequestPlanRequest {
  actionName: 'completeEstimateRequestPlan';
  request: mtechnavi.api.estimation.ICompleteEstimateRequestPlanRequest[];
}

export const completeEstimateRequestPlan = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.estimation.ICompleteEstimateRequestPlanRequest[]
) => {
  const fullMethodName =
    'mtechnavi.api.estimation.EstimateSenderService/CompleteEstimateRequestPlan';

  const result = req.map((item) => {
    return worker.invokeListRequest<
      mtechnavi.api.estimation.ICompleteEstimateRequestPlanRequest,
      void
    >(fullMethodName, {
      estimateRequestPlanId: item.estimateRequestPlanId,
    });
  });

  await Promise.all(result);
};
