import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface DeleteCatalogRequest {
  actionName: 'delete';
  request: mtechnavi.api.survey.ISurveyRequestCatalog[];
}

export const deleteCatalogRequest = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.survey.ISurveyRequestCatalog[]
) => {
  const fullMethodName =
    'mtechnavi.api.survey.SurveySenderService/DeleteSurveyRequestCatalog';

  type deleteRequestType =
    mtechnavi.api.survey.IDeleteSurveyRequestCatalogRequest;
  const result = req.map((item) => {
    const target = item as mtechnavi.api.survey.ISurveyRequestCatalog;
    return worker.invokeListRequest(fullMethodName, {
      surveyRequestCatalog: target as deleteRequestType,
    });
  });

  await Promise.all(result);
};
