import Long from 'long';
import { useState } from 'react';
import { ContentsDisplayDialog } from '~/shared/components/ui';
import { CaptionButton } from '~/shared/components/ui/Button';

export const UISampleContentsDisplayDialogs = () => {
  const [isShowDisplayDialog, setIsShowDisplayDialog] = useState(false);

  return (
    <div className="categories">
      <h3 id="dialog">内容確認ダイアログ</h3>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setIsShowDisplayDialog(true);
              }}
            />
            <ContentsDisplayDialog
              isOpen={isShowDisplayDialog}
              inputOption={{
                info: {
                  subject: '資料タイトル',
                  content: '内容',
                  attachment: [
                    {
                      filename: 'aaaa.pdf',
                    },
                  ],
                  lastUpdatedDate: Long.fromNumber(
                    Math.floor(new Date().getTime() / 1000)
                  ),
                },
              }}
              onCancel={() => {
                setIsShowDisplayDialog(false);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
