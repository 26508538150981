import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface DeleteDisasterSurveyRequest {
  actionName: 'deleteDisasterSurvey';
  request: mtechnavi.api.bcp.IDeleteDisasterSurveyRequest;
}

// 災害調査削除
export const deleteDisasterSurvey = async (
  worker: AppSaveWorker,
  request: mtechnavi.api.bcp.IDeleteDisasterSurveyRequest
) => {
  const fullMethodName = 'mtechnavi.api.bcp.BcpService/DeleteDisasterSurvey';

  await worker.invokeListRequest<
    mtechnavi.api.bcp.IDeleteDisasterSurveyRequest,
    void
  >(fullMethodName, {
    disasterSurveyId: request.disasterSurveyId,
  });
};
