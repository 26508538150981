import { mtechnavi, sharelib } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface UnsetAllForumMarkerRequestParam {
  recordIdList: string[];
  markers?: sharelib.INameOption[] | null;
}

export interface UnsetAllForumMarkerRequest {
  actionName: 'unsetAllForumMarker';
  request: UnsetAllForumMarkerRequestParam;
}

export const unsetAllForumMarker = async (
  worker: AppSaveWorker,
  req: UnsetAllForumMarkerRequestParam
) => {
  const requests = req.recordIdList.map((recordId) =>
    worker.invokeListRequest<
      mtechnavi.api.forum.IUnsetMarkerRequest,
      mtechnavi.api.forum.IUnsetMarkerResponse
    >('mtechnavi.api.forum.ForumService/UnsetMarker', {
      recordId,
      markers: req.markers,
      mode: 1,
      typeName: 'mtechnavi.api.forum.Comment',
    })
  );
  return await Promise.all(requests);
};
