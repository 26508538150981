import React, { ReactNode } from 'react';
import { ReactComponent as AccountIcon } from '@material-design-icons/svg/filled/account_circle.svg';
import { ReactComponent as RecipientsIcon } from '@material-design-icons/svg/filled/group.svg';
import { ReactComponent as GroupIcon } from '@material-design-icons/svg/filled/group.svg';
import { ReactComponent as AssignmentIcon } from '@material-design-icons/svg/filled/assignment.svg';
import { ReactComponent as NotificationsIcon } from '@material-design-icons/svg/outlined/notifications.svg';
import { ReactComponent as CompanyIcon } from '@material-design-icons/svg/outlined/business.svg';
import { ReactComponent as AddUserIcon } from '@material-design-icons/svg/outlined/person_add.svg';
import { ReactComponent as LogoutIcon } from '@material-design-icons/svg/filled/logout.svg';
import { ReactComponent as PasswordIcon } from '@material-design-icons/svg/filled/password.svg';
import { ReactComponent as AddIcon } from '@material-design-icons/svg/filled/add.svg';
import { ReactComponent as CancelIcon } from '@material-design-icons/svg/filled/cancel.svg';
import { ReactComponent as ClearIcon } from '@material-design-icons/svg/filled/clear.svg';
import { ReactComponent as CloseIcon } from '@material-design-icons/svg/filled/close.svg';
import { ReactComponent as FirstIcon } from '@material-design-icons/svg/filled/first_page.svg';
import { ReactComponent as PrevIcon } from '@material-design-icons/svg/filled/keyboard_arrow_left.svg';
import { ReactComponent as NextIcon } from '@material-design-icons/svg/filled/keyboard_arrow_right.svg';
import { ReactComponent as LastIcon } from '@material-design-icons/svg/filled/last_page.svg';
import { ReactComponent as UpIcon } from '@material-design-icons/svg/filled/keyboard_arrow_up.svg';
import { ReactComponent as DownIcon } from '@material-design-icons/svg/filled/keyboard_arrow_down.svg';
import { ReactComponent as RefreshIcon } from '@material-design-icons/svg/filled/refresh.svg';
import { ReactComponent as BackIcon } from '@material-design-icons/svg//filled/arrow_back.svg';
import { ReactComponent as DownloadIcon } from '@material-design-icons/svg/filled/save_alt.svg';
import { ReactComponent as FilesIcon } from '@material-design-icons/svg/filled/perm_media.svg';
import { ReactComponent as CheckedIcon } from '@material-design-icons/svg/filled/check_circle.svg';
import { ReactComponent as UnCheckedIcon } from '@material-design-icons/svg/filled/check_circle_outline.svg';
import { ReactComponent as ReadIcon } from '@material-design-icons/svg/outlined/drafts.svg';
import { ReactComponent as UnReadIcon } from '@material-design-icons/svg/filled/mark_email_unread.svg';
import { ReactComponent as DeleteIcon } from '@material-design-icons/svg/filled/delete_outline.svg';
import { ReactComponent as SendMailIcon } from '@material-design-icons/svg/filled/forward_to_inbox.svg';
import { ReactComponent as ForumIcon } from '@material-design-icons/svg/filled/forum.svg';
import { ReactComponent as RegistrationIcon } from '@material-design-icons/svg/filled/library_books.svg';
import { ReactComponent as SendIcon } from '@material-design-icons/svg/filled/send.svg';
import { ReactComponent as MoreIcon } from '@material-design-icons/svg/filled/more_vert.svg';
import { ReactComponent as ReadMoreIcon } from '@material-design-icons/svg/filled/read_more.svg';
import { ReactComponent as SearchIcon } from '@material-design-icons/svg/filled/search.svg';
import { ReactComponent as EditIcon } from '@material-design-icons/svg/filled/edit.svg';
import { ReactComponent as NoteAddIcon } from '@material-design-icons/svg/filled/note_add.svg';
import { ReactComponent as DescriptionIcon } from '@material-design-icons/svg/filled/description.svg';
import { ReactComponent as HistoryIcon } from '@material-design-icons/svg/filled/history.svg';
import { ReactComponent as ClearListIcon } from '@material-design-icons/svg/filled/playlist_remove.svg';
import { ReactComponent as UploadIcon } from '@material-design-icons/svg/filled/upload_file.svg';
import { ReactComponent as HelpIcon } from '@material-design-icons/svg/filled/help.svg';
import { ReactComponent as OpenInNew } from '@material-design-icons/svg/outlined/open_in_new.svg';
import { ReactComponent as SmsIcon } from '@material-design-icons/svg/outlined/sms.svg';
import { ReactComponent as InsertComment } from '@material-design-icons/svg/outlined/insert_comment.svg';
import { ReactComponent as SubmitCommentIcon } from '@material-design-icons/svg/outlined/library_books.svg';
import { ReactComponent as ReplyIcon } from '@material-design-icons/svg/filled/reply.svg';
import { ReactComponent as ReadAllIcon } from '@material-design-icons/svg/filled/mark_email_read.svg';
import { ReactComponent as SettingsIcon } from '@material-design-icons/svg/outlined/settings.svg';
import { ReactComponent as MailIcon } from '@material-design-icons/svg/outlined/mail.svg';
import { ReactComponent as CopyIcon } from '@material-design-icons/svg/outlined/content_copy.svg';
import { ReactComponent as ManageSearchIcon } from '@material-design-icons/svg/outlined/mark_chat_unread.svg';
import { ReactComponent as FlagOnIcon } from '@material-design-icons/svg/filled/flag.svg';
import { ReactComponent as FlagOffIcon } from '@material-design-icons/svg/outlined/flag.svg';
import { ReactComponent as ArrowUpwardIcon } from '@material-design-icons/svg/outlined/arrow_upward.svg';
import { ReactComponent as ArrowDownwardIcon } from '@material-design-icons/svg/outlined/arrow_downward.svg';
import { ReactComponent as QueryStatsIcon } from '@material-design-icons/svg/outlined/query_stats.svg';

export type GenerallyIconType =
  | 'account'
  | 'recipients'
  | 'group'
  | 'assignment'
  | 'notifications'
  | 'forum'
  | 'files'
  | 'company'
  | 'add_user'
  | 'registration'
  | 'send'
  | 'delete'
  | 'edit'
  | 'add'
  | 'cancel'
  | 'clear'
  | 'first'
  | 'prev'
  | 'next'
  | 'last'
  | 'up'
  | 'down'
  | 'close'
  | 'back'
  | 'search'
  | 'refresh'
  | 'export'
  | 'download'
  | 'logout'
  | 'more'
  | 'read_more'
  | 'checked'
  | 'unchecked'
  | 'read'
  | 'unread'
  | 'send_mail'
  | 'password'
  | 'noteadd'
  | 'description'
  | 'history'
  | 'upload'
  | 'help'
  | 'clear_list'
  | 'open_in_new'
  | 'message_text'
  | 'insert_comment'
  | 'submit'
  | 'reply'
  | 'settings'
  | 'mail'
  | 'read_all'
  | 'flag_on'
  | 'flag_off'
  | 'copy'
  | 'reply'
  | 'unread_message'
  | 'arrow_upward'
  | 'arrow_downward'
  | 'query_stats';

export const getGenerallyIconElement = (
  iconType: GenerallyIconType
): ReactNode => {
  switch (iconType) {
    // 機能
    case 'account':
      return <AccountIcon />;
    case 'recipients':
      return <RecipientsIcon />;
    case 'group':
      return <GroupIcon />;
    case 'assignment':
      return <AssignmentIcon />;
    case 'notifications':
      return <NotificationsIcon />;
    case 'forum':
      return <ForumIcon />;
    case 'files':
      return <FilesIcon />;
    case 'company':
      return <CompanyIcon />;
    case 'message_text':
      return <SmsIcon />;
    case 'insert_comment':
      return <InsertComment />;
    case 'submit':
      return <SubmitCommentIcon />;

    // 決定的なデータの操作
    case 'registration':
      return <RegistrationIcon />;
    case 'send':
      return <SendIcon />;
    case 'delete':
      return <DeleteIcon />;

    // 一時的な追加 / 削除
    case 'add':
      return <AddIcon />;
    case 'cancel':
      return <CancelIcon />;
    case 'clear':
      return <ClearIcon />;
    case 'noteadd':
      return <NoteAddIcon />;
    case 'copy':
      return <CopyIcon />;

    // リストなどの操作
    case 'first':
      return <FirstIcon />;
    case 'prev':
      return <PrevIcon />;
    case 'next':
      return <NextIcon />;
    case 'last':
      return <LastIcon />;
    case 'up':
      return <UpIcon />;
    case 'down':
      return <DownIcon />;
    case 'arrow_upward':
      return <ArrowUpwardIcon />;
    case 'arrow_downward':
      return <ArrowDownwardIcon />;

    // 遷移
    case 'close':
      return <CloseIcon />;
    case 'back':
      return <BackIcon />;
    case 'reply':
      return <ReplyIcon />;

    // その他のアクション
    case 'search':
      return <SearchIcon />;
    case 'refresh':
      return <RefreshIcon />;
    case 'export':
      return <DownloadIcon />;
    case 'download':
      return <DownloadIcon />;
    case 'logout':
      return <LogoutIcon />;
    case 'more':
      return <MoreIcon />;
    case 'edit':
      return <EditIcon />;
    case 'description':
      return <DescriptionIcon />;
    case 'history':
      return <HistoryIcon />;
    case 'clear_list':
      return <ClearListIcon />;
    case 'read_more':
      return <ReadMoreIcon />;
    case 'upload':
      return <UploadIcon />;
    case 'open_in_new':
      return <OpenInNew />;
    case 'settings':
      return <SettingsIcon />;
    case 'mail':
      return <MailIcon />;
    case 'unread_message':
      return <ManageSearchIcon />;
    case 'add_user':
      return <AddUserIcon />;
    case 'query_stats':
      return <QueryStatsIcon />;

    // チェック / 既読
    case 'checked':
      return <CheckedIcon />;
    case 'unchecked':
      return <UnCheckedIcon />;
    case 'read':
      return <ReadIcon />;
    case 'unread':
      return <UnReadIcon />;
    case 'read_all':
      return <ReadAllIcon />;
    case 'send_mail':
      return <SendMailIcon />;

    // フラグ
    case 'flag_on':
      return <FlagOnIcon />;
    case 'flag_off':
      return <FlagOffIcon />;

    // その他記号
    case 'password':
      return <PasswordIcon />;
    case 'help':
      return <HelpIcon />;
  }
};
