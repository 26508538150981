import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface CompleteSurveyRequest {
  actionName: 'completeSurveyRequest';
  request: CompleteSurveyParam;
}
interface CompleteSurveyParam {
  surveyRequestIds: string[];
}

// 依頼終了
export const completeSurveyRequest = async (
  worker: AppSaveWorker,
  req: CompleteSurveyParam
) => {
  const FullMethodName_CompleteSurveyRequest =
    'mtechnavi.api.survey.SurveySenderService/CompleteSurveyRequest';

  return await Promise.all(
    req.surveyRequestIds.map((surveyRequestId) =>
      worker.invokeListRequest<
        mtechnavi.api.survey.ICompleteSurveyRequestRequest,
        mtechnavi.api.survey.ICompleteSurveyRequestResponse
      >(FullMethodName_CompleteSurveyRequest, {
        surveyRequestId,
      })
    )
  );
};
