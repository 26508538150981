import { useIntl } from 'react-intl';
import {
  AttachmentItems,
  autoBulkDownload,
  autoDownloadFileOnlyName,
  getDateFormat,
} from '~/shared/utils';
import { GetMessage, GetMessageWithIntl } from '../../parts/Message/Message';
import { CaptionButton } from '../Button';
import { ModalDialogComponent, ModalDialogComponentProps } from '../..';
import { Textbox } from '../Textbox';
import { Textarea } from '../Textarea';
import { SimpleListView } from '../SimpleListView';
import './ContentsDisplayDialog.css';

const DIALOG_TITLE = 'contentsDisplayDialog';

export interface ContentsDisplayInfo {
  subject: string;
  content?: string;
  attachment?: AttachmentItems[];
  lastUpdatedDate?: Long;
}

interface ContentsDisplayDialogInputOption {
  info: ContentsDisplayInfo;
}

export interface ContentsDisplayDialogProps {
  isOpen: boolean;
  inputOption: ContentsDisplayDialogInputOption;
  onCancel: () => void;
}

export const ContentsDisplayDialog = (props: ContentsDisplayDialogProps) => {
  const intl = useIntl();
  const { isOpen, inputOption } = props;

  const view = (
    <div className="contents-display-dialog dialog-with-description">
      <div className="header-last-updated-date">
        {GetMessage({ id: 'lastUpdatedDate', prefixId: DIALOG_TITLE })}
        {getDateFormat(inputOption.info.lastUpdatedDate || '', 'YYYY/MM/DD')}
      </div>
      <div className="detail-area">
        <div className="comment-area">
          <div className="input-line">
            <div className="item-group-100">
              <div className="w-50">
                <Textbox
                  name="subject"
                  labelId={`${DIALOG_TITLE}.subject`}
                  columns={['subject']}
                  type="text"
                  value={inputOption.info.subject}
                  validateOption={{ required: true }}
                  disabled={true}
                />
              </div>
            </div>
          </div>
          <div className="input-line">
            <div className="item-group-100">
              <div className="w-100">
                <Textarea
                  name="content"
                  labelId={`${DIALOG_TITLE}.content`}
                  className="w-100 mh-middle"
                  columns={['content']}
                  value={inputOption.info.content || ''}
                  disabled={true}
                />
              </div>
            </div>
          </div>
          <div className="input-line">
            <div className="item-group-100">
              <div className="w-50">
                <SimpleListView
                  data={inputOption.info.attachment ?? []}
                  actionOptions={{
                    onRowClick: (item: AttachmentItems) => {
                      autoDownloadFileOnlyName(
                        item.filename ?? '',
                        item.assetId ?? ''
                      );
                    },
                    onFullDownLoad: () => {
                      const fileName = GetMessageWithIntl(intl, {
                        prefixId: DIALOG_TITLE,
                        id: 'commonFileAttachment',
                      });
                      autoBulkDownload(
                        (inputOption.info.attachment ?? []).map(
                          (item) => item.assetId ?? ''
                        ),
                        intl,
                        'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION',
                        fileName
                      );
                    },
                  }}
                  viewOptions={{
                    previewRowCount: 5,
                    columns: [
                      {
                        propertyName: 'filename',
                        header: {
                          id: 'simpleListView.header',
                          prefixId: DIALOG_TITLE,
                        },
                      },
                    ],
                    readonly: true,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="button-area">
          <CaptionButton
            name="cancelBtn"
            buttonType="cancel"
            className="button"
            caption={GetMessage({ id: 'cancel' })}
            onClick={() => {
              props.onCancel();
            }}
          />
        </div>
      </div>
    </div>
  );

  const openModalProps: ModalDialogComponentProps = {
    cancel: () => {
      props.onCancel();
    },
    send: () => {},
    modalIsOpen: isOpen,
    headerLabelId: {
      prefixId: 'DIALOG_TITLE',
      id: 'contents_display',
    },
    messageLabelId: {},
    elements: view,
  };

  return (
    <div className="ContentsDisplayDialog">
      <ModalDialogComponent {...openModalProps} />
    </div>
  );
};
