import { mtechnavi, sharelib } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';
import { FullMethodName_CreateTenantAdmin } from 'worker/fullMethodName/crud';

/** テナント管理者アカウント作成 */
export const createTenantAdmin = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.admin.ICreateTenantAdminRequest
) => {
  return await worker.invokeListRequest<
    mtechnavi.api.admin.ICreateTenantAdminRequest,
    sharelib.IUserReference
  >(FullMethodName_CreateTenantAdmin, req);
};
