import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface UpdateSampleWorkFormRequest {
  actionName: 'updateSampleWorkForm';
  request: UpdateSampleWorkFormRequestParam;
}

export type UpdateSampleWorkFormRequestParam =
  mtechnavi.api.form.IUpdateSampleWorkFormRequest;

// FormSample の更新
export const updateSampleWorkForm = (
  worker: AppSaveWorker,
  req: UpdateSampleWorkFormRequestParam
) => {
  const methodName =
    'mtechnavi.api.form.SampleWorkFormService/UpdateSampleWorkForm';

  return worker.invokeListRequest<
    mtechnavi.api.form.IUpdateSampleWorkFormRequest,
    mtechnavi.api.form.ISampleWorkForm
  >(methodName, req);
};
