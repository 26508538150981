import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface DeleteSurveyRequestWorkFormRequest {
  actionName: 'deleteSurveyRequestWorkForm';
  request: mtechnavi.api.survey.IDeleteSurveyRequestWorkFormRequest;
}

// RequestWorkForm の削除
export const deleteSurveyRequestWorkForm = (
  worker: AppSaveWorker,
  req: mtechnavi.api.survey.IDeleteSurveyRequestWorkFormRequest
) => {
  const methodName =
    'mtechnavi.api.survey.SurveySenderService/DeleteSurveyRequestWorkForm';

  return worker.invokeListRequest<
    mtechnavi.api.survey.IDeleteSurveyRequestWorkFormRequest,
    void
  >(methodName, req);
};
