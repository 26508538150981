import {
  PropsWithoutRef,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import {
  GetMessageWithIntl,
  MessageProps,
  ModalDialogComponent,
  ModalDialogComponentProps,
  Radio,
  error,
} from '~/shared/components';
import { CaptionButton } from '~/shared/components/ui/Button';
import {
  CategorizedCheckList,
  CategorizedList,
  CheckItem,
} from '~/shared/components/ui/CategorizedCheckList';
import { toCommaTypeString } from '~/shared/utils';
import { ConditionDetail, MultipleType } from '../../util';
import './ConditionTreeDialog.css';

export interface ConditionTreeDialogRef {
  open: (
    listData: CategorizedList,
    condition?: ConditionDetail,
    focusId?: string
  ) => void;
}
interface ConditionTreeDialogProps {
  messageOption: {
    headerLabelId: MessageProps;
    labelEveryMatch?: MessageProps;
    labelSomeMatch?: MessageProps;
  };
  onDecision?: (condition: ConditionDetail) => void;
  onCancel?: () => void;
  onCheckCount?: (condition: ConditionDetail) => Promise<Long>;
}

export const ConditionTreeDialog = forwardRef(
  (props: PropsWithoutRef<ConditionTreeDialogProps>, ref) => {
    const intl = useIntl();
    const [isOpen, setOpen] = useState(false);
    const [listData, setListData] = useState<CategorizedList>([]);
    const [initCheckIds, setInitCheckIds] = useState<string[]>([]);
    const [multipleType, setMultipleType] = useState<MultipleType>('or');
    const [checkedIds, setCheckedIds] = useState<CheckItem[]>([]);
    const [hitCount, setHitCount] = useState<Long | null>(null);
    const [focusId, setFocusId] = useState<string>();

    const labelEveryMatch = useMemo(
      () =>
        props.messageOption.labelEveryMatch
          ? GetMessageWithIntl(intl, props.messageOption.labelEveryMatch)
          : '',
      [intl, props.messageOption.labelEveryMatch]
    );
    const labelSomeMatch = useMemo(
      () =>
        props.messageOption.labelSomeMatch
          ? GetMessageWithIntl(intl, props.messageOption.labelSomeMatch)
          : '',
      [intl, props.messageOption.labelSomeMatch]
    );

    const handleChangeChecks = useCallback((checkIds: CheckItem[]) => {
      setCheckedIds(checkIds);
      setHitCount(null);
    }, []);

    const handleFetchCount = async () => {
      if (checkedIds.length === 0) {
        error([GetMessageWithIntl(intl, { id: 'E0000023' })]);
        return;
      }
      if (!props.onCheckCount) {
        return;
      }
      const count = await props.onCheckCount({
        multipleType,
        conditionItems: checkedIds.map((item) => ({
          item,
          checked: true,
        })),
      });
      setHitCount(count);
    };

    const handleResetCondition = () => {
      setInitCheckIds([]);
    };

    const handleCancel = () => {
      setOpen(false);
    };

    const handleDecision = () => {
      if (checkedIds.length === 0) {
        error([GetMessageWithIntl(intl, { id: 'E0000023' })]);
        return;
      }
      if (props.onDecision) {
        props.onDecision({
          multipleType,
          conditionItems: checkedIds.map((item) => ({
            item,
            checked: true,
          })),
        });
      }
      setOpen(false);
    };

    useImperativeHandle(
      ref,
      (): ConditionTreeDialogRef => ({
        open: (
          listData: CategorizedList,
          condition?: ConditionDetail,
          focusId?: string
        ) => {
          setListData(listData);
          if (condition) {
            setMultipleType(condition.multipleType);
            setInitCheckIds(
              condition.conditionItems
                .filter((condition) => condition.checked)
                .map((condition) => condition.item.id || '')
            );
          } else {
            setMultipleType('or');
            setInitCheckIds([]);
          }
          if (focusId) {
            setFocusId(focusId);
          }
          setOpen(true);
        },
      })
    );

    const elements = (
      <div className="ConditionTreeDialog">
        <Radio
          name="multipleType"
          items={[
            { value: 'and', displayName: labelEveryMatch },
            { value: 'or', displayName: labelSomeMatch },
          ]}
          value={multipleType}
          onChangeState={(v) => setMultipleType(v as MultipleType)}
        />
        <div>
          <CategorizedCheckList
            listData={listData}
            checkedIds={initCheckIds}
            onChange={handleChangeChecks}
            focusId={focusId}
          />
        </div>
        <div className="button-area">
          <CaptionButton
            name="reset"
            className="reset-condition-button"
            caption="条件解除"
            onClick={handleResetCondition}
          />
          <div className="fetch-count-container">
            <span className="fetch-count-label">対象企業数</span>
            <CaptionButton
              name="fetchCount"
              className={`fetch-count-button ${
                hitCount === null ? 're-calc' : ''
              }`}
              caption={
                hitCount !== null
                  ? `${toCommaTypeString(`${hitCount}`)}社`
                  : '再計算'
              }
              onClick={handleFetchCount}
            />
          </div>
          <CaptionButton
            name="save"
            buttonType="basic"
            className="button"
            caption={GetMessageWithIntl(intl, { id: 'select' })}
            onClick={() => handleDecision()}
          />
        </div>
      </div>
    );

    const openModalProps: ModalDialogComponentProps = {
      cancel: handleCancel,
      send: () => {},
      modalIsOpen: isOpen,
      headerLabelId: props.messageOption.headerLabelId,
      messageLabelId: {},
      elements,
      controls: { showCloseHeaderButton: true },
    };

    return (
      <div className="ConditionTreeDialogBase">
        <ModalDialogComponent {...openModalProps} />
      </div>
    );
  }
);
