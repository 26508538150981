import {
  FilterRequest,
  FilterResult,
  FilterExpression,
  SortTerm,
  AggregateStage,
} from '../';
import { tenantAdminCallMap } from './tenantAdmin';
import { assetInventoryCallMap } from './assetInventory';
import { surveyCallMap } from './survey';
import { companyCallMap } from './company';
import { workTaskCallMap } from './workTask';
import { formCallMap } from './form';
import { bcpCallMap } from './bcp';
import { estimateCallMap } from './estimate';
import { forumCallMap } from './forum';
import { programOptionCallMap } from './programoption';
import { optCallMap } from './opt';

// RequestType を抽出するユーティリティ型
type ExtractRequestType<T> = T extends (
  worker: AppSaveWorker,
  req: infer R
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
) => any
  ? R
  : never;

// saveWorker に渡される RequestType を callMap から抽出して定義
export type SaveRequestType = {
  [K in keyof typeof callMap]: {
    actionName: K;
    request: ExtractRequestType<typeof callMap[K]>;
  };
}[keyof typeof callMap];

// actionName の定数定義
type SaveWorkerRequestAction = SaveRequestType['actionName'];

// 戻り値の型を callMap から抽出して定義
type ResultTypeMap = {
  [K in keyof typeof callMap]: ReturnType<typeof callMap[K]>;
};
type SafeResultTypeMap = { [K in SaveWorkerRequestAction]: ResultTypeMap[K] };
export type SaveResultType<T> = T extends SaveRequestType
  ? SafeResultTypeMap[T['actionName']]
  : never;

// すべての actionName と function のマッピング
const callMap = {
  ...assetInventoryCallMap,
  ...bcpCallMap,
  ...companyCallMap,
  ...estimateCallMap,
  ...formCallMap,
  ...forumCallMap,
  ...optCallMap,
  ...programOptionCallMap,
  ...surveyCallMap,
  ...tenantAdminCallMap,
  ...workTaskCallMap,
  // 機能を追加した際はここに追記する
};

export class AppSaveWorker {
  // 親のクラスで定義されている関数をもらうための宣言
  invokeListRequest: <T, U>(a: string, b?: T) => Promise<U[]>;
  filter: <T>(a: FilterRequest) => Promise<FilterResult<T>>;

  query: (
    a: string,
    b: FilterExpression,
    c: SortTerm[],
    d?: AggregateStage[]
  ) => Promise<unknown>;
  checkDummyAPI: (a: string) => boolean;
  entities: {
    [k: string]: unknown[];
  } = {};

  constructor(
    invokeListRequest: <T, U>(a: string, b?: T) => Promise<U[]>,
    filter: <T>(a: FilterRequest) => Promise<FilterResult<T>>,
    query: (
      a: string,
      b: FilterExpression,
      c: SortTerm[],
      d?: AggregateStage[]
    ) => Promise<unknown>,
    checkDummyAPI: (a: string) => boolean
  ) {
    this.invokeListRequest = invokeListRequest;
    this.filter = filter;
    this.query = query;
    this.checkDummyAPI = checkDummyAPI;
  }

  async apiCall<T extends SaveRequestType>(req: T): Promise<SaveResultType<T>> {
    const func = Object.entries(callMap).find(
      ([actionName]) => actionName === req.actionName
    );
    if (!func) {
      throw new Error(`unknown save action: ${req.actionName}`);
    }
    const [, callFunction] = func;
    return callFunction(
      this,
      // スカラ型の場合にここだけ型解釈がうまくいかないので any にキャストしている
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      req.request as any
    ) as SaveResultType<T>;
  }
}
