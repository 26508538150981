import { IconButton } from '~/shared/components/ui/Button';
import {
  GetMessage,
  MessageProps,
} from '~/shared/components/parts/Message/Message';
import { Property } from 'csstype';
import './SimpleListView.css';
import { useTooltip } from '../../parts/Tooltip';
import { useRef } from 'react';

interface SimpleIconListActionOptions {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onRowClick?: (data: any) => void;
  onFullDownLoad?: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDelete?: (data: any) => void;
  onDeleteAll?: () => void;
}

// アイコンと同じにする
type SimpleIconMenuName =
  | 'noteadd'
  | 'delete'
  | 'export'
  | 'description'
  | 'read_more'
  | 'edit'
  | 'settings'
  | 'mail'
  | 'copy'
  | 'arrow_upward'
  | 'send_mail';

export interface IconMenuItem {
  name: SimpleIconMenuName;
  displayName?: string;
  disabled?: boolean;
  disabledFunc?: DisabledFunc;
  func: MenuFunc;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type DisabledFunc = (data: any) => boolean;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type MenuFunc = (data: any) => void;

interface SimpleIconMenuOptions {
  iconHeaderMenu?: IconMenuItem[];
  iconMenu: IconMenuItem[];
}

interface ListColumnSetting {
  header?: MessageProps;
  propertyName: string;
  width?: string;
  align?: Property.TextAlign;
  readonly?: boolean;
}

interface SimpleIconListViewOptions {
  readonly?: boolean;
  omitHeader?: boolean;
  omitFooter?: boolean;
  previewRowCount?: number;
  keyColumn?: string;
  columns: ListColumnSetting[];
}

interface SimpleIconListViewProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[];
  actionOptions: SimpleIconListActionOptions;
  viewOptions: SimpleIconListViewOptions;
  iconMenuOptions: SimpleIconMenuOptions;
}
export const SimpleIconListView = (props: SimpleIconListViewProps) => {
  return (
    <>
      <SimpleIconListViewContent {...props} />
    </>
  );
};

const SimpleIconListViewContent = ({
  data,
  actionOptions,
  viewOptions,
  iconMenuOptions,
}: SimpleIconListViewProps) => {
  const { onRowClick, onFullDownLoad, onDelete, onDeleteAll } = actionOptions;
  const { iconMenu, iconHeaderMenu } = iconMenuOptions;
  const listViewRef = useRef<HTMLDivElement | null>(null);
  const { showTooltip, hideTooltip } = useTooltip();

  return (
    <div className="SimpleListView">
      <div className="header-icon-menu">
        {iconHeaderMenu?.map((icon, index) => (
          <span key={icon.name + '_' + index}>
            <IconButton
              name={icon.name}
              buttonType="basic"
              iconType={icon.name}
              disabled={
                icon.disabledFunc
                  ? icon.disabledFunc(null)
                  : icon.disabled ?? false
              }
              onClick={() => {
                icon.func(null);
              }}
              caption={icon.displayName ?? ''}
            />
          </span>
        ))}
      </div>
      <div
        className="scrollable-area"
        style={{
          maxHeight: viewOptions.previewRowCount
            ? `calc(2.5 * ${viewOptions.previewRowCount}rem${
                !viewOptions.omitHeader ? ' + 3rem' : ''
              })`
            : 'auto',
        }}
        ref={listViewRef}
      >
        {!viewOptions.omitHeader && (
          <div
            className="list-header"
            style={{
              gridTemplateColumns: `
              ${iconMenu.map(() => '2em').join(' ')}
              ${viewOptions.columns
                .map((column) => column.width || '1fr')
                .join(' ')} ${!viewOptions.readonly && onDelete ? '2em' : ''}`,
            }}
          >
            {iconMenu.map((icon) => (
              <div key={icon.name}>&nbsp;</div>
            ))}
            {viewOptions.columns.map((column) => (
              <div
                key={column.propertyName}
                style={{
                  textAlign: column?.align,
                }}
              >
                {column.header ? GetMessage(column.header) : ''}
              </div>
            ))}
            {!viewOptions.readonly && onDeleteAll && (
              <IconButton
                name="delete"
                iconType="clear_list"
                onClick={() => {
                  onDeleteAll();
                }}
              />
            )}
          </div>
        )}
        <ul className="list-items">
          {data.map((item, index) => {
            const key = item[viewOptions.keyColumn ?? ''];
            return (
              <li
                key={key ?? index}
                style={{
                  gridTemplateColumns: `

              ${iconMenu.map(() => '2em').join(' ')}
                  ${viewOptions.columns
                    .map((column) => column.width || '1fr')
                    .join(' ')} ${
                    !viewOptions.readonly && onDelete ? '2em' : ''
                  }`,
                }}
              >
                {iconMenu.map((icon, index) => (
                  <span key={icon.name + '_' + index}>
                    <IconButton
                      name={icon.name}
                      buttonType="basic"
                      iconType={icon.name}
                      disabled={
                        icon.disabledFunc
                          ? icon.disabledFunc(item)
                          : icon.disabled ?? false
                      }
                      onClick={() => {
                        icon.func(item);
                      }}
                      caption={icon.displayName ?? ''}
                    />
                  </span>
                ))}
                {viewOptions.columns.map((column) => (
                  <div
                    className="cell"
                    key={column.propertyName}
                    style={{
                      textAlign: column?.align,
                    }}
                    onMouseEnter={(e) =>
                      showTooltip(item[column.propertyName], e.currentTarget)
                    }
                    onMouseLeave={hideTooltip}
                  >
                    {onRowClick && !column.readonly && (
                      <a onClick={() => onRowClick(item)}>
                        {item[column.propertyName]}
                      </a>
                    )}
                    {(!onRowClick || column.readonly) && (
                      <span>{item[column.propertyName]}</span>
                    )}
                  </div>
                ))}
                {!viewOptions.readonly && onDelete && (
                  <IconButton
                    name="delete"
                    buttonType="danger"
                    iconType="clear"
                    onClick={() => onDelete(item)}
                  />
                )}
              </li>
            );
          })}
        </ul>
      </div>
      {!viewOptions.omitFooter && (
        <div className="list-footer">
          <span className="total">{`全 ${data.length} 件`}</span>
          {onFullDownLoad && data.length > 0 && (
            <a
              className="download"
              onClick={() => {
                onFullDownLoad();
              }}
            >
              全件確認
            </a>
          )}
        </div>
      )}
    </div>
  );
};
