import {
  ViewId,
  getDateFormatWithTimezone,
  StringDateToNumber,
} from '~/shared/utils';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { SimpleIconListView } from '~/shared/components/ui';
import { GetMessageWithIntl } from '~/shared/components';
import {
  CertificateRegistrationDialog,
  CertificateRegistrationDialogInputOption,
} from '~/shared/components/ui/Dialog/CertificateRegistrationDialog';

interface CertificateTabItemProps {
  businessUnitCertificates?: mtechnavi.api.company.IBusinessUnitCertificate[];
}

interface ViewCertificateType {
  businessUnitCertificateId: string; // Key
  displayName: string; // 認証名
  obtained: string; // 取得状況
  obtainedDt: string; //取得年月日
  expirationDt: string; // 有効期限
  scope: string; // 適用範囲
}

const VIEW_ID: ViewId = 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION';

const certificateColumns = [
  {
    header: {
      id: 'certificate.displayName',
      prefixId: VIEW_ID,
    },
    propertyName: 'displayName',
    width: '20%',
  },
  {
    header: {
      id: 'certificate.obtained',
      prefixId: VIEW_ID,
    },
    propertyName: 'obtained',
    width: '6rem',
  },
  {
    header: {
      id: 'certificate.obtainedDt',
      prefixId: VIEW_ID,
    },
    propertyName: 'obtainedDt',
    width: '8rem',
  },
  {
    header: {
      id: 'certificate.expirationDt',
      prefixId: VIEW_ID,
    },
    propertyName: 'expirationDt',
    width: '8rem',
  },
  {
    header: {
      id: 'certificate.scope',
      prefixId: VIEW_ID,
    },
    propertyName: 'scope',
  },
];

type sortCategory = 'obtainedDt' | 'expirationDt' | 'certificationName';

export const CertificateTabItem = ({
  businessUnitCertificates,
}: CertificateTabItemProps) => {
  const intl = useIntl();

  const [certificateItems, setCertificateItems] = useState<
    ViewCertificateType[]
  >([]);
  const [certificateFormItems, setCertificateFormItems] =
    useState<CertificateRegistrationDialogInputOption>();
  const [isCertificateDialogOpen, setCertificateDialogOpen] = useState(false);

  // 認証情報変更時
  useEffect(() => {
    if (!businessUnitCertificates) {
      setCertificateItems([]);
      return;
    }
    const vals: ViewCertificateType[] = [];
    businessUnitCertificates.map((v) => {
      vals.push({
        businessUnitCertificateId: v.businessUnitCertificateId ?? '',
        displayName: v.displayName
          ? v.displayName
          : v.certificate!.displayNameLang!.ja ?? '',
        obtained: v.obtained ? '○' : '-',
        obtainedDt: v.obtainedDt ? getDateFormatWithTimezone(v.obtainedDt) : '',
        expirationDt: v.expirationDt
          ? getDateFormatWithTimezone(v.expirationDt)
          : '',
        scope:
          (v.scopeProduct ? v.scopeProduct + ',' : '') +
          (v.scopeBranch ? v.scopeBranch : ''),
      });
    });
    // 初期ソート（有効期限、降順）
    vals.sort(
      (val1, val2) =>
        StringDateToNumber(val2.expirationDt) -
        StringDateToNumber(val1.expirationDt)
    );
    setCertificateItems(vals);

    // businessUnitCertificates 変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessUnitCertificates]);

  // [実行処理]認証情報確認ダイアログ
  const handleCertificatesDialog = (v: ViewCertificateType) => {
    const formItem: mtechnavi.api.company.IBusinessUnitCertificate =
      businessUnitCertificates?.find(
        (w) => w.businessUnitCertificateId === v.businessUnitCertificateId
      ) ?? {};
    if (formItem) {
      setCertificateFormItems({
        displayModeType: 'display',
        businessUnitCertificate: formItem,
      });
    }
    setCertificateDialogOpen(true);
  };

  // ソート
  const sortCertificateItems = (sortCategory: sortCategory, desc: boolean) => {
    if (sortCategory === 'expirationDt') {
      if (desc) {
        const sortData = certificateItems;
        const sortedData = sortData.sort(
          (val1, val2) =>
            StringDateToNumber(val2.expirationDt) -
            StringDateToNumber(val1.expirationDt)
        );
        setCertificateItems(
          sortedData.map(
            (v): ViewCertificateType => ({
              businessUnitCertificateId: v.businessUnitCertificateId,
              displayName: v.displayName,
              obtained: v.obtained,
              obtainedDt: v.obtainedDt,
              expirationDt: v.expirationDt,
              scope: v.scope,
            })
          )
        );
      } else {
        const sortData = certificateItems;
        const sortedData = sortData.sort(
          (val1, val2) =>
            StringDateToNumber(val1.expirationDt) -
            StringDateToNumber(val2.expirationDt)
        );
        setCertificateItems(
          sortedData.map(
            (v): ViewCertificateType => ({
              businessUnitCertificateId: v.businessUnitCertificateId,
              displayName: v.displayName,
              obtained: v.obtained,
              obtainedDt: v.obtainedDt,
              expirationDt: v.expirationDt,
              scope: v.scope,
            })
          )
        );
      }
      return;
    }
    if (sortCategory === 'obtainedDt') {
      if (desc) {
        const sortData = certificateItems;
        const sortedData = sortData.sort(
          (val1, val2) =>
            StringDateToNumber(val2.obtainedDt) -
            StringDateToNumber(val1.obtainedDt)
        );
        setCertificateItems(
          sortedData.map(
            (v): ViewCertificateType => ({
              businessUnitCertificateId: v.businessUnitCertificateId,
              displayName: v.displayName,
              obtained: v.obtained,
              obtainedDt: v.obtainedDt,
              expirationDt: v.expirationDt,
              scope: v.scope,
            })
          )
        );
      } else {
        const sortData = certificateItems;
        const sortedData = sortData.sort(
          (val1, val2) =>
            StringDateToNumber(val1.obtainedDt) -
            StringDateToNumber(val2.obtainedDt)
        );
        setCertificateItems(
          sortedData.map(
            (v): ViewCertificateType => ({
              businessUnitCertificateId: v.businessUnitCertificateId,
              displayName: v.displayName,
              obtained: v.obtained,
              obtainedDt: v.obtainedDt,
              expirationDt: v.expirationDt,
              scope: v.scope,
            })
          )
        );
      }
      return;
    }
    if (sortCategory === 'certificationName') {
      if (desc) {
        const sortData = certificateItems;
        const sortedData = sortData.sort((val1, val2) =>
          val2.displayName.localeCompare(val1.displayName, 'ja')
        );
        setCertificateItems(
          sortedData.map(
            (v): ViewCertificateType => ({
              businessUnitCertificateId: v.businessUnitCertificateId,
              displayName: v.displayName,
              obtained: v.obtained,
              obtainedDt: v.obtainedDt,
              expirationDt: v.expirationDt,
              scope: v.scope,
            })
          )
        );
      } else {
        const sortData = certificateItems;
        const sortedData = sortData.sort((val1, val2) =>
          val1.displayName.localeCompare(val2.displayName, 'ja')
        );
        setCertificateItems(
          sortedData.map(
            (v): ViewCertificateType => ({
              businessUnitCertificateId: v.businessUnitCertificateId,
              displayName: v.displayName,
              obtained: v.obtained,
              obtainedDt: v.obtainedDt,
              expirationDt: v.expirationDt,
              scope: v.scope,
            })
          )
        );
      }
    }
  };

  return (
    <>
      <div className="tab-info-body">
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              <div className="sort-link-area">
                <span className="sort-label">
                  {GetMessageWithIntl(intl, {
                    id: 'certificate.sort.label',
                    prefixId: 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION',
                  })}
                </span>
                <div
                  className="sort-link expiredDt"
                  onClick={() => sortCertificateItems('expirationDt', true)}
                >
                  {GetMessageWithIntl(intl, {
                    id: 'certificate.sort.expiredDtDesc',
                    prefixId: 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION',
                  })}
                </div>
                <div
                  className="sort-link obtainedDt"
                  onClick={() => sortCertificateItems('obtainedDt', true)}
                >
                  {GetMessageWithIntl(intl, {
                    id: 'certificate.sort.obtainedDtDesc',
                    prefixId: 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION',
                  })}
                </div>
                <div
                  className="sort-link certificationName"
                  onClick={() =>
                    sortCertificateItems('certificationName', false)
                  }
                >
                  {GetMessageWithIntl(intl, {
                    id: 'certificate.sort.certificationNameAsc',
                    prefixId: 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION',
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              <SimpleIconListView
                data={certificateItems}
                viewOptions={{
                  previewRowCount: 10,
                  keyColumn: 'businessUnitCertificateId',
                  columns: certificateColumns,
                  omitFooter: true,
                }}
                actionOptions={{}}
                iconMenuOptions={{
                  iconMenu: [
                    {
                      name: 'description',
                      displayName: '確認',
                      func: (v: ViewCertificateType) => {
                        handleCertificatesDialog(v);
                      },
                    },
                  ],
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {/* 認証情報確認ダイアログ */}
      <CertificateRegistrationDialog
        isOpen={isCertificateDialogOpen}
        inputOption={certificateFormItems}
        onCancel={() => {
          setCertificateDialogOpen(false);
        }}
        onDecision={() => {}}
        messageOption={{}}
      />
    </>
  );
};
