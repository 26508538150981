import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface SurveyAutoReminderRequest {
  actionName: 'surveyAutoReminder';
  request: SurveyAutoReminderParam;
}
interface SurveyAutoReminderParam {
  surveyRequestId: string;
  autoReminderSetting: mtechnavi.api.survey.IAutoReminderSetting;
}

// 自動催促
export const surveyAutoReminder = async (
  worker: AppSaveWorker,
  req: SurveyAutoReminderParam
) => {
  const FullMethodName_GetSurveyRequest =
    'mtechnavi.api.survey.SurveySenderService/GetSurveyRequest';
  const FullMethodName_UpdateSurveyRequest =
    'mtechnavi.api.survey.SurveySenderService/UpdateSurveyRequest';

  const survey = await worker.invokeListRequest<
    mtechnavi.api.survey.IGetSurveyRequestRequest,
    mtechnavi.api.survey.SurveyRequest
  >(FullMethodName_GetSurveyRequest, {
    surveyRequestId: req.surveyRequestId,
  });

  return await worker.invokeListRequest<
    mtechnavi.api.survey.IUpdateSurveyRequestRequest,
    mtechnavi.api.survey.SurveyRequest
  >(FullMethodName_UpdateSurveyRequest, {
    surveyRequest: {
      ...survey[0],
      autoReminderSettings: [req.autoReminderSetting],
    },
  });
};
