import { useState, useEffect, useMemo, useRef } from 'react';
import { Container, error, Toast, LoadingIcon } from '~/shared/components';
import {
  ListView,
  Preset,
  MenuActionItem,
  ViewMenu,
  Property,
  ConfirmationDialog,
  getAltDisplaySchema,
  getBooleanDataFormetterSchema,
} from '~/shared/components/ui';
import { PresetItem, Property as PresetProperty } from '~/shared/services';
import { useNavigate } from 'react-router-dom';
import { ImportDialogHandleFormat } from '~/shared/components/ui/Dialog/ImportDialog';
import { useIntl } from 'react-intl';
import { mtechnavi } from '~/shared/libs/clientsdk';
import {
  PageState,
  getExceptionMessage,
  getPresetAndSchema,
  FullMethodName_ListProgramOptions,
} from '~/shared/utils';
const VIEW_ID = 'EXTEND_MASTER_PROGRAMOPTION_LIST';

type ProgramOption = mtechnavi.api.programoption.ProgramOption;
export function ExtendMasterProgramOptionList() {
  const [isLoading, setLoading] = useState(false);
  const intl = useIntl();
  const navi = useNavigate();
  const [childrenPresetItem, setChildrenPresetItem] = useState<PresetItem[]>();
  const [schema, setSchema] = useState<Property[]>([]);
  const [preset, setPreset] = useState<Preset>({
    filter: {},
    propertyNames: [],
  });

  const [menuModalIsOpen, setMenuModalIsOpen] = useState(false);
  const handleExport: ImportDialogHandleFormat = {
    name: 'masterProgramOpiton',
    headerColumns: [],
  };

  const handleImport: ImportDialogHandleFormat = {
    name: 'masterProgramOpiton',
    headerColumns: [],
  };
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogPreset, setDialogPreset] = useState<PresetProperty[]>([
    { name: '', propertyName: '', propertyValue: '' },
  ]);
  const deleteMessage = {
    id: 'C0000001',
    value: { $1: '「削除」' },
  };
  const masterProgramOptionList = useRef<ProgramOption[]>([]);
  const confirmationDialogInfo = {
    id: '',
    msg: deleteMessage,
    func: () => {},
  };

  useMemo(() => {
    const dialogPreset = childrenPresetItem?.find(
      (v) => v.name === 'ProgramOptionList'
    );
    setDialogPreset(
      dialogPreset?.property ?? [
        { name: '', propertyName: '', propertyValue: '' },
      ]
    );

    // preset 変更時に起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preset, childrenPresetItem]);
  useEffect(() => {
    (async () => {
      try {
        // スキーマ情報、preset関係の情報を取得
        const { childrenPresetItem, schemas, preset } =
          await getPresetAndSchema(VIEW_ID, [
            FullMethodName_ListProgramOptions,
          ]);

        // ja表示のみする処理
        const jaColumn = [
          'displayNameLang',
          'category.displayNameLang',
          'system.displayNameLang',
        ];
        const formatterSch = getAltDisplaySchema(schemas[0], jaColumn, 'ja');
        const booleanColumn = ['userEditable'];
        const booleanSch = getBooleanDataFormetterSchema(
          formatterSch,
          booleanColumn
        );
        // 取得した情報をセット
        setChildrenPresetItem(childrenPresetItem);
        setSchema(booleanSch);
        setPreset(preset);
      } catch (err) {
        error(getExceptionMessage(intl, err));
        throw err;
      }
    })();
  }, [intl]);

  // メニューの「確認」処理
  const handleMenuConfirm = (prop?: string[]) => {
    const state: PageState = {
      ids: prop ?? [],
    };
    navi('/master-programoption-confirmation', {
      state,
    });
  };

  // 使用されていないためコメントアウト 2024/4/15
  // メニューの「編集」処理
  // const handleMenuEdit = (prop?: string[]) => {
  //   const state: PageState = {
  //     ids: prop ?? [],
  //     actionType: 'edit',
  //   };
  //   navi('/master-programoption-input', {
  //     state,
  //   });
  // };

  // 使用されていないためコメントアウト 2024/4/15
  // メニューの「複写追加」処理
  // const handleMenuCopy = (prop?: string[]) => {
  //   const state: PageState = {
  //     ids: prop ?? [],
  //     actionType: 'copy',
  //   };
  //   navi('/master-programoption-input', {
  //     state,
  //   });
  // };

  // 使用されていないためコメントアウト 2024/4/15
  // const handleMenuImport = () => {
  //   // 取引ダイアログを表示
  //   setIsDialogOpen(true);
  // };

  // 使用されていないためコメントアウト 2024/4/15
  // const deleteAction = async (selectedProgramOption: ProgramOption) => {
  //   setLoading(true);
  //   setReload(false);
  //   try {
  //     //KEY（種別コード＋システムコード）で集計
  //     const filteredLatestTargetProgramOptions =
  //       (await window.App.services.ui.worker.filter({
  //         action: 'reload',
  //         fullMethodName: FullMethodName_ListProgramOptions,
  //         filter: {
  //           $and: [
  //             { categoryName: { $eq: selectedProgramOption.categoryName } },
  //             { systemName: { $eq: selectedProgramOption.systemName } },
  //           ],
  //         },
  //         sort: [],
  //       })) as mtechnavi.api.programoption.ListProgramOptionsResponse;

  //     //件数が1件になった状態で該当明細の削除する場合はエラーを表示して続行不可
  //     if (filteredLatestTargetProgramOptions.items.length <= 1) {
  //       error([GetMessageWithIntl(intl, { id: 'E0000063' })]);
  //       return;
  //     }

  //     //選択された最新のProgramOption
  //     const selectedLatestProgramOption =
  //       filteredLatestTargetProgramOptions.items.filter((item) => {
  //         return (
  //           item.programOptionId?.includes(
  //             selectedProgramOption.programOptionId
  //           ) && item.userEditable
  //         );
  //       });

  //     //選択されたものと最新を比較して編集不可に変更されている場合はエラーを表示して続行不可
  //     if (selectedLatestProgramOption.length === 0) {
  //       error([GetMessageWithIntl(intl, { id: 'E0000064' })]);
  //       return;
  //     }

  //     const res =
  //       await window.App.services.programOptionService.deleteProgramOption({
  //         programOption: selectedProgramOption,
  //       });
  //     if (res) {
  //       window.App.programoptions = await getProgramOptions();
  //       success([successMessage]);
  //       setMenuModalIsOpen(false);
  //       setReload(true);
  //     }
  //   } catch (err) {
  //     error(getExceptionMessage(intl, err));
  //     throw err;
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const setMenuActionItem = (): MenuActionItem[] => {
    const menuActionItems: MenuActionItem[] = [];
    menuActionItems.push({
      menuActionType: 'headerIconMenu',
      menu: headerIconEvent(),
      maxMenuColumn: 5,
    });
    menuActionItems.push({
      menuActionType: 'listIconMenu',
      menu: listIconEvent(),
    });
    menuActionItems.push({
      menuActionType: 'footerMenu',
      menu: footerMenuEvent(),
    });
    return menuActionItems;
  };

  const headerIconEvent = (): ViewMenu[] => {
    const menuItems: ViewMenu[] = [];
    // 追加
    menuItems.push({
      name: 'noteadd',
      func: () => {
        const req: mtechnavi.api.survey.ISurveyRequestCatalog[] = [
          {
            surveyRequestCatalogId: null,
            code: 'C-01',
            displayName: 'カタログ1',
            category: null,
            guidance: '',
            approval: false,
            completeCondition: null,
            partialReply: false,
            decline: false,
            autoReminderSettings: null,
            requesters: [],
            commonAttachments: [],
            surveyRequestCatalogUpdatedAt: null,
            surveyRequestCatalogUpdatedBy: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
          },
        ];
        try {
          window.App.services.ui.worker.apiCall({
            actionName: 'saveCatalog',
            request: req,
          });
        } catch (err) {
          getExceptionMessage(intl, err);
        }
      },
    });
    // 確認
    menuItems.push({
      name: 'description',
      func: async () => {
        // 依頼カタログリストを表示
        // 未使用のためコメントアウト2024/04/16
        // const listFullMethodName =
        //   'mtechnavi.api.survey.SurveySenderService/ListSurveyRequestCatalogs';
        // 未使用のためコメントアウト2024/04/16
        // const targetRes = (await window.App.services.ui.worker.filter({
        //   action: 'reload',
        //   fullMethodName: listFullMethodName,
        //   filter: {},
        //   sort: [],
        // })) as mtechnavi.api.survey.ListSurveyRequestCatalogsResponse;
        //handleMenuConfirm(v)
      },
    });
    // ダウンロード
    menuItems.push({
      name: 'download',
      func: () => {},
    });
    // 削除
    menuItems.push({
      name: 'delete',
      func: () => {
        setLoading(true);
        try {
          window.App.services.ui.worker.apiCall({
            actionName: 'delete',
            request: [],
          });
        } catch (err) {
          getExceptionMessage(intl, err);
        }
        setLoading(false);
      },
    });

    return menuItems;
  };

  const listIconEvent = (): ViewMenu[] => {
    const menuItems: ViewMenu[] = [];
    // 確認
    menuItems.push({
      name: 'description',
      func: (v?: string[]) => handleMenuConfirm(v),
    });
    // ダウンロード
    menuItems.push({
      name: 'download',
      func: () => {},
    });
    return menuItems;
  };

  const footerMenuEvent = (): ViewMenu[] => {
    const menuItems: ViewMenu[] = [];
    // 終了
    menuItems.push({
      name: 'complete',
      func: () => {},
    });
    return menuItems;
  };

  const onOriginalItem = (items: unknown) => {
    masterProgramOptionList.current = items as unknown as ProgramOption[];
  };

  return (
    <>
      <Container viewId={VIEW_ID}>
        <ListView
          pageInfo={{
            schema: schema,
            preset: preset,
            menuItem: setMenuActionItem(),
            menuTarget: 'programOptionId',
            headerTitle: { viewId: VIEW_ID },
            presetItems: childrenPresetItem,
            listSkipType: {
              isTotal: true,
              isOutput: true,
              isListActionMenu: true,
            },
          }}
          fullMethodName={FullMethodName_ListProgramOptions}
          importDialogOption={{
            isDisplay: true,
            isDialogOpen,
            dialogPreset,
            headerLabelId: {
              viewId: VIEW_ID,
              id: 'import',
            },
            handleExport,
            handleImport,
            onChangeState: setIsDialogOpen,
          }}
          stateOption={{
            onOriginalItemState: onOriginalItem,
          }}
        />
        <ConfirmationDialog
          isOpen={menuModalIsOpen}
          viewMessage={confirmationDialogInfo.msg}
          messageLabelId={{ prefixId: 'DIALOG_MESSAGE', viewId: VIEW_ID }}
          onDecision={() => {
            confirmationDialogInfo.func();
          }}
          onCancel={() => setMenuModalIsOpen(false)}
        />
        <Toast />
      </Container>
      {isLoading && <LoadingIcon />}
    </>
  );
}
