import dayjs from 'dayjs';
import Long from 'long';
export type Formatter = (value: object) => string;
import { convertLongToString } from '~/shared/utils';

export interface FormatterOptions {
  toString?: (v: unknown) => string;

  emptyString?: string;

  // 変換用オプション
  replaceOpts?: ReplaceOptions;

  dayOpts?: DayOptions;
}

export interface JoinFormatterOptions extends FormatterOptions {
  isFlatten?: boolean;
}

export interface DayOptions {
  formatType?: FormatStyle;
  isAccuracy?: boolean;
}

export interface ReplaceOptions {
  isReplace?: boolean;
  beforeValue?: string;
  afterValue?: string;
}

export function joinFormatter(
  sep: string,
  names: string[],
  opts?: JoinFormatterOptions
): Formatter {
  const toStr = opts?.toString ?? toString;
  const emptyString = opts?.emptyString ?? '';
  return (value: object) => {
    const l = opts?.isFlatten
      ? names.flatMap((name) => value[name as keyof typeof value])
      : names.map((name) => value[name as keyof typeof value]);
    return l.filter(hasValue).map(toStr).join(sep) || emptyString;
  };
}

export function joinUserInfoFormatter(
  names: string[],
  opts?: FormatterOptions
): Formatter {
  const toStr = opts?.toString ?? toString;
  const emptyString = opts?.emptyString ?? '';
  return (value: object) => {
    const l = names.map((name) => value[name as keyof typeof value]);
    const userName = toStr(hasValue(l[0]) ? l[0] : '') ?? '';
    const email = toStr(hasValue(l[1]) ? l[1] : '') ?? '';
    return userName && email ? `${userName}(${email})` : emptyString;
  };
}

export type FormatStyle =
  | 'YYYY/MM/DD HH:mm:ss'
  | 'YYYY/MM/DD HH:mm'
  | 'YYYY/MM/DD HH'
  | 'YYYY/MM/DD'
  | 'YYYY/MM'
  | 'YYYY';

// コードからフォーマットを取得するできる様にする
interface AccuracyFormatStyle {
  accuracy?: Number;
  formatStyle?: FormatStyle;
}

// ↓これはaccuracyからフォーマットを取得するための設定値を追加
export const InitAccuracyFormatStyle: AccuracyFormatStyle[] = [
  { accuracy: 0, formatStyle: 'YYYY/MM/DD HH:mm:ss' },
  { accuracy: 1, formatStyle: 'YYYY/MM/DD HH:mm:ss' },
  { accuracy: 2, formatStyle: 'YYYY/MM/DD HH:mm:ss' },
  { accuracy: 3, formatStyle: 'YYYY/MM/DD HH:mm' },
  { accuracy: 4, formatStyle: 'YYYY/MM/DD HH' },
  { accuracy: 5, formatStyle: 'YYYY/MM/DD' },
  { accuracy: 6, formatStyle: 'YYYY/MM' },
  { accuracy: 7, formatStyle: 'YYYY' },
];

export function distantAmountNumber(name: string) {
  return (value: object) => {
    if (!value) return '';
    const fractionalAmount = value[
      `${name}.fractionalAmount` as keyof typeof value
    ] as Long;
    const integralAmount = value[
      `${name}.integralAmount` as keyof typeof value
    ] as number;
    if (integralAmount && fractionalAmount) {
      return Number(
        `${integralAmount}.${String(fractionalAmount).slice(0, 4)}`
      ).toLocaleString(undefined, { maximumFractionDigits: 20 });
    } else if (integralAmount) {
      return Number(integralAmount).toLocaleString(undefined, {
        maximumFractionDigits: 20,
      });
    } else {
      return '';
    }
  };
}

// stringを3桁区切り表示に変換
export function commaTypeNumber(name: string) {
  return (value: object): string => {
    const v = value[name as keyof typeof value] as string;
    const num = Number(v);

    if (Number.isNaN(num)) {
      return '';
    } else {
      return num.toLocaleString(undefined, {
        maximumFractionDigits: 20,
      });
    }
  };
}

export function dayFormatter(opts: FormatterOptions, name: string) {
  return (value: object) => {
    const isAccuracy = opts.dayOpts?.isAccuracy ?? false;
    let formatStyle = 'YYYY/MM/DD';
    const targetName = isAccuracy ? `${name}.timestamp` : name;
    if (isAccuracy) {
      const accuracy = value[`${name}.accuracy` as keyof typeof value];
      const accuracyFormatStyle = InitAccuracyFormatStyle.filter((v) => {
        return v.accuracy === accuracy;
      });
      if (accuracyFormatStyle.length > 0) {
        formatStyle = accuracyFormatStyle[0].formatStyle ?? 'YYYY/MM/DD';
      }
    } else {
      formatStyle = opts.dayOpts?.formatType ?? 'YYYY/MM/DD';
    }
    const v: Long = value[targetName as keyof typeof value];
    // 日時処理に失敗すれば空文字を返す
    if (!v) {
      return '';
    }
    const day = new Date(Number(v) / 1000);
    return dayjs(day).format(formatStyle);
  };
}

/*
 特定カラムをそれの表示用カラムに読み替えるformatter
 例外として、jaとja-kanaを一覧で表示する場合、読み替えられないため
 特定カラム名を変換できるようにする
 例：displayNameLangをdisplayNameLang.jaに読み替え
 */
export function alterDisplayFormatter(
  alter: string,
  name: string,
  opts?: FormatterOptions
): Formatter {
  const emptyString = opts?.emptyString ?? '';
  const isReplace = opts?.replaceOpts?.isReplace ?? false;
  let targetName = name;
  if (isReplace) {
    const beforeValue = opts?.replaceOpts?.beforeValue ?? 'Kana';
    const afterValue = opts?.replaceOpts?.afterValue ?? '';
    if (name === beforeValue) {
      targetName = name.replace(beforeValue, afterValue);
    }
  }

  return (value: object) => {
    const v = value[`${targetName}.${alter}` as keyof typeof value];
    if (Array.isArray(v)) {
      return (v as string[]).join(',');
    }
    return v || emptyString;
  };
}
export function booleanDataFormatter(
  name: string,
  formatToString: boolean
): Formatter {
  return (value: object): string => {
    const booleanData = value[name as keyof typeof value] as boolean;
    if (formatToString) {
      return booleanData ? '完了' : '未完了';
    }
    return booleanData ? '○' : '-';
  };
}

export function coalesceFormatter(
  names: string[],
  opts?: FormatterOptions
): Formatter {
  return defaultFormatter(names, opts);
}

export function defaultFormatter(
  names: string[],
  opts?: FormatterOptions
): Formatter {
  const toStr = opts?.toString ?? toString;
  const emptyString = opts?.emptyString ?? '';
  return (value: object) => {
    const l = names.map((name) => value[name as keyof typeof value]);
    return toStr(l.find(hasValue)) || emptyString;
  };
}

function toString(v: unknown): string {
  return `${v ?? ''}`;
}

function hasValue(v: unknown): boolean {
  return v !== null && v !== undefined;
}

export function longNullZeroFormatter(name: string): Formatter {
  return (value: object): string => {
    const longData = value[name as keyof typeof value] as Long;
    if (longData) {
      return convertLongToString(longData);
    }
    return '0';
  };
}
