/**
 * API 由来の型を UI 向けの型に変換する処理
 */

import { DataFilterboxItem, FilterboxItem } from '~/shared/components/ui';
import { mtechnavi, sharelib } from '~/shared/libs/clientsdk';
import {
  CategorizedBranchData,
  CategorizedList,
  CategorizedTipData,
} from '~/shared/components/ui/CategorizedCheckList';
import { checkEmptyObject } from '../util';
import { getParentOrganization } from './sharelib';

// APIから得たデータ（UserReference型）をFilterboxItem型に変換
export function convertUserReferenceToFilterboxItem(
  userReference: sharelib.IUserReference | null | undefined
): FilterboxItem | undefined {
  if (checkEmptyObject(userReference)) {
    return undefined;
  }

  return {
    value: userReference!.email ?? '',
    displayName: userReference!.displayName ?? '',
  };
}

/**
 * APIから得たデータ（OrganizationStructureReference型）をFilterboxItem型に変換
 * 名称フォーマットは組織コードあり
 * @param organizationReference
 * @returns DataFilterboxItem | undefined
 */
export function convertOrganizationStructureReferenceToFilterboxItem(
  organizationReference:
    | sharelib.IOrganizationStructureReference
    | null
    | undefined
): DataFilterboxItem | undefined {
  if (checkEmptyObject(organizationReference)) {
    return undefined;
  }

  const parentOrganizations: string[] = [];
  (organizationReference?.parentOrganization
    ? organizationReference?.parentOrganization
    : []
  )?.map((item: sharelib.IOrganizationReference) => {
    if (item.displayNameShort) {
      parentOrganizations.push(item.displayNameShort);
    }
  });
  parentOrganizations.push(organizationReference?.displayNameShort ?? '');
  const displayName =
    parentOrganizations.length > 0
      ? `${parentOrganizations.join(' > ')} (${
          organizationReference?.code ?? ''
        })`
      : '';

  if (!organizationReference?.organizationId) {
    return undefined;
  }
  return {
    value: organizationReference.organizationId ?? '',
    displayName,
  };
}

/**
 * APIから得たデータ（OrganizationStructureReference型）を親組織含む階層式表示名に変換（コードなし）
 * @param organizationReference
 * @returns
 */
export function convertOrganizationStructureReferenceToDisplayName(
  organizationReference:
    | sharelib.IOrganizationStructureReference
    | null
    | undefined
): string {
  if (checkEmptyObject(organizationReference)) {
    return '';
  }

  const parentOrganizations: string[] = [];
  (organizationReference?.parentOrganization
    ? organizationReference?.parentOrganization
    : []
  )?.map((item: sharelib.IOrganizationReference) => {
    if (item.displayNameShort) {
      parentOrganizations.push(item.displayNameShort);
    }
  });
  parentOrganizations.push(organizationReference?.displayNameShort ?? '');
  const displayName =
    parentOrganizations.length > 0 ? parentOrganizations.join(' > ') : '';

  return displayName;
}

/**
 * 組織リストをDataFilterBox用に変換
 * @param organizations 組織リスト
 * @param code value値をコードにする
 */
export const convertOrganizationToDataFilterBox = (
  organizations: mtechnavi.api.company.IOrganization[],
  code?: boolean
) => {
  if (!organizations.length) {
    return [];
  }
  const sortedOrgs = organizations.sort((a, b) =>
    (a.code || '').localeCompare(b.code || '', 'ja')
  );
  const dataFilterBoxItems: DataFilterboxItem[] = [];
  sortedOrgs.map((item) => {
    const parents = getParentOrganization(
      item?.organizationId ?? '',
      organizations,
      []
    );
    const displayName =
      parents.length > 0
        ? parents
            .map((v) => {
              return v.displayNameShort;
            })
            .reverse()
            .join(' > ')
        : item?.displayNameShort;

    if (item?.organizationId && item?.code) {
      const dataFilterBoxItem: DataFilterboxItem = {
        value: code ? item?.code : item?.organizationId,
        displayName: `${displayName} (${item?.code})`,
      };
      dataFilterBoxItems.push(dataFilterBoxItem);
    }
  });
  return dataFilterBoxItems;
};

/**
 * 組織のデータフィルターデータを取得する
 * @param code value値をコードにする
 * @returns DataFilterboxItem[]
 */
export const getOrganizationFilterBoxItems = async (code?: boolean) => {
  const organizations = await window.App.services.ui.listOrganization();
  const result = convertOrganizationToDataFilterBox(organizations, code);
  return result;
};

export function getProgramOptionFilterboxData(categoryName: string) {
  const programOptionRes = window.App.services.ui.getProgramOption(
    categoryName
  ) as mtechnavi.api.programoption.IProgramOption[];

  return programOptionRes.map((v) => ({
    displayName: v.displayNameLang?.ja ?? '',
    value: v.code ?? '',
  }));
}

// NameOptionからFilterboxItemを返す
export function convertNameOptionToFilterboxItem(
  nameOption: sharelib.INameOption | null | undefined
): FilterboxItem | undefined {
  if (checkEmptyObject(nameOption)) {
    return undefined;
  }

  return {
    value: nameOption!.code ?? '',
    displayName: nameOption!.displayNameLang?.ja ?? '',
  };
}

// programOptionをDataFilterboxで利用
export function getProgramOptionFilterboxDataWithSystemName(
  categoryName: string,
  systemName: string
) {
  const programOptionRes =
    window.App.services.ui.getProgramOptionWithSystemName(
      categoryName,
      systemName
    ) as mtechnavi.api.programoption.IProgramOption[];

  return programOptionRes.map((v) => ({
    displayName: v.displayNameLang?.ja ?? '',
    value: v.code ?? '',
  }));
}

/**
 * BE からのデータを CategorizedCheckList で扱うデータに変換する。
 * カテゴリに識別可能な ID を割り振る。
 */
type SkillTree = mtechnavi.api.company.IBusinessUnitSkillTree;
export type SkillCategoryData = Omit<
  mtechnavi.api.company.IBusinessUnitSkillItem,
  'businessUnitSkillItemId'
>;
export type SkillTipData = Omit<
  mtechnavi.api.company.IBusinessUnitSkillItem,
  'children'
>;
export const convertSkillTreeToCategorizedList = (
  listData: SkillTree
): CategorizedList => {
  return (
    listData.skillItems?.map((category, index): CategorizedBranchData => {
      const id = `category-${index}`;
      return {
        CategorizedItemId: id,
        displayNameLang: category.displayNameLang,
        children: convertSkillTreeChildren(category.children || [], id),
      };
    }) || []
  );
};
const convertSkillTreeChildren = (
  listData: SkillCategoryData[] | SkillTipData[],
  parentId: string
): CategorizedBranchData[] | CategorizedTipData[] => {
  if (isSkillCategoryDataList(listData)) {
    return listData.map((item, index): CategorizedBranchData => {
      const id = `${parentId}-${index}`;
      return {
        CategorizedItemId: id,
        displayNameLang: item.displayNameLang,
        children: convertSkillTreeChildren(item?.children || [], id),
      };
    });
  } else {
    return listData.map(
      (item): CategorizedTipData => ({
        CategorizedItemId: item.businessUnitSkillItemId || '',
        displayNameLang: item.displayNameLang,
      })
    );
  }
};
export const isSkillCategoryDataList = (
  list: unknown[]
): list is SkillCategoryData[] => {
  return list.every(
    (item) =>
      !Object.hasOwn(
        item as SkillCategoryData | SkillTipData,
        'businessUnitSkillItemId'
      )
  );
};

export const replaceProhibitedCharacters = (str: string) => {
  return str.replace(/[¥/:"?<>|\\]/g, '_');
};
