import { Route, Routes } from 'react-router-dom';
import { PrivacyPolicy as PrivacyPolicyPage } from '~/shared/pages/PrivacyPolicy';
import { Help as HelpPage } from '~/shared/pages/Help/Help';

export const SharedRoutes = () => {
  return (
    <Routes>
      <Route
        path="/privacypolicy"
        element={<PrivacyPolicyPage contentsType={'privacypolicy'} />}
      />
      <Route
        path="/term"
        element={<PrivacyPolicyPage contentsType={'term'} />}
      />
      <Route path="/help/*" element={<HelpPage />} />
    </Routes>
  );
};
