import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  GetMessageWithIntl,
  MessageProps,
} from '~/shared/components/parts/Message/Message';
import {
  ModalDialogComponent,
  ModalDialogComponentProps,
} from '~/shared/components/ui/ModalDialog/ModalDialog';
import { CaptionButton } from '~/shared/components/ui/Button/CaptionButton';
import { SimpleListView } from '~/shared/components/ui/SimpleListView';
import './base.css';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { SearchListViewDialog } from '../SearchListViewDialog';
import { ViewId, saveLocalStorageCheckboxData } from '~/shared/utils';
import { FullMethodName_ListUserGroupAttributeContents } from '~/worker';
import { Schema, getAltDisplaySchema } from '~/shared/components/ui';
import { useAuth } from '~/shared/contexts/AuthProvider';

const VIEW_ID: ViewId = 'ADMIN_USER_GROUP_SEARCH';

export interface UserGroup {
  id?: string;
  displayName?: string;
}

export interface BelongGroupEditDialogMessageOption {
  headerLabelId: MessageProps;
  messageLabelId?: MessageProps;
}
export interface BelongGroupEditInputOption {
  userGroups: UserGroup[];
}

export interface BelongGroupEditDialogProps {
  isOpen: boolean;
  messageOption: BelongGroupEditDialogMessageOption;
  inputOption: BelongGroupEditInputOption;
  userAttribute: mtechnavi.api.tenantadmin.IUserAttribute;
  onDecision: (ids: string[]) => void;
  onCancel: () => void;
}

export type ViewItems = mtechnavi.api.tenantadmin.IUserGroupAttributeContent[];

export const BelongGroupEditDialog = ({
  isOpen,
  messageOption,
  inputOption,
  userAttribute,
  onDecision,
  onCancel,
}: BelongGroupEditDialogProps) => {
  const intl = useIntl();
  const [userGroupList, setUserGroupList] = useState<UserGroup[]>([]);
  const myEmail = useAuth().user?.email ?? '';
  const [baseModalIsOpen, setModalIsOpen] = useState(false);
  const handleClose = () => {
    setModalIsOpen(!baseModalIsOpen);
  };

  const clearCheckBox = useCallback(() => {
    saveLocalStorageCheckboxData(VIEW_ID, [], myEmail);
  }, [myEmail]);

  const isSelectAction = (items: ViewItems) => {
    const addItem: UserGroup[] = [];
    items.forEach((item) => {
      if (
        !userGroupList.filter(
          (value) => value.id === item.userGroupAttribute?.userGroupAttributeId
        ).length
      ) {
        addItem.push({
          id: item.userGroupAttribute?.userGroupAttributeId ?? '',
          displayName: item.userGroupAttribute?.userGroup?.displayName ?? '',
        });
      }
    });

    setUserGroupList([...userGroupList, ...addItem]);
    clearCheckBox();
    setModalIsOpen(!baseModalIsOpen);
  };

  const onHandleFormatSchema = (schema: Schema) => {
    // ja表示のみする処理
    const jaColumn = ['status.status.displayNameLang'];
    return getAltDisplaySchema(schema, jaColumn, 'ja');
  };

  const handleRemove = (item: UserGroup) => {
    setUserGroupList(userGroupList.filter((v) => v !== item));
  };

  const handleAllRemove = () => {
    setUserGroupList([]);
  };

  useEffect(() => {
    setUserGroupList(inputOption.userGroups);
  }, [inputOption.userGroups]);

  const view = (
    <div className="manual-reminder-dialog dialog-with-description">
      <div className="description-area">
        <p>
          {messageOption.messageLabelId &&
            GetMessageWithIntl(intl, messageOption.messageLabelId)}
        </p>
      </div>
      <div className="detail-area">
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              アカウント：{userAttribute?.user?.displayName ?? ''}(
              {userAttribute?.user?.email ?? ''})
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-75">
              <SimpleListView
                data={userGroupList}
                viewOptions={{
                  keyColumn: 'id',
                  previewRowCount: 5,
                  columns: [
                    {
                      header: { id: 'belongEditGroup' },
                      propertyName: 'displayName',
                    },
                  ],
                }}
                actionOptions={{
                  onDelete: handleRemove,
                  onDeleteAll: handleAllRemove,
                }}
              />
            </div>
            <div className="w-20">
              <CaptionButton
                name="add"
                buttonType="basic"
                caption="追加"
                onClick={() => setModalIsOpen(true)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="button-area">
        <CaptionButton
          name="cancelBtn"
          buttonType="cancel"
          className="button"
          caption={GetMessageWithIntl(intl, { id: 'cancel' })}
          onClick={() => onCancel()}
        />
        <CaptionButton
          name="sendBtn"
          buttonType="basic"
          className="button"
          caption={GetMessageWithIntl(intl, {
            id: 'save',
          })}
          onClick={() => {
            onDecision(
              userGroupList.length > 0
                ? (userGroupList.map((v) => v.id) as string[])
                : []
            );
          }}
        />
      </div>
    </div>
  );

  const openModalProps: ModalDialogComponentProps = {
    cancel: () => {
      onCancel();
    },
    send: () => {},
    modalIsOpen: isOpen,
    headerLabelId: {
      prefixId: 'DIALOG_TITLE',
      id: 'BelongGroupEdit',
    },
    messageLabelId: {},
    elements: view,
  };
  return (
    <div>
      <ModalDialogComponent {...openModalProps} />
      <SearchListViewDialog
        viewId={VIEW_ID}
        fullMethodName={FullMethodName_ListUserGroupAttributeContents}
        isOpen={baseModalIsOpen}
        headerLabelId={{
          prefixId: 'DIALOG_TITLE',
          viewId: VIEW_ID,
        }}
        onCloseChange={() => handleClose()}
        onSelectChange={(items) =>
          isSelectAction(items as unknown as ViewItems)
        }
        menuTarget="userGroupAttribute.userGroupAttributeId"
        onHandleFormatSchema={onHandleFormatSchema}
      />
    </div>
  );
};
