import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface SaveAdminAccount {
  actionName: 'saveAdminAccount';
  request: mtechnavi.api.tenantadmin.IUserAttribute;
}

export const saveAdminAccount = async (
  worker: AppSaveWorker,
  request: mtechnavi.api.tenantadmin.IUserAttribute
) => {
  const CreateFullMethodName =
    'mtechnavi.api.tenantadmin.TenantAdminService/CreateUserAndUserAttribute';
  const UpdateFullMethodName =
    'mtechnavi.api.tenantadmin.TenantAdminService/UpdateUserAndUserAttribute';

  if (request.userAttributeId) {
    const result = await worker.invokeListRequest(UpdateFullMethodName, {
      user: request.user,
      userAttribute: request,
    });
    return result;
  } else {
    const result = await worker.invokeListRequest(CreateFullMethodName, {
      user: request.user,
      userAttribute: request,
    });
    return result;
  }
};
