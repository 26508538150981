import { useIntl } from 'react-intl';
import { SectionStateProps } from '../ui/TaskListView';
import { useNavigate } from 'react-router-dom';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { movePage } from '~/shared/utils';
import { useEffect, useState } from 'react';
import { GetMessageWithIntl } from '../parts/Message/Message';
import { ESTIMATE_RESULT_CONFIRMATION_URL } from './utils';
import { FullMethodName_UncompletedListEstimateResults } from '~/worker';
import { FilterResult } from '~/worker';

/** 回答側未完了 */
const uncompletedListEstimateResults = async () => {
  try {
    return await window.App.services.ui.worker.filter<mtechnavi.api.estimation.EstimateResult>(
      {
        action: 'reload',
        fullMethodName: FullMethodName_UncompletedListEstimateResults,
        filter: {},
        sort: [],
      }
    );
  } catch (err) {
    console.error('Error UncompletedListEstimateResults', err);
    return Promise.resolve<
      FilterResult<mtechnavi.api.estimation.EstimateResult>
    >({ items: [] });
  }
};

export interface TaskEstimateProps extends SectionStateProps {}

export const TaskEstimate = (props: TaskEstimateProps) => {
  const intl = useIntl();
  const navi = useNavigate();
  const { onChangeLoadingState } = props;

  const [uncompletedResultsIds, setUncompletedResultsIds] = useState<string[]>(
    []
  );

  // 遷移処理
  const handleMovePage = (e: React.MouseEvent, ids: string[], url: string) => {
    e.preventDefault();

    if (!ids.length) return;
    movePage({ ids }, navi, url);
  };

  const callUncompletedListEstimateResults = async () => {
    const { items } = await uncompletedListEstimateResults();
    setUncompletedResultsIds(
      (items ?? []).map((item) => item.estimateResultId ?? '')
    );
  };

  useEffect(() => {
    onChangeLoadingState && onChangeLoadingState(true);
    Promise.all([
      callUncompletedListEstimateResults(), // 解答側未完了
    ]).finally(() => {
      onChangeLoadingState && onChangeLoadingState(false);
    });
    // 正確にローディング状況を伝えることが困難なため、依存関係に含めない
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {uncompletedResultsIds.length > 0 && (
        <div className="TaskList">
          <dl>
            <dt className="title">
              {GetMessageWithIntl(intl, {
                id: 'TaskList.title.estimateResult',
              })}
            </dt>
            {uncompletedResultsIds.length > 0 && (
              <dd>
                <a
                  href="#"
                  className="link"
                  onClick={(e) => {
                    handleMovePage(
                      e,
                      uncompletedResultsIds,
                      ESTIMATE_RESULT_CONFIRMATION_URL
                    );
                  }}
                >
                  {GetMessageWithIntl(intl, {
                    id: 'TaskList.taskItem.uncompletedEstimateResult',
                  })}
                  {`（${uncompletedResultsIds.length}）`}
                </a>
              </dd>
            )}
          </dl>
        </div>
      )}
    </>
  );
};
