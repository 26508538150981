import { useEffect, useMemo, useRef, useState, useCallback } from 'react';
import { useIntl } from 'react-intl';
import {
  Container,
  GetMessageWithIntl,
  Toast,
  error,
  GetMessage,
  success,
} from '~/shared/components';
import {
  DataFilterbox,
  Textbox,
  DataFilterboxItem,
} from '~/shared/components/ui';
import { CaptionButton } from '~/shared/components/ui/Button';
import { mtechnavi, sharelib } from '~/shared/libs/clientsdk';
import {
  FullMethodName_ListItemAutoNameManagements,
  ViewId,
  getExceptionMessage,
  getMaxMainContentsHeight,
  getProgramOptionFilterboxData,
  convertOrganizationStructureReference,
  getOrganizationFilterBoxItems,
} from '~/shared/utils';
import {
  FormBuilder,
  FormPreviewDialog,
  FormPreviewDialogRef,
  IFormSetting,
  IFormSettingItem,
} from '~/shared/components/ui/FormBuilder';
import './FormInputToJSON.css';
import { FilterRequest } from '~/worker';
import { JsonFileIODialog } from '~/shared/components/ui';
import { useLoading } from '~/shared/contexts/LoadingProvider';

const VIEW_ID: ViewId = '';
export const FormInputToJSON = () => {
  const intl = useIntl();
  const { showLoading, hideLoading } = useLoading();
  const toastSuccess = GetMessage({ id: 'I0000001' });

  // 定義情報（JSON）格納制御用
  const formList = useRef<mtechnavi.api.form.IForm[]>([]);
  const formSettingList = useRef<mtechnavi.api.form.IFormSetting[]>([]);
  const formSettingItemList = useRef<mtechnavi.api.form.IFormSettingItem[]>([]);

  const [isShowJsonFileIODialogForm, setShowJsonFileIODialogForm] =
    useState(false);
  const [
    isShowJsonFileIODialogFormSetting,
    setShowJsonFileIODialogFormSetting,
  ] = useState(false);
  const [
    isShowJsonFileIODialogFormSettingItem,
    setShowJsonFileIODialogFormSettingItem,
  ] = useState(false);
  const [formItem, setFormItem] = useState<DataFilterboxItem[]>([]);
  const [formItems, setFormItems] = useState<DataFilterboxItem[]>([]);
  const [isDisabledEditArea, setDisabledEditArea] = useState<boolean>(true);

  // データフィルターボックス制御用
  const PurposeCode = 'A5000004';
  const FormType1Code = 'A5000001';
  const FormType2Code = 'A5000002';
  const FormType3Code = 'A5000003';
  const [purposes, formType1s, formType2s, formType3s] = useMemo<
    [
      DataFilterboxItem[],
      DataFilterboxItem[],
      DataFilterboxItem[],
      DataFilterboxItem[]
    ]
  >(() => {
    const purposes = getProgramOptionFilterboxData(PurposeCode);
    const formType1s = getProgramOptionFilterboxData(FormType1Code);
    const formType2s = getProgramOptionFilterboxData(FormType2Code);
    const formType3s = getProgramOptionFilterboxData(FormType3Code);
    return [purposes, formType1s, formType2s, formType3s];
  }, []);
  const managementOrganizations = useRef<DataFilterboxItem[]>([]);

  // 画面レイアウト
  const [mainContentHeight, setMainContentHeight] = useState('');
  const footerRef = useRef<HTMLDivElement>(null);

  // 画面項目（ヘッダ情報）
  const formAreaRef = useRef(null);
  const [displayName, setDisplayName] = useState('');
  const [purpose, setPurpose] = useState<DataFilterboxItem[]>([]);
  const [formType1, setFormType1] = useState<DataFilterboxItem[]>([]);
  const [formType2, setFormType2] = useState<DataFilterboxItem[]>([]);
  const [formType3, setFormType3] = useState<DataFilterboxItem[]>([]);
  const [managementOrganization, setManagementOrganization] = useState<
    DataFilterboxItem[]
  >([]);
  const [description, setDescription] = useState('');

  const formBuilderAreaRef = useRef<HTMLDivElement>(null);
  const formPreviewDialogRef = useRef<FormPreviewDialogRef>(null);
  // メインデータ
  const formData = useRef<mtechnavi.api.form.IForm>();
  const [formSetting, setFormSetting] = useState<IFormSetting>({
    formPages: [],
  });
  const [formSettingItems, setFormSettingItems] =
    useState<IFormSettingItem[]>();
  const [itemAutoNameManagementItems, setItemAutoNameManagementItems] =
    useState<mtechnavi.api.form.IItemAutoNameManagement[]>([]);

  // [データ取得] 型チェックデータ取得
  const listItemAutoNameManagementsData = async () => {
    try {
      const listOption: FilterRequest = {
        action: 'reload',
        fullMethodName: FullMethodName_ListItemAutoNameManagements,
        filter: {},
        requestBody: {
          baseFormId: formData.current?.formId ?? '',
        },
        sort: [],
      };
      const resList = (await window.App.services.ui.worker.filter(
        listOption
      )) as mtechnavi.api.form.IListItemAutoNameManagementsResponse;
      const listItem: mtechnavi.api.form.IItemAutoNameManagement[] =
        resList.items ?? [];

      if (listItem.length > 0) {
        setItemAutoNameManagementItems(listItem);
      } else {
        setItemAutoNameManagementItems([]);
      }
    } catch (err) {
      error(getExceptionMessage(intl, err));
    }
  };

  const handleFormSettingChange = useCallback((setting: IFormSetting) => {
    setFormSetting(setting || {});
  }, []);

  const handleFormSettingItemsChange = useCallback(
    (settingItems: IFormSettingItem[]) => {
      setFormSettingItems(settingItems);
    },
    []
  );

  const createFormProperties = async () => {
    const resultPurposes: sharelib.INameOption[] = [];
    purpose.map((purpose) => {
      const resultPurpose = window.App.services.ui.getNameOptionWithCode(
        PurposeCode,
        purpose.value ?? ''
      );
      resultPurposes.push(resultPurpose);
    });

    const resultFormType1: sharelib.INameOption = formType1.length
      ? window.App.services.ui.getNameOptionWithCode(
          FormType1Code,
          formType1[0].value ?? ''
        )
      : {};

    const resultFormType2: sharelib.INameOption = formType2.length
      ? window.App.services.ui.getNameOptionWithCode(
          FormType2Code,
          formType2[0].value ?? ''
        )
      : {};

    const resultFormType3: sharelib.INameOption = formType3.length
      ? window.App.services.ui.getNameOptionWithCode(
          FormType3Code,
          formType3[0].value ?? ''
        )
      : {};

    const formProperties: mtechnavi.api.form.IFormProperties = {
      systemForm: true,
      purposes: resultPurposes,
      managementOrganization:
        managementOrganization.length > 0
          ? (await convertOrganizationStructureReference(
              managementOrganization[0].value || ''
            )) ?? {}
          : null,
      displayName: displayName,
      formType1: resultFormType1,
      formType2: resultFormType2,
      formType3: resultFormType3,
      description: description,
    };

    return formProperties;
  };

  // フォームのプレビュー
  const handleFormPreview = async () => {
    if (!formSetting) {
      return;
    }

    formPreviewDialogRef.current?.open(
      await createFormProperties(),
      true,
      formSetting,
      formSettingItems
    );
  };

  // 保存（変数に反映）
  const handleSave = async () => {
    showLoading();
    const anotherForm =
      formList.current?.filter((v) => v.formId !== formData.current?.formId) ??
      [];
    const formProperties = await createFormProperties();
    const newForm = {
      baseFormId: formData.current?.baseFormId,
      formId: formData.current?.formId,
      formProperties: formProperties,
    } as mtechnavi.api.form.IForm;
    formList.current = [...anotherForm, newForm];
    const anotherFormSetting =
      formSettingList.current?.filter(
        (v) => v.formId !== formData.current?.formId
      ) ?? [];
    formSettingList.current = [...anotherFormSetting, formSetting ?? {}];
    const anotherFormSettingItem =
      formSettingItemList.current?.filter(
        (v) => v.formId !== formData.current?.formId
      ) ?? [];
    const newFormSettingItem =
      formSettingItems?.map((v) => {
        return {
          formId: formData.current?.formId,
          formSettingId: formData.current?.formId,
          formSettingItemId: v.formSettingItemId,
          formPageId: v.formPageId,
          item: v.item,
        } as mtechnavi.api.form.IFormSettingItem;
      }) ?? [];
    formSettingItemList.current = [
      ...anotherFormSettingItem,
      ...newFormSettingItem,
    ];

    clearEditArea();
    success([toastSuccess]);
    hideLoading();
  };

  // 画面データ取得
  useEffect(() => {
    try {
      (async () => {
        // 管理組織用のデータを取得する
        managementOrganizations.current = await getOrganizationFilterBoxItems();
      })();
    } catch (err) {
      error(getExceptionMessage(intl, err));
    }

    // 初回だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intl]);

  // リサイズ
  useEffect(() => {
    setMainContentHeight(
      getMaxMainContentsHeight(footerRef.current?.clientHeight ?? 0)
    );
  }, [footerRef.current?.clientHeight]);

  // Form変更時
  useEffect(() => {
    clearEditArea();

    // formItem変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formItem]);

  const clearEditArea = () => {
    // 選択データクリア
    formData.current = {};
    setFormSetting({});
    setFormSettingItems(undefined);

    // 画面項目初期セット
    setDisplayName('');
    setPurpose([]);
    setFormType1([]);
    setFormType2([]);
    setFormType3([]);
    setDescription('');
    setDisabledEditArea(true);
  };

  // 編集開始制御
  const handleDisplayEditArea = async () => {
    try {
      const selectedForm = formList.current?.find(
        (v) => v.formId === formItem.at(0)?.value
      );
      formData.current = selectedForm;

      // 画面項目初期セット
      setDisplayName(formData.current?.formProperties?.displayName ?? '');

      const filterboxItemPurpose = formData.current?.formProperties?.purposes
        ? formData.current?.formProperties?.purposes.map((v) => {
            return {
              value: v.code ?? '',
              displayName: v.displayNameLang?.ja ?? '',
            };
          })
        : [];
      setPurpose(filterboxItemPurpose);

      const filterboxItemFormType1 = formData.current?.formProperties?.formType1
        ? [
            {
              value: formData.current?.formProperties?.formType1?.code ?? '',
              displayName:
                formData.current?.formProperties?.formType1?.displayNameLang
                  ?.ja ?? '',
            },
          ]
        : [];
      setFormType1(filterboxItemFormType1);

      const filterboxItemFormType2 = formData.current?.formProperties?.formType2
        ? [
            {
              value: formData.current?.formProperties?.formType2?.code ?? '',
              displayName:
                formData.current?.formProperties?.formType2?.displayNameLang
                  ?.ja ?? '',
            },
          ]
        : [];
      setFormType2(filterboxItemFormType2);

      const filterboxItemFormType3 = formData.current?.formProperties?.formType3
        ? [
            {
              value: formData.current?.formProperties?.formType3?.code ?? '',
              displayName:
                formData.current?.formProperties?.formType3?.displayNameLang
                  ?.ja ?? '',
            },
          ]
        : [];
      setFormType3(filterboxItemFormType3);

      setDescription(formData.current?.formProperties?.description ?? '');

      // フォーム設定情報を取得する
      // フォーム設定（構成）データ取得
      const fs =
        formSettingList.current?.find(
          (v) => v.formId === formData.current?.formId
        ) ?? {};
      setFormSetting(fs);

      // フォーム設定（項目）データ取得
      const fsi =
        formSettingItemList.current?.filter(
          (v) => v.formId === formData.current?.formId
        ) ?? [];
      setFormSettingItems(fsi);

      // 以降の処理は設定されている場合のみ実行する
      if (!formItem || !formItem.length) {
        return;
      }

      // 型チェック用データ取得
      (async () => {
        await listItemAutoNameManagementsData();
      })();
    } catch (err) {
      error(getExceptionMessage(intl, err));
    }

    setDisabledEditArea(false);
  };

  return (
    <>
      <Container viewId={VIEW_ID}>
        <div className="FormInputToJSON">
          <div className="header">
            <div className="input-line">
              <div className="item-group-100">
                <div className="w-25">
                  <CaptionButton
                    buttonType="basic"
                    caption="Form"
                    name="basic"
                    onClick={() => {
                      setShowJsonFileIODialogForm(true);
                    }}
                  />
                  <p>{`全 ${formList.current.length} 件 (選択中ID ${
                    formData.current?.formId ?? '-'
                  } ) `}</p>
                </div>
                <div className="w-25">
                  <CaptionButton
                    buttonType="basic"
                    caption="FormSetting"
                    name="basic"
                    onClick={() => {
                      setShowJsonFileIODialogFormSetting(true);
                    }}
                  />
                  <p>{`全 ${formSettingList.current.length} 件 (選択中ID ${
                    formSetting.formSettingId ?? '-'
                  } )`}</p>
                </div>
                <div className="w-25">
                  <CaptionButton
                    buttonType="basic"
                    caption="FormSettingItem"
                    name="basic"
                    onClick={() => {
                      setShowJsonFileIODialogFormSettingItem(true);
                    }}
                  />
                  <p>{`全 ${formSettingItemList.current.length} 件 (選択中 ${
                    formSettingItems?.length ?? 0
                  } 件) `}</p>
                </div>
              </div>
            </div>
            <div className="input-line">
              <div className="item-group-100">
                <div className="w-50">
                  <DataFilterbox
                    data={formItems}
                    name="formDataItem"
                    labelId=""
                    columns={['formDataItem']}
                    searchOption={{ targets: 'displayName' }}
                    value={formItem}
                    onChangeState={setFormItem}
                  />
                </div>
                <div className="w-25">
                  <CaptionButton
                    buttonType="basic"
                    caption="編集する"
                    name="basic"
                    onClick={handleDisplayEditArea}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className="scroll-main-contents-area"
            style={{
              maxHeight: mainContentHeight,
            }}
            ref={formAreaRef}
          >
            <div className="input-line">
              <div className="item-group-100">
                <div className="w-50">
                  <Textbox
                    labelId="IFM_FORM_DEFINITION_INPUT.displayName"
                    name="displayName"
                    columns={['displayName']}
                    value={displayName}
                    type="text"
                    validateOption={{ required: true }}
                    onChangeState={setDisplayName}
                    disabled={isDisabledEditArea}
                  />
                </div>
                <div className="w-25">
                  <Textbox
                    labelId="IFM_FORM_DEFINITION_INPUT.systemType"
                    name="systemType"
                    columns={['systemType']}
                    value={GetMessageWithIntl(intl, {
                      id: 'system',
                    })}
                    type="text"
                    disabled={true}
                  />
                </div>
                <div className="w-25">
                  <DataFilterbox
                    data={purposes}
                    name="purposes"
                    labelId="IFM_FORM_DEFINITION_INPUT.purposes"
                    columns={['purposes']}
                    searchOption={{ targets: 'displayName' }}
                    value={purpose}
                    onChangeState={setPurpose}
                    multiple={true}
                    disabled={isDisabledEditArea}
                  />
                </div>
              </div>
            </div>
            <div className="input-line">
              <div className="item-group-100">
                <div className="w-25">
                  <DataFilterbox
                    data={formType1s}
                    name="formType1"
                    labelId="IFM_FORM_DEFINITION_INPUT.formType1"
                    columns={['formType1']}
                    searchOption={{ targets: 'displayName' }}
                    value={formType1}
                    onChangeState={setFormType1}
                    disabled={isDisabledEditArea}
                  />
                </div>
                <div className="w-25">
                  <DataFilterbox
                    data={formType2s}
                    name="formType2"
                    labelId="IFM_FORM_DEFINITION_INPUT.formType2"
                    columns={['formType2']}
                    searchOption={{ targets: 'displayName' }}
                    value={formType2}
                    onChangeState={setFormType2}
                    disabled={isDisabledEditArea}
                  />
                </div>
                <div className="w-25">
                  <DataFilterbox
                    data={formType3s}
                    name="formType3"
                    labelId="IFM_FORM_DEFINITION_INPUT.formType3"
                    columns={['formType3']}
                    searchOption={{ targets: 'displayName' }}
                    value={formType3}
                    onChangeState={setFormType3}
                    disabled={isDisabledEditArea}
                  />
                </div>
                <div className="w-25">
                  <div>
                    <DataFilterbox
                      name="managementOrganization"
                      labelId="IFM_FORM_DEFINITION_INPUT.managementOrganization"
                      columns={['managementOrganization']}
                      data={managementOrganizations.current}
                      value={
                        managementOrganization ?? [
                          { value: '', displayName: '' },
                        ]
                      }
                      searchOption={{
                        targets: 'displayName',
                      }}
                      onChangeState={setManagementOrganization}
                      disabled={isDisabledEditArea}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="input-line">
              <div className="item-group-100">
                <div className="w-66">
                  <Textbox
                    labelId="IFM_FORM_DEFINITION_INPUT.description"
                    name="description"
                    columns={['description']}
                    value={description}
                    type="text"
                    onChangeState={setDescription}
                    disabled={isDisabledEditArea}
                  />
                </div>
              </div>
            </div>
            <div className="form-builder-area" ref={formBuilderAreaRef}>
              <label className="area-label">
                {GetMessageWithIntl(intl, {
                  prefixId: 'IFM_FORM_DEFINITION_INPUT',
                  id: 'formSetting',
                })}
              </label>
              <FormBuilder
                initialFormSetting={formSetting}
                onChangeFormSetting={handleFormSettingChange}
                initialFormSettingItems={formSettingItems}
                onChangeFormSettingItems={handleFormSettingItemsChange}
                itemAutoNameManagementItems={itemAutoNameManagementItems}
              />
            </div>
          </div>
        </div>
        <div className="footer" ref={footerRef}>
          <div className="footer-contents">
            <div className="input-line">
              <CaptionButton
                name="preview"
                buttonType="basic"
                caption={GetMessageWithIntl(intl, { id: 'preview' })}
                onClick={() => handleFormPreview()}
                disabled={isDisabledEditArea}
              />
              <CaptionButton
                name="save"
                buttonType="basic"
                caption={GetMessageWithIntl(intl, { id: 'save' })}
                onClick={handleSave}
                disabled={isDisabledEditArea}
              />
            </div>
          </div>
        </div>
        <FormPreviewDialog ref={formPreviewDialogRef} />
        <JsonFileIODialog
          isOpen={isShowJsonFileIODialogForm}
          inputOption={{
            data: formList.current ?? [],
            filename: 'system_form',
          }}
          onDecision={(v) => {
            console.log(v, 'onDecision');
            if (v.message.length > 0) {
              error(v.message);
            } else {
              formList.current = v.data;
              const items = v.data.map((w) => {
                return {
                  displayName: w.formProperties.displayName,
                  value: w.formId,
                } as DataFilterboxItem;
              });
              setFormItems(items);
              setShowJsonFileIODialogForm(false);
            }
          }}
          onCancel={() => {
            setShowJsonFileIODialogForm(false);
          }}
        />
        <JsonFileIODialog
          isOpen={isShowJsonFileIODialogFormSetting}
          inputOption={{
            data: formSettingList.current ?? [],
            filename: 'system_form_setting',
          }}
          onDecision={(v) => {
            console.log(v, 'onDecision');
            if (v.message.length > 0) {
              error(v.message);
            } else {
              formSettingList.current = v.data;
              setShowJsonFileIODialogFormSetting(false);
            }
          }}
          onCancel={() => {
            setShowJsonFileIODialogFormSetting(false);
          }}
        />
        <JsonFileIODialog
          isOpen={isShowJsonFileIODialogFormSettingItem}
          inputOption={{
            data: formSettingItemList.current ?? [],
            filename: 'system_form_setting_item',
          }}
          onDecision={(v) => {
            console.log(v, 'onDecision');
            if (v.message.length > 0) {
              error(v.message);
            } else {
              formSettingItemList.current = v.data;
              setShowJsonFileIODialogFormSettingItem(false);
            }
          }}
          onCancel={() => {
            setShowJsonFileIODialogFormSettingItem(false);
          }}
        />
        <Toast />
      </Container>
    </>
  );
};
