import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface ExecUncompleteWtTicketRequest {
  actionName: 'uncompleteWtTicket';
  request: mtechnavi.api.worktask.IUncompleteWorkTaskTicketRequest;
}

// 作業再開
export const execUncompleteWtTicket = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.worktask.IUncompleteWorkTaskTicketRequest
) => {
  const fullMethodName =
    'mtechnavi.api.worktask.WorkTaskService/UncompleteWorkTaskTicket';

  return await worker.invokeListRequest<
    mtechnavi.api.worktask.IUncompleteWorkTaskTicketRequest,
    mtechnavi.api.worktask.IUncompleteWorkTaskTicketResponse
  >(fullMethodName, req);
};
