import {
  CategorizedBranchData,
  CategorizedList,
  CategorizedTipData,
} from '~/shared/components/ui/CategorizedCheckList';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { DisplayNameLang } from '~/shared/utils/commonType';
import { MultipleType } from './util';

/**
 * 企業属性データの型情報。
 * API を介さず json で取得するためここで定義しておく。
 */
export interface BusinessUnitAttributes {
  children: BusinessUnitAttributeCategory[];
}
export interface BusinessUnitAttributeCategory {
  attributeCategoryId?: string;
  displayNameLang?: DisplayNameLang;
  children?: BusinessUnitAttributeCategory[] | BusinessUnitAttributeItem[];
}
export interface BusinessUnitAttributeItem {
  attributeItemId?: string;
  displayNameLang?: DisplayNameLang;
}

/**
 * 企業属性の形式である json データを CategorizedCheckList で扱うデータに変換する。
 * カテゴリに識別可能な ID を割り振る。
 */
interface CategoryData {
  attributeCategoryId?: string;
  displayNameLang?: DisplayNameLang;
  children?: CategoryData[];
}
interface TipData {
  attributeItemId?: string;
  displayNameLang?: DisplayNameLang;
}

export const convertToCategorizedList = (items: unknown): CategorizedList => {
  if (!Array.isArray(items)) {
    return [];
  }
  return items.map((item: unknown, index) => {
    const id = `category-${index}`;
    if (!isCategoryData(item)) {
      return {
        CategorizedItemId: id,
        children: [],
      };
    }
    return {
      CategorizedItemId: item.attributeCategoryId || id,
      displayNameLang: item.displayNameLang,
      children: convertToCategorizedListChildren(item.children || [], id),
    };
  });
};
const convertToCategorizedListChildren = (
  items: unknown,
  parentId: string
): CategorizedBranchData[] | CategorizedTipData[] => {
  if (!Array.isArray(items)) {
    return [];
  }
  if (isCategoryDataList(items)) {
    return items.map((item, index): CategorizedBranchData => {
      const id = `${parentId}-${index}`;
      return {
        CategorizedItemId: item.attributeCategoryId || id,
        displayNameLang: item.displayNameLang,
        children: convertToCategorizedListChildren(item?.children || [], id),
      };
    });
  } else {
    return items.map((item: unknown, index): CategorizedTipData => {
      const id = `${parentId}-${index}`;
      if (!isTipData(item)) {
        return {
          CategorizedItemId: id,
        };
      }
      return {
        CategorizedItemId: item.attributeItemId || id,
        displayNameLang: item.displayNameLang,
      };
    });
  }
};
const isCategoryData = (item: unknown): item is CategoryData => {
  return Object.hasOwn(item as Object, 'attributeCategoryId');
};
const isTipData = (item: unknown): item is TipData => {
  return Object.hasOwn(item as Object, 'attributeItemId');
};
const isCategoryDataList = (list: unknown[]): list is CategoryData[] => {
  return list.every((item) =>
    Object.hasOwn(item as CategoryData | TipData, 'children')
  );
};

export const convertMultipleTypeToFilterType = (
  multipleType: MultipleType
): mtechnavi.api.analysis.ListCompanysRequest.DataFilter.FilterType => {
  switch (multipleType) {
    case 'or':
      return mtechnavi.api.analysis.ListCompanysRequest.DataFilter.FilterType
        .OR;
    case 'and':
      return mtechnavi.api.analysis.ListCompanysRequest.DataFilter.FilterType
        .AND;
    default:
      throw new Error(`unknown multipleType ${multipleType}`);
  }
};
export const convertFilterTypeToMultipleType = (
  filterType: mtechnavi.api.analysis.ListCompanysRequest.DataFilter.FilterType
): MultipleType => {
  switch (filterType) {
    case mtechnavi.api.analysis.ListCompanysRequest.DataFilter.FilterType.OR:
      return 'or';
    case mtechnavi.api.analysis.ListCompanysRequest.DataFilter.FilterType.AND:
      return 'and';
    default:
      throw new Error(`unknown multipleType ${filterType}`);
  }
};
