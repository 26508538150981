import { useState, useEffect } from 'react';
import { CaptionButton } from '~/shared/components/ui/Button/CaptionButton';
import { ResponsiblePersonRegistrationDialog } from '~/shared/components/ui/Dialog/ResponsiblePersonRegistrationDialog';
import { mtechnavi } from '~/shared/libs/clientsdk';
import {
  FullMethodName_SharedListBusinessUnitBranchs,
  FullMethodName_ListBusinessUnitBranchs,
  FullMethodName_GetCompany,
} from '~/shared/utils';

export const UISampleResponsiblePersonRegistrationDialogs = () => {
  const [result, setResult] = useState('');
  const [receptionResult, setReceptionResult] = useState('');
  const [isOpenInputDialog, setOpenInputDialog] = useState(false);
  const [isOpenReceptionInputDialog, setOpenReceptionInputDialog] =
    useState(false);
  const [isOpenConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const rankContent: mtechnavi.api.company.IBusinessUnitRankContent = {
    businessUnitRankReception: {},
    businessUnitRankRequest: {},
  };
  const rankReceptionContent: mtechnavi.api.company.IBusinessUnitRankContent = {
    businessUnitRankReception: {
      businessUnitRankReceptionId: 'sample',
    },
    businessUnitRankRequest: {},
  };
  const [rankData, setRankData] =
    useState<mtechnavi.api.company.IBusinessUnitRankContent>({});

  useEffect(() => {
    // 会社マスタ
    async () => {
      await window.App.services.ui.worker.filter({
        action: 'reload',
        fullMethodName: FullMethodName_GetCompany,
        filter: {},
        sort: [],
      });

      // 拠点
      await window.App.services.ui.worker.filter({
        action: 'reload',
        fullMethodName: FullMethodName_SharedListBusinessUnitBranchs,
        filter: {},
        requestBody: {
          // companyIds: companyIds,
        },
        sort: [],
      });

      // 拠点（自社）
      await window.App.services.ui.worker.filter({
        action: 'reload',
        fullMethodName: FullMethodName_ListBusinessUnitBranchs,
        filter: {},
        requestBody: {
          // companyIds: companyIds,
        },
        sort: [],
      });
    };
  }, []);

  return (
    <div className="categories">
      <h3 id="dialog">責任者登録ダイログ</h3>
      <h4>責任者登録ダイアログ</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setResult('');
                setRankData(rankContent);
                setOpenInputDialog(true);
              }}
            />
            <ResponsiblePersonRegistrationDialog
              isOpen={isOpenInputDialog}
              inputOption={{
                displayType: 'edit',
                rankContent: rankData,
                sharedCompanyId: '',
                isLinkedTenant: false,
              }}
              onDecision={() => {
                setResult(`出力しました。`);
                setOpenInputDialog(false);
              }}
              onCancel={() => {
                setResult('キャンセルしました');
                setOpenInputDialog(false);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">{result}</pre>
          </div>
        </div>
      </div>
      <h4>責任者登録ダイアログ（取引先管理（品質責任者）マスタあり）</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setReceptionResult('');
                setRankData(rankReceptionContent);
                setOpenInputDialog(true);
              }}
            />
            <ResponsiblePersonRegistrationDialog
              isOpen={isOpenReceptionInputDialog}
              inputOption={{
                displayType: 'edit',
                rankContent: rankData,
                sharedCompanyId: '',
                isLinkedTenant: false,
              }}
              onDecision={() => {
                setReceptionResult(`出力しました。`);
                setOpenReceptionInputDialog(false);
              }}
              onCancel={() => {
                setReceptionResult('キャンセルしました');
                setOpenReceptionInputDialog(false);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">{receptionResult}</pre>
          </div>
        </div>
      </div>

      <h4>責任者確認ダイアログ</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setRankData(rankContent);
                setOpenConfirmationDialog(true);
              }}
            />
            <ResponsiblePersonRegistrationDialog
              isOpen={isOpenConfirmationDialog}
              inputOption={{
                displayType: 'display',
                rankContent: rankData,
                sharedCompanyId: '',
                isLinkedTenant: false,
              }}
              onDecision={() => {}}
              onCancel={() => {
                setOpenConfirmationDialog(false);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
