import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface UpdateFlagInSurveyRequestRequest {
  actionName: 'updateFlagInSurveyRequest';
  request: mtechnavi.api.survey.IUpdateFlagInSurveyRequestRequest;
}

/**
 * 確認フラグ更新
 */
export const updateFlagInSurveyRequest = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.survey.IUpdateFlagInSurveyRequestRequest
) => {
  const updateFullMethodName =
    'mtechnavi.api.survey.SurveySenderService/UpdateFlagInSurveyRequest';

  return worker.invokeListRequest<
    mtechnavi.api.survey.IUpdateFlagInSurveyRequestRequest,
    mtechnavi.api.survey.ISurveyRequest
  >(updateFullMethodName, req);
};
