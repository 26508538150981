import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface DeleteInformationRequest {
  actionName: 'delete';
  request: mtechnavi.api.admin.IDeleteInformationRequest;
}

export const deleteInformation = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.admin.IDeleteInformationRequest
) => {
  const fullMethodName =
    'mtechnavi.api.admin.InformationService/DeleteInformation';

  return await worker.invokeListRequest(fullMethodName, {
    information: req.information,
  });
};
