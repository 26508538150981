import { useRef, useState } from 'react';
import { GroupSearch } from './GroupSearch';
import {
  GroupTreeRoot,
  GroupTreeGlobalProps,
  GroupTreeRootRef,
} from './GroupTreeRoot';
import { GroupTreeControls } from './GroupTreeControls';
import './GroupTreeEditor.css';
import { groupTreeSearch } from './util';

export type GroupTreeEditorProps<T> = GroupTreeGlobalProps<T> & {
  onImport?: () => void;
};

/**
 * グループツリーエディターコンポーネント
 * 階層構造のデータを閲覧・編集する
 */
export const GroupTreeEditor = <T,>({
  data,
  rootLabel,
  editActions,
  isEnableDnD,
  isEditable,
  onDrop,
  onImport,
  isShowAddChild,
}: GroupTreeEditorProps<T>) => {
  const [isShowDisabled, setShowDisabled] = useState(false);
  const [searchWord, setSearchWord] = useState<string>();
  const ref = useRef<GroupTreeRootRef>(null);

  const handleSearch = () => {
    if (!searchWord) {
      return;
    }
    const findId = groupTreeSearch(data, searchWord);
    ref.current?.focus(findId);
  };
  const handleOpenAll = () => {
    ref.current?.allOpen();
  };
  const onCloseAll = () => {
    ref.current?.allClose();
  };

  return (
    <div className="GroupTreeEditor">
      <GroupSearch onChangeSearchWord={setSearchWord} onSearch={handleSearch} />
      <GroupTreeControls
        isShowDisabled={isShowDisabled}
        onOpenAll={handleOpenAll}
        onCloseAll={onCloseAll}
        onToggleShowDisable={setShowDisabled}
        onImport={onImport}
        isEditable={isEditable}
      />
      <GroupTreeRoot
        rootLabel={rootLabel}
        data={data}
        searchWord={searchWord}
        isShowDisabled={isShowDisabled}
        editActions={editActions}
        onDrop={onDrop}
        ref={ref}
        isEnableDnD={isEnableDnD}
        isEditable={isEditable}
        isShowAddChild={isShowAddChild}
      />
    </div>
  );
};
