import { useEffect, useMemo, useState, CSSProperties, useRef } from 'react';
import { Property } from '~/shared/services';
import { Schema } from '../ListView';
import { getSchema } from '~/shared/components/ui/Util';
import './base.css';
import { ReactComponent as KeyboardArrowRightIcon } from '@material-design-icons/svg/filled/keyboard_arrow_right.svg';
import { ReactComponent as KeyboardArrowLeftIcon } from '@material-design-icons/svg/filled/keyboard_arrow_left.svg';
import { ReactComponent as SearchIcon } from '@material-design-icons/svg/filled/search.svg';
import { ReactComponent as ExpandMoreIcon } from '@material-design-icons/svg/filled/expand_more.svg';
import { ReactComponent as DeleteIcon } from '@material-design-icons/svg/filled/close.svg';
import { ReactComponent as ExpandLessIcon } from '@material-design-icons/svg/filled/expand_less.svg';
import { usePagenator } from '../ListView/pagenator';
import { DummyUser, FilterExpression, SortTerm } from '~/worker';
import {
  GetMessage,
  GetMessageComponent,
} from '~/shared/components/parts/Message/Message';
import { ErrorMessage } from '~/shared/components/parts/ErrorMessage/ErrorMessage';
import { getProperty } from '../common';
import { getErrorBorderClassName } from '~/shared/utils';

export interface FilterboxProps {
  name: string;
  className?: string;
  labelId?: string;
  fullMethodName: string;
  itemType: FilterboxItem;
  multiple?: boolean;
  value?: FilterboxItem[];
  searchOption?: FilterboxSearchOption;
  displayOption?: FilterboxDisplayOption;
  disabled?: boolean;
  validateOption?: FilterboxValidateOption;
  properties?: Property[];
  columns?: string[];
  formatOption?: FilterboxFormatOption;
  onChangeState?: (arg: FilterboxItem[]) => void;
  workingBlur?: Date;
}

// リストの表示項目及び検索オプション項目
export type FilterboxItemOption = keyof FilterboxItem | 'both';

// 検索結果欄の表示項目オプション
interface FilterboxDisplayOption {
  displayedItems?: FilterboxItemOption;
  pageSize?: number;
  sort?: SortTerm[];
}

// 検索結果欄のアイテム構造
export interface FilterboxItem {
  value: string;
  displayName: string;
}

// 検索オプション
interface FilterboxSearchOption {
  targets?: FilterboxItemOption;
  keyword?: string[];
  customQuery?: FilterExpression;
  isLatestData?: boolean; // 常に最新の選択データを取得するかどうか
  appendSearchColumn?: string; // 追加の検索分類
}

// フォーマットオプション
interface FilterboxFormatOption {
  mapKeyValue?: string;
}

// バリデートオプション
interface FilterboxValidateOption {
  isSkippedValidation?: boolean;
  required?: boolean;
}

// MongoDBのfilter用クエリ生成
function getFilterQuery(
  itemType: FilterboxItem,
  targets: FilterboxItemOption,
  keywords: string[],
  customQuery: FilterExpression,
  appendSearchColumn: string
): FilterExpression {
  let query: FilterExpression = {};
  switch (targets) {
    // 片方の項目のみが検索対象
    case 'displayName':
    case 'value':
      if (appendSearchColumn) {
        query = {
          $or: [
            {
              $and: keywords.map((keyword) => ({
                [itemType[targets]]: { $like: keyword },
              })),
            },
            {
              $and: keywords.map((keyword) => ({
                [appendSearchColumn]: { $like: keyword },
              })),
            },
          ],
        };
      } else {
        query = {
          $and: keywords.map((keyword) => ({
            [itemType[targets]]: { $like: keyword },
          })),
        };
      }
      break;
    // 両方の項目が検索対象
    case 'both':
      if (appendSearchColumn) {
        query = {
          $or: [
            {
              $and: keywords.map((keyword) => ({
                [itemType.displayName]: { $like: keyword },
              })),
            },
            {
              $and: keywords.map((keyword) => ({
                [itemType.value]: { $like: keyword },
              })),
            },
            {
              $and: keywords.map((keyword) => ({
                [appendSearchColumn]: { $like: keyword },
              })),
            },
          ],
        };
      } else {
        query = {
          $or: [
            {
              $and: keywords.map((keyword) => ({
                [itemType.displayName]: { $like: keyword },
              })),
            },
            {
              $and: keywords.map((keyword) => ({
                [itemType.value]: { $like: keyword },
              })),
            },
          ],
        };
      }
      break;
  }
  //props.searchOption.customQuery(外部から与えられる絞り込み用のクエリ)が存在すれば併せて絞り込み
  return !!Object.keys(customQuery).length
    ? { $and: [customQuery, query] }
    : query;
}

// workerから取得した情報をFilterboxItem[]形式に変換
function convertFilterboxItems(
  target: DummyUser[],
  schema: Schema,
  { value, displayName }: FilterboxItem,
  formatOption?: FilterboxFormatOption
) {
  // DBのスキーマとprops.itemTypeの突き合わせ
  let valueSchema = '';
  let displayNameSchema = '';
  const mapKeyValue = formatOption?.mapKeyValue ?? '';
  schema.forEach((v) => {
    if (value === v.name) {
      valueSchema = v.name;
    }
    if (displayName === v.name) {
      displayNameSchema = mapKeyValue ? `${v.name}.${mapKeyValue}` : v.name;
    }
  });

  return target.map((v): FilterboxItem => {
    return {
      value: v[valueSchema as keyof typeof v] ?? '',
      displayName: v[displayNameSchema as keyof typeof v] ?? '',
    };
  });
}

// 正しい形式のfilterboxItemを返す
export function getCorrectFilterboxItem(value: FilterboxItem[] | undefined) {
  if (!value) {
    return [];
  }

  // value及びdisplayNameが空文字またはundefinedの場合、そのitemは不正として除去
  // valueまたはdisplayNameが空文字またはundefinedの場合は許容
  return value.filter(
    (v) => (v.displayName ?? '') !== '' || (v.value ?? '') !== ''
  );
}

export const searchItemAreaView = (
  contents: FilterboxItem[],
  handleItemClicked: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void,
  listView: (item: FilterboxItem) => string,
  visibility: string
) => {
  return contents.map((item, index) => {
    if (item.value) {
      return (
        <div
          className={`search-item enable-hover-color ${visibility}`}
          key={item.value + index}
        >
          <div
            onClick={handleItemClicked}
            key={item.value}
            className={`viewItemText`}
            data-value={item.value}
          >
            {listView(item)}
          </div>
          <div className="search-item-description">{listView(item)}</div>
        </div>
      );
    } else {
      return (
        <div className="search-item" key={index}>
          <div className={`viewItemText`}>{item.displayName}</div>
        </div>
      );
    }
  });
};

export function Filterbox(props: FilterboxProps) {
  const REQUIRED_MESSAGE = GetMessage({ id: 'E0000003' });

  const [componentStyle, viewStyle, _disabled] = useMemo<
    [CSSProperties, CSSProperties, boolean]
  >(() => {
    return getProperty(props.name, props.columns, props.properties);

    // props.name, props.column 変更時にのみ起動したいためlint除外
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.name, props.columns]);
  // propsのオプショナルチェック
  const filterboxClassName = props.className ?? '';
  const isMulti = props.multiple ?? false;
  const initKeyWords = props.searchOption?.keyword ?? [];
  const searchTarget = props.searchOption?.targets ?? 'displayName';
  const pageSize = props.displayOption?.pageSize ?? 5;
  const appendSearchColumn = props.searchOption?.appendSearchColumn ?? '';
  const displayItem = props.displayOption?.displayedItems ?? 'displayName';
  const sort = props.displayOption?.sort ?? [
    {
      [props.itemType.displayName]: 'asc',
    },
  ];
  const style = componentStyle ?? {};
  const disabled = _disabled ? _disabled : props.disabled ?? false;
  const isSkippedValidation =
    props.validateOption?.isSkippedValidation ?? false;

  const changeParentState = props.onChangeState || (() => {});

  // DBのカラムを取得
  const schema = useMemo(
    () => getSchema(props.fullMethodName),
    [props.fullMethodName]
  );

  // useState
  const [selected, setSelected] = useState<FilterboxItem[]>(
    getCorrectFilterboxItem(props.value)
  ); //初期選択アイテムがあればセット
  const [items, setItems] = useState<FilterboxItem[]>([]);
  const [keyword, setKeyword] = useState('');
  const [visibility, setVisibility] = useState<'hidden' | 'visible'>('hidden');
  const [customQuery, setCustomQuery] = useState<FilterExpression>(
    props.searchOption?.customQuery ?? {}
  );
  const [message, setMessage] = useState<string[]>([]);

  const keywordInputRef = useRef<HTMLInputElement>(null);

  // pagenator設定 workerへの依頼はdispatchで行う
  const [page, dispatch] = usePagenator({
    fullMethodName: props.fullMethodName,
    pageNumber: 1,
    pageSize: pageSize,
    maxPageNumber: 1,
    filter: getFilterQuery(
      props.itemType,
      searchTarget,
      initKeyWords,
      customQuery,
      appendSearchColumn
    ),
    sort: sort,
    items: [],
  });

  // クリック時の処理
  const handleOpenList = () => {
    if (disabled) {
      return;
    }
    // 検索結果欄表示
    setVisibility('visible');
    setTimeout(() => {
      keywordInputRef.current?.focus();
    }, 0);
  };

  const handleClearKeyword = () => {
    setKeyword('');
    dispatch({
      type: 'query',
      fullMethodName: props.fullMethodName,
      pageNumber: 1,
      filter: getFilterQuery(
        props.itemType,
        searchTarget,
        initKeyWords,
        customQuery,
        appendSearchColumn
      ),
    });
  };

  // フォーカスアウト時の処理
  const handleCloseList = () => {
    // 検索結果欄非表示、検索キーワード初期化
    setVisibility('hidden');
    handleClearKeyword();

    // バリデートを行なってもエラーメッセージは抑制されているので問題ないが、念の為処理をスキップする
    if (!isSkippedValidation) {
      // 必須入力チェック
      const isRequired = props.validateOption?.required ?? false;
      if (isRequired && selected.length === 0) {
        setMessage([REQUIRED_MESSAGE]);
        return;
      }
    }

    setMessage([]);
  };

  /*
   フィルターボックスのボタン押下時の必須チェックを行うために、強制的にフォーカスアウトと同様の処理を行う。
   用途としては承認依頼ダイアログで部門を未選択された状態で確定ボタンを押した時のチェックを行うなど。
  */
  useEffect(() => {
    if (!props.workingBlur) {
      return;
    }
    handleCloseList();
    // handleCloseListをeslint解消すると処理への影響が大きいので最低限で対応すべく除外対象にする
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.workingBlur]);

  // ページ移動
  const handleMovePage = (pageNumber: number) => {
    const n = Math.min(Math.max(1, pageNumber), page.maxPageNumber);
    dispatch({
      type: 'query',
      fullMethodName: props.fullMethodName,
      pageNumber: n,
    });
  };

  // アイテムをクリックした際の処理
  const handleItemClicked = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    const targetValue = event.currentTarget.dataset.value ?? '';
    const item: FilterboxItem = {
      value: targetValue,
      displayName:
        items.find((v) => v.value === targetValue)?.displayName ?? '',
    };

    // 単数選択の場合は選択項目を置き換えて処理終了
    if (!isMulti) {
      setSelected([item]);
      handleCloseList();
      handleClearKeyword(); // 検索ワードを消去
      changeParentState([item]);
      setMessage([]);
      return;
    }

    // valueは一意として重複がないかを確認
    if (!!selected.find((v) => v.value === targetValue)) {
      // 重複があった場合は処理終了
      return;
    }

    setSelected([...selected, item]);
    changeParentState([...selected, item]);
    handleCloseList();
  };

  useEffect(() => {
    setSelected(getCorrectFilterboxItem(props.value));
  }, [props.value]);

  // useEffect(() => {
  //   changeParentState(selected);
  // }, [selected]);

  // 選択されたアイテムの削除処理
  const handleItemDeleted = (target: string) => {
    const deleted = selected.filter((v: FilterboxItem) => {
      return v.value !== target;
    });

    setSelected(deleted);
    changeParentState(deleted);
  };

  // 検索処理
  const handleSearched = () => {
    // 初期の絞り込みからさらにinputで入力された検索ワードで絞り込み
    dispatch({
      type: 'query',
      fullMethodName: props.fullMethodName,
      pageNumber: 1,
      filter: getFilterQuery(
        props.itemType,
        searchTarget,
        [...initKeyWords, ...keyword.split(' ')],
        customQuery,
        appendSearchColumn
      ),
    });
  };

  const [isMaxPage, setMaxPage] = useState(false);
  const [isFirstPage, setFirstPage] = useState(true);

  // pageが変更されたら表示用に変換してリストアイテムにセット
  useEffect(() => {
    const converted = convertFilterboxItems(
      page.items,
      schema,
      props.itemType,
      props.formatOption
    );
    setItems(converted);

    // ページ送り/戻しを制御
    setMaxPage(page.pageNumber >= page.maxPageNumber);
    setFirstPage(page.pageNumber <= 1);

    // page 変更時のみ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  // コンポーネントから動的に与えられたpropsを検知してuseStateに格納
  useEffect(() => {
    const q = props.searchOption?.customQuery ?? {};
    // 他の箇所で値を使用するのでstateに格納
    setCustomQuery({ ...q });

    // クエリーを実行
    dispatch({
      type: 'query',
      fullMethodName: props.fullMethodName,
      pageNumber: 1,
      filter: getFilterQuery(
        props.itemType,
        searchTarget,
        initKeyWords,
        q,
        appendSearchColumn
      ),
    });

    // props.searchOption.customQuery 変更時のみ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.searchOption?.customQuery]);

  // 検索結果の表示アイテム出し分け処理
  const listView = (item: FilterboxItem) => {
    switch (displayItem) {
      case 'value':
        return item.value;

      case 'displayName':
        return item.displayName;

      case 'both':
        return `${item.value} :${item.displayName}`;
    }
  };

  // キーボード入力制御
  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case 'Enter':
        // 検索実行
        handleSearched();
        break;

      case 'Escape':
      case 'Tab':
        // 検索結果欄（リスト）を閉じて、フォーカスを外す
        handleCloseList();
        break;
    }
  };

  useEffect(() => {
    // 初回表示時にworkerから表示データを取得
    const isLatestData = props.searchOption?.isLatestData;
    dispatch({
      type: isLatestData ? 'reload' : 'query',
      fullMethodName: props.fullMethodName,
    });

    // 初回時のみ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // クリアボタンの表示・非表示
  const [clearButtonVisibility, setClearButtonVisibility] = useState<
    'hidden' | 'visible'
  >('hidden');
  useEffect(() => {
    //クリアボタンの表示・非表示切り替え
    if (keyword === '') {
      setClearButtonVisibility('hidden');
    } else {
      setClearButtonVisibility('visible');
    }
  }, [keyword]);

  // z-indexの基準値
  const baseZIndex = 10;

  // 検索結果表示エリアに表示するコンテンツ
  const searchItemArea = (items: FilterboxItem[]) => {
    const contents = [...Array(pageSize)].map(
      (_, i): FilterboxItem =>
        i
          ? { value: '', displayName: '' }
          : { value: '', displayName: '検索結果なし' }
    );

    if (items.length) {
      items.forEach((v, i) => (contents[i] = v));
    }

    return searchItemAreaView(
      contents,
      handleItemClicked,
      listView,
      visibility
    );
  };

  return (
    <div className={`${filterboxClassName} Filterbox`} style={viewStyle}>
      {/* 検索欄外クリック判定用エリア */}
      {visibility === 'visible' && (
        <div
          className={visibility === 'visible' ? 'click-area' : ''}
          onClick={() => handleCloseList()}
        ></div>
      )}

      {/* 常時表示エリア */}
      <div
        className="main-contents"
        style={visibility === 'visible' ? { zIndex: baseZIndex + 1 } : {}}
      >
        {/* ラベル */}
        {props.labelId && (
          <div className={props.validateOption?.required ? 'required ' : ''}>
            <div
              className={`topping-label ${
                disabled ? 'disabled-topping-label' : ''
              }`}
            >
              <GetMessageComponent id={props.labelId} />
            </div>
          </div>
        )}
        <div
          className={`selected-item-area ${
            disabled ? 'basic-disabled-input-border' : 'basic-input-border'
          } ${getErrorBorderClassName(message)} ${
            visibility === 'visible' ? 'focus' : ''
          } ${disabled ? 'disabled' : ''}`}
          onClick={handleOpenList}
        >
          {/* 表示リスト及びアイコン */}
          {/* 選択済みアイテム */}
          <div>
            {selected.map((v) => {
              return (
                <div key={v.value} className="selected-item">
                  <span className="selected-label">{v.displayName}</span>
                  {!props.disabled && (
                    <DeleteIcon onClick={() => handleItemDeleted(v.value)} />
                  )}
                </div>
              );
            })}
          </div>
          <button className="center filter-opener" disabled={disabled}>
            {visibility === 'visible' ? (
              <ExpandLessIcon
                onClick={(e) => {
                  e.stopPropagation();
                  handleCloseList();
                }}
              ></ExpandLessIcon>
            ) : (
              <ExpandMoreIcon onClick={handleOpenList}></ExpandMoreIcon>
            )}
          </button>
        </div>

        {/* クリック時 表示部分 */}
        <div
          style={{
            visibility: `${visibility}`,
            position: 'absolute',
            width: '100%',
            boxShadow: '0.2rem 0.2rem 0.5rem',
          }}
        >
          {/* 検索欄 */}
          <div className="search-area">
            <div className="input-area">
              <input
                name={props.name}
                className="keyword-input"
                onChange={(e) => {
                  setKeyword(e.target.value);
                }}
                onClick={handleOpenList}
                value={keyword}
                placeholder="絞り込み"
                style={style}
                disabled={disabled}
                onKeyDown={handleKeyPress}
                autoComplete="off"
                ref={keywordInputRef}
              ></input>

              <DeleteIcon
                onClick={handleClearKeyword}
                style={{
                  visibility: `${clearButtonVisibility}`,
                }}
              />
            </div>
            <SearchIcon className="center" onClick={handleSearched} />
          </div>

          {/* 検索結果欄 */}
          {/* 検索条件で絞り込んだ上で結果表示 */}
          <div
            className="search-result-area"
            style={{ gridTemplateRows: `repeat(${pageSize}, 1fr)` }}
          >
            {searchItemArea(items)}
          </div>

          {/* ページ送りボタン */}
          <div className="search-page-area">
            {isFirstPage ? (
              <KeyboardArrowLeftIcon className="center disabled-svg-icon" />
            ) : (
              <KeyboardArrowLeftIcon
                className="center"
                onClick={() => handleMovePage(page.pageNumber - 1)}
              />
            )}
            {isMaxPage ? (
              <KeyboardArrowRightIcon className="center end-column disabled-svg-icon" />
            ) : (
              <KeyboardArrowRightIcon
                className="center end-column"
                onClick={() => handleMovePage(page.pageNumber + 1)}
              />
            )}
          </div>
        </div>
      </div>
      {!isSkippedValidation ? (
        <ErrorMessage message={message}></ErrorMessage>
      ) : (
        <ErrorMessage message={[]}></ErrorMessage>
      )}
    </div>
  );
}
