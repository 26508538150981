import { FullMethodName_DeleteOrganizationWithRelatedItems } from 'worker/fullMethodName/crud';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface DeleteOrganizationsAction {
  actionName: 'deleteOrganizations';
  request: mtechnavi.api.company.IDeleteOrganizationWithRelatedItemsRequest;
}

export const deleteOrganizations = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.company.IDeleteOrganizationWithRelatedItemsRequest
) => {
  const result = await worker.invokeListRequest<
    mtechnavi.api.company.IDeleteOrganizationWithRelatedItemsRequest,
    void
  >(FullMethodName_DeleteOrganizationWithRelatedItems, req);
  return result;
};
