import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface ExecDeleteWorkTaskRelationRequest {
  actionName: 'deleteWorkTaskRelation';
  request: mtechnavi.api.worktask.IWorkTaskRelation[];
}

// 作業案件紐付け削除
export const execDeleteWorkTaskRelation = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.worktask.IWorkTaskRelation[]
) => {
  const fullMethodName =
    'mtechnavi.api.worktask.WorkTaskService/DeleteWorkTaskRelation';

  type deleteRequestType =
    mtechnavi.api.worktask.IDeleteWorkTaskRelationRequest;
  const result = req.map((item) => {
    const target = item as mtechnavi.api.worktask.IWorkTaskRelation;
    return worker.invokeListRequest(fullMethodName, {
      workTaskRelation: target as deleteRequestType,
    });
  });

  await Promise.all(result);
};
