import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface CreateSurveyResultWorkFormRequest {
  actionName: 'createSurveyResultWorkForm';
  request: mtechnavi.api.survey.ICreateSurveyResultWorkFormRequest;
}

// ResultWorkForm の登録
export const createSurveyResultWorkForm = (
  worker: AppSaveWorker,
  req: mtechnavi.api.survey.ICreateSurveyResultWorkFormRequest
) => {
  const methodName =
    'mtechnavi.api.survey.SurveySenderService/CreateSurveyResultWorkForm';

  return worker.invokeListRequest<
    mtechnavi.api.survey.ICreateSurveyResultWorkFormRequest,
    mtechnavi.api.survey.SurveyResultWorkForm
  >(methodName, req);
};
