import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface DeleteBusinessUnitPublicDocument {
  actionName: 'deleteBusinessUnitPublicDocument';
  request: mtechnavi.api.company.IBusinessUnitPublicDocument;
}

export const deleteBusinessUnitPublicDocument = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.company.IBusinessUnitPublicDocument
) => {
  // 公開資料情報削除
  const fullMethodName =
    'mtechnavi.api.company.BusinessUnitManagementService/DeleteBusinessUnitPublicDocument';
  await worker.invokeListRequest(fullMethodName, {
    businessUnitPublicDocument: req,
  });
};
