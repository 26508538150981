import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface DeleteFormWithRelatedItemsRequest {
  actionName: 'deleteFormWithRelatedItems';
  request: string[];
}

export type DeleteFormWithRelatedItemsRequestParam =
  mtechnavi.api.form.IDeleteFormWithRelatedItemsRequest;

// FormDefinition の単純な削除
export const deleteFormWithRelatedItems = async (
  worker: AppSaveWorker,
  req: string[]
) => {
  const methodName =
    'mtechnavi.api.form.FormService/DeleteFormWithRelatedItems';
  const result = req.map((formId) => {
    return worker.invokeListRequest<
      mtechnavi.api.form.IDeleteFormWithRelatedItemsRequest,
      void
    >(methodName, { formId });
  });

  await Promise.all(result);
};
