import React from 'react';
import ReactDOM from 'react-dom/client';
import '~/shared/css/theme/light.css';
import '~/shared/css/theme/dark.css';
import '~/shared/css/baseColor.css';
import '~/shared/css/index.css';
import '~/shared/css/normalize.css';
import { User as FirebaseUser, onAuthStateChanged } from 'firebase/auth';
import './index.css';
import { App, NotenatApp, StaticPageApp } from './App';
import reportWebVitals from '~/reportWebVitals';
import AppWorker from './App.worker';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getAnalytics } from 'firebase/analytics';
import { UiService, getAppConfig } from '~/shared/services';
import { mtechnavi, rpcImpl } from '~/shared/libs/clientsdk';
import { getLangCode, selectMessages } from '~/shared/services';
// 多言語対応
import { IntlProvider } from 'react-intl';
import dayjs from 'dayjs';
import { FILE_VIEWER_LOCALE_STORAGE_PREFIX } from '~/shared/components/ui/Viewer/FileViewer';

window.addEventListener('error', async (evt: ErrorEvent) => {
  console.error('global error handler', evt);
});

function selectLangName() {
  switch (getLangCode()) {
    case 'en':
      return 'en';
    default:
    case 'ja':
      return 'ja';
  }
}

(async () => {
  const cli = new mtechnavi.api.idp.Identity(rpcImpl);
  const queryParams = new URLSearchParams(window.location.search);
  const queryTenantId = queryParams.get('tenantId') ?? '';
  const tenantConfigParam = queryTenantId
    ? { internalTenantId: queryTenantId }
    : { domain: window.location.host };
  const pathName = window.location.pathname;
  console.info('queryTenantId with', queryTenantId);
  console.info('domain with', window.location.host, pathName);
  console.info('tenantConfigParam with', tenantConfigParam);

  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );
  const splitPath = pathName.split('/');
  const checkPathName = splitPath.length > 1 ? splitPath[1] : splitPath[0];
  try {
    const tenantConfig = await cli.getTenantConfig(tenantConfigParam);
    const appConfig = await getAppConfig();
    console.info(
      'initialize with',
      tenantConfig.toJSON(),
      appConfig,
      window.location.host
    );

    const authApp = initializeApp({
      apiKey: tenantConfig.internalApiKey,
      authDomain: tenantConfig.internalAuthDomain,
    });
    const auth = getAuth(authApp);
    auth.tenantId = tenantConfig.internalTenantId;

    const analyticsApp = initializeApp(
      appConfig.firebase.analyticsConfig,
      'firebase-analytics'
    );
    const analytics = getAnalytics(analyticsApp);
    const langName = selectLangName();

    // expose to global
    window.App = {
      firebaseApps: {
        auth,
        analytics,
      },
      config: {
        ...appConfig,
        langName: langName,
        timeZoneName: '',
        singleSignOn: {
          enabled: tenantConfig.singleSignOn?.enabled ?? false,
          allowedDomain: tenantConfig.singleSignOn?.allowedDomain ?? undefined,
        },
        tenantDomain: tenantConfig.domain,
      },
      services: {
        ui: new UiService(new AppWorker(), appConfig.adminMenu, langName),
        identity: new mtechnavi.api.idp.Identity(rpcImpl),
        assetInventory: new mtechnavi.api.assetinventory.AssetInventory(
          rpcImpl
        ),
        dlock: new mtechnavi.api.dlock.Locker(rpcImpl),
        uicontroller: new mtechnavi.api.uicontroller.UiController(rpcImpl),
        publicInformationService:
          new mtechnavi.api.uicontroller.PublicInformationService(rpcImpl),
        companyService: new mtechnavi.api.company.CompanyService(rpcImpl),
        componentunitService: new mtechnavi.api.company.ComponentUnitService(
          rpcImpl
        ),
        businessunitService: new mtechnavi.api.company.BusinessUnitService(
          rpcImpl
        ),
        businessUnitManagementService:
          new mtechnavi.api.company.BusinessUnitManagementService(rpcImpl),
        staffService: new mtechnavi.api.company.StaffService(rpcImpl),
        publicCompanyService: new mtechnavi.api.company.PublicCompanyService(
          rpcImpl
        ),
        organizationService: new mtechnavi.api.company.OrganizationService(
          rpcImpl
        ),
        billingCompanyService: new mtechnavi.api.company.BillingCompanyService(
          rpcImpl
        ),
        blueprintService: new mtechnavi.api.blueprint.BlueprintService(rpcImpl),
        transactionUnitService:
          new mtechnavi.api.blueprint.TransactionUnitService(rpcImpl),
        programOptionService:
          new mtechnavi.api.programoption.ProgramOptionService(rpcImpl),
        attributeService: new mtechnavi.api.programoption.AttributeService(
          rpcImpl
        ),
        estimationService: new mtechnavi.api.estimation.EstimationService(
          rpcImpl
        ),
        estimateSenderService:
          new mtechnavi.api.estimation.EstimateSenderService(rpcImpl),
        estimateReceiverService:
          new mtechnavi.api.estimation.EstimateReceiverService(rpcImpl),
        estimationTaskListService:
          new mtechnavi.api.estimation.EstimationTaskListService(rpcImpl),
        forumService: new mtechnavi.api.forum.ForumService(rpcImpl),
        forumTaskListService: new mtechnavi.api.forum.ForumTaskListService(
          rpcImpl
        ),
        tenantAdminService: new mtechnavi.api.tenantadmin.TenantAdminService(
          rpcImpl
        ),
        surveyService: new mtechnavi.api.survey.SurveyService(rpcImpl),
        surveySenderService: new mtechnavi.api.survey.SurveySenderService(
          rpcImpl
        ),
        surveyReceiverService: new mtechnavi.api.survey.SurveyReceiverService(
          rpcImpl
        ),
        surveyTaskListService: new mtechnavi.api.survey.SurveyTaskListService(
          rpcImpl
        ),
        surveyIOService: new mtechnavi.api.survey.SurveyIOService(rpcImpl),
        tenantProvisionService:
          new mtechnavi.api.tenantprovision.TenantProvisionService(rpcImpl),
        workTaskService: new mtechnavi.api.worktask.WorkTaskService(rpcImpl),
        workTaskTaskListService:
          new mtechnavi.api.worktask.WorkTaskTaskListService(rpcImpl),
        baseFormService: new mtechnavi.api.form.BaseFormService(rpcImpl),
        formService: new mtechnavi.api.form.FormService(rpcImpl),
        sampleWorkFormService: new mtechnavi.api.form.SampleWorkFormService(
          rpcImpl
        ),
        searchCompanyService: new mtechnavi.api.analysis.SearchCompanyService(
          rpcImpl
        ),
        analysisService: new mtechnavi.api.analysis.AnalysisService(rpcImpl),
        bcpService: new mtechnavi.api.bcp.BcpService(rpcImpl),
        licenseService: new mtechnavi.api.license.LicenseService(rpcImpl),
        adminLicenseService: new mtechnavi.api.license.AdminLicenseService(
          rpcImpl
        ),
        tenantManagementService:
          new mtechnavi.api.admin.TenantManagementService(rpcImpl),
        informationService: new mtechnavi.api.admin.InformationService(rpcImpl),
      },
      events: {},
      programoptions: [],
      notices: [],
      helpList: [],
    };
  } catch (err) {
    console.error(err);
    // テナント作成に行こうとしている時のみテナント作成画面に遷移させる
    if (
      checkPathName === 'reset-password' ||
      checkPathName === 'tenant-invite' ||
      checkPathName === 'tenant-request-apply'
    ) {
      root.render(
        <React.StrictMode>
          <IntlProvider locale={'ja'} messages={await selectMessages(true)}>
            <NotenatApp />
          </IntlProvider>
        </React.StrictMode>
      );
      return;
    } else {
      throw err;
    }
  }

  onAuthStateChanged(
    window.App.firebaseApps.auth,
    async (fbUser: FirebaseUser | null) => {
      try {
        if (!fbUser) return;
        const myCompanyInfo: mtechnavi.api.company.Company =
          await window.App.services.companyService.getCompany({});
        const timezoneName = myCompanyInfo.timezoneName;
        window.App.config.timeZoneName = timezoneName; //date型で使用するtimeZone設定を保存
      } catch (err) {
        console.error(err);
      }
    }
  );
  // 静的コンテンツにアクセスの場合は
  if (
    checkPathName === 'privacypolicy' ||
    checkPathName === 'term' ||
    checkPathName === 'help'
  ) {
    root.render(
      <React.StrictMode>
        <IntlProvider locale={'ja'} messages={await selectMessages(true)}>
          <StaticPageApp />
        </IntlProvider>
      </React.StrictMode>
    );
  } else {
    root.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>
    );
  }
})();

// ローカルストレージで一日以上経過したものを削除
for (let index = 0; index < localStorage.length; index++) {
  const key = localStorage.key(index);
  if (key === '') {
    continue;
  }

  const [prefix, registerTime] = key!.split('/');

  const time = Number(registerTime);
  if (isNaN(time) || time === 0) {
    continue;
  }

  // 現状ではFileViewerのみ期限を切って消す
  if (prefix !== FILE_VIEWER_LOCALE_STORAGE_PREFIX) {
    continue;
  }

  if (dayjs().diff(dayjs.unix(time), 'day') >= 1) {
    localStorage.removeItem(key!);
  }
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
