import { useEffect, useState } from 'react';
import { useAuth } from '~/shared/contexts/AuthProvider';
import { useNavigate, useLocation } from 'react-router-dom';
import './Login.css';

import { Textbox } from '../components/ui/Textbox';
import { CaptionButton } from '../components/ui/Button/CaptionButton';
import { IconWithCaptionButton } from '../components/ui/Button/IconWithCaptionButton';
import { FirebaseError } from 'firebase/app';
import { GetMessage } from '../components';

export function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [passwordContentState, setPasswordContentState] = useState('login');
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const from: string = (location.state as any)?.from?.pathname || '/';
  const [isClickableRest, setClickableRest] = useState<boolean>(true);

  const authErrorMessageMap: { [k: string]: string } = {
    'auth/user-disabled': GetMessage({ id: 'E0000147' }),
    'auth/operation-not-allowed': GetMessage({ id: 'E0000164' }),
    'auth/project-disabled': GetMessage({ id: 'E0000167' }),
  };

  useEffect(() => {
    if (!!auth.authorized) {
      navigate(from, { replace: true });
    }
  }, [auth, navigate, from]);

  const handleSignIn = () => {
    setErrorMessage('');
    const creds = {
      email: email,
      password: password,
    };
    if (!creds.email || !creds.password) {
      return setErrorMessage(
        'パスワードもしくはメールアドレスが、未入力です。'
      );
    }
    (async () => {
      try {
        await auth.signInWithEmailAndPassword(creds.email!, creds.password!);
      } catch (err) {
        console.error(err);
        if (
          err instanceof FirebaseError &&
          Object.prototype.hasOwnProperty.call(authErrorMessageMap, err.code)
        ) {
          setErrorMessage(authErrorMessageMap[err.code]);
        } else {
          setErrorMessage(`メールアドレスまたはパスワードが正しくありません`);
        }
        // SSOボタン押下時エラー
        // ログインできませんでした
      }
    })();
  };
  const handleResetPassword = () => {
    setClickableRest(false);
    setErrorMessage('');
    if (!email) {
      setClickableRest(true);
      return setErrorMessage(
        '入力欄にメールアドレスを入力してからクリックしてください'
      );
    }
    (async () => {
      try {
        await auth.resetPassword(email);
        setPasswordContentState('resetEmailMessage');
      } catch (err) {
        console.error(err);
        if (
          err instanceof FirebaseError &&
          Object.prototype.hasOwnProperty.call(authErrorMessageMap, err.code)
        ) {
          setErrorMessage(authErrorMessageMap[err.code]);
        } else {
          setErrorMessage(`リセットできませんでした`);
        }
      } finally {
        setClickableRest(true);
      }
    })();
  };

  const loginSectionMove = (moveSection: string) => {
    setPasswordContentState(moveSection);
    setErrorMessage('');
  };
  const decisionContent = (arr: string[]) => {
    return arr.includes(passwordContentState);
  };
  const enableGoogleSignIn = window.App.config.singleSignOn.enabled;

  const generateGoogleLoginIcon = () => {
    return <img src="/assets/logo/google.svg" alt="button icon" />;
  };

  return (
    <div className="Login">
      <section className="login-sction">
        <div className="visual-area">
          <p className="fonts">
            <span className="logo">
              <img src="/assets/logo/arrow.svg" alt="arrow" />
            </span>
            <br />
            <span className="font">M-Tech</span>
            <br />
            <span className="font">Navi</span>
          </p>
        </div>
        <div className="input-area">
          {passwordContentState !== 'login' && (
            <h3 className="title">パスワードのリセット</h3>
          )}
          {decisionContent(['login', 'resetEmail']) && (
            <div className="input-field email">
              <Textbox
                type="email"
                name="email"
                value={email}
                labelId="email"
                onChangeState={setEmail}
                columns={['email']}
                properties={[
                  {
                    name: 'email',
                    propertyName: '',
                    propertyValue: true,
                  },
                ]}
              />
            </div>
          )}
          {passwordContentState === 'login' && (
            <div>
              <div className="input-field password">
                <Textbox
                  type="password"
                  name="password"
                  value={password}
                  labelId="password"
                  onChangeState={setPassword}
                  columns={['password']}
                  properties={[
                    {
                      name: 'password',
                      propertyName: '',
                      propertyValue: true,
                    },
                  ]}
                />
              </div>
              <div className="input-field submit">
                <CaptionButton
                  name="loginBtn"
                  caption="ログイン"
                  className="btn limit"
                  properties={[
                    {
                      name: 'loginBtn',
                      propertyName: 'loginBtn',
                      propertyValue: 'loginBtn',
                    },
                  ]}
                  onClick={handleSignIn}
                  buttonType="basic"
                />
                <p className="error">{errorMessage}</p>
              </div>
              <div className="input-field forget">
                <a onClick={() => loginSectionMove('resetEmail')}>
                  パスワードを忘れた時
                </a>
              </div>
              {enableGoogleSignIn && (
                <div className="input-field google">
                  <p>または</p>
                  <IconWithCaptionButton
                    name="googleloginBtn"
                    caption="Googleで続行"
                    className="btn btn-normal"
                    disabled={!enableGoogleSignIn}
                    properties={[
                      {
                        name: 'googleloginBtn',
                        propertyName: 'googleloginBtn',
                        propertyValue: 'googleloginBtn',
                      },
                    ]}
                    onClick={() => auth.signInWithGoogle()}
                    icon={generateGoogleLoginIcon()}
                  />
                </div>
              )}
            </div>
          )}
          {passwordContentState === 'resetEmail' && (
            <div>
              <div className="input-field submit">
                <CaptionButton
                  name="resetBtn"
                  caption="リセット"
                  className="btn"
                  disabled={auth.authorized || !isClickableRest}
                  properties={[
                    {
                      name: 'resetBtn',
                      propertyName: 'resetBtn',
                      propertyValue: 'resetBtn',
                    },
                  ]}
                  onClick={handleResetPassword}
                  buttonType="basic"
                />
                <p className="error">{errorMessage}</p>
              </div>
              <div className="input-field remove">
                <a onClick={() => loginSectionMove('login')}>ログインに戻る</a>
              </div>
            </div>
          )}
          {passwordContentState === 'resetEmailMessage' && (
            <div>
              <div className="input-field message">
                <p>
                  {email}
                  にパスワードリセットのメールを送信しました。
                </p>
                <p>メール記載のリンクからパスワードを変更してください。</p>
              </div>
              <div className="input-field remove">
                <a onClick={() => loginSectionMove('login')}>ログインに戻る</a>
              </div>
            </div>
          )}
        </div>
      </section>
    </div>
  );
}
