import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { FullMethodName_ListOrganizations } from '~/worker';
import { Checkbox, error } from '~/shared/components';
import { CaptionButton } from '~/shared/components/ui/Button';
import { OrganizationTreeSearchDialog } from '~/shared/components/ui/Dialog/OrganizationTreeSearchDialog';
import { useLoading } from '~/shared/contexts/LoadingProvider';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { getExceptionMessage } from '~/shared/utils';

export const UISampleOrganizationTreeSearchDialogs = () => {
  const intl = useIntl();
  const { showLoading, hideLoading } = useLoading();
  const [isShowDialog, setShowDialog] = useState(false);
  const [isMultiple, setMultiple] = useState(false);
  const [organizationList, setOrganizationList] = useState<
    mtechnavi.api.company.IOrganization[]
  >([]);
  const [checkedIds, setCheckedIds] = useState<string[]>([]);

  const result = JSON.stringify(checkedIds, null, 2);

  useEffect(() => {
    showLoading();
    (async () => {
      try {
        // データの取得
        const orgResp =
          await window.App.services.ui.worker.filter<mtechnavi.api.company.IOrganization>(
            {
              action: 'reload',
              fullMethodName: FullMethodName_ListOrganizations,
              filter: { usable: { $eq: true } }, // 使用可能なもののみ
              sort: [],
            }
          );
        setOrganizationList(orgResp.items);
      } catch (err) {
        error(getExceptionMessage(intl, err));
        throw err;
      } finally {
        hideLoading();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="categories">
      <h3 id="dialog">組織ツリー検索ダイアログ</h3>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => setShowDialog(true)}
            />
            <Checkbox
              name="isUseLevel"
              className="group"
              items={[
                {
                  value: '1',
                  displayName: '複数選択',
                },
              ]}
              onChangeState={(checkedValues) =>
                setMultiple(checkedValues.includes('1'))
              }
            />
            {/* 組織検索ダイアログ */}
            <OrganizationTreeSearchDialog
              isOpen={isShowDialog}
              organizationList={organizationList}
              checkedIds={checkedIds}
              multiple={isMultiple}
              messageOption={{
                headerLabelId: {
                  prefixId: 'DIALOG_TITLE',
                  id: 'OrganizationTreeSearchDialog',
                },
              }}
              onCancel={() => setShowDialog(false)}
              onDecision={(checks) => {
                setCheckedIds(checks);
                setShowDialog(false);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">{result}</pre>
          </div>
        </div>
      </div>
    </div>
  );
};
