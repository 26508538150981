import { PropsWithoutRef } from 'react';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { GetMessage } from '~/shared/components';
import './BaseFormInformation.css';
import { convertOrganizationStructureReferenceToFilterboxItem } from '~/shared/utils';

interface BaseFormInformationProps {
  formProperties: mtechnavi.api.form.IFormProperties;
  usable?: boolean;
}
export const BaseFormInformation = (
  props: PropsWithoutRef<BaseFormInformationProps>
) => {
  const getFormType = () => {
    const v: string[] = [];
    if (props.formProperties.formType1?.displayNameLang?.ja)
      v.push(props.formProperties.formType1?.displayNameLang?.ja);
    if (props.formProperties.formType2?.displayNameLang?.ja)
      v.push(props.formProperties.formType2?.displayNameLang?.ja);
    if (props.formProperties.formType3?.displayNameLang?.ja)
      v.push(props.formProperties.formType3?.displayNameLang?.ja);
    return v.join('/');
  };

  return (
    <div className="BaseFormInformation">
      <div className="form-name">{props.formProperties.displayName}</div>
      <div className="input-line label-margin">
        <div className="item-group-100">
          <div className="w-25">
            {/* 使用可能 */}
            {`${GetMessage({
              prefixId: 'BaseFormInformation',
              id: 'usable',
            })} : ${props.usable ? '◯' : '-'}`}
          </div>
          <div className="w-75">
            {/* 管理組織 */}
            {`${GetMessage({
              prefixId: 'BaseFormInformation',
              id: 'managementOrganization',
            })} : ${
              convertOrganizationStructureReferenceToFilterboxItem(
                props.formProperties.managementOrganization
              )?.displayName ?? ''
            }`}
          </div>
        </div>
      </div>
      <div className="input-line label-margin">
        <div className="item-group-100">
          <div className="w-25">
            {/* システム区分 */}
            {`${GetMessage({
              prefixId: 'BaseFormInformation',
              id: 'systemType',
            })} : ${
              props.formProperties.systemForm
                ? GetMessage({
                    id: 'system',
                  })
                : GetMessage({
                    id: 'user',
                  })
            }`}
          </div>
          <div className="w-75">
            {/* 用途区分 */}
            {`${GetMessage({
              prefixId: 'BaseFormInformation',
              id: 'purposes',
            })} : ${
              props.formProperties.purposes
                ?.map((v) => {
                  return v.displayNameLang?.ja ?? '';
                })
                .join(',') ?? ''
            }`}
          </div>
        </div>
      </div>
      <div className="input-line label-margin">
        <div className="item-group-100">
          <div className="w-100">
            {/* 分類 */}
            {`${GetMessage({
              prefixId: 'BaseFormInformation',
              id: 'formType',
            })} : ${getFormType()}`}
          </div>
        </div>
      </div>
      <div className="input-line label-margin">
        <div className="item-group-100">
          <div className="w-100">
            {/* 説明 */}
            {`${GetMessage({
              prefixId: 'BaseFormInformation',
              id: 'description',
            })} : ${props.formProperties.description ?? ''}`}
          </div>
        </div>
      </div>
    </div>
  );
};
