import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface SaveEstimateSelectionAndApprovalRequestRequest {
  actionName: 'saveEstimateSelectionAndApprovalRequest';
  request: SaveEstimateSelectionAndApprovalRequestRequestParam;
}
export interface SaveEstimateSelectionAndApprovalRequestRequestParam {
  selection: mtechnavi.api.estimation.ISaveEstimateSelectionRequest;
  approvalRequest?: mtechnavi.api.estimation.IApprovalRequestEstimateSelectionRequest;
}

/**
 * 見積選考データの保存と承認依頼
 */
export const saveEstimateSelectionAndApprovalRequest = async (
  worker: AppSaveWorker,
  req: SaveEstimateSelectionAndApprovalRequestRequestParam
) => {
  const selectionResult = await worker.invokeListRequest<
    mtechnavi.api.estimation.ISaveEstimateSelectionRequest,
    mtechnavi.api.estimation.IEstimateSelection
  >(
    'mtechnavi.api.estimation.EstimateSenderService/SaveEstimateSelection',
    req.selection
  );
  if (req.approvalRequest) {
    await approvalRequest(worker, {
      ...req.approvalRequest,
      estimateSelectionId: selectionResult.at(0)?.estimateSelectionId,
    });
  }
  return selectionResult;
};

const approvalRequest = (
  worker: AppSaveWorker,
  approval: mtechnavi.api.estimation.IApprovalRequestEstimateSelectionRequest
) => {
  return worker.invokeListRequest<
    mtechnavi.api.estimation.IApprovalRequestEstimateSelectionRequest,
    mtechnavi.api.estimation.IApprovalRequestEstimateSelectionResponse
  >(
    'mtechnavi.api.estimation.EstimateSenderService/ApprovalRequestEstimateSelection',
    approval
  );
};
