import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface UpdateProxyAttachmentsRequest {
  actionName: 'updateProxyAttachments';
  request: mtechnavi.api.survey.ISurveyRequest;
}

// // 回答代行更新
export const updateProxyAttachments = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.survey.ISurveyRequest
) => {
  const getFullMethodName =
    'mtechnavi.api.survey.SurveySenderService/GetSurveyRequest';
  const updateFullMethodName =
    'mtechnavi.api.survey.SurveySenderService/UpdateSurveyRequest';
  // // 依頼情報を取得
  const items = (await worker.invokeListRequest(getFullMethodName, {
    surveyRequestId: req.surveyRequestId,
  })) as mtechnavi.api.survey.ISurveyRequest[];
  if (items && items.length > 0) {
    const item = items[0];
    item.proxyAttachments = req.proxyAttachments;
    // 依頼受信情報を更新
    await worker.invokeListRequest(updateFullMethodName, {
      surveyRequest: item,
    });
  }
};
