import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface UpdateForumCommentRequest {
  actionName: 'updateForumComment';
  request: mtechnavi.api.forum.IUpdateCommentRequest;
}

export const updateForumComment = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.forum.IUpdateCommentRequest
) => {
  return await worker.invokeListRequest<
    mtechnavi.api.forum.IUpdateCommentRequest,
    mtechnavi.api.forum.IComment
  >('mtechnavi.api.forum.ForumService/UpdateComment', req);
};
