import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  GetMessageWithIntl,
  ModalDialogComponent,
  ModalDialogComponentProps,
} from '../..';
import {
  CategorizedBranchData,
  CategorizedCheckList,
  CategorizedList,
  CheckItem,
} from '../CategorizedCheckList';
import { CaptionButton } from '../Button';
import { useIntl } from 'react-intl';
import {
  BusinessUnitAttributeCategory,
  BusinessUnitAttributeItem,
  convertToCategorizedList,
} from 'tenant/src/company/pages/converter';
import './SearchRegionDialog.css';

export interface SearchRegionDialogInputOption {
  regions?: string[];
}

export interface SearchRegionDialogProps {
  isOpen: boolean;
  inputOption?: SearchRegionDialogInputOption;
  onDecision: (result: SearchRegionDialogInputOption) => void;
  onCancel: () => void;
}

interface PrefectureCategoryMap {
  [key: string]: string[];
}

const REGION_MAP = [
  { id: 'REGION-1', name: '北海道' },
  { id: 'REGION-2', name: '東北' },
  { id: 'REGION-8', name: '関東' },
  { id: 'REGION-15', name: '中部・東海' },
  { id: 'REGION-25', name: '関西' },
  { id: 'REGION-31', name: '中国・四国' },
  { id: 'REGION-40', name: '九州' },
];

const PREFECTURE_CATEGORY_MAP: PrefectureCategoryMap = {
  'REGION-1': ['1'],
  'REGION-2': ['2', '3', '4', '5', '6', '7'],
  'REGION-8': ['8', '9', '10', '11', '12', '13', '14'],
  'REGION-15': ['15', '16', '17', '18', '19', '20', '21', '22', '23', '24'],
  'REGION-25': ['25', '26', '27', '28', '29', '30'],
  'REGION-31': ['31', '32', '33', '34', '35', '36', '37', '38', '39'],
  'REGION-40': ['40', '41', '42', '43', '44', '45', '46', '47'],
};

export const SearchRegionDialog = (props: SearchRegionDialogProps) => {
  const intl = useIntl();
  const [initCheckIds, setInitCheckIds] = useState<string[]>([]);
  const [listData, setListData] = useState<CategorizedList>([]);
  const [checkedIds, setCheckedIds] = useState<CheckItem[]>([]);
  const prefectures = useMemo(
    () => window.App.services.ui.getNameOption('A0000042'),
    []
  );

  useEffect(() => {
    if (!prefectures || prefectures.length === 0) {
      return;
    }
    const categorizedItem = convertToCategorizedList(
      convertNameOptionToChildrenData()
    );
    setListData(categorizedItem);

    // prefectures 変更時のみ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prefectures]);

  useEffect(() => {
    const ids = props.inputOption?.regions;
    if (ids) {
      setInitCheckIds(ids);
    }
  }, [props.inputOption]);

  const convertNameOptionToChildrenData =
    (): BusinessUnitAttributeCategory[] => {
      const baseChildrenData: BusinessUnitAttributeItem[] = [];
      prefectures.map((v) => {
        const baseChildren: BusinessUnitAttributeItem = {
          attributeItemId: v.code ?? '',
          displayNameLang: { ja: v.displayNameLang?.ja ?? '' },
        };
        baseChildrenData.push(baseChildren);
      });
      const baseData: BusinessUnitAttributeCategory[] = [];
      REGION_MAP.map((item) => {
        const base: BusinessUnitAttributeCategory = {
          attributeCategoryId: item.id,
          displayNameLang: { ja: item.name },
          children: baseChildrenData.filter((v) =>
            PREFECTURE_CATEGORY_MAP[item.id].includes(v.attributeItemId ?? '')
          ),
        };
        baseData.push(base);
      });
      return baseData;
    };

  const handleCancel = () => {
    setInitCheckIds([]);
    setCheckedIds([]);
    props.onCancel();
  };

  const handleDecision = () => {
    const regions: string[] = [];
    checkedIds.map((v) => {
      regions.push(v.id ?? '');
    });
    const result: SearchRegionDialogInputOption = {
      regions,
    };
    props.onDecision(result);
  };

  const handleChangeChecks = useCallback((checkIds: CheckItem[]) => {
    setCheckedIds(checkIds);
  }, []);

  const elements = (
    <div className="search-region-dialog">
      <div className="content-area">
        <CategorizedCheckList
          inputOption={{
            isUnVisibleSearchArea: true,
          }}
          listData={listData}
          checkedIds={initCheckIds}
          onChange={handleChangeChecks}
        />
      </div>
      <div className="button-area">
        <CaptionButton
          name="cancelBtn"
          buttonType="cancel"
          className="button"
          caption={GetMessageWithIntl(intl, { id: 'cancel' })}
          onClick={handleCancel}
        />
        <CaptionButton
          name="sendBtn"
          buttonType="basic"
          className="button"
          caption={GetMessageWithIntl(intl, { id: 'select' })}
          onClick={() => handleDecision()}
        />
      </div>
    </div>
  );
  const openModalProps: ModalDialogComponentProps = {
    cancel: () => {
      props.onCancel();
    },
    send: () => {},
    modalIsOpen: props.isOpen,
    headerLabelId: {
      id: 'SELECT_REGION',
      prefixId: 'DIALOG_TITLE',
    },
    messageLabelId: {},
    elements,
  };

  return (
    <div className="SearchRegionDialog">
      <ModalDialogComponent {...openModalProps} />
    </div>
  );
};
