import {
  ChangeEvent,
  CSSProperties,
  DragEvent,
  Fragment,
  useEffect,
  useState,
  ReactNode,
  useMemo,
} from 'react';
import { ReactComponent as AttachFileIcon } from '@material-design-icons/svg/filled/attach_file.svg';
import './FileUploader.css';
import { ErrorMessage } from '~/shared/components/parts/ErrorMessage/ErrorMessage';
import { useIntl } from 'react-intl';
import {
  GetMessageWithIntl,
  MessageProps,
} from '~/shared/components/parts/Message/Message';
import {
  FileItem,
  FileUploaderDnDOption,
  FileUploaderValidateOption,
} from './CommonType';
import { fileListToArray, UNIT_MiB, uploadFiles, validateFile } from './Utils';
import { IconButton } from '../../ui/Button';
export interface FileUploaderMessageOption {
  uploadLabelId?: MessageProps;
  omitAttachedIcon?: boolean;
}
export interface FileUploaderProps {
  name: string;
  className?: string;
  messageOption?: FileUploaderMessageOption;
  validateOption?: FileUploaderValidateOption;
  dndOption?: FileUploaderDnDOption;
  multiple?: boolean; // 複数ファイル対応
  disabled?: boolean;
  fileChooserComment?: ReactNode; // ファイル選択ボタンに対するコメント表示DOM
  onUpload?: (v: FileItem[]) => void;
  onChangeState?: (arg: boolean) => void;
  onChangeLoadingState?: (arg: boolean) => void;
}

export function FileUploader(props: FileUploaderProps) {
  const intl = useIntl();

  const [dndHeight, setDndHeight] = useState<string>(
    props.dndOption?.style?.height ?? '300px'
  );
  const FileUploaderClassName = props.className ?? '';
  const dndEnabled = props.dndOption?.enabled ?? false;
  const disabled = props.disabled ?? false;
  const style: CSSProperties = {
    border: props.dndOption?.style?.border ?? '1px dashed black',
    backgroundColor: disabled
      ? 'rgba(128, 128, 128, .5)'
      : props.dndOption?.style?.backgroundColor ?? 'rgba(255, 255, 128, .5)',
    width: props.dndOption?.style?.width ?? '300px',
    height: dndHeight,
    position: 'relative',
  };
  const multiple = props.multiple ?? false;
  const extensions = props.validateOption?.allowedFileExtensions ?? [];
  const size = (props.validateOption?.maxFileSizeInMebis ?? 0) * UNIT_MiB;
  const onParentChangeState = props.onChangeState ?? (() => {});
  const onParentLodingState = props.onChangeLoadingState ?? (() => {});

  const [fileItems, setFileItems] = useState<FileItem[]>([]);
  const [message, setMessage] = useState<string[]>([]); // バリデーションエラー用

  // D&Dでのファイルアップロード処理
  const handleFileDrop = async (evt: DragEvent<HTMLDivElement>) => {
    onParentChangeState(false);
    if (disabled) {
      return;
    }
    evt.preventDefault();
    // Filelistを配列に変換
    const l = fileListToArray(evt.dataTransfer.files);

    // ファイルサイズ及び拡張子のバリデーション実施
    const [files, msg] = validateFile(intl, l, extensions, size);
    setMessage(msg);

    if (files.length === 0) {
      // アップロードすべきファイルがなければearlyReturn
      return;
    }

    // アップロード処理
    await uploadFiles(files, setFileItems, intl);
    await onParentChangeState(true);
    evt.dataTransfer.clearData();
  };

  useEffect(() => {
    props.onUpload && props.onUpload(fileItems);
    if (fileItems.some((file) => file.status === 'uploading')) {
      onParentLodingState(true);
    } else {
      onParentLodingState(false);
    }
    const filenameSize = fileItems.length * 27.4 + 72;
    let dndHeight = '300px';
    if (props.dndOption?.enabled) {
      const propsHeight = (props.dndOption?.style?.height ?? '').replace(
        /[^0-9]/g,
        ''
      );
      const propsNumberHeight = Number(propsHeight);
      const calcHeight =
        propsNumberHeight > filenameSize ? propsNumberHeight : filenameSize;
      dndHeight = `${calcHeight}px`;
    }
    setDndHeight(dndHeight);

    // fileItems 変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileItems]);

  // input要素からのファイルアップロード処理
  const handleFileChange = async (evt: ChangeEvent<HTMLInputElement>) => {
    onParentChangeState(false);
    // ファイルがない場合は処理終了
    if (!evt.target.files) {
      return;
    }

    // Filelistを配列に変換
    const l = fileListToArray(evt.target.files);

    // ファイルサイズ及び拡張子のバリデーション実施
    const [files, msg] = validateFile(intl, l, extensions, size);
    setMessage(msg);

    if (files.length === 0) {
      // アップロードすべきファイルがなければearlyReturn
      return;
    }

    // アップロード処理
    await uploadFiles(files, setFileItems, intl);
    await onParentChangeState(true);

    evt.target.value = '';
  };

  const handleRemove = (item: FileItem) => {
    setFileItems(fileItems.filter((v) => v.file !== item.file));
    onParentChangeState(false);
  };

  const uploadeLabelId = useMemo(() => {
    const msgId: string[] = [];
    if (props.messageOption?.uploadLabelId) {
      msgId.push(props.messageOption.uploadLabelId.prefixId ?? '');
      msgId.push(props.messageOption.uploadLabelId.viewId ?? '');
      msgId.push(props.messageOption.uploadLabelId.id ?? '');
    } else {
      msgId.push('FILE_UPLOAD');
      msgId.push('file-chooser');
    }
    return msgId.filter((v) => !!v).join('.');
  }, [props.messageOption?.uploadLabelId]);

  // ファイルアップ機能
  const getFileUploadArea = () => {
    return (
      <div className="FileUploaderArea">
        {/* 説明エリア */}
        {props.fileChooserComment && props.fileChooserComment}
        <div
          className={`FileUploaderSelectParts ${dndEnabled ? 'center' : ''}`}
        >
          <div className={`${disabled ? 'disabled' : ''}`}>
            <label className="file-chooser">
              {GetMessageWithIntl(intl, { id: uploadeLabelId })}
              {!props.messageOption?.omitAttachedIcon && <AttachFileIcon />}
              <input
                type="file"
                multiple={multiple}
                onChange={handleFileChange}
                disabled={disabled}
              />
            </label>
          </div>
        </div>
      </div>
    );
  };

  const getUploadArea = (dndEnabled: boolean) => {
    if (!dndEnabled) {
      return getFileUploadArea();
    } else {
      return (
        <div
          style={style}
          onDrop={handleFileDrop}
          onDragOver={(e) => {
            if (!disabled) {
              e.preventDefault();
            }
          }}
        >
          {getFileUploadArea()}
        </div>
      );
    }
  };

  return (
    <div className={`FileUploader ${FileUploaderClassName}`}>
      {/* アップロード */}
      {getUploadArea(dndEnabled)}

      {/* バリデーションエラーメッセージ */}
      {message.length !== 0 && <ErrorMessage message={message}></ErrorMessage>}

      {/* アップロード関係メッセージ */}
      <div className="uploadStatus">
        {fileItems.map((v, i) => (
          <Fragment key={i}>
            <div className="status">{v.status}</div>
            <div className="actionIcon">
              <IconButton
                name="fileDelete"
                className="btn btn-normal"
                properties={[
                  {
                    name: 'fileDelete',
                    propertyName: 'fileDelete',
                    propertyValue: 'fileDelete',
                  },
                ]}
                onClick={() => handleRemove(v)}
                iconType="clear"
                buttonType="danger"
                disabled={v.status === 'uploading'}
              />
            </div>
            <div className="fileName">{v.file.name}</div>
          </Fragment>
        ))}
      </div>
    </div>
  );
}
