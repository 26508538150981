import { useMemo, useState } from 'react';
import {
  GetMessageWithIntl,
  ModalDialogComponent,
  ModalDialogComponentProps,
  Radio,
  RadioItem,
  error,
} from '../..';
import { CaptionButton } from '../Button/CaptionButton';
import { useIntl } from 'react-intl';
import {
  getProgramOptionFilterboxData,
  saveInitialLocalStorageData,
} from '~/shared/utils';
import { useAuth } from '~/shared/contexts/AuthProvider';
import { DataFilterbox, DataFilterboxItem } from '../Filterbox/DataFilterbox';
import { sharelib } from '~/shared/libs/clientsdk';

export interface SelectConnectingDialogOutputOption {
  newSlip: boolean;
  connectingCategory: sharelib.INameOption;
}

export interface SelectConnectingDialogProps {
  isOpen: boolean;
  onDecision: (result: SelectConnectingDialogOutputOption) => void;
  onCancel: () => void;
}

export const SlipTypeValues = ['newSlip', 'existingSlip'];

export const SelectConnectingDialog = (props: SelectConnectingDialogProps) => {
  const intl = useIntl();
  const myEmail = useAuth().user?.email ?? '';

  const radios: RadioItem[] = [
    {
      displayName: GetMessageWithIntl(intl, {
        id: 'newSlip',
        prefixId: 'SelectConnectingDialog',
      }),
      value: SlipTypeValues[0],
    },
    {
      displayName: GetMessageWithIntl(intl, {
        id: 'existingSlip',
        prefixId: 'SelectConnectingDialog',
      }),
      value: SlipTypeValues[1],
    },
  ];

  const connectingCategories = useMemo<DataFilterboxItem[]>(() => {
    const connectingCategories = getProgramOptionFilterboxData('A2000002');
    return connectingCategories;
  }, [intl]);
  const [connectingCategory, setConnectingCategory] = useState<
    DataFilterboxItem[]
  >([]);
  const [slipType, setSlipType] = useState<string>('');

  const handleCancel = () => {
    setConnectingCategory([]);
    setSlipType('');
    props.onCancel();
  };

  const handleDecision = () => {
    const errors: string[] = [];
    if (connectingCategory.length === 0) {
      errors.push(GetMessageWithIntl(intl, { id: 'E0000093' }));
    }
    if (!slipType) {
      errors.push(GetMessageWithIntl(intl, { id: 'E0000154' }));
    }

    if (errors.length > 0) {
      error(errors);
      return;
    }

    saveInitialLocalStorageData('WT_LINKED_WORK_TASK', myEmail);
    saveInitialLocalStorageData('WT_LINKED_ESTIMATE', myEmail);
    saveInitialLocalStorageData('WT_LINKED_SURVEY', myEmail);
    const nameOption = window.App.services.ui.getProgramOptionWithCode(
      'A2000002',
      connectingCategory[0].value
    );
    props.onDecision({
      newSlip: slipType === 'newSlip' ? true : false,
      connectingCategory: nameOption,
    });
    setSlipType('');
    setConnectingCategory([]);
  };

  const elements = (
    <div className="select-connecting-dialog dialog-with-description">
      <div className="description-area">
        <p className="text-box">
          {GetMessageWithIntl(intl, {
            id: 'workTask',
            prefixId: 'SelectConnectingDialogDescription',
          })}
        </p>
      </div>
      <div className="comment-area detail-area">
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-50">
              {/* 紐付け分類 */}
              <DataFilterbox
                data={connectingCategories}
                labelId="SelectConnectingDialog.category"
                searchOption={{ targets: 'displayName' }}
                name="connectingCategory"
                columns={['connectingCategory']}
                onChangeState={(v) => {
                  setConnectingCategory(v);
                }}
                displayOption={{
                  pageSize: 3,
                }}
                value={connectingCategory}
              ></DataFilterbox>
            </div>
          </div>
        </div>
        <div className="input-line mt-1">
          <div className="item-group-100">
            <div className="w-100">
              <Radio
                name="slipType"
                value={slipType}
                items={radios}
                validateOption={{ required: true }}
                onChangeState={setSlipType}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="button-area">
        <CaptionButton
          name="cancelBtn"
          buttonType="cancel"
          className="button"
          caption={GetMessageWithIntl(intl, { id: 'cancel' })}
          onClick={handleCancel}
        />
        <CaptionButton
          name="sendBtn"
          buttonType="basic"
          className="button"
          caption={GetMessageWithIntl(intl, { id: 'decision' })}
          onClick={handleDecision}
        />
      </div>
    </div>
  );

  const openModalProps: ModalDialogComponentProps = {
    cancel: () => {
      props.onCancel();
    },
    send: () => {},
    modalIsOpen: props.isOpen,
    headerLabelId: {
      id: 'SELECT_CONNECTING',
      prefixId: 'DIALOG_TITLE',
    },
    messageLabelId: {},
    elements,
  };

  return <ModalDialogComponent {...openModalProps} />;
};
