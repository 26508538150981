import { mtechnavi, sharelib } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface SurveyReplyDueDateChangeResultRequest {
  actionName: 'changeSurveyRequestDeadline';
  request: SurveyReplyDueDateChangeParam[];
}
interface SurveyReplyDueDateChangeParam {
  surveyRequestId: string;
  replyDueDateDt: sharelib.IDatetime;
  comment: string;
  surveyReplyDueDateChangeRequestId?: string | null;
}

// 回答期日変更
export const surveyReplyDueDateChange = async (
  worker: AppSaveWorker,
  req: SurveyReplyDueDateChangeParam[]
) => {
  const FullMethodName_CreateSurveyReplyDueDateChangeResult =
    'mtechnavi.api.survey.SurveySenderService/CreateSurveyReplyDueDateChangeResult';
  const FullMethodName_SharetoSurveyReplyDueDateChangeResult =
    'mtechnavi.api.survey.SurveySenderService/SharetoSurveyReplyDueDateChangeResult';

  const results = await Promise.all(
    req.map((surveyReplyDueDateChangeResult) =>
      worker.invokeListRequest<
        mtechnavi.api.survey.ICreateSurveyReplyDueDateChangeResultRequest,
        mtechnavi.api.survey.ISurveyReplyDueDateChangeResult
      >(FullMethodName_CreateSurveyReplyDueDateChangeResult, {
        surveyReplyDueDateChangeResult,
      })
    )
  );

  return await Promise.all(
    results.map((result) =>
      worker.invokeListRequest<
        mtechnavi.api.survey.ISharetoSurveyReplyDueDateChangeResultRequest,
        mtechnavi.api.survey.ISharetoSurveyReplyDueDateChangeResultResponse
      >(FullMethodName_SharetoSurveyReplyDueDateChangeResult, {
        surveyReplyDueDateChangeResult: result[0],
      })
    )
  );
};
