import { useToolContext } from '~/shared/contexts/ToolMenuProvider';

/**
 * フォーラム機能を利用するためのカスタムフック
 */
export const useHelp = () => {
  const { viewId, setViewId, setActiveToolType } = useToolContext();

  /** フォーラム UI を閉じる */
  const closeForum = () => {
    setActiveToolType('None');
  };

  return {
    viewId,
    setViewId,
    closeForum,
  };
};
