import { ViewId } from '~/shared/utils';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { useState } from 'react';
import { SimpleIconListView } from '~/shared/components/ui';
import { Property } from 'csstype';
import {
  BusinessUnitFacilityDialog,
  BusinessUnitFacilityFormOption,
} from '~/shared/components/ui/Dialog/BusinessUnitFacilityDialog';

interface FacilitiesTabItemProps {
  businessUnitFacilitiess?: mtechnavi.api.company.IBusinessUnitFacilities[];
  businessUnitBranchs?: mtechnavi.api.company.IBusinessUnitBranch[];
}

interface ViewFacilitiesType {
  businessUnitFacilitiesId: string; // Key
  branchName: string; // 拠点名
  manufacture: string; //メーカー
  modelNumber: string; // 型式
  assetsAmount: string; // 台数
}

const VIEW_ID: ViewId = 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION';

const facilitiesColumns = [
  {
    header: {
      id: 'facility.branchName',
      prefixId: VIEW_ID,
    },
    propertyName: 'branchName',
  },
  {
    header: {
      id: 'facility.manufacture',
      prefixId: VIEW_ID,
    },
    propertyName: 'manufacture',
  },
  {
    header: {
      id: 'facility.modelNumber',
      prefixId: VIEW_ID,
    },
    propertyName: 'modelNumber',
  },
  {
    header: {
      id: 'facility.assetsAmount',
      prefixId: VIEW_ID,
    },
    propertyName: 'assetsAmount',
    align: 'right' as Property.TextAlign,
    width: '6rem',
  },
];

export const FacilitiesTabItem = ({
  businessUnitFacilitiess,
  businessUnitBranchs,
}: FacilitiesTabItemProps) => {
  const [facilitiesFormItems, setFacilitiesFormItems] =
    useState<BusinessUnitFacilityFormOption>();
  const [isFacilitiesDialogOpen, setFacilitiesDialogOpen] = useState(false);

  const getFacilitiesItems = (): ViewFacilitiesType[] => {
    if (!businessUnitFacilitiess) {
      return [];
    }
    const vals: ViewFacilitiesType[] = [];
    businessUnitFacilitiess?.map((v) =>
      vals.push({
        businessUnitFacilitiesId: v.businessUnitFacilitiesId ?? '',
        branchName:
          businessUnitBranchs?.find(
            (branch) => branch.businessUnitBranchId === v.businessUnitBranchId
          )?.displayName ?? '',
        manufacture: v.manufacture ?? '',
        modelNumber: v.modelNumber ?? '',
        assetsAmount: v.assetsAmount ? v.assetsAmount.low.toString() : '',
      })
    );
    vals.sort((val1, val2) =>
      val1.branchName.localeCompare(val2.branchName, 'ja')
    );
    return vals;
  };

  // [実行処理]設備情報確認ダイアログ
  const handleFacilitiesDialog = (v: ViewFacilitiesType) => {
    const formItem: mtechnavi.api.company.IBusinessUnitFacilities =
      businessUnitFacilitiess?.find(
        (w) => w.businessUnitFacilitiesId === v.businessUnitFacilitiesId
      ) ?? {};
    if (formItem) {
      setFacilitiesFormItems({
        buFacilities: formItem as mtechnavi.api.company.IBusinessUnitFacilities,
        companyId: formItem.companyId ?? '',
        isShared: true,
      });
    }
    setFacilitiesDialogOpen(true);
  };

  return (
    <>
      <div className="tab-info-body">
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              <SimpleIconListView
                data={getFacilitiesItems()}
                viewOptions={{
                  previewRowCount: 10,
                  keyColumn: 'businessUnitFacilitiesId',
                  columns: facilitiesColumns,
                  omitFooter: true,
                }}
                actionOptions={{}}
                iconMenuOptions={{
                  iconMenu: [
                    {
                      name: 'description',
                      displayName: '確認',
                      func: (v: ViewFacilitiesType) => {
                        handleFacilitiesDialog(v);
                      },
                    },
                  ],
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {/* 設備情報確認ダイアログ */}
      <BusinessUnitFacilityDialog
        isOpen={isFacilitiesDialogOpen}
        inputMode={false}
        inputOption={facilitiesFormItems ?? { companyId: '' }}
        onCancel={() => {
          setFacilitiesDialogOpen(false);
        }}
        onDecision={() => {}}
      />
    </>
  );
};
