import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface ExecSurveyOpenRequest {
  actionName: 'execSurveyOpen';
  request: SurveyOpenRequestParam;
}
interface SurveyOpenRequestParam {
  surveyRequestId: string;
}

export const execSurveyOpenRequest = async (
  worker: AppSaveWorker,
  req: SurveyOpenRequestParam
) => {
  const openRequestFullMethodName =
    'mtechnavi.api.survey.SurveyReceiverService/OpenSurveyRequest';
  const sharetoRequestFullMethodName =
    'mtechnavi.api.survey.SurveyService/SharetoOpened';
  const openedResult = await worker.invokeListRequest<
    mtechnavi.api.survey.IOpenSurveyRequestRequest,
    mtechnavi.api.survey.IOpenSurveyRequestResponse
  >(openRequestFullMethodName, {
    surveyRequestId: req.surveyRequestId,
  });
  if (openedResult && openedResult.length > 0) {
    await worker.invokeListRequest<
      mtechnavi.api.survey.ISharetoOpenedRequest,
      mtechnavi.api.survey.ISharetoOpenedResponse
    >(sharetoRequestFullMethodName, {
      opened: openedResult[0].opened,
    });
  }
  return openedResult;
};
