import React, { CSSProperties, ReactNode, useMemo } from 'react';
import { Property } from '~/shared/services';
import { GenerallyIconType } from '~/shared/components/parts/Button/GenerallyIcons';
import {
  IconWithCaptionButton as IconWithCaptionButtonComponent,
  ButtonType,
} from '~/shared/components/parts/Button/IconWithCaptionButton';
import { getButtonProperties } from './utils';

export interface IconWithCaptionButtonProps {
  name: string;
  caption?: string;
  icon?: ReactNode;
  iconType?: GenerallyIconType;
  buttonType?: ButtonType;
  className?: string;
  onClick: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled?: boolean;
  properties?: Property[];
}

export const IconWithCaptionButton = (props: IconWithCaptionButtonProps) => {
  const [buttonStyle, disabled] = useMemo<[CSSProperties, boolean]>(() => {
    return getButtonProperties(props.properties, props.disabled);
  }, [props.properties, props.disabled]);

  return (
    <>
      <IconWithCaptionButtonComponent
        name={props.name}
        className={props.className}
        caption={props.caption}
        icon={props.icon}
        iconType={props.iconType}
        buttonType={props.buttonType}
        style={buttonStyle}
        onClick={props.onClick}
        disabled={disabled}
      />
    </>
  );
};
