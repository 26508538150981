import { useMemo, CSSProperties } from 'react';
import { Property } from '~/shared/services';
import { GetMessageComponent } from '~/shared/components/parts/Message/Message';
import {
  TimeH as TimeHComponent,
  TimeHM as TimeHMComponent,
  TimeHMS as TimeHMSComponent,
  TimesUnselectedOption,
  TimesValidateOption,
} from '~/shared/components/parts/Times/Times';
import { getProperty } from '../common';

export interface TimesProps {
  name: string;
  className?: string;
  value?: string | Date;
  unselectedOption?: TimesUnselectedOption;
  validator?: (v: string[]) => string[];
  validateOption?: TimesValidateOption;
  disabled?: boolean;
  style?: CSSProperties;
  onChangeState?: (arg: Date | null) => void; //親要素へ値を渡すためのプロパティ
  labelId?: string;
  properties?: Property[];
  columns?: string[];
}

export function TimeH(props: TimesProps) {
  const [componentStyle, viewStyle, _disabled] = useMemo<
    [CSSProperties, CSSProperties, boolean]
  >(() => {
    return getProperty(props.name, props.columns, props.properties);
  }, [props.properties, props.columns, props.name]);
  const disabled = _disabled ? _disabled : props.disabled ?? false;
  return (
    <div style={viewStyle}>
      <span className="caption">
        {props.labelId && (
          <div className={props.validateOption?.required ? 'required ' : ''}>
            <div
              className={`topping-label ${
                disabled ? 'disabled-topping-label' : ''
              }`}
            >
              <GetMessageComponent id={props.labelId} />
            </div>
          </div>
        )}
      </span>
      <TimeHComponent
        name={props.name}
        className={props.className}
        value={props.value}
        unselectedOption={props.unselectedOption}
        validator={props.validator}
        validateOption={props.validateOption}
        disabled={disabled}
        style={componentStyle}
        onChangeState={props.onChangeState}
      ></TimeHComponent>
    </div>
  );
}

export function TimeHM(props: TimesProps) {
  const [componentStyle, viewStyle, _disabled] = useMemo<
    [CSSProperties, CSSProperties, boolean]
  >(() => {
    return getProperty(props.name, props.columns, props.properties);
  }, [props.properties, props.columns, props.name]);
  const disabled = _disabled ? _disabled : props.disabled ?? false;
  return (
    <div style={viewStyle}>
      {props.labelId && (
        <div
          className={`topping-label ${
            disabled ? 'disabled-topping-label' : ''
          }`}
        >
          <GetMessageComponent id={props.labelId} />
        </div>
      )}
      <TimeHMComponent
        name={props.name}
        className={props.className}
        value={props.value}
        unselectedOption={props.unselectedOption}
        validator={props.validator}
        validateOption={props.validateOption}
        disabled={disabled}
        style={componentStyle}
        onChangeState={props.onChangeState}
      ></TimeHMComponent>
    </div>
  );
}

export function TimeHMS(props: TimesProps) {
  const [componentStyle, viewStyle, _disabled] = useMemo<
    [CSSProperties, CSSProperties, boolean]
  >(() => {
    return getProperty(props.name, props.columns, props.properties);
  }, [props.properties, props.columns, props.name]);
  const disabled = _disabled ? _disabled : props.disabled ?? false;
  return (
    <div style={viewStyle}>
      {props.labelId && (
        <div
          className={`topping-label ${
            disabled ? 'disabled-topping-label' : ''
          }`}
        >
          <GetMessageComponent id={props.labelId} />
        </div>
      )}
      <TimeHMSComponent
        name={props.name}
        className={props.className}
        value={props.value}
        unselectedOption={props.unselectedOption}
        validator={props.validator}
        validateOption={props.validateOption}
        disabled={disabled}
        style={componentStyle}
        onChangeState={props.onChangeState}
      ></TimeHMSComponent>
    </div>
  );
}
