export interface ResourceInfo {
  typeName: string;
  resourceId: string;
}

/** 辞書 prefix */
export const ForumPrefixId = 'Forum';

/** 最大スレッド数 */
export const ForumMaxThreadCount = 20;

/** 最大コメント数 */
export const ForumMaxCommentCount = 50;

/** スレッドタイトル文字数 */
export const ForumTitleMaxLength = 40;

/** コメント文字数 */
export const ForumCommentMaxLength = 100;

/** フォーラムが使用可能なViewID一覧 */
export const FORUM_USABLE_VIEW_IDS = [
  /** 依頼確認(送信) */
  'SURVEY_REQUEST_SENDER_CONFIRMATION',
  /** 依頼確認(受信) */
  'SURVEY_REQUEST_RECEIVER_CONFIRMATION',
  /** 指図案件確認(依頼情報・作業情報) */
  'WT_WORKTASK_CONFIRMATION',
  /** 見積依頼確認 */
  'EST_ESTIMATE_REQUEST_CONFIRMATION',
  /** 見積回答確認 */
  'EST_ESTIMATE_RESULT_CONFIRMATION',
  /** 図面プレビュー（見積（依頼）図面コメント） */
  'BLUEPRINT_REQUEST_COMMENT',
  /** 図面プレビュー（見積（回答）図面コメント） */
  'BLUEPRINT_RESULT_COMMENT',
] as const;

/** フォーラムが使用可能なViewID */
export type ForumUsableViewId = typeof FORUM_USABLE_VIEW_IDS[number];

/** スレッドタイプ(A0000038) */
export const ForumThreadType = {
  /** お知らせ */
  Information: 'B01',
  /** 社内 */
  Internal: 'B02',
  /** DM */
  External: 'B03',
} as const;
export type ForumThreadType =
  typeof ForumThreadType[keyof typeof ForumThreadType];

/** スレッドタイプに対する名称 */
export const ThreadTypeName: {
  [key in ForumThreadType | '']: string;
} = {
  B01: 'お知らせ',
  B02: '社内',
  B03: 'DM',
  '': '',
};

/** フォーラムレコードタイプ(機能名) */
export const ForumTypeName = {
  /** 依頼確認 */
  Survey: 'mtechnavi.api.survey.SurveyRequest',
  /** 指図案件確認 */
  WorkTask: 'mtechnavi.api.worktask.WorkTask',
  /** 見積(依頼/回答) */
  Estimate: 'mtechnavi.api.estimation.EstimateRequest',
  /** 図面プレビュー（見積（依頼/回答）図面コメント） */
  EstimateBlueprint: 'mtechnavi.api.estimation.EstimateRequestDetail',
} as const;
export type ForumTypeName = typeof ForumTypeName[keyof typeof ForumTypeName];

/** フォーラム利用 ViewId と利用可能なスレッドタイプのマッピング */
export const ForumTypeThreadTypeMap: {
  [key in ForumUsableViewId]?: ForumThreadType[];
} = {
  SURVEY_REQUEST_SENDER_CONFIRMATION: [ForumThreadType.External],
  SURVEY_REQUEST_RECEIVER_CONFIRMATION: [ForumThreadType.External],
  WT_WORKTASK_CONFIRMATION: [ForumThreadType.Internal],
  EST_ESTIMATE_REQUEST_CONFIRMATION: [
    ForumThreadType.Information,
    ForumThreadType.Internal,
    ForumThreadType.External,
  ],
  EST_ESTIMATE_RESULT_CONFIRMATION: [ForumThreadType.External],
  BLUEPRINT_REQUEST_COMMENT: [
    ForumThreadType.Information,
    ForumThreadType.Internal,
    ForumThreadType.External,
  ],
  BLUEPRINT_RESULT_COMMENT: [ForumThreadType.External],
};
