import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';
import { FullMethodName_SaveTenantManagementWithRelatedItems } from 'worker/fullMethodName/crud';

// 新規登録のプロパティに限定したパラメータ
type CreateTenantRequestParam = {
  tenantManagement?: Pick<
    mtechnavi.api.admin.ITenantManagement,
    'tenantCode' | 'tenantDisplayName'
  > | null;
  billingCompany?: Pick<
    mtechnavi.api.company.IBillingCompany,
    | 'displayName'
    | 'country'
    | 'phoneNumber'
    | 'region'
    | 'addressLines'
    | 'adminDisplayName'
    | 'adminEmail'
  > | null;
};

export const createTenant = async (
  worker: AppSaveWorker,
  req: CreateTenantRequestParam
) => {
  return await worker.invokeListRequest<
    mtechnavi.api.admin.ISaveTenantManagementWithRelatedItemsRequest,
    mtechnavi.api.admin.ITenantManagementContent
  >(FullMethodName_SaveTenantManagementWithRelatedItems, req);
};
