import { useIntl } from 'react-intl';
import {
  GetMessageWithIntl,
  MessageProps,
  ModalDialogComponent,
  ModalDialogComponentProps,
} from '../..';
import { CaptionButton } from '../Button';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { useState } from 'react';
import { Textarea } from '../Textarea';
import { Textbox } from '../Textbox';
import { getDateFormat, nameOptionToLocaleString } from '~/shared/utils';

const DialogId = 'InformationDialog';

interface InformationDialogMessageOption {
  headerLabelId: MessageProps;
}
interface InformationDialogInputOption {
  informationList: mtechnavi.api.admin.IInformation[];
}
interface InformationDialogProps {
  isOpen: boolean;
  messageOption: InformationDialogMessageOption;
  inputOption: InformationDialogInputOption;
  onCancel: () => void;
}
export const InformationDialog = ({
  isOpen,
  messageOption,
  inputOption,
  onCancel,
}: InformationDialogProps) => {
  const intl = useIntl();
  const [viewIndex, setViewIndex] = useState<number | null>(0);
  const viewInformation =
    viewIndex !== null ? inputOption.informationList.at(viewIndex) : null;
  const isLastInformation =
    viewIndex === inputOption.informationList.length - 1;

  const buttonLabel = isLastInformation
    ? GetMessageWithIntl(intl, { prefixId: DialogId, id: 'close' })
    : GetMessageWithIntl(intl, { prefixId: DialogId, id: 'nextInformation' });

  const handleConfirm = () => {
    if (isLastInformation) {
      setViewIndex(0);
      onCancel();
      return;
    }
    setViewIndex((viewIndex ?? 0) + 1);
  };

  const elements = (
    <div className="InformationDialog">
      <div className="detail-area">
        <div className="right-side-label">
          {viewInformation?.publishedAt && (
            <>
              {GetMessageWithIntl(intl, {
                prefixId: DialogId,
                id: 'updateAt',
              })}
              :{getDateFormat(viewInformation?.publishedAt, 'YYYY/MM/DD')}
            </>
          )}
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-50">
              <Textbox
                name="subject"
                labelId={`${DialogId}.subject`}
                columns={['subject']}
                type="text"
                value={viewInformation?.subject ?? ''}
                disabled={true}
              />
            </div>
            <div className="w-25">
              <Textbox
                name="type"
                labelId={`${DialogId}.type`}
                columns={['type']}
                type="text"
                value={nameOptionToLocaleString(
                  intl,
                  viewInformation?.category
                )}
                disabled={true}
              />
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              <Textarea
                className="w-100 mh-middle"
                name="content"
                columns={['content']}
                labelId={`${DialogId}.content`}
                value={viewInformation?.content ?? ''}
                disabled={true}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="button-area">
        <CaptionButton
          name="close"
          buttonType={isLastInformation ? 'cancel' : 'basic'}
          className="button"
          caption={buttonLabel}
          onClick={() => {
            handleConfirm();
          }}
        />
      </div>
    </div>
  );

  const modalProps: ModalDialogComponentProps = {
    cancel: handleConfirm,
    send: () => {},
    modalIsOpen: isOpen,
    headerLabelId: messageOption.headerLabelId,
    messageLabelId: {},
    elements,
  };

  return (
    <>
      <ModalDialogComponent {...modalProps} />
    </>
  );
};
