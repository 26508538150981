import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface DeleteAdminAccountList {
  actionName: 'deleteAdminAccountList';
  request: mtechnavi.api.tenantadmin.IUserAttribute[];
}

export const deleteAdminAccountList = async (
  worker: AppSaveWorker,
  request: mtechnavi.api.tenantadmin.IUserAttribute[]
) => {
  const fullMethodName =
    'mtechnavi.api.tenantadmin.TenantAdminService/DeleteUserAndUserAttribute';
  const result = request.map((userAttr) => {
    return worker.invokeListRequest(fullMethodName, {
      user: userAttr.user,
      userAttribute: userAttr,
    });
  });
  await Promise.all(result);
};
