import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface ExecEstimateResultChangeRequestRequest {
  actionName: 'execEstimateResultChangeRequest';
  request: mtechnavi.api.estimation.IEstimateResultChangeRequest;
}

export const execEstimateResultChangeRequest = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.estimation.IEstimateResultChangeRequest
) => {
  const FullMethodName_CreateEstimateResultChangeRequest =
    'mtechnavi.api.estimation.EstimateSenderService/CreateEstimateResultChangeRequest';
  const FullMethodName_SharetoEstimateResultChangeRequest =
    'mtechnavi.api.estimation.EstimateSenderService/SharetoEstimateResultChangeRequest';

  const results = await worker.invokeListRequest<
    mtechnavi.api.estimation.ICreateEstimateResultChangeRequestRequest,
    mtechnavi.api.estimation.EstimateResultChangeRequest
  >(FullMethodName_CreateEstimateResultChangeRequest, {
    estimateResultChangeRequest: req,
  });
  if (results && results.length > 0) {
    await worker.invokeListRequest<
      mtechnavi.api.estimation.ISharetoEstimateResultChangeRequestRequest,
      mtechnavi.api.estimation.SharetoEstimateResultChangeRequestResponse
    >(FullMethodName_SharetoEstimateResultChangeRequest, {
      estimateResultChangeRequestId: results[0].estimateResultChangeRequestId,
    });
  }
};
