import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface SaveOcpCertificate {
  actionName: 'saveOcpCertificate';
  request: SaveOcpCertificateRequestParam;
}

interface SaveOcpCertificateRequestParam {
  businessUnitCertificates: mtechnavi.api.company.IBusinessUnitCertificate[];
  companyId: string;
}

export const saveOcpCertificate = async (
  worker: AppSaveWorker,
  req: SaveOcpCertificateRequestParam
) => {
  const ListMethodName =
    'mtechnavi.api.company.BusinessUnitManagementService/ListBusinessUnitCertificates';
  const createMethodName =
    'mtechnavi.api.company.BusinessUnitManagementService/CreateBusinessUnitCertificate';
  const updateMethodName =
    'mtechnavi.api.company.BusinessUnitManagementService/UpdateBusinessUnitCertificate';
  const deleteMethodName =
    'mtechnavi.api.company.BusinessUnitManagementService/DeleteBusinessUnitCertificate';

  const CertificateList = (await worker.invokeListRequest(ListMethodName, {
    companyIds: [req.companyId],
  })) as mtechnavi.api.company.IBusinessUnitCertificate[];

  const result = req.businessUnitCertificates.map((item) => {
    if (item.businessUnitCertificateId) {
      console.info('saveOcpCertificate updateMethodName');
      return worker.invokeListRequest(updateMethodName, {
        businessUnitCertificate: item,
      });
    } else {
      console.info('saveOcpCertificate createMethodName');
      return worker.invokeListRequest(createMethodName, {
        businessUnitCertificate: item,
      });
    }
  });
  await Promise.all(result);

  const deleteCheckList = req.businessUnitCertificates.map((e) => {
    return e.businessUnitCertificateId;
  });
  const deleteResult = CertificateList.map((Certificate) => {
    if (!deleteCheckList.includes(Certificate.businessUnitCertificateId)) {
      console.info('saveOcpCertificate deleteMethodName');
      return worker.invokeListRequest(deleteMethodName, {
        businessUnitCertificate: Certificate,
      });
    }
  });
  await Promise.all(deleteResult);
};
