import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface SaveEstimateManagementWithRelatedItemsRequest {
  actionName: 'saveEstimateManagementWithRelatedItems';
  request: SaveEstimateManagementWithRelatedItemsParam;
}

interface SaveEstimateManagementWithRelatedItemsParam {
  estimateManagement: mtechnavi.api.estimation.IEstimateManagement;
  estimateRequest: mtechnavi.api.estimation.IEstimateRequest;
  estimateRequestDetails: mtechnavi.api.estimation.IEstimateRequestDetail[];
  estimateRequestUnits: mtechnavi.api.estimation.IEstimateRequestUnit[];
}

/**
 * 見積管理関連データ を 保存 する
 */
export const saveEstimateManagementWithRelatedItems = async (
  worker: AppSaveWorker,
  req: SaveEstimateManagementWithRelatedItemsParam
) => {
  const saveFullMethodName =
    'mtechnavi.api.estimation.EstimateSenderService/SaveEstimateManagementWithRelatedItems';

  // delete/insert処理のため、idやタイムスタンプを削除する
  req.estimateRequestDetails.forEach((detail) => {
    detail.estimateRequestDetailId = null;
    detail.createdAt = null;
    detail.updatedAt = null;
  });
  req.estimateRequestUnits.forEach((unit) => {
    unit.estimateRequestUnitId = null;
    unit.estimateRequestUnitAutoName = null;
    unit.status = null;
    unit.createdAt = null;
    unit.updatedAt = null;
  });

  // 見積管理関連データを更新
  return await worker.invokeListRequest<
    mtechnavi.api.estimation.ISaveEstimateManagementWithRelatedItemsRequest,
    mtechnavi.api.estimation.ISaveEstimateManagementWithRelatedItemsResponse
  >(saveFullMethodName, {
    estimateManagement: req.estimateManagement,
    estimateRequest: req.estimateRequest,
    estimateRequestDetails: req.estimateRequestDetails,
    estimateRequestUnits: req.estimateRequestUnits,
  });
};
