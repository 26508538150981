import { google, mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface DeleteSurveyRequest {
  actionName: 'deleteSurveyRequest';
  request: DeleteSurveyParam;
}
interface DeleteSurveyParam {
  surveyBaseRequestId: string;
  updatedAt?: Long | null;
}

// 依頼削除
export const deleteSurveyBaseRequest = async (
  worker: AppSaveWorker,
  req: DeleteSurveyParam
) => {
  const FullMethodName_DeleteSurveyBaseRequest =
    'mtechnavi.api.survey.SurveySenderService/DeleteSurveyBaseRequest';

  return await worker.invokeListRequest<
    mtechnavi.api.survey.IDeleteSurveyBaseRequestRequest,
    google.protobuf.Empty
  >(FullMethodName_DeleteSurveyBaseRequest, {
    surveyBaseRequest: req,
  });
};
