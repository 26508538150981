import { useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { SaveEstimateSelectionAndApprovalRequestRequestParam } from 'worker/saveworker/estimate/saveEstimateSelectionAndApprovalRequest';
import {
  Container,
  GetMessageWithIntl,
  LoadingIcon,
  Toast,
  error,
} from '~/shared/components';
import {
  ApprovalRequestDialog,
  ConfirmationDialog,
  ListView,
  InitialFilter,
  InitialFilterItem,
  MenuActionItem,
  OutputDialog,
  OutputDialogOutput,
  OutputOption,
  Preset,
  Property,
  getAltDisplaySchema,
  getBooleanDataFormetterSchema,
  getCommaTypeNumberSchema,
  getDayFormetterDisplaySchema,
} from '~/shared/components/ui';
import {
  EstimateSelectionDialog,
  EstimateSelectionDialogInputOption,
  EstimateSelectionDialogResult,
} from '~/shared/components/ui/Dialog/EstimateSelectionDialog';
import { useAuth } from '~/shared/contexts/AuthProvider';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { PresetItem } from '~/shared/services';
import {
  FullMethodName_ListEstimateRequestUnitSummarys,
  PageState,
  ViewId,
  autoDownloadFileWithDate,
  getExceptionMessage,
  getFilterAndSortData,
  getPresetAndSchema,
  getWorkerExceptionMessage,
  convertOrganizationStructureReference,
  convertUserReference,
} from '~/shared/utils';
import {
  isCanBeExportOrder,
  isCanBeSelection,
} from '~/tenant/estimate/utils/validator';
import { getOneByEstimateSelection } from '~/tenant/estimate/utils';
import { FullMethodName_ListUserAttributes } from '~/worker';

const VIEW_ID: ViewId = 'EST_ESTIMATE_REQUEST_DETAIL_LIST';

export function EstEstimateRequestDetailList() {
  const intl = useIntl();
  const navigate = useNavigate();
  const myEmail = useAuth().user?.email ?? '';
  const location = (useLocation().state as PageState) ?? [];
  const [isLoading, setLoading] = useState(false);
  const [isReload, setReload] = useState(false);

  const [schema, setSchema] = useState<Property[]>([]);
  const [preset, setPreset] = useState<Preset>({
    filter: {},
    propertyNames: [],
  });
  const [presetItems, setPresetItems] = useState<PresetItem[]>();
  const listItemRef = useRef<
    mtechnavi.api.estimation.IEstimateRequestUnitSummary[]
  >([]);
  const listAllItemRef = useRef<
    mtechnavi.api.estimation.IEstimateRequestUnitSummary[]
  >([]);
  const listSelectedItemRef = useRef<
    mtechnavi.api.estimation.IEstimateRequestUnitSummary[]
  >([]);

  const [isShowDownload, setShowDownload] = useState(false);

  // 選考ダイアログ制御用
  const [isShowSelection, setShowSelection] = useState(false);
  const [selectionOption, setSelectionOption] =
    useState<EstimateSelectionDialogInputOption>({});

  // 承認依頼ダイアログ制御用
  const [isShowApprovalRequest, setShowApprovalRequest] = useState(false);
  const approvalRequestResolverRef =
    useRef<
      (value: OutputOption | null | PromiseLike<OutputOption | null>) => void
    >();
  const organizations = useRef<mtechnavi.api.company.IOrganization[]>([]);
  const organizationRelations = useRef<
    mtechnavi.api.company.IOrganizationRelation[]
  >([]);

  // 確認ダイアログ制御用
  const [isShowConfirmation, setShowConfirmation] = useState(false);
  const [confirmationMessageId, setConfirmationMessageId] = useState('');
  const confirmResolverRef =
    useRef<(value: boolean | PromiseLike<boolean>) => void>();

  const naviFilterItems = useMemo(() => {
    const naviFilterParams = location?.naviFilters;
    if (!naviFilterParams) {
      return;
    }
    // 前回のフィルタ条件が残っていたらそちらを優先する
    const pageState = getFilterAndSortData(VIEW_ID, myEmail);
    if (pageState?.filter) {
      return;
    }
    const listViewFilter: InitialFilter = { info: [] };
    naviFilterParams.forEach((param) => {
      const filterItems: InitialFilterItem[] = param.value.map((v) => {
        return { targetKey: param.key, targetValue: v };
      });
      listViewFilter.info.push(...filterItems);
    });

    if (listViewFilter.info.length === 0) {
      return;
    }
    return listViewFilter;
    // location が変わった時にだけ動けば良い
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const setMenuActionItem = (): MenuActionItem[] => {
    const menuActionItems: MenuActionItem[] = [];
    menuActionItems.push({
      menuActionType: 'headerIconMenu',
      menu: [
        // 戻る
        { name: 'back', func: handleBack },
        // ダウンロード
        { name: 'download', func: handleShowDownloadDialog },
      ],
      maxMenuColumn: 1,
    });
    menuActionItems.push({
      menuActionType: 'listIconMenu',
      menu: [
        // 確認
        { name: 'description', func: handleConfirmation },
        // 選考
        { name: 'unchecked', func: handleShowSelection },
      ],
    });
    return menuActionItems;
  };

  /** 戻るボタン */
  const handleBack = () => {
    navigate('/estimate/est-estimate-request-list');
  };

  /** ダウンロードダイアログ表示 */
  const handleShowDownloadDialog = (ids?: string[]) => {
    const targets = listItemRef.current.filter((v) =>
      ids?.includes(v.estimateRequestUnitSummaryId ?? '')
    );
    if (
      targets.some((item) => {
        return !isCanBeExportOrder(item.status?.systemName);
      })
    ) {
      error([GetMessageWithIntl(intl, { id: 'E0000020' })]);
      return;
    }
    if (targets.some((item) => !item.adoption)) {
      error([GetMessageWithIntl(intl, { id: 'E0000132' })]);
      return;
    }
    listSelectedItemRef.current = targets;
    setShowDownload(true);
  };
  /** ダウンロード処理 */
  const handleDownload = async (v: OutputDialogOutput) => {
    setLoading(true);
    setReload(false);

    const targets =
      v.outputVolume === 'selectedOnly' ? listSelectedItemRef : listAllItemRef;
    const ids: string[] = targets.current.map(
      (item) => item.estimateRequestUnitSummaryId || ''
    );
    try {
      const format = await window.App.services.ui.getFileFormat('order', intl);

      const result = (await window.App.services.ui.worker.apiCall({
        actionName: 'exportOrders',
        request: { ids, format },
      })) as mtechnavi.api.estimation.IExportOrdersResponse[];

      if (result.length) {
        autoDownloadFileWithDate(
          GetMessageWithIntl(intl, {
            viewId: VIEW_ID,
            id: 'orderExportedFile',
          }),
          'csv',
          result.at(0)?.assetId ?? ''
        );
        setReload(true);
      }
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      return;
    } finally {
      setLoading(false);
    }
  };

  /** 一覧の確認アイコンボタン処理 */
  const handleConfirmation = (ids?: string[]) => {
    const targetIds = listItemRef.current
      .filter((v) => ids?.includes(v.estimateRequestUnitSummaryId ?? ''))
      .map((w) => w.estimateRequestId ?? '');
    const state: PageState = {
      ids: targetIds,
      sourceViewId: VIEW_ID,
      naviFilters: location?.naviFilters,
      baseViewOption: { sourceViewId: VIEW_ID },
    };
    navigate('/estimate/est-estimate-request-confirmation', { state });
  };

  /** 一覧の選考アイコンボタン処理 */
  const handleShowSelection = async (ids?: string[]) => {
    if (!ids || ids.length !== 1) {
      return;
    }
    const target =
      listItemRef.current.find(
        (v) => v.estimateRequestUnitSummaryId === ids[0]
      ) ?? {};
    if (!isCanBeSelection(target.status?.systemName)) {
      error([GetMessageWithIntl(intl, { id: 'E0000020' })]);
      return;
    }

    const request: mtechnavi.api.estimation.IEstimateRequest = {
      estimateRequestId: target.estimateRequestId,
      displayName: target.displayName,
    };

    const requestUnit: mtechnavi.api.estimation.IEstimateRequestUnit = {
      estimateRequestUnitId: target.estimateRequestUnitSummaryId,
      estimateRequestId: target.estimateRequestId,
      estimateRequestUnitAutoName: target.estimateRequestUnitAutoName,
      displayName: target.estimateRequestUnit,
    };

    const selection = await getOneByEstimateSelection(
      target?.estimateRequestUnitSummaryId || ''
    );
    setSelectionOption({
      request,
      requestUnit,
      data: {
        ...selection,
        estimateRequestId: request?.estimateRequestId,
      },
    });
    setShowSelection(true);
  };

  /** 選考ダイアログ確定時の処理 */
  const handleSelection = async (result: EstimateSelectionDialogResult) => {
    setReload(false);
    try {
      if (!result.estimateSelection?.notification) {
        if (!(await confirmation('save'))) {
          return;
        }
        setLoading(true);
        // 見積選考データの保存
        await saveEstimateSelection({
          selection: {
            estimateSelection: result.estimateSelection,
            estimateRequestUnitId: result.estimateRequestUnitId,
          },
        });
      } else {
        const approvalReq = await approvalRequest();
        if (!approvalReq) {
          return;
        }
        setLoading(true);
        const approvalPlanOrganization =
          await convertOrganizationStructureReference(
            approvalReq.approvalOrganizationUnit.at(0)?.value
          );
        const approvalPlanStaff = await convertUserReference(
          approvalReq?.approvalOrganizationStaff.at(0)?.value ?? ''
        );
        // 見積選考データの保存・承認依頼の作成
        await saveEstimateSelection({
          selection: {
            estimateSelection: result.estimateSelection,
            estimateRequestUnitId: result.estimateRequestUnitId,
          },
          approvalRequest: {
            approvalPlanOrganization,
            approvalPlanStaff,
            comment: approvalReq.comment,
          },
        });
      }
      setShowSelection(false);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
    setReload(true);
  };

  /** 見積選考データの保存と承認依頼の作成 */
  const saveEstimateSelection = async (
    request: SaveEstimateSelectionAndApprovalRequestRequestParam
  ) => {
    return await window.App.services.ui.worker.apiCall({
      actionName: 'saveEstimateSelectionAndApprovalRequest',
      request,
    });
  };

  const approvalRequest = (): Promise<OutputOption | null> => {
    setShowApprovalRequest(true);
    return new Promise((resolve) => {
      approvalRequestResolverRef.current = resolve;
    });
  };

  const handelApprovalRequest = (result: OutputOption | null) => {
    approvalRequestResolverRef.current &&
      approvalRequestResolverRef.current(result);
    setShowApprovalRequest(false);
  };

  const confirmation = (messageId: string): Promise<boolean> => {
    setConfirmationMessageId(messageId);
    setShowConfirmation(true);
    return new Promise((resolve) => {
      confirmResolverRef.current = resolve;
    });
  };

  const handleConfirm = (isConfirmed: boolean) => {
    confirmResolverRef.current && confirmResolverRef.current(isConfirmed);
    setShowConfirmation(false);
  };

  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        const { childrenPresetItem, schemas, preset } =
          await getPresetAndSchema(VIEW_ID, [
            FullMethodName_ListEstimateRequestUnitSummarys,
          ]);

        let schema = schemas[0];
        schema = getAltDisplaySchema(
          schema,
          ['status.displayNameLang'],
          intl.locale
        );
        schema = getDayFormetterDisplaySchema(
          schema,
          ['estimateDeadlineDt', 'deliveryTimeDt'],
          { dayOpts: { formatType: 'YYYY/MM/DD', isAccuracy: true } }
        );
        schema = getDayFormetterDisplaySchema(schema, ['replyedAt'], {
          dayOpts: { formatType: 'YYYY/MM/DD' },
        });
        schema = getCommaTypeNumberSchema(schema, ['amount.amountNum']);
        schema = getBooleanDataFormetterSchema(schema, [
          'adoption',
          'orderExported',
        ]);

        setPresetItems(childrenPresetItem);
        setSchema(schema);
        setPreset(preset);
        organizations.current = await window.App.services.ui.listOrganization();
        organizationRelations.current =
          await window.App.services.ui.listOrganizationRelation();
      } catch (err) {
        error(getExceptionMessage(intl, err));
        throw err;
      } finally {
        setLoading(false);
      }
    })();
  }, [intl]);

  return (
    <>
      <Container viewId={VIEW_ID}>
        <div className="EstEstimateRequestDetailList">
          <ListView
            isReload={isReload}
            fullMethodName={FullMethodName_ListEstimateRequestUnitSummarys}
            pageInfo={{
              preset,
              schema,
              menuItem: setMenuActionItem(),
              menuTarget: 'estimateRequestUnitSummaryId',
              presetItems,
              headerTitle: { viewId: VIEW_ID },
              listSkipType: {
                isTotal: true,
                isOutput: true,
                isListActionMenu: true,
              },
            }}
            filterItemOption={{
              naviFilterItems,
              isRequestBodyFilter: true,
            }}
            stateOption={{
              onOriginalItemState: (items: unknown[]) =>
                (listItemRef.current =
                  items as mtechnavi.api.estimation.IEstimateRequestUnitSummary[]),
              onAllItemState: (items: unknown[]) =>
                (listAllItemRef.current =
                  items as mtechnavi.api.estimation.IEstimateRequestUnitSummary[]),
            }}
          />
        </div>
        <Toast />
      </Container>
      <>
        {/* 出力ダイアログ */}
        <OutputDialog
          key={isShowSelection ? '1' : null}
          isOpen={isShowDownload}
          type={'estimate'}
          onDecision={handleDownload}
          onCancel={() => setShowDownload(false)}
        />
      </>
      <>
        {/* 選考ダイアログ */}
        <EstimateSelectionDialog
          key={isShowSelection ? '1' : null}
          isOpen={isShowSelection}
          inputOption={selectionOption}
          onDecision={handleSelection}
          onCancel={() => setShowSelection(false)}
        />
      </>
      <>
        {/* 承認依頼ダイアログ */}
        <ApprovalRequestDialog
          key={isShowApprovalRequest ? '1' : null}
          isOpen={isShowApprovalRequest}
          inputOption={{
            organizations: organizations.current,
            organizationRelations: organizationRelations.current,
            FullMethodName_ListUserAttributes:
              FullMethodName_ListUserAttributes,
            requestReciptId: '',
          }}
          onDecision={async (result) => {
            if (
              !(await confirmation('estimateSelectionDialog.approvalRequest'))
            ) {
              return;
            }
            handelApprovalRequest(result);
          }}
          onCancel={() => {
            handelApprovalRequest(null);
          }}
        />
      </>
      {/* 確認ダイアログ */}
      <ConfirmationDialog
        isOpen={isShowConfirmation}
        viewMessage={{
          id: 'C0000001',
          value: {
            $1: GetMessageWithIntl(intl, { id: confirmationMessageId }),
          },
        }}
        onDecision={() => handleConfirm(true)}
        onCancel={() => handleConfirm(false)}
      />
      {isLoading && <LoadingIcon />}
    </>
  );
}
