import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface ExecUpdateWtRequestReferenceRequest {
  actionName: 'updateWtRequestReference';
  request: mtechnavi.api.worktask.IWorkTask;
}

// 依頼参考情報更新
export const execUpdateWtRequestReference = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.worktask.IWorkTask
) => {
  const getFullMethodName =
    'mtechnavi.api.worktask.WorkTaskService/GetWorkTask';
  const updateFullMethodName =
    'mtechnavi.api.worktask.WorkTaskService/UpdateWorkTask';

  const items = await worker.invokeListRequest<
    mtechnavi.api.worktask.IGetWorkTaskRequest,
    mtechnavi.api.worktask.WorkTask
  >(getFullMethodName, {
    workTaskId: req.workTaskId,
  });

  if (items && items.length > 0) {
    const item = items[0];
    return await worker.invokeListRequest<
      mtechnavi.api.worktask.IUpdateWorkTaskRequest,
      mtechnavi.api.worktask.WorkTask
    >(updateFullMethodName, {
      workTask: {
        ...item,
        requestRemarks: req.requestRemarks,
        requestAttachments: req.requestAttachments,
      },
    });
  }
};
