import {
  Ref,
  RefObject,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';
import { GroupTreeItem } from './type';
import { GroupTreeEditActions, GroupTreeEditPane } from './GroupTreeEditPane';
import { GroupTreeNodeRef, isMatchItem } from './util';

export interface GroupTreeEdgeProps<T> {
  data: GroupTreeItem<T>;
  level: number;
  searchWord?: string;
  isParentDisable?: boolean;
  selectedGroup: string | null;
  onSelectGroup: (selectedGroup: string | null) => void;
  onDrop?: (data: { targetId: string; parentId: string }) => void;
  editActions: GroupTreeEditActions<T>;
  parentRef?: RefObject<GroupTreeNodeRef>;
  isEnableDnD?: boolean;
  isEditable?: boolean;
  isShowAddChild: boolean | ((data: T | undefined) => boolean);
}

/**
 * ツリーの末端コンポーネント。
 */
export const GroupTreeEdge = forwardRef(
  <T,>(
    {
      data,
      level,
      searchWord,
      isParentDisable,
      selectedGroup,
      onSelectGroup,
      onDrop,
      editActions,
      parentRef,
      isEnableDnD,
      isEditable,
      isShowAddChild,
    }: GroupTreeEdgeProps<T>,
    ref: Ref<GroupTreeNodeRef> | undefined
  ) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const isSelected = selectedGroup === data.id;
    const isDisabled = isParentDisable || data.isDisabled;
    const isMatch = useMemo<boolean | null>(() => {
      if (!searchWord) {
        return null;
      }
      return isMatchItem(data, searchWord);
    }, [data, searchWord]);

    const handleSelect = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation();
      onSelectGroup && onSelectGroup(data.id || null);
    };

    const handleDragStart = (e: React.DragEvent<HTMLDivElement>) => {
      e.stopPropagation();
      e.dataTransfer.effectAllowed = 'move';
      e.dataTransfer.setData('text/plain', data.id || '');
    };
    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
      e.stopPropagation();
      e.preventDefault();
      const targetId = e.dataTransfer.getData('text/plain');
      const parentId = data.id || '';
      if (targetId === parentId) {
        return;
      }
      onDrop && onDrop({ targetId, parentId });
    };
    const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
    };
    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
    };

    useImperativeHandle(
      ref,
      (): GroupTreeNodeRef => ({
        focus: () => {
          parentRef?.current?.open && parentRef.current.open();
          containerRef.current?.focus();
        },
      })
    );

    return (
      <div
        className={`GroupTreeEdge id-${data.id} ${
          isSelected ? 'selected' : ''
        } ${level % 2 === 0 ? 'even' : 'odd'} ${isDisabled ? 'disabled' : ''} ${
          isMatch === null ? '' : isMatch ? 'matched' : 'unmatched'
        }`}
        key={data.id}
        onClick={handleSelect}
        draggable={isEnableDnD}
        onDragStart={handleDragStart}
        onDragEnter={handleDragEnter}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        ref={containerRef}
      >
        <span>{`${data.displayName}(${data.code})`}</span>
        <GroupTreeEditPane
          editActions={editActions}
          data={data}
          isEditable={isEditable}
          isCanDelete={data.isDisabled}
          isDisabled={isDisabled}
          isShowAddChild={isShowAddChild}
        />
      </div>
    );
  }
);
