import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface UpdateSurveyRequestRemarksRequest {
  actionName: 'updateSurveyRequestRemarks';
  request: mtechnavi.api.survey.ISurveyRequest;
}

// // 社内メモ更新
export const updateSurveyRequestRemarks = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.survey.ISurveyRequest
) => {
  const getFullMethodName =
    'mtechnavi.api.survey.SurveySenderService/GetSurveyRequest';
  const updateFullMethodName =
    'mtechnavi.api.survey.SurveySenderService/UpdateSurveyRequest';
  // // 依頼受信情報を取得
  const items = await worker.invokeListRequest<
    mtechnavi.api.survey.IGetSurveyRequestContentRequest,
    mtechnavi.api.survey.SurveyRequest
  >(getFullMethodName, {
    surveyRequestId: req.surveyRequestId,
  });
  if (items && items.length > 0) {
    const item = items[0];
    item.remarks = req.remarks ?? '';
    // 依頼受信情報を更新
    await worker.invokeListRequest<
      mtechnavi.api.survey.IUpdateSurveyRequestRequest,
      mtechnavi.api.survey.SurveyRequest
    >(updateFullMethodName, {
      surveyRequest: item,
    });
  }
};
