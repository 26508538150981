import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';
import { FullMethodName_DeleteTenantManagementWithRelatedItems } from 'worker/fullMethodName/crud';

/** テナントの削除(即時) */
export const deleteTenant = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.admin.IDeleteTenantManagementWithRelatedItemsRequest
) => {
  return await worker.invokeListRequest<
    mtechnavi.api.admin.IDeleteTenantManagementWithRelatedItemsRequest,
    void
  >(FullMethodName_DeleteTenantManagementWithRelatedItems, req);
};
