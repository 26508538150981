import { mtechnavi, sharelib } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface ExecBusinessUnitDocument {
  actionName: 'execBusinessUnitDocument';
  request: mtechnavi.api.company.IBusinessUnitDocument;
}

export const execBusinessUnitDocument = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.company.IBusinessUnitDocument
) => {
  const createMethodName =
    'mtechnavi.api.company.BusinessUnitManagementService/CreateBusinessUnitDocument';
  const updateMethodName =
    'mtechnavi.api.company.BusinessUnitManagementService/UpdateBusinessUnitDocument';

  let result: unknown[] = [];
  if (req.businessUnitDocumentId) {
    result = await worker.invokeListRequest(updateMethodName, {
      businessUnitDocument: req,
    });
  } else {
    result = await worker.invokeListRequest(createMethodName, {
      businessUnitDocument: req,
    });

    // アセット情報更新
    const getAssetFullMethodName =
      'mtechnavi.api.assetinventory.AssetInventory/GetAsset';
    const updateAssetFullMethodName =
      'mtechnavi.api.assetinventory.AssetInventory/UpdateAsset';

    const items = await worker.invokeListRequest<
      mtechnavi.api.assetinventory.IAsset,
      mtechnavi.api.assetinventory.Asset
    >(getAssetFullMethodName, {
      assetId: req.attachment?.assetId,
    });

    if (items.length > 0) {
      const item: mtechnavi.api.assetinventory.IAsset = items[0];
      item.filename = req.attachment?.filename;
      item.displayName = req.attachment?.filename;

      result = await worker.invokeListRequest(updateAssetFullMethodName, {
        asset: item,
        sourceUrl: '',
      });
    }
  }

  return result;
};
