import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface CreateSampleWorkFormRequest {
  actionName: 'createSampleWorkForm';
  request: CreateSampleWorkFormRequestParam;
}

export type CreateSampleWorkFormRequestParam =
  mtechnavi.api.form.ICreateSampleWorkFormRequest;

// FormSample の単純な作成
export const createSampleWorkForm = (
  worker: AppSaveWorker,
  req: CreateSampleWorkFormRequestParam
) => {
  const methodName =
    'mtechnavi.api.form.SampleWorkFormService/CreateSampleWorkForm';

  return worker.invokeListRequest<
    mtechnavi.api.form.ICreateSampleWorkFormRequest,
    mtechnavi.api.form.SampleWorkForm
  >(methodName, req);
};
