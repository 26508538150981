import {
  ViewId,
  convertDisplayUserNameEmail,
  getDateFormat,
  createAttachmentFiles,
  autoDownloadFileOnlyName,
  autoBulkDownloadWithName,
  getWorkerExceptionMessage,
} from '~/shared/utils';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { useState, useEffect, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { SimpleIconListView } from '~/shared/components/ui';
import {
  DocumentRegistrationDialog,
  DocumentRegistrationDialogInputOption,
  DocumentRegistrationResult,
} from '~/shared/components/ui/Dialog/DocumentRegistrationDialog';
import { CaptionButton } from '~/shared/components/ui/Button';
import { success, error, GetMessageWithIntl } from '~/shared/components';

interface DocumentTabItemProps {
  businessUnitDocuments?: mtechnavi.api.company.IBusinessUnitDocument[];
  businessUnitManagementContent?: mtechnavi.api.company.IBusinessUnitManagementContent;
  onChangeLoadingState: (arg: boolean) => void;
  handleReload: () => void;
}

interface ViewDocumentType {
  businessUnitDocumentId: string; // Key
  category: string; // category.displayNameLang.ja
  filename: string; // attachment.filename
  attachedAt: string; // attachment.attachedAt yyyy/MM/dd
  updatedBy: string; // updatedProperties.updatedBy displayName(email)
  remarks: string; // attachment.remarks
  assetId: string; // 非表示項目 ダウンロード処理用
}

const VIEW_ID: ViewId = 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION';

const documentColumns = [
  {
    header: {
      id: 'document.category',
      prefixId: VIEW_ID,
    },
    propertyName: 'category',
    width: '10rem',
    readonly: true,
  },
  {
    header: {
      id: 'document.filename',
      prefixId: VIEW_ID,
    },
    propertyName: 'filename',
    width: '12rem',
  },
  {
    header: {
      id: 'document.attachedAt',
      prefixId: VIEW_ID,
    },
    propertyName: 'attachedAt',
    width: '13rem',
    readonly: true,
  },
  {
    header: {
      id: 'document.updatedBy',
      prefixId: VIEW_ID,
    },
    propertyName: 'updatedBy',
    width: '15rem',
    readonly: true,
  },
  {
    header: {
      id: 'document.remarks',
      prefixId: VIEW_ID,
    },
    propertyName: 'remarks',
    readonly: true,
  },
];

type sortDocumentType = 'category-filename' | 'attachedAt';

export const DocumentTabItem = ({
  businessUnitDocuments,
  businessUnitManagementContent,
  onChangeLoadingState,
  handleReload,
}: DocumentTabItemProps) => {
  const intl = useIntl();
  const [documentItems, setDocumentItems] = useState<ViewDocumentType[]>([]);
  const [documentConfirmationFormItems, setDocumentConfirmationFormItems] =
    useState<DocumentRegistrationDialogInputOption>();
  const [documentRegistrationFormItems, setDocumentRegistrationFormItems] =
    useState<DocumentRegistrationDialogInputOption>();
  // 文書確認ダイアログ
  const [isDocumentConfirmationDialogOpen, setDocumentConfirmationDialogOpen] =
    useState(false);
  // 文書登録ダイアログ
  const [isDocumentRegistrationDialogOpen, setDocumentRegistrationDialogOpen] =
    useState(false);

  // メッセージ
  const successMessage = GetMessageWithIntl(intl, { id: 'I0000001' });

  // 文書情報変更時
  useEffect(() => {
    if (!businessUnitDocuments) {
      setDocumentItems([]);
      return;
    }
    const vals: ViewDocumentType[] = [];
    businessUnitDocuments.map((v) =>
      vals.push({
        businessUnitDocumentId: v.businessUnitDocumentId ?? '',
        category: v.category?.displayNameLang?.ja ?? '',
        filename: v.attachment?.filename ?? '',
        attachedAt: getDateFormat(
          v.attachment?.attachedAt ?? '',
          'YYYY/MM/DD HH:mm'
        ),
        updatedBy: convertDisplayUserNameEmail(
          v.updatedProperties?.updatedBy?.displayName,
          v.updatedProperties?.updatedBy?.email
        ),
        remarks: v.attachment?.remarks ?? '',
        assetId: v.attachment?.assetId ?? '',
      })
    );

    vals.sort((val1, val2) => val1.category.localeCompare(val2.category, 'ja'));
    vals.sort((val1, val2) => {
      if (val1.category === val2.category) {
        return val1.filename.localeCompare(val2.filename, 'ja');
      }
      return 1;
    });

    const sortedData = vals.sort((val1, val2) =>
      val1.category.localeCompare(val2.category, 'ja')
    );
    sortedData.sort((val1, val2) => {
      if (val1.category === val2.category) {
        return val1.filename.localeCompare(val2.filename, 'ja');
      }
      return 1;
    });

    setDocumentItems(sortedData);

    // businessUnitDocumentsData変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessUnitDocuments]);

  // [実行処理]文書確認ダイアログ
  const handleDocumentConfirmationDialog = (v: ViewDocumentType) => {
    const formItem: mtechnavi.api.company.IBusinessUnitDocument =
      businessUnitDocuments?.find(
        (w) => w.businessUnitDocumentId === v.businessUnitDocumentId
      ) ?? {};

    setDocumentConfirmationFormItems({
      displayModeType: 'display',
      businessUnitDocument: formItem ?? {},
    });

    setDocumentConfirmationDialogOpen(true);
  };
  // [実行処理]削除 文書
  const handleDeleteDocument = async (v: DocumentRegistrationResult) => {
    onParentLoadingState(true);
    try {
      const request: mtechnavi.api.company.IBusinessUnitContact =
        v.businessUnitDocument;

      await window.App.services.ui.worker.apiCall({
        actionName: 'deleteBusinessUnitDocument',
        request: request,
      });

      setDocumentConfirmationDialogOpen(false);
      success([successMessage]);

      //リロード(文書情報のみ)
      handleReload();
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      onParentLoadingState(false);
    }
  };

  // [実行処理]文書登録ダイアログ
  const handleDocumentRegistrationDialog = (v?: DocumentRegistrationResult) => {
    setDocumentRegistrationFormItems({
      displayModeType: 'save',
      businessUnitDocument: v?.businessUnitDocument ?? {},
    });
    setDocumentConfirmationDialogOpen(false);
    setDocumentRegistrationDialogOpen(true);
  };

  // [実行処理]保存 文書
  const handleSaveDocument = async (v: DocumentRegistrationResult) => {
    onParentLoadingState(true);
    try {
      const request: mtechnavi.api.company.IBusinessUnitDocument =
        v.businessUnitDocument;

      if (v.files.length > 0) {
        const tmpData = await createAttachmentFiles(v.files ?? [], 'B09');
        request.businessUnitManagementId =
          businessUnitManagementContent?.businessUnitManagement?.businessUnitManagementId;
        request.attachment = tmpData.length > 0 ? tmpData[0] : {};
        request.attachment.remarks = v.files[0].remarks;
      }

      await window.App.services.ui.worker.apiCall({
        actionName: 'execBusinessUnitDocument',
        request: request,
      });

      setDocumentRegistrationDialogOpen(false);
      success([successMessage]);

      //リロード(文書情報のみ)
      handleReload();
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      onParentLoadingState(false);
    }
  };

  // [実行処理]添付ファイルダウンロード 文書
  const handleDownloadDocument = (v: ViewDocumentType) => {
    autoDownloadFileOnlyName(v.filename ?? '', v.assetId ?? '');
  };
  // [実行処理]添付ファイル全ダウンロード 文書
  const handleFullDownloadDocument = () => {
    autoBulkDownloadWithName(
      businessUnitDocuments?.map((item) => item.attachment?.assetId ?? '') ??
        [],
      intl,
      GetMessageWithIntl(intl, {
        id: 'document.download.zipFilename',
        prefixId: 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION',
      })
    );
  };

  // [実行処理]ソート 文書タブ
  const handleSortDocumentItems = (
    sortItem: sortDocumentType,
    desc: boolean
  ) => {
    const sortedData: ViewDocumentType[] = documentItems;

    switch (sortItem) {
      case 'category-filename':
        sortedData.sort((val1, val2) =>
          val1.category.localeCompare(val2.category, 'ja')
        );
        sortedData.sort((val1, val2) => {
          if (val1.category === val2.category) {
            return val1.filename.localeCompare(val2.filename, 'ja');
          }
          return 1;
        });

        break;
      case 'attachedAt':
        sortedData.sort(
          (val1, val2) =>
            val1.attachedAt.localeCompare(val2.attachedAt) * (desc ? -1 : 1)
        );
        break;
    }

    setDocumentItems(
      sortedData.map(
        (v): ViewDocumentType => ({
          businessUnitDocumentId: v.businessUnitDocumentId,
          category: v.category,
          filename: v.filename,
          attachedAt: v.attachedAt,
          updatedBy: v.updatedBy,
          remarks: v.remarks,
          assetId: v.assetId,
        })
      )
    );
  };

  const onParentLoadingState = useCallback(
    (arg: boolean) => {
      onChangeLoadingState(arg);
    },
    [onChangeLoadingState]
  );

  return (
    <>
      <div className="tab-info-body">
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              <div className="sort-link-area">
                <span className="sort-label">
                  {GetMessageWithIntl(intl, {
                    id: 'document.sort.label',
                    prefixId: 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION',
                  })}
                </span>
                <div
                  className="sort-link category-filename"
                  onClick={() =>
                    handleSortDocumentItems('category-filename', false)
                  }
                >
                  {GetMessageWithIntl(intl, {
                    id: 'document.sort.category-filename',
                    prefixId: 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION',
                  })}
                </div>
                <div
                  className="sort-link attachedAt-ask"
                  onClick={() => handleSortDocumentItems('attachedAt', false)}
                >
                  {GetMessageWithIntl(intl, {
                    id: 'document.sort.attachedAtAsc',
                    prefixId: 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION',
                  })}
                </div>
                <div
                  className="sort-link attachedAt-desk"
                  onClick={() => handleSortDocumentItems('attachedAt', true)}
                >
                  {GetMessageWithIntl(intl, {
                    id: 'document.sort.attachedAtDesc',
                    prefixId: 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION',
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-80">
              <div className="document-list-area">
                <SimpleIconListView
                  data={documentItems}
                  viewOptions={{
                    previewRowCount: 9,
                    keyColumn: 'businessUnitDocumentId',
                    columns: documentColumns,
                    omitFooter: false,
                  }}
                  actionOptions={{
                    onRowClick: handleDownloadDocument,
                    onFullDownLoad: handleFullDownloadDocument,
                  }}
                  iconMenuOptions={{
                    iconMenu: [
                      {
                        name: 'description',
                        displayName: '確認',
                        func: (v: ViewDocumentType) => {
                          handleDocumentConfirmationDialog(v);
                        },
                      },
                    ],
                  }}
                />
              </div>
            </div>
            <div className="w-20">
              <CaptionButton
                buttonType="basic"
                caption="文書登録"
                name="addDocumentBtn"
                onClick={() => {
                  handleDocumentRegistrationDialog();
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {/* 文書確認ダイアログ */}
      <DocumentRegistrationDialog
        isOpen={isDocumentConfirmationDialogOpen}
        inputOption={documentConfirmationFormItems}
        onEdit={handleDocumentRegistrationDialog}
        onDelete={handleDeleteDocument}
        onCancel={() => {
          setDocumentConfirmationDialogOpen(false);
        }}
        onSave={() => {}}
      />
      {/* 文書登録ダイアログ */}
      <DocumentRegistrationDialog
        isOpen={isDocumentRegistrationDialogOpen}
        inputOption={documentRegistrationFormItems}
        onEdit={() => {}}
        onDelete={() => {}}
        onCancel={() => {
          setDocumentRegistrationDialogOpen(false);
        }}
        onSave={(v) => {
          handleSaveDocument(v);
        }}
      />
    </>
  );
};
