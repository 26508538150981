import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';
import { FullMethodName_UpdateTenantManagementLicense } from 'worker/fullMethodName/crud';

/** ライセンスの更新 */
export const updateLicense = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.admin.IUpdateLicenseRequest
) => {
  return await worker.invokeListRequest<
    mtechnavi.api.admin.IUpdateLicenseRequest,
    mtechnavi.api.license.ILicense
  >(FullMethodName_UpdateTenantManagementLicense, req);
};
