import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';
import { FullMethodName_SendTenantManagementEmailTenantInvitation } from 'worker/fullMethodName/crud';

/** テナント招待メール送信 */
export const adminSendTenantInvitation = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.admin.ISendEmailTenantInvitationRequest
) => {
  return await worker.invokeListRequest<
    mtechnavi.api.admin.ISendEmailTenantInvitationRequest,
    mtechnavi.api.admin.ISendEmailTenantInvitationResponse
  >(FullMethodName_SendTenantManagementEmailTenantInvitation, req);
};
