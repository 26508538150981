import { sharelib } from '../libs/clientsdk';

/* utils全体で使い回すTypeやinterfaceを定義 */
export interface Amount {
  integralAmount: Long | null;
  fractionalAmount: number | null;
}

export type Amounts =
  | sharelib.IQuantityAmount
  | sharelib.IMonetaryAmount
  | sharelib.IDistantAmount
  | null
  | undefined;

// 多言語対応名称
export type DisplayNameLang = { [k: string]: string } | null;

export type ConnectingType = 'worktask' | 'survey' | 'estimate';

// 属性マスタの分類コードの定義
export const ATTRIBUTE_ORGANIZATION = '_ORGANIZATION';
export const ATTRIBUTE_BRANCH = '_BRANCH';
export type AttributeType = '_ORGANIZATION' | '_BRANCH';
