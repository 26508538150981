import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface DeleteSurveyResultWorkFormRequest {
  actionName: 'deleteSurveyResultWorkForm';
  request: mtechnavi.api.survey.IDeleteSurveyResultWorkFormRequest;
}

// ResultWorkForm の削除
export const deleteSurveyResultWorkForm = (
  worker: AppSaveWorker,
  req: mtechnavi.api.survey.IDeleteSurveyResultWorkFormRequest
) => {
  const methodName =
    'mtechnavi.api.survey.SurveySenderService/DeleteSurveyResultWorkForm';

  return worker.invokeListRequest<
    mtechnavi.api.survey.IDeleteSurveyResultWorkFormRequest,
    void
  >(methodName, req);
};
