import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface ExecSaveWorkTaskRequest {
  actionName: 'saveWorkTask';
  request: mtechnavi.api.worktask.IWorkTask;
}

// 指図案件登録
export const execSaveWorkTask = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.worktask.IWorkTask
) => {
  const updateFullMethodName =
    'mtechnavi.api.worktask.WorkTaskService/UpdateWorkTask';
  const fullMethodName =
    'mtechnavi.api.worktask.WorkTaskService/CreateWorkTask';
  if (req.workTaskId) {
    return await worker.invokeListRequest<
      mtechnavi.api.worktask.IUpdateWorkTaskRequest,
      mtechnavi.api.worktask.IWorkTask
    >(updateFullMethodName, {
      workTask: req,
    });
  }
  return await worker.invokeListRequest<
    mtechnavi.api.worktask.ICreateWorkTaskRequest,
    mtechnavi.api.worktask.IWorkTask
  >(fullMethodName, {
    workTask: req,
  });
};
