import { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { CaptionButton } from '~/shared/components/ui/Button';
import {
  GetMessageWithIntl,
  MessageProps,
  ModalDialogComponent,
  ModalDialogComponentProps,
} from '~/shared/components';
import { Textbox, Checkbox } from '~/shared/components/ui';
import {
  CommmonAttachedFileSystemCode,
  includeInputValidateError,
} from '~/shared/utils';
import {
  BuildedForm,
  BuildedFormRef,
} from '~/shared/components/ui/FormBuilder';
import './AttributeInputDialog.css';
import { ATTRIBUTE_ORGANIZATION } from '~/shared/utils/commonType';

const dialogId = 'AttributeInputDialog';
export interface AttributeValues {
  headerValues?: mtechnavi.api.programoption.IAttribute;
  detailValues?: mtechnavi.api.form.IFormValue[];
}

export interface AttributeInputDialogFormConfig {
  formSetting:
    | mtechnavi.api.form.IBaseFormSetting
    | mtechnavi.api.form.IFormSetting;
  formSettingItems:
    | mtechnavi.api.form.IBaseFormSettingItem[]
    | mtechnavi.api.form.IFormSettingItem[];
  assetSystemCode: CommmonAttachedFileSystemCode;
}

export interface AttributeInputDialogInputOption {
  initialValues?: AttributeValues;
  readonly: boolean;
}

interface AttributeInputDialogProps {
  isOpen: boolean;
  formConfig?: AttributeInputDialogFormConfig;
  inputOption?: AttributeInputDialogInputOption;
  messageOption: {
    headerLabelId: MessageProps;
    /**
     * 決定ボタンのラベル。
     * 渡さなければ「保存」となる。
     */
    buttonLabelId?: MessageProps;
  };
  /**
   * ダイアログの決定処理。
   */
  onDecision?: (values: AttributeValues) => void;
  /**
   * ダイアログのキャンセル処理。
   */
  onCancel?: () => void;
}
export const AttributeInputDialog = (props: AttributeInputDialogProps) => {
  const { formSetting, formSettingItems, assetSystemCode } =
    props.formConfig ?? {};
  const { initialValues, readonly } = props.inputOption ?? {};

  const intl = useIntl();
  const [headerValues, setHeaderValues] =
    useState<mtechnavi.api.programoption.IAttribute>(
      initialValues?.headerValues ?? {}
    );
  const buildedFormRef = useRef<BuildedFormRef>(null);
  const requiredHeaderArea = useRef(null);

  const handleCancel = () => {
    props.onCancel && props.onCancel();
  };

  const handleDecision = () => {
    if (isInputError()) {
      return;
    }
    const detailValues = buildedFormRef.current?.getValues();
    if (!detailValues) {
      return;
    }
    if (props.onDecision) {
      props.onDecision({
        headerValues,
        detailValues,
      });
    }
  };

  // エラーチェック
  const isInputError = (): boolean => {
    const inputValidationCheckList = [];
    inputValidationCheckList.push({
      value: (headerValues.key || headerValues.displayName) ?? '',
      ref: requiredHeaderArea,
    });

    const targetElm = document.querySelector('.attribute-input-dialog');
    if (includeInputValidateError(targetElm, intl, inputValidationCheckList)) {
      return true;
    }
    return false;
  };

  const elements = (
    <>
      <div className="attribute-input-dialog">
        <div className="attribute-header" ref={requiredHeaderArea}>
          <AttributeHeader
            initialValues={initialValues?.headerValues}
            readonly={!!readonly}
            onChange={setHeaderValues}
          />
        </div>
        <div className="form-area">
          <BuildedForm
            formPages={formSetting?.formPages || []}
            formSettingItems={formSettingItems || []}
            initialValues={initialValues?.detailValues}
            ref={buildedFormRef}
            readonly={!!readonly}
            assetSystemCode={assetSystemCode}
          />
        </div>
        <div className="button-area">
          <CaptionButton
            name="cancel"
            buttonType="cancel"
            className="button"
            caption={GetMessageWithIntl(intl, { id: 'cancel' })}
            onClick={handleCancel}
          />
          {!readonly && (
            <>
              <CaptionButton
                name="save"
                buttonType="basic"
                className="button"
                caption={GetMessageWithIntl(
                  intl,
                  props.messageOption.buttonLabelId || { id: 'save' }
                )}
                onClick={() => handleDecision()}
              />
            </>
          )}
        </div>
      </div>
    </>
  );

  const openModalProps: ModalDialogComponentProps = {
    cancel: handleCancel,
    send: () => {},
    modalIsOpen: props.isOpen,
    headerLabelId: props.messageOption.headerLabelId,
    messageLabelId: {},
    elements,
  };

  return (
    <div className="AttributeInputDialog">
      <ModalDialogComponent {...openModalProps} />
    </div>
  );
};

interface AttributeHeaderProps {
  initialValues?: mtechnavi.api.programoption.IAttribute;
  readonly?: boolean;
  onChange: (values: mtechnavi.api.programoption.IAttribute) => void;
}
const AttributeHeader = (props: AttributeHeaderProps) => {
  const intl = useIntl();
  const { systemAttribute } = props.initialValues ?? {};
  const [usable, setUsable] = useState(props.initialValues?.usable ?? false);
  const [key, setKey] = useState(props.initialValues?.key ?? '');
  const [displayName, setDisplayName] = useState(
    props.initialValues?.displayName ?? ''
  );
  const category = GetMessageWithIntl(intl, {
    prefixId: dialogId,
    id:
      props?.initialValues?.categoryCode === ATTRIBUTE_ORGANIZATION
        ? 'masterOrganization'
        : 'masterBranch',
  });

  const handleChangeUsable = (selection: string[]) => {
    const usable = !!selection.length;
    setUsable(usable);
    changeValues({ usable });
  };
  const handleChangeKey = (key: string) => {
    setKey(key);
    changeValues({ key });
  };
  const handleChangeDisplayName = (displayName: string) => {
    setDisplayName(displayName);
    changeValues({ displayName });
  };

  const changeValues = (newValue: mtechnavi.api.programoption.IAttribute) => {
    const values = {
      ...props?.initialValues,
      category,
      usable,
      key,
      displayName,
      ...newValue,
    };
    props.onChange && props.onChange(values);
  };

  return (
    <div className="AttributeHeader">
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-25">
            <Textbox
              name="category"
              labelId={`${dialogId}.category`}
              type="text"
              columns={['category']}
              value={category}
              disabled={true}
            />
          </div>
          <div className="w-25">
            <Textbox
              name="systemAttribute"
              labelId={`${dialogId}.systemAttribute`}
              type="text"
              columns={['systemAttribute']}
              value={systemAttribute ? '◯' : '-'}
              disabled={true}
            />
          </div>
          <div className="w-25">
            <Checkbox
              name="usable"
              className="usable"
              columns={['usable']}
              items={[
                {
                  displayName: GetMessageWithIntl(intl, {
                    prefixId: dialogId,
                    id: 'usable',
                  }),
                  value: '1',
                },
              ]}
              value={usable ? '1' : ''}
              disabled={!!props.readonly}
              onChangeState={handleChangeUsable}
            />
          </div>
        </div>
      </div>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-25">
            <Textbox
              name="key"
              labelId={`${dialogId}.key.${
                props?.initialValues?.categoryCode === ATTRIBUTE_ORGANIZATION
                  ? 'organization'
                  : 'branch'
              }`}
              type="text"
              columns={['key']}
              value={key}
              disabled={!!props.readonly || !!props?.initialValues?.key}
              onChangeState={handleChangeKey}
              validateOption={{ required: true }}
            />
          </div>
          <div className="w-50">
            <Textbox
              name="displayName"
              labelId={`${dialogId}.displayName`}
              type="text"
              columns={['displayName']}
              value={displayName}
              disabled={!!props.readonly}
              onChangeState={handleChangeDisplayName}
              validateOption={{ required: true }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
