import { useIntl } from 'react-intl';
import {
  GetMessageWithIntl,
  ModalDialogComponent,
  ModalDialogComponentProps,
  Radio,
} from '~/shared/components';
import { CaptionButton } from '~/shared/components/ui/Button';
import { Checkbox, Textbox } from '~/shared/components/ui';
import { useRef, useState } from 'react';
import './OrganizationDetailDialog.css';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { includeInputValidateError } from '~/shared/utils';

const prefixId = 'OrganizationDetailDialog';

export type OrganizationDetailDialogMode = 'add' | 'edit' | 'show';
type OrganizationDetailDialogInputOption = mtechnavi.api.company.IOrganization;
export interface OrganizationDetailDialogProps {
  isOpen: boolean;
  isUseProcurementOption: boolean;
  mode: OrganizationDetailDialogMode;
  parents?: mtechnavi.api.company.IOrganization[];
  inputOption?: OrganizationDetailDialogInputOption;
  onDecision: (result: mtechnavi.api.company.IOrganization) => void;
  onCancel: () => void;
}
export const OrganizationDetailDialog = ({
  isOpen,
  isUseProcurementOption,
  mode,
  parents,
  inputOption: data,
  onDecision,
  onCancel,
}: OrganizationDetailDialogProps) => {
  const intl = useIntl();

  const [usable, setUsable] = useState<boolean>(data ? !!data.usable : true);
  const [code, setCode] = useState(mode !== 'add' ? data?.code : '');
  const [displayName, setOrganizationName] = useState(
    mode !== 'add' ? data?.displayName : ''
  );
  const [displayNameShort, setDisplayNameShort] = useState(
    mode !== 'add' ? data?.displayNameShort : ''
  );
  const [procurementOption, setProcurementOption] = useState<boolean>(
    data?.procurementOption ?? false
  );
  const lastParent = parents?.at(parents.length - 1);
  const isLockUsable = !!lastParent && !lastParent?.usable;
  const noopRef = useRef(null);

  const handleDecision = () => {
    if (isInputError()) {
      return;
    }
    if (mode === 'edit') {
      onDecision({
        ...data,
        displayName,
        displayNameShort,
        usable,
        procurementOption,
      });
    } else {
      onDecision({
        code,
        displayName,
        displayNameShort,
        usable,
        procurementOption,
        parentOrganization: {
          organizationId: data?.organizationId,
          code: data?.code,
          displayName: data?.displayName,
          displayNameShort: data?.displayNameShort,
        },
      });
    }
  };

  // エラーチェック
  const isInputError = (): boolean => {
    const targetElm = document.querySelector('.OrganizationDetailDialog');
    return includeInputValidateError(targetElm, intl, [
      { ref: noopRef, value: code ?? '' },
      { ref: noopRef, value: displayName ?? '' },
    ]);
  };

  const view = (
    <div className="OrganizationDetailDialog">
      <div className="detail-area">
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-75">
              <div className="parents-breadcrumb">
                {(!parents || parents.length === 0) && <></>}
                {(parents || []).map((parent) => (
                  <span key={parent.organizationId}>{parent.displayName}</span>
                ))}
              </div>
            </div>
            <div className="w-25">
              <Checkbox
                name="disabled"
                className="disabled-check"
                items={[
                  {
                    value: '1',
                    displayName: GetMessageWithIntl(intl, {
                      prefixId,
                      id: 'disabled',
                    }),
                  },
                ]}
                value={!usable ? '1' : ''}
                columns={['disabled']}
                disabled={mode === 'show' || isLockUsable}
                onChangeState={(checkedValues) =>
                  setUsable(!checkedValues.includes('1'))
                }
              />
            </div>
          </div>
        </div>
        {isUseProcurementOption && (
          <div className="input-line">
            <div className="item-group-100">
              <div className="w-100">
                <Radio
                  name="procurementOption"
                  items={[
                    {
                      value: '0',
                      displayName: GetMessageWithIntl(intl, {
                        prefixId,
                        id: 'basicOrg',
                      }),
                    },
                    {
                      value: '1',
                      displayName: GetMessageWithIntl(intl, {
                        prefixId,
                        id: 'procurementOrg',
                      }),
                    },
                  ]}
                  disabled={mode !== 'add'}
                  value={procurementOption ? '1' : '0'}
                  onChangeState={(selectedValue) => {
                    setProcurementOption(selectedValue === '1');
                  }}
                />
              </div>
            </div>
          </div>
        )}
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-33">
              <Textbox
                name="code"
                type="text"
                labelId="OrganizationDetailDialog.organizationCode"
                disabled={mode !== 'add'}
                value={code}
                onChangeState={setCode}
                columns={['code']}
                validateOption={{ required: true }}
              ></Textbox>
            </div>
            <div className="w-33">
              <Textbox
                name="parentCode"
                type="text"
                labelId="OrganizationDetailDialog.parentCode"
                disabled={true}
                value={
                  mode === 'add' ? data?.code : data?.parentOrganization?.code
                }
                columns={['parentCode']}
              ></Textbox>
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-66">
              <Textbox
                name="name"
                type="text"
                labelId="OrganizationDetailDialog.organizationName"
                disabled={mode === 'show'}
                value={displayName}
                onChangeState={setOrganizationName}
                columns={['name']}
                validateOption={{ required: true }}
              ></Textbox>
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-66">
              <Textbox
                name="code"
                type="text"
                labelId="OrganizationDetailDialog.organizationAbbr"
                disabled={mode === 'show'}
                value={displayNameShort}
                onChangeState={setDisplayNameShort}
                columns={['code']}
              ></Textbox>
            </div>
          </div>
        </div>
      </div>
      <div className="button-area">
        <CaptionButton
          name="cancelBtn"
          buttonType="cancel"
          className="button"
          caption={GetMessageWithIntl(intl, { id: 'cancel' })}
          onClick={onCancel}
        />
        {mode !== 'show' && (
          <CaptionButton
            name="sendBtn"
            buttonType="basic"
            className="button"
            caption={GetMessageWithIntl(intl, { id: 'save' })}
            onClick={handleDecision}
          />
        )}
      </div>
    </div>
  );

  const modalProps: ModalDialogComponentProps = {
    cancel: () => {
      onCancel();
    },
    send: () => {},
    modalIsOpen: isOpen,
    headerLabelId: {
      prefixId: 'DIALOG_TITLE',
      id:
        mode === 'show'
          ? 'OrganizationConfirmationDialog'
          : 'OrganizationEditDialog',
    },
    messageLabelId: {},
    elements: view,
  };

  return (
    <>
      <ModalDialogComponent {...modalProps} />
    </>
  );
};
