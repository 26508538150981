import { useState } from 'react';
import { FileUploadWithCommentDialog } from '~/shared/components/ui';
import { CaptionButton } from '~/shared/components/ui/Button';

export const UISampleFileUploadWithCommentDialogs = () => {
  const [completeTaskResult, setCompleteTaskResult] = useState('');
  const [isShowCompleteTaskDialog, setShowCompleteTaskDialog] = useState(false);
  const [editRequestReferenceResult, setEditRequestReferenceResult] =
    useState('');
  const [isShowEditRequestReferenceDialog, setShowEditRequestReferenceDialog] =
    useState(false);
  const [fileManagementResult, setFileManagementResult] = useState('');
  const [isShowFileManagementDialog, setShowFileManagementDialog] =
    useState(false);

  return (
    <div className="categories">
      <h3 id="dialog">コメント付きファイルアップロードダイアログ</h3>
      <div className="description">
        デザインパターン：コメント欄の有無、D&Dオプション
      </div>

      <h4>作業完了ダイアログ</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setCompleteTaskResult('');
                setShowCompleteTaskDialog(true);
              }}
            />
            <FileUploadWithCommentDialog
              uploaderOption={{
                validateOption: {
                  maxFileSizeInMebis: 50,
                  maxFileCount: 10,
                },
              }}
              inputOption={{}}
              messageOption={{
                dialogTitle: {
                  prefixId: 'DIALOG_TITLE',
                  id: 'complete_task',
                },
                commentLabelId: 'CompleteTaskDialog.comment',
                buttonType: {
                  id: 'complete',
                },
                simpleListViewHeader: {
                  prefixId: 'CompleteTaskDialog',
                  id: 'simpleListViewHeader',
                },
              }}
              isOpen={isShowCompleteTaskDialog}
              onDecision={(v) => {
                setCompleteTaskResult(`出力しました。${v.comment}${v.files}`);
                setShowCompleteTaskDialog(false);
              }}
              onCancel={() => {
                setCompleteTaskResult('キャンセルしました');
                setShowCompleteTaskDialog(false);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">{completeTaskResult}</pre>
          </div>
        </div>
      </div>

      <h4>依頼参考情報編集ダイアログ</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setEditRequestReferenceResult('');
                setShowEditRequestReferenceDialog(true);
              }}
            />
            <FileUploadWithCommentDialog
              uploaderOption={{
                validateOption: {
                  maxFileSizeInMebis: 50,
                  maxFileCount: 10,
                },
              }}
              inputOption={{}}
              messageOption={{
                dialogTitle: {
                  prefixId: 'DIALOG_TITLE',
                  id: 'edit_request_reference',
                },
                commentLabelId: 'EditRequestReferenceDialog.comment',
                buttonType: {
                  id: 'save',
                },
                simpleListViewHeader: {
                  prefixId: 'EditRequestReferenceDialog',
                  id: 'simpleListViewHeader',
                },
              }}
              isOpen={isShowEditRequestReferenceDialog}
              onDecision={(v) => {
                setEditRequestReferenceResult(
                  `出力しました。${v.comment}${v.files}`
                );
                setShowEditRequestReferenceDialog(false);
              }}
              onCancel={() => {
                setEditRequestReferenceResult('キャンセルしました');
                setShowEditRequestReferenceDialog(false);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">{editRequestReferenceResult}</pre>
          </div>
        </div>
      </div>

      <h4>ファイル管理ダイアログ</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setFileManagementResult('');
                setShowFileManagementDialog(true);
              }}
            />
            <FileUploadWithCommentDialog
              uploaderOption={{
                validateOption: {
                  maxFileSizeInMebis: 50,
                  maxFileCount: 10,
                },
              }}
              inputOption={{}}
              displayOption={{
                isSkipCommentArea: true,
                isDnd: true,
              }}
              messageOption={{
                dialogTitle: {
                  prefixId: 'DIALOG_TITLE',
                  id: 'FILE_MANAGEMENT',
                },
                buttonType: {
                  id: 'save',
                },
                simpleListViewHeader: {
                  prefixId: 'FileManagementDialog',
                  id: 'simpleListViewHeader',
                },
              }}
              isOpen={isShowFileManagementDialog}
              onDecision={(v) => {
                setFileManagementResult(`出力しました。${v.comment}${v.files}`);
                setShowFileManagementDialog(false);
              }}
              onCancel={() => {
                setFileManagementResult('キャンセルしました');
                setShowFileManagementDialog(false);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">{fileManagementResult}</pre>
          </div>
        </div>
      </div>
    </div>
  );
};
