import { useMemo, CSSProperties } from 'react';
import { Property } from '~/shared/services';
import { GetMessageComponent } from '~/shared/components/parts/Message/Message';
import {
  Radio,
  RadioItem,
  RadioValidateOption,
} from '~/shared/components/parts/Radio/Radio';
import './Radiobutton.css';
import { getProperty } from '../common';

export interface RadioboxProps {
  name: string;
  className?: string;
  labelId?: string;
  items: RadioItem[];
  value?: string;
  disabled?: boolean;
  validator?: (v: string) => string[];
  validateOption?: RadioValidateOption;
  properties?: Property[];
  columns?: string[];
  onChangeState?: (arg: string) => void;
}

export function RadioButton(props: RadioboxProps) {
  const [componentStyle, viewStyle, _disabled] = useMemo<
    [CSSProperties, CSSProperties, boolean]
  >(() => {
    return getProperty(props.name, props.columns, props.properties);
  }, [props.name, props.properties, props.columns]);
  const disabled = _disabled ? _disabled : props.disabled ?? false;
  return (
    <div className="RadiobuttonUI" style={viewStyle}>
      <span className="caption">
        {props.labelId && (
          <div className={props.validateOption?.required ? 'required ' : ''}>
            <div
              className={`topping-label ${
                disabled ? 'disabled-topping-label' : ''
              }`}
            >
              <GetMessageComponent id={props.labelId} />
            </div>
          </div>
        )}
      </span>
      <div className="border backgroud">
        <Radio
          name={props.name}
          className={props.className}
          items={props.items}
          value={props.value}
          validator={props.validator}
          validateOption={props.validateOption}
          disabled={disabled}
          style={componentStyle}
          onChangeState={props.onChangeState}
        ></Radio>
      </div>
    </div>
  );
}
