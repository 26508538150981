import { useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  Checkbox,
  DataFilterbox,
  DataFilterboxItem,
  DateSuggest,
} from '~/shared/components/ui';
import {
  ModalDialogComponent,
  ModalDialogComponentProps,
} from '~/shared/components/ui/ModalDialog/ModalDialog';
import {
  GetMessage,
  GetMessageWithIntl,
  MessageProps,
} from '~/shared/components/parts/Message/Message';
import { CaptionButton } from '~/shared/components/ui/Button/CaptionButton';
import './base.css';
import {
  convertDateToLong,
  getRandomStringId,
  includeInputValidateError,
} from '~/shared/utils';
import { LicenseEditData } from './LicenseEditDialog';
import { validateFreeDate } from '~/shared/components/parts/validator';
import './LicenseAddDialog.css';
import { FullMethodName_ListAdminLicenseLicenseCatalogs } from '~/worker';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { error } from '~/shared/components/parts/Toast/Toast';

export interface LicenseAddDialogMessageOption {
  headerLabelId: MessageProps;
}

export interface LicenseAddDialogProps {
  messageOption: LicenseAddDialogMessageOption;
  isOpen: boolean;
  onDecision: (result: LicenseEditData) => void;
  onCancel: () => void;
}

export const LicenseAddDialog = (props: LicenseAddDialogProps) => {
  const intl = useIntl();
  const { isOpen, messageOption } = props;
  const [license, setLicense] =
    useState<mtechnavi.api.license.ILicenseCatalog>();
  const [withoutEnd, setWithoutEnd] = useState(false);
  const [startDt, setStartDt] = useState<Date | null>();
  const [endDt, setEndDt] = useState<Date | null>();
  const requiredLicenseArea = useRef(null);
  const [workingBlurLicense, setWorkingBlurLicense] = useState<Date>();

  const [licenseCatalogData, setLicenseCatalogData] = useState<
    mtechnavi.api.license.ILicenseCatalog[]
  >([]);
  const licenseCatalogItems = useMemo(
    () =>
      licenseCatalogData.map(
        (catalog): DataFilterboxItem => ({
          displayName: catalog.displayName ?? '',
          value: catalog.licenseCatalogId ?? '',
        })
      ),
    [licenseCatalogData]
  );
  const selectedLicenseItem = licenseCatalogItems.filter(
    (item) => item.value === license?.licenseCatalogId
  );

  const isInputError = (): boolean => {
    setWorkingBlurLicense(new Date());
    const inputValidationCheckList = [
      {
        value: license?.licenseCatalogId ?? '',
        ref: requiredLicenseArea,
      },
      {
        value: startDt?.toString() || '',
        ref: requiredLicenseArea,
      },
    ];
    if (!withoutEnd) {
      inputValidationCheckList.push({
        value: endDt?.toString() || '',
        ref: requiredLicenseArea,
      });
    }
    const targetElm = document.querySelector('.license-add-dialog');
    return includeInputValidateError(targetElm, intl, inputValidationCheckList);
  };

  const handleDecision = () => {
    if (isInputError()) {
      return;
    }
    if (!withoutEnd && (startDt?.getTime() ?? 0) > (endDt?.getTime() ?? 0)) {
      error([GetMessageWithIntl(intl, { id: 'E0000168' })]);
      return;
    }
    props.onDecision({
      id: getRandomStringId(), // 画面上で扱うための一時的なユニークキーをセット
      license: {
        licenseCatalogId: license?.licenseCatalogId,
        licenseCode: license?.licenseCode,
        displayName: license?.displayName,
        withoutEnd,
        startAt: convertDateToLong(startDt),
        endAt: withoutEnd ? null : convertDateToLong(endDt),
      },
    });
    clear();
  };

  const handleCancel = () => {
    props.onCancel();
    clear();
  };

  const clear = () => {
    setLicense(undefined);
    setWithoutEnd(false);
    setStartDt(null);
    setEndDt(null);
  };

  useEffect(() => {
    (async () => {
      const catalog =
        await window.App.services.ui.worker.filter<mtechnavi.api.license.ILicenseCatalog>(
          {
            action: 'reload',
            fullMethodName: FullMethodName_ListAdminLicenseLicenseCatalogs,
            filter: {},
            requestBody: {},
            sort: [{ displayName: 'asc' }],
          }
        );
      setLicenseCatalogData(catalog.items);
    })();
  }, []);

  const view = (
    <div className="license-add-dialog dialog-with-description">
      <div className="detail-area">
        <div className="comment-area">
          <div className="input-line">
            <div className="item-group-100">
              <div className="w-50">
                <DataFilterbox
                  name="license"
                  labelId="licenseAddDialog.license"
                  columns={['license']}
                  value={selectedLicenseItem}
                  data={licenseCatalogItems}
                  searchOption={{ targets: 'displayName' }}
                  onChangeState={(celection) => {
                    const target = licenseCatalogData.find(
                      (catalog) =>
                        catalog.licenseCatalogId === celection?.at(0)?.value
                    );
                    setLicense(target);
                  }}
                  validateOption={{ required: true }}
                  workingBlur={workingBlurLicense}
                />
              </div>
              <div className="w-50 checkbox-lineup">
                <Checkbox
                  name="expiration"
                  className="expiration"
                  columns={['expiration']}
                  items={[
                    {
                      displayName: GetMessageWithIntl(intl, {
                        prefixId: 'licenseAddDialog',
                        id: 'expiration',
                      }),
                      value: '1',
                    },
                  ]}
                  value={withoutEnd ? ['1'] : []}
                  onChangeState={(v) => {
                    setWithoutEnd(v.length > 0);
                    setEndDt(null);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="input-line">
            <div className="item-group-100">
              <div className="w-50">
                <DateSuggest
                  labelId="licenseAddDialog.startDt"
                  name="startDt"
                  validator={validateFreeDate(intl)}
                  validateOption={{ required: true }}
                  onChangeState={setStartDt}
                  value={startDt}
                  columns={['startDt']}
                ></DateSuggest>
              </div>
              <div className="w-50">
                <DateSuggest
                  labelId="licenseAddDialog.endDt"
                  name="endDt"
                  validator={validateFreeDate(intl)}
                  validateOption={{ required: !withoutEnd }}
                  onChangeState={setEndDt}
                  value={endDt}
                  disabled={withoutEnd}
                  columns={['endDt']}
                ></DateSuggest>
              </div>
            </div>
          </div>
        </div>
        <div className="button-area">
          <CaptionButton
            name="cancelBtn"
            buttonType="cancel"
            className="button"
            caption={GetMessage({ id: 'cancel' })}
            onClick={handleCancel}
          />
          <CaptionButton
            name="sendBtn"
            buttonType="basic"
            className="button"
            caption={GetMessage({ id: 'decision' })}
            onClick={handleDecision}
          />
        </div>
      </div>
    </div>
  );

  const openModalProps: ModalDialogComponentProps = {
    cancel: handleCancel,
    send: () => {},
    modalIsOpen: isOpen,
    headerLabelId: messageOption.headerLabelId,
    messageLabelId: {},
    elements: view,
  };

  return (
    <div className="LicenseAddDialog">
      <ModalDialogComponent {...openModalProps} />
    </div>
  );
};
