import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface ExecBusinessUnitContactHeader {
  actionName: 'execBusinessUnitContactHeader';
  request: BusinessUnitContactParam;
}

interface BusinessUnitContactParam {
  businessUnitContactHeader: mtechnavi.api.company.IBusinessUnitContactHeader;
  businessUnitContactId: string;
}

export const execBusinessUnitContactHeader = async (
  worker: AppSaveWorker,
  req: BusinessUnitContactParam
) => {
  const createMethodName =
    'mtechnavi.api.company.BusinessUnitManagementService/CreateBusinessUnitContactHeader';
  const updateMethodName =
    'mtechnavi.api.company.BusinessUnitManagementService/UpdateBusinessUnitContactHeader';

  let result: unknown[] = [];
  if (req.businessUnitContactHeader.businessUnitContactHeaderId) {
    result = await worker.invokeListRequest(updateMethodName, {
      businessUnitContactHeader: req.businessUnitContactHeader,
    });
  } else {
    result = await worker.invokeListRequest(createMethodName, {
      businessUnitContactHeader: req.businessUnitContactHeader,
      businessUnitContactId: req.businessUnitContactId,
    });
  }

  if (result && result.length > 0) {
    // shareToコール
    const sharetoFullMethodName =
      'mtechnavi.api.company.BusinessUnitManagementService/SharetoBusinessUnitContactHeader';
    await worker.invokeListRequest(sharetoFullMethodName, {
      businessUnitContactHeader: result[0],
    });
  }

  return result;
};
