import {
  CSSProperties,
  forwardRef,
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
  useReducer,
  useRef,
  useState,
} from 'react';
import './TextBox.css';
import { checkEmptyRequiredValue, countInputWord } from '../validator';
import { ReactComponent as VisibilityIcon } from '@material-design-icons/svg/filled/visibility.svg';
import { ReactComponent as InVisibilityIcon } from '@material-design-icons/svg/filled/visibility_off.svg';
import { ReactComponent as CloseIcon } from '@material-design-icons/svg/filled/close.svg';
import { ErrorMessage } from '../ErrorMessage/ErrorMessage';
import { GetMessage } from '../Message/Message';
import { getErrorBorderClassName } from '~/shared/utils';
import { useIntl } from 'react-intl';

export interface TextBoxComponentRef {
  focus: () => void;
}

export interface TextBoxProps {
  name: string;
  className?: string;
  value?: string | null;
  placeholder?: string;
  isCounter?: boolean;
  type: InputType;
  formatter?: (v: string) => string;
  validator?: (v: string) => string[];
  validateOption?: TextBoxValidateOption;
  externalIcon?: JSX.Element;
  disabled?: boolean;
  style?: CSSProperties;
  onChangeState?: (arg: string) => void;
}

export interface TextBoxValidateOption {
  maxLength?: number;
  minLength?: number;
  isSkippedValidation?: boolean;
  required?: boolean;
  isSkippedRequireCheck?: boolean;
}

export type InputType = 'text' | 'password' | 'email';

/* reducer用type,インターフェース */
type ReducerActionType = 'reset' | 'blur' | 'change' | 'focus';
interface ReducerData {
  value: string;
  displayValue: string;
}

// 文字列カウンタービュー
export function getCounterView(maxLength: number, value: string) {
  // カウンター表示がONで最大文字数が設定されている場合は 0/300 のように表示
  if (maxLength < Number.MAX_SAFE_INTEGER) {
    return (
      <small>
        {value.length}/{maxLength}
      </small>
    );
  }

  // カウンター表示がONで文字が入力されてる場合は 3文字 のように表示
  if (value.length > 0) {
    return <small>{value.length}文字</small>;
  }
}

export const TextBox = forwardRef(
  (props: TextBoxProps, ref: Ref<TextBoxComponentRef>) => {
    const intl = useIntl();
    const REQUIRED_MESSAGE = GetMessage({ id: 'E0000003' });

    const { onChangeState, formatter, validator } = props;
    // input type属性に設定する値
    const [type, setType] = useState(props.type);

    // inputのクラス名
    const inputClassName = props.className ?? '';
    const style = props.style ?? {};

    // オプショナルチェック
    const format = useCallback(
      (v: string) => {
        if (!formatter) {
          return v;
        }
        return formatter(v);
      },
      [formatter]
    );

    const validate = useCallback(
      (v: string): string[] => {
        if (!validator) {
          return [];
        }
        return validator(v);
      },
      [validator]
    );

    const maxLength =
      props.validateOption?.maxLength ?? Number.MAX_SAFE_INTEGER;
    const minLength =
      props.validateOption?.minLength ?? Number.MIN_SAFE_INTEGER;
    const required = props.validateOption?.required ?? false;
    const isSkippedValidation =
      props.validateOption?.isSkippedValidation ?? false;
    const isSkippedRequireCheck =
      props.validateOption?.isSkippedRequireCheck ?? false;

    // 引数チェック
    if (minLength > maxLength) {
      throw new Error(
        'assertion error: minLengthはmaxLengthより小さい値を設定してください'
      );
    }

    // useState
    const [message, setMessage] = useState<string[]>([]);
    const [isVisiblePassword, setVisiblePassword] = useState(false);
    const changeParentState = useCallback(
      (v: string) => {
        onChangeState && onChangeState(v);
      },
      [onChangeState]
    );
    const [isEdited, setEdited] = useState(false);

    const handleValidate = (inputValue: string) => {
      const msg: string[] = [];

      // フォーカスを一度も当てていない場合はearly return
      if (!isEdited || isSkippedValidation) {
        return msg;
      }

      // 必須入力チェック
      const isEmpty = checkEmptyRequiredValue(inputValue);
      if (!isSkippedRequireCheck && required && isEmpty) {
        msg.push(REQUIRED_MESSAGE);
        return msg;
      } else if (!required && isEmpty) {
        // 必須入力でなく、入力値が空の場合は以降の処理をスキップ
        return msg;
      }

      // 文字数カウント
      msg.push(...countInputWord(intl, inputValue, minLength, maxLength));

      // 外部バリデーション
      msg.push(...validate(inputValue));

      return msg;
    };

    // VisibilityIconクリック処理（パスワード表示）
    const handleVisibleInput = () => {
      setType('text');
      setVisiblePassword(true);
    };

    // VisibilityOffIconクリック処理（パスワード非表示）
    const handleInVisibleInput = () => {
      setType('password');
      setVisiblePassword(false);
    };

    // クリアボタンの表示・非表示を制御
    const [clearButtonVisibility, setClearButtonVisibility] = useState<
      'visible' | 'hidden'
    >('hidden');

    const inputRef = useRef<HTMLInputElement>(null);

    const textboxReducer = (
      valueState: ReducerData,
      action: { type: ReducerActionType; value?: string }
    ): ReducerData => {
      const value = action.value ?? '';
      switch (action.type) {
        case 'reset':
          setMessage([]);
          return { value: '', displayValue: '' };

        case 'blur': {
          const msg = handleValidate(value);
          setMessage(msg);
          return msg.length
            ? {
                value: value,
                displayValue: value,
              }
            : {
                value: value,
                displayValue: format(value),
              };
        }

        case 'change':
          return {
            value: value,
            displayValue: value,
          };

        case 'focus':
          return {
            ...valueState,
            displayValue: valueState.value,
          };

        default:
          return valueState;
      }
    };
    const [data, dispatch] = useReducer(textboxReducer, {
      value: props.value ?? '',
      displayValue: format(props.value ?? ''),
    });

    useEffect(() => {
      if (props.value === data.value) {
        return;
      }

      dispatch({ type: 'blur', value: props.value ?? '' });

      // 入力中の値か親要素からの連携された値かの区別するための実装なので、props.valueのみを依存値とする
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.value]);

    useEffect(() => {
      // 必須制御が変わって任意になる場合にエラー表示をクリアする
      if (!required) {
        // 値が空の場合だけに対応範囲を限定する
        if (!data.displayValue) {
          setMessage([]);
        }
      }
      // 呼出元で必須条件を変更したというイレギュラー対応なのでrequiredのみ依存関係にする
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [required]);

    useEffect(() => {
      // 必須チェック不要になる場合にエラー表示をクリアする
      if (isSkippedRequireCheck) {
        // 値が空の場合だけに対応範囲を限定する
        if (!data.displayValue) {
          setMessage([]);
        }
      }
      // 呼出元で必須条件を変更したというイレギュラー対応なのでisSkippedRequireCheckのみ依存関係にする
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSkippedRequireCheck]);

    useImperativeHandle(ref, () => ({
      focus: () => inputRef.current?.focus(),
    }));

    return (
      <div className="TextBox">
        <div
          className={`main-area ${
            props.disabled
              ? 'basic-disabled-input-border'
              : 'basic-input-border'
          } ${getErrorBorderClassName(message)}`}
          onMouseEnter={() => {
            // マウスオーバー時に入力値が無ければクリアボタンは非表示
            if (data.value === '') {
              return;
            }
            setClearButtonVisibility('visible');
          }}
          onMouseLeave={() => {
            setClearButtonVisibility('hidden');
          }}
          onClick={() => {
            //クリアボタンがhiddenになっている場合も含めてinputにフォーカス
            inputRef.current?.focus();
          }}
        >
          <input
            name={props.name}
            className={inputClassName}
            type={type}
            onBlur={(event) => {
              dispatch({ type: 'blur', value: event.target.value });
              changeParentState(event.target.value);
            }}
            value={data.displayValue}
            placeholder={props.placeholder}
            onChange={(event) => {
              dispatch({ type: 'change', value: event.target.value });
              changeParentState(event.target.value);
            }}
            onFocus={() => {
              setEdited(true);
              dispatch({ type: 'focus' });
            }}
            maxLength={maxLength}
            required={required}
            disabled={props.disabled}
            style={style}
            ref={inputRef}
            autoComplete="off"
          />

          {/* クリアボタン */}
          {!props.disabled && (
            <div
              className={`icon-wrapper ${
                props.disabled ? 'icon-wrapper-disabled' : ''
              }`}
            >
              <CloseIcon
                onClick={(event) => {
                  event.stopPropagation();
                  dispatch({ type: 'reset' });
                  changeParentState('');
                  inputRef.current?.focus();
                }}
                style={{ visibility: `${clearButtonVisibility}` }}
              ></CloseIcon>
            </div>
          )}

          {/* password表示・非表示切り替えボタン */}
          <div className="icon-wrapper">
            {(!props.disabled && type === 'password' && !isVisiblePassword && (
              <VisibilityIcon
                className="padding"
                onClick={() => handleVisibleInput()}
              />
            )) ||
              (isVisiblePassword && (
                <InVisibilityIcon
                  className="padding"
                  onClick={() => handleInVisibleInput()}
                />
              ))}
          </div>

          {/* 外部指定アイコン */}
          {props.externalIcon && (
            <div
              className={`icon-wrapper ${
                props.disabled ? 'icon-wrapper-disabled' : ''
              }`}
            >
              {props.externalIcon}
            </div>
          )}
        </div>
        {props.isCounter && <div>{getCounterView(maxLength, data.value)}</div>}

        {!props.disabled && !isSkippedValidation ? (
          <ErrorMessage message={message}></ErrorMessage>
        ) : (
          <ErrorMessage message={[]}></ErrorMessage>
        )}
      </div>
    );
  }
);
