import { ViewId } from '~/shared/utils';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { useState, useEffect, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { SimpleIconListView } from '~/shared/components/ui';
import {
  DISPLAY_TYPE,
  ResponsiblePersonRegistrationDialog,
  ResponsiblePersonRegistrationDialogInputOption,
} from '~/shared/components/ui/Dialog/ResponsiblePersonRegistrationDialog';
import { GetMessageWithIntl } from '~/shared/components';
import { isLinkedTenant } from '~/tenant/core/pages/BusinessUnitManagementConfirmation/utils/conditions';

interface RankRequestTabItemProps {
  businessUnitRankContents?: mtechnavi.api.company.IBusinessUnitRankContent[];
  businessUnitManagementContent?: mtechnavi.api.company.IBusinessUnitManagementContent;
  businessUnitBranchs?: mtechnavi.api.company.IBusinessUnitBranch[];
  onChangeLoadingState: (arg: boolean) => void;
}

interface RankType {
  id: number; //Key  (map index)
  requestId: string; // 品質要求ID
  receptionId: string; // 品質管理（責任者）ID
  displayName: string; //適用品種
  rank: string; // ランク
  companyDisplayName: string; // 品質管理企業名
  qualityManagerDisplayName: string; // 品質管理者名
  managerCategory: string; // 正/副(担当分類)
  businessUnitName: string; // 工場名
}

const VIEW_ID: ViewId = 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION';

const rankRequestColumns = [
  {
    header: {
      id: 'rank.displayName',
      prefixId: VIEW_ID,
    },
    propertyName: 'displayName',
    width: '16%',
  },
  {
    header: {
      id: 'rank.rank',
      prefixId: VIEW_ID,
    },
    propertyName: 'rank',
    width: '8rem',
  },
  {
    header: {
      id: 'rank.companyDisplayName',
      prefixId: VIEW_ID,
    },
    propertyName: 'companyDisplayName',
    width: '17rem',
  },
  {
    header: {
      id: 'rank.qualityManagerDisplayName',
      prefixId: VIEW_ID,
    },
    propertyName: 'qualityManagerDisplayName',
    width: '12rem',
  },
  {
    header: {
      id: 'rank.managerCategory',
      prefixId: VIEW_ID,
    },
    propertyName: 'managerCategory',
    width: '10rem',
  },
  {
    header: {
      id: 'rank.businessUnitName',
      prefixId: VIEW_ID,
    },
    propertyName: 'businessUnitName',
    width: '14rem',
  },
];

export const RankRequestTabItem = ({
  businessUnitRankContents,
  businessUnitManagementContent,
  businessUnitBranchs,
  onChangeLoadingState,
}: RankRequestTabItemProps) => {
  const intl = useIntl();
  const [rankRequestItem, setRankRequestItem] = useState<RankType[]>([]);
  const [requestCompanyRank, setRequestCompanyRank] = useState<string>('');
  // 責任者登録ダイアログ
  const [rankFormItem, setRankFormItem] =
    useState<ResponsiblePersonRegistrationDialogInputOption>({
      displayType: 'display',
      rankContent: {},
      sharedCompanyId: '',
      isLinkedTenant: false,
    });

  // 責任者登録ダイアログ
  const [
    isResponsiblePersonRegistrationDialogOpen,
    setResponsiblePersonRegistrationDialogOpen,
  ] = useState(false);

  // 品質要求変更時
  useEffect(() => {
    if (!businessUnitRankContents) {
      setRankRequestItem([]);
    }
    const ranks = window.App.services.ui.getNameOption('A4000011');
    const tmpData: string[] = [];
    ranks.map((rank) => {
      businessUnitRankContents?.map((item) => {
        if (
          rank.code &&
          item.businessUnitRankRequest?.rank?.code === rank.code
        ) {
          tmpData.push(rank.code);
        }
      });
    });
    setRequestCompanyRank(tmpData[0]);

    const vals: RankType[] = [];
    businessUnitRankContents?.map((v, index) => {
      let businessUnitName = '';
      // テナント紐付けなし、マスタ.拠点情報.工場名を参照
      if (
        !isLinkedTenant(businessUnitManagementContent?.tenantStatus?.systemName)
      ) {
        businessUnitName =
          v.businessUnitRankReception?.businessUnitBranchProperties
            ?.displayName ?? '';
        // 紐付けありの場合
      } else {
        // 自社、かつ拠点IDがからでない場合、拠点マスタ.拠点名を参照
        if (
          v.businessUnitRankReception?.ownCompany &&
          v.businessUnitRankReception?.businessUnitBranchProperties
            ?.businessUnitBranchId
        ) {
          businessUnitName =
            businessUnitBranchs?.find(
              (branch) =>
                branch.businessUnitBranchId ===
                v.businessUnitRankReception?.businessUnitBranchProperties
                  ?.businessUnitBranchId
            )?.displayName ?? '';
          // 他社の場合、マスタ.拠点情報.工場名を参照
        } else {
          businessUnitName =
            v.businessUnitRankReception?.businessUnitBranchProperties
              ?.displayName ?? '';
        }
      }
      vals.push({
        id: index,
        requestId: v.businessUnitRankRequest?.businessUnitRankRequestId ?? '',
        receptionId:
          v.businessUnitRankReception?.businessUnitRankReceptionId ?? '',
        displayName: v.businessUnitRankRequest?.displayName ?? '',
        rank: v.businessUnitRankRequest?.rank?.code ?? '',
        companyDisplayName:
          v.businessUnitRankReception?.companyDisplayName ?? '',
        qualityManagerDisplayName:
          v.businessUnitRankReception?.qualityManager?.displayName ?? '',
        managerCategory:
          v.businessUnitRankReception?.category?.displayNameLang?.ja ?? '',
        businessUnitName: businessUnitName,
      });
    });
    vals.sort((val1, val2) =>
      val1.displayName.localeCompare(val2.displayName, 'ja')
    );
    setRankRequestItem(vals);
    // businessUnitRankContents 変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessUnitRankContents]);

  // 責任者登録/確認ダイアログ
  const handleResponsiblePersonRegistrationDialog = (
    listViewItem: RankType,
    displayType: DISPLAY_TYPE
  ) => {
    let targetItem:
      | mtechnavi.api.company.IBusinessUnitRankContent
      | null
      | undefined;

    // rankRequestIdで絞る
    const items =
      businessUnitRankContents?.filter((v) => {
        return (
          listViewItem.requestId ===
          v.businessUnitRankRequest?.businessUnitRankRequestId
        );
      }) ?? [];
    // 二つ以上アイテムをある場合は、rankReceptionIDで絞る
    if (items.length > 1) {
      targetItem = items.find(
        (v) =>
          v.businessUnitRankReception?.businessUnitRankReceptionId ===
          listViewItem.receptionId
      );
    } else {
      targetItem = items.length === 1 ? items[0] : null;
    }

    if (!targetItem) {
      return;
    }
    setRankFormItem({
      displayType: displayType,
      rankContent: targetItem,
      sharedCompanyId:
        businessUnitManagementContent?.businessUnitManagement?.companyId ?? '',
      isLinkedTenant: isLinkedTenant(
        businessUnitManagementContent?.tenantStatus?.systemName
      ),
    });
    setResponsiblePersonRegistrationDialogOpen(true);
  };

  const onParentLoadingState = useCallback(
    (arg: boolean) => {
      onChangeLoadingState(arg);
    },
    [onChangeLoadingState]
  );

  return (
    <>
      <div className="tab-info-body pt-0">
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-50">
              <p className="company-rank">
                {GetMessageWithIntl(intl, {
                  prefixId: 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION',
                  id: 'rank.companyRank',
                })}
                {requestCompanyRank}
              </p>
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              <SimpleIconListView
                data={rankRequestItem}
                viewOptions={{
                  previewRowCount: 10,
                  keyColumn: 'id',
                  columns: rankRequestColumns,
                  omitFooter: true,
                }}
                actionOptions={{}}
                iconMenuOptions={{
                  iconMenu: [
                    {
                      name: 'description',
                      displayName: '確認',
                      func: (v: RankType) => {
                        handleResponsiblePersonRegistrationDialog(v, 'display');
                      },
                    },
                  ],
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {/* 責任者確認ダイアログ */}
      <ResponsiblePersonRegistrationDialog
        isOpen={isResponsiblePersonRegistrationDialogOpen}
        inputOption={rankFormItem}
        onDecision={() => {}}
        onCancel={() => {
          setResponsiblePersonRegistrationDialogOpen(false);
        }}
        onChangeLoadingState={(v: boolean) => {
          onParentLoadingState(v);
        }}
      />
    </>
  );
};
