import { useEffect, useState } from 'react';
import {
  ModalDialogComponent,
  ModalDialogComponentProps,
  GetMessage,
  GetMessageWithIntl,
  error,
  MessageProps,
} from '~/shared/components';
import { MenuItem, MenuListView } from '~/shared/components/ui';

import type { DummyUser } from '~/worker';

import {
  InitialFilterItem,
  Preset,
  Property,
  Schema,
} from '~/shared/components/ui';
import { PresetItem } from '~/shared/services';
import './base.css';
import './SearchMenuListViewDialog.css';

import { useAuth } from '~/shared/contexts/AuthProvider';
import { useIntl } from 'react-intl';
import {
  ViewId,
  getExceptionMessage,
  getLocalStorageCheckboxData,
  getPresetAndSchema,
} from '~/shared/utils';
import { CaptionButton } from '../Button';

export interface SearchMenuListViewDialogProps {
  fullMethodName: string;
  viewId: ViewId;
  isOpen: boolean;
  headerLabelId: MessageProps;
  headerTitleId?: MessageProps;
  selectLimit?: number;
  menuTarget?: string;
  menuEvent?: MenuItem[];
  onCloseChange?: () => void;
  onSelectChange?: (items: DummyUser[]) => void;
  onHandleFormatSchema?: (sch: Schema) => Schema;
  initialFilterItems?: { type: string; info: InitialFilterItem[] };
}

export function SearchMenuListViewDialog(props: SearchMenuListViewDialogProps) {
  const intl = useIntl();
  // ログイン者の情報特定用email
  const myEmail = useAuth().user?.email ?? '';
  const msg = GetMessage({
    id: 'selectItemOverError',
    value: {
      number: props.selectLimit,
      unit: '個',
    },
  });
  const [modleList, setModleList] = useState<DummyUser[]>([]);
  const [schema, setSchema] = useState<Property[]>([]);
  const [preset, setPreset] = useState<Preset>({
    filter: {},
    propertyNames: [],
  });
  const [childrenPresetItem, setChildrenPresetItem] = useState<PresetItem[]>();
  const [checkboxValue, setCheckboxValue] = useState<string[]>([]);
  const onSelectItemsState = props.onSelectChange ?? (() => {});

  useEffect(() => {
    (async () => {
      try {
        const { childrenPresetItem, schemas, preset } =
          await getPresetAndSchema(props.viewId, [props.fullMethodName]);
        // カラムの結合・読み替え処理
        const formatSchema = props.onHandleFormatSchema ?? ((v) => v);

        setChildrenPresetItem(childrenPresetItem);
        setSchema(formatSchema(schemas[0]));
        setPreset(preset);
      } catch (err) {
        error(getExceptionMessage(intl, err));
      }
    })();
    // 初回時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const menuViewlist = (props.menuEvent ?? []).map((v) => v.name);

  useEffect(() => {
    setModalIsOpen(props.isOpen);
  }, [props.isOpen]);

  const [baseModalIsOpen, setModalIsOpen] = useState(false);
  const selectModal = async () => {
    const list: DummyUser[] = [];

    const lsCheckboxValue = getLocalStorageCheckboxData(
      props.viewId ?? '',
      myEmail
    );
    const menuTarget = props.menuTarget ?? 'none';
    if (lsCheckboxValue.length >= 1 && modleList.length > 0) {
      // mongoDBに直接問合せ
      const result = await window.App.services.ui.worker.filter({
        action: 'query',
        fullMethodName: props.fullMethodName,
        filter: {
          [menuTarget]: { $in: lsCheckboxValue },
        },
        sort: [],
      });
      list.push(...(result.items as DummyUser[]));

      if (props.selectLimit && props.selectLimit < list.length) {
        error([msg]);
      } else {
        onSelectItemsState!(list ?? []);
      }
    } else {
      error([GetMessageWithIntl(intl, { id: 'E0000023' })]);
    }
  };
  const [comment, setComment] = useState('');

  const view = (
    <div className="content-box">
      <MenuListView
        headerTitle={{ viewId: props.viewId }}
        fullMethodName={props.fullMethodName}
        schema={schema}
        preset={preset}
        presetItems={childrenPresetItem}
        checkValue={checkboxValue}
        listSkipType={{
          isListActionMenu: props.menuEvent ? false : true,
          isTotal: true,
          isOutput: true,
        }}
        menuEvent={props.menuEvent}
        menuViewlist={menuViewlist}
        onChangeState={setCheckboxValue}
        onChangeCheckItemsState={(items) => {
          setModleList(items ?? []);
        }}
        menuTarget={props.menuTarget ?? 'company.companyId'}
        initialFilterItems={props.initialFilterItems ?? undefined}
      />
      <div className="btns">
        <CaptionButton
          name="cancelBtn"
          buttonType="cancel"
          className="btn limit buttonBox"
          caption={GetMessageWithIntl(intl, { id: 'cancel' })}
          onClick={() => {
            props.onCloseChange!();
          }}
        />
        <CaptionButton
          name="backBtn"
          buttonType="basic"
          className="btn limit buttonBox"
          caption={GetMessageWithIntl(intl, { id: 'select' })}
          onClick={selectModal}
        />
      </div>
    </div>
  );

  const openModalProps: ModalDialogComponentProps = {
    cancel: () => {},
    send: () => {},
    modalIsOpen: baseModalIsOpen,
    comment: comment,
    onChangeState: setComment,
    headerLabelId: props.headerLabelId,
    titleLabelId: props.headerTitleId ?? props.headerLabelId,
    messageLabelId: { prefixId: 'DIALOG_MESSAGE', viewId: props.viewId },
    elements: view,
    className: 'search-dialog',
  };

  return (
    <div className="SearchListView SearchMenuListViewDialog">
      <ModalDialogComponent {...openModalProps} />
    </div>
  );
}
