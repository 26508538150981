import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface RejectEstimateSelectionRequest {
  actionName: 'rejectEstimateSelection';
  request: mtechnavi.api.estimation.IRejectEstimateSelectionRequest;
}

export const rejectEstimateSelection = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.estimation.IRejectEstimateSelectionRequest
) => {
  return worker.invokeListRequest<
    mtechnavi.api.estimation.IRejectEstimateSelectionRequest,
    void
  >(
    'mtechnavi.api.estimation.EstimateSenderService/RejectEstimateSelection',
    req
  );
};
