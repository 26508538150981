import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface DeleteBusinessUnitManagementList {
  actionName: 'deleteBusinessUnitManagementList';
  request: mtechnavi.api.company.IBusinessUnitManagement[];
}

export const deleteBusinessUnitManagementList = async (
  worker: AppSaveWorker,
  request: mtechnavi.api.company.IBusinessUnitManagement[]
) => {
  const fullMethodName =
    'mtechnavi.api.company.BusinessUnitManagementService/DeleteBusinessUnitManagement';
  const result = request.map((item) => {
    return worker.invokeListRequest(fullMethodName, {
      businessUnitManagement: item,
    });
  });
  await Promise.all(result);
};
