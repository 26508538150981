import React from 'react';
import { CategorizedBranchRef } from './CategorizedBranch';
import { CategorizedTipCheckboxRef } from './CategorizedTipCheckbox';
import { DisplayNameLang } from '~/shared/utils/commonType';

export interface CheckItem {
  id?: string;
  displayNameLang?: DisplayNameLang;
}

export type CategorizedList = CategorizedItem[];

export type CategorizedItem = CategorizedBranchData | CategorizedTipData;
export interface CategorizedBranchData {
  CategorizedItemId: string; // 取り扱いやすいようカテゴリも ID を持つ
  displayNameLang?: DisplayNameLang;
  children: (CategorizedBranchData | CategorizedTipData)[];
}

export interface CategorizedTipData {
  CategorizedItemId: string;
  displayNameLang?: DisplayNameLang;
}

export const isCategorizedBranchList = (
  list: CategorizedItem[]
): list is CategorizedBranchData[] => {
  return list.every((item) => isCategorizedBranch(item));
};
export const isCategorizedTipList = (
  list: CategorizedItem[]
): list is CategorizedTipData[] => {
  return list.every((item) => isCategorizedTip(item));
};
export const isCategorizedBranch = (
  item: CategorizedBranchData | CategorizedTipData
): item is CategorizedBranchData => {
  return Object.hasOwn(item, 'children');
};
export const isCategorizedTip = (
  item: CategorizedBranchData | CategorizedTipData
): item is CategorizedTipData => {
  return !Object.hasOwn(item, 'children');
};

export const allCheckIdFormat = (parentId = '') => `${parentId}-allCheck`;

/**
 * CategorizedList を filterIds に指定された ID を内包するものだけに絞り込む。
 * 第一階層は filterIds の ID を内包しなくても維持される。
 */
export const filterCategorizedList = (
  listData: CategorizedList,
  filterIds: string[]
): CategorizedList => {
  return listData.map((category) => {
    if (!isCategorizedBranch(category)) {
      return category;
    }
    return {
      ...category,
      children:
        filterCategorizedListChildren(category.children, filterIds) || [],
    };
  });
};
const filterCategorizedListChildren = (
  listData: CategorizedBranchData[] | CategorizedTipData[],
  filterIds?: string[]
): CategorizedBranchData[] | CategorizedTipData[] | null => {
  if (isCategorizedBranchList(listData)) {
    const categories = listData
      .map((item) => {
        const children = filterCategorizedListChildren(
          item.children,
          filterIds
        );
        if (!children) {
          return null;
        }
        return {
          ...item,
          children: children,
        };
      })
      .filter((item) => !!item) as CategorizedBranchData[];
    if (categories.length === 0) {
      return null;
    }
    return categories;
  } else {
    if (
      filterIds &&
      !listData.some((item) =>
        filterIds.some((id) => id === item.CategorizedItemId)
      )
    ) {
      return null;
    }
    return listData;
  }
};

/**
 * 階層構造のデータを元に各要素の ref および、「フォーカス遷移可能な関連する要素の ID 」のデータを作成する。
 */
export interface RelationMap {
  [id: string]: RelationItem;
}
interface RelationItem {
  id: string;
  ref?: React.RefObject<CategorizedBranchRef | CategorizedTipCheckboxRef>;
  parentId?: string; // 親要素のID
  prevId?: string; // 直近の要素のID
  nextId?: string; // 直近の要素のID
  prevLayerId?: string; // 直近の別階層要素のID
  nextLayerId?: string; // 直近の別階層要素のID
  prevSiblingId?: string; // 直近の兄弟ID
  nextSiblingId?: string; // 直近の兄弟ID
}
interface ParentInfo {
  parent?: CategorizedItem;
  prev?: CategorizedItem;
  next?: CategorizedItem;
}
export const createRelationList = (
  list: CategorizedItem[],
  isVertical?: boolean
): RelationMap => {
  const [relationList] = createChildRelation(list, isVertical, undefined);
  const relationMap: RelationMap = {};
  relationList.forEach((item) => {
    relationMap[item.id] = item;
  });
  return relationMap;
};
const createChildRelation = (
  list: CategorizedItem[],
  isVertical?: boolean,
  parentInfo?: ParentInfo
): [RelationItem[], string, string] => {
  if (isCategorizedTipList(list)) {
    // 末端チェックボックス要素のみの階層の場合
    if (isVertical) {
      // 子チェック縦表示 ( "すべて" チェックがない世界 )
      const results: RelationItem[] = [
        ...list.flatMap((item, index): RelationItem[] => {
          const isFirst = index === 0;
          const rel: RelationItem = {
            id: item.CategorizedItemId,
            ref: React.createRef(),
            parentId: parentInfo?.parent?.CategorizedItemId,
            prevId: isFirst
              ? parentInfo?.parent?.CategorizedItemId
              : list.at(index - 1)?.CategorizedItemId,
            nextId:
              list.at(index + 1)?.CategorizedItemId ||
              parentInfo?.next?.CategorizedItemId,
            prevLayerId: parentInfo?.parent?.CategorizedItemId,
            nextLayerId: parentInfo?.next?.CategorizedItemId,
          };
          return [rel];
        }),
      ];
      return [
        results,
        parentInfo?.parent?.CategorizedItemId || '',
        results.at(results.length - 1)?.id || '',
      ];
    } else {
      // 子チェック横表示 ( "すべて" チェックがある世界 )
      const results: RelationItem[] = [
        {
          // "全て" チェック用の要素
          id: allCheckIdFormat(parentInfo?.parent?.CategorizedItemId),
          ref: React.createRef(),
          parentId: parentInfo?.parent?.CategorizedItemId,
          prevId: parentInfo?.parent?.CategorizedItemId,
          nextId: list.at(0)?.CategorizedItemId,
          prevLayerId: parentInfo?.parent?.CategorizedItemId,
          nextLayerId: parentInfo?.next?.CategorizedItemId,
        },
        ...list.flatMap((item, index): RelationItem[] => {
          const isFirst = index === 0;
          const rel: RelationItem = {
            id: item.CategorizedItemId,
            ref: React.createRef(),
            parentId: parentInfo?.parent?.CategorizedItemId,
            prevId: isFirst
              ? allCheckIdFormat(parentInfo?.parent?.CategorizedItemId)
              : list.at(index - 1)?.CategorizedItemId,
            nextId:
              list.at(index + 1)?.CategorizedItemId ||
              parentInfo?.next?.CategorizedItemId,
            prevLayerId: parentInfo?.parent?.CategorizedItemId,
            nextLayerId: parentInfo?.next?.CategorizedItemId,
          };
          return [rel];
        }),
      ];
      return [
        results,
        allCheckIdFormat(parentInfo?.parent?.CategorizedItemId),
        results.at(results.length - 1)?.id || '',
      ];
    }
  } else {
    let latestFirstReefId = '';
    let latestLastReefId = '';
    const results = (list as CategorizedItem[]).flatMap(
      (item, index): RelationItem[] => {
        const isBranch = isCategorizedBranch(item);
        const isFirst = index === 0;
        const isEnd = index === (list as CategorizedItem[]).length - 1;
        const prevLayerId = isVertical ? latestLastReefId : latestFirstReefId;
        if (isBranch) {
          // カテゴリ要素の場合
          const isChildTipList = isCategorizedTipList(item.children);
          const nextId =
            isChildTipList && !isVertical
              ? allCheckIdFormat(item.CategorizedItemId)
              : item.children.at(0)?.CategorizedItemId;

          const rel: RelationItem = {
            id: item.CategorizedItemId,
            ref: React.createRef(),
            parentId: parentInfo?.parent?.CategorizedItemId,
            prevId: isFirst
              ? parentInfo?.parent?.CategorizedItemId
              : latestLastReefId,
            prevSiblingId: isFirst
              ? undefined
              : (list as CategorizedItem[]).at(index - 1)?.CategorizedItemId,
            nextSiblingId: (list as CategorizedItem[]).at(index + 1)
              ?.CategorizedItemId,
            nextId: nextId,
            prevLayerId: isFirst
              ? parentInfo?.parent?.CategorizedItemId
              : prevLayerId,
            nextLayerId: nextId,
          };

          const [children, childFirstReefId, childLastReefId] =
            createChildRelation(item.children, isVertical, {
              parent: item,
              prev: isFirst
                ? parentInfo?.prev
                : (list as CategorizedItem[]).at(index - 1),
              next: isEnd
                ? parentInfo?.next
                : (list as CategorizedItem[]).at(index + 1),
            });
          latestFirstReefId = childFirstReefId;
          latestLastReefId = childLastReefId;
          return [...children, rel];
        } else {
          // カテゴリと混在する末端チェック要素の場合
          const nextId = !isVertical
            ? allCheckIdFormat(item.CategorizedItemId)
            : (list as CategorizedItem[]).at(index + 1)?.CategorizedItemId ||
              parentInfo?.next?.CategorizedItemId;

          const rel: RelationItem = {
            id: item.CategorizedItemId,
            ref: React.createRef(),
            parentId: parentInfo?.parent?.CategorizedItemId,
            prevId: isFirst
              ? parentInfo?.parent?.CategorizedItemId
              : latestLastReefId,
            prevSiblingId: isFirst
              ? undefined
              : (list as CategorizedItem[]).at(index - 1)?.CategorizedItemId,
            nextSiblingId: (list as CategorizedItem[]).at(index + 1)
              ?.CategorizedItemId,
            nextId: nextId,
            prevLayerId: isFirst
              ? parentInfo?.parent?.CategorizedItemId
              : prevLayerId,
            nextLayerId: nextId,
          };
          latestFirstReefId = item.CategorizedItemId;
          latestLastReefId = item.CategorizedItemId;
          return [rel];
        }
      }
    );
    return [results, latestFirstReefId, latestLastReefId];
  }
};

// 対象の ID に一致するものだけをフラットな配列で返す
export const extractTargetIdItems = (
  data: CategorizedItem[],
  targetIds: string[],
  canBeBranchSelection = false
): CheckItem[] => {
  return data.flatMap((item) => {
    let children: CheckItem[] = [];
    const isBranch = isCategorizedBranch(item);
    if (isBranch) {
      children = extractTargetIdItems(
        item.children,
        targetIds,
        canBeBranchSelection
      );
    }
    let currentMatch: CheckItem[] = [];
    if (
      (canBeBranchSelection || !isBranch) &&
      targetIds.includes(item.CategorizedItemId)
    ) {
      currentMatch = [
        {
          id: item.CategorizedItemId,
          displayNameLang: item.displayNameLang,
        },
      ];
    }
    return [...children, ...currentMatch];
  });
};
