import {
  useEffect,
  useMemo,
  useState,
  CSSProperties,
  useCallback,
  useRef,
} from 'react';
import { Property } from '~/shared/services';
import './base.css';
import { ReactComponent as KeyboardArrowRightIcon } from '@material-design-icons/svg/filled/keyboard_arrow_right.svg';
import { ReactComponent as KeyboardArrowLeftIcon } from '@material-design-icons/svg/filled/keyboard_arrow_left.svg';
import { ReactComponent as SearchIcon } from '@material-design-icons/svg/filled/search.svg';
import { ReactComponent as ExpandMoreIcon } from '@material-design-icons/svg/filled/expand_more.svg';
import { ReactComponent as DeleteIcon } from '@material-design-icons/svg/filled/close.svg';
import { ReactComponent as ExpandLessIcon } from '@material-design-icons/svg/filled/expand_less.svg';
import {
  GetMessage,
  GetMessageComponent,
} from '~/shared/components/parts/Message/Message';
import { getCorrectFilterboxItem, searchItemAreaView } from './Filterbox';
import { ErrorMessage } from '~/shared/components/parts/ErrorMessage/ErrorMessage';
import { getProperty } from '../common';
import { getErrorBorderClassName } from '~/shared/utils';
import { IconButton } from '~/shared/components/ui/Button';

const INIT_CURRENT_INDEX = 0;

// 配列をpage（ページ送り・戻し）用に分割
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function chunk<T extends any[]>(arr: T, size: number) {
  return arr.reduce(
    (newarr, _, i) => (i % size ? newarr : [...newarr, arr.slice(i, i + size)]),
    [] as T[][]
  );
}

interface Page {
  maxIndex: number;
  currentIndex: number;
}

// page（ページ送り・戻し）処理を行う
function initItemsandPage(
  data: DataFilterboxItem[],
  pageSize: number,
  setItems: (arg: DataFilterboxItem[][]) => void,
  setPage: (arg: Page) => void
) {
  const maxIndex = Math.max(
    Math.ceil(data.length / pageSize) - 1,
    INIT_CURRENT_INDEX
  );

  // スライスした配列を挿入
  setItems(chunk(data, pageSize));
  setPage({ maxIndex: maxIndex, currentIndex: INIT_CURRENT_INDEX });
}

export interface DataFilterboxProps {
  name: string;
  className?: string;
  labelId?: string;
  multiple?: boolean;
  data: DataFilterboxItem[];
  value?: DataFilterboxItem[];
  searchOption?: DataFilterboxSearchOption;
  displayOption?: DataFilterboxDisplayOption;
  validateOption?: DataFilterboxValidateOption;
  disabled?: boolean;
  properties?: Property[];
  columns?: string[];
  onChangeState?: (arg: DataFilterboxItem[]) => void;
  workingBlur?: Date;
  formBuilderOption?: FormBuilderOptions;
}

/**
 * フォーム作成機能向けのオプション
 * メッセージIDを介さず項目名を直接表示する。
 * また、description に値をセットすることで項目の補足説明アイコンを表示する。
 */
interface FormBuilderOptions {
  label?: string;
  description?: string;
}

// リストの表示項目及び検索オプション項目
type DataFilterboxItemOption = keyof DataFilterboxItem | 'both';

// 検索結果欄の表示項目オプション
interface DataFilterboxDisplayOption {
  displayedItems?: DataFilterboxItemOption;
  pageSize?: number;
}

// 検索結果欄のアイテム構造
export interface DataFilterboxItem {
  value: string;
  displayName: string;
}

// 検索オプション
interface DataFilterboxSearchOption {
  targets?: DataFilterboxItemOption;
}

// バリデートオプション
export interface DataFilterboxValidateOption {
  isSkippedValidation?: boolean;
  required?: boolean;
  isSkippedRequireCheck?: boolean;
}

export function DataFilterbox(props: DataFilterboxProps) {
  const REQUIRED_MESSAGE = GetMessage({ id: 'E0000003' });

  const [componentStyle, viewStyle, _disabled] = useMemo<
    [CSSProperties, CSSProperties, boolean]
  >(() => {
    return getProperty(props.name, props.columns, props.properties);
  }, [props.name, props.columns, props.properties]);
  // propsのオプショナルチェック
  const filterboxClassName = props.className ?? '';
  const isMulti = props.multiple ?? false;
  const searchTarget = props.searchOption?.targets ?? 'displayName';
  const pageSize = props.displayOption?.pageSize ?? 5;
  const displayItem = props.displayOption?.displayedItems ?? 'displayName';
  const style = componentStyle ?? {};
  const disabled = _disabled ? _disabled : props.disabled ?? false;
  const isSkippedValidation =
    props.validateOption?.isSkippedValidation ?? false;
  const isSkippedRequireCheck =
    props.validateOption?.isSkippedRequireCheck ?? false;

  const changeParentState = props.onChangeState || (() => {});

  // useState
  const [items, setItems] = useState<DataFilterboxItem[][]>([]);
  const [keyword, setKeyword] = useState('');
  const [visibility, setVisibility] = useState<'hidden' | 'visible'>('hidden');
  const [message, setMessage] = useState<string[]>([]);
  const [page, setPage] = useState<Page>({
    maxIndex: 0,
    currentIndex: INIT_CURRENT_INDEX,
  });
  const [selected, setSelected] = useState<DataFilterboxItem[]>(
    getCorrectFilterboxItem(props.value)
  ); //初期選択アイテムがあればセット

  const keywordInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setSelected(getCorrectFilterboxItem(props.value));
  }, [props.value]);

  // クリック時の処理
  const handleOpenList = () => {
    if (disabled) {
      return;
    }
    // 検索結果欄表示
    setVisibility('visible');
    setTimeout(() => {
      keywordInputRef.current?.focus();
    }, 0);
  };

  const handleClearKeyword = useCallback(() => {
    setKeyword('');
    initItemsandPage(props.data, pageSize, setItems, setPage);
  }, [pageSize, props.data]);

  // フォーカスアウト時の処理
  const handleCloseList = useCallback(() => {
    // 検索結果欄非表示、検索キーワード初期化
    setVisibility('hidden');
    handleClearKeyword();

    // バリデートを行なってもエラーメッセージは抑制されているので問題ないが、念の為処理をスキップする
    if (!isSkippedValidation) {
      // 必須入力チェック
      const isRequired = props.validateOption?.required ?? false;
      if (!isSkippedRequireCheck && isRequired && selected.length === 0) {
        setMessage([REQUIRED_MESSAGE]);
        return;
      }
    }

    setMessage([]);
    // チェックが必要な場合だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    REQUIRED_MESSAGE,
    handleClearKeyword,
    isSkippedValidation,
    props.validateOption?.required,
    selected.length,
  ]);

  useEffect(() => {
    if (!props.workingBlur) {
      return;
    }
    handleCloseList();
  }, [handleCloseList, props.workingBlur]);

  useEffect(() => {
    // 必須制御が変わって任意になる場合にエラー表示をクリアする
    if (!props.validateOption?.required) {
      // 値が空の場合だけに対応範囲を限定する
      if (selected.length === 0) {
        setMessage([]);
      }
    }
    // 呼出元で必須条件を変更したというイレギュラー対応なので、props.validateOption?.requiredのみ依存関係にする
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.validateOption?.required]);

  useEffect(() => {
    // 必須チェック不要になる場合にエラー表示をクリアする
    if (isSkippedRequireCheck) {
      // 値が空の場合だけに対応範囲を限定する
      if (selected.length === 0) {
        setMessage([]);
      }
    }
    // 呼出元で必須条件を変更したというイレギュラー対応なのでisSkippedRequireCheckのみ依存関係にする
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSkippedRequireCheck]);

  // ページ移動
  const handleMovePage = (pageNumber: number) => {
    const n = Math.min(Math.max(INIT_CURRENT_INDEX, pageNumber), page.maxIndex);
    setPage({ currentIndex: n, maxIndex: page.maxIndex });
  };

  // アイテムをクリックした際の処理
  const handleItemClicked = (
    target: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    const targetValue = target.currentTarget.dataset.value ?? '';

    // 該当するアイテムを格納
    const item = items[page.currentIndex].find((v) => v.value === targetValue);

    // 該当するアイテムがない場合は早期リターン
    if (!item) {
      return;
    }

    // 単数選択の場合は選択項目を置き換えて処理終了
    if (!isMulti) {
      setSelected([item]);
      handleCloseList();
      handleClearKeyword(); // 検索ワードを消去
      changeParentState([item]);
      setMessage([]);
      return;
    }

    // valueは一意として重複がないかを確認
    if (!!selected.find((v) => v.value === targetValue)) {
      // 重複があった場合は処理終了
      return;
    }

    setSelected([...selected, item]);
    changeParentState([...selected, item]);
  };

  // 選択されたアイテムの削除処理
  const handleItemDeleted = (target: string) => {
    const deleted = selected.filter((v: DataFilterboxItem) => {
      return v.value !== target;
    });

    setSelected(deleted);
    changeParentState(deleted);
  };

  // 検索処理
  const handleSearched = () => {
    // スライスしたものではなく、props.dataから検索欄で入力された検索ワードで絞り込み
    const filtered: DataFilterboxItem[] = [];
    if (searchTarget === 'both') {
      filtered.push(
        ...props.data.filter((v) => {
          return v.displayName.includes(keyword) || v.value.includes(keyword);
        })
      );
    } else {
      filtered.push(
        ...props.data.filter((v) => v[searchTarget].includes(keyword))
      );
    }
    initItemsandPage(filtered, pageSize, setItems, setPage);
  };

  // props.dataが変更されたら表示用に変換してリストアイテムにセット
  useEffect(() => {
    initItemsandPage(props.data, pageSize, setItems, setPage);
  }, [pageSize, props.data]);

  // 検索結果の表示アイテム出し分け処理
  const listView = (item: DataFilterboxItem) => {
    switch (displayItem) {
      case 'value':
        return item.value;

      case 'displayName':
        return item.displayName;

      case 'both':
        return `${item.value} :${item.displayName}`;
    }
  };

  // キーボード入力制御
  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case 'Enter':
        // 検索実行
        handleSearched();
        break;

      case 'Escape':
      case 'Tab':
        // 検索結果欄（リスト）を閉じて、フォーカスを外す
        handleCloseList();
        break;
    }
  };

  // クリアボタンの表示・非表示
  const [clearButtonVisibility, setClearButtonVisibility] = useState<
    'hidden' | 'visible'
  >('hidden');
  useEffect(() => {
    //クリアボタンの表示・非表示切り替え
    if (keyword === '') {
      setClearButtonVisibility('hidden');
    } else {
      setClearButtonVisibility('visible');
    }
  }, [keyword]);

  // z-indexの基準値
  const baseZIndex = 10;

  // 検索結果表示エリアに表示するコンテンツ
  const searchItemArea = (items: DataFilterboxItem[][]) => {
    const contents = [...Array(pageSize)].map(
      (_, i): DataFilterboxItem =>
        i
          ? { value: '', displayName: '' }
          : { value: '', displayName: '検索結果なし' }
    );

    if (items.length) {
      items[page.currentIndex].forEach((v, i) => (contents[i] = v));
    }

    return searchItemAreaView(
      contents,
      handleItemClicked,
      listView,
      visibility
    );
  };

  return (
    <div
      className={`Filterbox DataFilterbox ${filterboxClassName}`}
      style={viewStyle}
    >
      {/* 検索欄外クリック判定用エリア */}
      {visibility === 'visible' && (
        <div
          className={visibility === 'visible' ? 'click-area' : ''}
          onClick={() => handleCloseList()}
        ></div>
      )}

      {/* 常時表示エリア */}
      <div
        className={'main-contents'}
        style={visibility === 'visible' ? { zIndex: baseZIndex + 1 } : {}}
      >
        {/* ラベル */}
        {props.labelId && (
          <div className={props.validateOption?.required ? 'required ' : ''}>
            <div
              className={`topping-label ${
                disabled ? 'disabled-topping-label' : ''
              }`}
            >
              <GetMessageComponent id={props.labelId} />
            </div>
          </div>
        )}
        {props.formBuilderOption?.label && (
          <div className={props.validateOption?.required ? 'required ' : ''}>
            <div
              className={`topping-label topping-label-with-help ${
                disabled ? 'disabled-topping-label' : ''
              }`}
            >
              {props.formBuilderOption?.label}
              {!!props.formBuilderOption?.description && (
                <IconButton
                  name="help"
                  className="HelpIcon"
                  iconType="help"
                  buttonType="basic"
                  onClick={() => {}}
                  caption={props.formBuilderOption?.description}
                />
              )}
            </div>
          </div>
        )}
        <div
          className={`selected-item-area ${
            disabled ? 'basic-disabled-input-border' : 'basic-input-border'
          } ${getErrorBorderClassName(message)} ${
            visibility === 'visible' ? 'focus' : ''
          } ${disabled ? 'disabled' : ''}`}
          onClick={handleOpenList}
        >
          {/* 表示リスト及びアイコン */}
          {/* 選択済みアイテム */}
          <div>
            {selected.map((v) => {
              return (
                <div key={v.value} className="selected-item">
                  <span className="selected-label">{v.displayName}</span>
                  {!props.disabled && (
                    <DeleteIcon onClick={() => handleItemDeleted(v.value)} />
                  )}
                </div>
              );
            })}
          </div>
          <button className="center filter-opener" disabled={disabled}>
            {visibility === 'visible' ? (
              <ExpandLessIcon
                onClick={(e) => {
                  e.stopPropagation();
                  handleCloseList();
                }}
              ></ExpandLessIcon>
            ) : (
              <ExpandMoreIcon onClick={handleOpenList}></ExpandMoreIcon>
            )}
          </button>
        </div>

        {/* クリック時 表示部分 */}
        <div
          className={`search-box ${visibility}`}
          style={{
            visibility: `${visibility}`,
            position: 'absolute',
            boxShadow: '0.2rem 0.2rem 0.5rem',
          }}
        >
          {/* 検索欄 */}
          <div className="search-area">
            <div className="input-area">
              <input
                name={props.name}
                className="keyword-input"
                onChange={(e) => {
                  setKeyword(e.target.value);
                }}
                onClick={handleOpenList}
                value={keyword}
                placeholder="絞り込み"
                style={style}
                disabled={disabled}
                onKeyDown={handleKeyPress}
                autoComplete="off"
                ref={keywordInputRef}
              ></input>

              <DeleteIcon
                onClick={handleClearKeyword}
                style={{
                  visibility: `${clearButtonVisibility}`,
                }}
              />
            </div>
            <SearchIcon className="center" onClick={handleSearched} />
          </div>

          {/* 検索結果欄 */}
          {/* 検索条件で絞り込んだ上で結果表示 */}
          <div
            className="search-result-area"
            style={{ gridTemplateRows: `repeat(${pageSize}, 1fr)` }}
          >
            {searchItemArea(items)}
          </div>

          {/* ページ送りボタン */}
          <div className="search-page-area">
            <KeyboardArrowLeftIcon
              className={`center ${
                page.currentIndex === INIT_CURRENT_INDEX
                  ? 'disabled-svg-icon'
                  : ''
              }`}
              onClick={() => handleMovePage(page.currentIndex - 1)}
            />

            <KeyboardArrowRightIcon
              className={`center end-column ${
                page.currentIndex === page.maxIndex ? 'disabled-svg-icon' : ''
              }`}
              onClick={() => handleMovePage(page.currentIndex + 1)}
            />
          </div>
        </div>
      </div>
      {!props.disabled && !isSkippedValidation ? (
        <ErrorMessage message={message}></ErrorMessage>
      ) : (
        <ErrorMessage message={[]}></ErrorMessage>
      )}
    </div>
  );
}
