import dayjs from 'dayjs';
import { useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { FullMethodName_ListAnalysisOrders } from '~/worker';
import {
  error,
  GetMessageWithIntl,
  MessageProps,
  Radio,
  success,
} from '~/shared/components';
import {
  DataFilterbox,
  DataFilterboxItem,
  DateSuggest,
  OrderOutputDialog,
  OrderOutputDialogOutput,
} from '~/shared/components/ui';
import { CaptionButton } from '~/shared/components/ui/Button';
import { DataView } from '~/shared/components/ui/ListView/DataView';
import { mtechnavi } from '~/shared/libs/clientsdk';
import {
  autoDownloadFileWithDate,
  getWorkerExceptionMessage,
  includeInputValidateError,
  toCommaTypeString,
  ViewId,
} from '~/shared/utils';
import { useLoading } from '~/shared/contexts/LoadingProvider';

const dataTypeToBasisDateMap: { [key: string]: string[] } = {
  AnalysisOrderPlacement: [
    'order_date',
    'delivery_date',
    'delivery_plan_date',
    'inspection_date',
    'payment_plan_date',
  ],
  AnalysisOrderReceipt: [
    'order_date',
    'delivery_date',
    'delivery_plan_date',
    'inspection_date',
    'payment_plan_date',
  ],
  AnalysisInspection: ['delivery_date', 'inspection_date', 'payment_plan_date'],
  AnalysisSalesPlan: ['delivery_date', 'inspection_date', 'payment_plan_date'],
};

interface CompanyConfirmationDetailAnalysisProps {
  viewId: ViewId;
  companyId: string;
  analysisSettingItems: mtechnavi.api.analysis.IAnalysisSettingItem[];
}
export const CompanyConfirmationDetailAnalysis = ({
  viewId,
  companyId,
  analysisSettingItems,
}: CompanyConfirmationDetailAnalysisProps) => {
  const intl = useIntl();
  const { showLoading, hideLoading } = useLoading();

  // 初期値セット用
  const now = dayjs();
  const initialDateFrom = now.subtract(11, 'month').startOf('month');
  const initialDateTo = now.endOf('month');

  const [dataType, setDataType] = useState<DataFilterboxItem | null>(null);
  const [baseDateType, setBaseDateType] = useState<DataFilterboxItem | null>(
    null
  );
  const [analysisSettingItem, setAnalysisSettingItem] =
    useState<DataFilterboxItem | null>(null);
  const [startDate, setStartDate] = useState<Date | null>(
    initialDateFrom.toDate()
  );
  const [endDate, setEndDate] = useState<Date | null>(initialDateTo.toDate());
  const [aggregationUnit, setAggregationUnit] = useState<string>('month');

  const [listData, setListData] = useState<
    mtechnavi.api.analysis.IOrderAggregatedItem[]
  >([]);

  // ヘッダ
  const [baseDateLabel, setBaseDateLabel] = useState<MessageProps>({});

  const [isOpenOrderOutput, setOpenOrderOutput] = useState(false);
  const requestData =
    useRef<mtechnavi.api.analysis.IListAnalysisOrdersRequest | null>(null);

  const noopRef = useRef(null);
  const [workingBlur, setWorkingBlur] = useState<Date>();

  /** データ種別選択肢 */
  const dataTypeItems: DataFilterboxItem[] = [
    {
      value: 'AnalysisOrderPlacement',
      displayName: GetMessageWithIntl(intl, {
        viewId,
        id: 'detailAnalysis.analysisOrderPlacement',
      }),
    },
    {
      value: 'AnalysisOrderReceipt',
      displayName: GetMessageWithIntl(intl, {
        viewId,
        id: 'detailAnalysis.analysisOrderReceipt',
      }),
    },
    {
      value: 'AnalysisInspection',
      displayName: GetMessageWithIntl(intl, {
        viewId,
        id: 'detailAnalysis.analysisInspection',
      }),
    },
    {
      value: 'AnalysisSalesPlan',
      displayName: GetMessageWithIntl(intl, {
        viewId,
        id: 'detailAnalysis.analysisSalesPlan',
      }),
    },
  ];

  /** 基準日選択肢 */
  const baseDateTypeItems = useMemo(() => {
    const items: DataFilterboxItem[] = [
      {
        value: 'order_date',
        displayName: GetMessageWithIntl(intl, {
          viewId,
          id:
            dataType?.value === 'AnalysisOrderReceipt'
              ? 'detailAnalysis.orderReceiptDate'
              : 'detailAnalysis.orderPlacementDate',
        }),
      },
      {
        value: 'delivery_date',
        displayName: GetMessageWithIntl(intl, {
          viewId,
          id: 'detailAnalysis.deliveryDate',
        }),
      },
      {
        value: 'delivery_plan_date',
        displayName: GetMessageWithIntl(intl, {
          viewId,
          id: 'detailAnalysis.deliveryPlanDate',
        }),
      },
      {
        value: 'inspection_date',
        displayName: GetMessageWithIntl(intl, {
          viewId,
          id: 'detailAnalysis.inspectionDate',
        }),
      },
      {
        value: 'payment_plan_date',
        displayName: GetMessageWithIntl(intl, {
          viewId,
          id:
            dataType?.value === 'AnalysisOrderReceipt' ||
            dataType?.value === 'AnalysisSalesPlan'
              ? 'detailAnalysis.paymentPlanDateReceipt'
              : 'detailAnalysis.paymentPlanDatePlacement',
        }),
      },
    ];
    const dataTypeCode = dataType?.value ?? '';
    if (!Object.hasOwn(dataTypeToBasisDateMap, dataTypeCode)) {
      return [];
    }
    return items.filter((item) =>
      dataTypeToBasisDateMap[dataTypeCode].includes(item.value)
    );
  }, [intl, viewId, dataType]);

  /** 分析項目選択肢 */
  const analysisSettingItemList = analysisSettingItems
    .filter((item) => item.dataType === dataType?.value)
    .map(
      (item): DataFilterboxItem => ({
        value: item.id ?? '',
        displayName: item.displayName ?? '',
      })
    );

  /** 表示用リストデータ */
  const orderViewData = useMemo(
    () =>
      listData.map((item) => {
        const format = aggregationUnit === 'month' ? 'YYYY/MM' : 'YYYY/MM/DD';
        return {
          id: `${item.baseDate}:${item.analysisItem}`,
          baseDate: item.baseDate ? dayjs(item.baseDate).format(format) : '',
          analysisItem: item.analysisItem,
          amountNum: toCommaTypeString(item.amountNum),
        };
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [listData]
  );

  /** データ種別選択変更時 */
  const handleChangeDataType = (selection: DataFilterboxItem[]) => {
    setDataType(selection.at(0) ?? null);
    setBaseDateType(null);
    setAnalysisSettingItem(null);
  };

  /** 入力チェック */
  const isInputError = (): boolean => {
    setWorkingBlur(new Date());
    const inputValidationCheckList = [
      { value: dataType?.value ?? '', ref: noopRef },
      { value: baseDateType?.value ?? '', ref: noopRef },
      { value: analysisSettingItem?.value ?? '', ref: noopRef },
      { value: startDate?.toISOString() ?? '', ref: noopRef },
      { value: endDate?.toISOString() ?? '', ref: noopRef },
    ];
    return includeInputValidateError(document, intl, inputValidationCheckList);
  };

  /** 表更新ボタンクリック時 */
  const handleUpdateList = async () => {
    if (isInputError()) {
      return;
    }
    if (startDate && endDate && startDate > endDate) {
      error([GetMessageWithIntl(intl, { id: 'E0000168' })]);
      return;
    }
    showLoading();
    try {
      const requestBody: mtechnavi.api.analysis.IListAnalysisOrdersRequest = {
        dataFilter: {
          companyId,
          dataType: dataType?.value,
          baseDateType: baseDateType?.value,
          startDate: startDate ? dayjs(startDate).format('YYYY/MM/DD') : '',
          endDate: endDate ? dayjs(endDate).format('YYYY/MM/DD') : '',
          analysisSettingItemId: analysisSettingItem?.value,
          aggregationUnit,
        },
      };
      const results =
        await window.App.services.ui.worker.filter<mtechnavi.api.analysis.IOrderAggregatedItem>(
          {
            action: 'reload',
            fullMethodName: FullMethodName_ListAnalysisOrders,
            requestBody,
            filter: {},
            sort: [],
          }
        );
      const list = results.items ?? [];
      // // 第１並び替え：日付（昇順）
      list.sort((a, b) =>
        (a.baseDate || '').localeCompare(b.baseDate || '', 'ja')
      );
      // 第２並び替え：分析項目（昇順）
      list.sort((a, b) => {
        if (a.baseDate === b.baseDate) {
          return (a.analysisItem || '').localeCompare(
            b.analysisItem || '',
            'ja'
          );
        }
        return 1;
      });
      setListData(list);
      // 出力用に条件を保持
      requestData.current = requestBody;

      // リスト日付列ラベルの組み立て
      const labelParts = [aggregationUnit, baseDateType?.value];
      if (
        ['order_date', 'payment_plan_date'].includes(baseDateType?.value ?? '')
      ) {
        labelParts.push(dataType?.value ?? '');
      }
      const prefixId = labelParts.join('.');
      setBaseDateLabel({ prefixId, viewId, id: 'baseDate' });
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      hideLoading();
    }
  };

  /** CSV 出力 */
  const handleExport = () => {
    if (!requestData?.current) {
      return;
    }
    setOpenOrderOutput(true);
  };
  const handleOrderOutput = async (outputOption: OrderOutputDialogOutput) => {
    showLoading();
    try {
      const req: mtechnavi.api.analysis.IExportAnalysisOrdersRequest = {
        companyId: requestData?.current?.dataFilter?.companyId,
        baseDateType: requestData?.current?.dataFilter?.baseDateType,
        dataType: requestData?.current?.dataFilter?.dataType,
        startDate: requestData?.current?.dataFilter?.startDate,
        endDate: requestData?.current?.dataFilter?.endDate,
      };
      if (outputOption.outputVolume === 'aggregate') {
        req.analysisSettingItemId =
          requestData?.current?.dataFilter?.analysisSettingItemId;
        req.aggregationUnit = requestData?.current?.dataFilter?.aggregationUnit;
      }
      const res =
        await window.App.services.analysisService.exportAnalysisOrders(req);
      if (res) {
        success([GetMessageWithIntl(intl, { id: 'I0000001' })]);
        let fileNameId = 'detailAnalysis.analysisSummary';
        if (outputOption.outputVolume === 'detail') {
          if (
            ['AnalysisOrderPlacement', 'AnalysisInspection'].includes(
              requestData?.current?.dataFilter?.dataType || ''
            )
          ) {
            fileNameId = 'detailAnalysis.analysisOrderPlacement';
          } else {
            fileNameId = 'detailAnalysis.analysisOrderReceipt';
          }
        }
        autoDownloadFileWithDate(
          GetMessageWithIntl(intl, {
            viewId,
            id: fileNameId,
          }),
          'csv',
          res?.assetId ?? ''
        );
      }
      setOpenOrderOutput(false);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      hideLoading();
    }
  };

  return (
    <>
      <div className="analysis-form-area">
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-20">
              {/* データ種別 */}
              <DataFilterbox
                name="dataType"
                labelId={`${viewId}.detailAnalysis.dataType`}
                columns={['dataType']}
                multiple={false}
                data={dataTypeItems}
                validateOption={{ required: true }}
                onChangeState={handleChangeDataType}
                workingBlur={workingBlur}
              />
            </div>
            <div className="w-20">
              {/* 基準日 */}
              <DataFilterbox
                name="baseDateType"
                labelId={`${viewId}.detailAnalysis.baseDateType`}
                columns={['baseDateType']}
                multiple={false}
                data={baseDateTypeItems}
                validateOption={{ required: true }}
                value={baseDateType ? [baseDateType] : []}
                onChangeState={(v) => setBaseDateType(v.at(0) ?? null)}
                workingBlur={workingBlur}
              />
            </div>
            <div className="w-20">
              {/* 分析項目 */}
              <DataFilterbox
                name="analysisSettingItemId"
                labelId={`${viewId}.detailAnalysis.analysisSettingItemId`}
                columns={['analysisSettingItemId']}
                multiple={false}
                data={analysisSettingItemList}
                validateOption={{ required: true }}
                value={analysisSettingItem ? [analysisSettingItem] : []}
                onChangeState={(v) => setAnalysisSettingItem(v.at(0) ?? null)}
                workingBlur={workingBlur}
              />
            </div>
          </div>
        </div>

        <div className="input-line mixed-input-line">
          <div className="item-group-100">
            <div className="w-40">
              <div className="date-range">
                {/* 期間(開始) */}
                <DateSuggest
                  labelId={`${viewId}.detailAnalysis.setStartDate`}
                  name="setStartDate"
                  columns={['setStartDate']}
                  value={startDate}
                  onChangeState={setStartDate}
                  validateOption={{ required: true }}
                />
                <span className="aid">〜</span>
                {/* 期間(終了) */}
                <DateSuggest
                  labelId={`${viewId}.detailAnalysis.setEndDate`}
                  name="setEndDate"
                  columns={['setEndDate']}
                  value={endDate}
                  onChangeState={setEndDate}
                  validateOption={{ required: true }}
                />
              </div>
            </div>
            <div className="w-30">
              <Radio
                name="aggregationUnit"
                className="aggregationUnit"
                value={aggregationUnit ?? undefined}
                items={[
                  {
                    value: 'month',
                    displayName: GetMessageWithIntl(intl, {
                      viewId,
                      id: 'detailAnalysis.aggregationUnit.month',
                    }),
                  },
                  {
                    value: 'day',
                    displayName: GetMessageWithIntl(intl, {
                      viewId,
                      id: 'detailAnalysis.aggregationUnit.day',
                    }),
                  },
                ]}
                validateOption={{ required: true }}
                onChangeState={setAggregationUnit}
              />
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-20">
              {/* 表更新 */}
              <CaptionButton
                name="refresh"
                buttonType="basic"
                caption={GetMessageWithIntl(intl, {
                  viewId,
                  id: 'detailAnalysis.updateList',
                })}
                onClick={handleUpdateList}
              />
            </div>
          </div>
        </div>
      </div>
      {/* 一覧 */}
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-60">
            <DataView
              pageInfo={{
                header: [
                  { message: baseDateLabel, width: '30%' },
                  { message: { viewId, id: 'analysisItem' }, width: 'auto' },
                  {
                    message: { viewId, id: 'amountNum' },
                    width: '30%',
                    margin: '0 0 0 auto',
                  },
                ],
                data: orderViewData,
                menuItem: [
                  {
                    menuActionType: 'headerIconMenu',
                    menu: [
                      {
                        name: 'export',
                        func: handleExport,
                        disabledCondFn: () => !requestData?.current,
                      },
                    ],
                    maxMenuColumn: 1,
                  },
                  {
                    menuActionType: 'listIconMenu',
                    menu: [],
                    maxMenuColumn: 1,
                  },
                ],
                menuTarget: 'id',
              }}
              listSkipValue={{
                isFooterButton: false,
                isVisibleCheckbox: false,
              }}
            />
          </div>
        </div>
      </div>
      <OrderOutputDialog
        isOpen={isOpenOrderOutput}
        messageOption={{
          headerLabelId: { id: 'orderOutput', prefixId: 'DIALOG_TITLE' },
          messageLabelId: {
            id: 'description',
            prefixId: 'OrderOutputDialog',
          },
        }}
        onDecision={handleOrderOutput}
        onCancel={() => setOpenOrderOutput(false)}
      />
    </>
  );
};
