import { mtechnavi, sharelib } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface ExecBusinessUnitContact {
  actionName: 'execBusinessUnitContact';
  request: BusinessUnitContactParam;
}

interface BusinessUnitContactParam {
  tenantStatus?: sharelib.INameOption;
  activeStatus?: sharelib.INameOption;
  businessUnitContact: mtechnavi.api.company.IBusinessUnitContact;
  businessUnitContactHeaderId: string;
}

export const execBusinessUnitContact = async (
  worker: AppSaveWorker,
  req: BusinessUnitContactParam
) => {
  const createMethodName =
    'mtechnavi.api.company.BusinessUnitManagementService/CreateBusinessUnitContact';
  const updateMethodName =
    'mtechnavi.api.company.BusinessUnitManagementService/UpdateBusinessUnitContact';

  let result: unknown[] = [];
  if (req.businessUnitContact.businessUnitContactId) {
    result = await worker.invokeListRequest(updateMethodName, {
      businessUnitContact: req.businessUnitContact,
    });
  } else {
    result = await worker.invokeListRequest(createMethodName, {
      businessUnitContact: req.businessUnitContact,
      businessUnitContactHeaderId: req.businessUnitContactHeaderId,
    });
  }

  if (result && result.length > 0 && req.tenantStatus?.systemName === 'B03') {
    // shareToコール
    const sharetoFullMethodName =
      'mtechnavi.api.company.BusinessUnitManagementService/SharetoBusinessUnitContact';
    await worker.invokeListRequest(sharetoFullMethodName, {
      businessUnitContact: result[0],
    });
  }
  return result;
};
