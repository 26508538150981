import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface ExecUncompleteWorkTaskRequest {
  actionName: 'uncompleteWorkTask';
  request: mtechnavi.api.worktask.IUncompleteWorkTaskRequest;
}

// 案件再開
export const execUncompleteWorkTask = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.worktask.IUncompleteWorkTaskRequest
) => {
  const fullMethodName =
    'mtechnavi.api.worktask.WorkTaskService/UncompleteWorkTask';

  return await worker.invokeListRequest<
    mtechnavi.api.worktask.IUncompleteWorkTaskRequest,
    mtechnavi.api.worktask.IUncompleteWorkTaskResponse
  >(fullMethodName, req);
};
