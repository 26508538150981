import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Container,
  GetMessageWithIntl,
  LoadingIcon,
  Toast,
  error,
  success,
  MessageProps,
} from '~/shared/components';
import {
  AutoReminderSettingDialog,
  AutoReminderSettingDialogInputOption,
  AutoReminderSettingDialogOutputOption,
  CommentDialog,
  ConfirmationDialog,
  DeadlineChangeDialog,
  DeadlineChangeDialogOutputOption,
  DeadlineChangeResult,
  ListView,
  ViewMenu,
  IconMenuName,
  InitialFilter,
  InitialFilterItem,
  ManualReminderDialog,
  ManualReminderDialogOutputOption,
  MenuActionItem,
  OutputDialog,
  OutputDialogOutput,
  OutputVolumeItems,
  Preset,
  Property,
  getAltDisplaySchema,
  getBooleanDataFormetterSchema,
  getDayFormetterDisplaySchema,
  getJoinedColumnSchema,
} from '~/shared/components/ui';

import {
  FlagSettingDialog,
  FlagSettingInputOption,
} from '~/shared/components/ui/Dialog/FlagSettingDialog';
import { useAuth } from '~/shared/contexts/AuthProvider';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { PresetItem } from '~/shared/services';
import {
  FullMethodName_ExpandedListSurveyRequestContents,
  FullMethodName_ListSurveyRequestContents,
  PageState,
  ViewId,
  convertDate,
  convertDatetime,
  getExceptionMessage,
  getWorkerExceptionMessage,
  getPresetAndSchema,
  saveLocalStorageCheckboxData,
  autoDownloadFileWithDate,
  getFilterAndSortData,
} from '~/shared/utils';
import { AggregateStage } from '~/worker';
import {
  getLatestSurveyReplyDueDateChangeRequest,
  getLatestSurveyReplyDueDateChangeResult,
} from '../utils';
import { BqListDelayTime } from '~/shared/config';

type SurveyRequestContent = mtechnavi.api.survey.ISurveyRequestContent;

const aggregateStages: AggregateStage[] = [
  {
    $union: {
      from: FullMethodName_ExpandedListSurveyRequestContents,
      fields: [
        {
          // 一意に識別できるよう、予定データに仮の依頼明細ID列を追加
          name: [
            'surveyRequest.surveyBaseRequestId',
            'surveyRequest.requestUnit.businessUnitManagementId',
          ],
          as: 'surveyRequest.surveyRequestId',
        },
        'surveyRequest',
        'surveyResult',
        'complete',
        'discard',
        'surveyResultChangeRequest',
        'surveyReplyDueDateChangeResult',
        'status',
        'replyDueDateDt',
        'surveyRequestNotificationAutoName',
      ],
    },
  },
];

export const SurveyRequestSenderDetailList = () => {
  const { pathname } = useLocation();
  const VIEW_ID: ViewId = useMemo(() => {
    switch (pathname) {
      case '/supplier-base/survey-request-sender-detail-list':
        return 'SURVEY_REQUEST_SENDER_DETAIL_LIST';
      case '/supplier-base/survey-request-sender-approval-detail-list':
        return 'SURVEY_REQUEST_SENDER_APPROVAL_DETAIL_LIST';
      default:
        return '';
    }
  }, [pathname]);
  const backPath = useMemo(() => {
    switch (pathname) {
      case '/supplier-base/survey-request-sender-detail-list':
        return '/supplier-base/survey-request-list';
      case '/supplier-base/survey-request-sender-approval-detail-list':
        return '/supplier-base/survey-request-approval-list';
      default:
        return '';
    }
  }, [pathname]);
  const listItemRef = useRef<SurveyRequestContent[]>([]);
  const listAllItemRef = useRef<SurveyRequestContent[]>([]);
  const [isReload, setReload] = useState(false);

  const [isLoading, setLoading] = useState(false);
  // 自動催促設定ダイアログ
  const [isShowAutoReminder, setShowAutoReminder] = useState(false);
  const [autoReminderOption, setAutoReminderOption] =
    useState<AutoReminderSettingDialogInputOption>({
      deadline: new Date(),
      displayModeType: 'save',
    });
  // 手動催促ダイアログ
  const [isShowManualReminder, setShowManualReminder] = useState(false);
  // 確認ダイアログ(削除)
  const [isShowDelete, setShowDelete] = useState(false);
  // 破棄ダイアログ
  const [isShowDiscard, setShowDiscard] = useState(false);
  const [discardComment, setDiscardComment] = useState('');
  // 出力ダイアログ
  const [isShowOutput, setShowOutput] = useState(false);
  // 回答期日変更ダイアログ
  const [isShowDeadlineChange, setShowDeadlineChange] = useState(false);
  const [deadlineChangeOption, setDeadlineChangeOption] =
    useState<DeadlineChangeDialogOutputOption>({ currentDeadline: null });
  // フラグダイアログ
  const [isShowFlagSetting, setShowFlagSetting] = useState(false);
  const [flagSettingOption, setFlagSettingOption] =
    useState<FlagSettingInputOption>({
      flagProperties: {},
    });

  // 操作対象ID
  const [targetId, setTargetId] = useState<string | null>(null);
  // 操作対象IDs
  const selectedTargetIds = useRef<string[]>([]);
  // 手動催促ダイアログの送信ボタン活性・非活性対応            setLoading(true);
  const [isClickable, setClickable] = useState(true);

  // 確認ダイアログ
  const [isOpenConfirmDialog, setOpenConfirmDialog] = useState(false);
  // 確認ダイアログメッセージ
  const [confirmMessage, setConfirmMessage] = useState<MessageProps>({});
  const confirmPromiseRef =
    useRef<(value: boolean | PromiseLike<boolean>) => void>();

  const intl = useIntl();
  const navigate = useNavigate();
  const myEmail = useAuth().user?.email ?? '';
  // 遷移元から値を受け取る
  const location = (useLocation().state as PageState) ?? []; // eslint-disable-line

  const [schema, setSchema] = useState<Property[]>([]);
  const [preset, setPreset] = useState<Preset>({
    filter: {},
    propertyNames: [],
  });
  const [presetItems, setPresetItems] = useState<PresetItem[]>();
  const [presetViewId, setPresetViewId] = useState<ViewId>();

  const successMessage = GetMessageWithIntl(intl, { id: 'I0000001' });
  const invalidStatusMessage = GetMessageWithIntl(intl, { id: 'E0000020' });
  const unselectedMessage = GetMessageWithIntl(intl, { id: 'E0000023' });
  const excessTargetMessage = GetMessageWithIntl(intl, { id: 'E0000073' });
  const isNotResultMessage = GetMessageWithIntl(intl, { id: 'E0000074' });
  const invalidOutputStatusMessage = GetMessageWithIntl(intl, {
    id: 'E0000146',
  });

  const commentDeleteMessage = {
    id: 'commentDelete',
    prefixId: VIEW_ID,
  };
  const sendMessage = {
    id: 'C0000001',
    value: {
      $1: GetMessageWithIntl(intl, { id: 'send' }),
    },
  };
  const sendWithDeleteFlagMessage = {
    prefixId: VIEW_ID,
    id: 'sendWithDeleteFlag',
  };
  const completeMessage = {
    id: 'C0000001',
    value: {
      $1: GetMessageWithIntl(intl, {
        prefixId: VIEW_ID,
        id: 'complete',
      }),
    },
  };
  const completeWithDeleteFlagMessage = {
    prefixId: VIEW_ID,
    id: 'completeWithDeleteFlag',
  };

  const naviFilterItems = useMemo(() => {
    const sourcePageInfo = location as PageState;
    if (!sourcePageInfo?.naviFilters) {
      return;
    }
    // 前回のフィルタ条件が残っていたらそちらを優先する
    const pageState = getFilterAndSortData(VIEW_ID, myEmail);
    if (pageState?.filter) {
      return;
    }
    const naviFilter = sourcePageInfo.naviFilters.reduce(
      (filter, param) => {
        const initialFilterItems: InitialFilterItem[] = param.value.map((v) => {
          return {
            targetKey: param.key,
            targetValue: v,
          };
        });
        filter.info.push(...initialFilterItems);
        return filter;
      },
      { type: 'or', info: [] } as InitialFilter
    );
    if (naviFilter.info.length === 0) {
      return;
    }
    return naviFilter;
    // useMemoを使用しているためwarningが発生するが期待通りのためlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const setMenuActionItem = (): MenuActionItem[] => {
    const menuActionItems: MenuActionItem[] = [];
    menuActionItems.push({
      menuActionType: 'headerIconMenu',
      menu: headerIconEvent(),
      maxMenuColumn: 5,
    });
    menuActionItems.push({
      menuActionType: 'listIconMenu',
      menu: listIconEvent(),
    });
    menuActionItems.push({
      menuActionType: 'footerMenu',
      menu: footerMenuEvent(),
    });
    return menuActionItems;
  };

  const headerIconEvent = (): ViewMenu[] => {
    const menuItems: ViewMenu[] = [];
    // 戻る
    menuItems.push({
      name: 'back',
      func: () => {
        navigate(backPath);
      },
    });
    // 作成
    menuItems.push({
      name: 'noteadd',
      func: () => {
        const state: PageState = {
          sourceViewId: VIEW_ID,
          actionType: 'add',
          baseViewOption: { sourceViewId: VIEW_ID },
        };
        navigate('/supplier-base/survey-request-sender-input', { state });
      },
    });
    // 確認
    menuItems.push({
      name: 'description',
      func: (v?: string[]) => {
        if (!v) {
          return;
        }
        const state: PageState = {
          ids: v ?? [],
          sourceViewId: VIEW_ID,
          naviFilters: location?.naviFilters,
          baseViewOption: { sourceViewId: VIEW_ID },
        };
        navigate('/supplier-base/survey-request-sender-confirmation', {
          state,
        });
      },
    });
    // ダウンロード
    menuItems.push({
      name: 'export',
      func: (v?: string[]) => {
        selectedTargetIds.current = v ?? [];
        setShowOutput(true);
      },
    });
    // 破棄
    menuItems.push({
      name: 'delete',
      func: (v?: string[]) => {
        if (!v) {
          return;
        }
        if (v.length !== 1) {
          error([excessTargetMessage]);
          return;
        }

        const target = getRecordData(v[0]);
        if (!target) {
          error([unselectedMessage]);
          return;
        }

        // 一時保存・公開却下は専用のエラーメッセージを表示
        if (['1', '3'].some((code) => target.status?.code === code)) {
          error([isNotResultMessage]);
          return;
        }
        // 公開中、部分回答、未開封なら破棄
        if (['5', '6', '11'].some((code) => target.status?.code === code)) {
          setTargetId(v[0]);
          setDiscardComment('');
          setShowDiscard(true);
          return;
        }
        // それ以外はエラー
        error([invalidStatusMessage]);
      },
    });
    return menuItems;
  };

  const listIconEvent = (): ViewMenu[] => {
    const menuItems: ViewMenu[] = [];
    // 確認
    menuItems.push({
      name: 'description',
      func: (v?: string[]) => {
        if (!v) {
          return;
        }
        const state: PageState = {
          ids: v ?? [],
          sourceViewId: VIEW_ID,
          naviFilters: location?.naviFilters,
          baseViewOption: { sourceViewId: VIEW_ID },
        };
        navigate('/supplier-base/survey-request-sender-confirmation', {
          state,
        });
      },
    });
    // 確認フラグ
    menuItems.push({
      name: 'flag_off',
      func: (v?: string[]) => {
        if (!v) {
          return;
        }
        const target = getRecordData(v[0]);
        if (!target) {
          return;
        }

        // ステータスチェック
        if (
          ['B00', 'B01', 'B02', 'B03'].some(
            (systemName) => target.status?.systemName === systemName
          )
        ) {
          error([invalidStatusMessage]);
          return;
        }

        setTargetId(v[0]);
        setFlagSettingOption({
          flagProperties: target.surveyRequest?.flagProperties ?? {},
        });
        setShowFlagSetting(true);
      },
      viewMenuOption: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        iconChangeFn: (v: any): IconMenuName => {
          if (v['surveyRequest.flagProperties.active']) {
            return 'flag_on';
          }
          return 'flag_off';
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        captionChangeFn: (v: any): string => {
          const val = v['surveyRequest.flagProperties.comment'];
          if (val) {
            return val.toString();
          }
          return '';
        },
      },
    });
    return menuItems;
  };

  const footerMenuEvent = (): ViewMenu[] => {
    const menuItems: ViewMenu[] = [];
    // 自動催促設定
    menuItems.push({
      name: 'auto_reminder',
      func: (v?: string[]) => {
        if (!v) {
          return;
        }
        if (v.length !== 1) {
          error([excessTargetMessage]);
          return;
        }
        const target = getRecordData(v[0]);
        if (!target) {
          return;
        }
        // 公開中、部分回答、未開封
        if (!['5', '6', '11'].some((code) => target.status?.code === code)) {
          error([invalidStatusMessage]);
          return;
        }

        const autoReminderSetting = (target.surveyRequest
          ?.autoReminderSettings || [undefined])[0];

        setAutoReminderOption({
          displayModeType: 'save',
          deadline: convertDate(target.replyDueDateDt || null) || new Date(),
          autoReminderSetting: autoReminderSetting,
        });
        setTargetId(v[0]);
        setShowAutoReminder(true);
      },
    });
    // 手動催促
    menuItems.push({
      name: 'manual_reminder',
      func: (v?: string[]) => {
        if (!v) {
          return;
        }
        if (v.length !== 1) {
          error([excessTargetMessage]);
          return;
        }
        const target = getRecordData(v[0]);
        if (!target) {
          return;
        }
        // 公開中、部分回答、未開封
        if (!['5', '6', '11'].some((code) => target.status?.code === code)) {
          error([invalidStatusMessage]);
          return;
        }
        setTargetId(v[0]);
        setShowManualReminder(true);
      },
    });
    // 依頼終了
    menuItems.push({
      name: 'complete',
      func: (v?: string[]) => {
        if (!v) {
          return;
        }

        const targets: mtechnavi.api.survey.ISurveyRequestContent[] = [];
        v.map((id) => {
          const target = getRecordData(id);
          if (!target) {
            return;
          }
          targets.push(target);
        });
        // 回答待ち、部分回答、回答受領、回答辞退、未開封、以外の場合エラー
        if (
          targets.some(
            (content) =>
              !['5', '6', '7', '8', '11'].includes(content.status?.code ?? '')
          )
        ) {
          error([invalidStatusMessage]);
          return;
        }

        selectedTargetIds.current = v;
        handleComplete();
      },
    });
    // 回答期日変更
    menuItems.push({
      name: 'deadline_change',
      func: (v?: string[]) => {
        if (!v) {
          return;
        }
        if (v.length !== 1) {
          error([excessTargetMessage]);
          return;
        }
        const target = getRecordData(v[0]);
        if (!target) {
          return;
        }
        // 公開中、部分回答、未開封
        if (!['5', '6', '11'].some((code) => target.status?.code === code)) {
          error([invalidStatusMessage]);
          return;
        }
        setTargetId(v[0]);
        setDeadlineChangeOption({
          currentDeadline: convertDate(target.replyDueDateDt || null),
        });
        setShowDeadlineChange(true);
      },
    });
    return menuItems;
  };

  /* eslint @typescript-eslint/no-explicit-any: 0 */
  const getRecordData = (
    id: string | null
  ): SurveyRequestContent | undefined => {
    return listItemRef?.current.find(
      (item) =>
        // 予定データと実績データの構造の違いを考慮
        item.surveyRequest?.surveyRequestId === id ||
        (item as any)['surveyRequest.surveyRequestId'] === id
    );
  };

  const clearCheckBox = useCallback(() => {
    saveLocalStorageCheckboxData(VIEW_ID, [], myEmail);
  }, [VIEW_ID, myEmail]);

  const handleDownload = async (result: OutputDialogOutput) => {
    const existBaseStatus: string[] = ['B00', 'B01', 'B02', 'B03'];

    if (result.outputVolume === OutputVolumeItems[0]) {
      if (selectedTargetIds.current.length === 0) {
        error([unselectedMessage]);
        setShowOutput(false);
        return;
      }

      let err = false;
      selectedTargetIds.current.forEach((id) => {
        const target = getRecordData(id);
        if (!target) {
          err = true;
          return;
        }

        if (existBaseStatus.includes(target.status?.systemName ?? '')) {
          err = true;
          return;
        }
      });

      if (err) {
        error([invalidOutputStatusMessage]);
        return;
      }
    } else {
      if (
        listAllItemRef.current.some((v) =>
          existBaseStatus.includes((v as any)['status.systemName'] ?? '')
        )
      ) {
        error([invalidOutputStatusMessage]);
        return;
      }
    }

    setLoading(true);

    // 依頼管理情報出力
    await exportSurveyRequests(result);

    setShowOutput(false);
  };

  const exportSurveyRequests = async (result: OutputDialogOutput) => {
    // 出力対象取得
    const selectedSurveyRequestIds: string[] = [];

    if (result.outputVolume === OutputVolumeItems[0]) {
      listItemRef.current?.map((v) => {
        if (
          selectedTargetIds.current.includes(
            v.surveyRequest?.surveyRequestId ?? ''
          )
        ) {
          selectedSurveyRequestIds.push(v.surveyRequest?.surveyRequestId ?? '');
        }
      });
    } else {
      // 全ページの場合
      listAllItemRef.current?.map((v) => {
        selectedSurveyRequestIds.push(
          (v as any)['surveyRequest.surveyRequestId'] ?? ''
        );
      });
    }

    try {
      const fileFormat = await window.App.services.ui.getFileFormat(
        'surveyRequest',
        intl
      );
      const res =
        await window.App.services.surveySenderService.exportSurveyRequests({
          surveyBaseRequestIds: [],
          surveyRequestIds: selectedSurveyRequestIds,
          fileFormat: fileFormat,
        });
      if (res) {
        success([successMessage]);
        autoDownloadFileWithDate(
          GetMessageWithIntl(intl, {
            viewId: VIEW_ID,
            id: 'exportSurveyRequest',
          }),
          'csv',
          res?.assetId ?? ''
        );
        setReload(true);
      }
    } catch (err) {
      error(getExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  // 自動催促設定
  const handleAutoReminder = async (
    result: AutoReminderSettingDialogOutputOption
  ) => {
    const target = getRecordData(targetId);
    if (!target || !target.surveyRequest?.surveyRequestId) {
      error([unselectedMessage]);
      return;
    }
    setLoading(true);
    setReload(false);
    try {
      await window.App.services.ui.worker.apiCall({
        actionName: 'surveyAutoReminder',
        request: {
          surveyRequestId: target.surveyRequest?.surveyRequestId,
          autoReminderSetting: result.autoReminderSetting,
        },
      });
      setShowAutoReminder(false);
      success([successMessage]);
      setReload(true);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  // 手動催促設定
  const handleManualReminder = async (
    result: ManualReminderDialogOutputOption
  ) => {
    const target = getRecordData(targetId);
    if (!target || !target.surveyRequest?.surveyRequestId) {
      error([unselectedMessage]);
      return;
    }
    setClickable(false);
    setLoading(true);
    setReload(false);
    try {
      const changeResult = await getLatestSurveyReplyDueDateChangeResult(
        target.surveyRequest?.surveyRequestId
      );
      await window.App.services.ui.worker.apiCall({
        actionName: 'surveyManualReminder',
        request: {
          surveyRequestId: target.surveyRequest?.surveyRequestId,
          contentType: result.reminderMessageType || null,
          content: result.reminderMessage,
          businessUnitManagementId:
            target.surveyRequest.requestUnit?.businessUnitManagementId || null,
          surveyReplyDueDateChangeResultId:
            changeResult?.surveyReplyDueDateChangeResultId || null,
          sendTarget: result.sendTarget,
        },
      });
      setShowManualReminder(false);
      success([successMessage]);
      setReload(true);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setClickable(true);
      setLoading(false);
    }
  };

  // 依頼終了
  const handleComplete = async () => {
    const targets: mtechnavi.api.survey.ISurveyRequestContent[] = [];
    selectedTargetIds.current.forEach((v) => {
      const target = getRecordData(v);
      if (!target || !target.surveyRequest?.surveyRequestId) {
        error([unselectedMessage]);
        return;
      }
      targets.push(target);
    });
    if (targets.some((v) => v.surveyRequest?.flagProperties?.active)) {
      if (!(await confirmation(completeWithDeleteFlagMessage))) {
        return;
      }
    } else {
      if (!(await confirmation(completeMessage))) {
        return;
      }
    }

    setLoading(true);
    setReload(false);
    try {
      await window.App.services.ui.worker.apiCall({
        actionName: 'completeSurveyRequest',
        request: {
          surveyRequestIds: selectedTargetIds.current,
        },
      });
      if (targets.some((v) => v.surveyRequest?.flagProperties?.active)) {
        await window.App.services.ui.worker.apiCall({
          actionName: 'deleteFlagInSurveyRequest',
          request: {
            surveyRequestIds: selectedTargetIds.current,
          },
        });
      }
      clearCheckBox();
      success([successMessage]);
      setReload(true);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  // 削除実行
  const handleDelete = async () => {
    const target = getRecordData(targetId);
    if (!target || !target.surveyRequest?.surveyBaseRequestId) {
      error([unselectedMessage]);
      return;
    }
    setLoading(true);
    setReload(false);
    try {
      await window.App.services.ui.worker.apiCall({
        actionName: 'deleteSurveyRequest',
        request: {
          surveyBaseRequestId: target.surveyRequest?.surveyBaseRequestId,
          updatedAt: target.surveyRequest?.updatedAt,
        },
      });
      setShowDelete(false);
      clearCheckBox();
      success([successMessage]);
      setReload(true);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  // 破棄実行
  const handleDiscard = async () => {
    const target = getRecordData(targetId);
    if (!target || !target.surveyRequest?.surveyRequestId) {
      error([unselectedMessage]);
      return;
    }

    if (target.surveyRequest.flagProperties?.active) {
      if (!(await confirmation(sendWithDeleteFlagMessage))) {
        return;
      }
    } else {
      if (!(await confirmation(sendMessage))) {
        return;
      }
    }

    setLoading(true);
    setReload(false);
    try {
      await window.App.services.ui.worker.apiCall({
        actionName: 'discardSurveyRequest',
        request: {
          surveyRequestIds: [target.surveyRequest?.surveyRequestId],
          comment: discardComment,
        },
      });
      if (target.surveyRequest.flagProperties?.active) {
        await window.App.services.ui.worker.apiCall({
          actionName: 'deleteFlagInSurveyRequest',
          request: {
            surveyRequestIds: [target.surveyRequest.surveyRequestId],
          },
        });
      }
      setShowDiscard(false);
      clearCheckBox();
      success([successMessage]);
      setReload(true);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  // 回答期日変更実行
  const handleDeadlineChange = async (result: DeadlineChangeResult) => {
    const target = getRecordData(targetId);
    if (!target || !target.surveyRequest?.surveyRequestId) {
      error([unselectedMessage]);
      return;
    }
    setLoading(true);
    setReload(false);
    try {
      const changeRequest = await getLatestSurveyReplyDueDateChangeRequest(
        target.surveyRequest?.surveyRequestId
      );
      await window.App.services.ui.worker.apiCall({
        actionName: 'changeSurveyRequestDeadline',
        request: [
          {
            surveyRequestId: target.surveyRequest?.surveyRequestId,
            replyDueDateDt: convertDatetime(result.deadline, 'YYYY/MM/DD'),
            comment: result.comment,
            surveyReplyDueDateChangeRequestId:
              changeRequest?.surveyReplyDueDateChangeRequestId || null,
          },
        ],
      });
      setShowDeadlineChange(false);
      clearCheckBox();
      success([successMessage]);
      setReload(true);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  // 確認フラグ保存実行
  const handleSaveFlag = async (
    result: mtechnavi.api.survey.IFlagProperties
  ) => {
    // フラグを外すがチェックONの場合
    if (!result.active) {
      if (!(await confirmation(commentDeleteMessage))) {
        return;
      }
      // コメントを削除する
      result.comment = '';
    }

    const target = getRecordData(targetId);
    if (!target || !target.surveyRequest?.surveyRequestId) {
      error([unselectedMessage]);
      return;
    }
    setLoading(true);
    setReload(false);
    try {
      const req: mtechnavi.api.survey.IUpdateFlagInSurveyRequestRequest = {
        surveyRequestId: target.surveyRequest?.surveyRequestId ?? '',
        active: result.active,
        comment: result.comment,
        updatedAt: target.surveyRequest?.updatedAt,
      };
      await window.App.services.ui.worker.apiCall({
        actionName: 'updateFlagInSurveyRequest',
        request: req,
      });
      setShowFlagSetting(false);
      clearCheckBox();
      success([successMessage]);
      setReload(true);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        // スキーマ情報、preset関係の情報を取得
        const { childrenPresetItem, schemas, preset } =
          await getPresetAndSchema(VIEW_ID, [
            FullMethodName_ListSurveyRequestContents,
          ]);

        // ja表示のみする処理
        const jaColumn: string[] = ['status.displayNameLang'];
        const formatterSch = getAltDisplaySchema(schemas[0], jaColumn, 'ja');
        const booleanColumn = ['surveyRequest.surveyResultExportedAt'];
        const booleanSch = getBooleanDataFormetterSchema(
          formatterSch,
          booleanColumn
        );
        const dateColumn = [
          'surveyRequest.receiptedAt',
          'surveyRequest.sendedAt',
        ];
        const dateSch = getDayFormetterDisplaySchema(booleanSch, dateColumn, {
          dayOpts: {
            formatType: 'YYYY/MM/DD',
          },
        });
        const accuracyDateColumn = ['replyDueDateDt'];
        const accuracyDateSch = getDayFormetterDisplaySchema(
          dateSch,
          accuracyDateColumn,
          {
            dayOpts: {
              formatType: 'YYYY/MM/DD',
              isAccuracy: true,
            },
          }
        );
        const joinedSch = getJoinedColumnSchema(
          accuracyDateSch,
          ['surveyRequest.requesters'],
          '',
          true
        );
        // 取得した情報をセット
        setPresetItems(childrenPresetItem);
        setSchema(joinedSch);
        setPreset(preset);
        setPresetViewId(VIEW_ID);
      } catch (err) {
        error(getExceptionMessage(intl, err));
        throw err;
      }
    })();
  }, [VIEW_ID, intl]);

  /**
   * 確認ダイアログ処理
   * Promise で結果を制御する。
   * 確定: true / キャンセル: false
   */
  const confirmation = (viewMessage: MessageProps): Promise<boolean> => {
    setOpenConfirmDialog(true);
    setConfirmMessage(viewMessage);
    return new Promise((resolve) => {
      confirmPromiseRef.current = resolve;
    });
  };

  /**
   * 確認ダイアログの確認処理
   */
  const handleConfirmed = () => {
    if (confirmPromiseRef.current) {
      confirmPromiseRef.current(true);
    }
    setOpenConfirmDialog(false);
  };

  /**
   * 確認ダイアログのキャンセル処理
   */
  const handleCancel = () => {
    if (confirmPromiseRef.current) {
      confirmPromiseRef.current(false);
    }
    setOpenConfirmDialog(false);
  };

  return (
    <>
      <Container viewId={VIEW_ID}>
        <div className="SurveyRequestSenderDetailList">
          <ListView
            isReload={isReload}
            fullMethodName={FullMethodName_ListSurveyRequestContents}
            pageInfo={{
              preset,
              schema,
              menuItem: setMenuActionItem(),
              menuTarget: 'surveyRequest.surveyRequestId',
              presetItems,
              headerTitle: { viewId: VIEW_ID },
              listSkipType: {
                isTotal: true,
                isOutput: true,
                isListActionMenu: true,
              },
              reloadDelayTime: BqListDelayTime,
            }}
            filterItemOption={{
              aggregateStages,
              naviFilterItems,
              isRequestBodyFilter: true,
            }}
            stateOption={{
              onOriginalItemState: (items: unknown[]) =>
                (listItemRef.current = items as SurveyRequestContent[]),
              onAllItemState: (items: unknown[]) =>
                (listAllItemRef.current = items as SurveyRequestContent[]),
            }}
            isSuspendReload={VIEW_ID !== presetViewId}
          />

          <ConfirmationDialog
            isOpen={isShowDelete}
            viewMessage={{
              id: 'C0000001',
              value: {
                $1: GetMessageWithIntl(intl, {
                  id: 'delete',
                  prefixId: VIEW_ID,
                }),
              },
            }}
            onDecision={handleDelete}
            onCancel={() => setShowDelete(false)}
          />

          <AutoReminderSettingDialog
            isOpen={isShowAutoReminder}
            inputOption={autoReminderOption}
            onDecision={handleAutoReminder}
            onCancel={() => setShowAutoReminder(false)}
          />

          <ManualReminderDialog
            isOpen={isShowManualReminder}
            onDecision={handleManualReminder}
            onCancel={() => setShowManualReminder(false)}
            isClickableState={isClickable}
            onChangeClickableState={setClickable}
            inputOption={{ modeType: 'send' }}
          />

          <CommentDialog
            isOpen={isShowDiscard}
            inputOption={{
              comment: discardComment,
              butonType: 'high',
            }}
            inputStateOption={{ onChangeComment: setDiscardComment }}
            messageOption={{
              headerLabelId: {
                id: 'survey_discard',
                prefixId: 'DIALOG_TITLE',
              },
              messageLabelId: {
                id: 'survey_discard',
                prefixId: 'DIALOG_DESCRIPTION',
              },
              decisionLabelId: {
                id: 'send',
              },
            }}
            onDecision={handleDiscard}
            onCancel={() => {
              setShowDiscard(false);
            }}
          />

          <OutputDialog
            isOpen={isShowOutput}
            onDecision={handleDownload}
            messageOption={{
              headerLabelId: {
                id: 'output_requestManagementInfo',
                prefixId: 'DIALOG_TITLE',
              },
            }}
            onCancel={() => {
              setShowOutput(false);
            }}
          />

          <DeadlineChangeDialog
            isOpen={isShowDeadlineChange}
            outputOption={deadlineChangeOption}
            messageOption={{
              headerLabelId: {
                id: 'deadline_change',
                prefixId: 'DIALOG_TITLE',
              },
              messageLabelId: {
                id: 'deadline_change',
                prefixId: 'DIALOG_DESCRIPTION',
              },
            }}
            onDecision={handleDeadlineChange}
            onCancel={() => {
              setShowDeadlineChange(false);
            }}
          />

          <FlagSettingDialog
            isOpen={isShowFlagSetting}
            messageOption={{
              headerLabelId: {
                prefixId: 'DIALOG_TITLE',
                id: 'FlagSetting',
              },
            }}
            inputOption={flagSettingOption}
            onCancel={() => {
              setShowFlagSetting(false);
            }}
            onDecision={handleSaveFlag}
          />

          {/* 確認ダイアログ */}
          <ConfirmationDialog
            isOpen={isOpenConfirmDialog}
            viewMessage={confirmMessage}
            onDecision={handleConfirmed}
            onCancel={handleCancel}
          />
        </div>
        <Toast />
      </Container>
      {isLoading && <LoadingIcon />}
    </>
  );
};
