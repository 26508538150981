import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface CreateForumCommentRequest {
  actionName: 'createForumComment';
  request: mtechnavi.api.forum.ICreateCommentRequest;
}

export const createForumComment = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.forum.ICreateCommentRequest
) => {
  return await worker.invokeListRequest<
    mtechnavi.api.forum.ICreateCommentRequest,
    mtechnavi.api.forum.IComment
  >('mtechnavi.api.forum.ForumService/CreateComment', req);
};
