import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { FullMethodName_ListEstimateRequestDetails } from '~/worker';
import { Container, error, Toast } from '~/shared/components';
import {
  CommentResourceId,
  FILE_VIEWER_LOCALE_STORAGE_PREFIX,
  SimpleListView,
} from '~/shared/components/ui';
import { CaptionButton, IconButton } from '~/shared/components/ui/Button';
import { useLoading } from '~/shared/contexts/LoadingProvider';
import { mtechnavi } from '~/shared/libs/clientsdk';
import {
  getDateFormat,
  getWorkerExceptionMessage,
  openWindowOrTab,
  setLocalStorage,
} from '~/shared/utils';

export const CommentViewerSamplePage = () => {
  const intl = useIntl();
  const { showLoading, hideLoading } = useLoading();

  const [estimateDetails, setEstimateDetails] = useState<
    mtechnavi.api.estimation.IEstimateRequestDetail[]
  >([]);
  const [checkedIds, setCheckedIds] = useState<string[]>([]);

  const viewListItems = useMemo(
    () =>
      estimateDetails
        .map((item) => {
          const displayName = item.displayName ?? '';
          const estimateRequestDetailId = item?.estimateRequestDetailId ?? '';
          const assetId = item.attachment?.assetId ?? '';
          const createdAt = getDateFormat(
            item.createdAt ?? '',
            'YYYY/MM/DD HH:mm'
          );

          return {
            displayName,
            estimateRequestDetailId,
            assetId,
            link: '[ビューワー]',
            createdAt,
          };
        })
        .filter((item) => !!item.assetId),
    [estimateDetails]
  );

  const checkedItems = useMemo(
    () =>
      viewListItems.filter((item) =>
        checkedIds.includes(item.estimateRequestDetailId)
      ),
    [viewListItems, checkedIds]
  );

  useEffect(() => {
    (async () => {
      handleRefresh();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRefresh = async () => {
    try {
      showLoading();
      const result =
        await window.App.services.ui.worker.filter<mtechnavi.api.estimation.IEstimateRequestDetail>(
          {
            action: 'reload',
            fullMethodName: FullMethodName_ListEstimateRequestDetails,
            filter: {},
            sort: [{ createdAt: 'desc' }],
          }
        );
      setEstimateDetails(result.items ?? []);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      hideLoading();
    }
  };

  const handleMoveViewer = (item: typeof viewListItems[number]) => {
    const key = setLocalStorage(FILE_VIEWER_LOCALE_STORAGE_PREFIX, [
      item.assetId,
    ]);
    openWindowOrTab('file-viewer', key);
  };

  const handleMoveComment = (items: typeof viewListItems[number][]) => {
    const resourceIds: CommentResourceId[] = items.map((item) => [
      item.estimateRequestDetailId,
      [item.assetId],
    ]);
    const commentPageParam = encodeURI(JSON.stringify(resourceIds));
    openWindowOrTab('commentable-viewer', commentPageParam);
  };

  return (
    <>
      <Container>
        <h3>
          有効なアセットを持つ見積明細
          <IconButton
            name="refresh"
            iconType="refresh"
            buttonType="basic"
            onClick={handleRefresh}
          />
        </h3>
        <div style={{ marginBottom: '1rem' }}>
          <CaptionButton
            name="refresh"
            buttonType="basic"
            onClick={() => handleMoveComment(checkedItems)}
            caption="選択した明細の図面コメントを開く"
            disabled={checkedItems.length === 0}
          />
        </div>
        <SimpleListView
          data={viewListItems}
          viewOptions={{
            keyColumn: 'estimateRequestDetailId',
            columns: [
              {
                propertyName: 'estimateRequestDetailId',
                header: { id: '見積明細ID' },
                readonly: true,
              },
              {
                propertyName: 'displayName',
                header: { id: '品目名' },
                readonly: true,
              },
              {
                propertyName: 'link',
                width: '8rem',
              },
              {
                propertyName: 'createdAt',
                readonly: true,
                header: { id: '作成日時' },
              },
              {
                propertyName: 'assetId',
                readonly: true,
                header: { id: 'アセットID' },
              },
            ],
            isVisibleCheckBox: true,
          }}
          actionOptions={{
            onRowClick: handleMoveViewer,
          }}
          onChangeState={setCheckedIds}
        />
      </Container>
      <Toast />
    </>
  );
};
