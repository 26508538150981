import React, { useEffect, useMemo, useRef, useState } from 'react';
import { mtechnavi, sharelib } from '~/shared/libs/clientsdk';
import { ForumThread } from '../../tool/Forum/ForumThread';
import '../../tool/Forum/Forum.css';
import './CommentPaneForum.css';
import { Textbox } from '..';
import { IconButton } from '../Button';
import {
  ForumThreadForm,
  ForumThreadFormInputs,
} from '../../tool/Forum/ForumThreadForm';
import { GetMessageWithIntl } from '../../parts/Message/Message';
import { useIntl } from 'react-intl';
import {
  getCommentList,
  getMarkerList,
  getThreadList,
} from '../../tool/Forum/utils/api';
import {
  ForumMaxThreadCount,
  ForumPrefixId as prefixId,
} from '../../tool/Forum/utils/util';
import { error } from '../../parts/Toast/Toast';
import {
  getExceptionMessage,
  getWorkerExceptionMessage,
  ViewId,
} from '~/shared/utils';
import { FullMethodName_ListBusinessUnitManagements } from '~/worker';
import { FilterResult } from '~/worker';
import { useCommentPane, useCommentPaneDispatch } from './CommentPaneProvider';

/** スクロールが必要な要素かどうかをチェック */
const isNeedScroll = (
  parent: HTMLDivElement,
  child: HTMLElement | null
): boolean => {
  if (!child) {
    return false;
  }
  const scrollThreshold = parent.getBoundingClientRect().height;
  return (
    parent.scrollTop + scrollThreshold >
      child.offsetTop + child.getBoundingClientRect().height ||
    parent.scrollTop + parent.getBoundingClientRect().height - scrollThreshold <
      child.offsetTop
  );
};

/** 未読のスレッド ID を抽出する処理 */
const extractUnreadThreadIdList = (args: {
  markerList: mtechnavi.api.forum.IMarker[];
  threadList: mtechnavi.api.forum.IThread[];
  unreadMarker?: sharelib.INameOption;
}) => {
  const { markerList, threadList, unreadMarker } = args;
  // 未読のコメントを含むスレッドID
  const unreadThreadIdList = markerList
    .filter((marker) =>
      marker.markers?.some(
        (mark) =>
          mark.categoryName === unreadMarker?.categoryName &&
          mark.systemName === unreadMarker?.systemName
      )
    )
    .map((marker) => marker.threadId)
    .filter((threadId) => !!threadId);
  // スレッドIDを一意にする
  const uniqueUnreadThreadIdList = Array.from(new Set(unreadThreadIdList));
  // スレッドの並び順に合わせるため、スレッドリストを加工して戻り値とする
  return threadList
    .filter((thread) => uniqueUnreadThreadIdList.includes(thread.threadId))
    .map((thread) => thread.threadId);
};

export interface CommentPaneForumProps {
  companyIds?: string[];
  viewId: ViewId;
}
/**
 * フォーラム UI (図面コメント用)
 */
export const CommentPaneForum = ({
  companyIds,
  viewId,
}: CommentPaneForumProps) => {
  const { draft, threadSet, showOption, assetSet } = useCommentPane();
  const { baseThreadId } = assetSet;
  const { activeThreadId: currentThreadId } = threadSet;
  const commentPaneDispatch = useCommentPaneDispatch();
  const intl = useIntl();
  const [threadList, setThreadList] = useState<mtechnavi.api.forum.IThread[]>(
    []
  );
  const [commentList, setCommentList] = useState<
    mtechnavi.api.forum.IComment[]
  >([]);
  const [markerList, setMarkerList] = useState<mtechnavi.api.forum.IMarker[]>(
    []
  );
  const [filteredThreadIdList, setFilteredThreadIdList] = useState<string[]>(
    []
  );
  const [destinationBusinessUnitList, setDestinationBusinessUnitList] =
    useState<mtechnavi.api.company.IBusinessUnitManagement[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [isWorking, setWorking] = useState(false);
  const listRef = useRef<HTMLDivElement>(null);
  const isDisabled = isWorking || isLoading;

  const unreadMarker: sharelib.INameOption | undefined = useMemo(
    () =>
      window.App.services.ui
        .getNameOption('A0000039')
        .find((item) => item.systemName === 'B01'),
    []
  );

  // 未読コメントを含むスレッドID
  const unreadThreadIdList = useMemo(() => {
    return extractUnreadThreadIdList({
      markerList,
      threadList,
      unreadMarker,
    });
  }, [markerList, threadList, unreadMarker]);

  // スレッドの開閉
  const handleThreadOpen = (threadId: string) => {
    commentPaneDispatch({ type: 'changeActiveThread', threadId });
  };
  const handleThreadClose = () => {
    commentPaneDispatch({ type: 'changeActiveThread', threadId: null });
  };

  /** 検索ワード変更時 */
  const handleChangeSearchWord = (searchWord: string) => {
    if (!searchWord || threadList.length === 0) {
      setFilteredThreadIdList([]);
      return;
    }
    const matchThreadList = threadList.filter((item) =>
      (item.displayName || '').match(searchWord)
    );
    const matchCommentList = commentList.filter((item) =>
      (item.text || '').match(searchWord)
    );

    const matchThreadIdList = [
      ...matchThreadList.map((item) => item.threadId || ''),
      ...matchCommentList.map((item) => item.threadId || ''),
    ];
    const uniqueThreadIdList = Array.from(new Set(matchThreadIdList));

    setFilteredThreadIdList(uniqueThreadIdList);
  };

  /** 検索に一致するスレッドの表示 */
  const handleSearch = () => {
    if (filteredThreadIdList.length === 0) {
      return;
    }
    const currentIndex = filteredThreadIdList.findIndex(
      (threadId) => threadId === currentThreadId
    );
    const threadId =
      currentIndex === -1
        ? filteredThreadIdList.at(0)
        : filteredThreadIdList.at(
            (currentIndex + 1) % filteredThreadIdList.length
          );
    commentPaneDispatch({
      type: 'changeActiveThread',
      threadId: threadId || null,
    });
  };

  /** スレッドの追加キャンセル */
  const handleAddThreadCancel = () => {
    commentPaneDispatch({ type: 'cancelThread' });
  };
  /** スレッドの追加 */
  const handleAddThreadDecision = async (thread: ForumThreadFormInputs) => {
    if (threadList.length >= ForumMaxThreadCount) {
      // スレッド数チェック
      error([
        GetMessageWithIntl(intl, {
          id: 'E0000137',
          value: { $1: ForumMaxThreadCount },
        }),
      ]);
      return;
    }
    const result = await window.App.services.ui.worker.apiCall({
      actionName: 'createForumThread',
      request: {
        thread: {
          ...thread,
          baseThreadId,
          associations: [assetSet.activeAssetId ?? ''],
          attachedPoint: draft,
        },
      },
    });

    const newThread = result?.at(0);
    if (!newThread) {
      return;
    }

    setThreadList([newThread, ...(threadList || [])]);
    commentPaneDispatch({ type: 'addThread', thread: newThread ?? {} });
    commentPaneDispatch({
      type: 'changeActiveThread',
      threadId: newThread.threadId || null,
    });
  };

  /** スレッドの更新 */
  const handleUpdateThread = async (thread: mtechnavi.api.forum.IThread) => {
    const result = await window.App.services.ui.worker.apiCall({
      actionName: 'updateForumThread',
      request: { thread },
    });

    // 更新結果で該当スレッドの差し替え
    const newThread = result?.at(0);
    if (!newThread) {
      return;
    }
    const index = (threadList || []).findIndex(
      (thread) => thread.threadId === newThread.threadId
    );
    if (index < 0) {
      return;
    }
    const newList = [...threadList];
    newList.splice(index, 1, newThread);
    setThreadList(newList);
  };

  /** コメントの追加 */
  const handleSubmitComment = async (comment: mtechnavi.api.forum.IComment) => {
    const result = await window.App.services.ui.worker.apiCall({
      actionName: 'createForumComment',
      request: { comment },
    });

    const newComment = result?.at(0);
    if (!newComment) {
      return;
    }
    setCommentList([...(commentList || []), newComment]);
  };

  /** コメントの未読マーカーセット */
  const handleUnReadComment = async (comment: mtechnavi.api.forum.IComment) => {
    const result = await window.App.services.ui.worker.apiCall({
      actionName: 'setForumMarker',
      request: {
        recordId: comment.commentId,
        markers: [unreadMarker || {}],
      },
    });

    // 更新結果でマーカーの差し替え
    replaceMarkerListItem(result?.at(0)?.marker);
  };

  /** コメントの未読マーカー解除 */
  const handleReadComment = async (comment: mtechnavi.api.forum.IComment) => {
    const result = await window.App.services.ui.worker.apiCall({
      actionName: 'unsetForumMarker',
      request: {
        recordId: comment.commentId,
        markers: [unreadMarker || {}],
      },
    });

    // 更新結果でマーカーの差し替え
    replaceMarkerListItem(result?.at(0)?.marker);
  };

  /** マーカーの差し替え */
  const replaceMarkerListItem = (
    newMarker?: mtechnavi.api.forum.IMarker | null
  ) => {
    if (!newMarker) {
      return;
    }
    const index = (markerList || []).findIndex(
      (marker) => marker.markerId === newMarker.markerId
    );
    if (index < 0) {
      setMarkerList([...markerList, newMarker]);
      return;
    }
    const newList = [...markerList];
    newList.splice(index, 1, newMarker);
    setMarkerList(newList);
  };

  /** 全未読マーカー解除 */
  const handleReadAll = async () => {
    setWorking(true);
    try {
      const result = await window.App.services.ui.worker.apiCall({
        actionName: 'unsetAllForumMarker',
        request: {
          recordIdList: markerList
            .map((marker) => marker.urn?.split(':')?.at(1) || '')
            .filter((id) => !!id),
          markers: [unreadMarker || {}],
        },
      });

      const newMarkerList = result
        .flatMap((item) => item.map((item) => item.marker))
        .filter((item) => !!item) as mtechnavi.api.forum.IMarker[];
      setMarkerList(newMarkerList);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setWorking(false);
    }
  };

  /** コメントの削除 */
  const handleDeleteComment = async (comment: mtechnavi.api.forum.IComment) => {
    const { commentId, threadId, text, replyCommentId, updatedAt } = comment;
    const result = await window.App.services.ui.worker.apiCall({
      actionName: 'updateForumComment',
      request: {
        comment: {
          commentId,
          threadId,
          text,
          replyCommentId,
          updatedAt,
        },
      },
    });

    // 更新結果で該当コメントの差し替え
    const newComment = result?.at(0);
    if (!newComment) {
      return;
    }
    const index = commentList.findIndex(
      (comment) => comment.commentId === newComment.commentId
    );
    if (index < 0) {
      return;
    }
    const newList = [...commentList];
    newList.splice(index, 1, newComment);
    setCommentList(newList);
  };

  /** 一つ前のスレッドを表示 */
  const handlePrevThread = () => {
    if (threadList.length === 0) {
      return;
    }
    if (!currentThreadId) {
      commentPaneDispatch({
        type: 'changeActiveThread',
        threadId: threadList.at(threadList.length - 1)?.threadId || null,
      });
      return;
    }
    const currentIndex = threadList.findIndex(
      (thread) => thread.threadId === currentThreadId
    );
    const threadId =
      currentIndex === -1
        ? threadList.at(threadList.length - 1)?.threadId
        : threadList.at(currentIndex - 1)?.threadId;
    commentPaneDispatch({
      type: 'changeActiveThread',
      threadId: threadId || null,
    });
  };

  /** 一つ後のスレッドを表示 */
  const handleNextThread = () => {
    if (threadList.length === 0) {
      return;
    }
    if (!currentThreadId) {
      commentPaneDispatch({
        type: 'changeActiveThread',
        threadId: threadList.at(0)?.threadId || null,
      });
      return;
    }
    const currentIndex = threadList.findIndex(
      (thread) => thread.threadId === currentThreadId
    );
    const threadId =
      currentIndex === -1
        ? threadList.at(0)?.threadId
        : threadList.at((currentIndex + 1) % threadList.length)?.threadId;
    commentPaneDispatch({
      type: 'changeActiveThread',
      threadId: threadId || null,
    });
  };

  /** 未読のスレッドを表示 */
  const handleNextUnreadThread = () => {
    if (unreadThreadIdList.length === 0) {
      // 未読がなかったら何もしない
      return;
    }
    const currentIndex = unreadThreadIdList.findIndex(
      (threadId) => threadId === currentThreadId
    );
    const threadId =
      currentIndex === -1
        ? unreadThreadIdList.at(0)
        : unreadThreadIdList.at((currentIndex + 1) % unreadThreadIdList.length);
    commentPaneDispatch({
      type: 'changeActiveThread',
      threadId: threadId || null,
    });
  };

  useEffect(() => {
    if (!baseThreadId) {
      return;
    }
    (async () => {
      try {
        await handleRefresh(baseThreadId);
      } catch (err) {
        error(getExceptionMessage(intl, err));
        throw err;
      }
    })();
  }, [intl, baseThreadId]);

  // DM の場合の宛先企業(取引先)
  useEffect(() => {
    (async () => {
      if ((companyIds ?? []).length <= 0) {
        return [];
      }
      const result = (await window.App.services.ui.worker.filter({
        action: 'reload',
        fullMethodName: FullMethodName_ListBusinessUnitManagements,
        filter: { companyId: { $in: companyIds ?? [] } },
        sort: [],
      })) as FilterResult<mtechnavi.api.company.IBusinessUnitManagement>;
      setDestinationBusinessUnitList(result.items ?? []);
    })();
  }, [companyIds]);

  // 表示スレッドの切り替えによるスクロール位置調整
  useEffect(() => {
    if (!listRef.current) {
      return;
    }
    if (!currentThreadId) {
      listRef.current.scroll({ top: 0 });
      return;
    }
    const target = document.getElementById(currentThreadId);
    if (!isNeedScroll(listRef.current, target)) {
      return;
    }
    listRef.current.scroll({
      top: (target?.offsetTop || 0) - 9,
      behavior: 'smooth',
    });
  }, [currentThreadId]);

  /** 再取得 */
  const handleRefresh = async (baseThreadId?: string) => {
    setLoading(true);
    const [threadList, commentList, markerList] = await Promise.all([
      getThreadList(baseThreadId),
      getCommentList(baseThreadId),
      getMarkerList(baseThreadId),
    ]);
    setThreadList(threadList?.items || []);
    setCommentList(commentList?.items || []);
    setMarkerList(markerList?.items || []);
    setLoading(false);
  };

  const handleToggleThreadList = (open: boolean) => {
    if (open) {
      commentPaneDispatch({ type: 'showCommentPane' });
    } else {
      commentPaneDispatch({ type: 'hideCommentPane' });
    }
  };

  return (
    <div className="CommentPaneForum">
      <IconButton
        name="openMenu"
        iconType="forum"
        onClick={() => handleToggleThreadList(!showOption.isShowCommentPane)}
      />
      <div
        className={`list-container ${
          showOption.isShowCommentPane ? 'show' : ''
        }`}
      >
        {!!baseThreadId && (
          <>
            <div className="ForumHeader">
              <div className="search-box">
                <Textbox
                  name="searchWord"
                  className="search-word"
                  type="text"
                  columns={['searchWord']}
                  labelId="searchWord"
                  disabled={isDisabled}
                  onChangeState={handleChangeSearchWord}
                />
                <IconButton
                  name="search"
                  className="search-button"
                  iconType="search"
                  buttonType="basic"
                  disabled={isDisabled}
                  onClick={handleSearch}
                />
              </div>
              <div className="action-box">
                <IconButton
                  name="readAll"
                  iconType="read"
                  buttonType="basic"
                  caption={GetMessageWithIntl(intl, {
                    prefixId,
                    id: 'readAll',
                  })}
                  disabled={isDisabled}
                  onClick={handleReadAll}
                />
                <IconButton
                  name="refresh"
                  buttonType="basic"
                  iconType="refresh"
                  caption={GetMessageWithIntl(intl, {
                    prefixId,
                    id: 'refresh',
                  })}
                  disabled={isDisabled}
                  onClick={() => handleRefresh(baseThreadId)}
                />
                <hr />
                <IconButton
                  name="prevThread"
                  iconType="up"
                  buttonType="basic"
                  caption={GetMessageWithIntl(intl, {
                    prefixId,
                    id: 'prevThread',
                  })}
                  disabled={isDisabled}
                  onClick={handlePrevThread}
                />
                <IconButton
                  name="nextThread"
                  iconType="down"
                  buttonType="basic"
                  caption={GetMessageWithIntl(intl, {
                    prefixId,
                    id: 'nextThread',
                  })}
                  disabled={isDisabled}
                  onClick={handleNextThread}
                />
                <IconButton
                  name="openUnread"
                  iconType="unread"
                  buttonType="basic"
                  caption={GetMessageWithIntl(intl, {
                    prefixId,
                    id: 'openUnread',
                  })}
                  disabled={isDisabled}
                  onClick={handleNextUnreadThread}
                />
              </div>
            </div>
            {draft && (
              <ForumThreadForm
                viewId={viewId}
                destinationBusinessUnit={destinationBusinessUnitList}
                onCancel={handleAddThreadCancel}
                onDecision={handleAddThreadDecision}
              />
            )}
            <div
              className={`ForumThreadList ${isLoading ? 'loading' : ''}`}
              ref={listRef}
            >
              {!isLoading &&
                threadList?.map((thread) => (
                  <ForumThread
                    key={thread.threadId}
                    unreadMarker={unreadMarker}
                    inputOption={{
                      thread,
                      commentList:
                        commentList?.filter(
                          (comment) => comment.threadId === thread.threadId
                        ) || [],
                      markerList:
                        markerList?.filter(
                          (marker) => marker.threadId === thread.threadId
                        ) || [],
                    }}
                    onThreadOpen={handleThreadOpen}
                    onThreadClose={handleThreadClose}
                    onEditThread={handleUpdateThread}
                    onSubmitComment={handleSubmitComment}
                    onReadComment={handleReadComment}
                    onUnReadComment={handleUnReadComment}
                    onDeleteComment={handleDeleteComment}
                    isOpen={thread.threadId === currentThreadId}
                    isParentWorking={isWorking}
                  />
                ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
