import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface AddSurveyBaseRequestIdInDisasterSurveyRequest {
  actionName: 'addSurveyBaseRequestIdInDisasterSurvey';
  request: mtechnavi.api.bcp.IAddSuveyBaseRequestIdInDisasterSurveyRequest;
}

export const addSurveyBaseRequestIdInDisasterSurvey = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.bcp.IAddSuveyBaseRequestIdInDisasterSurveyRequest
) => {
  const methodName =
    'mtechnavi.api.bcp.BcpService/AddSuveyBaseRequestIdInDisasterSurvey';

  return await worker.invokeListRequest<
    mtechnavi.api.bcp.IAddSuveyBaseRequestIdInDisasterSurveyRequest,
    void
  >(methodName, req);
};
