import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface UpdateSurveyRequestWorkFormRequest {
  actionName: 'updateSurveyRequestWorkForm';
  request: mtechnavi.api.survey.IUpdateSurveyRequestWorkFormRequest;
}

// RequestWorkForm の更新
export const updateSurveyRequestWorkForm = (
  worker: AppSaveWorker,
  req: mtechnavi.api.survey.IUpdateSurveyRequestWorkFormRequest
) => {
  const methodName =
    'mtechnavi.api.survey.SurveySenderService/UpdateSurveyRequestWorkForm';

  return worker.invokeListRequest<
    mtechnavi.api.survey.IUpdateSurveyRequestWorkFormRequest,
    mtechnavi.api.survey.SurveyRequestWorkForm
  >(methodName, req);
};
