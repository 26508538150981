import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface ExecDeleteWorkTaskRequest {
  actionName: 'deleteWorkTask';
  request: mtechnavi.api.worktask.IWorkTask;
}

// 指図案件削除
export const execDeleteWorkTask = async (
  worker: AppSaveWorker,
  request: mtechnavi.api.worktask.IWorkTask
) => {
  const fullMethodName =
    'mtechnavi.api.worktask.WorkTaskService/DeleteWorkTask';

  await worker.invokeListRequest(fullMethodName, {
    workTask: request,
  });
};
