import { AppSaveWorker } from '..';

export interface ExecApprovalResultWorkTaskResult {
  actionName: 'execWorkTaskApproveResult';
  request: ExecWorkTaskApproveResultParam;
}
interface ExecWorkTaskApproveResultParam {
  workTaskId: string;
  comment: string;
  decline: boolean;
}

// 指図案件承認実績
export const execApprovalResultWorkTask = async (
  worker: AppSaveWorker,
  request: ExecWorkTaskApproveResultParam
) => {
  const approveFullMethodName =
    'mtechnavi.api.worktask.WorkTaskService/ApproveWorkTask';
  const rejectFullMethodName =
    'mtechnavi.api.worktask.WorkTaskService/RejectWorkTask';

  if (request.decline) {
    await worker.invokeListRequest(rejectFullMethodName, {
      workTaskId: request.workTaskId,
      comment: request.comment,
    });
  } else {
    await worker.invokeListRequest(approveFullMethodName, {
      workTaskId: request.workTaskId,
      comment: request.comment,
    });
  }
};
