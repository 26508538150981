import { useState, useEffect, useMemo } from 'react';
import { Container, error, Toast } from '~/shared/components';
import {
  MenuListView,
  Preset,
  MenuItem,
  Property,
  joinFormatter,
  getAltDisplaySchema,
} from '~/shared/components/ui';
import { PresetItem, Property as PresetProperty } from '~/shared/services';
import { useNavigate } from 'react-router-dom';
import { ImportDialogHandleFormat } from '~/shared/components/ui/Dialog/ImportDialog';
import { useIntl } from 'react-intl';
import {
  PageState,
  ViewId,
  getExceptionMessage,
  getPresetAndSchema,
  FullMethodName_ListComponentUnits,
} from '~/shared/utils';

const VIEW_ID: ViewId = 'MASTER_COMPONENT_UNIT_LIST';

export function CompanyComponentUnitList() {
  const intl = useIntl();
  const navi = useNavigate();

  const [childrenPresetItem, setChildrenPresetItem] = useState<PresetItem[]>();
  const [schema, setSchema] = useState<Property[]>([]);
  const [preset, setPreset] = useState<Preset>({
    filter: {},
    propertyNames: [],
  });

  // 取り込みダイアログ関係
  const [dialogPreset, setDialogPreset] = useState<PresetProperty[]>([
    { name: '', propertyName: '', propertyValue: '' },
  ]);
  const [isDialogOpen, setDialogOpen] = useState(false);

  useMemo(() => {
    const dialogPreset = childrenPresetItem?.find(
      (v) => v.name === 'businessUnitImport'
    );
    setDialogPreset(
      dialogPreset?.property ?? [
        { name: '', propertyName: '', propertyValue: '' },
      ]
    );

    // preset 変更時に起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preset, childrenPresetItem]);

  useEffect(() => {
    (async () => {
      try {
        // スキーマ情報、preset関係の情報を取得
        const { childrenPresetItem, schemas, preset } =
          await getPresetAndSchema(VIEW_ID, [
            FullMethodName_ListComponentUnits,
          ]);

        /*
         実施内容
         ja表示してからjoinするカラムを指定 -> ja表示するカラムを指定
        */
        // ja表示したカラムのjoinを行う処理
        // ja表示カラム
        const altColumns = [
          'postUnit.displayNameLang.ja',
          'organizationUnit.displayNameLang.ja',
          'deliveryPoint.displayNameLang.ja',
          'office.displayNameLang.ja',
        ];
        // joinカラム
        const jaJoinColumn = [
          'postUnit.displayNameLang',
          'organizationUnit.displayNameLang',
          'deliveryPoint.displayNameLang',
          'office.displayNameLang',
        ];
        const jaJoinSch = schemas[0].map((v) => {
          if (jaJoinColumn.includes(v.name)) {
            return {
              ...v,
              formatter: joinFormatter(',', altColumns),
            };
          } else {
            return v;
          }
        });

        // ja表示のみする処理
        const jaColumn = [
          'displayNameLang',
          'usable.displayNameLang',
          'placeProperties.displayNameLang',
        ];
        const formatterSch = getAltDisplaySchema(jaJoinSch, jaColumn, 'ja');

        // 取得した情報をセット
        setChildrenPresetItem(childrenPresetItem);
        setSchema(formatterSch);
        setPreset(preset);
      } catch (err) {
        error(getExceptionMessage(intl, err));
        throw err;
      }
    })();
  }, [intl]);

  const handleExport: ImportDialogHandleFormat = {
    name: 'componentUnit',
    headerColumns: [],
  };

  const handleImport: ImportDialogHandleFormat = {
    name: 'componentUnit',
    headerColumns: [],
  };

  // サンプルでバーガーメニューに関数を渡すためのサンプル関数(ダミー関数)
  const handleMenu = (prop?: string[]) => {
    console.debug('preset menuFunc:');
    console.debug(prop);
  };

  // メニューの「確認」処理
  const handleMenuConfirm = (prop?: string[]) => {
    const state: PageState = {
      ids: prop ?? [],
    };
    navi('/company-component-unit', {
      state,
    });
  };

  // メニューの「管理場所取込処理
  const handleMenuImport = () => {
    // 取り込みダイアログ表示
    setDialogOpen(true);
  };

  const burgerMenuEvent = (): MenuItem[] => {
    // 必要な関数を宣言
    const menuItems: MenuItem[] = [];
    // 確認
    menuItems.push({
      name: 'confirm',
      func: (v?: string[]) => handleMenuConfirm(v),
    });
    menuItems.push({ name: 'delete', func: (v?: string[]) => handleMenu(v) });
    menuItems.push({ name: 'tag', func: (v?: string[]) => handleMenu(v) });
    // 管理場所取込
    menuItems.push({
      name: 'import',
      func: () => handleMenuImport(),
    });
    menuItems.push({ name: 'edit', func: (v?: string[]) => handleMenu(v) });
    return menuItems;
  };
  const menuViewlist = burgerMenuEvent().map((v) => v.name);
  return (
    <Container viewId={VIEW_ID}>
      <MenuListView
        fullMethodName={FullMethodName_ListComponentUnits}
        schema={schema}
        preset={preset}
        presetItems={childrenPresetItem}
        menuEvent={burgerMenuEvent()}
        listSkipType={{
          isOutput: true,
          isTotal: true,
        }}
        headerTitle={{ viewId: VIEW_ID }}
        menuTarget={'componentUnitId'}
        menuViewlist={menuViewlist}
        importDialogOption={{
          isDisplay: true,
          isDialogOpen,
          dialogPreset,
          headerLabelId: { prefixId: 'DIALOG', viewId: VIEW_ID },
          handleExport,
          handleImport,
          onChangeState: (v) => {
            setDialogOpen(v);
          },
        }}
      />
      <Toast />
    </Container>
  );
}
