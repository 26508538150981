import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';
export interface DeleteEstimateRequestPlanRequest {
  actionName: 'deleteEstimateRequestPlan';
  request: mtechnavi.api.estimation.IEstimateRequestPlan;
}

export const deleteEstimateRequestPlan = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.estimation.IEstimateRequestPlan
) => {
  const methodName =
    'mtechnavi.api.estimation.EstimateSenderService/DeleteEstimateRequestPlan';

  await worker.invokeListRequest<
    mtechnavi.api.estimation.IDeleteEstimateRequestPlanRequest,
    void
  >(methodName, {
    estimateRequestPlan: req,
  });
};
