import Long from 'long';
import { useState } from 'react';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { ViewId, toCommaTypeString, convertLongToString } from '~/shared/utils';

interface CompanyConfirmationBranchProps {
  viewId: ViewId;
  branches?: mtechnavi.api.analysis.IBranch[];
  facilitiess?: mtechnavi.api.analysis.IFacilities[];
}
export const CompanyConfirmationBranch = ({
  branches,
  facilitiess,
}: CompanyConfirmationBranchProps) => {
  const [showFacilityIds, setShowFacilityIds] = useState<string[]>([]);

  const toggleFacilityDetail = (id: string) => {
    if (!showFacilityIds.some((showId) => showId === id)) {
      setShowFacilityIds([...showFacilityIds, id]);
      return;
    }
    setShowFacilityIds([...showFacilityIds.filter((showId) => showId !== id)]);
  };

  return (
    <>
      <ul className={`branch-list`}>
        <li className="branch-list-header">
          <div>拠点名</div>
          <div>地域</div>
          <div>設備</div>
          <div>事業内容 / 製造内容</div>
        </li>
        {(branches || []).map((branch) => {
          const ownedFacilities = (facilitiess || []).filter(
            (facility) => facility.branchId === branch.recordId
          );
          const facilityCount = ownedFacilities.reduce((prev, facility) => {
            if (!facility.assetsAmount) {
              return prev;
            }
            return prev.add(Long.fromValue(facility.assetsAmount));
          }, Long.fromInt(0));
          const isShowFacility = showFacilityIds.some(
            (showId) => showId === branch.recordId
          );
          return (
            <li
              className={`branch-list-row ${
                isShowFacility ? 'show-facility' : ''
              }`}
              key={branch.recordId}
            >
              <div>{branch.displayName}</div>
              <div>{branch.address?.region?.displayNameLangJa ?? ''}</div>
              <div>
                {ownedFacilities.length === 0 && 'なし'}
                {ownedFacilities.length > 0 && (
                  <>
                    {`${facilityCount.toString()}台`}
                    <a
                      className="show-label"
                      onClick={() =>
                        toggleFacilityDetail(branch.recordId || '')
                      }
                    >
                      {isShowFacility ? '非表示にする' : '表示する'}
                    </a>
                  </>
                )}
              </div>
              <div>{branch.overview}</div>
              {isShowFacility && (
                <div>
                  <ul className="facility-list">
                    <li className="facility-list-header">
                      <div>設備分類</div>
                      <div>メーカー</div>
                      <div>型式</div>
                      <div>台数</div>
                      <div>能力</div>
                    </li>
                    {ownedFacilities.map((facility) => (
                      <li className="facility-list-row" key={facility.recordId}>
                        <div>
                          {facility.categorys
                            ? facility.categorys
                                .map((v) => v.displayNameLangJa)
                                .join('\n')
                            : ''}
                        </div>
                        <div>{facility.manufacture}</div>
                        <div>{facility.modelNumber}</div>
                        <div>
                          {facility.assetsAmount
                            ? Long.fromValue(facility.assetsAmount).toString()
                            : ''}
                        </div>
                        <div>{formatAbilities(facility)}</div>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </li>
          );
        })}
      </ul>
    </>
  );
};

const formatAbilities = (
  facility: mtechnavi.api.analysis.IFacilities
): string => {
  const abilities: string[] = [];
  if (facility.processableTon) {
    abilities.push(
      `加工トン数：${toCommaTypeString(
        convertLongToString(facility.processableTon)
      )}トン`
    );
  }
  if (facility.accuracy?.displayNameLangJa) {
    abilities.push(`精度：${facility.accuracy.displayNameLangJa}`);
  }
  if (facility.sizeX && facility.sizeY) {
    let sizeText = `加工サイズmm：${toCommaTypeString(
      convertLongToString(facility.sizeX)
    )} × ${toCommaTypeString(convertLongToString(facility.sizeY))}`;
    if (facility.sizeZ) {
      sizeText += `(高さ${toCommaTypeString(
        convertLongToString(facility.sizeZ)
      )})`;
    }
    if (facility.sizeT) {
      sizeText += `(厚み${toCommaTypeString(
        convertLongToString(facility.sizeT)
      )})`;
    }
    if (facility.diameter) {
      sizeText += `(径${toCommaTypeString(
        convertLongToString(facility.diameter)
      )})`;
    }
    abilities.push(sizeText);
  }

  return abilities.join('\n');
};
