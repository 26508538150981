import { mtechnavi, sharelib } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';
export interface ExportOrdersRequest {
  actionName: 'exportOrders';
  request: ExportOrdersRequestParams;
}

export interface ExportOrdersRequestParams {
  ids: string[];
  format?: sharelib.IFileFormat;
}

/**
 * 発注データ出力
 */
export const exportOrders = async (
  worker: AppSaveWorker,
  req: ExportOrdersRequestParams
) => {
  return worker.invokeListRequest<
    mtechnavi.api.estimation.IExportOrdersRequest,
    mtechnavi.api.estimation.IExportOrdersResponse
  >('mtechnavi.api.estimation.EstimateSenderService/ExportOrders', {
    ids: req.ids,
    fileFormat: req.format,
  });
};
