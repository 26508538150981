import { useEffect, useState, useMemo } from 'react';
import { FilterExpression, FilterTerm, SortTerm } from '~/worker';
import { PresetItem } from '~/shared/services';
import { InitialLoadMethods } from '~/shared/config';
import { FilterViewQuery } from '../index';
import { mtechnavi } from '~/shared/libs/clientsdk';
import {
  DataFilterbox,
  DataFilterboxItem,
} from '~/shared/components/ui/Filterbox/DataFilterbox';
import { FilterboxItem } from '~/shared/components/ui/Filterbox/Filterbox';

import { getPresetPropertyValue, checkDisalbedPresetProperty } from '../preset';
import { isObject, isString } from '../filter';
import { getFilteringFilterboxMultipleValues } from './FilterInputFilterboxMultiple';
import { error } from '~/shared/components/parts/Toast/Toast';
import { IntlShape, useIntl } from 'react-intl';
import { getExceptionMessage } from '~/shared/utils';

const dataFilterboxItem = (
  displayName: string,
  value: string
): DataFilterboxItem => {
  return {
    displayName: displayName,
    value: value,
  };
};

const toDataFilterboxItemsByDisplayValues = (
  items: DataFilterboxItem[],
  ...selectedValues: string[]
): DataFilterboxItem[] => {
  return selectedValues.map((value) => {
    const v = items.find((item) => item.value === value);
    return dataFilterboxItem(v?.displayName ?? '', v?.value ?? '');
  });
};

const getCustomQuery = async (
  filterboxCustomQueryKey: string,
  filterboxCustomQueryValue: string,
  intl: IntlShape
): Promise<FilterExpression> => {
  if (!filterboxCustomQueryKey || !filterboxCustomQueryValue) {
    return {};
  }
  const customQueryFilter: FilterExpression = {};
  switch (filterboxCustomQueryValue) {
    case 'myCompanyId':
      {
        try {
          // myCompanyIdの場合、自社の企業IDを使用する
          const res: mtechnavi.api.company.Company =
            await window.App.services.companyService.getCompany({});
          customQueryFilter[filterboxCustomQueryKey] = {
            $eq: res.companyId,
          };
        } catch (err) {
          error(getExceptionMessage(intl, err));
        }
      }
      break;
    default:
      customQueryFilter[filterboxCustomQueryKey] = {
        $eq: filterboxCustomQueryValue,
      };
  }
  return customQueryFilter;
};

function ensureString(v: unknown): string {
  return isString(v) ? v : String(v);
}

const isNameOptionKeyValue = (name: string): boolean => {
  return name.endsWith('displayNameLang');
};

const getNameOptionLangName = (name: string): string => {
  return isNameOptionKeyValue(name) ? window.App.config.langName : '';
};

interface FilterInputAPIFilterboxMultipleProps {
  labelId: string;
  column: string;
  presetItem?: PresetItem;
  filterValue: FilterViewQuery;
  onChangeFilter?: (terms: FilterTerm[]) => void;
}

export type CustomDisplayNameType = 'brackets';
/**
 * API参照検索フィルタ
 *
 * 指定されたAPIの取得データから、フィールド一致検索を行う。
 *
 * - プリセット
 *     - type='apiFilterboxMultiple'
 *     - fullmethodName='メソッド名（e.g. "mtechnavi.api.company.ComponentUnitService/ListXXXXXs"）'
 *     - filterboxValue='参照先APIデータの検索値フィールド'
 *     - filterboxDisplayName='参照先APIデータの表示用フィールド'
 *
 * - プリセット（optional）
 *     - filterboxCustomQueryKey='参照先APIデータのフィルタ先フィールド'
 *     - filterboxCustomQueryValue='参照先APIデータのフィルタ値'
 *     - filterboxCustomDisplayName='表示用フィールドを変換するためのフィールド'
 *     - filterboxCustomDisplayNameType='表示用フィールドの変換フォーマットのフィールド'
 * - 注意点
 *     - filterboxValueは基本的にdisplayNameを使用するが、APIにデータ抽出項目として指定する場合は
 *       キーとなるIDのみ指定することを許可する
 */
export function FilterInputAPIFilterboxMultiple(
  props: FilterInputAPIFilterboxMultipleProps
) {
  const intl = useIntl();
  const { labelId, column, presetItem, filterValue, onChangeFilter } = props;
  const [dataFilterboxItems, setDataFilterboxItems] = useState<
    DataFilterboxItem[]
  >([]);

  const [dataFilterboxValues, setDataFilterboxValues] = useState<
    DataFilterboxItem[]
  >([]);

  const [
    fullmethodName,
    filterboxValue,
    filterboxDisplayName,
    filterboxValueLang,
    filterboxDisplayNameLang,
    filterboxCustomQueryKey,
    filterboxCustomQueryValue,
    filterboxCustomDisplayName,
    filterboxCustomDisplayNameType,
    filterboxSort,
    filterboxSortType,
    isDisabledColumn,
  ] = useMemo<
    [
      string,
      string,
      string,
      string,
      string,
      string,
      string,
      string,
      CustomDisplayNameType,
      string,
      string,
      boolean
    ]
  >(() => {
    const presetProperty = presetItem?.property;
    // API用フィルタボックス用のフルメソッド名取得
    const fullmethodName = getPresetPropertyValue(
      presetProperty,
      column,
      'fullmethodName'
    );
    const filterboxValue = getPresetPropertyValue(
      presetProperty,
      column,
      'filterboxValue'
    );
    const filterboxDisplayName = getPresetPropertyValue(
      presetProperty,
      column,
      'filterboxDisplayName'
    );
    const filterboxCustomQueryKey = getPresetPropertyValue(
      presetProperty,
      column,
      'filterboxCustomQueryKey'
    );
    const filterboxCustomQueryValue = getPresetPropertyValue(
      presetProperty,
      column,
      'filterboxCustomQueryValue'
    );
    const filterboxCustomDisplayName = getPresetPropertyValue(
      presetProperty,
      column,
      'filterboxCustomDisplayName'
    );
    const filterboxCustomDisplayNameType = getPresetPropertyValue(
      presetProperty,
      column,
      'filterboxCustomDisplayNameType'
    ) as CustomDisplayNameType;

    const filterboxSort = getPresetPropertyValue(
      presetProperty,
      column,
      'filterboxSort'
    );
    const filterboxSortType = getPresetPropertyValue(
      presetProperty,
      column,
      'filterboxSortType'
    );
    const filterboxValueLang = getNameOptionLangName(filterboxValue);
    const filterboxDisplayNameLang =
      getNameOptionLangName(filterboxDisplayName);

    const isDisabled = checkDisalbedPresetProperty(
      column,
      presetItem?.children
    );
    return [
      fullmethodName,
      filterboxValue,
      filterboxDisplayName,
      filterboxValueLang,
      filterboxDisplayNameLang,
      filterboxCustomQueryKey,
      filterboxCustomQueryValue,
      filterboxCustomDisplayName,
      filterboxCustomDisplayNameType,
      filterboxSort,
      filterboxSortType,
      isDisabled,
    ];
  }, [presetItem?.property, presetItem?.children, column]);

  // 初期データ
  useEffect(() => {
    const displayValues = getFilteringFilterboxMultipleValues(
      filterValue.filterTerms[column] ?? []
    );
    setDataFilterboxValues(
      toDataFilterboxItemsByDisplayValues(dataFilterboxItems, ...displayValues)
    );
  }, [column, dataFilterboxItems, filterValue.filterTerms]);

  useEffect(() => {
    (async () => {
      const customQueryFilter = await getCustomQuery(
        filterboxCustomQueryKey,
        filterboxCustomQueryValue,
        intl
      );
      const sortTerm: SortTerm[] = [];
      if (filterboxSort) {
        sortTerm.push({
          [filterboxSort]: filterboxSortType === 'asc' ? 'asc' : 'desc',
        });
      }
      // すでに初回起動時にReloadされている場合は、ワーカーに対してクエリのみを実行
      const useQuery = InitialLoadMethods.includes(fullmethodName);
      const filterResult = await window.App.services.ui.worker.pagenate({
        action: useQuery ? 'query' : 'reload',
        fullMethodName: fullmethodName,
        filter: customQueryFilter,
        sort: sortTerm,
        pageNumber: 1,
        pageSize: 1,
      });
      const dataFilterboxItems: DataFilterboxItem[] = [];
      for (const v of filterResult.allItems) {
        if (!isObject(v)) continue;
        const filterboxKeyColumn = filterboxDisplayNameLang
          ? `${filterboxDisplayName}.${filterboxDisplayNameLang}`
          : filterboxDisplayName;
        const filterboxValueColumn = filterboxValueLang
          ? `${filterboxValue}.${filterboxValueLang}`
          : filterboxValue;
        let displayName = ensureString(v[filterboxKeyColumn]);
        // 表示名称の制御が必要な場合は
        if (filterboxCustomDisplayName) {
          const customDisplayName = ensureString(v[filterboxCustomDisplayName]);
          // かっこで結合する場合
          if (filterboxCustomDisplayNameType === 'brackets') {
            displayName = `${displayName}(${customDisplayName})`;
          }
        }
        dataFilterboxItems.push({
          displayName: displayName,
          value: ensureString(v[filterboxValueColumn]),
        });
      }
      setDataFilterboxItems(dataFilterboxItems);
    })();
  }, [
    fullmethodName,
    filterboxValue,
    filterboxDisplayName,
    filterboxValueLang,
    filterboxDisplayNameLang,
    filterboxCustomQueryKey,
    filterboxCustomQueryValue,
    filterboxCustomDisplayName,
    filterboxCustomDisplayNameType,
    intl,
  ]);

  const onChangeDataFilterboxItem = (selectedItems: FilterboxItem[]) => {
    const displayNames = selectedItems.map((v) => v.value);
    const filterTerms: FilterTerm[] = [];
    if (displayNames.length > 0) {
      filterTerms.push({ [column]: { $in: displayNames } });
    }
    setDataFilterboxValues(selectedItems);
    onChangeFilter && onChangeFilter(filterTerms);
  };

  return (
    <div className="input-area">
      <DataFilterbox
        data={dataFilterboxItems}
        value={dataFilterboxValues}
        labelId={labelId}
        multiple={true}
        searchOption={{ targets: 'displayName' }}
        name={column}
        columns={[column]}
        onChangeState={(v) => {
          onChangeDataFilterboxItem(v);
        }}
        disabled={isDisabledColumn}
      ></DataFilterbox>
    </div>
  );
}
