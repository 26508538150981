import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface ExecConfirmBusinessUnitChangeNotification {
  actionName: 'execConfirmBusinessUnitChangeNotification';
  request: mtechnavi.api.company.IConfirmBusinessUnitChangeNotificationRequest;
}

export const execConfirmBusinessUnitChangeNotification = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.company.IConfirmBusinessUnitChangeNotificationRequest
) => {
  const execMethodName =
    'mtechnavi.api.company.BusinessUnitManagementService/ConfirmBusinessUnitChangeNotification';

  const result = await worker.invokeListRequest(execMethodName, {
    typeName: req.typeName,
    recordIds: req.recordIds,
  });

  return result;
};
