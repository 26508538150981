import { AppSaveWorker } from '..';

export interface ExecSurveyApproveResultRequest {
  actionName: 'execSurveyApproveResult';
  request: SurveyApproveResultRequestParam;
}
interface SurveyApproveResultRequestParam {
  surveyBaseRequestId: string;
  comment: string;
  decline: boolean;
}

export const execSurveyApproveResultRequest = async (
  worker: AppSaveWorker,
  req: SurveyApproveResultRequestParam
) => {
  const approveRequestFullMethodName =
    'mtechnavi.api.survey.SurveySenderService/ApproveSurveyBaseRequest';
  const rejectRequestFullMethodName =
    'mtechnavi.api.survey.SurveySenderService/RejectSurveyBaseRequest';
  if (req.decline) {
    await worker.invokeListRequest(rejectRequestFullMethodName, {
      surveyBaseRequestId: req.surveyBaseRequestId,
      comment: req.comment,
    });
  } else {
    await worker.invokeListRequest(approveRequestFullMethodName, {
      surveyBaseRequestId: req.surveyBaseRequestId,
      comment: req.comment,
    });
  }
};
