import { useEffect, useState } from 'react';
import {
  ContentsDisplayDialog,
  ContentsDisplayInfo,
  SimpleIconListView,
} from '~/shared/components/ui';
import { mtechnavi } from '~/shared/libs/clientsdk';
import {
  AttachmentItems,
  StringDateToNumber,
  ViewId,
  getDateFormat,
} from '~/shared/utils';

interface PublicDocumentTabItemProps {
  businessUnitPublicDocuments?: mtechnavi.api.company.IBusinessUnitPublicDocument[];
}

interface ViewPublicDocumentType {
  id: string; // key
  lastUpdatedDate: string; // 最終更新
  subject: string; // 件名
}

const VIEW_ID: ViewId = 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION';

const publicDocumentColumns = [
  {
    header: {
      id: 'publicDocument.lastUpdatedDate',
      prefixId: VIEW_ID,
    },
    propertyName: 'lastUpdatedDate',
    width: '12rem',
  },
  {
    header: {
      id: 'publicDocument.subject',
      prefixId: VIEW_ID,
    },
    propertyName: 'subject',
  },
];

export const PublicDocumentTabItem = ({
  businessUnitPublicDocuments,
}: PublicDocumentTabItemProps) => {
  const [publicDocumentItems, setPublicDocumentItems] = useState<
    ViewPublicDocumentType[]
  >([]);
  const [contentsDisplayDialogItems, setContentsDisplayDialogItems] =
    useState<ContentsDisplayInfo>({
      subject: '',
    });
  const [isContentsDisplayDialogOpen, setContentsDisplayDialogOpen] =
    useState(false);

  useEffect(() => {
    if (!businessUnitPublicDocuments) {
      setPublicDocumentItems([]);
      return;
    }
    const vals: ViewPublicDocumentType[] = [];
    businessUnitPublicDocuments.map(
      (v: mtechnavi.api.company.IBusinessUnitPublicDocument) => {
        vals.push({
          id: v.businessUnitPublicDocumentId ?? '',
          lastUpdatedDate: getDateFormat(v.latestUpdatedAt ?? '', 'YYYY/MM/DD'),
          subject: v.displayName ?? '',
        });
      }
    );
    vals.sort(
      (val1, val2) =>
        StringDateToNumber(val2.lastUpdatedDate) -
        StringDateToNumber(val1.lastUpdatedDate)
    );
    setPublicDocumentItems(vals);
  }, [businessUnitPublicDocuments]);

  // [実行処理]得意分野情報確認ダイアログ
  const handleContentsDisplayDialog = (v: ViewPublicDocumentType) => {
    const item = businessUnitPublicDocuments?.find(
      (doc) => doc.businessUnitPublicDocumentId === v.id
    );
    if (item) {
      setContentsDisplayDialogItems({
        subject: item.displayName ?? '',
        content: item.contents ?? '',
        attachment: (item.attachments as AttachmentItems[]) ?? [],
        lastUpdatedDate: item.latestUpdatedAt ?? undefined,
      });
    }
    setContentsDisplayDialogOpen(true);
  };

  return (
    <>
      <div className="tab-info-body">
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              <SimpleIconListView
                data={publicDocumentItems}
                viewOptions={{
                  previewRowCount: 10,
                  keyColumn: 'id',
                  columns: publicDocumentColumns,
                  omitFooter: true,
                }}
                actionOptions={{}}
                iconMenuOptions={{
                  iconMenu: [
                    {
                      name: 'description',
                      displayName: '確認',
                      func: (v: ViewPublicDocumentType) => {
                        handleContentsDisplayDialog(v);
                      },
                    },
                  ],
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <ContentsDisplayDialog
        isOpen={isContentsDisplayDialogOpen}
        inputOption={{
          info: contentsDisplayDialogItems,
        }}
        onCancel={() => setContentsDisplayDialogOpen(false)}
      />
    </>
  );
};
