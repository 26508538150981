import Long from 'long';
import { useState } from 'react';
import { CaptionButton } from '~/shared/components/ui/Button';
import {
  InformationManagementDialog,
  InformationManagementDialogOutputOption,
} from '~/shared/components/ui/Dialog/InformationManagementDialog';

export const UISampleInformationManagementDialogs = () => {
  const [addResult, setAddResult] =
    useState<Partial<InformationManagementDialogOutputOption>>();
  const [copyResult, setCopyResult] =
    useState<Partial<InformationManagementDialogOutputOption>>();
  const [editResult, setEditResult] =
    useState<Partial<InformationManagementDialogOutputOption>>();
  const [displayResult, setDisplayResult] =
    useState<Partial<InformationManagementDialogOutputOption>>();
  const [isShowAddDialog, setIsShowAddDialog] = useState(false);
  const [isShowCopyDialog, setIsShowCopyDialog] = useState(false);
  const [isShowEditDialog, setIsShowEditDialog] = useState(false);
  const [isShowDisplayDialog, setIsShowDisplayDialog] = useState(false);

  return (
    <div className="categories">
      <h3 id="dialog">お知らせ設定登録ダイアログ</h3>

      <h4>新規</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setAddResult({});
                setIsShowAddDialog(true);
              }}
            />
            <InformationManagementDialog
              isOpen={isShowAddDialog}
              messageOption={{
                headerLabelId: {
                  id: 'notification_add',
                  prefixId: 'DIALOG_TITLE',
                },
              }}
              inputOption={{
                inputType: 'add',
                information: {},
              }}
              onDecision={(v) => {
                setAddResult(v);
                setIsShowAddDialog(false);
              }}
              onCancel={() => {
                setAddResult({});
                setIsShowAddDialog(false);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">{JSON.stringify(addResult, null, 2)}</pre>
          </div>
        </div>
      </div>

      <h4>複写</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setCopyResult({});
                setIsShowCopyDialog(true);
              }}
            />
            <InformationManagementDialog
              isOpen={isShowCopyDialog}
              messageOption={{
                headerLabelId: {
                  id: 'notification_add',
                  prefixId: 'DIALOG_TITLE',
                },
              }}
              inputOption={{
                inputType: 'copy',
                information: {
                  subject: 'お知らせタイトル',
                  category: {
                    categoryName: 'A8000001',
                    systemName: 'B00',
                    displayNameLang: { ja: 'お知らせ' },
                    code: '1',
                  },
                  content: 'お知らせ内容',
                  // 複写する対象項目は利用画面側で調整する
                  // startAt: Long.fromNumber(
                  //   new Date('July 9, 2024').getTime()
                  // ).multiply(1000),
                  // endAt: Long.fromNumber(
                  //   new Date('September 9, 2024').getTime()
                  // ).multiply(1000),
                  requiredRead: true,
                },
              }}
              onDecision={(v) => {
                setCopyResult(v);
                setIsShowCopyDialog(false);
              }}
              onCancel={() => {
                setCopyResult({});
                setIsShowCopyDialog(false);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">{JSON.stringify(copyResult, null, 2)}</pre>
          </div>
        </div>
      </div>

      <h4>編集</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setEditResult({});
                setIsShowEditDialog(true);
              }}
            />
            <InformationManagementDialog
              isOpen={isShowEditDialog}
              messageOption={{
                headerLabelId: {
                  id: 'notification_add',
                  prefixId: 'DIALOG_TITLE',
                },
              }}
              inputOption={{
                inputType: 'edit',
                information: {
                  subject: 'お知らせタイトル',
                  category: {
                    categoryName: 'A8000001',
                    systemName: 'B00',
                    displayNameLang: { ja: 'お知らせ' },
                    code: '1',
                  },
                  content: 'お知らせ内容',
                  startAt: Long.fromNumber(
                    new Date('July 9, 2024').getTime()
                  ).multiply(1000),
                  endAt: Long.fromNumber(
                    new Date('September 9, 2024').getTime()
                  ).multiply(1000),
                  requiredRead: false,
                  updatedProperties: {
                    updatedBy: {
                      displayName: '田中太郎',
                      email: 'sample@p.centsys.jp',
                    },
                    updatedAt: Long.fromNumber(
                      Math.floor(new Date().getTime() / 1000)
                    ),
                  },
                },
              }}
              onDecision={(v) => {
                setEditResult(v);
                setIsShowEditDialog(false);
              }}
              onCancel={() => {
                setEditResult({});
                setIsShowEditDialog(false);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">{JSON.stringify(editResult, null, 2)}</pre>
          </div>
        </div>
      </div>

      <h4>表示</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setDisplayResult({});
                setIsShowDisplayDialog(true);
              }}
            />
            <InformationManagementDialog
              isOpen={isShowDisplayDialog}
              messageOption={{
                headerLabelId: {
                  id: 'notification_confirmation',
                  prefixId: 'DIALOG_TITLE',
                },
              }}
              inputOption={{
                inputType: 'display',
                information: {
                  subject: 'お知らせタイトル',
                  category: {
                    categoryName: 'A8000001',
                    systemName: 'B00',
                    displayNameLang: { ja: 'お知らせ' },
                    code: '1',
                  },
                  content: 'お知らせ内容',
                  startAt: Long.fromNumber(
                    new Date('July 9, 2024').getTime()
                  ).multiply(1000),
                  endAt: Long.fromNumber(
                    new Date('September 9, 2024').getTime()
                  ).multiply(1000),
                  requiredRead: true,
                  updatedProperties: {
                    updatedBy: {
                      displayName: '田中太郎',
                      email: 'sample@p.centsys.jp',
                    },
                    updatedAt: Long.fromNumber(
                      Math.floor(new Date().getTime() / 1000)
                    ),
                  },
                },
              }}
              onDecision={(v) => {
                setDisplayResult(v);
                setIsShowDisplayDialog(false);
              }}
              onCancel={() => {
                setDisplayResult({});
                setIsShowDisplayDialog(false);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">
              {JSON.stringify(displayResult, null, 2)}
            </pre>
          </div>
        </div>
      </div>
    </div>
  );
};
