import { useMemo, CSSProperties } from 'react';
import { Property } from '~/shared/services';
import { GetMessageComponent } from '~/shared/components/parts/Message/Message';
import {
  DateY as DateYComponent,
  DateYM as DateYMComponent,
  DateSuggest as DateSuggestComponent,
  DatesUnselectedOption,
  DatesValidateOption,
} from '~/shared/components/parts/Dates/Dates';
import { getProperty } from '../common';

export interface DatesProps {
  name: string;
  className?: string;
  value?: Date | string;
  unselectedOption?: DatesUnselectedOption;
  term?: number;
  disabled?: boolean;
  validator?: (v: string[]) => string[];
  validateOption?: DatesValidateOption;
  onChangeState?: (arg: Date | null) => void;
  labelId?: string;
  properties?: Property[];
  columns?: string[];
}

// サジェスト表示props
export interface DateSuggestProps {
  name: string;
  className?: string;
  value?: Date | null;
  disabled?: boolean;
  formatter?: (v: string) => string;
  validator?: (v: string) => string[];
  validateOption?: DatesValidateOption;
  onChangeState?: (arg: Date | null) => void;
  labelId?: string;
  properties?: Property[];
  columns?: string[];
}

export function DateY(props: DatesProps) {
  const [componentStyle, viewStyle, _disabled] = useMemo<
    [CSSProperties, CSSProperties, boolean]
  >(() => {
    return getProperty(props.name, props.columns, props.properties);
  }, [props.properties, props.columns, props.name]);
  const disabled = _disabled ? _disabled : props.disabled ?? false;
  return (
    <div style={viewStyle}>
      <span className="caption">
        {props.labelId && (
          <div
            className={`topping-label ${
              disabled ? 'disabled-topping-label' : ''
            }`}
          >
            <GetMessageComponent id={props.labelId} />
          </div>
        )}
      </span>
      <DateYComponent
        name={props.name}
        className={props.className}
        value={props.value}
        unselectedOption={props.unselectedOption}
        term={props.term}
        validator={props.validator}
        validateOption={props.validateOption}
        disabled={disabled}
        style={componentStyle}
        onChangeState={props.onChangeState}
      ></DateYComponent>
    </div>
  );
}

export function DateYM(props: DatesProps) {
  const [componentStyle, viewStyle, _disabled] = useMemo<
    [CSSProperties, CSSProperties, boolean]
  >(() => {
    return getProperty(props.name, props.columns, props.properties);
  }, [props.properties, props.columns, props.name]);
  const disabled = _disabled ? _disabled : props.disabled ?? false;
  return (
    <div style={viewStyle}>
      <span className="caption">
        {props.labelId && (
          <div className={props.validateOption?.required ? 'required ' : ''}>
            <div
              className={`topping-label ${
                disabled ? 'disabled-topping-label' : ''
              }`}
            >
              <GetMessageComponent id={props.labelId} />
            </div>
          </div>
        )}
      </span>
      <DateYMComponent
        name={props.name}
        className={props.className}
        value={props.value}
        unselectedOption={props.unselectedOption}
        term={props.term}
        validator={props.validator}
        validateOption={props.validateOption}
        disabled={disabled}
        style={componentStyle}
        onChangeState={props.onChangeState}
      ></DateYMComponent>
    </div>
  );
}

export function DateSuggest(props: DateSuggestProps) {
  const [componentStyle, viewStyle, _disabled] = useMemo<
    [CSSProperties, CSSProperties, boolean]
  >(() => {
    return getProperty(props.name, props.columns, props.properties);
  }, [props.properties, props.columns, props.name]);
  const disabled = _disabled ? _disabled : props.disabled ?? false;
  return (
    <div style={viewStyle}>
      <span className="caption">
        {props.labelId && (
          <div className={props.validateOption?.required ? 'required ' : ''}>
            <div
              className={`topping-label ${
                disabled ? 'disabled-topping-label' : ''
              }`}
            >
              <GetMessageComponent id={props.labelId} />
            </div>
          </div>
        )}
      </span>
      <DateSuggestComponent
        name={props.name}
        className={props.className}
        value={props.value}
        validator={props.validator}
        formatter={props.formatter}
        validateOption={props.validateOption}
        disabled={disabled}
        style={componentStyle}
        onChangeState={props.onChangeState}
      ></DateSuggestComponent>
    </div>
  );
}
