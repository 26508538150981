import { useState } from 'react';
import { CaptionButton } from '~/shared/components/ui/Button';
import { AdminAccountAddDialog } from '~/shared/components/ui/Dialog/AdminAccountAddDialog';

export const UISampleAdminAccountAddDialogs = () => {
  const [accountName, setAccountName] = useState('');
  const [email, setEmail] = useState('');
  const [isShowDialog, setIsShowDialog] = useState(false);

  return (
    <div className="categories">
      <h3 id="dialog">テナント管理者アカウント追加ダイアログ</h3>

      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setAccountName('');
                setEmail('');
                setIsShowDialog(true);
              }}
            />
            <AdminAccountAddDialog
              isOpen={isShowDialog}
              messageOption={{
                headerLabelId: {
                  id: 'adminAccountAdd',
                  prefixId: 'DIALOG_TITLE',
                },
              }}
              inputOption={{
                accountName,
                email,
              }}
              onDecision={(v) => {
                setAccountName(v.accountName || '');
                setEmail(v.email || '');
                setIsShowDialog(false);
              }}
              onCancel={() => {
                setAccountName('');
                setEmail('');
                setIsShowDialog(false);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">{`アカウント名：${accountName}, メールアドレス：${email}`}</pre>
          </div>
        </div>
      </div>
    </div>
  );
};
