import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface DiscardSurveyRequest {
  actionName: 'discardSurveyRequest';
  request: DiscardSurveyParam;
}
interface DiscardSurveyParam {
  surveyRequestIds: string[];
  comment?: string;
}

// 依頼破棄
export const discardSurveyRequest = async (
  worker: AppSaveWorker,
  req: DiscardSurveyParam
) => {
  const FullMethodName_DiscardSurveyRequest =
    'mtechnavi.api.survey.SurveySenderService/DiscardSurveyRequest';

  return await Promise.all(
    req.surveyRequestIds.map((surveyRequestId) =>
      worker.invokeListRequest<
        mtechnavi.api.survey.IDiscardSurveyRequestRequest,
        mtechnavi.api.survey.DiscardSurveyRequestResponse
      >(FullMethodName_DiscardSurveyRequest, {
        surveyRequestId,
        comment: req.comment,
      })
    )
  );
};
