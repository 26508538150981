import { useEffect, useState } from 'react';
import {
  ChangeStaffDialog,
  ChangeStaffDialogResult,
} from '~/shared/components/ui';
import { CaptionButton } from '~/shared/components/ui/Button';
import { useAuth } from '~/shared/contexts/AuthProvider';

export const UISampleChangeStaffDialogs = () => {
  const myUserId = useAuth().user?.userId || '';
  const [result, setResult] = useState<ChangeStaffDialogResult | null>();
  const [isShowChangeStaffDialog, setShowChangeStaffDialog] = useState(false);
  const [targetUserIds, setTargetUserIds] = useState<string[]>([]);
  const [componentDisplayName, setComponentDisplayName] = useState('');
  useEffect(() => {
    (async () => {
      const userOrganization = await window.App.services.ui.getUserOrganization(
        myUserId
      );
      const userIds: string[] = [];
      userOrganization.map((v) => {
        if (v.userId) {
          userIds.push(v.userId);
        }
      });
      setTargetUserIds(userIds);
      setComponentDisplayName('サンプル');
    })();
  }, [myUserId]);

  return (
    <div className="categories">
      <h3 id="dialog">担当変更ダイアログ</h3>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setResult(null);
                setShowChangeStaffDialog(true);
              }}
            />
            <ChangeStaffDialog
              isOpen={isShowChangeStaffDialog}
              targetUserIds={targetUserIds}
              componentDisplayName={componentDisplayName}
              onDecision={(r) => {
                setResult(r);
                setShowChangeStaffDialog(false);
              }}
              onCancel={() => {
                setShowChangeStaffDialog(false);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">
              {result && JSON.stringify(result, null, 2)}
            </pre>
          </div>
        </div>
      </div>
    </div>
  );
};
