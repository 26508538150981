/**
 * sharelib の汎用型に変換する処理
 */

import { mtechnavi, sharelib } from '~/shared/libs/clientsdk';
import { FullMethodName_ListUserAttributes } from '../fullMethodName';
import { FullMethodName_ListOrganizations } from '~/worker';

/**
 * 組織IDから組織（階層情報含む）に変換
 * @param organizationId
 * @returns Promise<sharelib.IOrganizationStructureReference | null>
 */
export async function convertOrganizationStructureReference(
  organizationId: string | undefined
): Promise<sharelib.IOrganizationStructureReference | null> {
  const result = (
    (await window.App.services.ui.worker.filter({
      action: 'reload',
      fullMethodName: FullMethodName_ListOrganizations,
      filter: {},
      sort: [],
    })) as mtechnavi.api.company.ListOrganizationsResponse
  ).items;

  // 該当するデータがなければ、表示上データが表示されていてもnullで登録する
  if (result.length === 0) {
    return null;
  }

  const item = result.find((item) => item.organizationId === organizationId);
  if (!item) {
    return null;
  }
  const resultItem: sharelib.IOrganizationStructureReference = {
    organizationId: item!.organizationId,
    code: item!.code,
    displayName: item?.displayName,
    displayNameShort: item!.displayNameShort,
    parentOrganization: null,
  };
  if (!item.parentOrganization?.organizationId) {
    return resultItem;
  }
  const parentOrganization = getParentOrganization(
    item?.parentOrganization?.organizationId,
    result,
    []
  );
  resultItem.parentOrganization = parentOrganization.reverse();
  return resultItem;
}

/**
 * 親組織を取得する再帰関数
 * @param parentOrganizationId 親組織ID
 * @param item 組織リスト
 * @param parents 親組織戻り値の入れ物
 * @returns sharelib.IOrganizationReference[]
 */
export function getParentOrganization(
  parentOrganizationId: string,
  item: mtechnavi.api.company.IOrganization[],
  parents: sharelib.IOrganizationReference[]
) {
  const parent = item.find((v) => v.organizationId === parentOrganizationId);
  if (parent) {
    parents.push({
      organizationId: parent.organizationId,
      code: parent?.code,
      displayName: parent.displayName,
      displayNameShort: parent.displayNameShort,
    });
    if (parent?.parentOrganization?.organizationId) {
      getParentOrganization(
        parent?.parentOrganization?.organizationId,
        item,
        parents
      );
    }
  }
  // 登録時はルート組織から０で始まるため、逆さにして返す。
  return parents;
}

/**
 * APIでUserReference型で登録する際に利用
 * @param email
 * @returns
 */
export async function convertUserReference(
  email: string
): Promise<sharelib.IUserReference | null> {
  const result = (await window.App.services.ui.worker.filter({
    action: 'reload',
    fullMethodName: FullMethodName_ListUserAttributes,
    filter: {
      'user.email': {
        $eq: email,
      },
    },
    sort: [],
  })) as mtechnavi.api.tenantadmin.ListUserAttributesResponse;
  // 該当するデータがなければ、表示上データが表示されていてもnullで登録する
  if (result.items.length === 0) {
    return null;
  }

  const item = result.items.shift();
  console.log(item);
  return {
    userId: item!.userAttributeId,
    email: item!.user!.email,
    displayName: item!.user!.displayName,
  };
}
