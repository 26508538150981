import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface SaveOcpFacilities {
  actionName: 'saveOcpFacilities';
  request: SaveOcpFacilitiesRequestParam;
}

interface SaveOcpFacilitiesRequestParam {
  businessUnitFacilitiess: mtechnavi.api.company.IBusinessUnitFacilities[];
  companyId: string;
}

export const saveOcpFacilities = async (
  worker: AppSaveWorker,
  req: SaveOcpFacilitiesRequestParam
) => {
  const ListMethodName =
    'mtechnavi.api.company.BusinessUnitManagementService/ListBusinessUnitFacilitiess';
  const createMethodName =
    'mtechnavi.api.company.BusinessUnitManagementService/CreateBusinessUnitFacilities';
  const updateMethodName =
    'mtechnavi.api.company.BusinessUnitManagementService/UpdateBusinessUnitFacilities';
  const deleteMethodName =
    'mtechnavi.api.company.BusinessUnitManagementService/DeleteBusinessUnitFacilities';

  const FacilitiesList = (await worker.invokeListRequest(ListMethodName, {
    companyIds: [req.companyId],
  })) as mtechnavi.api.company.IBusinessUnitFacilities[];

  const result = req.businessUnitFacilitiess.map((item) => {
    if (item.businessUnitFacilitiesId) {
      console.info('saveOcpFacilities updateMethodName');
      return worker.invokeListRequest(updateMethodName, {
        businessUnitFacilities: item,
      });
    } else {
      console.info('saveOcpFacilities createMethodName');
      return worker.invokeListRequest(createMethodName, {
        businessUnitFacilities: item,
      });
    }
  });
  await Promise.all(result);

  const deleteCheckList = req.businessUnitFacilitiess.map((e) => {
    return e.businessUnitFacilitiesId;
  });
  const deleteResult = FacilitiesList.map((facilities) => {
    if (!deleteCheckList.includes(facilities.businessUnitFacilitiesId)) {
      console.info('saveOcpFacilities deleteMethodName');
      return worker.invokeListRequest(deleteMethodName, {
        businessUnitFacilities: facilities,
      });
    }
  });
  await Promise.all(deleteResult);
};
