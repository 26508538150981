import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface DeleteBusinessUnitrankReception {
  actionName: 'deleteBusinessUnitRankReception';
  request: mtechnavi.api.company.IBusinessUnitRankReception;
}

// 品質管理削除
export const deleteBusinessUnitRankReception = async (
  worker: AppSaveWorker,
  request: mtechnavi.api.company.IBusinessUnitRankReception
) => {
  const fullMethodName =
    'mtechnavi.api.company.BusinessUnitManagementService/DeleteBusinessUnitRankReception';

  await worker.invokeListRequest<
    mtechnavi.api.company.IDeleteBusinessUnitRankReceptionRequest,
    void
  >(fullMethodName, {
    businessUnitRankReception: request,
  });
};
