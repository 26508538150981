import React, { useEffect, useRef, useState } from 'react';
import Long from 'long';
import { mtechnavi } from '~/shared/libs/clientsdk';
import {
  CheckboxItem,
  Container,
  GetMessageWithIntl,
  LoadingIcon,
  Radio,
  RadioItem,
  Toast,
  error,
} from '~/shared/components';
import { ViewId, getExceptionMessage, toCommaTypeString } from '~/shared/utils';
import {
  Checkbox,
  DataFilterbox,
  DataFilterboxItem,
  Textbox,
} from '~/shared/components/ui';
import { PopUpTabList, SelectedPopUpListItem } from './parts/PopUpTabList';
import {
  CaptionButton,
  IconWithCaptionButton,
} from '~/shared/components/ui/Button';
import {
  ConditionTreeDialog,
  ConditionTreeDialogRef,
} from './parts/ConditionTreeDialog';
import { useIntl } from 'react-intl';
import './CompanySearch.css';
import { CategorizedList } from '~/shared/components/ui/CategorizedCheckList';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  CompanySearchCondition,
  CompanySearchLocationState,
  ConditionDetail,
  extract1stLevelCategories,
  isCompanySearchLocationState,
  makeRequestFilter,
  saveCompanySearchState,
} from '../util';
import { BusinessUnitAttributes, convertToCategorizedList } from '../converter';
import { AttributeConditionItems } from './parts/AttributeConditionItems';
import { validateOnlyNumber } from '~/shared/components/parts/validator';
import { useAuth } from '~/shared/contexts/AuthProvider';

type ConditionTreeType =
  | 'region'
  | 'processingSkill'
  | 'facility'
  | 'material'
  | 'certificate'
  | 'importance'
  | 'industry';
interface ConditionTreeDialogInfo {
  type?: ConditionTreeType;
  editIndex?: number;
}

const preconditionItems: CheckboxItem[] = [
  { displayName: '取引先前提条件（基本）', value: 'A' },
  { displayName: '取引先前提条件（センサー）', value: 'B' },
  { displayName: '取引先前提条件（プラグ）', value: 'C' },
  { displayName: '取引先前提条件（xxxx）', value: 'D' },
];

const salesScaleItems: DataFilterboxItem[] = [
  { displayName: '500万', value: '5000000' },
  { displayName: '1000万', value: '10000000' },
  { displayName: '5000万', value: '50000000' },
  { displayName: '1億', value: '100000000' },
];

// 分類で分割するためのカテゴリID
const REGION_ATC_ID = 'ATC-1'; // 地域
const PROCESSING_ATC_ID = 'ATC-48'; // 加工技術
const FACILITY_ATC_ID = 'ATC-123'; // 設備
const MATERIAL_ATC_ID = 'ATC-129'; // 素材
const CERTIFICATE_ATC_ID = 'ATC-135'; // 取得認証
const IMPORTANCE_ATC_ID = 'ATC-154'; // 重視ポイント
const INDUSTRY_ATC_ID = 'ATC-166'; // 業種

const VIEW_ID: ViewId = 'POC_COMPANY_SEARCH';
const LIST_VIEW_ID: ViewId = 'POC_COMPANY_LIST';
export const CompanySearch = () => {
  const myEmail = useAuth().user?.email ?? '';
  const intl = useIntl();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [isLoading, setLoading] = useState(false);
  const [hitCount, setHitCount] = useState<Long | null>(null);

  const conditionTreeDialogRef = useRef<ConditionTreeDialogRef>(null);

  const [regionData, setRegionData] = useState<CategorizedList>([]);
  const [processingSkillData, setProcessingSkillData] =
    useState<CategorizedList>([]);
  const [facilityData, setFacilityData] = useState<CategorizedList>([]);
  const [materialData, setMaterialData] = useState<CategorizedList>([]);
  const [certificateData, setCertificateData] = useState<CategorizedList>([]);
  const [importanceData, setImportanceData] = useState<CategorizedList>([]);
  const [industryData, setIndustryData] = useState<CategorizedList>([]);

  // 検索条件
  const [selectedPrecondition, setSelectedPrecondition] = useState<string[]>(
    []
  );
  const [keyword, setKeyword] = useState('');
  const [regionConditions, setRegionConditions] = useState<ConditionDetail[]>(
    []
  );
  const [processingConditions, setProcessingConditions] = useState<
    ConditionDetail[]
  >([]);
  const [facilityConditions, setFacilityConditions] = useState<
    ConditionDetail[]
  >([]);
  const [materialConditions, setMaterialConditions] = useState<
    ConditionDetail[]
  >([]);
  const [certificateConditions, setCertificateConditions] = useState<
    ConditionDetail[]
  >([]);
  const [importanceConditions, setImportanceConditions] = useState<
    ConditionDetail[]
  >([]);
  const [industryConditions, setIndustryConditions] = useState<
    ConditionDetail[]
  >([]);
  const [selectedSalesScale, setSelectedSalesScale] = useState<
    DataFilterboxItem[]
  >([]);

  // 検索条件(設備固定項目)
  const [modelNumber, setModelNumber] = useState<string>('');
  const [sizeX, setSizeX] = useState<string>('');
  const [sizeY, setSizeY] = useState<string>('');
  const [sizeMM, setSizeMM] = useState<string>('');
  const [unit, setUnit] = useState<string>('sizeZ');

  // 編集制御
  const [editInfo, setEditInfo] = useState<ConditionTreeDialogInfo>({});

  const sizeType: RadioItem[] = [
    {
      displayName: GetMessageWithIntl(intl, {
        id: 'sizeZ',
        prefixId: `${VIEW_ID}.facility`,
      }),
      value: 'sizeZ',
    },
    {
      displayName: GetMessageWithIntl(intl, {
        id: 'sizeT',
        prefixId: `${VIEW_ID}.facility`,
      }),
      value: 'sizeT',
    },
    {
      displayName: GetMessageWithIntl(intl, {
        id: 'diameter',
        prefixId: `${VIEW_ID}.facility`,
      }),
      value: 'diameter',
    },
  ];

  // ラベルテキスト
  const labelAddCondition = GetMessageWithIntl(intl, {
    viewId: VIEW_ID,
    id: 'add_condition',
  });
  const labelSearchByProcessing = GetMessageWithIntl(intl, {
    viewId: VIEW_ID,
    id: 'search_by_processing',
  });
  const labelSearchByMaterial = GetMessageWithIntl(intl, {
    viewId: VIEW_ID,
    id: 'search_by_material',
  });
  const labeSearchByFacility = GetMessageWithIntl(intl, {
    viewId: VIEW_ID,
    id: 'search_by_facility',
  });
  const labelSearchByRegion = GetMessageWithIntl(intl, {
    viewId: VIEW_ID,
    id: 'search_by_region',
  });

  const getConditionSetterByType = (type?: ConditionTreeType) => {
    switch (type) {
      case 'region':
        return setRegionConditions;
      case 'processingSkill':
        return setProcessingConditions;
      case 'facility':
        return setFacilityConditions;
      case 'material':
        return setMaterialConditions;
      case 'certificate':
        return setCertificateConditions;
      case 'importance':
        return setImportanceConditions;
      case 'industry':
        return setIndustryConditions;
      default:
        console.info(`unknown setter type:[${type}]`);
        return;
    }
  };

  const getConditionByType = (type?: ConditionTreeType) => {
    switch (type) {
      case 'region':
        return regionConditions;
      case 'processingSkill':
        return processingConditions;
      case 'facility':
        return facilityConditions;
      case 'material':
        return materialConditions;
      case 'certificate':
        return certificateConditions;
      case 'importance':
        return importanceConditions;
      case 'industry':
        return industryConditions;
      default:
        console.info(`unknown value type:[${type}]`);
        return;
    }
  };

  const handleSelectPurpose = (item: SelectedPopUpListItem) => {
    switch (item.categoryId) {
      case 'processingSkill':
        handleConditionCheckAdd(
          item.categoryId,
          processingSkillData,
          item.value
        );
        break;
      case 'material':
        handleConditionCheckAdd(item.categoryId, materialData, item.value);
        break;
      case 'facility':
        handleConditionCheckAdd(item.categoryId, facilityData, item.value);
        break;
      case 'region':
        handleConditionCheckAdd(item.categoryId, regionData, item.value);
        break;
    }
  };

  // 前提条件変更
  const handleChangePrecondition = (checkedId: string[]) => {
    setSelectedPrecondition(checkedId);
    setHitCount(null);
  };

  // キーワード変更
  const handleChangeKeyword = (value: string) => {
    setKeyword(value);
    setHitCount(null);
  };

  // 分類条件ダイアログの完了処理
  const handleConditionTreeComplete = (newCondition: ConditionDetail) => {
    // 対応する分類の setter と旧条件を取得
    const setter = getConditionSetterByType(editInfo.type);
    const oldConditions = getConditionByType(editInfo.type);
    if (!setter || !oldConditions) {
      return;
    }

    if (editInfo.editIndex === undefined) {
      // 追加
      setter([...oldConditions, newCondition]);
    } else {
      // 編集
      setter(
        oldConditions?.map((condition, index) =>
          editInfo.editIndex === index ? newCondition : condition
        )
      );
    }
    setHitCount(null);
  };

  // 分類条件ダイアログの件数再計算処理
  const handleCheckCount = async (newCondition: ConditionDetail) => {
    const allConditions: CompanySearchCondition = getInputConditions(); // 保持中の条件を書き換えてしまうと困るので deep copy を利用する
    const key = getConditionKey(editInfo.type);
    if (key && Array.isArray(allConditions[key])) {
      if (editInfo.editIndex === undefined) {
        // 追加
        allConditions[key] = [...(allConditions[key] || []), newCondition];
      } else {
        // 編集
        allConditions[key] = allConditions[key]?.map((condition, index) =>
          editInfo.editIndex === index ? newCondition : condition
        );
      }
    }
    const filter = makeRequestFilter(allConditions, intl);
    return fetchCount(filter);
  };
  const getConditionKey = (
    type?: ConditionTreeType
  ):
    | keyof Omit<
        CompanySearchCondition,
        'keywords' | 'facilitySpecConditions' | 'selectedSalesScale'
      >
    | undefined => {
    switch (type) {
      case 'region':
        return 'regionConditions';
      case 'processingSkill':
        return 'regionConditions';
      case 'facility':
        return 'facilityConditions';
      case 'material':
        return 'materialConditions';
      case 'certificate':
        return 'certificateConditions';
      case 'importance':
        return 'importanceConditions';
      case 'industry':
        return 'industryConditions';
      default:
        console.info(`unknown category type:[${type}]`);
        return;
    }
  };

  // 条件の追加・編集・削除
  const handleConditionCheckAdd = (
    type: ConditionTreeType,
    treeData: CategorizedList,
    focusId?: string
  ) => {
    setEditInfo({ type });
    conditionTreeDialogRef.current?.open(treeData, undefined, focusId);
  };
  const handleConditionCheckEdit = (
    type: ConditionTreeType,
    treeData: CategorizedList,
    editIndex: number,
    editData: ConditionDetail
  ) => {
    setEditInfo({ type, editIndex });
    conditionTreeDialogRef.current?.open(treeData, editData);
  };
  const handleConditionDelete = (
    dispatch: React.Dispatch<React.SetStateAction<ConditionDetail[]>>,
    data: ConditionDetail[],
    deleteIndex: number
  ) => {
    dispatch(data?.filter((condition, index) => deleteIndex !== index));
    setHitCount(null);
  };
  const handleToggleCheck = (
    dispatch: React.Dispatch<React.SetStateAction<ConditionDetail[]>>,
    data: ConditionDetail[],
    editIndex: number,
    targetId: string
  ) => {
    dispatch(
      data?.map((item, index) => {
        if (index !== editIndex) {
          return item;
        }
        return {
          ...item,
          conditionItems: item.conditionItems.map((item) => {
            if (item.item.id !== targetId) {
              return item;
            }
            return {
              ...item,
              checked: !item.checked,
            };
          }),
        };
      })
    );
    setHitCount(null);
  };

  // 件数再計算ボタン処理
  const handleFetchCount = async () => {
    const dataFilter = getFilterByInputConditions();
    const count = await fetchCount(dataFilter);
    setHitCount(count);
  };

  // 件数取得処理
  const fetchCount = async (
    dataFilter: mtechnavi.api.analysis.ListCompanysRequest.IDataFilter
  ): Promise<Long> => {
    setLoading(true);
    try {
      const result =
        await window.App.services.searchCompanyService.listCompanys({
          dataFilter,
          limit: Long.fromInt(1), // total さえ取れれば良いので 1 件だけ取得
        });
      return Long.fromValue(result.total);
    } catch (err) {
      error(getExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = () => {
    const state: CompanySearchLocationState = {
      conditions: getInputConditions(),
      pageSetting: {
        pageSize: '20',
        pageNumber: '1',
        checkedValues: [],
      },
    };
    saveCompanySearchState(LIST_VIEW_ID, state, myEmail);

    navigate('/company/list', {
      state,
    });
  };

  const handleReset = () => {
    setSelectedPrecondition([]);
    setKeyword('');
    setRegionConditions([]);
    setProcessingConditions([]);
    setFacilityConditions([]);
    setModelNumber('');
    setSizeX('');
    setSizeY('');
    setSizeMM('');
    setUnit('sizeZ');
    setMaterialConditions([]);
    setCertificateConditions([]);
    setImportanceConditions([]);
    setIndustryConditions([]);
    setSelectedSalesScale([]);
    setHitCount(null);
  };

  const getInputConditions = (): CompanySearchCondition => {
    return {
      keywords: keyword ? keyword.trim().split(' ') : [],
      regionConditions,
      processingConditions,
      facilityConditions,
      materialConditions,
      certificateConditions,
      importanceConditions,
      industryConditions,
      selectedSalesScale,
      facilitySpecConditions: {
        modelNumber,
        sizeX,
        sizeY,
        unit,
        sizeMM,
      },
    };
  };

  const getFilterByInputConditions = () => {
    return makeRequestFilter(getInputConditions(), intl);
  };

  useEffect(() => {
    setLoading(true);
    (async () => {
      const attributesRawData = await fetch(
        '/assets/conditions/BusinessUnitAttribute.json'
      );
      const attributes =
        (await attributesRawData.json()) as BusinessUnitAttributes;
      attributes.children.forEach((item) => {
        const categorizedItem = convertToCategorizedList(item.children);
        switch (item.attributeCategoryId) {
          case REGION_ATC_ID:
            setRegionData(categorizedItem);
            return;
          case PROCESSING_ATC_ID:
            setProcessingSkillData(categorizedItem);
            return;
          case FACILITY_ATC_ID:
            setFacilityData(categorizedItem);
            return;
          case MATERIAL_ATC_ID:
            setMaterialData(categorizedItem);
            return;
          case CERTIFICATE_ATC_ID:
            setCertificateData(categorizedItem);
            return;
          case IMPORTANCE_ATC_ID:
            setImportanceData(categorizedItem);
            return;
          case INDUSTRY_ATC_ID:
            setIndustryData(categorizedItem);
            return;
        }
      });
      setLoading(false);
    })();
  }, []);

  // 検索条件を復元する
  useEffect(() => {
    if (!isCompanySearchLocationState(state)) {
      return;
    }
    const { conditions } = state;
    setSelectedPrecondition([]);
    setKeyword(conditions?.keywords?.join(' ') || '');
    setRegionConditions(conditions?.regionConditions || []);
    setProcessingConditions(conditions?.processingConditions || []);
    setFacilityConditions(conditions?.facilityConditions || []);
    setModelNumber(conditions?.facilitySpecConditions?.modelNumber || '');
    setSizeX(conditions?.facilitySpecConditions?.sizeX || '');
    setSizeY(conditions?.facilitySpecConditions?.sizeY || '');
    setSizeMM(conditions?.facilitySpecConditions?.sizeMM || '');
    setUnit(conditions?.facilitySpecConditions?.unit || 'sizeZ');
    setMaterialConditions(conditions?.materialConditions || []);
    setCertificateConditions(conditions?.certificateConditions || []);
    setImportanceConditions(conditions?.importanceConditions || []);
    setIndustryConditions(conditions?.industryConditions || []);
    setSelectedSalesScale(conditions?.selectedSalesScale || []);
  }, [state, intl]);

  return (
    <>
      <Container viewId={VIEW_ID}>
        <div className="CompanySearch">
          <h2 className="section-head">前提条件をつける</h2>
          <div className="condition-section">
            <Checkbox
              className="precondition"
              name="basic"
              columns={['basic']}
              items={preconditionItems}
              value={selectedPrecondition}
              onChangeState={handleChangePrecondition}
            />
          </div>
          <h2 className="section-head">目的から探す</h2>
          <div className="condition-section">
            <PopUpTabList
              tabs={[
                {
                  label: labelSearchByProcessing,
                  categoryId: 'processingSkill',
                  items: extract1stLevelCategories(processingSkillData),
                },
                {
                  label: labelSearchByMaterial,
                  categoryId: 'material',
                  items: extract1stLevelCategories(materialData),
                },
                {
                  label: labeSearchByFacility,
                  categoryId: 'facility',
                  items: extract1stLevelCategories(facilityData),
                },
                {
                  label: labelSearchByRegion,
                  categoryId: 'region',
                  items: extract1stLevelCategories(regionData),
                },
              ]}
              onClick={(v) => handleSelectPurpose(v)}
            />
          </div>
          <h2 className="section-head">詳細条件</h2>
          <div className="condition-section">
            <dl className="detail-condition">
              {/* キーワード */}
              <dt>
                {GetMessageWithIntl(intl, { viewId: VIEW_ID, id: 'keyword' })}
              </dt>
              <dd>
                <Textbox
                  className="keyword"
                  name="keyword"
                  type="text"
                  labelId=""
                  columns={['keyword']}
                  value={keyword}
                  onChangeState={handleChangeKeyword}
                />
              </dd>

              {/* 地域 */}
              <dt>
                {GetMessageWithIntl(intl, { viewId: VIEW_ID, id: 'region' })}
              </dt>
              <dd>
                <AttributeConditionItems
                  viewId={VIEW_ID}
                  conditions={regionConditions}
                  onEdit={(index, condition) =>
                    handleConditionCheckEdit(
                      'region',
                      regionData,
                      index,
                      condition
                    )
                  }
                  onDelete={(index) =>
                    handleConditionDelete(
                      setRegionConditions,
                      regionConditions,
                      index
                    )
                  }
                  onChange={(index, toggleId) =>
                    handleToggleCheck(
                      setRegionConditions,
                      regionConditions,
                      index,
                      toggleId
                    )
                  }
                />
                <IconWithCaptionButton
                  className="condition-add-button"
                  name=""
                  iconType="add"
                  caption={labelAddCondition}
                  onClick={() => handleConditionCheckAdd('region', regionData)}
                />
              </dd>

              {/* 加工技術 */}
              <dt>
                {GetMessageWithIntl(intl, {
                  viewId: VIEW_ID,
                  id: 'processingSkill',
                })}
              </dt>
              <dd>
                <AttributeConditionItems
                  viewId={VIEW_ID}
                  conditions={processingConditions}
                  onEdit={(index, condition) =>
                    handleConditionCheckEdit(
                      'processingSkill',
                      processingSkillData,
                      index,
                      condition
                    )
                  }
                  onDelete={(index) =>
                    handleConditionDelete(
                      setProcessingConditions,
                      processingConditions,
                      index
                    )
                  }
                  onChange={(index, toggleId) =>
                    handleToggleCheck(
                      setProcessingConditions,
                      processingConditions,
                      index,
                      toggleId
                    )
                  }
                />
                <IconWithCaptionButton
                  className="condition-add-button"
                  name=""
                  iconType="add"
                  caption={labelAddCondition}
                  onClick={() =>
                    handleConditionCheckAdd(
                      'processingSkill',
                      processingSkillData
                    )
                  }
                />
              </dd>

              {/* 設備 */}
              <dt>
                {GetMessageWithIntl(intl, { viewId: VIEW_ID, id: 'facility' })}
              </dt>
              <dd>
                <div className="input-line">
                  <div className="item-group-100">
                    <div className="w-66">
                      {/* 型式 */}
                      <Textbox
                        name="modelNumber"
                        type="text"
                        labelId="BusinessUnitFacilityDialog.modelNumber"
                        columns={['modelNumber']}
                        value={modelNumber}
                        onChangeState={(value) => {
                          setHitCount(null);
                          setModelNumber(value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="input-line">
                  <div className="item-group-100">
                    <div className="w-50">
                      {/* 加工サイズX */}
                      <div className="input-with-unit">
                        <Textbox
                          name="sizeX"
                          type="text"
                          labelId={`${VIEW_ID}.facility.sizeX`}
                          className="right"
                          value={sizeX}
                          columns={['sizeX']}
                          validator={validateOnlyNumber(intl)}
                          onChangeState={(value) => {
                            setHitCount(null);
                            setSizeX(value);
                          }}
                        />
                        <span>mm以上</span>
                      </div>
                    </div>
                    <div className="w-50">
                      {/* 加工サイズY */}
                      <div className="input-with-unit">
                        <Textbox
                          name="sizeY"
                          type="text"
                          labelId={`${VIEW_ID}.facility.sizeY`}
                          className="right"
                          value={sizeY}
                          columns={['sizeY']}
                          validator={validateOnlyNumber(intl)}
                          onChangeState={(value) => {
                            setHitCount(null);
                            setSizeY(value);
                          }}
                        />
                        <span>mm以上</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="input-line">
                  <div className="item-group-100">
                    <div className="w-50">
                      {/* 加工サイズmmZTφ ラジオ */}
                      <Radio
                        name="unit"
                        className="sizeUnit"
                        value={unit}
                        items={sizeType}
                        validateOption={{
                          required: sizeMM ? true : false,
                        }}
                        onChangeState={(value) => {
                          setHitCount(null);
                          setUnit(value);
                        }}
                      />
                      <div className="input-with-unit">
                        <Textbox
                          name="sizeMM"
                          type="text"
                          className="right"
                          value={sizeMM}
                          columns={['sizeMM']}
                          validator={validateOnlyNumber(intl)}
                          onChangeState={(value) => {
                            setHitCount(null);
                            setSizeMM(value);
                          }}
                        />
                        <span>mm以上</span>
                      </div>
                    </div>
                  </div>
                </div>

                <AttributeConditionItems
                  viewId={VIEW_ID}
                  conditions={facilityConditions}
                  onEdit={(index, condition) =>
                    handleConditionCheckEdit(
                      'facility',
                      facilityData,
                      index,
                      condition
                    )
                  }
                  onDelete={(index) =>
                    handleConditionDelete(
                      setFacilityConditions,
                      facilityConditions,
                      index
                    )
                  }
                  onChange={(index, toggleId) =>
                    handleToggleCheck(
                      setFacilityConditions,
                      facilityConditions,
                      index,
                      toggleId
                    )
                  }
                />

                <IconWithCaptionButton
                  className="condition-add-button"
                  name=""
                  iconType="add"
                  caption={labelAddCondition}
                  onClick={() =>
                    handleConditionCheckAdd('facility', facilityData)
                  }
                />
              </dd>

              {/* 材料 */}
              <dt>
                {GetMessageWithIntl(intl, {
                  viewId: VIEW_ID,
                  id: 'material',
                })}
              </dt>
              <dd>
                <AttributeConditionItems
                  viewId={VIEW_ID}
                  conditions={materialConditions}
                  onEdit={(index, condition) =>
                    handleConditionCheckEdit(
                      'material',
                      materialData,
                      index,
                      condition
                    )
                  }
                  onDelete={(index) =>
                    handleConditionDelete(
                      setMaterialConditions,
                      materialConditions,
                      index
                    )
                  }
                  onChange={(index, toggleId) =>
                    handleToggleCheck(
                      setMaterialConditions,
                      materialConditions,
                      index,
                      toggleId
                    )
                  }
                />
                <IconWithCaptionButton
                  className="condition-add-button"
                  name=""
                  iconType="add"
                  caption={labelAddCondition}
                  onClick={() =>
                    handleConditionCheckAdd('material', materialData)
                  }
                />
              </dd>

              {/* 取得認証 */}
              <dt>
                {GetMessageWithIntl(intl, {
                  viewId: VIEW_ID,
                  id: 'certificate',
                })}
              </dt>
              <dd>
                <AttributeConditionItems
                  viewId={VIEW_ID}
                  conditions={certificateConditions}
                  onEdit={(index, condition) =>
                    handleConditionCheckEdit(
                      'certificate',
                      certificateData,
                      index,
                      condition
                    )
                  }
                  onDelete={(index) =>
                    handleConditionDelete(
                      setCertificateConditions,
                      certificateConditions,
                      index
                    )
                  }
                  onChange={(index, toggleId) =>
                    handleToggleCheck(
                      setCertificateConditions,
                      certificateConditions,
                      index,
                      toggleId
                    )
                  }
                />
                <IconWithCaptionButton
                  className="condition-add-button"
                  name=""
                  iconType="add"
                  caption={labelAddCondition}
                  onClick={() =>
                    handleConditionCheckAdd('certificate', certificateData)
                  }
                />
              </dd>

              {/* 重視ポイント */}
              <dt>
                {GetMessageWithIntl(intl, {
                  viewId: VIEW_ID,
                  id: 'importance',
                })}
              </dt>
              <dd>
                <AttributeConditionItems
                  viewId={VIEW_ID}
                  conditions={importanceConditions}
                  onEdit={(index, condition) =>
                    handleConditionCheckEdit(
                      'importance',
                      importanceData,
                      index,
                      condition
                    )
                  }
                  onDelete={(index) =>
                    handleConditionDelete(
                      setImportanceConditions,
                      importanceConditions,
                      index
                    )
                  }
                  onChange={(index, toggleId) =>
                    handleToggleCheck(
                      setImportanceConditions,
                      importanceConditions,
                      index,
                      toggleId
                    )
                  }
                />
                <IconWithCaptionButton
                  className="condition-add-button"
                  name=""
                  iconType="add"
                  caption={labelAddCondition}
                  onClick={() =>
                    handleConditionCheckAdd('importance', importanceData)
                  }
                />
              </dd>

              {/* 売上規模 */}
              <dt>
                {GetMessageWithIntl(intl, {
                  viewId: VIEW_ID,
                  id: 'sales_scale',
                })}
              </dt>
              <dd>
                <div className="sales-scale-selector">
                  <DataFilterbox
                    className="sales-scale"
                    data={salesScaleItems}
                    value={selectedSalesScale}
                    name="salesScale"
                    columns={['salesScale']}
                    onChangeState={(value) => {
                      setHitCount(null);
                      setSelectedSalesScale(value);
                    }}
                  />
                  <span className="suffix">円以上</span>
                </div>
              </dd>

              {/* 業種 */}
              <dt>
                {GetMessageWithIntl(intl, {
                  viewId: VIEW_ID,
                  id: 'industry',
                })}
              </dt>
              <dd>
                <AttributeConditionItems
                  viewId={VIEW_ID}
                  conditions={industryConditions}
                  onEdit={(index, condition) =>
                    handleConditionCheckEdit(
                      'industry',
                      industryData,
                      index,
                      condition
                    )
                  }
                  onDelete={(index) =>
                    handleConditionDelete(
                      setIndustryConditions,
                      industryConditions,
                      index
                    )
                  }
                  onChange={(index, toggleId) =>
                    handleToggleCheck(
                      setIndustryConditions,
                      industryConditions,
                      index,
                      toggleId
                    )
                  }
                />
                <IconWithCaptionButton
                  className="condition-add-button"
                  name=""
                  iconType="add"
                  caption={labelAddCondition}
                  onClick={() =>
                    handleConditionCheckAdd('industry', industryData)
                  }
                />
              </dd>
            </dl>
          </div>

          <div className="footer">
            <div className="footer-contents">
              <span className="count-label">対象企業数</span>
              <CaptionButton
                name="fetchCount"
                className={`fetch-count-button ${
                  hitCount === null ? 're-calc' : ''
                }`}
                caption={
                  hitCount !== null
                    ? `${toCommaTypeString(`${hitCount}`)}社`
                    : '再計算'
                }
                onClick={handleFetchCount}
              />
              <CaptionButton
                buttonType="basic"
                name="search"
                caption="検索する"
                onClick={handleSearch}
              />
              <CaptionButton
                name="reset"
                className="fetch-reset-button"
                caption="条件を解除する"
                onClick={handleReset}
              />
            </div>
          </div>
        </div>
        <ConditionTreeDialog
          ref={conditionTreeDialogRef}
          messageOption={{
            headerLabelId: { prefixId: VIEW_ID, id: `select_${editInfo.type}` },
            labelEveryMatch: {
              viewId: VIEW_ID,
              id: 'every_match',
            },
            labelSomeMatch: {
              viewId: VIEW_ID,
              id: 'some_match',
            },
          }}
          onDecision={handleConditionTreeComplete}
          onCheckCount={handleCheckCount}
        />
      </Container>
      <Toast />
      {isLoading && <LoadingIcon />}
    </>
  );
};
