import { AssetInfo } from './CommentPaneProvider';
import { CommentResourceId } from '../Viewer';
import { FullMethodName_ListThreads } from '~/shared/utils';

export const getAssetInfoListByResourceIds = async (
  resourceIdList: CommentResourceId[]
) => {
  if (!resourceIdList || resourceIdList.length <= 0) {
    return;
  }
  const assetIdList = resourceIdList?.flatMap(([, assetIdList]) => assetIdList);
  const res = await window.App.services.assetInventory.listAssets({
    ids: assetIdList,
  });
  return resourceIdList?.flatMap(([resourceId, assetIdList]) => {
    return assetIdList.reduce((prev, assetId) => {
      const asset = res.items.find((item) => item.assetId == assetId);
      if (!asset) {
        return prev;
      }
      const isDuplicated = prev.some(
        (v) => v.resourceId === resourceId && v.asset === asset
      );
      if (!isDuplicated) {
        prev.push({ resourceId, asset });
      }
      return prev;
    }, [] as AssetInfo[]);
  });
};

export const getThreadListByResourceWithAsset = async (
  baseThreadId: string,
  resourceType: string,
  resourceId: string,
  assetId: string
) => {
  return window.App.services.ui.worker.filter({
    action: 'reload',
    fullMethodName: FullMethodName_ListThreads,
    requestBody: {
      baseThreadId,
      resourceReference: {
        typeName: resourceType,
        resourceId: resourceId,
      },
      associations: [assetId],
    },
    filter: {},
    sort: [
      {
        createdAt: 'desc',
      },
    ],
  });
};
