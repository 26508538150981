import { IntlShape } from 'react-intl';
import {
  CategorizedList,
  CheckItem,
} from '~/shared/components/ui/CategorizedCheckList';
import { mtechnavi } from '~/shared/libs/clientsdk';
import Long from 'long';
import { convertMultipleTypeToFilterType } from './converter';
import { DataFilterboxItem, ListViewPageSetting } from '~/shared/components/ui';
import { BaseViewOption, ViewId, getLocalStorageKey } from '~/shared/utils';
import { GetMessageWithIntl } from '~/shared/components';

export type MultipleType = 'and' | 'or';
export interface ConditionItem {
  item: CheckItem;
  checked: boolean;
}
export interface ConditionDetail {
  multipleType: MultipleType;
  conditionItems: ConditionItem[];
}

// 検索条件を保持するための型
export interface CompanySearchCondition {
  keywords?: string[];
  regionConditions?: ConditionDetail[];
  processingConditions?: ConditionDetail[];
  facilitySpecConditions?: FacilitySpec;
  facilityConditions?: ConditionDetail[];
  materialConditions?: ConditionDetail[];
  certificateConditions?: ConditionDetail[];
  importanceConditions?: ConditionDetail[];
  industryConditions?: ConditionDetail[];
  selectedSalesScale?: DataFilterboxItem[];
}
export interface FacilitySpec {
  modelNumber?: string;
  sizeX?: string;
  sizeY?: string;
  sizeMM?: string;
  unit?: string;
}

// 条件と結果を持ち回るための型
export interface CompanySearchLocationState {
  conditions?: CompanySearchCondition;
  listData?: mtechnavi.api.analysis.IViewCompany[];
  pageSetting?: Omit<ListViewPageSetting, 'filter' | 'sort'>;
  baseViewOption?: BaseViewOption;
}

export const isCompanySearchLocationState = (
  state?: unknown | null
): state is CompanySearchLocationState => {
  return (
    !!state &&
    (Object.hasOwn(state as object, 'conditions') ||
      Object.hasOwn(state as object, 'pageSetting'))
  );
};

export const makeRequestFilter = (
  condition: CompanySearchCondition,
  intl: IntlShape
): mtechnavi.api.analysis.ListCompanysRequest.IDataFilter => {
  const {
    keywords,
    regionConditions,
    processingConditions,
    facilityConditions,
    materialConditions,
    certificateConditions,
    importanceConditions,
    industryConditions,
    selectedSalesScale,
    facilitySpecConditions,
  } = condition;
  const filters: mtechnavi.api.analysis.ListCompanysRequest.IDataFilter = {};
  // キーワード
  if (keywords && keywords.length > 0) {
    filters.keywordFilters = [
      {
        names: keywords,
        type: mtechnavi.api.analysis.ListCompanysRequest.DataFilter.FilterType
          .OR,
      },
    ];
  }
  // 地域(カテゴリチェック)
  if (regionConditions && regionConditions.length > 0) {
    filters.regionFilters = regionConditions
      .map((item) => ({
        names: item.conditionItems
          .filter((conditionItems) => conditionItems.checked)
          .map(
            (condition) =>
              (condition.item.displayNameLang &&
                condition.item.displayNameLang[intl.locale]) ||
              ''
          ),
        type: convertMultipleTypeToFilterType(item.multipleType),
      }))
      .filter((item) => item.names.length > 0);
  }
  // 加工技術(カテゴリチェック)
  if (processingConditions && processingConditions.length > 0) {
    filters.skillFilters = processingConditions
      .map((item) => ({
        skillIds: item.conditionItems
          .filter((conditionItems) => conditionItems.checked)
          .map((condition) => condition.item.id || ''),
        type: convertMultipleTypeToFilterType(item.multipleType),
      }))
      .filter((item) => item.skillIds.length > 0);
  }
  // 設備(固定項目)
  const facilitiesFilter: mtechnavi.api.analysis.ListCompanysRequest.DataFilter.IFacilitiesFilter =
    {};
  if (facilitySpecConditions?.modelNumber) {
    facilitiesFilter.modelNumber = facilitySpecConditions.modelNumber;
  }
  if (facilitySpecConditions?.sizeX) {
    facilitiesFilter.sizeX = Long.fromString(facilitySpecConditions.sizeX);
  }
  if (facilitySpecConditions?.sizeY) {
    facilitiesFilter.sizeY = Long.fromString(facilitySpecConditions.sizeY);
  }
  if (facilitySpecConditions?.sizeMM && facilitySpecConditions?.unit) {
    const size = Long.fromString(facilitySpecConditions.sizeMM);
    switch (facilitySpecConditions.unit) {
      case 'sizeZ':
        facilitiesFilter.sizeZ = size;
        break;
      case 'sizeT':
        facilitiesFilter.sizeT = size;
        break;
      case 'diameter':
        facilitiesFilter.diameter = size;
        break;
    }
  }
  filters.facilitiesFilter = facilitiesFilter;
  // 設備(カテゴリチェック)
  if (facilityConditions && facilityConditions.length > 0) {
    filters.facilitiesCategoryFilters = facilityConditions
      .map((item) => ({
        names: item.conditionItems
          .filter((conditionItems) => conditionItems.checked)
          .map(
            (condition) =>
              (condition.item.displayNameLang &&
                condition.item.displayNameLang[intl.locale]) ||
              ''
          ),
        type: convertMultipleTypeToFilterType(item.multipleType),
      }))
      .filter((item) => item.names.length > 0);
  }
  // 材料(カテゴリチェック)
  if (materialConditions && materialConditions.length > 0) {
    filters.materialFilters = materialConditions
      .map((item) => ({
        materialIds: item.conditionItems
          .filter((conditionItems) => conditionItems.checked)
          .map((condition) => condition.item.id || ''),
        type: convertMultipleTypeToFilterType(item.multipleType),
      }))
      .filter((item) => item.materialIds.length > 0);
  }
  // 取得認証(カテゴリチェック)
  if (certificateConditions && certificateConditions.length > 0) {
    filters.certificateFilters = certificateConditions
      .map((item) => ({
        names: item.conditionItems
          .filter((conditionItems) => conditionItems.checked)
          .map(
            (condition) =>
              (condition.item.displayNameLang &&
                condition.item.displayNameLang[intl.locale]) ||
              ''
          ),
        type: convertMultipleTypeToFilterType(item.multipleType),
      }))
      .filter((item) => item.names.length > 0);
  }
  // 重視ポイント(カテゴリチェック)
  if (importanceConditions && importanceConditions.length > 0) {
    filters.strengthFilters = importanceConditions
      .map((item) => ({
        names: item.conditionItems
          .filter((conditionItems) => conditionItems.checked)
          .map(
            (condition) =>
              (condition.item.displayNameLang &&
                condition.item.displayNameLang[intl.locale]) ||
              ''
          ),
        type: convertMultipleTypeToFilterType(item.multipleType),
      }))
      .filter((item) => item.names.length > 0);
  }
  // 売上
  const netSales = selectedSalesScale?.at(0)?.value || '';
  if (netSales) {
    filters.netSalesFilter = { netSales: Long.fromString(netSales) };
  }
  // 業種(カテゴリチェック)
  if (industryConditions && industryConditions.length > 0) {
    filters.industryFilters = industryConditions
      .map((item) => ({
        industryIds: item.conditionItems
          .filter((conditionItems) => conditionItems.checked)
          .map((condition) => condition.item.id || ''),
        type: convertMultipleTypeToFilterType(item.multipleType),
      }))
      .filter((item) => item.industryIds.length > 0);
  }
  return filters;
};

// 1階層目のカテゴリだけ抜き出す
export const extract1stLevelCategories = (data: CategorizedList) => {
  return data.map((item) => ({
    value: item.CategorizedItemId,
    displayNameLang: item.displayNameLang,
  }));
};

// 検索条件を名称化して1つのテキストにする
export const getConditionText = (
  intl: IntlShape,
  condition: CompanySearchCondition
): string => {
  const {
    keywords,
    regionConditions,
    processingConditions,
    facilitySpecConditions,
    facilityConditions,
    materialConditions,
    certificateConditions,
    importanceConditions,
    industryConditions,
    selectedSalesScale,
  } = condition;
  const conditionNames: string[] = [];

  // キーワード
  if (keywords && keywords.length > 0) {
    conditionNames.push(
      GetMessageWithIntl(intl, {
        id: 'POC_COMPANY_SEARCH.keyword_text',
        value: {
          1: keywords.join(','),
        },
      })
    );
  }
  // 地域
  const regionText = categoryToText(intl, regionConditions);
  if (regionText) {
    conditionNames.push(
      GetMessageWithIntl(intl, {
        id: 'POC_COMPANY_SEARCH.region_text',
        value: {
          1: regionText,
        },
      })
    );
  }
  // 加工技術
  const processingText = categoryToText(intl, processingConditions);
  if (processingText) {
    conditionNames.push(
      GetMessageWithIntl(intl, {
        id: 'POC_COMPANY_SEARCH.processingSkill_text',
        value: {
          1: processingText,
        },
      })
    );
  }
  // 設備
  const facilityTexts = [
    facilitySpecToText(intl, facilitySpecConditions),
    categoryToText(intl, facilityConditions),
  ].filter((text) => !!text);
  if (facilityTexts.length > 0) {
    conditionNames.push(
      GetMessageWithIntl(intl, {
        id: 'POC_COMPANY_SEARCH.facility_text',
        value: {
          1: facilityTexts.join(','),
        },
      })
    );
  }
  // 材料
  const materialText = categoryToText(intl, materialConditions);
  if (materialText) {
    conditionNames.push(
      GetMessageWithIntl(intl, {
        id: 'POC_COMPANY_SEARCH.material_text',
        value: {
          1: materialText,
        },
      })
    );
  }
  // 取得認証
  const certificateText = categoryToText(intl, certificateConditions);
  if (certificateText) {
    conditionNames.push(
      GetMessageWithIntl(intl, {
        id: 'POC_COMPANY_SEARCH.certificate_text',
        value: {
          1: certificateText,
        },
      })
    );
  }
  // 重視ポイント
  const importanceText = categoryToText(intl, importanceConditions);
  if (importanceText) {
    conditionNames.push(
      GetMessageWithIntl(intl, {
        id: 'POC_COMPANY_SEARCH.importance_text',
        value: {
          1: importanceText,
        },
      })
    );
  }
  // 売上規模
  if (selectedSalesScale && selectedSalesScale.length > 0) {
    conditionNames.push(
      GetMessageWithIntl(intl, {
        id: 'POC_COMPANY_SEARCH.sales_scale_text',
        value: {
          1: selectedSalesScale.at(0)?.displayName,
        },
      })
    );
  }
  // 業種
  const industryText = categoryToText(intl, industryConditions);
  if (industryText) {
    conditionNames.push(
      GetMessageWithIntl(intl, {
        id: 'POC_COMPANY_SEARCH.industry_text',
        value: {
          1: industryText,
        },
      })
    );
  }
  return conditionNames.join('\n');
};
const categoryToText = (
  intl: IntlShape,
  conditions?: ConditionDetail[]
): string => {
  if (!conditions) {
    return '';
  }
  const labelEveryMatch = GetMessageWithIntl(intl, {
    id: 'POC_COMPANY_SEARCH.every_match',
  });
  const labelSomeMatch = GetMessageWithIntl(intl, {
    id: 'POC_COMPANY_SEARCH.some_match',
  });
  return conditions
    .map((condition) => {
      const checkedItems = condition.conditionItems.filter(
        (item) => item.checked
      );
      if (checkedItems.length === 0) {
        return '';
      }
      return `${
        condition.multipleType === 'and' ? labelEveryMatch : labelSomeMatch
      } [${checkedItems.map(
        ({ item }) =>
          (item.displayNameLang && item.displayNameLang[intl.locale]) || ''
      )}]`;
    })
    .filter((text) => !!text) // 空文字になった条件は除く
    .join(', ');
};
const facilitySpecToText = (intl: IntlShape, condition?: FacilitySpec) => {
  if (!condition) {
    return '';
  }
  const { modelNumber, sizeX, sizeY, sizeMM, unit } = condition;
  const specTexts: string[] = [];
  if (modelNumber) {
    specTexts.push(
      `${GetMessageWithIntl(intl, {
        id: 'POC_COMPANY_SEARCH.facility.modelNumber',
      })}:${modelNumber}`
    );
  }
  if (sizeX) {
    specTexts.push(
      `${GetMessageWithIntl(intl, {
        id: 'POC_COMPANY_SEARCH.facility.sizeX',
      })}:${sizeX}`
    );
  }
  if (sizeY) {
    specTexts.push(
      `${GetMessageWithIntl(intl, {
        id: 'POC_COMPANY_SEARCH.facility.sizeY',
      })}:${sizeY}`
    );
  }
  if (sizeMM && unit) {
    specTexts.push(
      `${GetMessageWithIntl(intl, {
        id: `POC_COMPANY_SEARCH.facility.${unit}`,
      })}:${sizeMM}`
    );
  }
  if (specTexts.length === 0) {
    return '';
  }
  return `[${specTexts.join(',')}]`;
};

// 検索条件とDataViewの情報（絞り込み用id, ページ状態）を localStorage に格納
export function saveCompanySearchState(
  viewId: ViewId,
  data: CompanySearchLocationState,
  myEmail: string
) {
  const viewIdWithEmail = getLocalStorageKey(viewId, myEmail);
  const item = localStorage.getItem(viewIdWithEmail);
  if (item) {
    const prevItem: CompanySearchLocationState = JSON.parse(item);
    const v: CompanySearchLocationState = {
      conditions: data.conditions ?? prevItem.conditions,
      pageSetting: {
        ids: data.pageSetting?.ids ?? prevItem.pageSetting?.ids,
        checkedValues:
          data.pageSetting?.checkedValues ??
          prevItem.pageSetting?.checkedValues,
        pageSize: data.pageSetting?.pageSize ?? prevItem.pageSetting?.pageSize,
        pageNumber:
          data.pageSetting?.pageNumber ?? prevItem.pageSetting?.pageNumber,
      },
    };
    localStorage.setItem(viewIdWithEmail, JSON.stringify(v));
  } else {
    localStorage.setItem(viewIdWithEmail, JSON.stringify(data));
  }
}

// 検索条件とDataViewの情報（絞り込み用id, ページ状態）を localStorage から復元
export function getCompanySearchState(
  sourceViewId: ViewId,
  myEmail: string
): CompanySearchLocationState | null {
  if (!sourceViewId) {
    return null;
  }
  const viewIdWithEmail = getLocalStorageKey(sourceViewId, myEmail);
  const item = localStorage.getItem(viewIdWithEmail);
  if (!item) {
    return null;
  }
  return JSON.parse(item) ?? null;
}
