import { saveInformation } from './createInformation';
import { createLicense } from './createLicense';
import { createTenant } from './createTenant';
import { deleteInformation } from './deleteInformation';
import { deleteLicense } from './deleteLicense';
import { deleteTenant } from './deleteTenant';
import { reserveDeleteTenant } from './reserveDeleteTenant';
import { adminSendTenantInvitation } from './adminSendTenantInvitation';
import { updateLicense } from './updateLicense';
import { enableTenantManagement } from './enableTenantManagement';
import { disableTenantManagement } from './disableTenantManagement';
import { cancelReserveDeleteTenantManagement } from './cancelReserveDeleteTenantManagement';
import { createTenantAdmin } from './createTenantAdmin';
import { sendEmailAccountCreated } from './sendEmailAccountCreated';
import { bulkWriteLicenses } from './bulkWriteLicenses';

// actionName と function のマッピング
export const optCallMap = {
  createTenant: createTenant,
  adminSendTenantInvitation: adminSendTenantInvitation,
  deleteTenant: deleteTenant,
  reserveDeleteTenant: reserveDeleteTenant,
  enableTenantManagement: enableTenantManagement,
  disableTenantManagement: disableTenantManagement,
  cancelReserveDeleteTenantManagement: cancelReserveDeleteTenantManagement,
  createTenantAdmin: createTenantAdmin,
  sendEmailAccountCreated: sendEmailAccountCreated,
  createLicense: createLicense,
  updateLicense: updateLicense,
  deleteLicense: deleteLicense,
  deleteInformation: deleteInformation,
  saveInformation: saveInformation,
  bulkWriteLicenses: bulkWriteLicenses,
} as const;
