import { AppSaveWorker } from '..';
export interface DeleteEstimateManagementWithRelatedItemsRequest {
  actionName: 'deleteEstimateManagementWithRelatedItems';
  request: string[];
}

export const deleteEstimateManagementWithRelatedItems = async (
  worker: AppSaveWorker,
  req: string[]
) => {
  const methodName =
    'mtechnavi.api.estimation.EstimateSenderService/DeleteEstimateManagementWithRelatedItems';
  const result = req.map((item) => {
    return worker.invokeListRequest(methodName, {
      estimateManagementId: item,
    });
  });

  await Promise.all(result);
};
