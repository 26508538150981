import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface ExecCompleteWorkTaskRequest {
  actionName: 'completeWorkTask';
  request: mtechnavi.api.worktask.ICompleteWorkTaskRequest;
}

// 案件完了
export const execCompleteWorkTask = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.worktask.ICompleteWorkTaskRequest
) => {
  const fullMethodName =
    'mtechnavi.api.worktask.WorkTaskService/CompleteWorkTask';

  return await worker.invokeListRequest<
    mtechnavi.api.worktask.ICompleteWorkTaskRequest,
    mtechnavi.api.worktask.ICompleteWorkTaskResponse
  >(fullMethodName, req);
};
