import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface UpdateEstimateResultSystemNotificationSettingRequest {
  actionName: 'updateEstimateResultSystemNotificationSetting';
  request: mtechnavi.api.worker.ISystemNotificationSetting;
}

export const updateEstimateResultSystemNotificationSetting = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.worker.ISystemNotificationSetting
) => {
  const getFullMethodName =
    'mtechnavi.api.estimation.EstimateReceiverService/ListEstimateResults';
  const updateFullMethodName =
    'mtechnavi.api.estimation.EstimateReceiverService/UpdateSystemNotificationUsersInEstimateResult';

  const items = await worker.invokeListRequest<
    mtechnavi.api.estimation.IListEstimateResultsRequest,
    mtechnavi.api.estimation.IListEstimateResultsResponse
  >(getFullMethodName, {
    estimateResultIds: [req.surveyReceptionId ?? ''],
  });
  if (items && items.length > 0) {
    const item = items[0] as mtechnavi.api.estimation.IEstimateResult;
    const users = item.systemNotificationUsers ?? [];
    // 追加ユーザーが渡された場合
    if (req.appendUser) {
      if (!users.find((user) => user.userId === req.appendUser?.userId)) {
        users.push(req.appendUser);
      }
      item.systemNotificationUsers = users;
    }
    // 削除ユーザーが渡された場合
    if (req.removeUser) {
      const deleteUsers = users.filter(
        (user) => user.userId !== req.removeUser?.userId
      );
      item.systemNotificationUsers = deleteUsers;
    }
    await worker.invokeListRequest<
      mtechnavi.api.estimation.IUpdateSystemNotificationUsersInEstimateResultRequest,
      mtechnavi.api.estimation.EstimateResult
    >(updateFullMethodName, {
      estimateResultId: item.estimateResultId,
      systemNotificationUsers: item.systemNotificationUsers,
      updatedAt: item.updatedAt,
    });
  }
};
