import { useState, useMemo, useEffect } from 'react';
import { Textbox } from '~/shared/components/ui';
import { openWindowOrTab } from '~/shared/utils';
import { CaptionButton, IconButton } from '~/shared/components/ui/Button';

import './Help.css';
import { useHelp } from './useHelp';
import { QAItemComponent } from '~/shared/pages/Help/QAItemComponent';
import { useLocation } from 'react-router-dom';
import { HelpItem } from '~/shared/services/staticContents';

export const Help = () => {
  const { viewId } = useHelp(); // マニュアルで使う
  const { pathname } = useLocation();
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const [currentHelp, setCurrentHelp] = useState<HelpItem[]>([]);
  const helpList = useMemo<HelpItem[]>(() => {
    return window.App.helpList;
  }, []);

  useEffect(() => {
    // 現在のURLから画面に合わせたQAリストを絞り込み
    const paths = pathname.split('/');
    let pathTag = '';
    if (paths.length > 1) {
      pathTag = paths.slice(1).join('');
    }
    let result = helpList;
    if (pathTag) {
      result = helpList.filter((v) =>
        v.tag.some((tag) => pathTag.indexOf(tag) >= 0)
      );
    }
    setCurrentHelp(
      result.length > 0 ? result.slice(0, 3) : helpList.slice(0, 3)
    );
  }, [helpList, pathname]);

  const handleSearch = () => {
    const answerItems: HelpItem[] = [];
    helpList.forEach((v) => {
      if (
        v.title.indexOf(searchKeyword) >= 0 ||
        v.answer.indexOf(searchKeyword) >= 0
      ) {
        answerItems.push(v);
      }
    });
    setCurrentHelp(answerItems);
  };

  const tabs: string[] = ['画面マニュアル', 'よくある質問'];
  const [tabPanel, setTabPanel] = useState<string>('画面マニュアル');

  const ManualTab = () => {
    return (
      <>
        <div className="search-area">
          <Textbox
            name="help"
            type="text"
            value={searchKeyword}
            columns={['help']}
            onChangeState={setSearchKeyword}
          />
          <IconButton
            name="search"
            className="search-btn"
            iconType="search"
            onClick={handleSearch}
          />
        </div>
        <div className="manual">
          <p className="viewTitle">依頼確認（送信）</p>
          <div className="manual-contents">
            <div className="description">機能：依頼情報を確認します。</div>
            <div className="manual-list">
              <div className="manual-item">
                <span>{`(${1})`}</span>
                <span>戻る</span>
              </div>
              <div className="manual-item">遷移前の画面に戻ります。</div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const QAtab = () => {
    return (
      <>
        <div className="search-area">
          <Textbox
            name="help"
            type="text"
            value={searchKeyword}
            columns={['help']}
            onChangeState={setSearchKeyword}
          />
          <IconButton
            name="search"
            className="search-btn"
            iconType="search"
            onClick={handleSearch}
          />
        </div>
        <div className="help-list">
          <hr />
          {currentHelp.map((v) => (
            <QAItemComponent qaItem={v} key={v.id} />
          ))}
        </div>
        <CaptionButton
          name="navTOHelpTop"
          className="navTOHelpTop"
          buttonType="basic"
          caption="他の質問を探す"
          onClick={() => {
            openWindowOrTab('help');
          }}
        />
      </>
    );
  };

  return (
    <>
      <div className="help-right">
        <div className="tabPanelContents">
          {tabs.map((v, index) => {
            const selectPane = v === tabPanel ? 'current' : '';
            return (
              <div
                key={`tab-${index}`}
                className={`tabPanel ${selectPane}`}
                onClick={() => setTabPanel(v)}
              >
                {v}
              </div>
            );
          })}
        </div>
        {tabPanel === '画面マニュアル' && ManualTab()}
        {tabPanel === 'よくある質問' && QAtab()}
      </div>
    </>
  );
};
