import { ViewId } from '~/shared/utils';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { useState, useEffect } from 'react';
import {
  BusinessUnitStrengthDialog,
  BusinessUnitStrengthFormOption,
  SimpleIconListView,
} from '~/shared/components/ui';

interface StrengthTabItemProps {
  businessUnitStrengths?: mtechnavi.api.company.IBusinessUnitStrength[];
  businessUnitFacilitiess?: mtechnavi.api.company.IBusinessUnitFacilities[];
  businessUnitBranchs?: mtechnavi.api.company.IBusinessUnitBranch[];
}

interface ViewStrengthType {
  businessUnitStrengthId: string; // Key
  category: string; // 分類
  content: string; //メーカー
  linkProperties: string; // 関連拠点/関連設備
}

const VIEW_ID: ViewId = 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION';

const strengthColumns = [
  {
    header: {
      id: 'strength.category',
      prefixId: VIEW_ID,
    },
    propertyName: 'category',
    width: '12rem',
  },
  {
    header: {
      id: 'strength.content',
      prefixId: VIEW_ID,
    },
    propertyName: 'content',
  },
  {
    header: {
      id: 'strength.linkProperties',
      prefixId: VIEW_ID,
    },
    propertyName: 'linkProperties',
    width: '30%',
  },
];

export const StrengthTabItem = ({
  businessUnitStrengths,
  businessUnitFacilitiess,
  businessUnitBranchs,
}: StrengthTabItemProps) => {
  const [strengthItems, setStrengthItems] = useState<ViewStrengthType[]>([]);
  const [strengthFormItems, setStrengthFormItems] =
    useState<BusinessUnitStrengthFormOption>();
  const [isStrengthDialogOpen, setStrengthDialogOpen] = useState(false);

  useEffect(() => {
    if (!businessUnitStrengths) {
      setStrengthItems([]);
      return;
    }
    const vals: ViewStrengthType[] = [];
    businessUnitStrengths.map((v) => {
      const branchName =
        businessUnitBranchs?.find(
          (branch) =>
            branch.businessUnitBranchId ===
            v.linkProperties?.businessUnitBranchId
        )?.displayName ?? '';
      const modelNumber =
        businessUnitFacilitiess?.find(
          (facility) =>
            facility.businessUnitFacilitiesId ===
            v.linkProperties?.businessUnitFacilitiesId
        )?.modelNumber ?? '';
      vals.push({
        businessUnitStrengthId: v.businessUnitStrengthId ?? '',
        category: v.category?.displayNameLang?.ja ?? '',
        content: v.content ?? '',
        linkProperties:
          branchName || modelNumber ? [branchName, modelNumber].join('/') : '',
      });
    });
    vals.sort((val1, val2) => val1.category.localeCompare(val2.category, 'ja'));
    setStrengthItems(vals);
  }, [businessUnitStrengths, businessUnitBranchs, businessUnitFacilitiess]);

  // [実行処理]得意分野情報確認ダイアログ
  const handleStrengthDialog = (v: ViewStrengthType) => {
    const formItem: mtechnavi.api.company.IBusinessUnitStrength =
      businessUnitStrengths?.find(
        (w) => w.businessUnitStrengthId === v.businessUnitStrengthId
      ) ?? {};
    if (formItem) {
      setStrengthFormItems({
        companyId: formItem.companyId ?? '',
        isShared: true,
        category: formItem.category ?? {},
        content: formItem.content ?? '',
        linkProperties: formItem.linkProperties ?? {},
      });
    }
    setStrengthDialogOpen(true);
  };

  return (
    <>
      <div className="tab-info-body">
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              <SimpleIconListView
                data={strengthItems}
                viewOptions={{
                  previewRowCount: 10,
                  keyColumn: 'businessUnitStrengthId',
                  columns: strengthColumns,
                  omitFooter: true,
                }}
                actionOptions={{}}
                iconMenuOptions={{
                  iconMenu: [
                    {
                      name: 'description',
                      displayName: '確認',
                      func: (v: ViewStrengthType) => {
                        handleStrengthDialog(v);
                      },
                    },
                  ],
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {/* 得意分野情報確認ダイアログ */}
      <BusinessUnitStrengthDialog
        isOpen={isStrengthDialogOpen}
        inputMode={false}
        inputOption={strengthFormItems}
        onCancel={() => {
          setStrengthDialogOpen(false);
        }}
        onDecision={() => {}}
      />
    </>
  );
};
