import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface ImportSurveyResultWorkFormsRequest {
  actionName: 'importSurveyResultWorkForms';
  request: mtechnavi.api.survey.IImportSurveyResultWorkFormsRequest;
}
export const importSurveyResultWorkForms = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.survey.IImportSurveyResultWorkFormsRequest
) => {
  return await worker.invokeListRequest<
    mtechnavi.api.survey.IImportSurveyResultWorkFormsRequest,
    mtechnavi.api.survey.IImportSurveyResultWorkFormsResponse
  >(
    'mtechnavi.api.survey.SurveyReceiverService/ImportSurveyResultWorkForms',
    req
  );
};
