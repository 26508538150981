import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface CreateResultFormShareManagementRequest {
  actionName: 'createResultFormShareManagement';
  request: mtechnavi.api.survey.ICreateResultFormShareManagementRequest;
}

export const createResultFormShareManagement = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.survey.ICreateResultFormShareManagementRequest
) => {
  return worker.invokeListRequest<
    mtechnavi.api.survey.ICreateResultFormShareManagementRequest,
    mtechnavi.api.survey.ResultFormShareManagement
  >(
    'mtechnavi.api.survey.SurveyReceiverService/CreateResultFormShareManagement',
    req
  );
};
