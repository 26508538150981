import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface SaveBaseFormRequest {
  actionName: 'saveBaseForm';
  request: SaveBaseFormRequestParam;
}

export type SaveBaseFormRequestParam =
  mtechnavi.api.form.ICreateOrUpdateBaseFormWithRelatedItemsRequest;

export const saveBaseForm = (
  worker: AppSaveWorker,
  req: SaveBaseFormRequestParam
) => {
  const methodName =
    'mtechnavi.api.form.BaseFormService/CreateOrUpdateBaseFormWithRelatedItems';

  // baseFormSettingItemsはinsert用データとして初期化する
  if (req.baseFormSettingItems) {
    req.baseFormSettingItems.map((v) => {
      v.baseFormSettingItemId = null;
      v.updatedAt = null;
      v.createdAt = null;
    });
  }

  return worker.invokeListRequest<
    mtechnavi.api.form.ICreateOrUpdateBaseFormWithRelatedItemsRequest,
    mtechnavi.api.form.IBaseForm
  >(methodName, req);
};
