import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface SharetoEstimateResultWithRelatedItemsRequest {
  actionName: 'sharetoEstimateResultWithRelatedItems';
  request: mtechnavi.api.estimation.ISharetoEstimateResultWithRelatedItemsRequest;
}

export const sharetoEstimateResultWithRelatedItems = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.estimation.ISharetoEstimateResultWithRelatedItemsRequest
) => {
  const shareToMethodName =
    'mtechnavi.api.estimation.EstimateReceiverService/SharetoEstimateResultWithRelatedItems';

  return await worker.invokeListRequest<
    mtechnavi.api.estimation.ISharetoEstimateResultWithRelatedItemsRequest,
    mtechnavi.api.estimation.ISharetoEstimateResultWithRelatedItemsResponse
  >(shareToMethodName, {
    estimateResultId: req.estimateResultId,
    comment: req.comment,
    declined: req.declined,
  });
};
