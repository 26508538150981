import { mtechnavi } from '~/shared/libs/clientsdk';
import { GroupTreeItem } from '~/shared/components/ui/GroupTreeEditor';

// IOrganization の配列を受け取り、GroupTreeItemの配列に変換する関数
export function convertGroupTree(
  orgs: mtechnavi.api.company.IOrganization[]
): GroupTreeItem<mtechnavi.api.company.IOrganization>[] {
  const itemMap = new Map<
    string,
    GroupTreeItem<mtechnavi.api.company.IOrganization>
  >();
  const rootItems: GroupTreeItem<mtechnavi.api.company.IOrganization>[] = [];

  // 出来上がりの順序に影響があるため並べ替えておく
  const sortedOrgs = orgs.sort((a, b) =>
    (a.code || '').localeCompare(b.code || '', 'ja')
  );

  // 全ての組織をGroupTreeItemとしてMapに格納する
  sortedOrgs.forEach((org) => {
    const item: GroupTreeItem<mtechnavi.api.company.IOrganization> = {
      id: org.organizationId ?? '',
      displayName: org.displayName ?? '',
      code: org.code ?? '',
      isDisabled: !org.usable,
      children: [],
      originItem: org,
    };
    itemMap.set(org.organizationId!, item);
  });

  // 各組織に対して親子関係を構築する
  sortedOrgs.forEach((org) => {
    const currentItem = itemMap.get(org.organizationId!);
    if (org.parentOrganization?.organizationId) {
      const parentItem = itemMap.get(org.parentOrganization.organizationId);
      if (parentItem) {
        parentItem.children!.push(currentItem!);
      } else {
        // 親がMapに存在しない場合、ルートアイテムとして扱う
        rootItems.push(currentItem!);
      }
    } else {
      // 親IDが指定されていない場合、ルートアイテムとして追加
      rootItems.push(currentItem!);
    }
  });

  // ルートアイテムに親が存在しない場合のみ追加されるので、重複がない
  return rootItems;
}
