import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';
import { FullMethodName_EnableTenantManagement } from 'worker/fullMethodName/crud';

/** テナントの有効化 */
export const enableTenantManagement = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.admin.IEnableTenantManagementRequest
) => {
  return await worker.invokeListRequest<
    mtechnavi.api.admin.IEnableTenantManagementRequest,
    mtechnavi.api.admin.IEnableTenantManagementResponse
  >(FullMethodName_EnableTenantManagement, req);
};
