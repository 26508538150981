import { useIntl } from 'react-intl';
import {
  GetMessageWithIntl,
  ModalDialogComponent,
  ModalDialogComponentProps,
  Radio,
  RadioItem,
} from '../..';
import { CaptionButton } from '../Button/CaptionButton';
import { useEffect, useRef, useState, useCallback } from 'react';
import {
  FullMethodName_ListBusinessUnitBranchs,
  FullMethodName_SharedListBusinessUnitBranchs,
  FullMethodName_GetCompany,
  getProgramOptionFilterboxData,
  getProgramOptionFilterboxDataWithSystemName,
  getWorkerExceptionMessage,
  includeInputValidateError,
} from '~/shared/utils';
import { mtechnavi } from '~/shared/libs/clientsdk';
import {
  DataFilterbox,
  DataFilterboxItem,
  Filterbox,
  FilterboxItem,
} from '../Filterbox';
import { Textbox } from '../Textbox';
import './ResponsiblePersonRegistrationDialog.css';

export type DISPLAY_TYPE = 'display' | 'edit' | 'copy';
const QaCompany = ['inhouse', 'subcontract'];

export interface ResponsiblePersonRegistrationDialogInputOption {
  displayType: DISPLAY_TYPE;
  rankContent: mtechnavi.api.company.IBusinessUnitRankContent;
  sharedCompanyId: string; // 取引先会社ID
  isLinkedTenant: boolean; // テナント紐付け有無
}

const getCountrySystemName = (countryCode: string) => {
  return (
    window.App.services.ui.getNameOptionWithCode('A0000041', countryCode)
      .systemName ?? ''
  );
};

export interface ResponsiblePersonRegistrationDialogPorps {
  isOpen: boolean;
  inputOption: ResponsiblePersonRegistrationDialogInputOption;
  onChangeLoadingState?: (isLoading: boolean) => void;
  onDecision: (
    result: mtechnavi.api.company.IBusinessUnitRankReception
  ) => void;
  onCancel: () => void;
}

export const ResponsiblePersonRegistrationDialog = ({
  isOpen,
  inputOption,
  onChangeLoadingState,
  onDecision,
  onCancel,
}: ResponsiblePersonRegistrationDialogPorps) => {
  const businessUnitRankRequest =
    inputOption.rankContent?.businessUnitRankRequest;
  const businessUnitRankReception =
    inputOption.rankContent?.businessUnitRankReception;
  const intl = useIntl();
  const [countries, setCountries] = useState<DataFilterboxItem[]>([]);
  const [mainSubs, setMainSubs] = useState<DataFilterboxItem[]>([]);
  const [regions, setRegions] = useState<DataFilterboxItem[]>([]);
  const branchItemType = {
    value: 'businessUnitBranchId',
    displayName: 'displayName',
  };
  const radios: RadioItem[] = [
    {
      displayName: GetMessageWithIntl(intl, {
        id: QaCompany[0],
        prefixId: 'ResponsiblePersonRegistrationDialog',
      }),
      value: QaCompany[0],
    },
    {
      displayName: GetMessageWithIntl(intl, {
        id: QaCompany[1],
        prefixId: 'ResponsiblePersonRegistrationDialog',
      }),
      value: QaCompany[1],
    },
  ];
  const [isDisabledAllItem, setDisabledAllItem] = useState(false);
  const [isBranchInfoDisabled, setBranchInfoDisabled] = useState(false);
  const [companyId, setCompanyId] = useState<string>('');
  const [companyName, setCompanyName] = useState<string>('');
  const businessUnitBranchInfo = useRef<
    mtechnavi.api.company.IBusinessUnitBranch[]
  >([]);
  const [applyBrand, setApplyBrand] = useState<string>('');
  const [rank, setRank] = useState<string>('');
  const [qaCompany, setQaCompany] = useState<string>('');
  const [companyDisplayName, setCompanyDisplayName] = useState<string>('');
  const [businessUnitBranch, setBusinessUnitBranch] = useState<FilterboxItem[]>(
    []
  );
  const [factoryName, setFactoryName] = useState('');
  const [country, setCountry] = useState<DataFilterboxItem[]>([]);
  const [postCode, setPostCode] = useState('');
  const [region, setRegion] = useState<DataFilterboxItem[]>([]);
  const [addressLine, setAddressLine] = useState('');
  const [propertyName, setPropertyName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [faxNumber, setFaxNumber] = useState('');
  const [mainSubCategory, setMainSubCategory] = useState<DataFilterboxItem[]>(
    []
  );
  const [qualityManager, setQualityManager] = useState('');
  const [managerPosition, setManagerPosition] = useState('');
  const [managerEmail, setManagerEmail] = useState('');

  //入力チェック
  const requiredQaCompanyNameArea = useRef(null);
  const requiredStaffNameArea = useRef(null);
  const [workingBlurBranch, setWorkingBlurBranch] = useState<Date>();
  const requiredBranchArea = useRef(null);
  const [workingBlurQaCompany, setWorkingBlurQaCompany] = useState<Date>();
  const [workingBlurCountry, setWorkingBlurCountry] = useState<Date>();
  const requiredCountryArea = useRef(null);
  const [workingBlurRegion, setWorkingBlurRegion] = useState<Date>();
  const requiredRegionArea = useRef(null);
  const requiredAddressLineArea = useRef(null);

  // 初回ダイアログオープン時にuseEffectが走らないようにする制御
  const isInitialLoading = useRef<boolean>(true);

  const onParentLoadingState = useCallback(
    (arg: boolean) => {
      onChangeLoadingState && onChangeLoadingState(arg);
    },
    [onChangeLoadingState]
  );

  // isOpen === falseの時にフォームリセットを行う
  useEffect(() => {
    if (!isOpen) {
      resetForm();
    }
  }, [isOpen]);

  // 初期情報の取得、セット
  useEffect(() => {
    if (!isOpen) {
      return;
    }
    try {
      (async () => {
        onParentLoadingState(true);
        isInitialLoading.current = true;
        setDisabledAllItem(
          inputOption.displayType === 'display' ? true : false
        );
        const company: mtechnavi.api.company.ICompany = (
          await window.App.services.ui.worker.filter({
            action: 'query',
            fullMethodName: FullMethodName_GetCompany,
            filter: {},
            sort: [],
          })
        ).items.at(0) as mtechnavi.api.company.ICompany;

        setCompanyId(company.companyId ?? '');
        setCompanyName(company.displayNameLang?.ja ?? '');
        const branchRes = (await window.App.services.ui.worker.filter({
          action: 'query',
          fullMethodName:
            inputOption?.displayType === 'display'
              ? FullMethodName_SharedListBusinessUnitBranchs
              : FullMethodName_ListBusinessUnitBranchs,
          filter: {},
          requestBody: {
            companyIds: [
              inputOption?.displayType === 'display'
                ? inputOption.sharedCompanyId ?? ''
                : company.companyId,
            ],
          },
          sort: [], // ソートは画面項目セット時に行うため不要
        })) as mtechnavi.api.company.ListBusinessUnitBranchsResponse;
        const branches: mtechnavi.api.company.IBusinessUnitBranch[] =
          branchRes.items ?? [];
        businessUnitBranchInfo.current = branches;
        setCountries(getProgramOptionFilterboxData('A0000041'));
        setMainSubs(getProgramOptionFilterboxData('A4000012'));
        const regionsFilterBoxData =
          getProgramOptionFilterboxDataWithSystemName(
            'A0000042',
            getCountrySystemName(
              businessUnitRankReception?.businessUnitBranchProperties?.address
                ?.country?.code || ''
            )
          );
        setRegions(regionsFilterBoxData);

        // 画面アイテムセット
        // 適用品種
        setApplyBrand(businessUnitRankRequest?.displayName ?? '');
        // ランク
        setRank(businessUnitRankRequest?.rank?.code ?? '');
        // 品質管理企業名
        if (businessUnitRankReception?.companyDisplayName) {
          setCompanyDisplayName(businessUnitRankReception?.companyDisplayName);
        }
        // 自社他社ラジオボタン：取引先管理（品質責任者）マスタ.取引先管理（品質責任者）ID がNULLもしくは取引先管理（品質責任者）マスタ.自社企業がTrueの場合、自社を初期表示
        if (
          !businessUnitRankReception?.businessUnitRankReceptionId ||
          businessUnitRankReception?.ownCompany
        ) {
          setQaCompany('inhouse');
          // 拠点情報は自動入力のためDisabledにTrueをセット
          setBranchInfoDisabled(true);
          // 編集、複写の場合、自社名をセット
          if (inputOption.displayType !== 'display') {
            setCompanyDisplayName(company.displayNameLang?.ja ?? '');
          }
        } else {
          // 工場情報は手入力のためDisabledをFalseにセット
          setBranchInfoDisabled(false);
          setQaCompany('subcontract');
        }

        // 入力値を事前に変数に格納する
        // 拠点ID
        const businessUnitBranchId =
          businessUnitRankReception?.businessUnitBranchProperties
            ?.businessUnitBranchId;
        // 拠点名
        const factoryName =
          businessUnitRankReception?.businessUnitBranchProperties
            ?.displayName ?? '';
        // 住所情報
        const address =
          businessUnitRankReception?.businessUnitBranchProperties?.address;
        // 電話番号
        const phoneNumber =
          businessUnitRankReception?.businessUnitBranchProperties
            ?.phoneNumber ?? '';
        // FAX番号
        const faxNumber =
          businessUnitRankReception?.businessUnitBranchProperties?.faxNumber ??
          '';
        // テナント紐付き前の場合、品質責任者.拠点情報を表示する
        if (!inputOption.isLinkedTenant) {
          // 工場名（拠点名）
          setFactoryName(factoryName);
          // 国
          setCountry([
            {
              value: address?.country?.code ?? '',
              displayName: address?.country?.displayNameLang?.ja ?? '',
            },
          ]);
          // 都道府県（地域）
          setRegion([
            {
              value: address?.region?.code ?? '',
              displayName: address?.region?.displayNameLang?.ja ?? '',
            },
          ]);
          // 郵便番号
          setPostCode(address?.postalCode ?? '');
          // 市区町村以下
          const addressLines = address?.addressLines ?? [];
          setAddressLine(addressLines.length > 0 ? addressLines[0] : '');
          // 建物名、号室
          setPropertyName(addressLines.length > 1 ? addressLines[1] : '');
          // 電話番号
          setPhoneNumber(phoneNumber);
          // ファックス番号
          setFaxNumber(faxNumber);
        } else {
          // テナント紐付きありの場合
          // 拠点IDが存在する場合拠点情報をセットする
          if (businessUnitBranchId) {
            const branch = branches.find(
              (item) => item.businessUnitBranchId === businessUnitBranchId
            );
            // 拠点
            setBusinessUnitBranch([
              {
                value: branch?.businessUnitBranchId ?? '',
                displayName: branch?.displayName ?? '',
              },
            ]);
          } else {
            // 拠点情報なしの場合
            // 工場名
            setFactoryName(factoryName);
            // 入力モードの場合、保存された情報をセット
            if (inputOption.displayType !== 'display') {
              const selectedCountryCode = address?.country?.code;
              const selectedCountry = selectedCountryCode
                ? countries.filter((c) => c.value === selectedCountryCode)
                : [];
              setCountry(selectedCountry);

              const selectedRegionCode = address?.region?.code;
              const selectedRegion = selectedRegionCode
                ? regionsFilterBoxData.filter(
                    (p) => p.value === selectedRegionCode
                  )
                : [];
              setRegion(selectedRegion);
            } else {
              // 国
              setCountry([
                {
                  value: address?.country?.code ?? '',
                  displayName: address?.country?.displayNameLang?.ja ?? '',
                },
              ]);
              // 都道府県（地域）
              setRegion([
                {
                  value: address?.region?.code ?? '',
                  displayName: address?.region?.displayNameLang?.ja ?? '',
                },
              ]);
            }
            // 郵便番号
            setPostCode(address?.postalCode ?? '');
            // 市区町村以下
            const addressLines = address?.addressLines ?? [];
            setAddressLine(addressLines.length > 0 ? addressLines[0] : '');
            // 建物名、号室
            setPropertyName(addressLines.length > 1 ? addressLines[1] : '');
            // 電話番号
            setPhoneNumber(phoneNumber);
            // ファックス番号
            setFaxNumber(faxNumber);
          }
        }
        // 複写の時は空欄
        if (inputOption?.displayType !== 'copy') {
          // 品質管理者名
          setQualityManager(
            businessUnitRankReception?.qualityManager?.displayName ?? ''
          );
          // 正/副
          setMainSubCategory([
            {
              value: businessUnitRankReception?.category?.code ?? '',
              displayName:
                businessUnitRankReception?.category?.displayNameLang?.ja ?? '',
            },
          ]);
          // 役職
          setManagerPosition(
            businessUnitRankReception?.qualityManager?.position ?? ''
          );
          // メールアドレス
          setManagerEmail(
            businessUnitRankReception?.qualityManager?.email ?? ''
          );
        }
      })().then(() => {
        onParentLoadingState(false);
      });
    } catch (err) {
      getWorkerExceptionMessage(intl, err);
      throw err;
    }
    // 起動時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputOption, intl]);

  // 拠点が変更された時
  useEffect(() => {
    // 拠点情報が空の場合、リセットする
    if (businessUnitBranch.length === 0) {
      setCountry([]);
      setRegion([]);
      setPostCode('');
      setAddressLine('');
      setPropertyName('');
      setPhoneNumber('');
      setFaxNumber('');
      return;
    }
    const branch = businessUnitBranchInfo.current.find(
      (item) => item.businessUnitBranchId === businessUnitBranch[0].value
    );
    setCountry([
      {
        value: branch?.address?.country?.code ?? '',
        displayName: branch?.address?.country?.displayNameLang?.ja ?? '',
      },
    ]);
    setRegion([
      {
        value: branch?.address?.region?.code ?? '',
        displayName: branch?.address?.region?.displayNameLang?.ja ?? '',
      },
    ]);
    setPostCode(branch?.address?.postalCode ?? '');
    const addressLines = branch?.address?.addressLines ?? [];
    setAddressLine(addressLines.length > 0 ? addressLines[0] : '');
    setPropertyName(addressLines.length > 1 ? addressLines[1] : '');
    setPhoneNumber(branch?.phoneNumber ?? '');
    setFaxNumber(branch?.faxNumber ?? '');
  }, [businessUnitBranch]);

  // 国が変更された時、国に一致する地域のみ表示する
  const resetRegions = (countryValue: DataFilterboxItem[]) => {
    const systemName = getCountrySystemName(
      countryValue.length ? countryValue[0].value : ''
    );
    setRegions(
      getProgramOptionFilterboxDataWithSystemName('A0000042', systemName)
    );
    setRegion([]);
  };

  // 自社・仕入先変更
  useEffect(() => {
    // アイテムリセット時には発生させない
    if (!qaCompany) {
      return;
    }
    // 初回アイテムセット時には発生させない
    if (isInitialLoading.current) {
      isInitialLoading.current = false;
      return;
    }
    if (qaCompany === 'inhouse') {
      setCompanyDisplayName(companyName);
      setFactoryName('');
      setBranchInfoDisabled(true);
    } else {
      setCompanyDisplayName('');
      setBusinessUnitBranch([]);
      setBranchInfoDisabled(false);
    }
    setCountry([]);
    setRegion([]);
    setPostCode('');
    setAddressLine('');
    setPropertyName('');
    setPhoneNumber('');
    setFaxNumber('');

    // qaCompany 変更時のみ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qaCompany]);

  const isInputError = (): boolean => {
    const targetElm = document.querySelector(
      '.responsible-person-registration-dialog'
    );
    if (!qaCompany) {
      setWorkingBlurQaCompany(new Date());
      return true;
    }
    if (qaCompany === 'subcontract') {
      return includeInputValidateError(targetElm, intl, [
        { value: companyDisplayName ?? '', ref: requiredQaCompanyNameArea },
        {
          value: qualityManager ?? '',
          ref: requiredStaffNameArea,
        },
      ]);
    }
    if (qaCompany === 'inhouse') {
      setWorkingBlurBranch(new Date());
      setWorkingBlurCountry(new Date());
      setWorkingBlurRegion(new Date());
      const branchValue =
        businessUnitBranch.length > 0 ? businessUnitBranch[0].value : '';
      const countryValue = country.length > 0 ? country[0].value : '';
      const regionValue = region.length > 0 ? region[0].value : '';
      return includeInputValidateError(targetElm, intl, [
        { value: companyDisplayName ?? '', ref: requiredQaCompanyNameArea },
        {
          value: qualityManager ?? '',
          ref: requiredStaffNameArea,
        },
        {
          value: branchValue,
          ref: requiredBranchArea,
        },
        {
          value: countryValue,
          ref: requiredCountryArea,
        },
        {
          value: regionValue,
          ref: requiredRegionArea,
        },
        {
          value: addressLine ?? '',
          ref: requiredAddressLineArea,
        },
      ]);
    }
    return false;
  };

  const handleDecision = () => {
    if (isInputError()) {
      return;
    }

    const address: mtechnavi.api.company.IAddress = {};
    if (qaCompany === 'subcontract') {
      address.postalCode = postCode;
      address.country = window.App.services.ui.getNameOptionWithCode(
        'A0000041',
        country.length > 0 ? country[0].value : ''
      );
      address.region = window.App.services.ui.getNameOptionWithCode(
        'A0000042',
        region.length > 0 ? region[0].value : ''
      );
      address.addressLines = [addressLine, propertyName];
    }

    const result: mtechnavi.api.company.IBusinessUnitRankReception = {
      businessUnitRankReceptionId:
        inputOption.displayType === 'edit'
          ? businessUnitRankReception?.businessUnitRankReceptionId ?? null
          : null,
      businessUnitRankRequestId:
        businessUnitRankReception?.businessUnitRankRequestId ??
        businessUnitRankRequest?.businessUnitRankRequestId,
      ownCompany: qaCompany === 'inhouse' ? true : false,
      companyDisplayName,
      businessUnitBranchProperties: {
        businessUnitBranchId:
          qaCompany === 'inhouse'
            ? businessUnitBranch.length > 0
              ? businessUnitBranch[0].value
              : null
            : null,
        displayName: factoryName,
        address,
        phoneNumber,
        faxNumber,
      },
      category:
        mainSubCategory && mainSubCategory.length > 0
          ? window.App.services.ui.getNameOptionWithCode(
              'A4000012',
              mainSubCategory.length > 0 ? mainSubCategory[0].value : ''
            )
          : null,
      qualityManager: {
        displayName: qualityManager,
        position: managerPosition,
        email: managerEmail,
      },
      createdAt:
        inputOption.displayType === 'edit'
          ? businessUnitRankReception?.createdAt
          : null,
      updatedAt:
        inputOption.displayType === 'edit'
          ? businessUnitRankReception?.updatedAt
          : null,
    };
    onDecision(result);
  };

  const handleCancel = () => {
    onCancel();
  };

  const resetForm = () => {
    setRank('');
    setApplyBrand('');
    setQaCompany('');
    setCompanyDisplayName('');
    setBusinessUnitBranch([]);
    setFactoryName('');
    setCountry([]);
    setRegion([]);
    setPostCode('');
    setAddressLine('');
    setPropertyName('');
    setPhoneNumber('');
    setFaxNumber('');
    setMainSubCategory([]);
    setQualityManager('');
    setManagerPosition('');
    setManagerEmail('');
    setBranchInfoDisabled(false);
    setWorkingBlurBranch(undefined);
  };

  const elements = (
    <div className="responsible-person-registration-dialog">
      <div className="detail-area">
        <div className="contents-box">
          {/* コンテンツ */}
          <div className="input-line">
            <div className="item-group-100">
              {/* 適用範囲 */}
              <div className="w-33">
                <Textbox
                  name="applyBrand"
                  type="text"
                  labelId="ResponsiblePersonRegistrationDialog.applyBrand"
                  columns={['applyBrand']}
                  value={applyBrand}
                  disabled={true}
                />
              </div>
              {/* ランク */}
              <div className="w-33">
                <Textbox
                  name="rank"
                  type="text"
                  labelId="ResponsiblePersonRegistrationDialog.rank"
                  columns={['rank']}
                  value={rank}
                  disabled={true}
                />
              </div>
            </div>
          </div>
          <div className="input-line">
            <div className="item-group-100">
              {/* 品質管理区分 */}
              <div className="w-50">
                <Radio
                  name="qaCompany"
                  className="qaCompany"
                  value={qaCompany}
                  items={radios}
                  validateOption={{ required: true }}
                  onChangeState={setQaCompany}
                  disabled={isDisabledAllItem}
                  workingBlur={workingBlurQaCompany}
                />
              </div>
            </div>
          </div>
          <div className="input-line">
            <div className="item-group-100">
              {/* 品質管理企業名 */}
              <div className="w-66">
                <Textbox
                  name="companyDisplayName"
                  type="text"
                  labelId="ResponsiblePersonRegistrationDialog.companyDisplayName"
                  columns={['companyDisplayName']}
                  value={companyDisplayName}
                  onChangeState={setCompanyDisplayName}
                  validateOption={{ required: true }}
                  disabled={isDisabledAllItem || qaCompany === 'inhouse'}
                />
              </div>
            </div>
          </div>
          <div className="input-line">
            <div className="item-group-100">
              {/* 工場名 */}
              {qaCompany === 'subcontract' || !inputOption?.isLinkedTenant ? (
                <div className="w-66">
                  <Textbox
                    name="factoryName"
                    type="text"
                    labelId="ResponsiblePersonRegistrationDialog.factoryName"
                    columns={['factoryName']}
                    value={factoryName}
                    onChangeState={setFactoryName}
                    disabled={isDisabledAllItem}
                  />
                </div>
              ) : (
                <div className="w-66">
                  {/* 工場名 */}
                  <Filterbox
                    name="businessUnitBranch"
                    fullMethodName={FullMethodName_ListBusinessUnitBranchs}
                    itemType={branchItemType}
                    columns={['businessUnitBranch']}
                    labelId="ResponsiblePersonRegistrationDialog.businessUnitBranch"
                    validateOption={{ required: true }}
                    value={businessUnitBranch}
                    searchOption={{
                      targets: 'displayName',
                      customQuery: {
                        companyId: { $eq: companyId ?? '' },
                      },
                    }}
                    workingBlur={workingBlurBranch}
                    disabled={isDisabledAllItem}
                    onChangeState={setBusinessUnitBranch}
                  ></Filterbox>
                </div>
              )}
            </div>
          </div>
          <div className="input-line">
            <div className="item-group-100">
              {/* 所在国 */}
              <div className="w-33">
                <DataFilterbox
                  data={countries}
                  labelId="ResponsiblePersonRegistrationDialog.country"
                  searchOption={{ targets: 'displayName' }}
                  name="country"
                  columns={['country']}
                  onChangeState={(v) => {
                    setCountry(v);
                    resetRegions(v);
                  }}
                  validateOption={{
                    required: qaCompany === 'inhouse' ? true : false,
                  }}
                  value={country}
                  workingBlur={workingBlurCountry}
                  disabled={isDisabledAllItem || isBranchInfoDisabled}
                ></DataFilterbox>
              </div>
              {/* 郵便番号 */}
              <div className="w-33">
                <Textbox
                  name="postCode"
                  type="text"
                  value={postCode}
                  labelId="ResponsiblePersonRegistrationDialog.postCode"
                  columns={['postCode']}
                  onChangeState={setPostCode}
                  disabled={isDisabledAllItem || isBranchInfoDisabled}
                />
              </div>
            </div>
          </div>
          <div className="input-line">
            <div className="item-group-100">
              {/* 都道府県名 */}
              <div className="w-33">
                <DataFilterbox
                  data={regions}
                  labelId="ResponsiblePersonRegistrationDialog.region"
                  searchOption={{ targets: 'displayName' }}
                  name="region"
                  columns={['region']}
                  onChangeState={(v) => {
                    setRegion(v);
                  }}
                  validateOption={{
                    required: qaCompany === 'inhouse' ? true : false,
                  }}
                  value={region}
                  workingBlur={workingBlurRegion}
                  disabled={isDisabledAllItem || isBranchInfoDisabled}
                ></DataFilterbox>
              </div>
              {/* 市区町村以下 */}
              <div className="w-66">
                <Textbox
                  name="addressLine"
                  type="text"
                  labelId="ResponsiblePersonRegistrationDialog.addressLine"
                  columns={['addressLine']}
                  value={addressLine}
                  onChangeState={setAddressLine}
                  validateOption={{
                    required: qaCompany !== 'subcontract' ? true : false,
                  }}
                  disabled={isDisabledAllItem || isBranchInfoDisabled}
                />
              </div>
            </div>
          </div>
          <div className="input-line">
            <div className="item-group-100">
              {/* 建物名、号室 */}
              <div className="w-66">
                <Textbox
                  name="propertyName"
                  type="text"
                  labelId="ResponsiblePersonRegistrationDialog.propertyName"
                  columns={['propertyName']}
                  value={propertyName}
                  onChangeState={setPropertyName}
                  disabled={isDisabledAllItem || isBranchInfoDisabled}
                />
              </div>
            </div>
          </div>
          <div className="input-line">
            <div className="item-group-100">
              {/* 電話番号 */}
              <div className="w-33">
                <Textbox
                  name="phoneNumber"
                  type="text"
                  labelId="ResponsiblePersonRegistrationDialog.phoneNumber"
                  value={phoneNumber}
                  onChangeState={setPhoneNumber}
                  columns={['phoneNumber']}
                  disabled={isDisabledAllItem || isBranchInfoDisabled}
                />
              </div>
              {/* FAX番号 */}
              <div className="w-33">
                <Textbox
                  name="faxNumber"
                  type="text"
                  labelId="ResponsiblePersonRegistrationDialog.faxNumber"
                  value={faxNumber}
                  onChangeState={setFaxNumber}
                  columns={['faxNumber']}
                  disabled={isDisabledAllItem || isBranchInfoDisabled}
                />
              </div>
            </div>
          </div>
          <div className="input-line">
            <div className="item-group-100">
              {/* 正/副 */}
              <div className="w-33">
                <DataFilterbox
                  data={mainSubs}
                  labelId="ResponsiblePersonRegistrationDialog.mainSubCategory"
                  searchOption={{ targets: 'displayName' }}
                  name="mainSubCategory"
                  columns={['mainSubCategory']}
                  onChangeState={(v) => {
                    setMainSubCategory(v);
                  }}
                  value={mainSubCategory}
                  disabled={isDisabledAllItem}
                ></DataFilterbox>
              </div>
              {/* 品質管理者名 */}
              <div className="w-33">
                <Textbox
                  name="qualityManager"
                  type="text"
                  labelId="ResponsiblePersonRegistrationDialog.qualityManager"
                  value={qualityManager}
                  onChangeState={setQualityManager}
                  columns={['qualityManager']}
                  validateOption={{ required: true }}
                  disabled={isDisabledAllItem}
                />
              </div>
              {/* 役職 */}
              <div className="w-33">
                <Textbox
                  name="managerPosition"
                  type="text"
                  labelId="ResponsiblePersonRegistrationDialog.managerPosition"
                  value={managerPosition}
                  onChangeState={setManagerPosition}
                  columns={['managerPosition']}
                  disabled={isDisabledAllItem}
                />
              </div>
            </div>
          </div>
          <div className="input-line">
            <div className="item-group-100">
              {/* メールアドレス */}
              <div className="w-66">
                <Textbox
                  name="managerEmail"
                  type="text"
                  labelId="ResponsiblePersonRegistrationDialog.managerEmail"
                  value={managerEmail}
                  onChangeState={setManagerEmail}
                  columns={['managerEmail']}
                  disabled={isDisabledAllItem}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="button-area">
          <CaptionButton
            name="cancelBtn"
            buttonType="cancel"
            className="button"
            caption={GetMessageWithIntl(intl, {
              id: 'cancel',
            })}
            onClick={() => handleCancel()}
          />
          {inputOption.displayType !== 'display' && (
            <CaptionButton
              name="sendBtn"
              buttonType="basic"
              className="button"
              caption={GetMessageWithIntl(intl, {
                id: 'save',
              })}
              onClick={() => handleDecision()}
            />
          )}
        </div>
      </div>
    </div>
  );

  const openModalProps: ModalDialogComponentProps = {
    cancel: () => {
      onCancel();
    },
    send: () => {},
    modalIsOpen: isOpen,
    headerLabelId: {
      id:
        inputOption.displayType === 'display'
          ? 'responsiblePersonConfirmation'
          : 'responsiblePersonRegistration',
      prefixId: 'DIALOG_TITLE',
    },
    messageLabelId: {},
    elements,
  };

  return (
    <div className="ResponsiblePersonRegistrationDialog">
      <ModalDialogComponent {...openModalProps} />
    </div>
  );
};
