import { Container, Toast } from '~/shared/components';
import { Tabs } from '~/shared/components/ui';
import './DialogSample.css';

import { UISampleDialogs } from '../UISample/Interactive/Dialogs';
import { UISampleBusinessUnitDetailDialogs } from './samples/BusinessUnitDetailDialogs';
import { UISampleCommentDialogs } from './samples/CommentDialogs';
import { UISampleMemoDialogs } from './samples/MemoDialogs';
import { UISampleFileUploadDialogs } from './samples/FileUploadDialogs';
import { UISampleDeadlineChangeDialogs } from './samples/DeadlineChangeDialogs';
import { UISampleApprovalRequestDialogs } from './samples/ApprovalRequestDialogs';
import { UISampleApprovalRejectionDialogs } from './samples/ApprovalRejectionDialogs';
import { UISampleOutputDialogs } from './samples/OutputDialogs';
import { UISampleOrderOutputDialogs } from './samples/OrderOutputDialogs';
import { UISampleSystemNotificationSettingDialogs } from './samples/SystemNotificationSettingDialogs';
import { UISampleSurveyReplyDialogs } from './samples/SurveyReplyDialogs';
import { UISampleSurveyReplyDelegationDialogs } from './samples/SurveyReplyDelegationDialog';
import { UISampleManualReminderDialogs } from './samples/ManualReminderDialogs';
import { UISampleAutoReminderSettingDialogs } from './samples/AutoReminderSettingDialogs';
import { UISampleBusinessUnitCsvAdditionDialogs } from './samples/BusinessUnitCsvAddtionDialogs';
import { UISampleHistoryDialogs } from './samples/HistoryDialogs';
import { UISampleBusinessUnitDialogs } from './samples/BusinessUnitDailogs';
import { UISampleUserAttributeDialogs } from './samples/UserAttributeDialogs';
import { UISampleFileImportDialogs } from './samples/FileImportDialogs';
import { UISampleFileUploadWithCommentDialogs } from './samples/FileUploadWithCommentDialogs';
import { UISampleFileUploadWithMemoDialogs } from './samples/FileUploadWithMemoDialogs';
import { UISampleChangeStaffDialogs } from './samples/ChangeStaffDialogs';
import { UISampleSelectConnectingDialogs } from './samples/SelectConnectingDialogs';
import { UISampleBusinessUnitBranchDialogs } from './samples/BusinessUnitBranchDialogs';
import { UISampleBusinessUnitStrengthDialogs } from './samples/BusinessUnitStrengthDialogs';
import { UISampleBusinessUnitFacilityDialogs } from './samples/BusinessUnitFacilityDialogs';
import { UISampleFinancialRegistrationDialogs } from './samples/FinancialRegistrationDialogs';
import { UISampleCertificateRegistrationDialogs } from './samples/CertificateRegistrationDialogs';
import { UISampleContactDialogs } from './samples/ContactDialogs';
import { UISampleNotificationUpdateStartStopDialogs } from './samples/NotificationUpdateStartStopDialogs';
import { UISampleChangeNotificationDialogs } from './samples/ChangeNotificationDialogs';
import { UISampleDocumentRegistrationDialogs } from './samples/DocumentRegistrationDialogs';
import { UISampleFileInputImportDialogs } from './samples/FormInputImportDialogs';
import { UISampleFormSettingDialogs } from './samples/FormSettingDialogs';
import { UISampleSearchRegionDialogs } from './samples/SearchRegionDialogs';
import { UISampleSurveyRequestRegistrationDialogs } from './samples/SurveyRequestRegistrationDialogs';
import { UISampleBuildedInputFormDialogs } from './samples/BuildedInputFormDialogs';
import { UISampleResponsiblePersonRegistrationDialogs } from './samples/ResponsibePersonRegistrationDialogs';
import { UISampleSimpleListDialogs } from './samples/SimpleListDialogs';
import { UISampleSelectPlanDialogs } from './samples/SelectPlanDialogs';
import { UISampleEstimateSelectionDialogs } from './samples/EstimateSelectionDialogs';
import { UISampleSuppliedRegistrationDialogs } from './samples/SuppliedRegistrationDialogs';
import { UISampleEstimateResultDetailRegistrationDialogs } from './samples/EstimateResultDetailRegistrationDialogs';
import { UISampleDetailInputDialogs } from './samples/DetailInputDialogs';
import { UISampleDetailConfirmationDialogs } from './samples/DetailConfirmationDialogs';
import { UISampleEstimateBasicInfoRegistrationDialogs } from './samples/EstimateBasicInfoRegistrationDialogs';
import { UISampleFileUploadAppendDialogs } from './samples/FileUploadAppendDialogs';
import { UISampleFileUploadAppendListDialogs } from './samples/FileUploadAppendListDialogs';
import { UISampleOwnStaffDialogs } from './samples/OwnStaffDialogs';
import { UISampleFlagSettingDialogs } from './samples/FlagSettingDialogs';
import { UISampleNotificationSendTargetSettingDialogs } from './samples/NotificationSendTargetSettingDialogs';
import { UIOrganizationDetailDialogs } from './samples/OrganizationDetailDialogs';
import { UIOrganizationMemberDialogs } from './samples/OrganizationMemberDialogs';
import { UISampleRadioSelectDialogs } from './samples/RadioSelectDialogs';
import { UISampleAttributeInputDialogs } from './samples/AttributeInputDialogs';
import { UISampleOrganizationTreeSearchDialogs } from './samples/OrganizationTreeSearchDialogs';
import { UISampleAdminAccountAddDialogs } from './samples/AdminAccountAddDialogs';
import { UISampleInformationManagementDialogs } from './samples/InformationManagementDialogs';
import { UISampleLicenseEditDialogs } from './samples/LicenseEditDialogs';
import { UISampleInformationDialogs } from './samples/InformationDialogs';
import { UISampleMainContactSettingDialogs } from './samples/MainContactSettingDialogs';
import { UISamplePublicDocumentRegistrationDialogs } from './samples/PublicDocumentRegistrationDialogs';
import { UISampleContentsDisplayDialogs } from './samples/ContentsDisplayDialogs';
import { UISampleJsonFileIODialogs } from './samples/JsonFileIODialogs';

const tabData = [
  // 共通
  // ファイル追加
  // 取引先管理系
  // サプライヤーベス系
  // 汎用系
  // ファイルアップロード系
  // フォーム系
  // 見積
  // アカウント
  // その他
  // 運営
];

const commonDialogs = () => {
  return (
    <div>
      {/* 確認ダイアログ */}
      <UISampleDialogs />
      {/* コメント付ダイアログ */}
      <UISampleCommentDialogs />
      {/* メモダイアログ */}
      <UISampleMemoDialogs />
      {/* 履歴ダイアログ */}
      <UISampleHistoryDialogs />
      {/* 承認依頼ダイアログ */}
      <UISampleApprovalRequestDialogs />
      {/* 承認/却下ダイアログ */}
      <UISampleApprovalRejectionDialogs />
      {/* システム通知設定ダイアログ */}
      <UISampleSystemNotificationSettingDialogs />
      {/* 地域検索ダイアログ */}
      <UISampleSearchRegionDialogs />
    </div>
  );
};

const generalPurposeDialogs = () => {
  return (
    <div>
      {/* 出力ダイアログ */}
      <UISampleOutputDialogs />
      {/* 出力ダイアログ(受発注) */}
      <UISampleOrderOutputDialogs />
      {/* 取引先取込ダイアログ */}
      <UISampleBusinessUnitDialogs />
      {/* アカウント取込ダイアログ */}
      <UISampleUserAttributeDialogs />
      {/* ファイル取込ダイアログ */}
      <UISampleFileImportDialogs />
      {/* シンプルリストビューダイアログ */}
      <UISampleSimpleListDialogs />
      {/* ラジオ選択ダイアログ */}
      <UISampleRadioSelectDialogs />
    </div>
  );
};

const fileUploadDialogs = () => {
  return (
    <div>
      {/* コメント付きファイルアップロードダイアログ */}
      <UISampleFileUploadWithCommentDialogs />
      {/* 備考付きファイルアップロードダイアログ */}
      <UISampleFileUploadWithMemoDialogs />
      {/* ファイル追加ダイアログ */}
      <UISampleFileUploadDialogs />
      {/* ファイル追加ダイアログ（追加アップロード、呼び出し元と重複チェックあり） */}
      <UISampleFileUploadAppendDialogs />
      {/* リスト付きファイル追加ダイアログ（重複チェックあり） */}
      <UISampleFileUploadAppendListDialogs />
    </div>
  );
};

const businessUnitManagementDialogs = () => {
  return (
    <div>
      {/* 取引先詳細ダイアログ */}
      <UISampleBusinessUnitDetailDialogs />
      {/* 拠点情報登録/確認ダイアログ */}
      <UISampleBusinessUnitBranchDialogs />
      {/* 得意分野情報登録/確認ダイアログ */}
      <UISampleBusinessUnitStrengthDialogs />
      {/* 設備情報登録/確認ダイアログ */}
      <UISampleBusinessUnitFacilityDialogs />
      {/* 決算情報登録ダイアログ */}
      <UISampleFinancialRegistrationDialogs />
      {/* 文書登録ダイアログ */}
      <UISampleDocumentRegistrationDialogs />
      {/* 認証情報登録ダイアログ */}
      <UISampleCertificateRegistrationDialogs />
      {/* 連絡先系ダイアログ */}
      <UISampleContactDialogs />
      {/* 責任者登録/確認ダイアログ */}
      <UISampleResponsiblePersonRegistrationDialogs />
      {/* 公開資料登録ダイアログ */}
      <UISamplePublicDocumentRegistrationDialogs />
      {/* 内容確認ダイアログ */}
      <UISampleContentsDisplayDialogs />
      {/* 更新通知ダイアログ */}
      <UISampleChangeNotificationDialogs />
      {/* 代表連絡先設定ダイアログ */}
      <UISampleMainContactSettingDialogs />
      {/* 通知更新停止/再開ダイアログ */}
      <UISampleNotificationUpdateStartStopDialogs />
    </div>
  );
};

const surveyDialogs = () => {
  return (
    <div>
      {/* 回答送信ダイアログ */}
      <UISampleSurveyReplyDialogs />
      {/* 回答代行ダイアログ */}
      <UISampleSurveyReplyDelegationDialogs />
      {/* 回答期日変更系ダイアログ */}
      <UISampleDeadlineChangeDialogs />
      {/* 手動催促ダイアログ */}
      <UISampleManualReminderDialogs />
      {/* 自動催促設定ダイアログ */}
      <UISampleAutoReminderSettingDialogs />
      {/* 取引先CSV追加ダイアログ */}
      <UISampleBusinessUnitCsvAdditionDialogs />
      {/* フラグダイアログ */}
      <UISampleFlagSettingDialogs />
      {/* 自社担当ダイアログ */}
      <UISampleOwnStaffDialogs />
      {/* 通知メール送信対象設定ダイアログ */}
      <UISampleNotificationSendTargetSettingDialogs />
    </div>
  );
};

const estimateDialogs = () => {
  return (
    <div>
      {/* 予定選択ダイアログ */}
      <UISampleSelectPlanDialogs />
      {/* 選考ダイアログ */}
      <UISampleEstimateSelectionDialogs />
      {/* 明細登録ダイアログ */}
      <UISampleDetailInputDialogs />
      {/* 明細確認ダイアログ */}
      <UISampleDetailConfirmationDialogs />
      {/* 明細内訳登録ダイアログ */}
      <UISampleEstimateResultDetailRegistrationDialogs />
      {/* 支給品登録ダイアログ */}
      <UISampleSuppliedRegistrationDialogs />
      {/* 見積基本情報登録ダイアログ */}
      <UISampleEstimateBasicInfoRegistrationDialogs />
    </div>
  );
};

const workDialogs = () => {
  return (
    <div>
      {/* 担当変更ダイアログ */}
      <UISampleChangeStaffDialogs />
      {/* 紐付け分類ダイアログ（紐付けダイアログ） */}
      <UISampleSelectConnectingDialogs />
    </div>
  );
};

const formDialogs = () => {
  return (
    <div>
      {/* フォーム入力インポートダイアログ */}
      <UISampleFileInputImportDialogs />
      {/* フォーム設定ダイアログ */}
      <UISampleFormSettingDialogs />
      {/* フォーム入力系ダイアログ */}
      <UISampleBuildedInputFormDialogs />
    </div>
  );
};

const bcpDialogs = () => {
  return (
    <div>
      {/* 調査依頼追加ダイアログ */}
      <UISampleSurveyRequestRegistrationDialogs />
    </div>
  );
};

const organizationDialogs = () => {
  return (
    <div>
      {/* 組織編集ダイアログ */}
      <UIOrganizationDetailDialogs />
      {/* 組織ツリー検索ダイアログ */}
      <UISampleOrganizationTreeSearchDialogs />
    </div>
  );
};

const operationDialogs = () => {
  return (
    <div>
      {/* テナント管理者アカウント追加ダイアログ */}
      <UISampleAdminAccountAddDialogs />
      {/* [運営]お知らせ設定登録ダイアログ */}
      <UISampleInformationManagementDialogs />
      {/* [運営]ライセンス編集ダイアログ */}
      <UISampleLicenseEditDialogs />
    </div>
  );
};

const accountDialogs = () => {
  return (
    <div>
      {/* 所属アカウント編集ダイアログ */}
      <UIOrganizationMemberDialogs />
      {/* 属性情報入力ダイアログ */}
      <UISampleAttributeInputDialogs />
    </div>
  );
};

const otherDialogs = () => {
  return (
    <div>
      {/* お知らせ確認ダイアログ */}
      <UISampleInformationDialogs />
      {/* JSONデータIOダイアログ */}
      <UISampleJsonFileIODialogs />
    </div>
  );
};

const secondTabData = [
  { label: '共通系', tabContent: commonDialogs() },
  { label: '汎用系', tabContent: generalPurposeDialogs() },
  { label: 'ファイルアップロード系', tabContent: fileUploadDialogs() },
  { label: '取引先管理系', tabContent: businessUnitManagementDialogs() },
  { label: 'サプライヤーベース系', tabContent: surveyDialogs() },
  { label: '見積管理系', tabContent: estimateDialogs() },
  { label: '指図案件系', tabContent: workDialogs() },
  { label: 'BCP系', tabContent: bcpDialogs() },
  { label: 'フォーム系', tabContent: formDialogs() },
  { label: '組織系', tabContent: organizationDialogs() },
  { label: '運営系', tabContent: operationDialogs() },
  { label: 'アカウント系', tabContent: accountDialogs() },
  { label: 'その他', tabContent: otherDialogs() },
];

export const DialogSample = () => {
  return (
    <Container>
      <div className="DialogSample">
        <Tabs tabItems={secondTabData} />
      </div>
      <Toast />
    </Container>
  );
};
