import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface ExecSurveyResultRequest {
  actionName: 'execSurveyResult';
  request: mtechnavi.api.survey.ISurveyResult;
}

// 依頼回答
export const execSurveyResult = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.survey.ISurveyResult
) => {
  const createRequest: mtechnavi.api.survey.ISurveyResult = {
    surveyRequestId: req.surveyRequestId,
    partialReplyed: req.partialReplyed,
    declined: req.declined,
    replyAttachments: req.replyAttachments,
    lockedFormIds: req.lockedFormIds,
    comment: req.comment,
  };

  // 依頼回答データ登録
  const createFullMethodName =
    'mtechnavi.api.survey.SurveyReceiverService/CreateSurveyResult';
  const createResult = await worker.invokeListRequest(createFullMethodName, {
    surveyResult: createRequest,
  });
  if (createResult && createResult.length > 0) {
    // shareToコール
    const sharetoFullMethodName =
      'mtechnavi.api.survey.SurveyReceiverService/SharetoSurveyResult';
    await worker.invokeListRequest(sharetoFullMethodName, {
      surveyResult: createResult[0],
    });
  }
};
