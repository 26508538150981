import { useState, useMemo, useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { PresetItem } from '~/shared/services';
import { GetMessageWithIntl } from '~/shared/components/parts/Message/Message';
import {
  DataFilterbox,
  DataFilterboxItem,
} from '~/shared/components/ui/Filterbox/DataFilterbox';
import { ReactComponent as Cancel } from '@material-design-icons/svg/filled/cancel.svg';
import { CaptionButton, IconWithCaptionButton } from '../Button';
import { FilterInputView } from './FilterInputView';
import { FilterViewQuery, usePreviousViewId } from './FilterView';

import './FilterAreaView.css';
import { ViewId, includeInputValidateError } from '~/shared/utils';
import { checkDisalbedPresetProperty } from './preset';

interface FilterAreaProps {
  viewId?: ViewId;
  presetItem?: PresetItem;
  filterValue?: FilterViewQuery;
  initialFilter?: FilterViewQuery;
  initialRequestBodyFilter?: FilterViewQuery;
  close: () => void;
  send: () => void;
  onChangeFilter?: (arg: FilterViewQuery) => void;
  isFilterAreaOpen?: boolean;
  isRequestBodyFilter?: boolean;
}

export function FilterAreaView(props: FilterAreaProps) {
  const {
    viewId,
    presetItem,
    filterValue = { filterTerms: {} } as FilterViewQuery,
    initialFilter = { filterTerms: {} } as FilterViewQuery,
    close,
    send,
    onChangeFilter,
    isFilterAreaOpen,
    isRequestBodyFilter,
  } = props;

  const intl = useIntl();
  const [viewFilterItem, setViewFilterItem] = useState<DataFilterboxItem[]>([]);
  const [viewFilterRequestBodyItem, setViewFilterRequestBodyItem] = useState<
    DataFilterboxItem[]
  >([]);
  const [filterboxValues, setFilterboxValues] = useState<DataFilterboxItem[]>(
    []
  );

  const toDataFilterboxItem = useCallback(
    (column: string) => {
      return {
        value: column,
        displayName: GetMessageWithIntl(intl, {
          id: column,
          viewId: viewId ?? '',
        }),
      };
    },
    [intl, viewId]
  );

  const getPresetItem = (
    name: string,
    presetItems: PresetItem[] | undefined
  ): PresetItem | undefined => {
    return presetItems
      ? presetItems.find((v) => v.name === name)
      : { name: name };
  };

  const filterboxItemDatas = useMemo<DataFilterboxItem[]>(() => {
    // プリセットが取れなかった時は早期返却
    if (!presetItem || !presetItem.children) return [];
    // フィルター対象のカラム
    const filterColumns = presetItem.columns ?? [];
    // リクエストボディ検索対象のカラム
    const initialRequestBody = getPresetItem(
      'initialRequestBodyFilter',
      presetItem.children
    );
    const rbColumns = initialRequestBody?.columns ?? [];
    const filterboxItemDatas: DataFilterboxItem[] = [];
    for (const v of filterColumns) {
      if (!rbColumns.includes(v)) {
        filterboxItemDatas.push(toDataFilterboxItem(v));
      }
    }
    return filterboxItemDatas;
  }, [presetItem, toDataFilterboxItem]);

  const filterboxRequestBodyItemDatas = useMemo<DataFilterboxItem[]>(() => {
    // プリセットが取れなかった時は早期返却
    if (!presetItem || !presetItem.children) return [];
    // フィルター対象のカラム
    const filterColumns = presetItem.columns ?? [];
    // リクエストボディ検索対象のカラム
    const initialRequestBody = getPresetItem(
      'initialRequestBodyFilter',
      presetItem.children
    );
    const rbColumns = initialRequestBody?.columns ?? [];

    const filterboxRequestBodyItems: DataFilterboxItem[] = [];
    for (const v of filterColumns) {
      if (rbColumns.includes(v)) {
        filterboxRequestBodyItems.push(toDataFilterboxItem(v));
      }
    }
    return filterboxRequestBodyItems;
  }, [presetItem, toDataFilterboxItem]);

  const prevViewId = usePreviousViewId(viewId ?? '');
  useEffect(() => {
    // viewId が変更された場合は、以前の検索状態を初期化する
    if (prevViewId !== viewId) {
      setViewFilterItem([]);
      setViewFilterRequestBodyItem([]);
      return;
    }
    const filteringColomns = Object.keys(filterValue.filterTerms);
    const viewFilterItems: DataFilterboxItem[] = [];
    for (const v of filteringColomns) {
      const filterItem = filterboxItemDatas.find((item) => item.value === v);
      filterItem && viewFilterItems.push(filterItem);
    }
    setViewFilterItem(viewFilterItems);

    const viewFilterRequestBodyItems: DataFilterboxItem[] = [];
    for (const v of filteringColomns) {
      const filterItem = filterboxRequestBodyItemDatas.find(
        (item) => item.value === v
      );
      filterItem && viewFilterRequestBodyItems.push(filterItem);
    }
    setViewFilterRequestBodyItem(viewFilterRequestBodyItems);
    // viewId / filterTerms 変更時のみ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewId, filterValue.filterTerms]);

  const handleInitializeFilter = () => {
    onChangeFilter && onChangeFilter(initialFilter);
    const filteringColomns = Object.keys(initialFilter.filterTerms);
    const viewFilterItems: DataFilterboxItem[] = [];
    for (const v of filteringColomns) {
      const filterItem = filterboxItemDatas.find((item) => item.value === v);
      filterItem && viewFilterItems.push(toDataFilterboxItem(v));
    }
    setViewFilterItem(viewFilterItems);

    const viewFilterRequestBodyItems: DataFilterboxItem[] = [];
    for (const v of filteringColomns) {
      const filterItem = filterboxRequestBodyItemDatas.find(
        (item) => item.value === v
      );
      filterItem && viewFilterRequestBodyItems.push(filterItem);
    }
    setViewFilterRequestBodyItem(viewFilterRequestBodyItems);
  };

  const isAlreadySelectedItem = (item: DataFilterboxItem) => {
    return viewFilterItem.some(
      (v) => v.displayName === item.displayName && v.value === item.value
    );
  };

  const appendColumnFilterItem = (values: DataFilterboxItem[]): void => {
    if (values.length === 0) return;
    const filterTerms = { ...filterValue.filterTerms };
    for (const { value } of values) {
      filterTerms[value] = filterTerms[value] ?? [];
    }
    onChangeFilter &&
      onChangeFilter({ ...filterValue, filterTerms: filterTerms });
  };

  const appendViewFilterItem = (values: DataFilterboxItem[]): void => {
    const filterItems: DataFilterboxItem[] = [];
    for (const v of values) {
      if (isAlreadySelectedItem(v)) continue;
      filterItems.push(v);
    }
    setViewFilterItem([...viewFilterItem, ...filterItems]);
  };

  const handleAppendItem = () => {
    appendViewFilterItem(filterboxValues);
    appendColumnFilterItem(filterboxValues);
    setFilterboxValues([]);
  };

  const removeColumnFilterItem = (columnName: string): void => {
    const filterTerms = { ...filterValue.filterTerms };
    delete filterTerms[columnName];
    onChangeFilter &&
      onChangeFilter({ ...filterValue, filterTerms: filterTerms });
  };

  const removeViewFilterItem = (columnName: string): void => {
    const viewFilterItems = [...viewFilterItem];
    const columnNames = viewFilterItem.map((v) => v.value);
    const index = columnNames.indexOf(columnName);
    if (index >= 0) {
      viewFilterItems.splice(index, 1);
    }
    setViewFilterItem(viewFilterItems);
  };

  const handleRemoveItem = (columnName: string) => {
    removeViewFilterItem(columnName);
    removeColumnFilterItem(columnName);
  };

  const isInputError = (): boolean => {
    const targetElm = document.querySelector('.FilterArea');
    return includeInputValidateError(targetElm, intl, []);
  };

  return (
    <>
      {isRequestBodyFilter && (
        <div className="FilterArea">
          <div className="scroll-area">
            {viewFilterRequestBodyItem.length > 0 && (
              <div className="select-control">
                <div className="filter-area-title">固定フィルタ</div>
                <div className="filter-area">
                  <div className="filter-area-description">
                    指定の条件でデータベースからデータを取得します。
                  </div>
                  {viewFilterRequestBodyItem.map(({ value }) => {
                    return (
                      <div
                        className={`filter-input ${
                          isFilterAreaOpen ? `` : 'off'
                        }`}
                        key={value}
                      >
                        <FilterInputView
                          column={value}
                          presetItem={presetItem}
                          filterValue={filterValue}
                          onChangeFilter={(v) => {
                            onChangeFilter && onChangeFilter(v);
                          }}
                          viewId={viewId}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            <div className="select-control">
              <div className="filter-area-title">任意フィルタ</div>
              <div className="filter-area">
                <div className="filter-area-description">
                  抽出したデータに対してフィルタを設定します。
                </div>
                {viewFilterItem.map(({ value }) => {
                  const isDisabled = checkDisalbedPresetProperty(
                    value,
                    presetItem?.children
                  );
                  return (
                    <div
                      className={`filter-input ${
                        isFilterAreaOpen ? `` : 'off'
                      }`}
                      key={value}
                    >
                      <FilterInputView
                        column={value}
                        presetItem={presetItem}
                        filterValue={filterValue}
                        onChangeFilter={(v) => {
                          onChangeFilter && onChangeFilter(v);
                        }}
                        viewId={viewId}
                      />
                      {!isDisabled && (
                        <Cancel
                          onClick={() => {
                            handleRemoveItem(value);
                          }}
                          className="filter-clear"
                        />
                      )}
                    </div>
                  );
                })}
                <div className="select-area">
                  <div className="add">
                    <DataFilterbox
                      data={filterboxItemDatas}
                      value={filterboxValues}
                      multiple={false}
                      searchOption={{ targets: 'displayName' }}
                      name="filterItem"
                      columns={['filterItem']}
                      onChangeState={(v) => {
                        setFilterboxValues(v);
                      }}
                    ></DataFilterbox>
                    <IconWithCaptionButton
                      name="filterAddBtn"
                      buttonType="basic"
                      caption="追加"
                      iconType="add"
                      className="btn-normal"
                      onClick={() => {
                        handleAppendItem();
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="btn-area">
            <CaptionButton
              name="cancelBtn"
              buttonType="cancel"
              caption="キャンセル"
              className="button btn-normal"
              onClick={() => {
                close();
              }}
            />

            <CaptionButton
              name="filterRemoveBtn"
              buttonType="cancel"
              caption="初期フィルタに戻す"
              className="button btn-normal"
              onClick={() => {
                handleInitializeFilter();
              }}
            />
            <CaptionButton
              name="applyBtn"
              buttonType="basic"
              caption="適用"
              className="button btn-normal"
              onClick={() => {
                if (isInputError()) {
                  return;
                }
                send();
                close();
              }}
            />
          </div>
        </div>
      )}
      {!isRequestBodyFilter && (
        <div className="FilterArea">
          <div className="select-area">
            <div className="add">
              <DataFilterbox
                data={filterboxItemDatas}
                value={filterboxValues}
                multiple={false}
                searchOption={{ targets: 'displayName' }}
                name="filterItem"
                columns={['filterItem']}
                onChangeState={(v) => {
                  setFilterboxValues(v);
                }}
              ></DataFilterbox>
              <IconWithCaptionButton
                name="filterAddBtn"
                buttonType="basic"
                caption="追加"
                iconType="add"
                className="btn-normal"
                onClick={() => {
                  handleAppendItem();
                }}
              />
            </div>
            <div className="rescission">
              <IconWithCaptionButton
                name="filterRemoveBtn"
                buttonType="cancel"
                caption="解除"
                iconType="clear"
                className="btn-normal"
                onClick={() => {
                  handleInitializeFilter();
                }}
              />
            </div>
          </div>
          <div className="select-control">
            {viewFilterItem.map(({ value }) => {
              const isDisabled = checkDisalbedPresetProperty(
                value,
                presetItem?.children
              );
              return (
                <div
                  className={`filter-input ${isFilterAreaOpen ? `` : 'off'}`}
                  key={value}
                >
                  <FilterInputView
                    column={value}
                    presetItem={presetItem}
                    filterValue={filterValue}
                    onChangeFilter={(v) => {
                      onChangeFilter && onChangeFilter(v);
                    }}
                    viewId={viewId}
                  />
                  {!isDisabled && (
                    <Cancel
                      onClick={() => {
                        handleRemoveItem(value);
                      }}
                      className="filter-clear"
                    />
                  )}
                </div>
              );
            })}
          </div>
          <div className="btn-area">
            <IconWithCaptionButton
              name="cancelBtn"
              buttonType="cancel"
              caption="キャンセル"
              iconType="clear"
              className="button btn-normal"
              onClick={() => {
                close();
              }}
            />

            <IconWithCaptionButton
              name="applyBtn"
              buttonType="basic"
              caption="適用"
              iconType="add"
              className="button btn-normal"
              onClick={() => {
                send();
                close();
              }}
            />
          </div>
        </div>
      )}
    </>
  );
}
