import React, { useState } from 'react';
import { Checkbox } from '~/shared/components/ui';
import { CaptionButton } from '~/shared/components/ui/Button';
import {
  AttributeValues,
  AttributeInputDialog,
  AttributeInputDialogFormConfig,
} from '~/shared/components/ui/Dialog/AttributeInputDialog';
import { mtechnavi } from '~/shared/libs/clientsdk';

export const UISampleAttributeInputDialogs = () => {
  const [result, setResult] = useState('');
  const [isOpen, setOpen] = useState(false);
  const [formConfig, setFormConfig] =
    useState<AttributeInputDialogFormConfig>();
  const [isInitialInputs, setInitialInputs] = useState(false);
  const [readonly, setReadonly] = useState(false);

  const handleDialogOpen = () => {
    setFormConfig({
      formSetting: DummyFormSetting,
      formSettingItems: DummyFormSettingItems,
      assetSystemCode: 'B10',
    });
    setOpen(true);
  };

  return (
    <div className="categories">
      <h3 id="dialog">属性情報入力ダイアログ</h3>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <Checkbox
              name=""
              columns={['']}
              items={[{ displayName: '初期値セット', value: '1' }]}
              onChangeState={(v) => {
                setInitialInputs(!!v.length);
              }}
            />
            <Checkbox
              name=""
              columns={['']}
              items={[{ displayName: '確認モード', value: '1' }]}
              onChangeState={(v) => {
                setReadonly(!!v.length);
              }}
            />
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setResult('');
                handleDialogOpen();
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">{result}</pre>
          </div>
        </div>
      </div>
      <AttributeInputDialog
        key={isOpen ? '1' : '0'}
        isOpen={isOpen}
        formConfig={formConfig}
        inputOption={{
          initialValues: isInitialInputs
            ? DummyInitialInput
            : { headerValues: { categoryCode: '_BRANCH' } },
          readonly,
        }}
        messageOption={{
          headerLabelId: {
            prefixId: 'DIALOG_TITLE',
            id: readonly
              ? 'AttributeConfirmationDialog'
              : 'AttributeInputDialog',
          },
        }}
        onDecision={(...args) => {
          setResult(JSON.stringify({ args }, null, 2));
          setOpen(false);
        }}
        onCancel={() => setOpen(false)}
      />
    </div>
  );
};

const DummyForm: mtechnavi.api.form.IForm = {
  formId: 'dummyformid1',
  baseFormId: 'dummybaseformid2',
  formProperties: {
    displayName: 'サンプルフォーム',
  },
};

const DummyFormSetting: mtechnavi.api.form.IFormSetting = {
  formSettingId: 'dummyformsettingid',
  formId: DummyForm.formId,
  formPages: [
    {
      formPageId: 'dummyformpageid',
      displayName: 'page_1',
    },
  ],
};

const DummyFormSettingItems: mtechnavi.api.form.IFormSettingItem[] = [
  {
    formSettingItemId: 'dummyformsettingitemid1',
    formId: DummyForm.formId,
    formSettingId: DummyFormSetting.formSettingId,
    formPageId: DummyFormSetting.formPages![0].formPageId,
    item: {
      itemAutoName: 'autoname1',
      inputType: 'text',
      displayName: 'text1',
    },
  },
  {
    formSettingItemId: 'dummyformsettingitemid2',
    formId: DummyForm.formId,
    formSettingId: DummyFormSetting.formSettingId,
    formPageId: DummyFormSetting.formPages![0].formPageId,
    item: {
      itemAutoName: 'autoname2',
      inputType: 'text',
      displayName: 'text2',
    },
  },
];

const DummyInitialInput: AttributeValues = {
  headerValues: {
    categoryCode: '_ORGANIZATION',
    systemAttribute: true,
    usable: true,
    key: 'AAAA',
    displayName: '組織名',
  },
  detailValues: [
    {
      formSettingItemId: DummyFormSettingItems[0].formSettingItemId,
      itemAutoName: DummyFormSettingItems[0].item?.itemAutoName,
      input: {
        inputType: 'text',
        controlValue: '{"displayName":"テスト1"}',
      },
    },
    {
      formSettingItemId: DummyFormSettingItems[1].formSettingItemId,
      itemAutoName: DummyFormSettingItems[1].item?.itemAutoName,
      input: {
        inputType: 'text',
        controlValue: '{"displayName":"テスト2"}',
      },
    },
  ],
};
