import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface UpdateOcpProfileSystemNotificationSetting {
  actionName: 'updateOcpProfileSystemNotificationSetting';
  request: mtechnavi.api.worker.ISystemNotificationSetting;
}

export const updateOcpProfileSystemNotificationSetting = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.worker.ISystemNotificationSetting
) => {
  const getFullMethodName =
    'mtechnavi.api.company.BusinessUnitManagementService/GetBusinessUnitProfile';
  const updateFullMethodName =
    'mtechnavi.api.company.BusinessUnitManagementService/UpdateSystemNotificationUsersInBusinessUnitProfile';

  const items = await worker.invokeListRequest<
    mtechnavi.api.company.IGetBusinessUnitProfileRequest,
    mtechnavi.api.company.BusinessUnitProfile
  >(getFullMethodName, {
    businessUnitProfileId: req.surveyReceptionId,
  });
  if (items && items.length > 0) {
    const item = items[0];
    const users = item.systemNotificationUsers;

    // 追加ユーザーが渡された場合
    if (req.appendUser) {
      if (!users.find((user) => user.userId === req.appendUser?.userId)) {
        users.push(req.appendUser);
      }
      item.systemNotificationUsers = users;
    }
    // 削除ユーザーが渡された場合
    if (req.removeUser) {
      const deleteUsers = users.filter(
        (user) => user.userId !== req.removeUser?.userId
      );
      item.systemNotificationUsers = deleteUsers;
    }
    // 自社基本情報情報を更新
    await worker.invokeListRequest<
      mtechnavi.api.company.IUpdateSystemNotificationUsersInBusinessUnitProfileRequest,
      mtechnavi.api.company.BusinessUnitProfile
    >(updateFullMethodName, {
      businessUnitProfileId: item.businessUnitProfileId,
      systemNotificationUsers: item.systemNotificationUsers,
      updatedAt: item.updatedAt,
    });
  }
};
