import { CSSProperties, useMemo } from 'react';
import { Property } from '~/shared/services';
import { getProperty } from '../common';
import { LinkTextbox as LinkTextboxComponent } from '../../parts/LinkTextbox/LinkTextbox';
import { GetMessageComponent } from '../..';

export interface LinkTextProps {
  name: string;
  className?: string;
  labelId?: string;
  value?: string | null;
  columns?: string[];
  properties?: Property[];
  isExternalLink?: boolean;
}

export function LinkTextbox(props: LinkTextProps) {
  // _disabledは使用しないが、戻り値にあるためlint除外
  // eslint-disable-next-line  @typescript-eslint/no-unused-vars
  const [componentStyle, viewStyle, _disabled] = useMemo<
    [CSSProperties, CSSProperties, boolean]
  >(() => {
    return getProperty(props.name, props.columns, props.properties);
  }, [props.properties, props.columns, props.name]);
  return (
    <div style={viewStyle}>
      <span className={props.labelId !== '' ? 'caption' : 'caption no-name'}>
        {props.labelId && (
          <div>
            <div className="topping-label disabled-topping-label">
              {props.labelId !== '' ? (
                <GetMessageComponent id={props.labelId} />
              ) : (
                ''
              )}
            </div>
          </div>
        )}
      </span>
      <LinkTextboxComponent
        value={props.value}
        name={props.name}
        className={props.className}
        style={componentStyle}
        isExternalLink={props.isExternalLink}
      />
    </div>
  );
}
