import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface CompleteEstimateManagementRequest {
  actionName: 'completeEstimateManagement';
  request: CompleteEstimateManagementParam;
}
interface CompleteEstimateManagementParam {
  estimateManagementIds: string[];
  comment: string;
}

export const completeEstimateManagement = async (
  worker: AppSaveWorker,
  req: CompleteEstimateManagementParam
) => {
  const FullMethodName_CompleteEstimateManagement =
    'mtechnavi.api.estimation.EstimateSenderService/CompleteEstimateManagement';
  const FullMethodName_SharetoComplete =
    'mtechnavi.api.estimation.EstimationService/SharetoComplete';

  const results = await Promise.all(
    req.estimateManagementIds.map((estimateManagementId) =>
      worker.invokeListRequest<
        mtechnavi.api.estimation.ICompleteEstimateManagementRequest,
        mtechnavi.api.estimation.ICompleteEstimateManagementResponse
      >(FullMethodName_CompleteEstimateManagement, {
        estimateManagementId: estimateManagementId,
        comment: req.comment,
      })
    )
  );
  return await Promise.all(
    results.map((result) =>
      worker.invokeListRequest<
        mtechnavi.api.estimation.ISharetoCompleteRequest,
        mtechnavi.api.estimation.ISharetoCompleteResponse
      >(FullMethodName_SharetoComplete, {
        complete: result[0].complete,
      })
    )
  );
};
