import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface SaveSurveyBaseRequest {
  actionName: 'saveSurveyBaseRequest';
  request: mtechnavi.api.survey.ISurveyBaseRequest;
}

// 依頼登録
export const saveSurveyBaseRequest = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.survey.ISurveyBaseRequest
) => {
  const createFullMethodName =
    'mtechnavi.api.survey.SurveySenderService/CreateSurveyBaseRequest';

  const updateFullMethodName =
    'mtechnavi.api.survey.SurveySenderService/UpdateSurveyBaseRequest';

  if (req?.surveyBaseRequestId === '' || req?.surveyBaseRequestId === null) {
    return await worker.invokeListRequest<
      mtechnavi.api.survey.ICreateSurveyBaseRequestRequest,
      mtechnavi.api.survey.ISurveyBaseRequest
    >(createFullMethodName, {
      surveyBaseRequest: req,
    });
  } else {
    return await worker.invokeListRequest<
      mtechnavi.api.survey.IUpdateSurveyBaseRequestRequest,
      mtechnavi.api.survey.ISurveyBaseRequest
    >(updateFullMethodName, {
      surveyBaseRequest: req,
    });
  }
};
