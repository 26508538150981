import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface ExecDeleteWorkTaskCatalogRequest {
  actionName: 'deleteWorkTaskCatalog';
  request: mtechnavi.api.worktask.IWorkTaskCatalog[];
}

// 指図案件カタログ削除
export const execDeleteWorkTaskCatalog = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.worktask.IWorkTaskCatalog[]
) => {
  const fullMethodName =
    'mtechnavi.api.worktask.WorkTaskService/DeleteWorkTaskCatalog';

  type deleteRequestType = mtechnavi.api.worktask.IDeleteWorkTaskCatalogRequest;
  const result = req.map((item) => {
    const target = item as mtechnavi.api.worktask.IWorkTaskCatalog;
    return worker.invokeListRequest(fullMethodName, {
      workTaskCatalog: target as deleteRequestType,
    });
  });

  await Promise.all(result);
};
