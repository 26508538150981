import {
  Container,
  GetMessageWithIntl,
  MessageProps,
  error,
  Toast,
} from '~/shared/components';
import {
  GroupTreeEditor,
  GroupTreeItem,
} from '~/shared/components/ui/GroupTreeEditor';
import { useAuth } from '~/shared/contexts/AuthProvider';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  OrganizationDetailDialog,
  OrganizationDetailDialogMode,
} from '~/shared/components/ui/Dialog/OrganizationDetailDialog';
import { OrganizationMemberDialog } from '~/shared/components/ui/Dialog/OrganizationMemberDialog';
import { useLoading } from '~/shared/contexts/LoadingProvider';
import { ConfirmationDialog, ImportDialog } from '~/shared/components/ui';
import { useIntl } from 'react-intl';
import {
  ViewId,
  getExceptionMessage,
  getPresetAndSchema,
  getWorkerExceptionMessage,
} from '~/shared/utils';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { convertGroupTree } from './util';
import {
  FullMethodName_ListOrganizationRelations,
  FullMethodName_ListOrganizations,
  FullMethodName_SharedListLicenses,
} from '~/worker';
import { Property } from '~/shared/services';

const MAX_HIERARCHY = 10;
const VIEW_ID: ViewId = 'OCP_ORGANIZATION_TREE_CONFIRMATION';
const LICENSE_CODE_NITERRA = '_NITERRA_COMPANY';
const EDITABLE_ROLE = 'Role-Organization-Editor';

export const OcpOrganizationEdit = () => {
  const auth = useAuth();
  const intl = useIntl();
  const { showLoading, hideLoading } = useLoading();
  const [originData, setOriginData] = useState<
    mtechnavi.api.company.IOrganization[]
  >([]);
  const [orgTreeData, setOrgTreeData] = useState<
    GroupTreeItem<mtechnavi.api.company.IOrganization>[]
  >([]);
  const [orgRelationData, setOrgRelationData] = useState<
    mtechnavi.api.company.IOrganizationRelation[]
  >([]);
  const allOrganizationIds = useMemo(
    () => orgRelationData.map((item) => item.organizationId ?? ''),
    [orgRelationData]
  );

  const [detailMode, setDetailMode] =
    useState<OrganizationDetailDialogMode>('add');
  const [targetData, setTargetData] =
    useState<mtechnavi.api.company.IOrganization>();
  const [targetParents, setTargetParents] = useState<
    mtechnavi.api.company.IOrganization[]
  >([]);
  const [isOpenOrgDetailDialog, setOpenOrgDetailDialog] = useState(false);
  const [isOpenOrgMemberDialog, setOpenOrgMemberDialog] = useState(false);
  const [isOpenImportDialog, setOpenImportDialog] = useState(false);
  const [memberUserList, setMemberUserList] =
    useState<mtechnavi.api.company.IOrganizationRelation[]>();
  const [licenseList, setLicenseList] =
    useState<mtechnavi.api.license.ILicense[]>();

  // 確認ダイアログ
  const [isOpenConfirmDialog, setOpenConfirmDialog] = useState(false);
  // 確認ダイアログメッセージ
  const [confirmMessage, setConfirmMessage] = useState<MessageProps>({});
  const confirmPromiseRef =
    useRef<(value: boolean | PromiseLike<boolean>) => void>();

  // 出力用プリセット
  const [presetItems, setPresetItems] = useState<Property[]>([]);

  // const companyName = auth.tenant?.displayName || '';
  const [companyName, setCompanyName] = useState<string>('');
  // 編集権限有無
  const isEditable =
    (auth.roles || []).findIndex((role) => role.roleName === EDITABLE_ROLE) !==
    -1;

  // ライセンス
  const isNiterra =
    (licenseList || []).findIndex(
      (license) => license.licenseCode === LICENSE_CODE_NITERRA
    ) !== -1;

  useEffect(() => {
    showLoading();
    (async () => {
      try {
        // データの取得
        await Promise.all([loadInitialData(), loadData()]);
      } catch (err) {
        error(getExceptionMessage(intl, err));
        throw err;
      } finally {
        hideLoading();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadInitialData = async () => {
    const [licenseResp, preset, companyResp] = await Promise.all([
      fetchLicenses(),
      getPresetAndSchema(VIEW_ID, [FullMethodName_ListOrganizations]),
      window.App.services.ui.getMyCompany(),
    ]);
    setLicenseList(licenseResp.items || []);

    // プリセットから取込のオプション設定を取得して保持
    const optionalPreset = preset.childrenPresetItem?.find(
      (v) => v.name === 'organizationRelationImport'
    )?.property;
    setPresetItems(
      optionalPreset ?? [{ name: '', propertyName: '', propertyValue: '' }]
    );
    setCompanyName(
      companyResp.displayNameLang
        ? companyResp.displayNameLang[intl.locale]
        : ''
    );
  };

  const loadData = async () => {
    const [orgResp, orgRelationResp] = await Promise.all([
      fetchOrganization(),
      fetchOrganizationRelation(),
    ]);
    setOrganizationData(orgResp.items);
    setOrgRelationData(orgRelationResp.items);
  };

  // 組織の取得
  const fetchOrganization = () =>
    window.App.services.ui.worker.filter<mtechnavi.api.company.IOrganization>({
      action: 'reload',
      fullMethodName: FullMethodName_ListOrganizations,
      filter: {},
      sort: [{ code: 'asc' }],
    });

  // 組織所属情報の取得
  const fetchOrganizationRelation = () =>
    window.App.services.ui.worker.filter<mtechnavi.api.company.IOrganizationRelation>(
      {
        action: 'reload',
        fullMethodName: FullMethodName_ListOrganizationRelations,
        filter: {},
        sort: [],
      }
    );

  // ライセンスの取得
  const fetchLicenses = () =>
    window.App.services.ui.worker.filter({
      action: 'reload',
      fullMethodName: FullMethodName_SharedListLicenses,
      filter: {},
      sort: [],
    }) as mtechnavi.api.license.ISharedListLicensesResponse;

  const traceParent = (
    target: mtechnavi.api.company.IOrganization | undefined
  ): mtechnavi.api.company.IOrganization[] => {
    if (!target || !target.parentOrganization?.organizationId) {
      return [];
    }
    const parent = originData.find(
      (item) =>
        item.organizationId === target.parentOrganization?.organizationId
    );
    if (!parent) {
      return [{ displayName: companyName }];
    }
    const parents = traceParent(parent);
    return [...parents, parent];
  };

  const isDuplicationCode = (orgCode: string): boolean => {
    return originData.some((item) => item.code === orgCode);
  };

  const setOrganizationData = (
    flatData: mtechnavi.api.company.IOrganization[]
  ) => {
    setOriginData(flatData);
    const treeData = convertGroupTree(flatData);
    setOrgTreeData(treeData);
  };

  // 追加
  const handleAddChild = (
    target: mtechnavi.api.company.IOrganization | undefined
  ) => {
    const parents = traceParent(target);
    if (parents.length + 1 >= MAX_HIERARCHY) {
      error([
        GetMessageWithIntl(intl, {
          id: 'E0000149',
          value: { $1: MAX_HIERARCHY },
        }),
      ]);
      return;
    }
    setTargetData(target);
    setTargetParents([...parents, ...(target ? [{ ...target }] : [])]);
    setDetailMode('add');
    setOpenOrgDetailDialog(true);
  };
  const handleAddChildDecision = async (
    newOrg: mtechnavi.api.company.IOrganization
  ) => {
    if (!newOrg.code || isDuplicationCode(newOrg.code)) {
      error(['使用されている組織コードです']);
      return;
    }
    try {
      showLoading();
      await window.App.services.ui.worker.apiCall({
        actionName: 'saveOrganizations',
        request: { organization: newOrg },
      });
      setOpenOrgDetailDialog(false);
      await loadData();
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      hideLoading();
    }
  };

  // 詳細表示
  const handleDetail = (
    target: mtechnavi.api.company.IOrganization | undefined
  ) => {
    setTargetData(target);
    setTargetParents(traceParent(target));
    setDetailMode('show');
    setOpenOrgDetailDialog(true);
  };

  // 編集
  const handleEdit = (
    target: mtechnavi.api.company.IOrganization | undefined
  ) => {
    setTargetData(target);
    setTargetParents(traceParent(target));
    setDetailMode('edit');
    setOpenOrgDetailDialog(true);
  };
  const handleEditDecision = async (
    editOrg: mtechnavi.api.company.IOrganization
  ) => {
    try {
      showLoading();
      await window.App.services.ui.worker.apiCall({
        actionName: 'saveOrganizations',
        request: { organization: editOrg },
      });
      setOpenOrgDetailDialog(false);
      await loadData();
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      hideLoading();
    }
  };

  // 削除
  const handleDelete = async (
    target: mtechnavi.api.company.IOrganization | undefined
  ) => {
    const message = {
      id: 'C0000001',
      value: { $1: GetMessageWithIntl(intl, { id: 'delete' }) },
    };
    if (!(await confirmation(message))) {
      return;
    }
    try {
      showLoading();
      await window.App.services.ui.worker.apiCall({
        actionName: 'deleteOrganizations',
        request: { organizationId: target?.organizationId },
      });
      await loadData();
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      hideLoading();
    }
  };

  // グループ内ユーザ表示・編集
  const handleShowMember = async (
    target: mtechnavi.api.company.IOrganization | undefined
  ) => {
    const orgUsers = orgRelationData.filter(
      (rel) => rel.organizationId === target?.organizationId
    );
    setMemberUserList(orgUsers);
    setTargetData(target);
    setTargetParents(traceParent(target));
    setOpenOrgMemberDialog(true);
  };
  const handleMemberEditDecision = async (
    result: mtechnavi.api.company.IOrganizationRelation[]
  ) => {
    try {
      showLoading();
      await window.App.services.ui.worker.apiCall({
        actionName: 'saveOrganizationUsers',
        request: {
          organizationId: targetData?.organizationId,
          items: result,
        },
      });
      setOpenOrgMemberDialog(false);
      await loadData();
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      hideLoading();
    }
  };

  const handleImport = () => {
    console.log('handleImport');
    setOpenImportDialog(true);
  };
  const handleImportSuccess = async () => {
    showLoading();
    try {
      await loadData();
      setOpenImportDialog(false);
    } catch (err) {
      error(getExceptionMessage(intl, err));
      throw err;
    } finally {
      hideLoading();
    }
  };

  // 決定時の処理分岐用
  const orgDetailDecisionMap = {
    add: handleAddChildDecision,
    edit: handleEditDecision,
    show: () => {},
  };

  /**
   * 確認ダイアログ処理
   * Promise で結果を制御する。
   * 確定: true / キャンセル: false
   */
  const confirmation = (viewMessage: MessageProps) => {
    setOpenConfirmDialog(true);
    setConfirmMessage(viewMessage);
    return new Promise<boolean>((resolve) => {
      confirmPromiseRef.current = resolve;
    });
  };

  /**
   * 購買グループ・変更可否で表示するダイアログのラベル情報を返却する
   */
  const getMemerEditDialogLabel = (procurementOption: boolean) => {
    if (isEditable) {
      return procurementOption
        ? 'ProcurementOrgMemberEditDialog'
        : 'MemberEditDialog';
    } else {
      return procurementOption
        ? 'ProcurementOrgMemberConfirmationDialog'
        : 'MemberConfirmationDialog';
    }
  };

  return (
    <>
      <Container viewId={VIEW_ID}>
        <div className="OrganizationTreeSamplePage">
          <GroupTreeEditor
            rootLabel={companyName}
            data={orgTreeData}
            isEditable={isEditable}
            editActions={{
              onDetail: handleDetail,
              onEdit: handleEdit,
              onDelete: handleDelete,
              onEditMember: handleShowMember,
              onAddChild: handleAddChild,
            }}
            onImport={handleImport}
            isShowAddChild={(data) => !data?.procurementOption}
          />
        </div>
        <Toast />
      </Container>
      <>
        {/* 組織編集ダイアログ */}
        <OrganizationDetailDialog
          key={isOpenOrgDetailDialog ? '1' : '0'} // 開閉する度に初期化する
          isOpen={isOpenOrgDetailDialog}
          isUseProcurementOption={isNiterra}
          mode={detailMode}
          parents={targetParents}
          inputOption={targetData && { ...targetData }}
          onDecision={orgDetailDecisionMap[detailMode]}
          onCancel={() => setOpenOrgDetailDialog(false)}
        />
      </>
      <>
        {/* 所属アカウント編集ダイアログ */}
        <OrganizationMemberDialog
          isOpen={isOpenOrgMemberDialog}
          mode={isEditable ? 'edit' : 'show'}
          isProcurement={!!targetData?.procurementOption}
          inputOption={{
            organizations: [
              ...(targetParents || []),
              ...(targetData ? [targetData] : []),
            ],
            memberUserList,
          }}
          messageOption={{
            headerLabelId: {
              prefixId: 'DIALOG_TITLE',
              id: getMemerEditDialogLabel(!!targetData?.procurementOption),
            },
          }}
          onDecision={handleMemberEditDecision}
          onCancel={() => setOpenOrgMemberDialog(false)}
        />
      </>
      {/* 所属アカウント取込ダイアログ */}
      <ImportDialog
        isOpen={isOpenImportDialog}
        headerLabelId={{
          prefixId: 'DIALOG_TITLE',
          id: 'MemberImportDialog',
        }}
        allIds={allOrganizationIds}
        ids={allOrganizationIds}
        preset={presetItems}
        isFileTypeSelectBox={isNiterra}
        userMasterCategoryName="A0000045"
        isAllIdOnly={true}
        handleExport={{ name: 'organizationRelations', headerColumns: [] }}
        handleImport={{ name: 'organizationRelations', headerColumns: [] }}
        onChangeState={() => {
          setOpenImportDialog(false);
        }}
        onHandleSuccess={handleImportSuccess}
        onChangeLoadingState={(isShowLoading) =>
          isShowLoading ? showLoading() : hideLoading()
        }
      />
      {/* 確認ダイアログ */}
      <ConfirmationDialog
        isOpen={isOpenConfirmDialog}
        viewMessage={confirmMessage}
        onDecision={() => {
          if (confirmPromiseRef.current) {
            confirmPromiseRef.current(true);
          }
          setOpenConfirmDialog(false);
        }}
        onCancel={() => {
          if (confirmPromiseRef.current) {
            confirmPromiseRef.current(false);
          }
          setOpenConfirmDialog(false);
        }}
      />
    </>
  );
};
