import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface DeleteFlagInSurveyRequestRequest {
  actionName: 'deleteFlagInSurveyRequest';
  request: DeleteFlagInSurveyRequestParam;
}

interface DeleteFlagInSurveyRequestParam {
  surveyRequestIds: string[];
}

export const deleteFlagInSurveyRequest = async (
  worker: AppSaveWorker,
  req: DeleteFlagInSurveyRequestParam
) => {
  const listFullMethodName =
    'mtechnavi.api.survey.SurveySenderService/ListSurveyRequestContents';
  const updateFullMethodName =
    'mtechnavi.api.survey.SurveySenderService/UpdateFlagInSurveyRequest';

  const items = await worker.invokeListRequest<
    mtechnavi.api.survey.IListSurveyRequestContentsRequest,
    mtechnavi.api.survey.ISurveyRequestContent
  >(listFullMethodName, {
    dataFilter: { surveyRequestIds: req.surveyRequestIds ?? [] },
  });
  if (items && items.length > 0) {
    const result = items
      .filter((item) => item.surveyRequest?.flagProperties?.active)
      .map((item) => {
        return worker.invokeListRequest<
          mtechnavi.api.survey.IUpdateFlagInSurveyRequestRequest,
          void
        >(updateFullMethodName, {
          surveyRequestId: item.surveyRequest?.surveyRequestId ?? '',
          active: false,
          comment: '',
          updatedAt: item.surveyRequest?.updatedAt,
        });
      });

    await Promise.all(result);
  }
};
