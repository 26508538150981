import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface SaveOcpProfile {
  actionName: 'saveOcpProfile';
  request: mtechnavi.api.company.IBusinessUnitProfile;
}

export const saveOcpProfile = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.company.IBusinessUnitProfile
) => {
  const createMethodName =
    'mtechnavi.api.company.BusinessUnitManagementService/CreateBusinessUnitProfile';
  const updateMethodName =
    'mtechnavi.api.company.BusinessUnitManagementService/UpdateBusinessUnitProfile';

  if (req.businessUnitProfileId) {
    const result = await worker.invokeListRequest(updateMethodName, {
      businessUnitProfile: req,
    });
    return result;
  } else {
    const result = await worker.invokeListRequest(createMethodName, {
      businessUnitProfile: req,
    });
    return result;
  }
};
