import { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { FullMethodName_ListBusinessUnitPublicDocuments } from '~/worker';
import {
  Container,
  GetMessageWithIntl,
  Toast,
  error,
  success,
} from '~/shared/components';
import {
  CommentDialog,
  ListView,
  MenuActionItem,
  Preset,
  Property,
  PublicDocument,
  PublicDocumentRegistrationDialog,
  PublicDocumentRegistrationDialogInputType,
  PublicDocumentRegistrationDialogOutputOption,
  ViewMenu,
  getAltDisplaySchema,
  getDayFormetterDisplaySchema,
} from '~/shared/components/ui';
import { useAuth } from '~/shared/contexts/AuthProvider';
import { useLoading } from '~/shared/contexts/LoadingProvider';
import { useConfirmation } from '~/shared/hooks';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { PresetItem } from '~/shared/services';
import {
  ViewId,
  getPresetAndSchema,
  getWorkerExceptionMessage,
  saveLocalStorageCheckboxData,
} from '~/shared/utils';

const VIEW_ID: ViewId = 'BUM_BUSINESS_UNIT_PUBLIC_DOCUMENTS_LIST';
type BusinessUnitPublicDocument =
  mtechnavi.api.company.IBusinessUnitPublicDocument;
export const BusinessUnitPublicDocumentsList = () => {
  const { showLoading, hideLoading } = useLoading();
  const intl = useIntl();
  const myEmail = useAuth().user?.email ?? '';
  const [childrenPresetItem, setChildrenPresetItem] = useState<PresetItem[]>();
  const [schema, setSchema] = useState<Property[]>([]);
  const [preset, setPreset] = useState<Preset>({
    filter: {},
    propertyNames: [],
  });
  const [isReload, setReload] = useState(false);

  // 公開資料登録ダイアログ
  const [
    isPublicDocumentRegistrationDialogOpen,
    setPublicDocumentRegistrationDialogOpen,
  ] = useState(false);
  const [dialogInputType, setDialogInputType] =
    useState<PublicDocumentRegistrationDialogInputType>('add');
  const [dialogInputItem, setDialogInputItem] = useState<PublicDocument>({});
  // 確認ダイアログ
  const { confirmation, confirmationElement } = useConfirmation();
  const deleteMessage = { id: 'C0000001', value: { $1: '削除' } };
  const deleteMessageWithMessage = { id: 'C0000003', value: { $1: '削除' } };
  const selectedId = useRef<string>('');
  const businessUnitPublicDocumentList = useRef<BusinessUnitPublicDocument[]>(
    []
  );
  const toastSuccess = GetMessageWithIntl(intl, { id: 'I0000001' });

  useEffect(() => {
    (async () => {
      try {
        const { childrenPresetItem, schemas, preset } =
          await getPresetAndSchema(VIEW_ID, [
            FullMethodName_ListBusinessUnitPublicDocuments,
          ]);
        let schema = schemas[0];
        // ja変換
        const jaColumn: string[] = ['status.displayNameLang'];
        schema = getAltDisplaySchema(schema, jaColumn, 'ja');
        // 日付フォーマット
        const dateColumn = ['releaseStartDt', 'releaseEndDt'];
        schema = getDayFormetterDisplaySchema(schema, dateColumn, {
          dayOpts: {
            formatType: 'YYYY/MM/DD',
            isAccuracy: true,
          },
        });

        // 取得した情報をセット
        setChildrenPresetItem(childrenPresetItem);
        setSchema(schema);
        setPreset(preset);
      } catch (err) {
        error(getWorkerExceptionMessage(intl, err));
        throw err;
      }
    })();
  }, [intl]);

  const setMenuActionItem = (): MenuActionItem[] => {
    const menuActionItems: MenuActionItem[] = [];
    menuActionItems.push({
      menuActionType: 'headerIconMenu',
      menu: headerIconEvent(),
      maxMenuColumn: 5,
    });
    menuActionItems.push({
      menuActionType: 'listIconMenu',
      menu: listIconEvent(),
    });
    return menuActionItems;
  };

  const headerIconEvent = (): ViewMenu[] => {
    const menuItems: ViewMenu[] = [];
    menuItems.push({
      name: 'noteadd',
      func: () => {
        setDialogInputType('add');
        setPublicDocumentRegistrationDialogOpen(true);
      },
    });
    // 削除
    menuItems.push({
      name: 'delete',
      func: async (ids?: string[]) => {
        if (isSelectError(ids)) {
          return;
        }
        handleDelete();
      },
    });
    return menuItems;
  };

  const listIconEvent = (): ViewMenu[] => {
    const menuItems: ViewMenu[] = [];
    menuItems.push({
      name: 'description',
      func: (ids?: string[]) => {
        if (isSelectError(ids)) {
          return;
        }
        setDialogInputType('display');
        const targetItem = findBusinessUnitPublicDocument(selectedId.current);
        setDialogInputItem({ ...targetItem });
        setPublicDocumentRegistrationDialogOpen(true);
      },
    });
    return menuItems;
  };

  const isSelectError = (ids?: string[]) => {
    if (!ids || ids.length === 0) {
      error([GetMessageWithIntl(intl, { id: 'E0000023' })]);
      return true;
    }
    if (ids.length > 1) {
      error([GetMessageWithIntl(intl, { id: 'E0000073' })]);
      return true;
    }
    const targetItem = findBusinessUnitPublicDocument(ids[0]);
    if (!targetItem) {
      return true;
    }
    selectedId.current = ids[0];
    return false;
  };

  const handleSave = async (v: PublicDocument) => {
    showLoading();
    setReload(false);

    let request: mtechnavi.api.company.IBusinessUnitPublicDocument = {};
    if (dialogInputType === 'display') {
      const base = findBusinessUnitPublicDocument(selectedId.current);
      request = { ...base };
    }
    (request.displayName = v.displayName ?? ''),
      (request.contents = v.contents ?? ''),
      (request.attachments = v.attachments || []),
      (request.releaseStartDt = v.releaseStartDt),
      (request.releaseEndDt = v.releaseEndDt);
    try {
      await window.App.services.ui.worker.apiCall({
        actionName: 'saveBusinessUnitPublicDocument',
        request,
      });
      success([toastSuccess]);
      clearCheckBox();
      setReload(true);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
    }
    setPublicDocumentRegistrationDialogOpen(false);
    hideLoading();
  };

  const handleDelete = async () => {
    const target = findBusinessUnitPublicDocument(selectedId.current);
    const message =
      target?.statusSystemName === 'B01'
        ? deleteMessageWithMessage
        : deleteMessage;
    if (!(await confirmation(message))) {
      return;
    } else {
      handleDeleteAction();
    }
  };

  const handleDeleteAction = async () => {
    showLoading();
    setReload(false);

    const businessUnitPublicDocument =
      findBusinessUnitPublicDocument(selectedId.current) || {};
    try {
      await window.App.services.ui.worker.apiCall({
        actionName: 'deleteBusinessUnitPublicDocument',
        request: businessUnitPublicDocument,
      });
      success([toastSuccess]);
      clearCheckBox();
      setPublicDocumentRegistrationDialogOpen(false);
      setReload(true);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
    }
    setPublicDocumentRegistrationDialogOpen(false);
    hideLoading();
  };

  const clearCheckBox = useCallback(() => {
    saveLocalStorageCheckboxData(VIEW_ID, [], myEmail);
  }, [myEmail]);

  const onOriginalItem = (items: unknown) => {
    businessUnitPublicDocumentList.current =
      items as unknown as BusinessUnitPublicDocument[];
  };

  const findBusinessUnitPublicDocument = (
    businessUnitPublicDocumentId: string
  ) => {
    return businessUnitPublicDocumentList.current.find(
      (v) => v.businessUnitPublicDocumentId === businessUnitPublicDocumentId
    );
  };

  return (
    <>
      <Container viewId={VIEW_ID}>
        <div className="bcpDisasterSurveyList">
          <ListView
            pageInfo={{
              schema: schema,
              preset: preset,
              menuItem: setMenuActionItem(),
              menuTarget: 'businessUnitPublicDocumentId',
              headerTitle: { viewId: VIEW_ID },
              presetItems: childrenPresetItem,
              listSkipType: {
                isTotal: true,
                isOutput: true,
                isListActionMenu: true,
              },
            }}
            isReload={isReload}
            fullMethodName={FullMethodName_ListBusinessUnitPublicDocuments}
            stateOption={{
              onOriginalItemState: onOriginalItem,
            }}
          />
        </div>
        {confirmationElement}
        <PublicDocumentRegistrationDialog
          isOpen={isPublicDocumentRegistrationDialogOpen}
          inputOption={{
            inputType: dialogInputType,
            publicDocument: dialogInputType === 'add' ? null : dialogInputItem,
          }}
          onDecision={async (
            v: PublicDocumentRegistrationDialogOutputOption
          ) => {
            if (v.deleteFlag) {
              await handleDelete();
            } else {
              await handleSave(v.publicDocument);
            }
          }}
          onCancel={() => setPublicDocumentRegistrationDialogOpen(false)}
        />
        <Toast />
      </Container>
    </>
  );
};
