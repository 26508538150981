import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface DeleteBusinessUnitDocument {
  actionName: 'deleteBusinessUnitDocument';
  request: mtechnavi.api.company.IBusinessUnitDocument;
}

export const deleteBusinessUnitDocument = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.company.IBusinessUnitDocument
) => {
  // 文書情報削除
  const fullMethodName =
    'mtechnavi.api.company.BusinessUnitManagementService/DeleteBusinessUnitDocument';
  const result = worker.invokeListRequest(fullMethodName, {
    businessUnitDocument: req,
  });

  // アセット情報削除
  const getAssetFullMethodName =
    'mtechnavi.api.assetinventory.AssetInventory/GetAsset';
  const deleteAssetFullMethodName =
    'mtechnavi.api.assetinventory.AssetInventory/DeleteAsset';

  const items = await worker.invokeListRequest<
    mtechnavi.api.assetinventory.IAsset,
    mtechnavi.api.assetinventory.Asset
  >(getAssetFullMethodName, {
    assetId: req.attachment?.assetId,
  });

  if (items.length > 0) {
    return await worker.invokeListRequest(deleteAssetFullMethodName, {
      asset: items[0],
    });
  }

  await result;
};
