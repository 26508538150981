import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface SaveOcpFinancial {
  actionName: 'saveOcpFinancial';
  request: SaveOcpFinancialRequestParam;
}

interface SaveOcpFinancialRequestParam {
  businessUnitFinancials: mtechnavi.api.company.IBusinessUnitFinancial[];
  companyId: string;
}

export const saveOcpFinancial = async (
  worker: AppSaveWorker,
  req: SaveOcpFinancialRequestParam
) => {
  const ListMethodName =
    'mtechnavi.api.company.BusinessUnitManagementService/ListBusinessUnitFinancials';
  const createMethodName =
    'mtechnavi.api.company.BusinessUnitManagementService/CreateBusinessUnitFinancial';
  const updateMethodName =
    'mtechnavi.api.company.BusinessUnitManagementService/UpdateBusinessUnitFinancial';
  const deleteMethodName =
    'mtechnavi.api.company.BusinessUnitManagementService/DeleteBusinessUnitFinancial';

  const financialList = (await worker.invokeListRequest(ListMethodName, {
    companyIds: [req.companyId],
  })) as mtechnavi.api.company.IBusinessUnitFinancial[];

  const result = req.businessUnitFinancials.map((item) => {
    if (item.businessUnitFinancialId) {
      console.info('saveOcpFinancial updateMethodName');
      return worker.invokeListRequest(updateMethodName, {
        businessUnitFinancial: item,
      });
    } else {
      console.info('saveOcpFinancial createMethodName');
      return worker.invokeListRequest(createMethodName, {
        businessUnitFinancial: item,
      });
    }
  });
  await Promise.all(result);

  const deleteCheckList = req.businessUnitFinancials.map((e) => {
    return e.businessUnitFinancialId;
  });

  const deleteResult = financialList.map((financial) => {
    if (!deleteCheckList.includes(financial.businessUnitFinancialId)) {
      console.info(financial);
      console.info('saveOcpFinancial deleteMethodName');
      return worker.invokeListRequest(deleteMethodName, {
        businessUnitFinancial: financial,
      });
    }
  });
  await Promise.all(deleteResult);
};
