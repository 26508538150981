import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface SaveOcpBranch {
  actionName: 'saveOcpBranch';
  request: SaveOcpBranchRequestParam;
}

interface SaveOcpBranchRequestParam {
  businessUnitBranchs: mtechnavi.api.company.IBusinessUnitBranch[];
  companyId: string;
}

export const saveOcpBranch = async (
  worker: AppSaveWorker,
  req: SaveOcpBranchRequestParam
) => {
  const ListMethodName =
    'mtechnavi.api.company.BusinessUnitManagementService/ListBusinessUnitBranchs';
  const createMethodName =
    'mtechnavi.api.company.BusinessUnitManagementService/CreateBusinessUnitBranch';
  const updateMethodName =
    'mtechnavi.api.company.BusinessUnitManagementService/UpdateBusinessUnitBranch';
  const deleteMethodName =
    'mtechnavi.api.company.BusinessUnitManagementService/DeleteBusinessUnitBranch';

  const branchList = (await worker.invokeListRequest(ListMethodName, {
    companyIds: [req.companyId],
  })) as mtechnavi.api.company.IBusinessUnitBranch[];

  const result = req.businessUnitBranchs.map((item) => {
    if (item.businessUnitBranchId) {
      console.info('saveOcpBranch updateMethodName');
      return worker.invokeListRequest(updateMethodName, {
        businessUnitBranch: item,
      });
    } else {
      console.info('saveOcpBranch createMethodName');
      return worker.invokeListRequest(createMethodName, {
        businessUnitBranch: item,
      });
    }
  });
  await Promise.all(result);

  const deleteCheckList = req.businessUnitBranchs.map((e) => {
    return e.businessUnitBranchId;
  });
  const deleteResult = branchList.map((branch) => {
    if (!deleteCheckList.includes(branch.businessUnitBranchId)) {
      console.info('saveOcpBranch deleteMethodName');
      return worker.invokeListRequest(deleteMethodName, {
        businessUnitBranch: branch,
      });
    }
  });
  await Promise.all(deleteResult);
};
