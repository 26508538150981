import { mtechnavi, sharelib } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface ActivateMainContactInBusinessUnitContact {
  actionName: 'activateMainContactInBusinessUnitContact';
  request: BusinessUnitContactParam;
}

interface BusinessUnitContactParam {
  tenantStatus?: sharelib.INameOption;
  businessUnitContactId: string;
}

export const activateMainContactInBusinessUnitContact = async (
  worker: AppSaveWorker,
  req: BusinessUnitContactParam
) => {
  const activateMethodName =
    'mtechnavi.api.company.BusinessUnitManagementService/ActivateMainContactInBusinessUnitContact';

  return await worker.invokeListRequest(activateMethodName, {
    businessUnitContactId: req.businessUnitContactId,
  });
};
