import * as pdfjs from 'pdfjs-dist';

export class PDFRenderer {
  doc?: pdfjs.PDFDocumentProxy;

  pages?: pdfjs.PDFPageProxy[];

  constructor(public src: string) {}

  async load(): Promise<void> {
    const task = await pdfjs.getDocument({
      url: this.src,
      useWorkerFetch: true,
      // pdfjsに同梱のcmapファイル群を指定
      // Note: pdfjsはApache-2.0ライセンス
      cMapPacked: true,
      cMapUrl: '/assets/Viewer/cmaps/',
    });
    const doc = await task.promise;
    const pageTasks = [];
    for (let i = 0; i < doc.numPages; i++) {
      pageTasks.push(doc.getPage(i + 1));
    }
    this.pages = await Promise.all(pageTasks);
    // this.docで初期化判定をしているため、最後に設定
    this.doc = doc;
  }

  maxPageNumber(): number {
    return this.doc?.numPages ?? 0;
  }

  render(
    ctx: CanvasRenderingContext2D,
    pageNumber: number
  ): [Promise<void>, () => void] {
    const state: {
      timeoutId?: number;
    } = {};
    const cancel = () => {
      window.clearTimeout(state.timeoutId);
    };
    const promise = new Promise<void>((resolve) => {
      const fn = async () => {
        if (!this.doc) {
          return (state.timeoutId = window.setTimeout(fn, 0));
        }
        const page = this.pages![pageNumber - 1];
        const el = ctx.canvas;
        // キャンバスサイズをPDFに合わせる
        const ratio = window.devicePixelRatio || 1;
        const vp = page.getViewport({
          scale: 1,
        });
        ctx.canvas.width = Math.floor(vp.width * ratio);
        ctx.canvas.height = Math.floor(vp.height * ratio);

        const viewport = fitViewport(page, {
          width: el.width,
          height: el.height,
        });
        const task = page.render({
          canvasContext: ctx,
          viewport,
        });
        await task.promise;

        resolve();
      };
      state.timeoutId = window.setTimeout(fn, 0);
    });
    return [promise, cancel];
  }
}

function fitViewport(
  page: pdfjs.PDFPageProxy,
  dst: { width: number; height: number }
): pdfjs.PageViewport {
  const viewport = page.getViewport({
    scale: 1,
  });
  const xScale = dst.width / viewport.width;
  const yScale = dst.height / viewport.height;
  return page.getViewport({
    scale: Math.min(xScale, yScale),
  });
}
