import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface SaveOcpSkillAction {
  actionName: 'saveOcpSkill';
  request: mtechnavi.api.company.IBusinessUnitSkill;
}

export const saveOcpSkill = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.company.IBusinessUnitSkill
) => {
  if (req.businessUnitSkillId) {
    return await worker.invokeListRequest<
      mtechnavi.api.company.IUpdateBusinessUnitSkillRequest,
      mtechnavi.api.company.IBusinessUnitSkill
    >(
      'mtechnavi.api.company.BusinessUnitManagementService/UpdateBusinessUnitSkill',
      {
        businessUnitSkill: {
          businessUnitSkillId: req.businessUnitSkillId,
          companyId: req.companyId,
          businessUnitSkillItemIds: req.businessUnitSkillItemIds,
          otherSkill: req.otherSkill,
          updatedAt: req.updatedAt,
        },
      }
    );
  }
  return await worker.invokeListRequest<
    mtechnavi.api.company.ICreateBusinessUnitSkillRequest,
    mtechnavi.api.company.IBusinessUnitSkill
  >(
    'mtechnavi.api.company.BusinessUnitManagementService/CreateBusinessUnitSkill',
    {
      businessUnitSkill: {
        companyId: req.companyId,
        businessUnitSkillItemIds: req.businessUnitSkillItemIds,
        otherSkill: req.otherSkill,
      },
    }
  );
};
