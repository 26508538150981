import {
  ChangeEvent,
  DragEvent,
  Ref,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
  useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import { ReactComponent as AttachFileIcon } from '@material-design-icons/svg/filled/attach_file.svg';
import { SimpleListView } from '~/shared/components/ui';
import { ErrorMessage } from '~/shared/components/parts/ErrorMessage/ErrorMessage';
import {
  GetMessageWithIntl,
  MessageProps,
} from '~/shared/components/parts/Message/Message';
import {
  FileItem,
  FileUploaderDnDOption,
  FileUploaderResultListOption,
  FileUploaderValidateOption,
} from './CommonType';
import {
  fileListToArray,
  DEFAULT_MAX_FILE_LIMIT,
  UNIT_MiB,
  uploadFiles,
  validateFile,
} from './Utils';
import './ExtendFileUploader.css';

export interface ExtendFileUploaderRef {
  /** アップロード結果リストをクリアする */
  clear(): void;
  /** アップロード成功したファイルだけを結果リストから抜き出し、結果リストから削除する */
  picksSuccessful: () => FileItem[];
}

export interface ExtendFileUploaderMessageOption {
  uploadLabelId?: MessageProps;
  omitAttachedIcon?: boolean;
}

export interface ExtendFileUploaderProps {
  name: string;
  className?: string;
  messageOption?: ExtendFileUploaderMessageOption;
  validateOption?: FileUploaderValidateOption;
  dndOption?: FileUploaderDnDOption;
  resultOption?: FileUploaderResultListOption;
  multiple?: boolean;
  disabled?: boolean;
  onUpload?: (v: FileItem[]) => void;
  onChangeState?: (arg: boolean) => void;
  onChangeLoadingState?: (arg: boolean) => void;
}

type ViewFileStatus = 'OK' | 'READY' | 'NG';

export interface ViewFileItem {
  key: string;
  status: ViewFileStatus;
  filename: string;
  file: File;
  url?: string;
}

export const fileStatusToViewFileStatus = (
  status: FileItem['status']
): ViewFileStatus => {
  switch (status) {
    case 'OK':
      return status;
    case 'uploading':
      return 'READY';
    case 'failure':
      return 'NG';
  }
};

export const ExtendFileUploader = forwardRef(
  (
    {
      name,
      className,
      messageOption,
      validateOption,
      dndOption,
      resultOption,
      multiple,
      disabled,
      onUpload,
      onChangeState,
      onChangeLoadingState,
    }: ExtendFileUploaderProps,
    ref: Ref<ExtendFileUploaderRef>
  ) => {
    const intl = useIntl();
    const [fileItems, setFileItems] = useState<FileItem[]>([]);
    const [viewFileItems, setViewFileItems] = useState<ViewFileItem[]>([]);
    const [message, setMessage] = useState<string[]>([]);

    const onParentChangeState = useCallback(
      (arg: boolean) => {
        onChangeState && onChangeState(arg);
      },
      [onChangeState]
    );

    const onParentLoadingState = useCallback(
      (arg: boolean) => {
        onChangeLoadingState && onChangeLoadingState(arg);
      },
      [onChangeLoadingState]
    );

    const validateAndUpload = async (fileList: FileList) => {
      // Filelistを配列に変換
      const l = fileListToArray(fileList);

      // ファイル数のチェック
      const maxFileLimit =
        validateOption?.maxFileCount ||
        (!multiple ? 1 : DEFAULT_MAX_FILE_LIMIT);
      if (l.length > maxFileLimit) {
        setMessage([
          GetMessageWithIntl(intl, {
            id: 'E0000077',
            value: { $1: maxFileLimit },
          }),
        ]);
        return;
      }

      // ファイルサイズ及び拡張子のバリデーション実施
      const [files, msg] = validateFile(
        intl,
        l,
        validateOption?.allowedFileExtensions ?? [],
        (validateOption?.maxFileSizeInMebis ?? 0) * UNIT_MiB
      );
      setMessage(msg);

      if (files.length === 0) {
        // アップロードすべきファイルがなければearlyReturn
        return;
      }

      // アップロード処理
      return uploadFiles(files, setFileItems, intl);
    };

    // D&Dでのファイルアップロード処理
    const handleFileDrop = async (evt: DragEvent<HTMLDivElement>) => {
      onParentChangeState(false);

      if (disabled) {
        return;
      }
      evt.preventDefault();

      // アップロード処理
      const result = await validateAndUpload(evt.dataTransfer.files);
      if (!result) {
        return;
      }

      await onParentChangeState(true);
      evt.dataTransfer.clearData();
    };

    // input要素からのファイルアップロード処理
    const handleFileChange = async (evt: ChangeEvent<HTMLInputElement>) => {
      onParentChangeState(false);

      // ファイルがない場合は処理終了
      if (!evt.target.files) {
        return;
      }

      // アップロード処理
      onParentLoadingState(true);
      const result = await validateAndUpload(evt.target.files);
      onParentLoadingState(false);

      if (!result) {
        return;
      }

      await onParentChangeState(true);
      evt.target.value = '';
    };

    const handleRemove = (item: ViewFileItem) => {
      setFileItems(fileItems.filter((v) => v.file !== item.file));
      onParentChangeState(false);
    };

    useEffect(() => {
      onUpload && onUpload(fileItems);
    }, [fileItems, onUpload]);

    useEffect(() => {
      setViewFileItems(
        fileItems
          .sort((a, b) => {
            // uploading > failure > OK の順でソートする
            return b.status.charCodeAt(0) - a.status.charCodeAt(0);
          })
          .map(
            (item): ViewFileItem => ({
              key: item.file.name,
              status: fileStatusToViewFileStatus(item.status),
              filename: item.file.name,
              file: item.file,
              url: item.url,
            })
          )
      );
    }, [fileItems]);

    useImperativeHandle(ref, () => ({
      clear() {
        setFileItems([]);
      },
      picksSuccessful: () => {
        setFileItems(fileItems.filter((item) => item.status === 'failure'));
        return fileItems.filter((item) => item.status === 'OK');
      },
    }));

    const uploadeLabelId = useMemo(() => {
      const msgId: string[] = [];
      if (messageOption?.uploadLabelId) {
        msgId.push(messageOption.uploadLabelId.prefixId ?? '');
        msgId.push(messageOption.uploadLabelId.viewId ?? '');
        msgId.push(messageOption.uploadLabelId.id ?? '');
      } else {
        msgId.push('FILE_UPLOAD');
        msgId.push('file-chooser');
      }
      return msgId.filter((v) => !!v).join('.');
    }, [messageOption?.uploadLabelId]);

    // ファイルアップ機能
    const renderFileUploadButton = () => {
      return (
        <div className={`${disabled ? 'disabled' : ''}`}>
          <label className="file-chooser">
            {GetMessageWithIntl(intl, { id: uploadeLabelId })}
            {!messageOption?.omitAttachedIcon && <AttachFileIcon />}
            <input
              type="file"
              name={name}
              multiple={multiple}
              onChange={handleFileChange}
              disabled={disabled}
            />
          </label>
        </div>
      );
    };

    const renderUploadArea = (acceptDnd: boolean) => {
      if (!acceptDnd) {
        return renderFileUploadButton();
      } else {
        return (
          <div
            className={`dnd-area ${dndOption?.dndAreaClassName}`}
            onDrop={handleFileDrop}
            onDragOver={(e) => {
              if (!disabled) {
                e.preventDefault();
              }
            }}
          >
            <div className="FileUploaderSelectParts">
              <p>
                {GetMessageWithIntl(intl, {
                  id: 'description1',
                  prefixId: 'FILE_UPLOAD',
                })}
              </p>
              <div>
                {GetMessageWithIntl(intl, {
                  id: 'description2',
                  prefixId: 'FILE_UPLOAD',
                })}
                {renderFileUploadButton()}
              </div>
            </div>
          </div>
        );
      }
    };

    return (
      <div className={`ExtendFileUploader ${className || ''}`}>
        {/* アップロード */}
        {renderUploadArea(!!dndOption?.enabled)}

        {/* バリデーションエラーメッセージ */}
        {message.length !== 0 && (
          <ErrorMessage message={message}></ErrorMessage>
        )}

        {/* アップロード関係メッセージ */}
        {resultOption?.previewRowCount !== 0 && (
          <SimpleListView
            data={viewFileItems}
            viewOptions={{
              readonly: fileItems.some((file) => file.status === 'uploading'),
              omitHeader: true,
              omitFooter:
                !dndOption?.enabled || resultOption?.omitFooter || !multiple,
              keyColumn: 'key',
              previewRowCount: resultOption?.previewRowCount,
              columns: [
                {
                  propertyName: 'status',
                  width: '5.5rem',
                },
                {
                  propertyName: 'filename',
                },
              ],
            }}
            actionOptions={{
              onDelete: handleRemove,
            }}
          />
        )}
      </div>
    );
  }
);
