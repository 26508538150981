import { GroupTreeItem } from './type';

export interface GroupTreeNodeRef {
  open?: () => void;
  close?: () => void;
  allOpen?: () => void;
  allClose?: () => void;
  focus?: () => void;
}

export const groupTreeSearch = <T>(
  tree: GroupTreeItem<T>[],
  searchWord: string
): string => {
  let matchId = '';
  tree.forEach((item) => {
    if (matchId) {
      return;
    }
    if (isMatchItem(item, searchWord)) {
      matchId = item.id || '';
      return;
    }
    if (item.children) {
      const result = groupTreeSearch(item.children, searchWord);
      matchId = result;
    }
  });
  return matchId;
};

export const isMatchItem = <T>(
  item: GroupTreeItem<T>,
  searchWord: string
): boolean => {
  return (
    !!(item.displayName ?? '')?.match(searchWord) ||
    !!(item.code ?? '')?.match(searchWord)
  );
};
