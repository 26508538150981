import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface DeleteAttribute {
  actionName: 'deleteAttribute';
  request: mtechnavi.api.programoption.IAttribute[];
}

export const deleteAttribute = async (
  worker: AppSaveWorker,
  request: mtechnavi.api.programoption.IAttribute[]
) => {
  const fullMethodName =
    'mtechnavi.api.programoption.AttributeService/DeleteAttribute';
  const result = request.map((attribute) => {
    return worker.invokeListRequest(fullMethodName, {
      attribute: attribute,
    });
  });
  await Promise.all(result);
};
