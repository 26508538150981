import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface CreateForumThreadRequest {
  actionName: 'createForumThread';
  request: mtechnavi.api.forum.ICreateThreadRequest;
}

export const createForumThread = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.forum.ICreateThreadRequest
) => {
  return await worker.invokeListRequest<
    mtechnavi.api.forum.ICreateThreadRequest,
    mtechnavi.api.forum.IThread
  >('mtechnavi.api.forum.ForumService/CreateThread', req);
};
