import { useState, useEffect, useMemo } from 'react';
import {
  Container,
  ModalDialogComponent,
  ModalDialogComponentProps,
  Toast,
  success,
  error,
  GetMessage,
  LoadingIcon,
} from '~/shared/components';
import {
  MenuListView,
  ConfirmationDialog,
  Preset,
  MenuItem,
  Property,
  Textbox,
  Textarea,
  Schema,
  Formatter,
  getAltDisplaySchema,
  getDayFormetterDisplaySchema,
} from '~/shared/components/ui';
import { mtechnavi, sharelib } from '~/shared/libs/clientsdk';
import { FileItem, FileUploader } from '~/shared/components/file/FileUploader';
import { ImportDialogHandleFormat } from '~/shared/components/ui/Dialog/ImportDialog';

import './BlueprintBlueprintList.css';
import { PresetItem, Property as PresetProperty } from '~/shared/services';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';

import type { FilterRequest } from '~/worker';
import { FILE_VIEWER_LOCALE_STORAGE_PREFIX } from '~/shared/components/ui/Viewer/FileViewer';
import {
  getExceptionMessage,
  getPresetAndSchema,
  PageState,
  setLocalStorage,
  openWindowOrTab,
  FullMethodName_ListBlueprints,
  convertUserReference,
} from '~/shared/utils';
import { CaptionButton } from '~/shared/components/ui/Button/CaptionButton';
import { useAuth } from '~/shared/contexts/AuthProvider';

const VIEW_ID = 'BLUEPRINT_BLUEPRINT_LIST';

export function BlueprintBlueprintList() {
  const myEmail = useAuth().user?.email ?? '';
  const intl = useIntl();
  const navi = useNavigate();
  const successMessage = GetMessage({
    id: 'I0000001',
  });
  const confirmMessage = {
    id: 'C0000001',
    value: { $1: '改訂登録' },
  };
  const [childrenPresetItem, setChildrenPresetItem] = useState<PresetItem[]>();
  const [schema, setSchema] = useState<Property[]>([]);
  const [preset, setPreset] = useState<Preset>({
    filter: {},
    propertyNames: [],
  });
  const [createItems, setCreateItem] =
    useState<mtechnavi.api.blueprint.IBlueprint>();
  const [fieldcolumns, setFieldcolumns] = useState<string[]>([]);
  const [baseModalIsOpen, setModalIsOpen] = useState(false);
  const [isAddDialog, setAddDialog] = useState(false);
  const [comment, setComment] = useState('');
  const [revisionNumber, setRevisionNumber] = useState('');
  const [revisionComment, setRevisionComment] = useState('');
  const [isReload, setReload] = useState(false);
  const [isLoading, setLoading] = useState(false);
  // 各種ボタン操作関係
  const [uploadFile, setUploadFile] = useState<FileItem[]>([]);

  const openRevisionModal = (ids?: string[]) => {
    (async () => {
      try {
        const item = await window.App.services.blueprintService.getBlueprint({
          blueprintId: ids![0],
        });
        setRevisionNumber(String(item.revision + 1));
        setRevisionComment('');
        setCreateItem(item);
      } catch (err) {
        error(getExceptionMessage(intl, err));
        throw err;
      }
    })();
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setAddDialog(false);
    setUploadFile([]);
    setRevisionNumber('');
    setRevisionComment('');
    setCreateItem({});
  };

  const setData = (assetId?: string): mtechnavi.api.blueprint.IBlueprint => {
    const setItem = { ...createItems };
    setItem.blueprintId = '';
    setItem.revision = Number(revisionNumber);
    setItem.revisionProperties = {
      ...setItem.revisionProperties,
      comment: revisionComment,
    };
    // ファイル選択されている場合
    if (assetId) {
      setItem.blueprintFile = {
        assetId: assetId,
      };
    }
    return setItem;
  };

  const createAsset = (
    item: FileItem
  ): Promise<mtechnavi.api.assetinventory.Asset> => {
    const purposeUnits: sharelib.INameOption[] =
      window.App.services.ui.getNameOptionWithSystemName('A0000033', 'B01');
    const asset: mtechnavi.api.assetinventory.IAsset = {
      displayName: item.file.name,
      filename: item.file.name,
    };
    asset.purposeUnits = purposeUnits;

    return window.App.services.assetInventory.createAsset({
      asset: asset,
      sourceUrl: item.url,
    });
  };
  const createBlueprint = (
    assetId?: string
  ): Promise<mtechnavi.api.blueprint.Blueprint> => {
    return window.App.services.blueprintService.createBlueprint({
      blueprint: setData(assetId),
    });
  };

  const createAssetAndBlueprint = () => {
    // データアクションの追加予定
    (async () => {
      setLoading(true);
      try {
        let assetId = '';
        // ファイルが指定されている場合
        if (uploadFile[0]) {
          const asset = await createAsset(uploadFile[0]);
          assetId = asset.assetId;
        }
        await createBlueprint(assetId);
      } catch (err) {
        console.error(err);
        error(getExceptionMessage(intl, err));
        throw err;
      } finally {
        setLoading(false);
      }
      success([successMessage]);
      closeModal();
      setReload(true); // listの更新
    })();
  };

  // ファイルアップロード処理（オブジェクトストレージのtmp領域）
  const handleUpload = (v: FileItem[]) => {
    if (!v.length) {
      return;
    }
    setUploadFile(v);
  };

  // 取り込みダイアログ関係
  const [dialogPreset, setDialogPreset] = useState<PresetProperty[]>([
    { name: '', propertyName: '', propertyValue: '' },
  ]);
  const [isDialogOpen, setDialogOpen] = useState(false);

  useMemo(() => {
    const dialogPreset = childrenPresetItem?.find(
      (v) => v.name === 'blueprintBlueprintListImport'
    );
    setDialogPreset(
      dialogPreset?.property ?? [
        { name: '', propertyName: '', propertyValue: '' },
      ]
    );

    // preset 変更時に起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preset, childrenPresetItem]);

  useEffect(() => {
    (async () => {
      try {
        // スキーマ情報、preset関係の情報を取得
        const { childrenPresetItem, schemas, preset } =
          await getPresetAndSchema(VIEW_ID, [FullMethodName_ListBlueprints]);
        setFieldcolumns(preset.propertyNames);

        const hasCadDataFormatter = (name: string): Formatter => {
          return (value: object): string => {
            const hasCad = value[name as keyof typeof value] as boolean;
            return hasCad ? '○' : '-';
          };
        };
        const getHasCadDataFormetterSchema = (
          sch: Schema,
          targetColumn: string[]
        ) => {
          return sch.map((v) => {
            if (targetColumn.includes(v.name)) {
              return {
                ...v,
                formatter: hasCadDataFormatter(v.name),
              };
            } else {
              return v;
            }
          });
        };
        // ja表示のみする処理
        const jaColumn = [
          'displayNameLang',
          'blueprintStatus.status.displayNameLang',
        ];
        const formatterSch = getAltDisplaySchema(schemas[0], jaColumn, 'ja');
        const dayCololmns = ['revisionProperties.revisedAt'];
        const daySch = getDayFormetterDisplaySchema(formatterSch, dayCololmns, {
          dayOpts: { formatType: 'YYYY/MM/DD HH:mm' },
        });
        const blueprintSch = getHasCadDataFormetterSchema(daySch, [
          'partDrawingProperties.hasCadData',
        ]);
        // 取得した情報をセット
        setChildrenPresetItem(childrenPresetItem);
        setSchema(blueprintSch);
        setPreset(preset);
      } catch (err) {
        error(getExceptionMessage(intl, err));
        throw err;
      }
    })();
  }, [intl]);

  // APIが実装後に必要部分を移行して削除
  // const handleExport = async (ids: string[]) => {
  //   const componentUnitIds =
  //     await window.App.services.componentunitService.exportComponentUnits({
  //       ids,
  //     });
  //   return componentUnitIds.assetId;
  // };
  // const handleImport = async (url: string, isDryRun: boolean) => {
  //   const fileFormat = await window.App.services.ui.getFileFormat('blueprint');
  //   const uploadRequest = {
  //     url,
  //     fileFormat: fileFormat,
  //     dryRun: isDryRun,
  //     exportError: true,
  //   };
  //   const result = await window.App.services.blueprintService.importBlueprints(
  //     uploadRequest
  //   );

  //   return result.errors as sharelib.ImportError[];
  // };

  const handleExport: ImportDialogHandleFormat = {
    name: 'blueprint',
    headerColumns: [],
  };
  const handleImport: ImportDialogHandleFormat = {
    name: 'blueprint',
    headerColumns: [],
  };

  // サンプルでバーガーメニューに関数を渡すためのサンプル関数(ダミー関数)
  const handleMenu = (prop?: string[]) => {
    console.debug('preset menuFunc:');
    console.debug(prop);
  };

  // メニューの「確認」処理
  const handleMenuConfirm = (prop?: string[]) => {
    setLoading(true);
    const state: PageState = {
      ids: prop ?? [],
    };
    setLoading(false);
    navi('/blueprint-unit', {
      state,
    });
  };

  // メニューの「管理場所取込処理
  const handleMenuImport = () => {
    // 取り込みダイアログ表示
    setDialogOpen(true);
  };

  // ビューワー処理
  const handleMenuViewer = async (v: string[]) => {
    setLoading(true);
    // blueprintIDを元にassetIDを取得
    const option: FilterRequest = {
      action: 'query',
      fullMethodName: FullMethodName_ListBlueprints,
      filter: {
        blueprintId: {
          $in: v,
        },
      },
      sort: [],
    };
    const bluePrint = (await window.App.services.ui.worker.filter(
      option
    )) as mtechnavi.api.blueprint.ListBlueprintsResponse;
    const assetIds = bluePrint.items.map((v) => v.blueprintFile?.assetId);
    const key = setLocalStorage(
      FILE_VIEWER_LOCALE_STORAGE_PREFIX,
      (assetIds ?? []) as string[]
    );
    setLoading(false);
    openWindowOrTab('file-viewer', key);
  };

  const burgerMenuEvent = (): MenuItem[] => {
    // 必要な関数を宣言
    const menuItems: MenuItem[] = [];
    // 確認
    menuItems.push({
      name: 'confirm',
      func: (v?: string[]) => handleMenuConfirm(v),
    });
    menuItems.push({ name: 'add', func: (v?: string[]) => handleMenu(v) });
    // 改訂登録
    menuItems.push({
      name: 'blueprintRevise',
      func: (v?: string[]) => openRevisionModal(v),
    });
    menuItems.push({ name: 'delete', func: (v?: string[]) => handleMenu(v) });
    menuItems.push({
      name: 'tag',
      func: (v?: string[]) => {
        handleMenu(v);
      },
    });
    // 図面取込
    menuItems.push({
      name: 'import',
      func: () => handleMenuImport(),
    });
    menuItems.push({ name: 'edit', func: (v?: string[]) => handleMenu(v) });
    menuItems.push({
      name: 'viewerAll',
      func: (v?: string[]) => handleMenuViewer(v ?? []),
    });
    menuItems.push({
      name: 'viewer',
      func: (v?: string[]) => handleMenuViewer(v ?? []),
    });
    return menuItems;
  };
  const menuViewlist = burgerMenuEvent().map((v) => v.name);

  const DaialogContent = () => {
    return (
      <div className="modal-body">
        <div className="revision-add-content">
          <div className="input-area">
            <div className="inputfields group">
              <div className="small">
                <Textbox
                  name="revisedRegistration.displayNameLang"
                  type="text"
                  value={createItems?.displayNameLang?.ja}
                  labelId="revisedRegistration.displayNameLang"
                  columns={fieldcolumns}
                  disabled={true}
                />
              </div>
              <div className="small">
                <Textbox
                  name="revisedRegistration.blueprintNumber"
                  type="text"
                  value={createItems?.blueprintNumber}
                  labelId="revisedRegistration.blueprintNumber"
                  columns={fieldcolumns}
                  disabled={true}
                />
              </div>
              <div className="small">
                <Textbox
                  name="revisedRegistration.branchNumber"
                  type="text"
                  value={createItems?.branchNumber}
                  labelId="revisedRegistration.branchNumber"
                  columns={fieldcolumns}
                  disabled={true}
                />
              </div>
              <div className="small">
                <Textbox
                  name="revisedRegistration.revision"
                  type="text"
                  value={revisionNumber}
                  labelId="revisedRegistration.revision"
                  columns={fieldcolumns}
                  disabled={true}
                />
              </div>
            </div>
            <div className="inputfields group">
              <div className="field fileUpload">
                <h3 className="item-name">図面改訂</h3>
                <p className="item-disc">図面ファイル</p>
                <FileUploader
                  name="fileUploader"
                  className="btnType"
                  multiple={false}
                  onUpload={handleUpload}
                  onChangeLoadingState={setLoading}
                />
              </div>
            </div>
            <div className="inputfields group">
              <div className="field textarea">
                <Textarea
                  name="revisionProperties.comment"
                  labelId="revisionProperties.comment"
                  columns={fieldcolumns}
                  value={revisionComment}
                  onChangeState={setRevisionComment}
                  validateOption={{ maxLength: 1000 }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="btn-area">
          <CaptionButton
            name="resetBtn"
            caption="キャンセル"
            className="button-margin"
            properties={[
              {
                name: 'resetBtn',
                propertyName: 'resetBtn',
                propertyValue: 'resetBtn',
              },
            ]}
            onClick={() => closeModal()}
            buttonType="cancel"
          />
          <CaptionButton
            name="resetBtn"
            caption="確定"
            className="button-margin"
            properties={[
              {
                name: 'resetBtn',
                propertyName: 'resetBtn',
                propertyValue: 'resetBtn',
              },
            ]}
            onClick={() => setAddDialog(true)}
            buttonType="basic"
          />
        </div>
      </div>
    );
  };

  const openModalProps: ModalDialogComponentProps = {
    modalIsOpen: baseModalIsOpen,
    comment: comment,
    onChangeState: setComment,
    headerLabelId: { prefixId: 'DIALOG_ADD', viewId: VIEW_ID },
    messageLabelId: { prefixId: 'DIALOG_MESSAGE', viewId: VIEW_ID },
    send: () => {},
    cancel: () => setModalIsOpen(false),
    elements: DaialogContent(),
  };

  return (
    <>
      <Container viewId={VIEW_ID}>
        <div className="BlueprintBlueprintList">
          <MenuListView
            headerTitle={{ viewId: VIEW_ID }}
            isReload={isReload}
            fullMethodName={FullMethodName_ListBlueprints}
            schema={schema}
            preset={preset}
            presetItems={childrenPresetItem}
            menuEvent={burgerMenuEvent()}
            listSkipType={{
              isTotal: true,
              isOutput: true,
            }}
            menuTarget={'blueprintId'}
            menuViewlist={menuViewlist}
            importDialogOption={{
              isDisplay: true,
              isDialogOpen,
              dialogPreset,
              headerLabelId: {
                id: 'importBlueprintBlueprintListDialog',
                viewId: VIEW_ID,
              },
              handleExport,
              handleImport,
              onChangeState: setDialogOpen,
            }}
          />
          <ModalDialogComponent {...openModalProps} />
          <ConfirmationDialog
            isOpen={isAddDialog}
            messageLabelId={{ prefixId: 'DIALOG_MESSAGE', viewId: VIEW_ID }}
            viewMessage={confirmMessage}
            onDecision={() => createAssetAndBlueprint()}
            onCancel={() => setAddDialog(false)}
          />
        </div>
        <Toast />
      </Container>
      {isLoading && <LoadingIcon />}
    </>
  );
}
