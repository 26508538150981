import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';
import { FullMethodName_SendEmailAccountCreated } from 'worker/fullMethodName/crud';

/** アカウント作成通知メール送信 */
export const sendEmailAccountCreated = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.admin.ISendEmailAccountCreatedRequest
) => {
  return await worker.invokeListRequest<
    mtechnavi.api.admin.ISendEmailAccountCreatedRequest,
    mtechnavi.api.admin.ISendEmailAccountCreatedResponse
  >(FullMethodName_SendEmailAccountCreated, req);
};
