import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface UpdateSurveyResultWorkFormRequest {
  actionName: 'updateSurveyResultWorkForm';
  request: mtechnavi.api.survey.IUpdateSurveyResultWorkFormRequest;
}

// ResultWorkForm の更新
export const updateSurveyResultWorkForm = (
  worker: AppSaveWorker,
  req: mtechnavi.api.survey.IUpdateSurveyResultWorkFormRequest
) => {
  const methodName =
    'mtechnavi.api.survey.SurveySenderService/UpdateSurveyResultWorkForm';

  return worker.invokeListRequest<
    mtechnavi.api.survey.IUpdateSurveyResultWorkFormRequest,
    mtechnavi.api.survey.SurveyResultWorkForm
  >(methodName, req);
};
