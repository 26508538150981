import {
  ForwardedRef,
  PropsWithoutRef,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { CaptionButton } from '~/shared/components/ui/Button';
import {
  GetMessageWithIntl,
  MessageProps,
  ModalDialogComponent,
  ModalDialogComponentProps,
  error,
} from '~/shared/components';
import { useIntl } from 'react-intl';
import { BuildedForm, BuildedFormRef } from './parts/BuildedForm';
import './BuildedInputFormDialog.css';
import {
  CommmonAttachedFileSystemCode,
  getWorkerExceptionMessage,
} from '~/shared/utils';
import { FormInputInformation } from './parts/FormInputInformation';
import { Checkbox } from '../Checkbox';
import { WorkFormTypes } from './utils';
import { FileItem } from '../../file';

export interface BuildedInputFormDialogRef<T> {
  open: (
    form: mtechnavi.api.form.IBaseForm | mtechnavi.api.form.IForm,
    formSetting:
      | mtechnavi.api.form.IBaseFormSetting
      | mtechnavi.api.form.IFormSetting,
    formSettingItems:
      | mtechnavi.api.form.IBaseFormSettingItem[]
      | mtechnavi.api.form.IFormSettingItem[],
    workForm: T,
    assetSystemCode: CommmonAttachedFileSystemCode,
    values?: mtechnavi.api.form.IFormValue[],
    arrowSave?: boolean,
    isDecisionCheckShow?: boolean,
    isDecisioned?: boolean
  ) => void;
}
interface BuildedFormDialogProps<T> {
  messageOption: {
    headerLabelId: MessageProps;
    /**
     * 決定ボタンのラベル。
     * 渡さなければ「保存」となる。
     */
    buttonLabelId?: MessageProps;
  };
  /**
   * フォーム入力ファイルのダウンロード処理。
   * 利用側から渡された時だけアイコンボタンが表示される。
   */
  onDownload?: (workForm?: T) => Promise<void> | void;
  /**
   * フォーム入力ファイルのインポート処理。
   * 利用側から渡された時だけアイコンボタンが表示される。
   * インポート後の新しい値を戻り値としてください。
   */
  onImport?: (
    result: FileItem[],
    workForm?: T
  ) =>
    | Promise<mtechnavi.api.form.IFormValue[] | void>
    | mtechnavi.api.form.IFormValue[]
    | void;
  /**
   * ダイアログの決定処理。
   */
  onDecision?: (
    formId: string,
    formValues?: mtechnavi.api.form.IFormValue[],
    isDecision?: boolean
  ) => void;
  /**
   * ダイアログのキャンセル処理。
   */
  onCancel?: () => void;
}
export const BuildedInputFormDialog = forwardRef(
  <T extends WorkFormTypes>(
    props: PropsWithoutRef<BuildedFormDialogProps<T>>,
    ref: ForwardedRef<BuildedInputFormDialogRef<T>>
  ) => {
    const intl = useIntl();
    const buildedFormRef = useRef<BuildedFormRef>(null);
    const [isOpen, setOpen] = useState(false);
    const [formId, setFormId] = useState('');
    const [formSetting, setFormSetting] =
      useState<mtechnavi.api.form.IFormSetting>();
    const [formSettingItems, setFormSettingItems] = useState<
      mtechnavi.api.form.IFormSettingItem[]
    >([]);
    const [workForm, setWorkForm] = useState<T>();
    const [formValues, setFormValues] = useState<
      mtechnavi.api.form.IFormValue[]
    >([]);
    const [assetSystemCode, setAssetSystemCode] =
      useState<CommmonAttachedFileSystemCode>();
    const [isReadOnly, setReadOnly] = useState<boolean>(false);
    const [isDecisionCheckShow, setDecisionCheckShow] = useState(false);
    const [decisionCheck, setDecisionCheck] = useState<boolean>(false);

    const handleCancel = () => {
      if (props.onCancel) {
        props.onCancel();
      }
      setOpen(false);
    };

    const handleDecision = () => {
      const values = buildedFormRef.current?.getValues();
      if (!values) {
        return;
      }
      if (props.onDecision) {
        props.onDecision(formId, values, decisionCheck);
      }
      setOpen(false);
    };

    const handleDownload = async () => {
      if (!props.onDownload) {
        return;
      }
      await props.onDownload(workForm);
    };

    const handleImport = async (result: FileItem[]) => {
      if (!props.onImport) {
        return;
      }
      try {
        const updatedFormValues = await props.onImport(result);
        if (!updatedFormValues) {
          return;
        }
        setFormValues(updatedFormValues);
      } catch (err) {
        error(getWorkerExceptionMessage(intl, err));
        throw err;
      }
    };

    useImperativeHandle(
      ref,
      (): BuildedInputFormDialogRef<T> => ({
        open: (
          form: mtechnavi.api.form.IBaseForm | mtechnavi.api.form.IForm,
          formSetting:
            | mtechnavi.api.form.IBaseFormSetting
            | mtechnavi.api.form.IFormSetting,
          formSettingItems:
            | mtechnavi.api.form.IBaseFormSettingItem[]
            | mtechnavi.api.form.IFormSettingItem[],
          workForm: T,
          assetSystemCode: CommmonAttachedFileSystemCode,
          formValues?: mtechnavi.api.form.IFormValue[],
          arrowSave?: boolean,
          isDecisionCheckShow?: boolean,
          isDecisioned?: boolean
        ) => {
          setFormId((form as mtechnavi.api.form.IForm).formId || '');
          setFormSetting(formSetting || {});
          setFormSettingItems(formSettingItems);
          setWorkForm(workForm);
          setReadOnly(!arrowSave);
          setFormValues(formValues || []);
          setAssetSystemCode(assetSystemCode);
          if ('status' in workForm) {
            setDecisionCheck(workForm.status?.systemName === 'B01');
          } else {
            setDecisionCheck(!!isDecisioned);
          }
          setDecisionCheckShow(!!isDecisionCheckShow);
          setOpen(true);
        },
      })
    );

    const elements = (
      <div className="BuildedFormDialog">
        <div className="form-input-formInformation">
          <FormInputInformation
            formInformation={workForm ?? {}}
            onClickDownload={props.onDownload && handleDownload}
            onClickImport={props.onImport && handleImport}
          />
        </div>
        <div className="form-area">
          <BuildedForm
            formPages={formSetting?.formPages || []}
            formSettingItems={formSettingItems}
            initialValues={formValues}
            ref={buildedFormRef}
            readonly={isReadOnly}
            assetSystemCode={assetSystemCode}
            isSkippedRequireCheck={isDecisionCheckShow && !decisionCheck}
          />
        </div>
        <div className="button-area">
          <CaptionButton
            name="cancel"
            buttonType="cancel"
            className="button"
            caption={GetMessageWithIntl(intl, { id: 'cancel' })}
            onClick={handleCancel}
          />
          {!isReadOnly && (
            <>
              <CaptionButton
                name="save"
                buttonType="basic"
                className="button"
                caption={GetMessageWithIntl(
                  intl,
                  props.messageOption.buttonLabelId || { id: 'save' }
                )}
                onClick={() => handleDecision()}
              />
            </>
          )}
          {isDecisionCheckShow && (
            <>
              <div className="decision-check-container">
                <Checkbox
                  name="decisionCheck"
                  className="decisionCheck"
                  items={[
                    {
                      value: '1',
                      displayName: GetMessageWithIntl(intl, {
                        id: 'decision',
                      }),
                    },
                  ]}
                  value={decisionCheck ? ['1'] : []}
                  onChangeState={(checkedIds) =>
                    setDecisionCheck(checkedIds.length > 0)
                  }
                  columns={['decisionCheck']}
                  disabled={isReadOnly}
                />
              </div>
            </>
          )}
        </div>
      </div>
    );

    const openModalProps: ModalDialogComponentProps = {
      cancel: handleCancel,
      send: () => {},
      modalIsOpen: isOpen,
      headerLabelId: props.messageOption.headerLabelId,
      messageLabelId: {},
      elements,
    };

    return (
      <div className="BuildedFormDialogBase">
        <ModalDialogComponent {...openModalProps} />
      </div>
    );
  }
);
