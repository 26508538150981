import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface ImportSampleWorkFormsRequest {
  actionName: 'importSampleWorkForms';
  request: string;
}

export const importSampleWorkForms = (worker: AppSaveWorker, req: string) => {
  const methodName =
    'mtechnavi.api.form.SampleWorkFormService/ImportSampleWorkForms';

  return worker.invokeListRequest<
    mtechnavi.api.form.IImportSampleWorkFormsRequest,
    mtechnavi.api.form.ImportSampleWorkFormsResponse
  >(methodName, { url: req });
};
