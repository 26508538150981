import { GetMessage } from '~/shared/components/parts/Message/Message';
import {
  ModalDialogComponent,
  ModalDialogComponentProps,
} from '../ModalDialog/ModalDialog';
import { CaptionButton } from '~/shared/components/ui/Button/CaptionButton';
import './base.css';
// import './JsonFileIODialog.css';
import { useEffect, useState, ChangeEvent } from 'react';
import { useIntl } from 'react-intl';
import { ErrorMessage } from '~/shared/components/parts/ErrorMessage/ErrorMessage';
import { GetMessageWithIntl } from '../../parts/Message/Message';

/* eslint @typescript-eslint/no-explicit-any: 0 */
export interface JsonFileIOItem {
  message: string[];
  data: any[];
}

export interface JsonFileIODialogInputOption {
  data: any[];
  encoding?: string;
  filename?: string;
}

export interface JsonFileIODialogProps {
  isOpen: boolean;
  inputOption?: JsonFileIODialogInputOption;
  onDecision: (v: JsonFileIOItem) => void;
  onCancel: () => void;
}

export const JsonFileIODialog = (props: JsonFileIODialogProps) => {
  const intl = useIntl();
  const [message, setMessage] = useState<string[]>([]); // バリデーションエラー用
  const [jsonFileData, setJsonFileData] = useState<any[]>([]);
  const [jsonFileName, setJsonFileName] = useState<string>();

  // JSONファイルの読み込み処理
  function parseJson(data: string) {
    try {
      const obj = JSON.parse(data);
      return obj;
    } catch (e) {
      return e;
    }
  }

  // input要素からのファイルアップロード処理
  const handleFileChange = async (evt: ChangeEvent<HTMLInputElement>) => {
    // ファイルがない場合は処理終了
    if (!evt.target.files) {
      return;
    }
    setMessage([]);

    // Filelistを配列に変換
    const uploads: File[] = [];
    for (let i = 0; i < evt.target.files.length; i++) {
      uploads.push(evt.target.files[i]);
    }
    const validateMessage: string[] = [];
    uploads.forEach((v) => {
      // Json以外のファイル形式の場合
      if (v.name.split('.').pop() !== 'json') {
        validateMessage.push(
          GetMessageWithIntl(intl, { id: 'E0000005', value: { $1: v.name } })
        );
      }
    });
    setMessage(validateMessage);
    const targetFile: File = uploads[0];
    setJsonFileName(targetFile.name);

    // エラーがある場合、後続処理を行わない
    if (validateMessage.length > 0) {
      return;
    }

    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        const result = reader.result;
        if (result) {
          const parseData = parseJson(reader.result as string);
          setJsonFileData(parseData);
        }
      },
      false
    );
    await reader.readAsText(targetFile, props.inputOption?.encoding);

    // JSON形式のチェック
    evt.target.value = '';
  };

  const handleDecision = () => {
    console.log(jsonFileData, 'handleDecision');
    // JSONフォーマットチェック
    const errorMessage: string[] = [];
    // 更新データなし
    if (jsonFileData.length <= 0) {
      errorMessage.push(GetMessageWithIntl(intl, { id: 'E1000010' }));
    }
    // JSON形式のエラーがあれば処理終了
    if (errorMessage.length > 0) {
      props.onDecision({ message: errorMessage, data: [] });
      return;
    }
    props.onDecision({ message: errorMessage, data: jsonFileData });
  };

  const handleExport = () => {
    const blob = new Blob(
      [JSON.stringify(props.inputOption?.data, null, '  ')],
      { type: 'application/json' }
    );
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.download = `${props.inputOption?.filename ?? 'data'}.json`;
    link.href = url;
    link.click();
    link.remove();
  };

  const formReset = () => {
    setMessage([]);
    setJsonFileData([]);
    setJsonFileName('');
  };

  // フォームに初期値をセットする
  useEffect(() => {
    formReset();
  }, [props.isOpen]);

  const elements = (
    <div className="output-dialog">
      <div className="contents-box">
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100 file-uploader-button">
              {/* ファイル選択処理はGCSアップなど不要のため、自前で作成 */}
              <div className="FileUploaderArea">
                <div className="FileUploader">
                  <div className="FileUploaderSelectParts">
                    <label className="file-chooser">
                      ファイル選択
                      <input type="file" onChange={handleFileChange} />
                    </label>
                  </div>
                </div>
                <div className="fileName">{jsonFileName}</div>
              </div>
              <ErrorMessage message={message}></ErrorMessage>
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-50">
              <CaptionButton
                name="selectedOnlyBtn"
                buttonType="basic"
                className="button"
                caption={GetMessage({
                  id: 'format_output',
                  prefixId: 'JsonFileIODialog',
                })}
                onClick={() => handleExport()}
              />
            </div>
          </div>
        </div>

        <div className="button-area">
          <CaptionButton
            name="cancelBtn"
            buttonType="cancel"
            className="button"
            caption={GetMessage({ id: 'cancel' })}
            onClick={() => {
              props.onCancel();
            }}
          />
          <CaptionButton
            name="cancelBtn"
            buttonType="basic"
            className="button"
            caption={GetMessage({ id: 'decision' })}
            onClick={() => handleDecision()}
          />
        </div>
      </div>
    </div>
  );

  const openModalProps: ModalDialogComponentProps = {
    cancel: () => {
      props.onCancel();
    },
    send: () => {},
    modalIsOpen: props.isOpen,
    headerLabelId: {
      id: 'JSON_FILE_IO',
      prefixId: 'DIALOG_TITLE',
    },
    messageLabelId: {},
    elements,
  };

  return <ModalDialogComponent {...openModalProps} />;
};
