import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface ExecChangeWtWorkStaffRequest {
  actionName: 'updateWtWorkStaff';
  request: mtechnavi.api.worktask.IWorkTask;
}

// 作業担当変更
export const execChangeWtWorkStaff = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.worktask.IWorkTask
) => {
  const getFullMethodName =
    'mtechnavi.api.worktask.WorkTaskService/GetWorkTask';
  const updateFullMethodName =
    'mtechnavi.api.worktask.WorkTaskService/UpdateWorkTask';

  const items = await worker.invokeListRequest<
    mtechnavi.api.worktask.IGetWorkTaskRequest,
    mtechnavi.api.worktask.WorkTask
  >(getFullMethodName, {
    workTaskId: req.workTaskId,
  });

  if (items && items.length > 0) {
    const item = items[0];
    return await worker.invokeListRequest<
      mtechnavi.api.worktask.IUpdateWorkTaskRequest,
      mtechnavi.api.worktask.WorkTask
    >(updateFullMethodName, {
      workTask: {
        ...item,
        workStaff: req.workStaff,
        workStaffChangeProcess: req.workStaffChangeProcess,
      },
    });
  }
};
