import dayjs from 'dayjs';
import { IntlShape } from 'react-intl';
import { GetMessageWithIntl } from './Message/Message';
import { convertNumber } from '~/shared/utils';

/*
  バリデーション関数
 */

// 禁止文字列が含まれるかどうかのバリデーション
export function validateIncludeProhibit(
  intl: IntlShape,
  chars: string
): (v: string) => string[] {
  return (value: string) => {
    if (chars.split('').find((c) => value.includes(c))) {
      return [
        GetMessageWithIntl(intl, { id: 'E0000007', value: { $1: chars } }),
      ];
    }

    return [];
  };
}

// emailのバリデーション関係
export function validateEmail(
  intl: IntlShape,
  regExp: string | RegExp
): (v: string) => string[] {
  return (value: string) => {
    if (typeof regExp === 'string') {
      regExp = new RegExp(regExp);
    }

    return regExp.test(value)
      ? []
      : [GetMessageWithIntl(intl, { id: 'E0000008' })];
  };
}

// 時刻範囲のバリデーション
export function validateTime(
  intl: IntlShape,
  from: number,
  to: number
): (v: string[]) => string[] {
  return (value: string[]) => {
    if (from > Number(value[0]) || to < Number(value[0])) {
      return [
        GetMessageWithIntl(intl, {
          id: 'E0000017',
          value: { $1: from, $2: to },
        }),
      ];
    }
    return [];
  };
}

// 指定時刻以降か判断用のバリデーション
export function validatePreviousTime(
  intl: IntlShape,
  judgeTime: Date
): (v: string[]) => string[] {
  return (value: string[]) => {
    const selectedTime = new Date();
    selectedTime.setHours(
      Number(value[0] ?? 0),
      Number(value[1] ?? 59),
      Number(value[2] ?? 59)
    );
    if (judgeTime > selectedTime) {
      let message = '';
      switch (value.length) {
        case 1:
          message = `${judgeTime.getHours()}時`;
          break;

        case 2:
          message = `${judgeTime.getHours()}時${judgeTime.getMinutes()}分`;
          break;

        case 3:
          message = `${judgeTime.getHours()}時${judgeTime.getMinutes()}分${
            judgeTime.getMinutes() + '秒'
          }`;
          break;

        default:
          break;
      }
      return [
        GetMessageWithIntl(intl, { id: 'E0000018', value: { $1: message } }),
      ];
    }
    return [];
  };
}

// 日付のバリデーション（過去判定）
export function validatePreviousDate(
  intl: IntlShape,
  now: Date
): (v: Date) => string[] {
  return (v: Date) => {
    return dayjs(v).isBefore(dayjs(now), 'date')
      ? [GetMessageWithIntl(intl, { id: 'E0000016' })]
      : [];
  };
}

// 日付のフリー入力用バリデーション
export function validateFreeDate(intl: IntlShape): (v: string) => string[] {
  return (value: string) => {
    const reg = new RegExp(
      '^([0-9]{4}/[0-9]{1,2}/[0-9]{1,2})$|^([0-9]{1,2}/[0-9]{1,2})$|^([0-9]{1,8})$'
    );

    return reg.test(value)
      ? []
      : [GetMessageWithIntl(intl, { id: 'E0000015' })];
  };
}

// 郵便番号のバリデーション
export function validateZipCode(intl: IntlShape, v: string) {
  // 7桁バリデーション
  const reg = new RegExp('^([0-9]{7})$|^([0-9]{3}-[0-9]{4})$');

  return reg.test(v) ? [] : [GetMessageWithIntl(intl, { id: 'E0000009' })];
}

// 必須入力で値が入力されたかどうかをチェック
export function checkEmptyRequiredValue(v: string | string[]) {
  // 配列チェック
  if (Array.isArray(v)) {
    // 各入力項目の内、一つでも空文字の場合
    return v.some((val) => {
      val === '';
    });
  } else {
    // 入力値が空文字かどうかチェック
    return v === '';
  }
}

// 電話番号バリデーション
export function validateTel(intl: IntlShape, v: string) {
  // 電話番号バリデーション
  const reg = new RegExp('^[0-9]{1,4}-[0-9]{1,4}-[0-9]{1,4}$');

  return reg.test(v) ? [] : [GetMessageWithIntl(intl, { id: 'E0000010' })];
}

// 文字数カウント
export function countInputWord(
  intl: IntlShape,
  value: string,
  minLength = 0,
  maxLength = Number.MAX_SAFE_INTEGER
): string[] {
  const count = value.length;
  if (count < minLength) {
    return [
      GetMessageWithIntl(intl, { id: 'E0000011', value: { $1: minLength } }),
    ];
  } else if (count > maxLength) {
    // HTML5標準機能で最大数を制限しているが、念の為処理を行う
    return [
      GetMessageWithIntl(intl, { id: 'E0000012', value: { $1: maxLength } }),
    ];
  }

  return [];
}

// 数値範囲チェック
export function validateRangeNumber(
  intl: IntlShape,
  num: number,
  minLength: number,
  maxLength: number
): string[] {
  if (minLength > num) {
    return [
      GetMessageWithIntl(intl, { id: 'E0000013', value: { $1: minLength } }),
    ];
  } else if (maxLength < num) {
    return [
      GetMessageWithIntl(intl, { id: 'E0000014', value: { $1: maxLength } }),
    ];
  }

  return [];
}

// 数値バリデーション
export function validateNumber(
  intl: IntlShape,
  value: string,
  minLength = Number.MIN_SAFE_INTEGER,
  maxLength = Number.MAX_SAFE_INTEGER
): string[] {
  if (minLength > maxLength) {
    throw new Error(
      'assertion error: minLengthはmaxLengthより小さい値を設定してください'
    );
  }
  // バリデーションメッセージ
  const message: string[] = [];

  let num = 0;
  try {
    num = convertNumber(value);
    // 数値範囲チェック
    message.push(...validateRangeNumber(intl, num, minLength, maxLength));
  } catch (error) {
    message.push(GetMessageWithIntl(intl, { id: 'E0000004' }));
  }

  return message;
}

// 数値バリデーション
export function validateOnlyNumber(intl: IntlShape) {
  return (value: string) => {
    // バリデーションエラーメッセージ
    const message: string[] = [];

    // 数値バリデーション
    const reg = new RegExp('^[0-9]*$');
    if (!reg.test(value)) {
      message.push(GetMessageWithIntl(intl, { id: 'E0000004' }));
    }

    return message;
  };
}

// 数値バリデーション
export function validateOnlyNumberWithMaxMin(
  intl: IntlShape,
  minLength?: number,
  maxLength?: number
) {
  return (value: string) => {
    // バリデーションエラーメッセージ
    const message: string[] = [];

    // 数値バリデーション
    const reg = new RegExp('^[0-9]*$');
    if (!reg.test(value)) {
      message.push(GetMessageWithIntl(intl, { id: 'E0000004' }));
    } else {
      const numValue = Number(value);

      if (minLength && minLength > numValue) {
        message.push(
          GetMessageWithIntl(intl, { id: 'E0000013', value: { $1: minLength } })
        );
      }
      if (maxLength && maxLength < numValue) {
        message.push(
          GetMessageWithIntl(intl, { id: 'E0000014', value: { $1: maxLength } })
        );
      }
    }
    return message;
  };
}

// 数値バリデーション(カンマ含む)
export function validateOnlyNumberWithComma(intl: IntlShape) {
  return (value: string) => {
    // バリデーションエラーメッセージ
    const message: string[] = [];

    // 何も入力されていない場合は早期リターン
    if (!value) {
      return message;
    }

    // 数値バリデーション
    const removedCommaValue = value.replaceAll(',', '');
    const num = Number(removedCommaValue);
    if (Number.isNaN(num)) {
      message.push(GetMessageWithIntl(intl, { id: 'E0000004' }));
    }

    return message;
  };
}

// 半角英数字バリデーション（桁数制御あり）
export function validateHalfAlphanumericAndPerfectSize(
  intl: IntlShape,
  size: number
) {
  return (value: string) => {
    // バリデーションエラーメッセージ
    const message: string[] = [];

    // 半角英数字バリデーション
    const reg = new RegExp('^[A-Za-z0-9]*$');
    if (!reg.test(value) || size !== value.length) {
      message.push(
        GetMessageWithIntl(intl, { id: 'E0000104', value: { $1: size } })
      );
    }
    return message;
  };
}

// 半角英数字(小文字)(記号)バリデーション（桁数制御あり）
export function validateHalfAlphanumericAndMaxMinSize(
  intl: IntlShape,
  minSize: number,
  maxSize: number
) {
  return (value: string) => {
    // バリデーションエラーメッセージ
    const message: string[] = [];

    // 半角英数字バリデーション
    const reg = new RegExp('^[a-z0-9-]*$');
    if (!reg.test(value) || minSize > value.length || maxSize < value.length) {
      message.push(
        GetMessageWithIntl(intl, {
          id: 'E0000160',
          value: { $1: minSize, $2: maxSize },
        })
      );
    }
    return message;
  };
}

// 半角英数字バリデーション
export function validateHalfAlphanumeric(intl: IntlShape) {
  return (value: string) => {
    // バリデーションエラーメッセージ
    const message: string[] = [];

    // 半角英数字バリデーション
    const reg = new RegExp('^[A-Za-z0-9]*$');
    if (!reg.test(value)) {
      message.push(GetMessageWithIntl(intl, { id: 'E0000113' }));
    }
    return message;
  };
}

// 小数点桁数バリデーション
export function validateOverDigits(
  intl: IntlShape,
  digits: number | null | undefined
) {
  return (value: string) => {
    if (!digits) {
      return [];
    }
    const numbers = value.split('.');
    const countedDigits = numbers.length > 1 ? numbers[1].length : 0;
    if (countedDigits > digits) {
      const errorMsg = [
        GetMessageWithIntl(intl, {
          id: 'E0000153',
          value: { $1: digits },
        }),
      ];
      return errorMsg;
    }
    return [];
  };
}
