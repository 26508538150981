import { useEffect, useRef, useState } from 'react';
import { FullMethodName_ListUserAttributes } from '~/worker';
import { CaptionButton } from '~/shared/components/ui/Button';
import { ApprovalRequestDialog } from '~/shared/components/ui/Dialog';
import { mtechnavi } from '~/shared/libs/clientsdk';

export const UISampleApprovalRequestDialogs = () => {
  const [result, setResult] = useState('');
  const [isShowConfirm, setIsShowConfirm] = useState(false);
  const organizations = useRef<mtechnavi.api.company.IOrganization[]>([]);
  const organizationRelations = useRef<
    mtechnavi.api.company.IOrganizationRelation[]
  >([]);

  useEffect(() => {
    (async () => {
      organizations.current = await window.App.services.ui.listOrganization();
      organizationRelations.current =
        await window.App.services.ui.listOrganizationRelation();
    })();
  }, []);

  return (
    <div className="categories">
      <h3 id="dialog">承認依頼ダイアログ</h3>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setResult('');
                setIsShowConfirm(true);
              }}
            />
            <ApprovalRequestDialog
              isOpen={isShowConfirm}
              inputOption={{
                organizations: organizations.current,
                organizationRelations: organizationRelations.current,
                FullMethodName_ListUserAttributes:
                  FullMethodName_ListUserAttributes,
                requestReciptId: '12345',
              }}
              onDecision={() => {
                setIsShowConfirm(false);
              }}
              onCancel={() => {
                setIsShowConfirm(false);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">{result}</pre>
          </div>
        </div>
      </div>
    </div>
  );
};
