import { mtechnavi, sharelib } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface ApprovalRequestEstimateManagementRequest {
  actionName: 'approvalRequestEstimateManagement';
  request: ApprovalRequestEstimateManagementParam;
}
interface ApprovalRequestEstimateManagementParam {
  estimateManagementId: string;
  approvalPlanStaff: sharelib.IUserReference;
  approvalPlanOrganization: sharelib.IOrganizationStructureReference;
  comment: string;
}

// 承認依頼
export const approvalRequestEstimateManagement = async (
  worker: AppSaveWorker,
  req: ApprovalRequestEstimateManagementParam
) => {
  const approvalRequestFullMethodName =
    'mtechnavi.api.estimation.EstimateSenderService/ApprovalRequestEstimateManagement';

  return await worker.invokeListRequest<
    mtechnavi.api.estimation.IApprovalRequestEstimateManagementRequest,
    mtechnavi.api.estimation.IApprovalRequestEstimateManagementResponse
  >(approvalRequestFullMethodName, {
    estimateManagementId: req.estimateManagementId,
    approvalPlanStaff: req.approvalPlanStaff,
    approvalPlanOrganization: req.approvalPlanOrganization,
    comment: req.comment,
  });
};
