import { ViewId, getWorkerExceptionMessage } from '~/shared/utils';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { useState, useEffect, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { SimpleIconListView } from '~/shared/components/ui';
import {
  DISPLAY_TYPE,
  ResponsiblePersonRegistrationDialog,
  ResponsiblePersonRegistrationDialogInputOption,
} from '~/shared/components/ui/Dialog/ResponsiblePersonRegistrationDialog';
import { ConfirmationDialog } from '~/shared/components/ui/Dialog';
import {
  success,
  error,
  GetMessageWithIntl,
  MessageProps,
} from '~/shared/components';
import { isLinkedTenant } from '~/tenant/core/pages/BusinessUnitManagementConfirmation/utils/conditions';

interface RankReceptionTabItemProps {
  businessUnitRankContents?: mtechnavi.api.company.IBusinessUnitRankContent[];
  businessUnitManagementContent?: mtechnavi.api.company.IBusinessUnitManagementContent;
  ownCompanyBranchs?: mtechnavi.api.company.IBusinessUnitBranch[];
  onChangeLoadingState: (arg: boolean) => void;
  handleReload: () => void;
}

interface RankType {
  id: number; //Key  (map index)
  requestId: string; // 品質要求ID
  receptionId: string; // 品質管理（責任者）ID
  displayName: string; //適用品種
  rank: string; // ランク
  companyDisplayName: string; // 品質管理企業名
  qualityManagerDisplayName: string; // 品質管理者名
  managerCategory: string; // 正/副(担当分類)
  businessUnitName: string; // 工場名
}

type rankActionType = 'delete' | 'save';
interface ConfirmationDialogInfo {
  actionType: rankActionType;
  item: RankType | mtechnavi.api.company.IBusinessUnitRankReception;
}

const VIEW_ID: ViewId = 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION';

const rankReceptionColumns = [
  {
    header: {
      id: 'rank.displayName',
      prefixId: VIEW_ID,
    },
    propertyName: 'displayName',
    width: '16%',
  },
  {
    header: {
      id: 'rank.rank',
      prefixId: VIEW_ID,
    },
    propertyName: 'rank',
    width: '8rem',
  },
  {
    header: {
      id: 'rank.companyDisplayName',
      prefixId: VIEW_ID,
    },
    propertyName: 'companyDisplayName',
    width: '17rem',
  },
  {
    header: {
      id: 'rank.qualityManagerDisplayName',
      prefixId: VIEW_ID,
    },
    propertyName: 'qualityManagerDisplayName',
    width: '12rem',
  },
  {
    header: {
      id: 'rank.managerCategory',
      prefixId: VIEW_ID,
    },
    propertyName: 'managerCategory',
    width: '10rem',
  },
  {
    header: {
      id: 'rank.businessUnitName',
      prefixId: VIEW_ID,
    },
    propertyName: 'businessUnitName',
    width: '14rem',
  },
];

export const RankReceptionTabItem = ({
  businessUnitRankContents,
  businessUnitManagementContent,
  ownCompanyBranchs,
  onChangeLoadingState,
  handleReload,
}: RankReceptionTabItemProps) => {
  const intl = useIntl();
  const [rankReceptionItem, setRankReceptionItem] = useState<RankType[]>([]);
  const [receptionCompanyRank, setReceptionCompanyRank] = useState<string>('');
  const [rankFormItem, setRankFormItem] =
    useState<ResponsiblePersonRegistrationDialogInputOption>({
      displayType: 'display',
      rankContent: {},
      sharedCompanyId: '',
      isLinkedTenant: false,
    });
  // 確認ダイアログ
  const viewMessageSave: MessageProps = {
    id: 'C0000003',
    value: {
      $1: GetMessageWithIntl(intl, { id: 'save' }),
    },
  };
  const viewMessageDelete: MessageProps = {
    id: 'C0000003',
    value: {
      $1: GetMessageWithIntl(intl, { id: 'delete' }),
    },
  };
  const [confirmationDialogInfo, setConfirmationDialogInfo] =
    useState<ConfirmationDialogInfo>({
      actionType: 'delete',
      item: {},
    });
  const [isSaveConfirmationDialogOpen, setSaveConfirmationDialogOpen] =
    useState(false);

  // 責任者登録ダイアログ
  const [
    isResponsiblePersonRegistrationDialogOpen,
    setResponsiblePersonRegistrationDialogOpen,
  ] = useState(false);
  // メッセージ
  const successMessage = GetMessageWithIntl(intl, { id: 'I0000001' });

  // 品質管理変更時
  useEffect(() => {
    if (!businessUnitRankContents) {
      setRankReceptionItem([]);
    }
    const ranks = window.App.services.ui.getNameOption('A4000011');
    const tmpData: string[] = [];
    ranks.map((rank) => {
      businessUnitRankContents?.map((item) => {
        if (
          rank.code &&
          item.businessUnitRankRequest?.rank?.code === rank.code
        ) {
          tmpData.push(rank.code);
        }
      });
    });
    setReceptionCompanyRank(tmpData[0]);

    const vals: RankType[] = [];
    businessUnitRankContents?.map((v, index) =>
      vals.push({
        id: index,
        requestId: v.businessUnitRankRequest?.businessUnitRankRequestId ?? '',
        receptionId:
          v.businessUnitRankReception?.businessUnitRankReceptionId ?? '',
        displayName: v.businessUnitRankRequest?.displayName ?? '',
        rank: v.businessUnitRankRequest?.rank?.code ?? '',
        companyDisplayName:
          v.businessUnitRankReception?.companyDisplayName ?? '',
        qualityManagerDisplayName:
          v.businessUnitRankReception?.qualityManager?.displayName ?? '',
        managerCategory:
          v.businessUnitRankReception?.category?.displayNameLang?.ja ?? '',
        businessUnitName: v.businessUnitRankReception?.ownCompany
          ? ownCompanyBranchs?.find(
              (branch) =>
                branch.businessUnitBranchId ===
                v.businessUnitRankReception?.businessUnitBranchProperties
                  ?.businessUnitBranchId
            )?.displayName ?? ''
          : v.businessUnitRankReception?.businessUnitBranchProperties
              ?.displayName ?? '',
      })
    );
    vals.sort((val1, val2) =>
      val1.displayName.localeCompare(val2.displayName, 'ja')
    );
    setRankReceptionItem(vals);
    // businessUnitRankContents 変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessUnitRankContents, ownCompanyBranchs]);

  // 責任者登録/確認ダイアログ
  const handleResponsiblePersonRegistrationDialog = (
    listViewItem: RankType,
    displayType: DISPLAY_TYPE
  ) => {
    let targetItem:
      | mtechnavi.api.company.IBusinessUnitRankContent
      | null
      | undefined;

    if (listViewItem.receptionId) {
      targetItem = businessUnitRankContents?.find(
        (v) =>
          v.businessUnitRankReception?.businessUnitRankReceptionId ===
          listViewItem?.receptionId
      );
    } else {
      targetItem = businessUnitRankContents?.find(
        (v) =>
          v.businessUnitRankRequest?.businessUnitRankRequestId ===
          listViewItem.requestId
      );
    }

    if (!targetItem) {
      return;
    }
    setRankFormItem({
      displayType: displayType,
      rankContent: targetItem,
      sharedCompanyId:
        businessUnitManagementContent?.businessUnitManagement?.companyId ?? '',
      isLinkedTenant: isLinkedTenant(
        businessUnitManagementContent?.tenantStatus?.systemName
      ),
    });
    setResponsiblePersonRegistrationDialogOpen(true);
  };

  // [実行処理]責任者情報登録
  const handleSaveRankReception = async (
    v: mtechnavi.api.company.IBusinessUnitRankReception
  ) => {
    if (!v) {
      return;
    }
    onParentLoadingState(true);
    try {
      await window.App.services.ui.worker.apiCall({
        actionName: 'saveBusinessUnitRankReception',
        request: v,
      });

      success([successMessage]);
      setSaveConfirmationDialogOpen(false);
      setResponsiblePersonRegistrationDialogOpen(false);

      // リロード(品質管理情報のみ)
      handleReload();
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      onParentLoadingState(false);
    }
  };

  // [実行処理]品質管理削除
  const handleDeleteReceptionItem = async (
    v: mtechnavi.api.company.IBusinessUnitRankReception
  ) => {
    onParentLoadingState(true);
    try {
      await window.App.services.ui.worker.apiCall({
        actionName: 'deleteBusinessUnitRankReception',
        request: v,
      });

      success([successMessage]);
      setSaveConfirmationDialogOpen(false);
      // リロード(品質管理情報のみ)
      handleReload();
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      onParentLoadingState(false);
    }
  };

  const onParentLoadingState = useCallback(
    (arg: boolean) => {
      onChangeLoadingState(arg);
    },
    [onChangeLoadingState]
  );

  return (
    <>
      <div className="tab-info-body pt-0">
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-50">
              <p className="company-rank">
                {GetMessageWithIntl(intl, {
                  prefixId: 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION',
                  id: 'rank.companyRank',
                })}
                {receptionCompanyRank}
              </p>
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              <SimpleIconListView
                data={rankReceptionItem}
                viewOptions={{
                  previewRowCount: 10,
                  keyColumn: 'id',
                  columns: rankReceptionColumns,
                  omitFooter: true,
                }}
                actionOptions={{}}
                iconMenuOptions={{
                  iconMenu: [
                    {
                      name: 'edit',
                      displayName: '編集',
                      func: (v: RankType) => {
                        handleResponsiblePersonRegistrationDialog(v, 'edit');
                      },
                    },
                    {
                      name: 'copy',
                      displayName: '複写',
                      func: (v: RankType) => {
                        handleResponsiblePersonRegistrationDialog(v, 'copy');
                      },
                    },
                    {
                      name: 'delete',
                      displayName: '削除',
                      func: async (v: RankType) => {
                        const item = businessUnitRankContents?.find(
                          (reception) =>
                            reception.businessUnitRankReception
                              ?.businessUnitRankReceptionId === v.receptionId
                        );
                        if (!item?.businessUnitRankReception) {
                          // 未登録状態で削除した場合もエラー
                          error([GetMessageWithIntl(intl, { id: 'E0000129' })]);
                          return;
                        }
                        const deletable =
                          businessUnitRankContents?.filter((data) => {
                            if (
                              data.businessUnitRankRequest?.displayName ===
                                item.businessUnitRankRequest?.displayName &&
                              data.businessUnitRankRequest?.rank?.code ===
                                item?.businessUnitRankRequest?.rank?.code
                            ) {
                              return data;
                            }
                          }) ?? [];
                        if (deletable.length === 1) {
                          error([GetMessageWithIntl(intl, { id: 'E0000129' })]);
                          return;
                        }
                        setConfirmationDialogInfo({
                          actionType: 'delete',
                          item: item.businessUnitRankReception,
                        });
                        setSaveConfirmationDialogOpen(true);
                      },
                    },
                  ],
                }}
              />
            </div>
          </div>
        </div>
      </div>

      {/* 責任者登録/確認ダイアログ */}
      <ResponsiblePersonRegistrationDialog
        isOpen={isResponsiblePersonRegistrationDialogOpen}
        inputOption={rankFormItem}
        onDecision={(v) => {
          setConfirmationDialogInfo({
            actionType: 'save',
            item: v,
          });
          setSaveConfirmationDialogOpen(true);
        }}
        onCancel={() => {
          setResponsiblePersonRegistrationDialogOpen(false);
        }}
        onChangeLoadingState={(v: boolean) => {
          onParentLoadingState(v);
        }}
      />
      {/* 自社企業情報の保存 確認ダイアログ */}
      <ConfirmationDialog
        isOpen={isSaveConfirmationDialogOpen}
        viewMessage={
          confirmationDialogInfo.actionType === 'save'
            ? viewMessageSave
            : viewMessageDelete
        }
        onDecision={() => {
          confirmationDialogInfo.actionType === 'delete'
            ? handleDeleteReceptionItem(confirmationDialogInfo.item as RankType)
            : handleSaveRankReception(
                confirmationDialogInfo.item as mtechnavi.api.company.IBusinessUnitRankReception
              );
        }}
        onCancel={() => {
          setSaveConfirmationDialogOpen(false);
        }}
      />
    </>
  );
};
