import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface UncompleteDisasterSurveyRequest {
  actionName: 'uncompleteDisasterSurvey';
  request: mtechnavi.api.bcp.IUncompleteDisasterSurveyRequest;
}

// 災害調査再開
export const uncompleteDisasterSurvey = async (
  worker: AppSaveWorker,
  request: mtechnavi.api.bcp.IUncompleteDisasterSurveyRequest
) => {
  const fullMethodName =
    'mtechnavi.api.bcp.BcpService/UncompleteDisasterSurvey';

  await worker.invokeListRequest<
    mtechnavi.api.bcp.IUncompleteDisasterSurveyRequest,
    void
  >(fullMethodName, {
    disasterSurveyId: request.disasterSurveyId,
  });
};
