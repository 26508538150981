import { mtechnavi } from '~/shared/libs/clientsdk';

const NEST_SIZE = 5;
export const generateMockData = (): mtechnavi.api.company.IOrganization[] => {
  return generateMockChild('', 0, 0) || [];
};
const generateMockChild = (
  parentCode: string,
  layer: number,
  parentIndex: number
): mtechnavi.api.company.IOrganization[] => {
  if (layer >= NEST_SIZE) {
    return [];
  }
  return new Array(layer <= 1 && parentIndex === 0 ? NEST_SIZE - layer : 1)
    .fill(null)
    .flatMap((noop, index): mtechnavi.api.company.IOrganization[] => {
      const code = `${parentCode ? `${parentCode}-` : ''}${index + 1}`;
      return [
        {
          organizationId: `${code}`,
          parentOrganization: { organizationId: `${parentCode}` },
          displayName: `グループ ${code}`,
          displayNameShort: `${code}`,
          usable: true,
        },
        ...generateMockChild(code, layer + 1, index),
      ];
    });
};
