import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface ApproveAndShareToEstimateSelectionRequest {
  actionName: 'approveAndShareToEstimateSelection';
  request: mtechnavi.api.estimation.IApproveEstimateSelectionRequest;
}

export const approveAndShareToEstimateSelection = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.estimation.IApproveEstimateSelectionRequest
) => {
  const approveMethod =
    'mtechnavi.api.estimation.EstimateSenderService/ApproveEstimateSelection';
  await worker.invokeListRequest<
    mtechnavi.api.estimation.IApproveEstimateSelectionRequest,
    void
  >(approveMethod, req);

  const shareMethod =
    'mtechnavi.api.estimation.EstimateSenderService/SharetoEstimateSelection';
  await worker.invokeListRequest<
    mtechnavi.api.estimation.ISharetoEstimateSelectionRequest,
    void
  >(shareMethod, {
    estimateRequestUnitId: req.estimateSelectionId,
  });
};
