import { mtechnavi } from '~/shared/libs/clientsdk';
import { FullMethodName_ListProgramOptions } from '../utils';
import { FullMethodName_PublicListInformations } from '~/worker';

// 最終的にサービスAPIとして切り出される想定のため、ここに配置
export async function getAppConfig(): Promise<AppConfig> {
  const res = await fetch('/__/app/config.json');
  if (!res.ok) {
    throw new Error(`failed to load config.json: ${res.statusText}`);
  }
  return await res.json();
}

// アプリ起動時に呼び出されて、取得データのキャッシュを行う
export async function getProgramOptions(): Promise<
  mtechnavi.api.programoption.IProgramOption[]
> {
  const trs = (await window.App.services.ui.worker.filter({
    action: 'reload',
    fullMethodName: FullMethodName_ListProgramOptions,
    filter: {},
    sort: [],
  })) as mtechnavi.api.programoption.ListProgramOptionsResponse;

  return trs.items;
}

export async function getNoticeList(): Promise<
  mtechnavi.api.admin.IInformation[]
> {
  const trs = (await window.App.services.ui.worker.filter({
    action: 'reload',
    fullMethodName: FullMethodName_PublicListInformations,
    filter: {},
    sort: [{ publishedAt: 'desc' }],
  })) as mtechnavi.api.uicontroller.PublicListInformationsResponse;

  return trs.items;
}
