import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface ExecStopBusiness {
  actionName: 'execStopBusiness';
  request: mtechnavi.api.company.IStopBusinessRequest;
}

export const execStopBusiness = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.company.IStopBusinessRequest
) => {
  const execMethodName =
    'mtechnavi.api.company.BusinessUnitManagementService/StopBusiness';

  const result = await worker.invokeListRequest(execMethodName, {
    businessUnitManagementId: req.businessUnitManagementId,
  });
  return result;
};
