import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface BulkDeleteFormValuesRequest {
  actionName: 'bulkDeleteFormValues';
  request: mtechnavi.api.form.IBulkDeleteFormValuesRequest;
}

export const bulkDeleteFormValues = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.form.IBulkDeleteFormValuesRequest
) => {
  const methodName = 'mtechnavi.api.form.FormService/BulkDeleteFormValues';

  return worker.invokeListRequest<
    mtechnavi.api.form.IBulkDeleteFormValuesRequest,
    void
  >(methodName, req);
};
