import { useState, useEffect, useRef, useCallback } from 'react';
import {
  Container,
  error,
  GetMessageWithIntl,
  success,
  Toast,
  MessageProps,
} from '~/shared/components';
import {
  Preset,
  Property,
  getBooleanDataFormetterSchema,
  getJoinedUserNameEmailSchema,
  getDayFormetterDisplaySchema,
  ListView,
  MenuActionItem,
  ViewMenu,
  ConfirmationDialog,
} from '~/shared/components/ui';

import { PresetItem } from '~/shared/services';
import { useIntl } from 'react-intl';
import {
  getPresetAndSchema,
  getExceptionMessage,
  getWorkerExceptionMessage,
  saveLocalStorageCheckboxData,
} from '~/shared/utils';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { useLoading } from '~/shared/contexts/LoadingProvider';
import { useAuth } from '~/shared/contexts/AuthProvider';
import { RadioSelectDialog } from '~/shared/components/ui/Dialog/RadioSelectDialog';
import { RadioItem } from '~/shared/components/parts/Radio/Radio';
import {
  AttributeValues,
  AttributeInputDialog,
  AttributeInputDialogFormConfig,
  AttributeInputDialogInputOption,
} from '~/shared/components/ui/Dialog/AttributeInputDialog';
import {
  FullMethodName_ListAttributes,
  FullMethodName_ListFormValues,
} from '~/worker';
import {
  AttributeType,
  ATTRIBUTE_ORGANIZATION,
  ATTRIBUTE_BRANCH,
} from '~/shared/utils/commonType';

const VIEW_ID = 'MASTER_ATTRIBUTE_LIST';

export function MasterAttributeList(): JSX.Element {
  const intl = useIntl();
  const myEmail = useAuth().user?.email ?? '';
  const { showLoading, hideLoading } = useLoading();
  const [childrenPresetItem, setChildrenPresetItem] = useState<PresetItem[]>();
  const [schema, setSchema] = useState<Property[]>([]);
  const [preset, setPreset] = useState<Preset>({
    filter: {},
    propertyNames: [],
  });
  const successMessage = GetMessageWithIntl(intl, { id: 'I0000001' });
  const unselectedMessage = GetMessageWithIntl(intl, { id: 'E0000023' });
  const excessTargetMessage = GetMessageWithIntl(intl, { id: 'E0000073' });

  // 確認ダイアログ(削除)
  const [isShowDelete, setShowDelete] = useState(false);
  // 属性分類選択ダイアログ
  const [isShowAttributeSelectDialog, setShowAttributeSelectDialog] =
    useState(false);
  // 属性情報入力ダイアログ
  const [isShowAttributeInputDialog, setShowAttributeInputDialog] =
    useState(false);
  const [formConfig, setFormConfig] =
    useState<AttributeInputDialogFormConfig>();
  const [
    isAttributeInputDialogInputOption,
    setAttributeInputDialogInputOption,
  ] = useState<AttributeInputDialogInputOption>();
  const [
    isAttributeInputDialogHeaderLabel,
    setAttributeInputDialogHeaderLabel,
  ] = useState<MessageProps>({});
  // リロード管理
  const [isReload, setReload] = useState(false);

  // 操作対象ID
  const [targetId, setTargetId] = useState<string | null>(null);
  const listItemRef = useRef<mtechnavi.api.programoption.IAttribute[]>([]);
  const listAllItemRef = useRef<mtechnavi.api.programoption.IAttribute[]>([]);

  const forms = useRef<mtechnavi.api.form.IForm[]>([]);
  const formSettings = useRef<mtechnavi.api.form.IFormSetting[]>([]);
  const formSettingItems = useRef<mtechnavi.api.form.IFormSettingItem[]>([]);
  const targetFormValues = useRef<mtechnavi.api.form.IFormValue[]>([]);

  const attributeCategoryValues = [ATTRIBUTE_BRANCH, ATTRIBUTE_ORGANIZATION];
  const attributeCategoryRadioItems: RadioItem[] = [
    {
      displayName: GetMessageWithIntl(intl, {
        id: 'branch',
        prefixId: 'selectAttributeDialog',
      }),
      value: attributeCategoryValues[0],
    },
    {
      displayName: GetMessageWithIntl(intl, {
        id: 'organization',
        prefixId: 'selectAttributeDialog',
      }),
      value: attributeCategoryValues[1],
    },
  ];

  async function getSystemData(
    src: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Promise<any> {
    return await fetch(src, {
      method: 'GET',
    }).then(async (response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const blob = await response.blob();
      const responseText = await blob.text();
      return JSON.parse(responseText);
    });
  }

  useEffect(() => {
    (async () => {
      try {
        const { childrenPresetItem, schemas, preset } =
          await getPresetAndSchema(VIEW_ID, [FullMethodName_ListAttributes]);
        const booleanColumn = ['usable'];
        const booleanSch = getBooleanDataFormetterSchema(
          schemas[0],
          booleanColumn
        );
        const joinedUserNameSch = getJoinedUserNameEmailSchema(
          booleanSch,
          'updatedProperties.updatedBy.displayName',
          'updatedProperties.updatedBy.email'
        );
        const dayCololmns = ['updatedProperties.updatedAt'];
        const daySch = getDayFormetterDisplaySchema(
          joinedUserNameSch,
          dayCololmns,
          {
            dayOpts: { formatType: 'YYYY/MM/DD HH:mm' },
          }
        );
        setChildrenPresetItem(childrenPresetItem);
        setSchema(daySch);
        setPreset(preset);

        const srcForm = `/resources/common/attribute/system_form.json`;
        forms.current = (await getSystemData(
          srcForm
        )) as mtechnavi.api.form.IForm[];

        const srcFormSetting = `/resources/common/attribute/system_form_setting.json`;
        formSettings.current = (await getSystemData(
          srcFormSetting
        )) as mtechnavi.api.form.IFormSetting[];

        const srcFormSettingItem = `/resources/common/attribute/system_form_setting_item.json`;
        formSettingItems.current = (await getSystemData(
          srcFormSettingItem
        )) as mtechnavi.api.form.IFormSettingItem[];
      } catch (err) {
        error(getExceptionMessage(intl, err));
        throw err;
      }
    })();
  }, [intl]);

  const clearCheckBox = useCallback(() => {
    saveLocalStorageCheckboxData(VIEW_ID, [], myEmail);
  }, [myEmail]);

  // 追加
  const handleAdd = (categoryCode: string) => {
    setAttributeInputDialogInputOption({
      initialValues: {
        headerValues: {
          formId: categoryCode,
          categoryCode: categoryCode as AttributeType,
          systemAttribute: true,
          usable: true,
        },
      },
      readonly: false,
    });
    setAttributeInputDialogHeaderLabel({
      prefixId: 'DIALOG_TITLE',
      id: 'AttributeInputDialog',
    });
    handleShowAttributeInputDialog(categoryCode);
  };

  // 削除
  const handleDelete = async () => {
    const target = getRecordData(targetId);
    if (!target) {
      error([unselectedMessage]);
      return;
    }
    showLoading();
    setReload(false);
    try {
      await Promise.all([
        // フォーム入力値データの削除
        window.App.services.ui.worker.apiCall({
          actionName: 'bulkDeleteFormValues',
          request: {
            formId: target.formId,
            tag: target.attributeId,
          },
        }),
        // 属性マスタの削除
        window.App.services.ui.worker.apiCall({
          actionName: 'deleteAttribute',
          request: [target],
        }),
      ]);

      success([successMessage]);
      clearCheckBox();
      setReload(true);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      hideLoading();
    }
  };

  // フォーム入力値取得
  const listFormValues = async (recordId: string) => {
    return (await window.App.services.ui.worker.filter({
      action: 'reload',
      fullMethodName: FullMethodName_ListFormValues,
      filter: {},
      sort: [],
      requestBody: {
        formId: null,
        typeName: 'mtechnavi.api.programoption.Attribute',
        recordId: recordId,
      },
    })) as mtechnavi.api.form.ListFormValuesResponse;
  };

  // 確認
  const handleConfirm = async (prop?: string[]) => {
    const target = getRecordData(prop?.at(0) ?? '');
    showLoading();
    const resValues = await listFormValues(prop?.at(0) ?? '');
    targetFormValues.current = resValues.items;
    setAttributeInputDialogInputOption({
      initialValues: {
        headerValues: target,
        detailValues: resValues.items,
      },
      readonly: true,
    });
    setAttributeInputDialogHeaderLabel({
      prefixId: 'DIALOG_TITLE',
      id: 'AttributeConfirmationDialog',
    });
    handleShowAttributeInputDialog(target?.categoryCode ?? '');
    hideLoading();
  };

  // 編集
  const handleEdit = async (prop?: string[]) => {
    const target = getRecordData(prop?.at(0) ?? '');
    showLoading();
    const resValues = await listFormValues(prop?.at(0) ?? '');
    targetFormValues.current = resValues.items;
    setAttributeInputDialogInputOption({
      initialValues: {
        headerValues: target,
        detailValues: resValues.items,
      },
      readonly: false,
    });
    setAttributeInputDialogHeaderLabel({
      prefixId: 'DIALOG_TITLE',
      id: 'AttributeInputDialog',
    });
    handleShowAttributeInputDialog(target?.categoryCode ?? '');
    hideLoading();
  };

  const setMenuActionItem = (): MenuActionItem[] => {
    const menuActionItems: MenuActionItem[] = [];
    menuActionItems.push({
      menuActionType: 'headerIconMenu',
      menu: headerIconEvent(),
    });
    menuActionItems.push({
      menuActionType: 'listIconMenu',
      menu: listIconEvent(),
    });
    return menuActionItems;
  };

  const headerIconEvent = (): ViewMenu[] => {
    const menuItems: ViewMenu[] = [];
    // 追加
    menuItems.push({
      name: 'noteadd',
      func: () => setShowAttributeSelectDialog(true),
    });
    // 削除
    menuItems.push({
      name: 'delete',
      func: (v?: string[]) => {
        if (!v) {
          return;
        }
        if (v.length !== 1) {
          error([excessTargetMessage]);
          return;
        }

        const target = getRecordData(v[0]);
        if (!target) {
          error([unselectedMessage]);
          return;
        }
        setTargetId(v[0]);
        setShowDelete(true);
        return;
      },
    });
    return menuItems;
  };

  const listIconEvent = (): ViewMenu[] => {
    const menuItems: ViewMenu[] = [];
    // 確認
    menuItems.push({
      name: 'description',
      func: (v?: string[]) => handleConfirm(v),
    });
    // 編集
    menuItems.push({
      name: 'edit',
      func: (v?: string[]) => handleEdit(v),
    });
    return menuItems;
  };

  const getRecordData = (
    id: string | null
  ): mtechnavi.api.programoption.IAttribute | undefined => {
    return listItemRef?.current.find((item) => item.attributeId === id);
  };

  const handleShowAttributeInputDialog = (formId: string) => {
    setFormConfig({
      formSetting: formSettings.current.find((x) => x.formId === formId) ?? {},
      formSettingItems: formSettingItems.current.filter(
        (x) => x.formId === formId
      ),
      assetSystemCode: 'B10',
    });
    setShowAttributeInputDialog(true);
  };

  // 保存処理
  const handleSave = async (result: AttributeValues) => {
    showLoading();
    setReload(false);

    try {
      const resAttribute = (await window.App.services.ui.worker.apiCall({
        actionName: 'saveAttribute',
        request: result.headerValues ?? {},
      })) as unknown[] as mtechnavi.api.programoption.IAttribute[];

      await window.App.services.ui.worker.apiCall({
        actionName: 'saveFormValue',
        request: {
          items: result?.detailValues?.map((formValue) => {
            const oldValue = (targetFormValues.current ?? []).find(
              (val) => val.formSettingItemId === formValue.formSettingItemId
            );
            return {
              ...formValue,
              formValueId: oldValue?.formValueId,
              formId: result.headerValues?.categoryCode,
              typeName: 'mtechnavi.api.programoption.Attribute',
              recordId: resAttribute.at(0)?.attributeId,
              createdAt: oldValue?.createdAt,
              updatedAt: oldValue?.updatedAt,
            };
          }),
          tag: resAttribute.at(0)?.attributeId,
        },
      });

      success([successMessage]);
      setReload(true);
    } catch (err) {
      hideLoading();
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      hideLoading();
    }
  };

  return (
    <Container viewId={VIEW_ID}>
      <div className="MasterAttributeList">
        <ListView
          pageInfo={{
            schema: schema,
            preset: preset,
            menuItem: setMenuActionItem(),
            menuTarget: 'attributeId',
            headerTitle: { viewId: VIEW_ID },
            presetItems: childrenPresetItem,
            listSkipType: {
              isTotal: true,
              isOutput: true,
              isListActionMenu: true,
            },
          }}
          isReload={isReload}
          fullMethodName={FullMethodName_ListAttributes}
          stateOption={{
            onOriginalItemState: (items: unknown[]) =>
              (listItemRef.current =
                items as mtechnavi.api.programoption.IAttribute[]),
            onAllItemState: (items: unknown[]) =>
              (listAllItemRef.current =
                items as mtechnavi.api.programoption.IAttribute[]),
          }}
        />
        <ConfirmationDialog
          isOpen={isShowDelete}
          viewMessage={{
            id: 'C0000001',
            value: {
              $1: GetMessageWithIntl(intl, {
                id: 'delete',
              }),
            },
          }}
          onDecision={() => {
            handleDelete();
            setShowDelete(false);
          }}
          onCancel={() => setShowDelete(false)}
        />
        <RadioSelectDialog
          isOpen={isShowAttributeSelectDialog}
          inputOption={{
            radioButtonInfo: attributeCategoryRadioItems,
            initialValue: attributeCategoryValues[0],
          }}
          messageOption={{
            headerMessageOption: {
              id: 'selectAttributeDialog',
              prefixId: 'DIALOG_TITLE',
            },
            descriptionMessageOption: {
              id: 'selectAttributeDialog',
              prefixId: 'DIALOG_CAPTION',
            },
          }}
          onDecision={(v) => {
            handleAdd(v);
            setShowAttributeSelectDialog(false);
          }}
          onCancel={() => {
            setShowAttributeSelectDialog(false);
          }}
        />
        <AttributeInputDialog
          key={isShowAttributeInputDialog ? '1' : '0'}
          isOpen={isShowAttributeInputDialog}
          formConfig={formConfig}
          inputOption={isAttributeInputDialogInputOption}
          messageOption={{ headerLabelId: isAttributeInputDialogHeaderLabel }}
          onDecision={(...args) => {
            handleSave(args.at(0) ?? {});
            setShowAttributeInputDialog(false);
          }}
          onCancel={() => setShowAttributeInputDialog(false)}
        />
      </div>
      <Toast />
    </Container>
  );
}
