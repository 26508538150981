import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface SharetoSurveyRequest {
  actionName: 'sharetoSurveyRequest';
  request: SharetoSurveyRequestParam;
}
interface SharetoSurveyRequestParam {
  surveyBaseRequestId: string;
}

// 依頼展開
export const sharetoSurveyRequest = async (
  worker: AppSaveWorker,
  req: SharetoSurveyRequestParam
) => {
  const shareToMethodName =
    'mtechnavi.api.survey.SurveySenderService/SharetoSurveyRequest';

  return await worker.invokeListRequest<
    mtechnavi.api.survey.ISharetoSurveyRequestRequest,
    mtechnavi.api.survey.ISharetoSurveyRequestResponse
  >(shareToMethodName, {
    surveyBaseRequestId: req.surveyBaseRequestId,
  });
};
