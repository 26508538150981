import { useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Container,
  GetMessage,
  GetMessageWithIntl,
  LoadingIcon,
  MessageProps,
  Radio,
  Toast,
  success,
  error,
} from '~/shared/components';
import {
  ConfirmationDialog,
  DataFilterbox,
  DataFilterboxItem,
  PageNavigation,
  Textbox,
  Textarea,
  Checkbox,
  Filterbox,
  SimpleListView,
  FilterboxItem,
  AutoReminderSettingDialog,
  BusinessUnitCsvAdditionDialog,
  Schema,
  getAltDisplaySchema,
  DateSuggest,
  ApprovalRequestDialog,
  Accordion,
  SimpleIconListView,
} from '~/shared/components/ui';
import { mtechnavi, sharelib } from '~/shared/libs/clientsdk';
import { PresetItem } from '~/shared/services';
import {
  AttachmentItems,
  FullMethodName_ListSurveyRequestCatalogs,
  PageState,
  ViewId,
  getExceptionMessage,
  getWorkerExceptionMessage,
  getMaxMainContentsHeight,
  getProgramOptionFilterboxData,
  convertDatetime,
  convertDate,
  includeInputValidateError,
  autoDownloadFileOnlyName,
  autoBulkDownload,
  getRandomStringId,
  saveLocalStorageCheckboxData,
  createAttachmentAsset,
  handleCommonFIleUpload,
  convertDisplayUserNameEmail,
  nameOptionToLocaleString,
  FullMethodName_ListSharedContactContents,
  createWorkTaskRelation,
  convertUserReference,
  convertOrganizationStructureReference,
  convertOrganizationToDataFilterBox,
  convertOrganizationStructureReferenceToFilterboxItem,
} from '~/shared/utils';
import urlList from '~/shared/menu/path.json';
import { CaptionButton } from '~/shared/components/ui/Button/CaptionButton';
import './SurveyRequestSenderInput.css';
import { SearchListViewDialog } from '~/shared/components/ui/SearchListViewDialog';
import Long from 'long';
import {
  AutoReminderSettingDialogOutputOption,
  BusinessUnitManagementItem,
  FileUploadAppendDialog,
  FileUploadAppendDialogResult,
  OutputOption,
} from '~/shared/components/ui/Dialog';
import { useAuth } from '~/shared/contexts/AuthProvider';
import {
  FormSettingDialog,
  FormSettingDialogResult,
} from '~/shared/components/ui/Dialog/FormSettingDialog';
import {
  SURVEY_PURPOSES_CODE,
  SurveyRequestWorkFormWithValues,
  SurveyResultWorkFormWithValues,
  convertToRequestWorkFormWithValues,
  convertToPlanResultWorkFormWithValues,
  listBaseForms,
  listFormSettingItems,
  listFormSettings,
  listFormValues,
  listForms,
  listSurveyCatalog,
  listSurveyRequestWorkForms,
  listPlanSurveyResultWorkForms,
  listUser,
  listSharedContactContents,
  isSurveyRequestWorkFormWithValues,
  isSurveyResultWorkFormWithValues,
} from '../utils';
import dayjs from 'dayjs';
import {
  BuildedInputFormDialog,
  BuildedInputFormDialogRef,
} from '~/shared/components/ui/FormBuilder';
import { FormValuesTypeNames } from '~/shared/components/ui/FormBuilder/utils';
import { NotificationSendTargetSettingDialog } from '~/shared/components/ui/Dialog/NotificationSendTargetSettingDialog';
import { BqListDelayTime } from '~/shared/config';
import { FullMethodName_ListUserAttributes } from '~/worker';

type MatchingValue = 'OK' | 'NG';
/**
 * 連絡先
 */
interface BusinessUnitListViewItem {
  businessUnitContactId: string;
  businessUnitManagementId: string;
  hasError: boolean;
  matching: MatchingValue;
  displayName: string;
  attribute: string;
  contactData?: mtechnavi.api.company.ISharedContactContent | null; // 対応する連絡先データ
}
/**
 * 取引先別ファイル
 */
interface BusinessUnitAttachmentItem {
  id: string;
  hasError: boolean;
  matching: MatchingValue;
  displayName: string;
  attribute: string;
  attachment: AttachmentItems;
  businessUnitContactId: string;
  businessUnitManagementId: string;
  contactData?: mtechnavi.api.company.ISharedContactContent | null; // 対応する連絡先データ
}

const VIEW_ID: ViewId = 'SURVEY_REQUEST_SENDER_INPUT';
const CONTACT_SEARCH_VIEW_ID: ViewId = 'SHARED_CONTACT_CONTENTS_SEARCH';
const FORM_TYPE_NAME: FormValuesTypeNames =
  'mtechnavi.api.survey.SurveyBaseRequest';

const MAX_REQUEST_FORM = 10;

const requestFormListColumns = [
  {
    header: { id: 'requestForm', viewId: VIEW_ID },
    propertyName: 'statusName',
    width: '8rem',
  },
  { propertyName: 'displayName' },
  { propertyName: 'attribute1', width: '15%' },
  { propertyName: 'attribute2', width: '15%' },
  { propertyName: 'attribute3', width: '15%' },
];
const resultFormListColumns = [
  {
    header: { id: 'resultForm', viewId: VIEW_ID },
    propertyName: 'displayName',
  },
  { propertyName: 'attribute1', width: '20%' },
  { propertyName: 'attribute2', width: '20%' },
  { propertyName: 'attribute3', width: '20%' },
];
const businessUnitRequestAttachmentListColumns = [
  {
    header: { id: 'businessUnit' },
    propertyName: 'matching',
    readonly: true,
  },
  { propertyName: 'displayName', readonly: true },
  { propertyName: 'attribute' },
];
const businessUnitListColumns = [
  {
    header: { id: 'businessUnit' },
    propertyName: 'matching',
  },
  { propertyName: 'displayName' },
  { propertyName: 'attribute' },
];

export function SurveyRequestSenderInput() {
  const sourcePageInfo = (useLocation().state as PageState) ?? [];
  const actionType = sourcePageInfo.actionType;
  const intl = useIntl();
  const myEmail = useAuth().user?.email ?? '';
  const [isLoading, setLoading] = useState(false);
  const [preset, setPreset] = useState<PresetItem>({ name: '' });
  const [mainContentHeight, setMainContentHeight] = useState('');
  const footerRef = useRef<HTMLDivElement>(null);
  const navi = useNavigate();
  const [backPageUrl, setBackPageUrl] = useState('');
  const loginUser = useAuth().user;
  const locationState = useLocation().state as PageState;
  const formInputDialogRef =
    useRef<
      BuildedInputFormDialogRef<mtechnavi.api.survey.ISurveyRequestWorkForm>
    >(null);
  const executeCreateWorkTaskRelation = useRef(true);

  const sucessMessage = GetMessage({ id: 'I0000001' });

  const viewMessageSave: MessageProps = {
    id: 'C0000001',
    value: {
      $1: GetMessageWithIntl(intl, { id: 'temporarySave' }),
    },
  };
  const viewMessageSend: MessageProps = {
    id: 'C0000001',
    value: {
      $1: GetMessageWithIntl(intl, { id: 'send' }),
    },
  };

  const viewMessageCancel: MessageProps = {
    id: 'confirmationDialogMessage',
    prefixId: VIEW_ID,
  };

  const viewMessageDelete: MessageProps = {
    id: 'confirmationDialogMessageDelete',
    prefixId: VIEW_ID,
  };

  const viewMessageDeleteAll: MessageProps = {
    id: 'confirmationDialogMessageDeleteAll',
    prefixId: VIEW_ID,
  };

  const viewMessageBusinessUnitDelete: MessageProps = {
    id: 'confirmationDialogMessageBusinessUnitDelete',
    prefixId: VIEW_ID,
  };

  const viewMessageBusinessUnitDeleteAll: MessageProps = {
    id: 'confirmationDialogMessageBusinessUnitDeleteAll',
    prefixId: VIEW_ID,
  };

  const viewMessageDeleteForm: MessageProps = {
    id: 'confirmationDialogMessageDeleteForm',
    prefixId: VIEW_ID,
  };

  const viewMessageDeleteAllForm: MessageProps = {
    id: 'confirmationDialogMessageDeleteAllForm',
    prefixId: VIEW_ID,
  };

  const radioItemValues = ['yes', 'no'];
  const radioItems = [
    { value: radioItemValues[0], displayName: '取引先別ファイルあり' },
    {
      value: radioItemValues[1],
      displayName: '取引先別ファイルなし（取引先指定のみ）',
    },
  ];

  const surveyRequestcatalogItemType = {
    value: 'surveyRequestCatalogId',
    displayName: 'displayName',
  };

  // カラムの読み替え処理
  const onHandleFormatSchema = (schema: Schema) => {
    // ja表示のみする処理
    const jaColumn = ['displayNameLang'];
    return getAltDisplaySchema(schema, jaColumn, 'ja');
  };

  // 災害調査ID
  const disasterSurveyId = useMemo(() => {
    if (locationState.sourceViewId !== 'BCP_DISASTER_SURVEY_CONFIRMATION') {
      return null;
    }
    return locationState.ids?.at(0);
  }, [locationState]);

  // 確認ダイアログ
  const [isOpenConfirmDialog, setOpenConfirmDialog] = useState(false);
  // 自動催促設定ダイアログ
  const [isOpenAutoReminderSettingDialog, setOpenAutoReminderSettingDialog] =
    useState(false);
  // ファイル追加ダイアログ
  const [isOpenFileUploadDialog, setOpenFileUploadDialog] = useState(false);
  // CSV追加ダイアログ
  const [isOpenCsvUploadDialog, setOpenCsvUploadDialog] = useState(false);
  // 取引先検索追加ダイアログ
  const [isOpenBusinessUnitSearchDialog, setOpenBusinessUnitSearchDialog] =
    useState(false);
  // 承認依頼ダイアログ
  const [isOpenApprovalRequestDialog, setOpenApprovalRequestDialog] =
    useState(false);
  // フォーム設定ダイアログ
  const [isOpenFormSettingDialog, setOpenFormSettingDialog] = useState(false);
  // 通知メール送信対象設定ダイアログ
  const [
    isOpenNotificationSendTargetSettingDialog,
    setOpenNotificationSendTargetSettingDialog,
  ] = useState(false);

  // 確認ダイアログメッセージ
  const [confirmMessage, setConfirmMessage] =
    useState<MessageProps>(viewMessageCancel);
  const confirmPromiseRef =
    useRef<(value: boolean | PromiseLike<boolean>) => void>();

  // ファイル追加ダイアログTYpe
  const [fileUploadDialogType, setFileUploadDialogType] = useState('');

  // フォーム設定ダイアログ種別
  const [formSettingType, setFormSettingType] = useState<
    'request' | 'result' | null
  >(null);
  const [formSettingInitialData, setFormSettingInitialData] =
    useState<FormSettingDialogResult | null>(null);
  const [formEditTarget, setFormEditTarget] = useState<
    SurveyRequestWorkFormWithValues | SurveyResultWorkFormWithValues | null
  >(null);

  // 依頼カタログ(from依頼カタログ一覧)
  const [catalogList, setCatalogList] =
    useState<mtechnavi.api.survey.ISurveyRequestCatalog[]>();
  // 組織マスタ
  const [organizationList, setOrganizationList] =
    useState<mtechnavi.api.company.IOrganization[]>();
  // 組織所属情報
  const [organizationRelationList, setOrganizationRelationList] =
    useState<mtechnavi.api.company.IOrganizationRelation[]>();
  // ユーザーマスタ
  const [userList, setUserList] =
    useState<mtechnavi.api.tenantadmin.IUserAttribute[]>();
  // フォームテンプレート(回答フォーム編集時にテンプレート名を取得するため保持)
  const [baseFormList, setBaseFormList] = useState<
    mtechnavi.api.form.IBaseForm[]
  >([]);
  // 連絡先コンテンツ
  const sharedContactContents = useRef<
    mtechnavi.api.company.ISharedContactContent[]
  >([]);

  // 画面アイテムセット用 依頼伝票データ
  const [surveyBaseRequest, setSurveyBaseRequest] =
    useState<mtechnavi.api.survey.ISurveyBaseRequest>();

  // 画面アイテム
  const [surveyRequestCatalog, setSurveyRequestCatalog] = useState<
    FilterboxItem[]
  >([]);
  const [displayName, setDisplayName] = useState('');
  const [surveyRequestAutoName, setSurveyRequestAutoName] = useState('');
  const [replyDueDateDt, setReplyDueDateDt] = useState<Date | null>();
  const [guidance, setGuidance] = useState('');
  const [approval, setApproval] = useState<string[]>([]);
  const [completeCondition, setCompleteCondition] = useState<
    DataFilterboxItem[]
  >([]);
  const [partialReply, setPartialReply] = useState<string[]>([]);
  const [decline, setDecline] = useState<string[]>([]);
  const [requester1, setRequester1] = useState('');
  const [requester2, setRequester2] = useState('');
  const [managementOrganization, setManagementOrganization] = useState<
    DataFilterboxItem[]
  >([]);
  const [systemNotificationTos, setSystemNotificationTos] = useState<
    DataFilterboxItem[]
  >([]);
  const [
    isSystemNotificationIncludeMainContact,
    setSystemNotificationIncludeMainContact,
  ] = useState(false);
  const [isRequiredAttachment, setRequiredAttachment] = useState(false);
  const [isRequestFormUsable, setRequestFormUsable] = useState(false);
  const [isResultFormUsable, setResultFormUsable] = useState(false);
  // 依頼フォーム情報
  const [requestForms, setRequestForms] = useState<
    SurveyRequestWorkFormWithValues[]
  >([]);
  // 削除した依頼フォーム
  const [deleteRequestForms, setDeleteRequestForms] = useState<
    SurveyRequestWorkFormWithValues[]
  >([]);
  // 回答フォーム情報
  const [resultForms, setResultForms] = useState<
    SurveyResultWorkFormWithValues[]
  >([]);
  // 削除した回答フォーム
  const [deleteResultForms, setDeleteResultForms] = useState<
    SurveyResultWorkFormWithValues[]
  >([]);

  // ラジオボタン
  const [businessUnitRadio, setBusinessUnitRadio] = useState(
    radioItemValues[0]
  );
  const [isBusinessUnitRadioDisabled, setBusinessUnitRadioDisabled] =
    useState(false);

  // 取得データ格納用 依頼伝票データ
  const surveyBaseRequestData = useRef<mtechnavi.api.survey.ISurveyBaseRequest>(
    {}
  );

  // 入力チェック用
  const requireHeaderArea = useRef(null);
  const requireCompleteCondition = useRef(null);
  const [workingBlurCompleteCondition, setWorkingBlurCompleteCondition] =
    useState<Date>();
  const [workingBlurSurveyRequestCatalog, setWorkingBlurSurveyRequestCatalog] =
    useState<Date>();

  // ファイル添付処理用
  const [commonRequestAttachmentItems, setCommonRequestAttachmentItems] =
    useState<AttachmentItems[]>([]);
  const [
    businessUnitRequestAttachmentItems,
    setBusinessUnitRequestAttachmentItems,
  ] = useState<BusinessUnitAttachmentItem[]>([]);

  // 取引先リスト (表示用)
  const [businessUnitListViewItem, setBusinessUnitListViewItem] = useState<
    BusinessUnitListViewItem[]
  >([]);

  // DataFilterbox用(名称マスタ)
  const completeConditions = useMemo(
    () => getProgramOptionFilterboxData('A1000002'),
    []
  );
  // DataFilterbox用(システム通知先)
  const systemNotificationTosList = useMemo(
    () =>
      userList
        ? userList!.map(
            (v): FilterboxItem => ({
              displayName: convertDisplayUserNameEmail(
                v.user?.displayName,
                v.user?.email
              ),
              value: v.user?.userId ?? '',
            })
          ) ?? []
        : [],
    [userList]
  );
  // DataFilterbox用(依頼管理組織)
  const organizationTosDataFilterItems = useMemo(
    () =>
      organizationList
        ? convertOrganizationToDataFilterBox(organizationList)
        : [],
    [organizationList]
  );

  // 依頼フォーム入力ステータス(名称マスタ)
  const [enteredStatus, notEnteredStatus] = useMemo(() => {
    const status = window.App.services.ui.getNameOption('A1010003');
    return [
      status.find((item) => item.systemName === 'B02'),
      status.find((item) => item.systemName === 'B01'),
    ] as const;
  }, []);

  /**
   * 連絡先検索ダイアログ選択時処理
   */
  const handleSearchSelect = (
    contacts: mtechnavi.api.company.ISharedContactContent[]
  ) => {
    if (contacts.length === 0) {
      return;
    }

    const listViewItems: BusinessUnitListViewItem[] = [];
    contacts.forEach((contact) => {
      const duplicateId = businessUnitListViewItem.some(
        (w) =>
          w.businessUnitContactId ===
          contact.businessUnitContact?.businessUnitContactId
      );
      if (duplicateId) {
        return;
      }

      // 表示用に加工して追加
      listViewItems.push({
        businessUnitContactId:
          contact.businessUnitContact?.businessUnitContactId ?? '',
        businessUnitManagementId:
          contact.businessUnitManagement?.businessUnitManagementId ?? '',
        hasError: false,
        matching: 'OK',
        displayName: `${contact.businessUnitManagement?.displayName || ''}${
          contact?.businessUnitContact?.displayName
            ? `(${contact?.businessUnitContact?.displayName})`
            : ''
        }`,
        attribute: `${contact.businessUnitManagement?.code || ''}${
          contact?.businessUnitContactAttribute?.code
            ? `(${contact?.businessUnitContactAttribute?.code})`
            : ``
        }`,
        contactData: contact,
      });
    });

    setBusinessUnitListViewItem(
      sortBusinessUnitList([...businessUnitListViewItem, ...listViewItems])
    );

    clearContactSearchCheckBox();
    setOpenBusinessUnitSearchDialog(false);
  };

  const clearContactSearchCheckBox = () => {
    saveLocalStorageCheckboxData(CONTACT_SEARCH_VIEW_ID, [], myEmail);
  };

  // 画面サイズ変更時の高さ調整
  useEffect(() => {
    setMainContentHeight(
      getMaxMainContentsHeight(footerRef.current?.clientHeight ?? 0)
    );
  }, [footerRef.current?.clientHeight]);

  // 画面データ取得
  useEffect(() => {
    setLoading(true);

    //非表示項目リセット
    surveyBaseRequestData.current = {};

    // 戻るボタンURLセット
    setBackPageUrl(
      urlList.find((v) => v.viewId === sourcePageInfo.sourceViewId)?.path ?? ''
    );

    (async () => {
      try {
        // 共通で取得するもの
        const [
          { presetItem },
          listSurveyCatalogRes,
          listOrganizationRes,
          listOrganizationRelationRes,
          listUserRes,
          listBaseFormRes,
          listSharedContactContentsRes,
        ] = await Promise.all([
          window.App.services.ui.getViewIdPreset(VIEW_ID), // プリセット取得
          listSurveyCatalog(), // カタログマスタ
          await window.App.services.ui.listOrganization(), // 組織マスタ
          await window.App.services.ui.listOrganizationRelation(), // 組織所属情報
          listUser(), // ユーザーマスタ
          listBaseForms({ purposes: [SURVEY_PURPOSES_CODE] }), // フォームテンプレート
          listSharedContactContents(), // 連絡先コンテンツ
        ]);
        setPreset(presetItem);
        setCatalogList(listSurveyCatalogRes.items);
        setOrganizationList(listOrganizationRes);
        setOrganizationRelationList(listOrganizationRelationRes);
        setUserList(listUserRes.items);
        setBaseFormList(listBaseFormRes.items);
        sharedContactContents.current =
          listSharedContactContentsRes.items || [];

        // 新規登録モード（依頼管理一覧から追加）
        if (actionType === 'add') {
          const usersFilterBoxItems: FilterboxItem[] = [
            {
              displayName: convertDisplayUserNameEmail(
                loginUser?.displayName,
                loginUser?.email
              ),
              value: loginUser?.userId ?? '',
            },
          ];
          setSystemNotificationTos(usersFilterBoxItems);
          const userRepresentativeOrg = listUserRes.items.find(
            (user) => user.user?.userId === loginUser?.userId
          )?.representativeOrganization;
          userRepresentativeOrg
            ? setManagementOrganization([
                convertOrganizationStructureReferenceToFilterboxItem(
                  userRepresentativeOrg || null
                ) || {
                  displayName: '',
                  value: '',
                },
              ])
            : setManagementOrganization([]);
        }
        // 依頼カタログ一覧から依頼カタログを選択して登録
        if (
          actionType === 'add' &&
          sourcePageInfo.sourceViewId === 'SURVEY_REQUEST_CATALOG_LIST'
        ) {
          // カタログマスタから取得
          const selectedSurveyRequestCatalog = (
            listSurveyCatalogRes.items ?? []
          ).filter((v) =>
            (sourcePageInfo.ids || []).length > 0
              ? v.surveyRequestCatalogId === sourcePageInfo.ids![0] ?? ''
              : ''
          );
          const filterboxItemSurveyRequestCatalog =
            selectedSurveyRequestCatalog.length > 0
              ? [
                  {
                    value:
                      selectedSurveyRequestCatalog[0].surveyRequestCatalogId ??
                      '',
                    displayName:
                      selectedSurveyRequestCatalog[0].displayName ?? '',
                  },
                ]
              : [];
          setSurveyRequestCatalog(filterboxItemSurveyRequestCatalog);
          catalogDataToViews(
            listSurveyCatalogRes.items,
            filterboxItemSurveyRequestCatalog
          );
        }
        // 編集モード（依頼確認から遷移）
        if (actionType === 'edit') {
          const getSurveyBaseRequestContentRes =
            await window.App.services.surveySenderService.getSurveyBaseRequestContent(
              {
                surveyBaseRequestId:
                  (sourcePageInfo.ids || []).length > 0
                    ? sourcePageInfo.ids![0] ?? ''
                    : '',
              }
            );

          if (
            getSurveyBaseRequestContentRes?.surveyBaseRequest
              ?.surveyBaseRequestId === ''
          ) {
            setLoading(false);
            error([GetMessageWithIntl(intl, { id: 'E0000070' })]);
            return;
          }

          // 非表示項目のセット
          surveyBaseRequestData.current =
            getSurveyBaseRequestContentRes?.surveyBaseRequest || {};

          // 画面項目のセット
          setSurveyBaseRequest(surveyBaseRequestData.current);
          surveyBaseRequestToViews(surveyBaseRequestData.current);

          if (
            getSurveyBaseRequestContentRes?.surveyBaseRequest?.requestFormUsable
          ) {
            // 依頼フォームデータのセット
            const requestFormListData = await fetchRequestFromToView(
              getSurveyBaseRequestContentRes?.surveyBaseRequest
                ?.requestFormIds || []
            );
            setRequestForms(requestFormListData);
          }

          if (
            getSurveyBaseRequestContentRes?.surveyBaseRequest
              ?.resultFormUsable &&
            getSurveyBaseRequestContentRes?.surveyBaseRequest
              ?.surveyResultWorkFormIds &&
            getSurveyBaseRequestContentRes?.surveyBaseRequest
              ?.surveyResultWorkFormIds.length > 0
          ) {
            // 回答フォームデータのセット
            const resultFormListRes = await listPlanSurveyResultWorkForms(
              getSurveyBaseRequestContentRes.surveyBaseRequest
                .surveyResultWorkFormIds
            );
            setResultForms(
              convertToPlanResultWorkFormWithValues(
                resultFormListRes.items || [],
                { baseFormList: listBaseFormRes.items || [] }
              )
            );
          }
        }
      } catch (err) {
        error(getExceptionMessage(intl, err));
      } finally {
        setLoading(false);
      }
    })();

    // 初回だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 画面データセット
  const surveyBaseRequestToViews = (
    surveyBaseRequest: mtechnavi.api.survey.ISurveyBaseRequest
  ) => {
    const filterboxItemSurveyRequestCatalog =
      surveyBaseRequest?.surveyRequestCatalog
        ? [
            {
              value:
                surveyBaseRequest.surveyRequestCatalog.surveyRequestCatalogId ??
                '',
              displayName:
                surveyBaseRequest.surveyRequestCatalog.displayName ?? '',
            },
          ]
        : [];
    setSurveyRequestCatalog(filterboxItemSurveyRequestCatalog);
    setDisplayName(surveyBaseRequest?.displayName ?? '');
    setSurveyRequestAutoName(
      surveyBaseRequest?.surveyRequestAutoName
        ? Long.fromValue(surveyBaseRequest?.surveyRequestAutoName).toString(10)
        : ''
    );
    const replyDueDateDate = convertDate(
      surveyBaseRequest?.replyDueDateDt || null
    );
    setReplyDueDateDt(replyDueDateDate);
    setGuidance(surveyBaseRequest?.guidance ?? '');
    setApproval(surveyBaseRequest?.approval ? ['1'] : []);
    const filterboxItemCompleteCondition = surveyBaseRequest?.completeCondition
      ? [
          {
            value: surveyBaseRequest?.completeCondition?.code ?? '',
            displayName:
              surveyBaseRequest?.completeCondition?.displayNameLang?.ja ?? '',
          },
        ]
      : [];
    setCompleteCondition(filterboxItemCompleteCondition);
    setPartialReply(surveyBaseRequest?.partialReply ? ['1'] : []);
    setDecline(surveyBaseRequest?.decline ? ['1'] : []);
    setRequester1(
      (surveyBaseRequest?.requesters ?? []).length > 0
        ? surveyBaseRequest!.requesters![0]
        : ''
    );
    setRequester2(
      (surveyBaseRequest?.requesters ?? []).length > 1
        ? surveyBaseRequest!.requesters![1]
        : ''
    );
    setRequiredAttachment(!!surveyBaseRequest.requiredAttachment);
    setRequestFormUsable(!!surveyBaseRequest.requestFormUsable);
    setResultFormUsable(!!surveyBaseRequest.resultFormUsable);

    const usersFilterBoxItems: FilterboxItem[] = (
      surveyBaseRequest?.systemNotificationUsers || []
    ).map((v) => ({
      displayName: convertDisplayUserNameEmail(v.displayName, v.email),
      value: v.userId ?? '',
    }));
    setSystemNotificationTos(usersFilterBoxItems);
    const managementOrg = convertOrganizationStructureReferenceToFilterboxItem(
      surveyBaseRequest?.managementOrganization
    );
    if (managementOrg) {
      setManagementOrganization([managementOrg]);
    }
    setSystemNotificationIncludeMainContact(
      !!surveyBaseRequest.systemNotificationIncludeMainContact
    );

    if (
      surveyBaseRequest?.requestUnits &&
      surveyBaseRequest?.requestUnits.length > 0 &&
      (!surveyBaseRequest?.businessUnitRequestAttachments ||
        surveyBaseRequest?.businessUnitRequestAttachments?.length === 0)
    ) {
      // 取引先の設定があるが、取引先別ファイルがない場合
      setBusinessUnitRadio(radioItemValues[1]);

      // 取引先を連絡先単位に展開して保持する
      const listViewItems = surveyBaseRequest?.requestUnits?.flatMap((v) =>
        (v.businessUnitContactIds || []).map(
          (contactId): BusinessUnitListViewItem => {
            const contactData = sharedContactContents.current.find(
              (item) =>
                item.businessUnitContact?.businessUnitContactId === contactId
            );
            return {
              businessUnitContactId: contactId ?? '',
              businessUnitManagementId: v.businessUnitManagementId ?? '',
              hasError: false,
              matching: 'OK',
              displayName: `${v.displayName || ''}${
                contactData?.businessUnitContact?.displayName
                  ? `(${contactData?.businessUnitContact?.displayName})`
                  : ''
              }`,
              attribute: `${v.code || ''}${
                contactData?.businessUnitContactAttribute?.code
                  ? `(${contactData?.businessUnitContactAttribute?.code})`
                  : ``
              }`,
              contactData,
            };
          }
        )
      );
      setBusinessUnitListViewItem(sortBusinessUnitList(listViewItems));
    } else {
      setBusinessUnitRadio(radioItemValues[0]);
    }

    // ファイル
    convertAndSetCommonRequestAttachmentItems(
      surveyBaseRequest?.commonRequestAttachments ?? []
    );
    convertAndSetBusinessUnitRequestAttachmentItems(
      surveyBaseRequest?.businessUnitRequestAttachments ?? []
    );
  };

  /**
   * 依頼関連フォームに関する情報をまとめて取得して画面用の値に変換する
   */
  const fetchRequestFromToView = async (formIds: string[]) => {
    if (formIds.length === 0) {
      return [];
    }
    const [
      requestFormListRes,
      formListRes,
      formSettingListRes,
      formSettingItemListRes,
      formValueListRes,
    ] = await Promise.all([
      listSurveyRequestWorkForms(formIds),
      listForms(formIds),
      listFormSettings(formIds),
      listFormSettingItems(formIds),
      listFormValues(formIds),
    ]);

    return convertToRequestWorkFormWithValues(
      requestFormListRes.items || [],
      {
        formList: formListRes.items || [],
        formSettingList: formSettingListRes.items || [],
        formSettingItemList: formSettingItemListRes.items || [],
        formValueList: formValueListRes.items || [],
        targetId: surveyBaseRequestData.current?.surveyBaseRequestId || '',
      },
      { intl, enteredStatus, notEnteredStatus }
    );
  };

  /**
   * カタログマスタ変更時の画面データセット
   * 新規時のみ使用する。
   */
  const catalogDataToViews = (
    catalogDataList: mtechnavi.api.survey.ISurveyRequestCatalog[],
    catalogItems: FilterboxItem[]
  ) => {
    if (catalogItems.length > 0) {
      (async () => {
        const surveyRequestCatalogItem = catalogDataList?.find(
          (item) => item.surveyRequestCatalogId === catalogItems[0].value
        );
        if (!surveyRequestCatalogItem) {
          return;
        }

        if (surveyRequestCatalogItem?.surveyRequestCatalogId === '') {
          setLoading(false);
          error([GetMessageWithIntl(intl, { id: 'E0000070' })]);
          return;
        }
        setDisplayName(surveyRequestCatalogItem?.displayName ?? '');
        setGuidance(surveyRequestCatalogItem?.guidance ?? '');
        setApproval(surveyRequestCatalogItem?.approval ? ['1'] : []);
        const completeConditionItem: DataFilterboxItem[] =
          surveyRequestCatalogItem.completeCondition
            ? completeConditions.filter(
                (v) =>
                  v.value === surveyRequestCatalogItem.completeCondition?.code
              )
            : [];
        setCompleteCondition(completeConditionItem);
        setPartialReply(surveyRequestCatalogItem.partialReply ? ['1'] : []);
        setDecline(surveyRequestCatalogItem.decline ? ['1'] : []);

        surveyBaseRequestData.current.autoReminderSettings =
          surveyRequestCatalogItem.autoReminderSettings;
        setRequester1(
          (surveyRequestCatalogItem.requesters ?? []).length > 0
            ? surveyRequestCatalogItem!.requesters![0]
            : ''
        );
        setRequester2(
          (surveyRequestCatalogItem.requesters ?? []).length > 1
            ? surveyRequestCatalogItem!.requesters![1]
            : ''
        );
        setRequiredAttachment(!!surveyRequestCatalogItem.requiredAttachment);
        setRequestFormUsable(!!surveyRequestCatalogItem.requestFormUsable);
        setResultFormUsable(!!surveyRequestCatalogItem.resultFormUsable);

        // ファイル
        convertAndSetCommonRequestAttachmentItems(
          surveyRequestCatalogItem.commonAttachments ?? []
        );
      })();
    }
  };

  // テンプレート(カタログ)変更時の処理
  const handleChangeSurveyRequestCatalog = (catalogs: FilterboxItem[]) => {
    setSurveyRequestCatalog(catalogs);
    // 新規モード時のみ、テンプレートの値を各項目にセットする
    if (!surveyBaseRequest?.surveyBaseRequestId) {
      catalogDataToViews(catalogList || [], catalogs);
    }
  };

  const sortBusinessUnitList = (data: BusinessUnitListViewItem[]) => {
    return data.sort((val1, val2) => {
      if (val1.matching === val2.matching) {
        return val1.attribute.localeCompare(val2.attribute, 'ja');
      }
      if (val1.matching === 'OK') {
        return 1;
      }
      return -1;
    });
  };

  const sortBusinessUnitAttachmentList = (
    data: BusinessUnitAttachmentItem[]
  ) => {
    return data.sort((val1, val2) => {
      if (val1.matching === val2.matching) {
        return val1.attribute.localeCompare(val2.attribute, 'ja');
      }
      if (val1.matching === 'OK') {
        return 1;
      }
      return -1;
    });
  };

  // 取引先別ファイルのアップロード後処理
  const handleBusinessUnitFIleUpload = async (
    result: FileUploadAppendDialogResult
  ) => {
    try {
      const assetRes = await createAttachmentAsset(result, 'B05');

      if (!assetRes) {
        setOpenFileUploadDialog(false);
        return;
      }
      const category = window.App.services.ui.getNameOptionWithSystemName(
        'A0000016',
        'B03'
      );
      const linkType = window.App.services.ui.getNameOptionWithSystemName(
        'A0000017',
        'B03'
      );
      // 重複データがある場合、上書きするため元のデータから削除する
      const deletedDuplicatedDataList =
        businessUnitRequestAttachmentItems.filter(
          (item) =>
            !result.files.some(
              (file) => file.file.name === item.attachment.filename
            )
        );
      assetRes.forEach((asset) => {
        let resultAsset: mtechnavi.api.assetinventory.IAsset = {};
        // Promise.allで複数件のアセットを追加する時、配列で返却される時があるのでその対応
        if (Array.isArray(asset)) {
          resultAsset = asset.at(0) || {};
        } else {
          resultAsset = asset;
        }
        // マッチング
        const businessUnitManagementCode =
          (resultAsset?.filename || '').split('_').at(0) || '';
        const contactData = sharedContactContents.current.find(
          (contact) =>
            contact.businessUnitContactAttribute?.code ===
              businessUnitManagementCode &&
            contact.businessUnitManagement?.status?.systemName === 'B00' // B00:取引中
        );
        const isError = !contactData;

        const tempId = getRandomStringId();
        deletedDuplicatedDataList.push({
          id: isError ? tempId : resultAsset.assetId ?? '',
          hasError: isError,
          matching: isError ? 'NG' : 'OK',
          displayName: `${
            contactData?.businessUnitManagement?.displayName || ''
          }${
            contactData?.businessUnitContact?.displayName
              ? `(${contactData?.businessUnitContact?.displayName})`
              : ''
          }`,
          attribute: resultAsset.filename ?? '',
          attachment: {
            id: resultAsset.assetId ?? '',
            assetId: resultAsset.assetId ?? '',
            filename: resultAsset.filename ?? '',
            mimeType: resultAsset.mimeType ?? '',
            category: category.at(0) ?? {},
            linkType: linkType.at(0) ?? {},
          },
          businessUnitContactId:
            contactData?.businessUnitContact?.businessUnitContactId ?? '',
          businessUnitManagementId:
            contactData?.businessUnitManagement?.businessUnitManagementId ?? '',
          contactData,
        });
      });
      setBusinessUnitRequestAttachmentItems(
        sortBusinessUnitAttachmentList(deletedDuplicatedDataList)
      );
      setOpenFileUploadDialog(false);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    }
  };

  // 取引先指定CSVアップロード後の制御
  const mergeUploadedContactList = (
    businessUnitUploadCsv: BusinessUnitManagementItem
  ) => {
    const listViewItems: BusinessUnitListViewItem[] = [
      ...businessUnitListViewItem,
    ];

    if (
      !businessUnitUploadCsv?.data ||
      businessUnitUploadCsv.data.length === 0
    ) {
      return;
    }

    businessUnitUploadCsv?.data.forEach((csvItem) => {
      // 紐づく連絡先を取得
      const contactData = sharedContactContents.current.find(
        (contact) =>
          contact.businessUnitContactAttribute?.code === csvItem.code &&
          contact.businessUnitManagement?.status?.systemName === 'B00' // B00:取引中
      );
      if (!contactData) {
        // 存在しないなら NG データとする
        listViewItems.push({
          businessUnitContactId: csvItem.code, // エラーデータも一意にするため code をセット
          businessUnitManagementId: '',
          hasError: true,
          matching: 'NG',
          displayName: '',
          attribute: csvItem.code,
        });
        return;
      }

      // 重複チェック
      const isDuplicateContact = listViewItems.some(
        (item) =>
          item.businessUnitContactId ===
          contactData.businessUnitContact?.businessUnitContactId
      );
      if (isDuplicateContact) {
        return;
      }

      // 新しい連絡先
      listViewItems.push({
        businessUnitContactId:
          contactData.businessUnitContact?.businessUnitContactId ?? '',
        businessUnitManagementId:
          contactData.businessUnitManagement?.businessUnitManagementId ?? '',
        hasError: false,
        matching: 'OK',
        displayName: `${contactData.businessUnitManagement?.displayName || ''}${
          contactData?.businessUnitContact?.displayName
            ? `(${contactData?.businessUnitContact?.displayName})`
            : ''
        }`,
        attribute: `${contactData?.businessUnitManagement?.code || ''}${
          contactData?.businessUnitContactAttribute?.code
            ? `(${contactData?.businessUnitContactAttribute?.code})`
            : ``
        }`,
        contactData,
      });
    });

    setBusinessUnitListViewItem(sortBusinessUnitList(listViewItems));
  };

  // ラジオボタン制御
  useEffect(() => {
    businessUnitRequestAttachmentItems.length > 0
      ? setBusinessUnitRadioDisabled(true)
      : setBusinessUnitRadioDisabled(false);
  }, [businessUnitRequestAttachmentItems]);
  useEffect(() => {
    businessUnitListViewItem.length > 0
      ? setBusinessUnitRadioDisabled(true)
      : setBusinessUnitRadioDisabled(false);
  }, [businessUnitListViewItem]);

  //取引先リスト削除
  const deleteBusinessUnitItems = (
    status: 'delete' | 'deleteAll',
    item?: BusinessUnitListViewItem
  ) => {
    if (status === 'delete') {
      const items = businessUnitListViewItem?.filter(
        (v) => v.businessUnitContactId !== item?.businessUnitContactId
      );
      setBusinessUnitListViewItem(items);
    }
    if (status === 'deleteAll') {
      setBusinessUnitListViewItem([]);
    }
  };

  const convertAndSetCommonRequestAttachmentItems = (
    attachmentItems: sharelib.IAttachment[]
  ) => {
    const resultItems: AttachmentItems[] = attachmentItems.map((item) => ({
      id: item.assetId ?? '',
      category: item.category ?? {},
      assetId: item.assetId ?? '',
      filename: item.filename ?? '',
      mimeType: item.mimeType ?? '',
      remarks: item.remarks ?? '',
      linkType: item.linkType ?? {},
    }));
    setCommonRequestAttachmentItems(resultItems);
  };

  const convertAndSetBusinessUnitRequestAttachmentItems = (
    attachmentItems: mtechnavi.api.survey.IAttachmentAttribute[]
  ) => {
    const resultItems: BusinessUnitAttachmentItem[] = attachmentItems.map(
      (item) => {
        const businessUnitManagementCode =
          (item?.attachment?.filename || '').split('_').at(0) || '';
        const contactData = sharedContactContents.current.find(
          (contact) =>
            contact.businessUnitContactAttribute?.code ===
            businessUnitManagementCode
        );
        return {
          id: item.attachment?.assetId ?? '',
          hasError: (item.businessUnitManagementId ?? '') === '',
          matching: (item.businessUnitManagementId ?? '') === '' ? 'NG' : 'OK',
          displayName: `${
            contactData?.businessUnitManagement?.displayName || ''
          }${
            contactData?.businessUnitContact?.displayName
              ? `(${contactData?.businessUnitContact?.displayName})`
              : ''
          }`,
          attribute: item.attachment?.filename ?? '',
          attachment: {
            id: item.attachment?.assetId ?? '',
            category: item.attachment?.category ?? {},
            assetId: item.attachment?.assetId ?? '',
            filename: item.attachment?.filename ?? '',
            mimeType: item.attachment?.mimeType ?? '',
            remarks: item.attachment?.remarks ?? '',
            linkType: item.attachment?.linkType ?? {},
          },
          businessUnitContactId:
            contactData?.businessUnitContact?.businessUnitContactId ?? '',
          businessUnitManagementId: item.businessUnitManagementId ?? '',
          contactData,
        };
      }
    );
    setBusinessUnitRequestAttachmentItems(
      sortBusinessUnitAttachmentList(resultItems)
    );
  };

  // 表示用の取引先別ファイルリストから保存用の取引先リストに変換する
  const contactAttachmentListToRequestUnits = (
    items: BusinessUnitAttachmentItem[]
  ): mtechnavi.api.survey.IBusinessUnitProperties[] => {
    const resultList: mtechnavi.api.survey.IBusinessUnitProperties[] = [];
    items.forEach(
      ({
        businessUnitContactId,
        businessUnitManagementId,
        contactData,
        hasError,
      }) =>
        contactItemToRequestUnit(
          resultList,
          businessUnitContactId,
          businessUnitManagementId,
          hasError,
          contactData
        )
    );
    return resultList;
  };

  // 表示用の連絡先リストから保存用の取引先リストに変換する
  const contactListToRequestUnits = (
    items: BusinessUnitListViewItem[]
  ): mtechnavi.api.survey.IBusinessUnitProperties[] => {
    const resultList: mtechnavi.api.survey.IBusinessUnitProperties[] = [];
    items.forEach(
      ({
        businessUnitContactId,
        businessUnitManagementId,
        contactData,
        hasError,
      }) =>
        contactItemToRequestUnit(
          resultList,
          businessUnitContactId,
          businessUnitManagementId,
          hasError,
          contactData
        )
    );
    return resultList;
  };

  const contactItemToRequestUnit = (
    workingResultList: mtechnavi.api.survey.IBusinessUnitProperties[],
    businessUnitContactId: string,
    businessUnitManagementId: string,
    hasError: boolean,
    contactData?: mtechnavi.api.company.ISharedContactContent | null
  ) => {
    if (hasError) {
      return;
    }
    const duplicateRequestUnit = workingResultList.find(
      (item) => item.businessUnitManagementId === businessUnitManagementId
    );

    if (duplicateRequestUnit) {
      // 重複した取引先
      // 連絡先IDも重複していたらスキップ
      if (
        duplicateRequestUnit.businessUnitContactIds?.includes(
          businessUnitContactId
        )
      ) {
        return;
      }
      // 重複していない連絡先IDを追加
      duplicateRequestUnit.businessUnitContactIds = [
        ...(duplicateRequestUnit.businessUnitContactIds || []),
        businessUnitContactId,
      ];
      // 連絡先ユーザを追加
      contactData?.businessUnitContact?.notificationUsers?.forEach(
        (notifyUser) => {
          if (!notifyUser.user) {
            return;
          }
          // 取引先担当者の追加
          if (
            notifyUser.category?.systemName === 'B01' &&
            duplicateRequestUnit.mainContact?.every(
              (contact) => contact.email !== notifyUser.user?.email
            )
          ) {
            duplicateRequestUnit.mainContact.push(notifyUser.user);
          }
          // 取引先アシスタントの追加
          if (
            notifyUser.category?.systemName === 'B02' &&
            duplicateRequestUnit.mainContactAssistant?.every(
              (contact) => contact.email !== notifyUser.user?.email
            )
          ) {
            duplicateRequestUnit.mainContactAssistant.push(notifyUser.user);
          }
        }
      );
    } else {
      // 重複していない取引先
      const requestUnit: mtechnavi.api.survey.IBusinessUnitProperties = {
        businessUnitManagementId:
          contactData?.businessUnitManagement?.businessUnitManagementId ?? '',
        businessUnitContactIds: [
          contactData?.businessUnitContact?.businessUnitContactId || '',
        ],
        code: contactData?.businessUnitManagement?.code ?? '',
        displayName: contactData?.businessUnitManagement?.displayName ?? '',
        mainContact: (contactData?.businessUnitContact?.notificationUsers || [])
          .filter((notifyUser) => notifyUser.category?.systemName === 'B01')
          .map((notifyUser) => notifyUser.user || {}),
        mainContactAssistant: (
          contactData?.businessUnitContact?.notificationUsers || []
        )
          .filter((notifyUser) => notifyUser.category?.systemName === 'B02')
          .map((notifyUser) => notifyUser.user || {}),
        staff: contactData?.businessUnitManagement?.staff ?? [],
        staffAssistant:
          contactData?.businessUnitManagement?.staffAssistant ?? [],
        companyId: contactData?.businessUnitManagement?.companyId ?? '',
      };
      workingResultList.push(requestUnit);
    }
  };

  // データ保存時の入力項目エラーチェック
  const isInputErrorCheck = () => {
    // 必須入力のfilterboxに強制的にblur作業を行う
    setWorkingBlurSurveyRequestCatalog(new Date());
    setWorkingBlurCompleteCondition(new Date());

    // 入力エラーチェック
    const targetEle = document.querySelector('.scroll-main-contents-area');
    if (
      includeInputValidateError(targetEle, intl, [
        {
          value: surveyRequestCatalog.length > 0 ? 'exists' : '',
          ref: requireHeaderArea,
        },
        {
          value: completeCondition?.length > 0 ? 'exists' : '',
          ref: requireCompleteCondition,
        },
      ])
    ) {
      return true;
    }

    setWorkingBlurSurveyRequestCatalog(undefined);
    setWorkingBlurCompleteCondition(undefined);
    return false;
  };

  // 依頼フォーム入力済みチェック
  const isRequestFormInputErrorCheck = () => {
    if (!isRequestFormUsable) {
      return false;
    }
    if (
      isRequestFormUsable &&
      requestForms.every(
        (formSetting) =>
          formSetting.formValues && formSetting?.formValues.length > 0
      )
    ) {
      return false;
    }
    error([
      GetMessageWithIntl(intl, {
        id: 'E0000121',
      }),
    ]);
    return true;
  };

  // 取引先管理マスタチェック
  const isBusinessUnitManagementErrorCheck = () => {
    const checkItems =
      businessUnitRadio === radioItemValues[0]
        ? businessUnitRequestAttachmentItems
        : businessUnitListViewItem;

    // 取引先未登録
    if (checkItems.length < 1) {
      error([GetMessageWithIntl(intl, { id: 'E0000084' })]);
      return true;
    }

    // 取引先照合エラー
    const isError = checkItems.some((item) => item.hasError);
    if (isError) {
      error([GetMessageWithIntl(intl, { id: 'E0000078' })]);
    }
    return isError;
  };

  // 選択肢した組織がログインユーザーの所属組織もしくは所属親組織と一致するか
  const isUserOrganizationErrorCheck = async () => {
    if (managementOrganization.length === 0) {
      return false;
    }

    // ログインユーザーの所属組織IDと所属組織親組織ID
    const loginUserOrgAndParentIds = await getLoginUserOrgAndParentIds();

    if (
      loginUserOrgAndParentIds.some(
        (v) => v === managementOrganization[0].value
      )
    ) {
      return false;
    }
    error([GetMessageWithIntl(intl, { id: 'E0000161' })]);
    return true;
  };

  const getLoginUserOrgAndParentIds = async () => {
    const loginUserOrganizations: mtechnavi.api.company.IOrganizationRelation[] =
      organizationRelationList?.filter((v) => v.userId === loginUser?.userId) ??
      [];
    const orgs = await Promise.all(
      loginUserOrganizations.map(async (item) => {
        const org = await getOrganizationIds(item.organizationId ?? '');
        return org;
      })
    );
    return orgs.flat();
  };

  const getOrganizationIds = async (organizationId: string) => {
    const org = await convertOrganizationStructureReference(organizationId);
    const ids: string[] = [];
    if (org && org.organizationId) {
      ids.push(org.organizationId);
      const parentIds = org.parentOrganization?.map((item) => {
        return item.organizationId;
      });
      parentIds &&
        parentIds.map((id) => {
          if (id) {
            ids.push(id);
          }
        });
    }
    return ids;
  };

  // 依頼フォーム・回答フォームデータの作成を行う
  const formSave = async () => {
    return Promise.all([
      Promise.all(isRequestFormUsable ? await saveRequestForm() : []),
      Promise.all(isResultFormUsable ? saveResultForm() : []),
    ]);
  };

  // 依頼フォームのデータ作成
  const saveRequestForm = async () => {
    // まだ未作成のフォームのみを GenerateForm する
    const needGenerateBaseFormIds = requestForms
      .filter((requestForm) => !requestForm.form)
      .map((requestForm) => requestForm.tempForm?.baseFormId || '');
    let generateFormResponses: mtechnavi.api.form.IGenerateFormResponse[] = [];
    if (needGenerateBaseFormIds.length > 0) {
      generateFormResponses = (await window.App.services.ui.worker.apiCall({
        actionName: 'generateForm',
        request: {
          baseFormIds: needGenerateBaseFormIds,
        },
      })) as mtechnavi.api.form.IGenerateFormResponse[];
    }

    let orderCounter = 0;
    return requestForms.map(async (requestForm) => {
      orderCounter += 1;
      if (!requestForm.form) {
        const generateForm = generateFormResponses.at(0)?.forms?.shift();
        return window.App.services.ui.worker.apiCall({
          actionName: 'createSurveyRequestWorkForm',
          request: {
            surveyRequestWorkForm: {
              ...requestForm.tempForm,
              formId: generateForm?.formId,
              required: true,
              order: Long.fromNumber(orderCounter),
            },
          },
        });
      } else {
        return window.App.services.ui.worker.apiCall({
          actionName: 'updateSurveyRequestWorkForm',
          request: {
            surveyRequestWorkForm: {
              surveyRequestWorkFormId:
                requestForm.workForm?.surveyRequestWorkFormId,
              formId: requestForm.workForm?.formId,
              displayName: requestForm.tempForm?.displayName,
              attribute1: requestForm.tempForm?.attribute1,
              attribute2: requestForm.tempForm?.attribute2,
              attribute3: requestForm.tempForm?.attribute3,
              notice: requestForm.tempForm?.notice,
              required: true,
              updatedAt: requestForm.workForm?.updatedAt,
              order: Long.fromNumber(orderCounter),
            },
          },
        });
      }
    });
  };

  // 回答フォームのデータ作成
  const saveResultForm = () => {
    let orderCounter = 0;
    return resultForms.map(async (resultForm) => {
      orderCounter += 1;
      if (!resultForm.workForm) {
        return window.App.services.ui.worker.apiCall({
          actionName: 'createSurveyResultWorkForm',
          request: {
            surveyResultWorkForm: {
              baseFormId: resultForm.tempForm?.baseFormId,
              displayName: resultForm.tempForm?.displayName,
              attribute1: resultForm.tempForm?.attribute1,
              attribute2: resultForm.tempForm?.attribute2,
              attribute3: resultForm.tempForm?.attribute3,
              notice: resultForm.tempForm?.notice,
              required: resultForm.tempForm?.required,
              order: Long.fromNumber(orderCounter),
            },
          },
        });
      } else {
        return window.App.services.ui.worker.apiCall({
          actionName: 'updateSurveyResultWorkForm',
          request: {
            surveyResultWorkForm: {
              surveyResultWorkFormId:
                resultForm.workForm.surveyResultWorkFormId,
              baseFormId: resultForm.tempForm?.baseFormId,
              displayName: resultForm.tempForm?.displayName,
              attribute1: resultForm.tempForm?.attribute1,
              attribute2: resultForm.tempForm?.attribute2,
              attribute3: resultForm.tempForm?.attribute3,
              notice: resultForm.tempForm?.notice,
              required: resultForm.tempForm?.required,
              updatedAt: resultForm.workForm.updatedAt,
              order: Long.fromNumber(orderCounter),
            },
          },
        });
      }
    });
  };

  // 依頼フォーム入力値の更新
  const formValueSave = async () => {
    const requestFormValueUpdates = requestForms.map(async (requestForm) => {
      if (!requestForm.formValues || requestForm.formValues.length === 0) {
        return;
      }
      return window.App.services.ui.worker.apiCall({
        actionName: 'saveFormValue',
        request: {
          items: requestForm.formValues,
        },
      });
    });

    return Promise.all(requestFormValueUpdates);
  };

  // フォーム削除の永続化
  const formDeleteSave = async () => {
    return Promise.all([
      // 依頼フォームのフォーム情報をまとめて削除する
      deleteRequestForms.length > 0
        ? await window.App.services.ui.worker.apiCall({
            actionName: 'deleteFormWithRelatedItems',
            request: deleteRequestForms.map(
              (requestForm) => requestForm.workForm?.formId || ''
            ),
          })
        : Promise.resolve(),
      // 依頼フォーム情報を削除する
      ...deleteRequestForms.map(async (requestFormValues) => {
        return window.App.services.ui.worker.apiCall({
          actionName: 'deleteSurveyRequestWorkForm',
          request: {
            surveyRequestWorkForm: {
              surveyRequestWorkFormId:
                requestFormValues.workForm?.surveyRequestWorkFormId,
              updatedAt: requestFormValues.workForm?.updatedAt,
            },
          },
        });
      }),
      // 回答フォーム情報を削除する
      ...deleteResultForms.map(async (resultFormValues) => {
        return window.App.services.ui.worker.apiCall({
          actionName: 'deleteSurveyResultWorkForm',
          request: {
            surveyResultWorkForm: {
              surveyResultWorkFormId:
                resultFormValues.workForm?.surveyResultWorkFormId,
              updatedAt: resultFormValues.workForm?.updatedAt,
            },
          },
        });
      }),
    ]);
  };

  // データの永続化
  const saveAction = async () => {
    // まずフォームを作成する
    const [savedRequestForms, savedResultForms] = await formSave();

    // カタログマスタから取得
    const selectedSurveyRequestCatalog: mtechnavi.api.survey.ISurveyRequestCatalog[] =
      (catalogList ?? []).filter(
        (v) =>
          surveyRequestCatalog.length > 0 &&
          v.surveyRequestCatalogId === surveyRequestCatalog[0].value
      );

    // システム通知先
    const selectedSystemNotificationUsers: sharelib.IUserReference[] = [];
    systemNotificationTos.forEach((v) => {
      const selectedUser = (userList ?? []).find(
        (w) => w.userAttributeId === v.value
      );
      if (selectedUser) {
        selectedSystemNotificationUsers.push({
          userId: selectedUser.user?.userId,
          email: selectedUser.user?.email,
          displayName: selectedUser.user?.displayName,
        });
      }
    });
    const selectedManagementOrganization =
      managementOrganization.length > 0
        ? await convertOrganizationStructureReference(
            managementOrganization[0].value
          )
        : null;

    const requestData: mtechnavi.api.survey.ISurveyBaseRequest = {
      surveyBaseRequestId:
        surveyBaseRequestData.current?.surveyBaseRequestId || null,
      systemNotificationUsers: selectedSystemNotificationUsers,
      managementOrganization: selectedManagementOrganization,
      displayName: displayName,
      replyDueDateDt: convertDatetime(replyDueDateDt || null, 'YYYY/MM/DD'),
      surveyRequestCatalog:
        selectedSurveyRequestCatalog.length > 0
          ? selectedSurveyRequestCatalog[0]
          : {},
      guidance: guidance,
      approval: approval.length > 0 ? approval[0] === '1' : false,
      completeCondition: completeCondition[0]
        ? window.App.services.ui.getNameOptionWithCode(
            'A1000002',
            completeCondition[0].value ?? ''
          )
        : null,
      partialReply: partialReply.length > 0 ? partialReply[0] === '1' : false,
      decline: decline.length > 0 ? decline[0] === '1' : false,
      requiredAttachment: isRequiredAttachment,
      autoReminderSettings: surveyBaseRequestData.current?.autoReminderSettings,
      requesters: [requester1, requester2],
      commonRequestAttachments: commonRequestAttachmentItems,
      businessUnitRequestAttachments: businessUnitRequestAttachmentItems,
      requestUnits:
        businessUnitRadio === radioItemValues[0]
          ? contactAttachmentListToRequestUnits(
              businessUnitRequestAttachmentItems
            )
          : contactListToRequestUnits(businessUnitListViewItem),
      systemNotificationIncludeMainContact:
        isSystemNotificationIncludeMainContact,
      updatedAt: surveyBaseRequestData.current?.updatedAt,
      requestFormUsable: isRequestFormUsable,
      requestFormIds: isRequestFormUsable
        ? savedRequestForms.flatMap((form) => form).map((form) => form.formId)
        : undefined,
      resultFormUsable: isResultFormUsable,
      surveyResultWorkFormIds: isResultFormUsable
        ? savedResultForms
            .flatMap((form) => form)
            .map((form) => form.surveyResultWorkFormId)
        : undefined,
    };

    const baseRequest = (await window.App.services.ui.worker.apiCall({
      actionName: 'saveSurveyBaseRequest',
      request: requestData,
    })) as mtechnavi.api.survey.ISurveyBaseRequest[];

    if (surveyBaseRequestData.current.surveyBaseRequestId) {
      // フォーム入力値の更新
      await formValueSave();
      // 削除されたフォームの更新
      await formDeleteSave();
      setDeleteRequestForms([]);
      setDeleteResultForms([]);
    } else {
      // BCP紐付けデータ更新
      if (disasterSurveyId) {
        await window.App.services.ui.worker.apiCall({
          actionName: 'addSurveyBaseRequestIdInDisasterSurvey',
          request: {
            disasterSurveyId,
            suveyBaseRequestIds: [baseRequest.at(0)?.surveyBaseRequestId || ''],
          },
        });
      }
    }

    return baseRequest;
  };

  // フォーム情報の再取得
  const reloadForm = async () => {
    if (surveyBaseRequestData.current?.requestFormUsable) {
      // 依頼フォームデータのセット
      const requestFormListData = await fetchRequestFromToView(
        surveyBaseRequestData.current?.requestFormIds || []
      );
      setRequestForms(requestFormListData);
    }

    if (
      surveyBaseRequestData.current?.resultFormUsable &&
      surveyBaseRequestData.current?.surveyResultWorkFormIds &&
      surveyBaseRequestData.current?.surveyResultWorkFormIds.length > 0
    ) {
      // 回答フォームデータのセット
      const resultFormListRes = await listPlanSurveyResultWorkForms(
        surveyBaseRequestData.current.surveyResultWorkFormIds
      );
      setResultForms(
        convertToPlanResultWorkFormWithValues(resultFormListRes.items || [], {
          baseFormList,
        })
      );
    }
  };

  // 一時保存
  const handleSave = async () => {
    // 入力エラーチェック
    if (isInputErrorCheck()) {
      return;
    }
    // 取引先管理マスタチェック
    if (isBusinessUnitManagementErrorCheck()) {
      return;
    }
    // ユーザー組織チェック
    if (await isUserOrganizationErrorCheck()) {
      return;
    }
    // 確認処理
    if (!(await confirmation(viewMessageSave))) {
      return;
    }
    setLoading(true);
    try {
      const res =
        (await saveAction()) as mtechnavi.api.survey.ISurveyBaseRequest[];
      // 非表示項目のセット
      surveyBaseRequestData.current = res.length > 0 ? res[0] : {};
      // 指図案件紐付け
      if (
        surveyBaseRequestData.current.surveyBaseRequestId &&
        executeCreateWorkTaskRelation.current
      ) {
        await createWorkTaskRelation(
          [surveyBaseRequestData.current.surveyBaseRequestId],
          sourcePageInfo.beforeStateIds ?? [],
          sourcePageInfo.baseViewOption?.sourceViewId ?? null,
          'survey'
        );
        executeCreateWorkTaskRelation.current = false;
      }
      // フォーム情報の再取得
      await reloadForm();

      success([sucessMessage]);
      setOpenConfirmDialog(false);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      // reload処理
      // 画面項目のセット
      setSurveyBaseRequest(surveyBaseRequestData.current);
      surveyBaseRequestToViews(surveyBaseRequestData.current);
      setLoading(false);
    }
    setLoading(false);
  };

  const handleSend = async () => {
    // 入力エラーチェック
    if (isInputErrorCheck()) {
      return;
    }
    // 取引先管理マスタチェック
    if (isBusinessUnitManagementErrorCheck()) {
      return;
    }
    // ユーザー組織チェック
    if (await isUserOrganizationErrorCheck()) {
      return;
    }
    // 依頼フォーム入力チェック
    if (isRequestFormInputErrorCheck()) {
      return;
    }
    // 確認処理
    if (!(await confirmation(viewMessageSend))) {
      return;
    }

    setLoading(true);
    try {
      const res =
        (await saveAction()) as mtechnavi.api.survey.ISurveyBaseRequest[];
      // 非表示項目のセット
      surveyBaseRequestData.current = res.length > 0 ? res[0] : {};

      // フォーム情報の再取得
      await reloadForm();

      await window.App.services.ui.worker.apiCall({
        actionName: 'sharetoSurveyRequest',
        request: {
          surveyBaseRequestId:
            res.length > 0 ? res[0].surveyBaseRequestId! : '',
        },
      });
      if (
        surveyBaseRequestData.current.surveyBaseRequestId &&
        executeCreateWorkTaskRelation.current
      ) {
        await createWorkTaskRelation(
          [surveyBaseRequestData.current.surveyBaseRequestId],
          sourcePageInfo.beforeStateIds ?? [],
          sourcePageInfo.baseViewOption?.sourceViewId ?? null,
          'survey'
        );
        executeCreateWorkTaskRelation.current = false;
      }
      success([sucessMessage]);
      setOpenConfirmDialog(false);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      setLoading(false);
      throw err;
    } finally {
      // setLoading(false);
      // reload処理
      // 画面項目のセット
      setSurveyBaseRequest(surveyBaseRequestData.current);
      surveyBaseRequestToViews(surveyBaseRequestData.current);
    }
    backToBaseViewPage();
  };

  const handleAutoReminderSetting = (
    result: AutoReminderSettingDialogOutputOption
  ) => {
    if (surveyBaseRequestData.current) {
      surveyBaseRequestData.current.autoReminderSettings = [
        result.autoReminderSetting,
      ];
    }
  };

  const handleBusinessUnitCsvAddition = (
    result: BusinessUnitManagementItem
  ) => {
    if (result.message.length > 0) {
      error(result.message);
      return;
    }
    mergeUploadedContactList(result);
    setOpenCsvUploadDialog(false);
  };

  const handleAddUserToUserReferenceSystemNotificationTos = () => {
    if (managementOrganization.length === 0) {
      return;
    }
    const newUserIds: string[] = [];
    organizationRelationList
      ?.filter((v) => v.organizationId === managementOrganization[0].value)
      .map((v) => {
        if (v.userId) {
          newUserIds.push(v.userId);
        }
      });
    const users = userList?.filter((v) =>
      newUserIds.includes(v.user?.userId ?? '')
    );
    const setItems = users?.filter(
      (v) => !systemNotificationTos.some((i) => i.value === v.user?.userId)
    );
    const setItemsToFilterBox = systemNotificationTosList.filter((v) =>
      setItems?.some((i) => i.user?.userId === v.value)
    );
    if (setItemsToFilterBox.length > 0) {
      setSystemNotificationTos([
        ...systemNotificationTos,
        ...setItemsToFilterBox,
      ]);
    }
  };

  const handleApprovalRequest = async (result: OutputOption) => {
    setLoading(true);
    try {
      // 保存処理
      const res =
        (await saveAction()) as mtechnavi.api.survey.ISurveyBaseRequest[];
      // 非表示項目のセット
      surveyBaseRequestData.current = res.length > 0 ? res[0] : {};
      if (
        surveyBaseRequestData.current.surveyBaseRequestId &&
        executeCreateWorkTaskRelation.current
      ) {
        await createWorkTaskRelation(
          [surveyBaseRequestData.current.surveyBaseRequestId],
          sourcePageInfo.beforeStateIds ?? [],
          sourcePageInfo.baseViewOption?.sourceViewId ?? null,
          'survey'
        );
        executeCreateWorkTaskRelation.current = false;
      }

      // フォーム情報の再取得
      await reloadForm();

      // 担当者マスタから取得
      const selectedStaff =
        result.approvalOrganizationStaff.length > 0
          ? (await convertUserReference(
              result.approvalOrganizationStaff[0].value
            )) || {}
          : {};

      // 管理場所マスタから取得
      const selectedOrganization = organizationList?.find(
        (item) =>
          item.organizationId ===
          (result.approvalOrganizationUnit.length > 0
            ? result.approvalOrganizationUnit[0].value
            : '')
      );
      const selectedOrganizationStructure = selectedOrganization
        ? (await convertOrganizationStructureReference(
            selectedOrganization?.organizationId ?? undefined
          )) || {}
        : {};

      await window.App.services.ui.worker.apiCall({
        actionName: 'approvalRequestSurveyBaseRequest',
        request: {
          surveyBaseRequestId:
            res.length > 0 ? res[0].surveyBaseRequestId! : '',
          approvalPlanStaff: res.length > 0 ? selectedStaff : {},
          approvalPlanOrganization:
            res.length > 0 ? selectedOrganizationStructure : {},
          comment: result.comment,
        },
      });

      success([sucessMessage]);
      setOpenApprovalRequestDialog(false);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      setLoading(false);
      throw err;
    } finally {
      // reload処理
      // 画面項目のセット
      setSurveyBaseRequest(surveyBaseRequestData.current);
      surveyBaseRequestToViews(surveyBaseRequestData.current);
    }
    // 一覧に戻る
    backToBaseViewPage();
  };

  // 依頼フォーム追加ボタン
  const handleAddRequestForm = () => {
    if (requestForms.length >= MAX_REQUEST_FORM) {
      error([
        GetMessageWithIntl(intl, {
          id: 'E0000120',
          value: { $1: MAX_REQUEST_FORM },
        }),
      ]);
      return;
    }
    setFormSettingType('request');
    setFormEditTarget(null);
    setOpenFormSettingDialog(true);
  };

  // 依頼フォーム追加処理
  const addRequestForm = (result: FormSettingDialogResult) => {
    setRequestForms([
      ...requestForms,
      {
        type: 'request',
        ...result,
        id: `TmpRequestId:${dayjs().unix()}`,
        tempForm: result,
        statusName: nameOptionToLocaleString(intl, notEnteredStatus),
      },
    ]);
  };

  // 依頼フォーム編集ボタン
  const handleEditRequestForm = (
    targetItem: SurveyRequestWorkFormWithValues
  ) => {
    setFormSettingType('request');
    setFormEditTarget(targetItem);
    setFormSettingInitialData({
      baseFormId: targetItem.tempForm?.baseFormId || '',
      baseDisplayName: targetItem.tempForm?.baseDisplayName || '',
      displayName: targetItem.tempForm?.displayName || '',
      notice: targetItem.tempForm?.notice || '',
      attribute1: targetItem.tempForm?.attribute1 || '',
      attribute2: targetItem.tempForm?.attribute2 || '',
      attribute3: targetItem.tempForm?.attribute3 || '',
      required: targetItem.tempForm?.required || false,
    });
    setOpenFormSettingDialog(true);
  };

  // 依頼フォーム編集確定時処理
  const editRequestForm = (result: FormSettingDialogResult) => {
    if (!isSurveyRequestWorkFormWithValues(formEditTarget)) {
      console.error('Failed: FormType mismatched');
      return;
    }
    const newData: SurveyRequestWorkFormWithValues = {
      ...formEditTarget,
      ...result,
      tempForm: result,
    };
    const targetIndex = requestForms.findIndex(
      (form) => form.id === newData.id
    );
    if (targetIndex === -1) {
      return;
    }
    const newList = [...requestForms];
    newList.splice(targetIndex, 1, newData);
    setRequestForms(newList);
    setFormEditTarget(null);
  };

  // 依頼フォーム入力
  const handleInputRequestForm = (
    targetItem: SurveyRequestWorkFormWithValues
  ) => {
    if (!targetItem.form) {
      // フォーム情報がない(=未保存)場合はエラーとする
      error([GetMessageWithIntl(intl, { id: 'E0000119' })]);
      return;
    }
    setFormEditTarget(targetItem);
    formInputDialogRef.current?.open(
      targetItem.form || {},
      targetItem.formSetting || {},
      targetItem.formSettingItems || [],
      targetItem.workForm || {},
      'B10',
      targetItem.formValues,
      true,
      false
    );
  };

  // 依頼フォーム入力確定
  const handleFormInputDecision = (
    formId: string,
    formValues?: mtechnavi.api.form.IFormValue[] | undefined
  ) => {
    if (!isSurveyRequestWorkFormWithValues(formEditTarget)) {
      console.error('Failed: FormType mismatched');
      return;
    }
    const newData: SurveyRequestWorkFormWithValues = {
      ...formEditTarget,
      formValues: formValues?.map((formValue) => {
        const oldValue = (formEditTarget.formValues ?? []).find(
          (val) => val.formSettingItemId === formValue.formSettingItemId
        );
        return {
          ...formValue,
          formId,
          formValueId: oldValue?.formValueId,
          typeName: FORM_TYPE_NAME,
          recordId: surveyBaseRequestData.current.surveyBaseRequestId,
          updatedAt: oldValue?.updatedAt,
        };
      }),
      statusName: nameOptionToLocaleString(intl, enteredStatus),
    };
    const targetIndex = requestForms.findIndex(
      (form) => form.id === newData.id
    );
    if (targetIndex === -1) {
      return;
    }
    const newList = [...requestForms];
    newList.splice(targetIndex, 1, newData);
    setRequestForms(newList);
    setFormEditTarget(null);
  };

  // 依頼フォーム削除
  const handleDeleteRequestForm = async (
    deleteItem: SurveyRequestWorkFormWithValues
  ) => {
    if (!(await confirmation(viewMessageDeleteForm))) {
      return;
    }
    const newList = [...requestForms];
    const targetIndex = requestForms.findIndex(
      (item) => item.id === deleteItem.id
    );
    if (targetIndex === -1) {
      return;
    }
    const target = newList.splice(targetIndex, 1);
    setRequestForms(newList);

    // 永続化済みデータの場合は削除対象リストに追加
    const targetData = target.at(0);
    if (targetData && targetData.workForm) {
      setDeleteRequestForms([...(deleteRequestForms || []), targetData]);
    }
  };

  // 依頼フォーム全削除
  const handleAllDeleteRequestForm = async () => {
    if (!(await confirmation(viewMessageDeleteAllForm))) {
      return;
    }
    // 永続化されたデータのみに絞り込んで削除対象リストに追加
    setDeleteRequestForms(
      requestForms.filter((resultForm) => !!resultForm.workForm)
    );
    setRequestForms([]);
  };

  // 回答フォーム追加
  const handleAddResultForm = () => {
    if (resultForms.length >= MAX_REQUEST_FORM) {
      error([
        GetMessageWithIntl(intl, {
          id: 'E0000120',
          value: { $1: MAX_REQUEST_FORM },
        }),
      ]);
      return;
    }
    setFormSettingType('result');
    setFormEditTarget(null);
    setOpenFormSettingDialog(true);
  };
  const addResultForm = async (result: FormSettingDialogResult) => {
    setResultForms([
      ...resultForms,
      {
        type: 'result',
        id: `TmpResultId:${dayjs().unix()}`,
        tempForm: result,
        ...result,
      },
    ]);
  };

  // 回答フォーム編集ボタン
  const handleEditResultForm = (targetItem: SurveyResultWorkFormWithValues) => {
    setFormSettingType('result');
    setFormEditTarget(targetItem);
    setFormSettingInitialData({
      baseFormId: targetItem.tempForm?.baseFormId || '',
      baseDisplayName: targetItem.tempForm?.baseDisplayName || '',
      displayName: targetItem.tempForm?.displayName || '',
      notice: targetItem.tempForm?.notice || '',
      attribute1: targetItem.tempForm?.attribute1 || '',
      attribute2: targetItem.tempForm?.attribute2 || '',
      attribute3: targetItem.tempForm?.attribute3 || '',
      required: targetItem.tempForm?.required || false,
    });
    setOpenFormSettingDialog(true);
  };

  // 回答フォーム編集確定時処理
  const editResultForm = (result: FormSettingDialogResult) => {
    if (!isSurveyResultWorkFormWithValues(formEditTarget)) {
      console.error('Failed: FormType mismatched');
      return;
    }
    const newData: SurveyResultWorkFormWithValues = {
      ...formEditTarget,
      tempForm: result,
      ...result,
    };
    const targetIndex = resultForms.findIndex((form) => form.id === newData.id);
    if (targetIndex === -1) {
      return;
    }
    const newList = [...resultForms];
    newList.splice(targetIndex, 1, newData);
    setResultForms(newList);
    setFormEditTarget(null);
  };

  // 回答フォーム削除
  const handleDeleteResultForm = async (
    deleteItem: SurveyResultWorkFormWithValues
  ) => {
    if (!(await confirmation(viewMessageDeleteForm))) {
      return;
    }
    const newList = [...resultForms];
    const targetIndex = resultForms.findIndex(
      (item) => item.id === deleteItem.id
    );
    if (targetIndex === -1) {
      return;
    }
    const target = newList.splice(targetIndex, 1);
    setResultForms(newList);

    // 永続化済みデータの場合は削除対象リストに追加
    const targetData = target.at(0);
    if (targetData && targetData.workForm) {
      setDeleteResultForms([...(deleteResultForms || []), targetData]);
    }
  };

  // 回答フォーム全削除
  const handleAllDeleteResultForm = async () => {
    if (!(await confirmation(viewMessageDeleteAllForm))) {
      return;
    }
    // 永続化されたデータのみに絞り込んで削除対象リストに追加
    setDeleteResultForms(
      resultForms.filter((resultForm) => !!resultForm.workForm)
    );
    setResultForms([]);
  };

  // フォーム設定ダイアログ確定時
  const handleFormSettingDecision = (result: FormSettingDialogResult) => {
    switch (formSettingType) {
      case 'request':
        if (formEditTarget) {
          editRequestForm(result);
        } else {
          addRequestForm(result);
        }
        break;
      case 'result':
        if (formEditTarget) {
          editResultForm(result);
        } else {
          addResultForm(result);
        }
        break;
    }
    setFormSettingType(null);
    setFormEditTarget(null);
    setFormSettingInitialData(null);
    setOpenFormSettingDialog(false);
  };

  // フォーム設定ダイアログキャンセル時
  const handleFormSettingCancel = () => {
    setFormSettingType(null);
    setFormEditTarget(null);
    setFormSettingInitialData(null);
    setOpenFormSettingDialog(false);
  };

  /**
   * 取引先別ファイルの削除
   */
  const handleDeleteBusinessUnitRequestAttachment = async (
    item: BusinessUnitAttachmentItem
  ) => {
    if (!(await confirmation(viewMessageDelete))) {
      return;
    }
    setBusinessUnitRequestAttachmentItems(
      businessUnitRequestAttachmentItems?.filter((v) => v.id !== item.id)
    );
  };

  /**
   * 取引先別ファイルの全削除
   */
  const handleAllDeleteBusinessUnitRequestAttachment = async () => {
    if (!(await confirmation(viewMessageDeleteAll))) {
      return;
    }
    setBusinessUnitRequestAttachmentItems([]);
  };

  /**
   * 取引先別ファイル一覧の行クリック
   */
  const handleRowClickBusinessUnitRequestAttachment = (
    item: BusinessUnitAttachmentItem
  ) => {
    autoDownloadFileOnlyName(
      item.attachment.filename ?? '',
      item.attachment.assetId ?? ''
    );
  };

  /**
   * 取引先別ファイル一覧の全件ダウンロード
   */
  const handleFullDownloadBusinessUnitRequestAttachment = () => {
    autoBulkDownload(
      (businessUnitRequestAttachmentItems ?? []).map(
        (item) => item.attachment.assetId ?? ''
      ),
      intl,
      VIEW_ID
    );
  };

  /**
   * 取引先指定ファイルの削除
   */
  const handleDeleteBusinessUnitList = async (
    item?: BusinessUnitListViewItem
  ) => {
    if (!(await confirmation(viewMessageBusinessUnitDelete))) {
      return;
    }
    deleteBusinessUnitItems('delete', item);
    setOpenConfirmDialog(false);
  };

  /**
   * 取引先指定ファイルの全削除
   */
  const handleAllDeleteBusinessUnitList = async () => {
    if (!(await confirmation(viewMessageBusinessUnitDeleteAll))) {
      return;
    }
    deleteBusinessUnitItems('deleteAll');
    setOpenConfirmDialog(false);
  };

  /**
   * 確認ダイアログ処理
   * Promise で結果を制御する。
   * 確定: true / キャンセル: false
   */
  const confirmation = (viewMessage: MessageProps): Promise<boolean> => {
    setOpenConfirmDialog(true);
    setConfirmMessage(viewMessage);
    return new Promise((resolve) => {
      confirmPromiseRef.current = resolve;
    });
  };

  /**
   * 確認ダイアログの確認処理
   */
  const handleConfirmed = () => {
    if (confirmPromiseRef.current) {
      confirmPromiseRef.current(true);
    }
    setOpenConfirmDialog(false);
  };

  /**
   * 確認ダイアログのキャンセル処理
   */
  const handleCancel = () => {
    if (confirmPromiseRef.current) {
      confirmPromiseRef.current(false);
    }
    setOpenConfirmDialog(false);
  };

  const isRenderRequestButton = approval.length > 0 && approval[0] === '1';

  const isRenderSendButton =
    approval.length < 1 || (approval.length > 0 && approval[0] !== '1');

  const boolDataFormat = (target: boolean) => {
    return target ? '○' : '-';
  };

  // 戻るページ
  const backToPreviewPage = async () => {
    if (!(await confirmation(viewMessageCancel))) {
      return;
    }
    const state: PageState = {
      ids: sourcePageInfo.beforeStateIds ?? [],
      sourceViewId: VIEW_ID,
      naviFilters: sourcePageInfo.naviFilters,
      beforeStateIds: sourcePageInfo.beforeStateIds,
      baseViewOption: sourcePageInfo.baseViewOption,
      confirmationViewOption: sourcePageInfo.confirmationViewOption,
    };
    navi(backPageUrl, { state });
  };

  // 一覧に戻る
  const backToBaseViewPage = async () => {
    const state: PageState = {
      ids: sourcePageInfo.beforeStateIds ?? [],
      sourceViewId: VIEW_ID,
      naviFilters: sourcePageInfo.naviFilters,
      beforeStateIds: sourcePageInfo.beforeStateIds,
      baseViewOption: sourcePageInfo.baseViewOption,
    };

    // 戻り先に遷移元(一覧)情報をセット
    //  遷移先不明の場合は依頼管理一覧に戻る
    const backPath =
      urlList.find(
        (v) => v.viewId === sourcePageInfo.baseViewOption?.sourceViewId
      )?.path ?? '/supplier-base/survey-request-list';

    if (BqListDelayTime) {
      // 更新内容が反映されるようウェイトをかける
      await new Promise((resolve) => setTimeout(resolve, BqListDelayTime));
    }

    navi(backPath, { state });
  };

  return (
    <>
      <Container viewId={VIEW_ID}>
        <div className="SurveyRequestSenderInput">
          <div className="header">
            <PageNavigation
              backpagePath={backPageUrl}
              pageInfo={{
                isVisibleMoveNavi: false,
              }}
              infoOption={{
                lastUpdateInfo: { isVisibleUpdateInfo: false },
                issuerInfo: { isVisibleIssuerInfo: false },
              }}
              handleBackPage={backToPreviewPage}
            />
          </div>
          <div
            className="scroll-main-contents-area"
            style={{
              maxHeight: mainContentHeight,
            }}
          >
            {/* 依頼 */}
            <Accordion title={GetMessage({ id: 'request', prefixId: VIEW_ID })}>
              <div className="input-line">
                <div className="item-group-100">
                  <div className="w-25">
                    <Filterbox
                      name="surveyRequestCatalog"
                      fullMethodName={FullMethodName_ListSurveyRequestCatalogs}
                      labelId="SURVEY_REQUEST_SENDER_INPUT.surveyRequestCatalog"
                      columns={preset.columns}
                      itemType={surveyRequestcatalogItemType}
                      value={surveyRequestCatalog}
                      validateOption={{ required: true }}
                      onChangeState={handleChangeSurveyRequestCatalog}
                      searchOption={{
                        targets: 'displayName',
                        isLatestData: true,
                        appendSearchColumn: 'category.displayNameLang',
                      }}
                      disabled={surveyBaseRequest?.surveyBaseRequestId != null}
                      workingBlur={workingBlurSurveyRequestCatalog}
                    />
                  </div>
                  <div className="w-30">
                    <Textbox
                      name="displayName"
                      className="field"
                      value={displayName}
                      type="text"
                      labelId="SURVEY_REQUEST_SENDER_INPUT.displayName"
                      columns={preset.columns}
                      onChangeState={setDisplayName}
                      validateOption={{ required: true }}
                    />
                  </div>
                  <div className="w-25">
                    <Textbox
                      name="surveyRequestAutoName"
                      className="field"
                      value={surveyRequestAutoName}
                      type="text"
                      labelId="SURVEY_REQUEST_SENDER_INPUT.surveyRequestAutoName"
                      columns={preset.columns}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
              <div className="input-line">
                <div className="item-group-100">
                  <div className="w-25">
                    <DateSuggest
                      labelId="SURVEY_REQUEST_SENDER_INPUT.replyDueDateDt"
                      name="replyDueDateDt"
                      columns={preset.columns}
                      value={replyDueDateDt}
                      onChangeState={setReplyDueDateDt}
                    ></DateSuggest>
                  </div>
                </div>
              </div>
              <div className="input-line">
                <div className="item-group-100">
                  <div className="w-40">
                    <Textbox
                      name="requester1"
                      className="field"
                      value={requester1}
                      type="text"
                      labelId="SURVEY_REQUEST_SENDER_INPUT.requester1"
                      columns={preset.columns}
                      onChangeState={setRequester1}
                    />
                  </div>
                  <div className="w-40">
                    <Textbox
                      name="requester2"
                      className="field"
                      value={requester2}
                      type="text"
                      labelId="SURVEY_REQUEST_SENDER_INPUT.requester2"
                      columns={preset.columns}
                      onChangeState={setRequester2}
                    />
                  </div>
                </div>
              </div>
              <div className="input-line">
                <div className="item-group-100">
                  <div className="w-100">
                    <Textarea
                      name="guidance"
                      className="w-100 mh-middle"
                      value={guidance}
                      labelId="SURVEY_REQUEST_SENDER_INPUT.guidance"
                      columns={preset.columns}
                      onChangeState={setGuidance}
                    />
                  </div>
                </div>
              </div>
              <div className="input-line mixed-input-line">
                <div className="item-group-100">
                  <div className="w-16">
                    <Textbox
                      name="approval"
                      className="field"
                      value={boolDataFormat(
                        approval.length > 0 && approval[0] === '1'
                      )}
                      type="text"
                      labelId="SURVEY_REQUEST_SENDER_INPUT.approval"
                      columns={preset.columns}
                      disabled={true}
                    />
                  </div>
                  <div className="w-16" ref={requireCompleteCondition}>
                    <DataFilterbox
                      data={completeConditions}
                      name="completeCondition"
                      labelId="SURVEY_REQUEST_SENDER_INPUT.completeCondition"
                      columns={preset.columns}
                      searchOption={{ targets: 'displayName' }}
                      validateOption={{ required: true }}
                      onChangeState={setCompleteCondition}
                      value={completeCondition}
                      workingBlur={workingBlurCompleteCondition}
                    />
                  </div>
                  <div className="w-16">
                    <Checkbox
                      name="partialReply"
                      className="group"
                      items={[
                        {
                          value: '1',
                          displayName: GetMessage({
                            prefixId: 'SURVEY_REQUEST_SENDER_INPUT',
                            id: 'partialReply',
                          }),
                        },
                      ]}
                      value={partialReply}
                      columns={preset.columns}
                      onChangeState={setPartialReply}
                    />
                  </div>
                  <div className="w-16">
                    <Checkbox
                      name="decline"
                      className="group"
                      items={[
                        {
                          value: '1',
                          displayName: GetMessage({
                            prefixId: 'SURVEY_REQUEST_SENDER_INPUT',
                            id: 'decline',
                          }),
                        },
                      ]}
                      value={decline}
                      columns={preset.columns}
                      onChangeState={setDecline}
                    />
                  </div>
                  <div className="w-16">
                    <Checkbox
                      name="requiredAttachment"
                      columns={['requiredAttachment']}
                      items={[
                        {
                          displayName: GetMessageWithIntl(intl, {
                            id: 'requiredAttachment',
                            viewId: VIEW_ID,
                          }),
                          value: '1',
                        },
                      ]}
                      value={isRequiredAttachment ? ['1'] : []}
                      onChangeState={(v) => {
                        setRequiredAttachment(!!v.length);
                      }}
                    />
                  </div>
                  <div className="w-16">
                    <CaptionButton
                      className="auto-reminder-setting-button"
                      name=""
                      caption="自動催促設定"
                      onClick={() => setOpenAutoReminderSettingDialog(true)}
                      buttonType="basic"
                    ></CaptionButton>
                  </div>
                </div>
              </div>
              <div className="input-line mixed-input-line">
                <div className="item-group-100">
                  <div className="w-40">
                    <DataFilterbox
                      data={organizationTosDataFilterItems}
                      name="managementOrganization"
                      labelId="SURVEY_REQUEST_SENDER_INPUT.managementOrganization"
                      columns={preset.columns}
                      searchOption={{ targets: 'displayName' }}
                      onChangeState={setManagementOrganization}
                      value={managementOrganization}
                    />
                  </div>
                  <div className="w-50">
                    <CaptionButton
                      buttonType="basic"
                      caption="所属アカウントを依頼担当に追加"
                      name=""
                      onClick={() =>
                        handleAddUserToUserReferenceSystemNotificationTos()
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="input-line mixed-input-line">
                <div className="item-group-100">
                  <div className="w-40">
                    <DataFilterbox
                      data={systemNotificationTosList}
                      name="userReferenceSystemNotificationTos"
                      labelId="SURVEY_REQUEST_SENDER_INPUT.userReferenceSystemNotificationTos"
                      columns={preset.columns}
                      searchOption={{ targets: 'displayName' }}
                      onChangeState={setSystemNotificationTos}
                      value={systemNotificationTos}
                      multiple={true}
                    />
                  </div>
                  <div className="w-25">
                    <Checkbox
                      name="systemNotificationIncludeMainContact"
                      columns={['systemNotificationIncludeMainContact']}
                      items={[
                        {
                          displayName: GetMessageWithIntl(intl, {
                            id: 'systemNotificationIncludeMainContact',
                            viewId: VIEW_ID,
                          }),
                          value: '1',
                        },
                      ]}
                      value={
                        isSystemNotificationIncludeMainContact ? ['1'] : []
                      }
                      onChangeState={(v) => {
                        setSystemNotificationIncludeMainContact(!!v.length);
                      }}
                    />
                  </div>
                  <div className="w-20">
                    <CaptionButton
                      buttonType="basic"
                      caption="通知メール送信対象設定"
                      name=""
                      onClick={() =>
                        setOpenNotificationSendTargetSettingDialog(true)
                      }
                    />
                  </div>
                </div>
              </div>
              {/* 添付ファイル(共通) */}
              <div className="input-line">
                <div className="item-group-100 file-area">
                  <div className="w-50">
                    <SimpleListView
                      data={commonRequestAttachmentItems}
                      viewOptions={{
                        previewRowCount: 5,
                        columns: [
                          {
                            header: { id: 'commonAttachedFile' },
                            propertyName: 'filename',
                          },
                        ],
                      }}
                      actionOptions={{
                        onDelete: async (item: AttachmentItems) => {
                          if (!(await confirmation(viewMessageDelete))) {
                            return;
                          }
                          setCommonRequestAttachmentItems(
                            commonRequestAttachmentItems?.filter(
                              (v) => v.assetId !== item.assetId
                            )
                          );
                        },
                        onDeleteAll: async () => {
                          if (!(await confirmation(viewMessageDeleteAll))) {
                            return;
                          }
                          setCommonRequestAttachmentItems([]);
                        },
                        onRowClick: (item: AttachmentItems) => {
                          autoDownloadFileOnlyName(
                            item.filename ?? '',
                            item.assetId ?? ''
                          );
                        },
                        onFullDownLoad: () => {
                          autoBulkDownload(
                            (commonRequestAttachmentItems ?? []).map(
                              (item) => item.assetId ?? ''
                            ),
                            intl,
                            VIEW_ID
                          );
                        },
                      }}
                    />
                  </div>
                  <div className="w-20">
                    <CaptionButton
                      buttonType="basic"
                      caption="ファイル追加"
                      name=""
                      onClick={() => {
                        if (
                          commonRequestAttachmentItems &&
                          commonRequestAttachmentItems.length >= 10
                        ) {
                          error([
                            GetMessageWithIntl(intl, {
                              id: 'E0000077',
                              value: { $1: 10 },
                            }),
                          ]);
                          return;
                        }
                        setFileUploadDialogType('common');
                        setOpenFileUploadDialog(true);
                      }}
                    />
                  </div>
                </div>
              </div>
            </Accordion>

            {/* 依頼詳細情報 */}
            {(isRequestFormUsable || isResultFormUsable) && (
              <Accordion
                title={GetMessageWithIntl(intl, {
                  id: 'requestDetail',
                  viewId: VIEW_ID,
                })}
              >
                {/* 依頼フォーム */}
                {isRequestFormUsable && (
                  <div className="area-container">
                    <div className="input-line">
                      <div className="item-group-100">
                        <div className="w-80">
                          <SimpleIconListView
                            data={requestForms}
                            viewOptions={{
                              previewRowCount: 10,
                              omitFooter: true,
                              keyColumn: 'id',
                              columns: requestFormListColumns,
                            }}
                            iconMenuOptions={{
                              iconMenu: [
                                {
                                  name: 'settings',
                                  displayName: '設定',
                                  func: handleEditRequestForm,
                                },
                                {
                                  name: 'edit',
                                  displayName: '入力',
                                  func: handleInputRequestForm,
                                },
                              ],
                            }}
                            actionOptions={{
                              onDelete: handleDeleteRequestForm,
                              onDeleteAll: handleAllDeleteRequestForm,
                            }}
                          />
                        </div>
                        <div className="w-20">
                          <CaptionButton
                            name="add-form"
                            buttonType="basic"
                            caption={GetMessageWithIntl(intl, {
                              id: 'addForm',
                              viewId: VIEW_ID,
                            })}
                            onClick={handleAddRequestForm}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* 回答フォーム */}
                {isResultFormUsable && (
                  <div className="area-container">
                    <div className="input-line">
                      <div className="item-group-100">
                        <div className="w-80">
                          <SimpleIconListView
                            data={resultForms}
                            viewOptions={{
                              previewRowCount: 10,
                              omitFooter: true,
                              keyColumn: 'id',
                              columns: resultFormListColumns,
                            }}
                            iconMenuOptions={{
                              iconMenu: [
                                {
                                  name: 'settings',
                                  displayName: '設定',
                                  func: handleEditResultForm,
                                },
                              ],
                            }}
                            actionOptions={{
                              onDelete: handleDeleteResultForm,
                              onDeleteAll: handleAllDeleteResultForm,
                            }}
                          />
                        </div>
                        <div className="w-20">
                          <CaptionButton
                            name="add-form"
                            buttonType="basic"
                            caption={GetMessageWithIntl(intl, {
                              id: 'addForm',
                              viewId: VIEW_ID,
                            })}
                            onClick={handleAddResultForm}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Accordion>
            )}

            {/* 取引先 */}
            <Accordion
              title={GetMessage({ id: 'businessUnit', prefixId: VIEW_ID })}
            >
              <div className="input-line">
                <div className="item-group-100">
                  <div className="w-100">
                    <Radio
                      name="radio"
                      className="group"
                      items={radioItems}
                      value={businessUnitRadio}
                      validateOption={{ required: true }}
                      onChangeState={setBusinessUnitRadio}
                      disabled={isBusinessUnitRadioDisabled}
                    ></Radio>
                  </div>
                </div>
              </div>
              {/* 取引先別ファイルあり */}
              {businessUnitRadio === radioItemValues[0] && (
                <>
                  <div className="input-line">
                    <div className="item-group-100">
                      <div className="w-33">
                        <CaptionButton
                          buttonType="basic"
                          caption="ファイル追加"
                          name=""
                          onClick={() => {
                            setFileUploadDialogType('businessUnit');
                            setOpenFileUploadDialog(true);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="input-line">
                    <div className="item-group-100 file-area businessUnit">
                      <div className="w-66">
                        <SimpleListView
                          data={businessUnitRequestAttachmentItems}
                          viewOptions={{
                            previewRowCount: 5,
                            keyColumn: 'id',
                            columns: businessUnitRequestAttachmentListColumns,
                          }}
                          actionOptions={{
                            onDelete: handleDeleteBusinessUnitRequestAttachment,
                            onDeleteAll:
                              handleAllDeleteBusinessUnitRequestAttachment,
                            onRowClick:
                              handleRowClickBusinessUnitRequestAttachment,
                            onFullDownLoad:
                              handleFullDownloadBusinessUnitRequestAttachment,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
              {/* 取引先指定ファイルなし */}
              {businessUnitRadio === radioItemValues[1] && (
                <>
                  <div className="input-line">
                    <div className="item-group-100">
                      <div className="w-33">
                        <CaptionButton
                          buttonType="basic"
                          caption="CSVで追加"
                          name=""
                          onClick={() => {
                            setOpenCsvUploadDialog(true);
                          }}
                        />
                      </div>
                      <div className="w-33">
                        <CaptionButton
                          buttonType="basic"
                          caption="ダイアログ選択"
                          name=""
                          onClick={() => {
                            setOpenBusinessUnitSearchDialog(true);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="input-line">
                    <div className="item-group-100 file-area businessUnit">
                      <div className="w-66">
                        <SimpleListView
                          data={businessUnitListViewItem}
                          viewOptions={{
                            previewRowCount: 5,
                            keyColumn: 'contactId',
                            columns: businessUnitListColumns,
                          }}
                          actionOptions={{
                            onDelete: handleDeleteBusinessUnitList,
                            onDeleteAll: handleAllDeleteBusinessUnitList,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </Accordion>
          </div>
        </div>
        <div className="footer" ref={footerRef}>
          <div className="footer-contents">
            <div className="input-line">
              <div className="item-group-100">
                <CaptionButton
                  name="saveBtn"
                  caption="一時保存"
                  className="btn"
                  onClick={handleSave}
                  buttonType="basic"
                />
                {/* {承認依頼ボタン} */}
                {isRenderRequestButton && (
                  <CaptionButton
                    name="approvalRequestBtn"
                    caption="承認依頼"
                    className="btn"
                    onClick={async () => {
                      // 入力エラーチェック
                      if (isInputErrorCheck()) {
                        return;
                      }
                      // 取引先管理マスタチェック
                      if (isBusinessUnitManagementErrorCheck()) {
                        return;
                      }
                      // ユーザー組織チェック
                      if (await isUserOrganizationErrorCheck()) {
                        return;
                      }
                      // 依頼フォーム入力チェック
                      if (isRequestFormInputErrorCheck()) {
                        return;
                      }
                      setOpenApprovalRequestDialog(true);
                    }}
                    buttonType="basic"
                  />
                )}
                {/* {送信ボタン} */}
                {isRenderSendButton && (
                  <CaptionButton
                    name="sendBtn"
                    caption="送信"
                    className="btn"
                    onClick={handleSend}
                    buttonType="basic"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <Toast />
      </Container>
      {/* 確認ダイアログ */}
      <ConfirmationDialog
        isOpen={isOpenConfirmDialog}
        viewMessage={confirmMessage}
        onDecision={handleConfirmed}
        onCancel={handleCancel}
      />
      {/* ファイル追加ダイアログ */}
      <FileUploadAppendDialog
        isOpen={isOpenFileUploadDialog}
        inputOption={{
          fineNames:
            fileUploadDialogType === 'common'
              ? commonRequestAttachmentItems.map((v) => {
                  return v.filename;
                })
              : businessUnitRequestAttachmentItems.map((v) => {
                  return v.attachment?.filename;
                }),
        }}
        messageOption={{
          headerLabelId: {
            id: 'file_add',
            prefixId: 'DIALOG_TITLE',
          },
        }}
        fileUploadOption={{
          multiple: true,
          validateOption: {
            maxFileSizeInMebis: 50,
            maxFileCount: fileUploadDialogType === 'common' ? 10 : undefined,
          },
        }}
        onCancel={() => {
          setOpenFileUploadDialog(false);
        }}
        onDecision={async (result) => {
          if (fileUploadDialogType === 'common') {
            if (
              commonRequestAttachmentItems &&
              commonRequestAttachmentItems.length + result.files.length > 10
            ) {
              error([
                GetMessageWithIntl(intl, {
                  id: 'E0000077',
                  value: { $1: 10 },
                }),
              ]);
              return;
            }

            const attchmentfiels = await handleCommonFIleUpload(
              // 重複データがある場合、上書きするため元のデータから削除する
              commonRequestAttachmentItems.filter(
                (v) => !result.files.some((i) => i.file.name === v.filename)
              ),
              result,
              'B05',
              intl
            );
            setCommonRequestAttachmentItems(attchmentfiels);
            setOpenFileUploadDialog(false);
          }
          if (fileUploadDialogType === 'businessUnit') {
            await handleBusinessUnitFIleUpload(result);
            setBusinessUnitRadioDisabled(true);
          }
        }}
        onChangeLoadingState={(isLoading) => {
          setLoading(isLoading);
        }}
      />
      {/* 自動催促設定ダイアログ */}
      <AutoReminderSettingDialog
        inputOption={{
          deadline: replyDueDateDt ?? undefined,
          displayModeType: 'decision',
          autoReminderSetting:
            (surveyBaseRequestData.current?.autoReminderSettings ?? []).length >
            0
              ? surveyBaseRequestData.current?.autoReminderSettings![0]
              : {},
        }}
        isOpen={isOpenAutoReminderSettingDialog}
        onDecision={(result) => {
          handleAutoReminderSetting(result);
          setOpenAutoReminderSettingDialog(false);
        }}
        onCancel={() => setOpenAutoReminderSettingDialog(false)}
      />
      {/* 取引先(連絡先)CSV追加ダイアログ */}
      <BusinessUnitCsvAdditionDialog
        isOpen={isOpenCsvUploadDialog}
        onDecision={(result) => {
          handleBusinessUnitCsvAddition(result);
        }}
        onCancel={() => setOpenCsvUploadDialog(false)}
      />
      {/* 取引先(連絡先)検索ダイアログ */}
      <SearchListViewDialog
        viewId={CONTACT_SEARCH_VIEW_ID}
        fullMethodName={FullMethodName_ListSharedContactContents}
        isOpen={isOpenBusinessUnitSearchDialog}
        headerLabelId={{
          prefixId: 'DIALOG_TITLE',
          id: 'sharedContactContentsSearch',
        }}
        onCloseChange={() => {
          clearContactSearchCheckBox();
          setOpenBusinessUnitSearchDialog(false);
        }}
        onSelectChange={(items) =>
          handleSearchSelect(
            items as unknown as mtechnavi.api.company.ISharedContactContent[]
          )
        }
        menuTarget="businessUnitContact.businessUnitContactId"
        onHandleFormatSchema={onHandleFormatSchema}
      />
      {/* 承認依頼ダイアログ */}
      <ApprovalRequestDialog
        isOpen={isOpenApprovalRequestDialog}
        inputOption={{
          organizations: organizationList ?? [],
          organizationRelations: organizationRelationList ?? [],
          FullMethodName_ListUserAttributes: FullMethodName_ListUserAttributes,
          requestReciptId:
            surveyBaseRequestData.current?.surveyBaseRequestId || '',
        }}
        onDecision={(result) => {
          handleApprovalRequest(result);
          setOpenApprovalRequestDialog(false);
        }}
        onCancel={() => {
          setOpenApprovalRequestDialog(false);
        }}
      />
      {/* フォーム設定ダイアログ */}
      <FormSettingDialog
        isOpen={isOpenFormSettingDialog}
        messageOption={{
          headerLabelId: { id: 'form-setting', viewId: VIEW_ID },
        }}
        inputOption={{
          purposes: [SURVEY_PURPOSES_CODE],
          dialogData: formSettingInitialData,
          isDisabledRequired: formSettingType === 'request',
        }}
        onDecision={handleFormSettingDecision}
        onCancel={handleFormSettingCancel}
      />
      {/* フォーム入力ダイアログ */}
      <BuildedInputFormDialog
        messageOption={{
          headerLabelId: { prefixId: VIEW_ID, id: 'requestFormInputDialog' },
          buttonLabelId: { id: 'decision' },
        }}
        ref={formInputDialogRef}
        onDecision={handleFormInputDecision}
      />
      {/* 通知メール送信対象設定ダイアログ */}
      <NotificationSendTargetSettingDialog
        isOpen={isOpenNotificationSendTargetSettingDialog}
        messageOption={{
          headerLabelId: {
            id: 'NOTIFICATION_SEND_TARGET_SETTING',
            prefixId: 'DIALOG_TITLE',
          },
        }}
        onDecision={() => {
          setOpenNotificationSendTargetSettingDialog(false);
        }}
        onCancel={() => {
          setOpenNotificationSendTargetSettingDialog(false);
        }}
      />
      {isLoading && <LoadingIcon />}
    </>
  );
}
