import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface UpdateForumThreadRequest {
  actionName: 'updateForumThread';
  request: mtechnavi.api.forum.IUpdateThreadRequest;
}

export const updateForumThread = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.forum.IUpdateThreadRequest
) => {
  return await worker.invokeListRequest<
    mtechnavi.api.forum.IUpdateThreadRequest,
    mtechnavi.api.forum.IThread
  >('mtechnavi.api.forum.ForumService/UpdateThread', req);
};
