import { mtechnavi } from '~/shared/libs/clientsdk';
import { Textarea } from '~/shared/components/ui';
import {
  CategorizedCheckList,
  CategorizedList,
} from '~/shared/components/ui/CategorizedCheckList';

interface SkillTabItemProps {
  skillTreeData?: CategorizedList;
  businessUnitSkills?: mtechnavi.api.company.IBusinessUnitSkill[];
}

export const SkillTabItem = ({
  skillTreeData,
  businessUnitSkills,
}: SkillTabItemProps) => {
  return (
    <>
      <div className="tab-info-body">
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              <CategorizedCheckList
                listData={skillTreeData ?? []}
                checkedIds={
                  businessUnitSkills?.at(0)?.businessUnitSkillItemIds || []
                }
                disabled={true}
                checkedOnly={true}
                inputOption={{
                  isVisibleOpenAll: true,
                  isVisibleCloseAll: true,
                }}
              ></CategorizedCheckList>
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              <Textarea
                name="otherSkill"
                className="w-75 mh-middle"
                value={businessUnitSkills?.at(0)?.otherSkill || ''}
                labelId="OCP_BUSINESS_UNIT_SKILL_INPUT.otherSkill"
                columns={['otherSkill']}
                disabled={true}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
