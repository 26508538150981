import { execRequestWorkTask } from './execRequestWorkTask';
import { execApprovalRequestWorkTask } from './execApprovalRequestWorkTask';
import { execCompleteWorkTask } from './execCompleteWorkTask';
import { execCompleteWtTicket } from './execCompleteWtTicket';
import { execUpdateWorkTaskRelation } from './execUpdateWorkTaskRelation';
import { execDeleteWorkTask } from './execDeleteWorkTask';
import { execDeleteWorkTaskCatalog } from './execDeleteWorkTaskCatalog';
import { execDeleteWorkTaskRelation } from './execDeleteWorkTaskRelation';
import { execDiscardWorkTask } from './execDiscardWorkTask';
import { execApprovalResultWorkTask } from './execApprovalResultWorkTask';
import { execSaveWorkTask } from './execSaveWorkTask';
import { execUpdateWorkTaskCatalog } from './execUpdateWorkTaskCatalog';
import { execUncompleteWorkTask } from './execUncompleteWorkTask';
import { execUncompleteWtTicket } from './execUncompleteWtTicket';
import { execChangeWtDueDate } from './execChangeWtDueDate';
import { execChangeWtManagementStaff } from './execChangeWtManagementStaff';
import { execUpdateWtRequestReference } from './execUpdateWtRequestReference';
import { execUpdateWtSystemNotificationSetting } from './execUpdateWtSystemNotificationSetting';
import { execChangeWtTicketDueDate } from './execChangeWtTicketDueDate';
import { execUpdateWtTicketRemarks } from './execUpdateWtTicketRemarks';
import { execChangeWtTicketScheduledDate } from './execChangeWtTicketScheduledDate';
import { execChangeWtWorkStaff } from './execChangeWtWorkStaff';

// actionName と function のマッピング
export const workTaskCallMap = {
  deleteWorkTask: execDeleteWorkTask,
  discardWorkTask: execDiscardWorkTask,
  execWorkTaskApproveResult: execApprovalResultWorkTask,
  saveWorkTaskCatalog: execUpdateWorkTaskCatalog,
  deleteWorkTaskCatalog: execDeleteWorkTaskCatalog,
  saveWorkTask: execSaveWorkTask,
  approvalRequestWorkTask: execApprovalRequestWorkTask,
  activateWorkTask: execRequestWorkTask,
  updateWtSystemNotificationSetting: execUpdateWtSystemNotificationSetting,
  updateWtDueDate: execChangeWtDueDate,
  updateWtTicketDueDate: execChangeWtTicketDueDate,
  updateWtTicketScheduledDate: execChangeWtTicketScheduledDate,
  updateWtManagementStaff: execChangeWtManagementStaff,
  updateWtWorkStaff: execChangeWtWorkStaff,
  updateWtRequestReference: execUpdateWtRequestReference,
  updateWtTicketRemarks: execUpdateWtTicketRemarks,
  createWorkTaskRelation: execUpdateWorkTaskRelation,
  deleteWorkTaskRelation: execDeleteWorkTaskRelation,
  completeWtTicket: execCompleteWtTicket,
  uncompleteWtTicket: execUncompleteWtTicket,
  completeWorkTask: execCompleteWorkTask,
  uncompleteWorkTask: execUncompleteWorkTask,
};
