import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';
import { FullMethodName_ReserveDeleteTenantManagement } from 'worker/fullMethodName/crud';

type ReserveDeleteTenantParam = {
  tenantManagement?: Pick<
    mtechnavi.api.admin.ITenantManagement,
    | 'tenantManagementId'
    | 'tenantId'
    | 'tenantCode'
    | 'tenantDisplayName'
    | 'createdAt'
    | 'updatedAt'
  > | null;
};

/** テナントの削除予約 */
export const reserveDeleteTenant = async (
  worker: AppSaveWorker,
  req: ReserveDeleteTenantParam
) => {
  return await worker.invokeListRequest<ReserveDeleteTenantParam, void>(
    FullMethodName_ReserveDeleteTenantManagement,
    req
  );
};
