import { useRef, useState } from 'react';
import { IconWithCaptionButton } from '../ui/Button';
import { NoticeArea } from './Notice';
import './NoticeCard.css';
import { InformationDialog } from '../ui/Dialog/InformationDialog';
import { mtechnavi } from '~/shared/libs/clientsdk';

export interface NoticeListTabProps {
  informationList: mtechnavi.api.admin.IInformation[];
}

export const NoticeListTab = (props: NoticeListTabProps) => {
  const { informationList } = props;
  const [readMore, setReadMore] = useState(false);
  const [isOpenDialog, setOpenDialog] = useState(false);
  const selectedItem = useRef<mtechnavi.api.admin.IInformation>({});

  return (
    <>
      <div className="ListTab">
        <ul>
          {informationList.map((information, index) => {
            return (
              <NoticeArea
                key={index}
                information={information}
                disabled={!readMore && index > 4 ? true : false}
                handleOpenDialog={() => {
                  selectedItem.current = information;
                  setOpenDialog(true);
                }}
              />
            );
          })}
        </ul>
        <div className="input-line">
          <div className="item-group-100 no-space">
            {informationList.length > 5 && (
              <IconWithCaptionButton
                name="readMore"
                iconType={readMore ? 'up' : 'down'}
                caption={readMore ? '閉じる' : 'もっと見る'}
                onClick={() => {
                  readMore ? setReadMore(false) : setReadMore(true);
                }}
              />
            )}
          </div>
        </div>
      </div>
      <InformationDialog
        isOpen={isOpenDialog}
        inputOption={{
          informationList: [selectedItem.current],
        }}
        messageOption={{
          headerLabelId: {
            id: 'InformationDialog',
            prefixId: 'DIALOG_TITLE',
          },
        }}
        onCancel={() => setOpenDialog(false)}
      />
    </>
  );
};
