import {
  PropsWithoutRef,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { CaptionButton } from '~/shared/components/ui/Button';
import {
  GetMessageWithIntl,
  ModalDialogComponent,
  ModalDialogComponentProps,
} from '~/shared/components';
import { useIntl } from 'react-intl';
import { BuildedForm, BuildedFormRef } from './parts/BuildedForm';
import './FormPreviewDialog.css';
import { BaseFormInformation } from './parts/BaseFormInformation';
import { IFormSetting, IFormSettingItem } from './';

export interface FormPreviewDialogRef {
  open: (
    formProperties: mtechnavi.api.form.IFormProperties,
    usable?: boolean,
    formSetting?: IFormSetting,
    formSettingItems?: IFormSettingItem[]
  ) => void;
}
interface FormPreviewDialogProps {
  onCancel?: () => void;
}
export const FormPreviewDialog = forwardRef(
  (props: PropsWithoutRef<FormPreviewDialogProps>, ref) => {
    const intl = useIntl();
    const buildedFormRef = useRef<BuildedFormRef>(null);
    const [isOpen, setOpen] = useState(false);
    const [formProperties, setFormProperties] =
      useState<mtechnavi.api.form.IFormProperties>();
    const [formUsable, setFormUsable] = useState<boolean>(false);
    const [formSetting, setFormSetting] = useState<IFormSetting>();
    const [formSettingItems, setFormSettingItems] = useState<
      IFormSettingItem[]
    >([]);

    const handleCancel = () => {
      if (props.onCancel) {
        props.onCancel();
      }
      setOpen(false);
    };

    useImperativeHandle(
      ref,
      (): FormPreviewDialogRef => ({
        open: (
          formProperties?: mtechnavi.api.form.IFormProperties,
          usable?: boolean,
          formSetting?: IFormSetting,
          formSettingItems?: IFormSettingItem[]
        ) => {
          setFormProperties(formProperties);
          setFormUsable(usable ?? false);
          setFormSetting(formSetting);
          setFormSettingItems(formSettingItems ?? []);
          setOpen(true);
        },
      })
    );

    const elements = (
      <div className="FormPreviewDialog">
        <div className="form-information">
          <BaseFormInformation
            formProperties={formProperties ?? {}}
            usable={formUsable}
          />
        </div>
        <div className="form-area">
          <BuildedForm
            formPages={formSetting?.formPages || []}
            formSettingItems={formSettingItems}
            ref={buildedFormRef}
            readonly={true}
          />
        </div>
      </div>
    );

    const footerElements = (
      <div className="FormPreviewDialog">
        <div className="button-area">
          <CaptionButton
            name="cancel"
            buttonType="cancel"
            className="button"
            caption={GetMessageWithIntl(intl, { id: 'cancel' })}
            onClick={handleCancel}
          />
        </div>
      </div>
    );

    const openModalProps: ModalDialogComponentProps = {
      cancel: handleCancel,
      send: () => {},
      modalIsOpen: isOpen,
      headerLabelId: {
        prefixId: 'FORM_BUILDER',
        id: 'FormPreviewDialog',
      },
      messageLabelId: {},
      elements,
      footerElements,
    };

    return (
      <div className="FormPreviewDialogBase">
        <ModalDialogComponent {...openModalProps} />
      </div>
    );
  }
);
