import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface ExecDiscardWorkTaskRequest {
  actionName: 'discardWorkTask';
  request: mtechnavi.api.worktask.IDiscardWorkTaskRequest;
}

// 指図案件破棄
export const execDiscardWorkTask = async (
  worker: AppSaveWorker,
  request: mtechnavi.api.worktask.IDiscardWorkTaskRequest
) => {
  const fullMethodName =
    'mtechnavi.api.worktask.WorkTaskService/DiscardWorkTask';

  await worker.invokeListRequest(fullMethodName, {
    workTaskId: request.workTaskId,
    comment: request.comment,
  });
};
