/**
 * エラーメッセージ関連
 */

import { IntlShape } from 'react-intl';
import { GetMessageWithIntl } from '~/shared/components';
import { Descriptor, RpcError, StatusCode } from '~/shared/libs/clientsdk';

export const isRpcError = (err: unknown): err is RpcError => {
  return err instanceof RpcError;
};

export const isError = (err: unknown): err is Error => {
  return err instanceof Error;
};

const toErrorMessage = (intl: IntlShape) => {
  return (desc: Descriptor): string => {
    const messageArgs = desc.messageArgs?.reduce((args, v, idx) => {
      args[`$${++idx}`] = v;
      return args;
    }, {} as Record<string, string>);
    return GetMessageWithIntl(intl, {
      id: desc.messageName,
      value: messageArgs,
    });
  };
};

/**
 * 例外発生時のエラーメッセージ取得共通関数
 * worker 経由の例外を RpcError として識別できないため注意
 */
export function getExceptionMessage(intl: IntlShape, err: unknown): string[] {
  const errorMessage = GetMessageWithIntl(intl, { id: 'E0000001' });
  const permissionErrorMessage = GetMessageWithIntl(intl, { id: 'E0000019' });
  if (!isRpcError(err)) {
    return [errorMessage];
  }
  // バリデーションエラーがあれば、優先的に表示
  if (err.validationError) {
    return err.validationError.descriptors.map(toErrorMessage(intl));
  }
  switch (err.code) {
    // 403(PERMISSION_DENIED)
    case StatusCode.PERMISSION_DENIED:
      return [permissionErrorMessage];
    default:
      return [errorMessage];
  }
}

/**
 * 例外発生時のエラーメッセージ取得共通関数
 * worker の例外発生時は RpcError の変換に失敗するためこちらを使用する
 */
export function getWorkerExceptionMessage(
  intl: IntlShape,
  err: unknown
): string[] {
  const errorMessage = GetMessageWithIntl(intl, { id: 'E0000001' });

  const permissionErrorMessage = GetMessageWithIntl(intl, { id: 'E0000019' });
  const abortedErrorMessage = GetMessageWithIntl(intl, { id: 'E0000102' });

  if (isRpcError(err)) {
    return getExceptionMessage(intl, err);
  }

  if (!isError(err)) {
    return [errorMessage];
  }

  try {
    const prefixValidationError = 'validationError=';
    const prefixValidationNullError = 'validationError=null';
    const val = err.message;
    const validationErrorIndex = val.indexOf(prefixValidationError);
    const validationNullErrorIndex = val.indexOf(prefixValidationNullError);

    if (validationNullErrorIndex < 0) {
      if (validationErrorIndex > 0) {
        const validationError = JSON.parse(
          val.substring(validationErrorIndex + prefixValidationError.length)
        );
        return validationError.descriptors.map(toErrorMessage(intl));
      }
    }

    // メッセージID取得
    const prefixErrorMessage = 'message=';
    const messageIndex = val.indexOf(prefixErrorMessage);
    const messageStartIndex = messageIndex + prefixErrorMessage.length;
    const messageEndIndex = messageStartIndex + 8;
    let message =
      messageIndex > 0
        ? val.substring(messageStartIndex, messageEndIndex)
        : 'E0000001';
    message = message.substring(0, 1) === 'E' ? message : 'E0000001';

    let valCode: StatusCode = StatusCode.OK;
    for (const [status, code] of Object.entries(StatusCode)) {
      if (!val.includes(`code=${status}`)) continue;
      valCode = code as StatusCode;
    }

    switch (valCode) {
      // 403(PERMISSION_DENIED)
      case StatusCode.PERMISSION_DENIED: {
        if (message !== 'E0000001') {
          return [GetMessageWithIntl(intl, { id: message })];
        }
        return [permissionErrorMessage];
      }
      case StatusCode.ABORTED: {
        if (message !== 'E0000001') {
          return [GetMessageWithIntl(intl, { id: message })];
        }
        return [abortedErrorMessage];
      }
      default:
        return [errorMessage];
    }
  } catch {
    return [errorMessage];
  }
}
