/**
 * プリセットおよびスキーマ関連
 */

import { mtechnavi } from '~/shared/libs/clientsdk';
import { Preset, Schema, getSchema } from '../../components/ui';
import { PresetItem, Property } from '../../services';
import { IntlShape } from 'react-intl';
import { ViewId } from './viewId';
import { error } from '~/shared/components';
import { getExceptionMessage } from '.';

// presetからpropertyを抜き出す
export function getPresetProperties(
  target: string,
  properties?: Property[]
): Property[] {
  // プロパティがなかったら、空を返す
  if (!properties) return [];
  return properties.filter((v) => v.name === target);
}

// 画面表示時に必要なpreset関係、schema情報を返却
export async function getPresetAndSchema(
  viewId: string,
  fullMethodNames: string[]
): Promise<{
  childrenPresetItem: PresetItem[];
  schemas: Schema[];
  preset: Preset;
  presetItem: PresetItem;
  viewIdPreset: mtechnavi.api.uicontroller.Preset;
}> {
  // 画面固有のpresetを取得
  const { presetItem, viewIdPreset } =
    await window.App.services.ui.getViewIdPreset(viewId);

  // 画面内の独立画面要素のpresetを取得
  const childrenPresetItem = presetItem.children ?? [];

  // schema情報及びpresetに必要情報を挿入したものを返却
  const [schemas, preset]: [Schema[], Preset] = (() => {
    const sch: Schema[] = fullMethodNames.map(getSchema);
    return [
      sch,
      {
        filter: {},
        propertyNames: presetItem.columns ?? [],
      },
    ];
  })();

  return { childrenPresetItem, schemas, preset, presetItem, viewIdPreset };
}

// preset取得
export async function getViewIdPreset(intl: IntlShape, viewId: ViewId) {
  try {
    return window.App.services.ui.getViewIdPreset(viewId);
  } catch (err) {
    error(getExceptionMessage(intl, err));
    return {
      presetItem: { name: '' },
      viewIdPreset: new mtechnavi.api.uicontroller.Preset(),
    };
  }
}
