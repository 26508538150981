import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface SaveBusinessUnitContactAttribute {
  actionName: 'saveBusinessUnitContactAttribute';
  request: mtechnavi.api.company.IBusinessUnitContactAttribute;
}

export const saveBusinessUnitContactAttribute = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.company.IBusinessUnitContactAttribute
) => {
  const createMethodName =
    'mtechnavi.api.company.BusinessUnitManagementService/CreateBusinessUnitContactAttribute';
  const updateMethodName =
    'mtechnavi.api.company.BusinessUnitManagementService/UpdateBusinessUnitContactAttribute';

  if (req.businessUnitContactAttributeId) {
    const result = await worker.invokeListRequest(updateMethodName, {
      businessUnitContactAttribute: req,
    });
    return result;
  } else {
    const result = await worker.invokeListRequest(createMethodName, {
      businessUnitContactAttribute: req,
    });
    return result;
  }
};
