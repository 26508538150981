import Long from 'long';
import { useState } from 'react';
import { CaptionButton } from '~/shared/components/ui/Button';
import { InformationDialog } from '~/shared/components/ui/Dialog/InformationDialog';

const informationList = [
  {
    subject: 'お知らせ１',
    category: { displayNameLang: { ja: '分類A' } },
    content: `お\n知\nら\nせ\nそ\nの\n１`,
    updatedAt: Long.fromNumber(new Date().getTime()).multiply(1000),
  },
  {
    subject: 'お知らせ２',
    category: { displayNameLang: { ja: '分類B' } },
    content: `お知らせ\nその２`,
    updatedAt: Long.fromNumber(new Date().getTime()).multiply(1000),
  },
  {
    subject: 'お知らせ３',
    category: { displayNameLang: { ja: '分類C' } },
    content: `お知らせ\nその３`,
    updatedAt: Long.fromNumber(new Date().getTime()).multiply(1000),
  },
];

export const UISampleInformationDialogs = () => {
  const [isOpen, setOpen] = useState(false);

  return (
    <div className="categories">
      <h3 id="dialog">お知らせ確認ダイアログ</h3>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="表示"
              name=""
              onClick={() => setOpen(true)}
            />
            <InformationDialog
              isOpen={isOpen}
              inputOption={{ informationList }}
              messageOption={{
                headerLabelId: {
                  id: 'InformationDialog',
                  prefixId: 'DIALOG_TITLE',
                },
              }}
              onCancel={() => setOpen(false)}
            />
          </div>
          <div className="w-66">
            <pre className="output"></pre>
          </div>
        </div>
      </div>
    </div>
  );
};
