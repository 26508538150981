import { useState } from 'react';
import { CaptionButton } from '~/shared/components/ui/Button/CaptionButton';
import { OrderOutputDialog } from '~/shared/components/ui/';

export const UISampleOrderOutputDialogs = () => {
  const [result, setResult] = useState('');
  const [isOutputShow, setOutputShow] = useState(false);

  return (
    <div className="categories">
      <h3 id="dialog">出力ダイアログ(受発注)</h3>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setResult('');
                setOutputShow(true);
              }}
            />
            <OrderOutputDialog
              isOpen={isOutputShow}
              messageOption={{
                headerLabelId: { id: 'orderOutput', prefixId: 'DIALOG_TITLE' },
                messageLabelId: {
                  id: 'description',
                  prefixId: 'OrderOutputDialog',
                },
              }}
              onDecision={(v) => {
                setResult(`出力しました。${v.outputVolume}`);
                setOutputShow(false);
              }}
              onCancel={() => {
                setResult('キャンセルしました');
                setOutputShow(false);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">{result}</pre>
          </div>
        </div>
      </div>
    </div>
  );
};
