import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface SaveDisasterSystemNotificationRequest {
  actionName: 'saveDisasterSystemNotification';
  request: mtechnavi.api.bcp.IDisasterSystemNotification;
}

// 災害調査削除
export const saveDisasterSystemNotification = async (
  worker: AppSaveWorker,
  request: mtechnavi.api.bcp.IDisasterSystemNotification
) => {
  const createFullMethodName =
    'mtechnavi.api.bcp.BcpService/CreateDisasterSystemNotification';
  const updateFullMethodName =
    'mtechnavi.api.bcp.BcpService/UpdateDisasterSystemNotification';

  if (request.disasterSystemNotificationId) {
    await worker.invokeListRequest(updateFullMethodName, {
      disasterSystemNotification: request,
    });
    return;
  }
  await worker.invokeListRequest(createFullMethodName, {
    disasterSystemNotification: request,
  });
};
