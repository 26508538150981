import { useState } from 'react';
import { PartialLoading } from '~/shared/components/ui';
import { CaptionButton } from '~/shared/components/ui/Button';

export const UISamplePagePartialLoading = () => {
  const [isShowDialog, setShowDialog] = useState(false);

  const handleLoading = () => {
    setShowDialog(true);
    setTimeout(() => setShowDialog(false), 3000);
  };

  return (
    <div className="categories">
      <h3 id="partial-loading">
        部分ローディング表示
        <br />
        <small></small>
      </h3>
      <p></p>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-100">
            <div className="partial-loading-area">
              <CaptionButton
                buttonType="basic"
                caption="ローディング表示"
                name=""
                onClick={handleLoading}
              />
              <PartialLoading isShow={isShowDialog} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
