import { useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { GetMessage, error, Radio, RadioItem } from '~/shared/components';
import { CaptionButton } from '~/shared/components/parts/Button/CaptionButton';
import {
  getExceptionMessage,
  nameOptionToLocaleString,
  ViewId,
} from '~/shared/utils';
import {
  DataFilterbox,
  DataFilterboxItem,
  Textbox,
  TextboxRef,
} from '~/shared/components/ui';
import { mtechnavi, sharelib } from '~/shared/libs/clientsdk';
import './ForumThreadForm.css';
import {
  FORUM_USABLE_VIEW_IDS,
  ForumThreadType,
  ForumTitleMaxLength,
  ForumTypeThreadTypeMap,
  ForumUsableViewId,
} from './utils/util';
import { useAuth } from '~/shared/contexts/AuthProvider';

export type ForumThreadFormInputs = Pick<
  mtechnavi.api.forum.IThread,
  'displayName' | 'threadType' | 'companyIds' | 'companys'
>;

interface ForumThreadFormProps {
  viewId: ViewId;
  destinationBusinessUnit?: mtechnavi.api.company.IBusinessUnitManagement[];
  onDecision?: (thread: ForumThreadFormInputs) => void;
  onCancel?: () => void;
}

/**
 * スレッド追加フォームコンポーネント
 */
export const ForumThreadForm = ({
  viewId,
  destinationBusinessUnit,
  onDecision,
  onCancel,
}: ForumThreadFormProps) => {
  const { tenantId: myCompanyId } = useAuth().tenant ?? {};
  const myCompanyName = useRef('');
  const [displayName, setDisplayName] = useState('');
  const [selectedThreadTypeCode, setSelectedThreadTypeCode] = useState('');
  const [selectedCompany, setSelectedCompany] = useState<DataFilterboxItem>();
  const [isWorking, setWorking] = useState(false);
  const titleRef = useRef<TextboxRef>(null);
  const intl = useIntl();

  const UsableForumType: string[] = useMemo(() => {
    const forumViewId = viewId as ForumUsableViewId;
    const types = FORUM_USABLE_VIEW_IDS.includes(forumViewId)
      ? ForumTypeThreadTypeMap[forumViewId]
      : [];
    setSelectedThreadTypeCode(types?.at(0) || '');
    return types ?? [];
  }, [viewId]);

  const threadTypeList = useMemo(
    () => window.App.services.ui.getNameOption('A0000038'),
    []
  );
  const usableThreadTypeItems: RadioItem[] = useMemo(() => {
    return [
      ...threadTypeList
        .filter((item) => UsableForumType.includes(item.systemName ?? ''))
        .map((item) => ({
          value: item.systemName || '',
          displayName:
            (item.displayNameLang && item.displayNameLang[intl.locale]) || '',
        })),
    ];
  }, [intl, UsableForumType, threadTypeList]);

  const isDMOnly =
    usableThreadTypeItems.length === 1 &&
    usableThreadTypeItems.at(0)?.value === 'B03';

  const destinationCompanyItems: DataFilterboxItem[] = useMemo(() => {
    const items = (destinationBusinessUnit ?? [])?.map(
      (businessUnit): DataFilterboxItem => ({
        displayName: businessUnit?.displayName ?? '',
        value: businessUnit.companyId ?? '',
      })
    );
    setSelectedCompany(items?.at(0));
    return items;
  }, [destinationBusinessUnit]);

  const handleCancelThread = () => {
    onCancel && onCancel();
  };

  const handleRegisterThread = async () => {
    if (!onDecision) {
      return;
    }

    const threadType = threadTypeList.find(
      (item) => item.systemName === selectedThreadTypeCode
    );
    if (!threadType) {
      return;
    }

    try {
      setWorking(true);
      let companyIds: string[] = [];
      let companys: sharelib.ICompanyReference[] = [];
      if (selectedThreadTypeCode === ForumThreadType.External) {
        companys = [
          {
            // 送信先企業
            companyId: selectedCompany?.value ?? '',
            displayName: selectedCompany?.displayName ?? '',
          },
          {
            // 自社
            companyId: myCompanyId ?? '',
            displayName: myCompanyName.current ?? '',
          },
        ];
        companyIds = companys.map((item) => item.companyId ?? '');
      }

      await onDecision({
        displayName,
        threadType,
        companyIds,
        companys,
      });
    } catch (err) {
      error(getExceptionMessage(intl, err));
      throw err;
    } finally {
      setWorking(false);
    }
  };

  useEffect(() => titleRef.current?.focus(), []);

  useEffect(() => {
    (async () => {
      const company = await window.App.services.ui.getMyCompany();
      myCompanyName.current = nameOptionToLocaleString(intl, company);
    })();
  }, [intl]);

  return (
    <div className="ForumThreadForm">
      <Textbox
        type="text"
        name="displayName"
        labelId="thread.displayName"
        value={displayName}
        onChangeState={(v) => {
          setDisplayName(v);
        }}
        validateOption={{ required: true, maxLength: ForumTitleMaxLength }}
        columns={['displayName']}
        properties={[]}
        disabled={isWorking}
        ref={titleRef}
      />
      {usableThreadTypeItems.length > 1 && (
        <Radio
          name="threadType"
          className="threadType"
          value={selectedThreadTypeCode}
          items={usableThreadTypeItems}
          validateOption={{ required: true }}
          onChangeState={(v) => {
            setSelectedThreadTypeCode(v);
          }}
          disabled={isWorking}
        />
      )}
      {selectedThreadTypeCode === ForumThreadType.External &&
        (!isDMOnly || destinationCompanyItems.length > 1) && (
          <DataFilterbox
            name="shareScope"
            labelId="thread.company"
            columns={['shareScope']}
            multiple={false}
            value={selectedCompany ? [selectedCompany] : []}
            data={destinationCompanyItems}
            validateOption={{ required: true }}
            onChangeState={(selections) => {
              setSelectedCompany(selections.at(0));
            }}
            disabled={isWorking}
          />
        )}
      <div className="actions">
        <CaptionButton
          buttonType="cancel"
          name="cancel"
          caption={GetMessage({ id: 'cancel' })}
          onClick={handleCancelThread}
          disabled={isWorking}
        />
        <CaptionButton
          buttonType="basic"
          name="send"
          caption={GetMessage({ id: 'decision' })}
          onClick={handleRegisterThread}
          disabled={isWorking || !displayName}
        />
      </div>
    </div>
  );
};
