import { createForumComment } from './createForumComment';
import { createForumThread } from './createForumThread';
import { setForumMarker } from './setForumMarker';
import { unsetAllForumMarker } from './unsetAllForumMarker';
import { unsetForumMarker } from './unsetForumMarker';
import { updateForumComment } from './updateForumComment';
import { updateForumThread } from './updateForumThread';

// actionName と function のマッピング
export const forumCallMap = {
  createForumThread: createForumThread,
  updateForumThread: updateForumThread,
  createForumComment: createForumComment,
  updateForumComment: updateForumComment,
  setForumMarker: setForumMarker,
  unsetForumMarker: unsetForumMarker,
  unsetAllForumMarker: unsetAllForumMarker,
};
