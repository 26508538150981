import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface CompleteDisasterSurveyRequest {
  actionName: 'completeDisasterSurvey';
  request: mtechnavi.api.bcp.ICompleteDisasterSurveyRequest;
}

// 災害調査削除
export const completeDisasterSurvey = async (
  worker: AppSaveWorker,
  request: mtechnavi.api.bcp.ICompleteDisasterSurveyRequest
) => {
  const fullMethodName = 'mtechnavi.api.bcp.BcpService/CompleteDisasterSurvey';

  await worker.invokeListRequest<
    mtechnavi.api.bcp.ICompleteDisasterSurveyRequest,
    void
  >(fullMethodName, {
    disasterSurveyId: request.disasterSurveyId,
  });
};
