import { GetMessage, GetMessageWithIntl } from '../../parts/Message/Message';
import { Filterbox, FilterboxItem } from '../Filterbox';
import {
  ModalDialogComponent,
  ModalDialogComponentProps,
} from '../ModalDialog/ModalDialog';
import { Textbox } from '../Textbox';
import { useEffect, useRef, useState } from 'react';
import { Textarea } from '../Textarea';
import { CaptionButton } from '../Button/CaptionButton';
import { useIntl } from 'react-intl';
import { includeInputValidateError } from '~/shared/utils';
import { FullMethodName_ListUserAttributes } from '~/worker';

export interface ChangeStaffDialogResult {
  changedStaff: FilterboxItem;
  comment: string;
}

export interface ChangeStaffDialogProps {
  targetUserIds: string[]; //FilterBoxのフィルターに使用するユーザーIDs
  isOpen: boolean;
  componentDisplayName: string;
  onDecision: (result: ChangeStaffDialogResult) => void;
  onCancel: () => void;
}

export const ChangeStaffDialog = (props: ChangeStaffDialogProps) => {
  const intl = useIntl();
  const staffItemType = {
    value: 'user.email',
    displayName: 'user.displayName',
  };
  const [changedStaff, setChangedStaff] = useState<FilterboxItem[]>([]);
  const [comment, setComment] = useState('');

  // 入力チェック用
  const requiredAreaChangedStaff = useRef(null);
  const [workingBlurChangedStaff, setWorkingBlurChangedStaff] =
    useState<Date>();

  useEffect(() => {
    if (props.isOpen) {
      setChangedStaff([]);
      setComment('');
    }
  }, [props.isOpen]);

  const isInputError = (): boolean => {
    setWorkingBlurChangedStaff(new Date());

    const inputValidationCheckList = [
      {
        value: changedStaff.length > 0 ? changedStaff[0].value : '',
        ref: requiredAreaChangedStaff,
      },
    ];

    const targetElm = document.querySelector('.change-staff-dialog');
    if (includeInputValidateError(targetElm, intl, inputValidationCheckList)) {
      return true;
    }
    return false;
  };

  const elements = (
    <div className="change-staff-dialog dialog-with-description">
      <div className="description-area">
        <p className="text-box">
          <span className="text">
            {GetMessage({
              prefixId: 'DIALOG_DESCRIPTION',
              id: 'change_staff',
            })}
          </span>
        </p>
      </div>
      <div className="detail-area">
        <div className="comment-area">
          <div className="input-line">
            <div className="item-group-100">
              <div className="w-100">
                <Textbox
                  name="organizationUnit"
                  type="text"
                  labelId="ChangeStaffDialog.organizationUnit"
                  columns={['organizationUnit']}
                  value={props.componentDisplayName}
                  disabled={true}
                />
              </div>
            </div>
          </div>
          <div className="input-line">
            <div className="item-group-100">
              <div className="w-50">
                <Filterbox
                  name="organizationStaffs"
                  fullMethodName={FullMethodName_ListUserAttributes}
                  labelId="ChangeStaffDialog.organizationStaff"
                  columns={['organizationStaffs']}
                  value={changedStaff ?? [{ value: '', displayName: '' }]}
                  itemType={staffItemType}
                  validateOption={{ required: true }}
                  searchOption={{
                    targets: 'displayName',
                    customQuery: {
                      'user.userId': { $in: props.targetUserIds ?? [] },
                    },
                  }}
                  onChangeState={setChangedStaff}
                  workingBlur={workingBlurChangedStaff}
                />
              </div>
            </div>
          </div>
          <div className="input-line">
            <div className="item-group-100">
              <div className="w-100">
                <Textarea
                  name="comment"
                  labelId="ChangeStaffDialog.comment"
                  value=""
                  columns={['comment']}
                  className="w-100 mh-middle"
                  onChangeState={setComment}
                />
              </div>
            </div>
          </div>
          <div className="button-area">
            <CaptionButton
              name="cancelBtn"
              buttonType="cancel"
              className="button"
              caption={GetMessageWithIntl(intl, { id: 'cancel' })}
              onClick={() => props.onCancel()}
            />
            <CaptionButton
              name="sendBtn"
              buttonType="basic"
              className="button"
              caption={GetMessageWithIntl(intl, {
                id: 'save',
              })}
              onClick={() => {
                if (isInputError()) {
                  return;
                }
                props.onDecision({
                  changedStaff: changedStaff[0],
                  comment,
                });
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );

  const openModalProps: ModalDialogComponentProps = {
    cancel: () => {
      props.onCancel();
    },
    send: () => {},
    modalIsOpen: props.isOpen,
    headerLabelId: {
      prefixId: 'DIALOG_TITLE',
      id: 'change_staff',
    },
    messageLabelId: {},
    elements,
  };

  return <ModalDialogComponent {...openModalProps} />;
};
