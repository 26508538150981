import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface GenerateFormRequest {
  actionName: 'generateForm';
  request: GenerateFormRequestParam;
}

export interface GenerateFormRequestParam {
  /**
   * @deprecated
   */
  baseFormId?: string;
  baseFormIds?: string[];
}
// FormDefinition の単純な作成
export const generateForm = (
  worker: AppSaveWorker,
  req: GenerateFormRequestParam
) => {
  const methodName = 'mtechnavi.api.form.FormService/GenerateForm';

  return worker.invokeListRequest<
    mtechnavi.api.form.IGenerateFormRequest,
    mtechnavi.api.form.IGenerateFormResponse[]
  >(methodName, req);
};
