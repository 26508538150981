import { mtechnavi, sharelib } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface SurveyManualReminderRequest {
  actionName: 'surveyManualReminder';
  request: SurveyManualReminderParam;
}
interface SurveyManualReminderParam {
  surveyRequestId: string;
  surveyReplyDueDateChangeResultId: string | null;
  businessUnitManagementId: string | null;
  contentType: sharelib.INameOption | null;
  content: string;
  sendTarget: mtechnavi.api.survey.ISendTargetSetting | null;
}

// 手動催促
export const surveyManualReminder = async (
  worker: AppSaveWorker,
  req: SurveyManualReminderParam
) => {
  const FullMethodName_CreateSurveyReminder =
    'mtechnavi.api.survey.SurveySenderService/CreateSurveyReminder';
  const FullMethodName_SharetoSurveyReminder =
    'mtechnavi.api.survey.SurveySenderService/SharetoSurveyReminder';

  const result = await worker.invokeListRequest<
    mtechnavi.api.survey.ICreateSurveyReminderRequest,
    mtechnavi.api.survey.SurveyReminder
  >(FullMethodName_CreateSurveyReminder, {
    surveyReminder: {
      surveyRequestId: req.surveyRequestId,
      contentType: req.contentType,
      content: req.content,
      manual: true,
      surveyReplyDueDateChangeId: req.surveyReplyDueDateChangeResultId,
      sendTarget: req.sendTarget,
    },
  });

  return await worker.invokeListRequest<
    mtechnavi.api.survey.ISharetoSurveyReminderRequest,
    mtechnavi.api.survey.SharetoSurveyReminderResponse
  >(FullMethodName_SharetoSurveyReminder, {
    surveyReminder: result[0],
  });
};
