import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface OpenEstimateRequestUnitRequest {
  actionName: 'openEstimateRequestUnit';
  request: mtechnavi.api.estimation.IOpenEstimateRequestUnitRequest;
}

export const openEstimateRequestUnit = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.estimation.IOpenEstimateRequestUnitRequest
) => {
  const openFullMethodName =
    'mtechnavi.api.estimation.EstimateReceiverService/OpenEstimateRequestUnit';
  const shareToFullMethodName =
    'mtechnavi.api.estimation.EstimationService/SharetoOpened';

  const result = await worker.invokeListRequest<
    mtechnavi.api.estimation.IOpenEstimateRequestUnitRequest,
    mtechnavi.api.estimation.IOpenEstimateRequestUnitResponse
  >(openFullMethodName, {
    estimateRequestUnitId: req.estimateRequestUnitId,
  });
  if (result && result.length > 0) {
    await worker.invokeListRequest<
      mtechnavi.api.estimation.ISharetoOpenedRequest,
      mtechnavi.api.estimation.ISharetoOpenedResponse
    >(shareToFullMethodName, {
      opened: result[0].opened,
    });
  }
  return result;
};
