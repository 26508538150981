import { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Textbox } from '~/shared/components/ui';
import {
  ModalDialogComponent,
  ModalDialogComponentProps,
} from '../ModalDialog/ModalDialog';
import {
  GetMessage,
  MessageProps,
} from '~/shared/components/parts/Message/Message';
import { CaptionButton } from '../Button/CaptionButton';
import './base.css';
import { includeInputValidateError } from '~/shared/utils';

export interface AdminAccountAddDialogMessageOption {
  headerLabelId: MessageProps;
}

export interface AdminAccountAddDialogInputOption {
  accountName?: string;
  email?: string;
}

export interface AdminAccountAddDialogProps {
  isOpen: boolean;
  messageOption: AdminAccountAddDialogMessageOption;
  inputOption: AdminAccountAddDialogInputOption;
  onDecision: (result: AdminAccountAddDialogInputOption) => void;
  onCancel: () => void;
}

export const AdminAccountAddDialog = (props: AdminAccountAddDialogProps) => {
  const intl = useIntl();
  const { isOpen, inputOption, messageOption } = props;
  const [accountName, setAccountName] = useState<string>(
    inputOption.accountName || ''
  );
  const [email, setEmail] = useState<string>(inputOption.email || '');
  const requiredAreaAccount = useRef(null);

  const isInputError = (): boolean => {
    const inputValidationCheckList = [
      {
        value: accountName || '',
        ref: requiredAreaAccount,
      },
      {
        value: email || '',
        ref: requiredAreaAccount,
      },
    ];

    const targetElm = document.querySelector('.admin-account-add-dialog');
    if (includeInputValidateError(targetElm, intl, inputValidationCheckList)) {
      return true;
    }
    return false;
  };

  const handleDecision = () => {
    if (isInputError()) {
      return;
    }
    props.onDecision({
      accountName,
      email,
    });
  };

  const handleCancel = () => {
    props.onCancel();
  };

  const view = (
    <div className="admin-account-add-dialog dialog-with-description">
      <div className="detail-area">
        <div className="comment-area">
          <div className="input-line">
            <div className="item-group-100">
              <div className="w-75">
                <Textbox
                  name="accountName"
                  labelId="adminAccountDialog.accountName"
                  columns={['accountName']}
                  type="text"
                  value={accountName}
                  onChangeState={setAccountName}
                  validateOption={{ required: true }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-75">
              <Textbox
                name="email"
                labelId="adminAccountDialog.email"
                value={email}
                columns={['email']}
                type="email"
                onChangeState={setEmail}
                validateOption={{ required: true }}
              />
            </div>
          </div>
        </div>
        <div className="button-area">
          <CaptionButton
            name="cancelBtn"
            buttonType="cancel"
            className="button"
            caption={GetMessage({ id: 'cancel' })}
            onClick={handleCancel}
          />
          <CaptionButton
            name="sendBtn"
            buttonType="basic"
            className="button"
            caption={GetMessage({
              id: 'save',
            })}
            onClick={handleDecision}
          />
        </div>
      </div>
    </div>
  );

  const openModalProps: ModalDialogComponentProps = {
    cancel: handleCancel,
    send: () => {},
    modalIsOpen: isOpen,
    headerLabelId: messageOption.headerLabelId,
    messageLabelId: {},
    elements: view,
  };

  return <ModalDialogComponent {...openModalProps} />;
};
