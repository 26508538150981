import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface SaveOcpStrength {
  actionName: 'saveOcpStrength';
  request: SaveOcpStrengthRequestParam;
}

interface SaveOcpStrengthRequestParam {
  businessUnitStrengths: mtechnavi.api.company.IBusinessUnitStrength[];
  companyId: string;
}

export const saveOcpStrength = async (
  worker: AppSaveWorker,
  req: SaveOcpStrengthRequestParam
) => {
  const ListMethodName =
    'mtechnavi.api.company.BusinessUnitManagementService/ListBusinessUnitStrengths';
  const createMethodName =
    'mtechnavi.api.company.BusinessUnitManagementService/CreateBusinessUnitStrength';
  const updateMethodName =
    'mtechnavi.api.company.BusinessUnitManagementService/UpdateBusinessUnitStrength';
  const deleteMethodName =
    'mtechnavi.api.company.BusinessUnitManagementService/DeleteBusinessUnitStrength';

  const StrengthList = (await worker.invokeListRequest(ListMethodName, {
    companyIds: [req.companyId],
  })) as mtechnavi.api.company.IBusinessUnitStrength[];

  const result = req.businessUnitStrengths.map((item) => {
    if (item.businessUnitStrengthId) {
      console.info('saveOcpStrength updateMethodName');
      return worker.invokeListRequest(updateMethodName, {
        businessUnitStrength: item,
      });
    } else {
      console.info('saveOcpStrength createMethodName');
      return worker.invokeListRequest(createMethodName, {
        businessUnitStrength: item,
      });
    }
  });
  await Promise.all(result);

  const deleteCheckList = req.businessUnitStrengths.map((e) => {
    return e.businessUnitStrengthId;
  });
  const deleteResult = StrengthList.map((strength) => {
    if (!deleteCheckList.includes(strength.businessUnitStrengthId)) {
      console.info('saveOcpStrength deleteMethodName');
      return worker.invokeListRequest(deleteMethodName, {
        businessUnitStrength: strength,
      });
    }
  });
  await Promise.all(deleteResult);
};
