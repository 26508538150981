import React from 'react';
import { useIntl } from 'react-intl';
import { mtechnavi, sharelib } from '~/shared/libs/clientsdk';
import { getDateFormat, nameOptionToLocaleString } from '~/shared/utils';
import './ForumLatestComment.css';
import { GetMessageWithIntl } from '../../parts/Message/Message';
import { ForumThreadType, ForumPrefixId as prefixId } from './utils/util';
import { useTooltip } from '../../parts/Tooltip';
import { useAuth } from '~/shared/contexts/AuthProvider';

export type LatestComment = mtechnavi.api.forum.IComment & {
  companyName?: string;
};

export interface ForumLatestCommentProps {
  comment?: LatestComment;
  replyComment?: mtechnavi.api.forum.IComment;
  threadType?: sharelib.INameOption | null;
}

/**
 * 最新コメント表示コンポーネント
 */
export const ForumLatestComment = ({
  comment,
  replyComment,
  threadType,
}: ForumLatestCommentProps) => {
  const intl = useIntl();
  const myCompanyId = useAuth().tenant?.tenantId ?? '';
  const { showTooltip, hideTooltip } = useTooltip();
  const isOurComment = comment?.contributor?.company?.companyId === myCompanyId;

  // 投稿者表記
  const metaDataText =
    threadType?.systemName === ForumThreadType.Internal
      ? comment?.contributor?.user?.displayName
      : nameOptionToLocaleString(intl, comment?.contributor?.company);

  return comment?.contributedAt ? (
    <div className={`ForumLatestComment ${isOurComment ? 'our' : 'their'}`}>
      <div className="comment-info">
        <div className="metadata">
          <span>
            {GetMessageWithIntl(intl, { prefixId, id: 'lastUpdated' })} :{' '}
          </span>
          <span
            onMouseEnter={(e) => {
              const tipText = `${comment?.contributor?.user?.displayName}\n${
                comment?.contributor?.user?.email ?? ''
              }`;
              showTooltip(tipText, e.currentTarget);
            }}
            onMouseLeave={hideTooltip}
          >
            {metaDataText ? `[${metaDataText}]` : ''}
          </span>
          <span>
            {getDateFormat(comment?.contributedAt ?? '', 'YYYY/MM/DD HH:mm')}
          </span>
        </div>
      </div>
      {replyComment && <div className="reply-detail">{replyComment.text}</div>}
      <p>{comment?.text}</p>
    </div>
  ) : (
    <></>
  );
};
