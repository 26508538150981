import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface DiscardEstimateManagementRequest {
  actionName: 'discardEstimateManagement';
  request: DiscardEstimateManagementParam;
}
interface DiscardEstimateManagementParam {
  estimateManagementIds: string[];
  comment?: string;
}

// 依頼破棄
export const discardEstimateManagement = async (
  worker: AppSaveWorker,
  req: DiscardEstimateManagementParam
) => {
  const FullMethodName_DiscardEstimateManagement =
    'mtechnavi.api.estimation.EstimateSenderService/DiscardEstimateManagement';
  const FullMethodName_SharetoDiscard =
    'mtechnavi.api.estimation.EstimationService/SharetoDiscard';

  const results = await Promise.all(
    req.estimateManagementIds.map((estimateManagementId) =>
      worker.invokeListRequest<
        mtechnavi.api.estimation.IDiscardEstimateManagementRequest,
        mtechnavi.api.estimation.DiscardEstimateManagementResponse
      >(FullMethodName_DiscardEstimateManagement, {
        estimateManagementId,
        comment: req.comment,
      })
    )
  );

  return await Promise.all(
    results.map((result) =>
      worker.invokeListRequest<
        mtechnavi.api.estimation.ISharetoDiscardRequest,
        mtechnavi.api.estimation.SharetoDiscardResponse
      >(FullMethodName_SharetoDiscard, {
        discard: result[0].discard,
      })
    )
  );
};
