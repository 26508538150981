import './PartialLoading.css';

interface PartialLoadingProps {
  isShow: boolean;
}
/** 部分ローディング表示コンポーネント */
export const PartialLoading = ({ isShow }: PartialLoadingProps) => {
  if (!isShow) {
    return <></>;
  }
  return (
    <div className="PartialLoading">
      <div className="spinner type1">
        <span>Loading...</span>
      </div>
    </div>
  );
};
