import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface ExecStartBusineess {
  actionName: 'execStartBusiness';
  request: mtechnavi.api.company.IStartBusinessRequest;
}

export const execStartBusiness = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.company.IStartBusinessRequest
) => {
  const execMethodName =
    'mtechnavi.api.company.BusinessUnitManagementService/StartBusiness';

  const result = await worker.invokeListRequest(execMethodName, {
    businessUnitManagementId: req.businessUnitManagementId,
  });
  return result;
};
