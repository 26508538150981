import { SimpleListView } from '~/shared/components/ui';
import { ViewId } from '~/shared/utils';
import { mtechnavi } from '~/shared/libs/clientsdk';

interface ViewStrengthType {
  recordId: string; // Key
  categoryName: string; // 分類
  content: string; // 内容
  connection: string; // 関連拠点（関連設備）
}
interface CompanyConfirmationStrengthProps {
  viewId: ViewId;
  strengths?: mtechnavi.api.analysis.IStrength[];
  branches?: mtechnavi.api.analysis.IBranch[];
  facilitiess?: mtechnavi.api.analysis.IFacilities[];
}
export const CompanyConfirmationStrength = ({
  viewId,
  strengths,
  branches,
  facilitiess,
}: CompanyConfirmationStrengthProps) => {
  const strengthItems = () => {
    if (!strengths) {
      return [];
    }
    const vals: ViewStrengthType[] = [];
    strengths.map((v) => {
      const branchName = branches?.length
        ? branches.find(
            (branch) => branch.recordId === v.linkProperties?.branchId
          )?.displayName ?? ''
        : '';
      const modelNumber = facilitiess?.length
        ? facilitiess.find(
            (facility) => facility.recordId === v.linkProperties?.facilitiesId
          )?.modelNumber ?? ''
        : '';
      vals.push({
        recordId: v.recordId ?? '',
        categoryName: v.category?.displayNameLangJa ?? '',
        content: v.content ?? '',
        connection:
          branchName || modelNumber
            ? [
                branchName ? '関連拠点：' + branchName : '',
                modelNumber ? '関連設備：' + modelNumber : '',
              ].join('\n')
            : '',
      });
    });
    return vals;
  };

  return (
    <>
      <div className="strength-list">
        <SimpleListView
          data={strengthItems()}
          viewOptions={{
            omitFooter: true,
            keyColumn: 'id',
            columns: [
              {
                header: { viewId: viewId, id: 'categoryName' },
                propertyName: 'categoryName',
                width: '12rem',
              },
              {
                header: { viewId: viewId, id: 'content' },
                propertyName: 'content',
                width: '1fr',
              },
              {
                header: { viewId: viewId, id: 'connection' },
                propertyName: 'connection',
                width: '1fr',
              },
            ],
          }}
          actionOptions={{}}
        />
      </div>
    </>
  );
};
