import { ViewId, joinAddress } from '~/shared/utils';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { useState } from 'react';
import {
  BusinessUnitBranchDialog,
  BusinessUnitBranchFormOption,
} from '~/shared/components/ui/Dialog/BusinessUnitBranchDialog';
import { SimpleIconListView } from '~/shared/components/ui/SimpleListView';

interface BranchTabItemProps {
  businessUnitBranchs?: mtechnavi.api.company.IBusinessUnitBranch[];
}

interface ViewBranchType {
  businessUnitBranchId: string; // Key
  displayName: string; // displayName
  address: string; // address joinAddress
  phoneNumber: string; // phoneNumber
  overview: string; // overview
}

const VIEW_ID: ViewId = 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION';

const branchColumns = [
  {
    header: {
      id: 'branch.displayName',
      prefixId: VIEW_ID,
    },
    propertyName: 'displayName',
    width: '16%',
  },
  {
    header: {
      id: 'branch.address',
      prefixId: VIEW_ID,
    },
    propertyName: 'address',
  },
  {
    header: {
      id: 'branch.phoneNumber',
      prefixId: VIEW_ID,
    },
    propertyName: 'phoneNumber',
    width: '11rem',
  },
  {
    header: {
      id: 'branch.overview',
      prefixId: VIEW_ID,
    },
    propertyName: 'overview',
    width: '22%',
  },
];

export const BranchTabItem = ({ businessUnitBranchs }: BranchTabItemProps) => {
  const [branchFormItems, setBranchFormItems] =
    useState<BusinessUnitBranchFormOption>();
  const [isBranchDialogOpen, setBranchDialogOpen] = useState(false);

  const getBranchItems = (): ViewBranchType[] => {
    if (!businessUnitBranchs) {
      return [];
    }
    const vals: ViewBranchType[] = [];
    businessUnitBranchs?.map((v) =>
      vals.push({
        businessUnitBranchId: v.businessUnitBranchId ?? '',
        displayName: v.displayName ?? '',
        address: joinAddress(v.address ?? {}),
        phoneNumber: v.phoneNumber ?? '',
        overview: v.overview ?? '',
      })
    );
    vals.sort((val1, val2) =>
      val1.displayName.localeCompare(val2.displayName, 'ja')
    );
    return vals;
  };

  // [実行処理]拠点情報確認ダイアログ
  const handleBranchDialog = (v: ViewBranchType) => {
    const formItem: mtechnavi.api.company.IBusinessUnitBranch =
      businessUnitBranchs?.find(
        (w) => w.businessUnitBranchId === v.businessUnitBranchId
      ) ?? {};
    if (formItem) {
      setBranchFormItems({
        displayName: formItem.displayName ?? '',
        address: formItem.address ?? {},
        phoneNumber: formItem.phoneNumber ?? '',
        faxNumber: formItem.faxNumber ?? '',
        overview: formItem.overview ?? '',
      });
    }
    setBranchDialogOpen(true);
  };

  return (
    <>
      <div className="tab-info-body">
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              <SimpleIconListView
                data={getBranchItems()}
                viewOptions={{
                  previewRowCount: 10,
                  keyColumn: 'businessUnitBranchId',
                  columns: branchColumns,
                  omitFooter: true,
                }}
                actionOptions={{}}
                iconMenuOptions={{
                  iconMenu: [
                    {
                      name: 'description',
                      displayName: '確認',
                      func: (v: ViewBranchType) => {
                        handleBranchDialog(v);
                      },
                    },
                  ],
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {/* 拠点情報確認ダイアログ */}
      <BusinessUnitBranchDialog
        isOpen={isBranchDialogOpen}
        inputMode={false}
        inputOption={branchFormItems}
        onCancel={() => {
          setBranchDialogOpen(false);
        }}
        onDesicion={() => {}}
      />
    </>
  );
};
