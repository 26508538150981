import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface SetForumMarkerRequest {
  actionName: 'setForumMarker';
  request: mtechnavi.api.forum.ISetMarkerRequest;
}

export const setForumMarker = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.forum.ISetMarkerRequest
) => {
  return await worker.invokeListRequest<
    mtechnavi.api.forum.ISetMarkerRequest,
    mtechnavi.api.forum.ISetMarkerResponse
  >('mtechnavi.api.forum.ForumService/SetMarker', {
    ...req,
    mode: 1,
    typeName: 'mtechnavi.api.forum.Comment',
  });
};
