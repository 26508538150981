import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface ExecChangeWtTicketDueDateRequest {
  actionName: 'updateWtTicketDueDate';
  request: mtechnavi.api.worktask.IWorkTask;
}

// 作業完了期日変更
export const execChangeWtTicketDueDate = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.worktask.IWorkTask
) => {
  const getFullMethodName =
    'mtechnavi.api.worktask.WorkTaskService/GetWorkTask';
  const updateFullMethodName =
    'mtechnavi.api.worktask.WorkTaskService/UpdateWorkTask';

  const items = await worker.invokeListRequest<
    mtechnavi.api.worktask.IGetWorkTaskRequest,
    mtechnavi.api.worktask.WorkTask
  >(getFullMethodName, {
    workTaskId: req.workTaskId,
  });

  if (items && items.length > 0) {
    const item = items[0];
    return await worker.invokeListRequest<
      mtechnavi.api.worktask.IUpdateWorkTaskRequest,
      mtechnavi.api.worktask.WorkTask
    >(updateFullMethodName, {
      workTask: {
        ...item,
        asap: false,
        asapComment: '',
        worktaskTicketDueDateDt: req.worktaskTicketDueDateDt,
        worktaskTicketDueDateChangeProcess:
          req.worktaskTicketDueDateChangeProcess,
      },
    });
  }
};
