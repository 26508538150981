/**
 * カテゴライズできないユーティリティ関数
 */
import { mtechnavi, sharelib } from '../libs/clientsdk';
import { ViewId } from './page';
import { ConnectingType } from './commonType';
import { FullMethodName_ListOrganizationRelations } from '~/worker';

// 空オブジェクト判定 APIのデータを表示データに変換する際に利用
export function checkEmptyObject(targetObject: {} | null | undefined) {
  if (targetObject === null || targetObject === undefined) {
    return true;
  }

  const items = Object.keys(targetObject);
  if (items.length === 0) {
    return true;
  } else if (items.length === 1 && items.includes('displayNameLang')) {
    // mongoDBを通すと空オブジェクトでもdisplayNameLangが設定されるためその対応
    const tmp = targetObject['displayNameLang' as keyof typeof targetObject];
    if (typeof tmp === 'object' && Object.keys(tmp).length === 0) {
      return true;
    }
  }

  return false;
}

// 配列から指定されたものを削除した処理
export function getDeletedItemList<T>(
  deleteTargetIds: string[] | undefined,
  list: T[],
  deleteTargetName: keyof T
) {
  if (!deleteTargetIds) {
    return list;
  }

  let tmp = list;
  deleteTargetIds?.map((id) => {
    tmp = tmp.filter(
      // @ts-ignore
      (item) => item[deleteTargetName] !== id
    );
  });
  return tmp;
}

// ランダムな値を生成 現状ではdataviewで各要素にランダム値を設定する場合に使用
export function getRandomStringId() {
  return Math.random().toString(32).substring(2);
}

/**
 * 指図案件の紐付け処理
 * @param ids: 子案件ID
 * @param linkedId: 紐付け指図案件ID
 * @param viewId: 紐付け指図案件の画面ID
 * @param connectingType: 子案件の紐付けタイプ
 */
export const createWorkTaskRelation = async (
  ids: string[],
  linkedId: string[],
  viewId: ViewId | null,
  connectingType: ConnectingType
) => {
  if (!linkedId || !linkedId[0]) {
    return;
  }
  if (viewId !== 'WT_WORKTASK_CONFIRMATION') {
    return;
  }
  const workTaskRelation: mtechnavi.api.worktask.IWorkTaskRelation[] = [];
  ids.map((id) => {
    let typeName = '';
    let systemCategory: sharelib.INameOption[] = [];
    switch (connectingType) {
      case 'worktask': {
        typeName = 'mtechnavi.api.worktask.WorkTask';
        systemCategory = window.App.services.ui.getNameOptionWithSystemName(
          'A2000002',
          'B01'
        );
        break;
      }
      case 'survey': {
        typeName = 'mtechnavi.api.survey.SurveyBaseRequest';
        systemCategory = window.App.services.ui.getNameOptionWithSystemName(
          'A2000002',
          'B02'
        );
        break;
      }
      case 'estimate': {
        typeName = 'mtechnavi.api.estimation.EstimateRequest';
        systemCategory = window.App.services.ui.getNameOptionWithSystemName(
          'A2000002',
          'B03'
        );
        break;
      }
    }
    workTaskRelation.push({
      linkedWorkTaskId: linkedId[0],
      typeName,
      systemCategory: systemCategory.length > 0 ? systemCategory[0] : {},
      recordId: id,
    });
  });
  return await window.App.services.ui.worker.apiCall({
    actionName: 'createWorkTaskRelation',
    request: workTaskRelation ?? [],
  });
};

/**
 * 組織に所属しているユーザーIDを取得する
 * @param organizationId
 * @returns
 */
export const getUserIdsByOrganizationId = async (
  organizationId: string | null | undefined
): Promise<string[]> => {
  if (!organizationId) {
    return [];
  }
  const organizationRelations = (
    (await window.App.services.ui.worker.filter({
      action: 'reload',
      fullMethodName: FullMethodName_ListOrganizationRelations,
      filter: {
        organizationId: { $eq: organizationId },
      },
      sort: [],
    })) as mtechnavi.api.company.ListOrganizationRelationsResponse
  ).items;
  const targetUserIds: string[] = [];
  organizationRelations.map((item) => {
    if (item.userId) {
      targetUserIds.push(item.userId);
    }
  });
  return targetUserIds;
};
