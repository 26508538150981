import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface SaveBusinessUnitRankReception {
  actionName: 'saveBusinessUnitRankReception';
  request: mtechnavi.api.company.IBusinessUnitRankReception;
}

export const saveBusinessUnitRankReception = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.company.IBusinessUnitRankReception
) => {
  const createMethodName =
    'mtechnavi.api.company.BusinessUnitManagementService/CreateBusinessUnitRankReception';
  const updateMethodName =
    'mtechnavi.api.company.BusinessUnitManagementService/UpdateBusinessUnitRankReception';

  if (req.businessUnitRankReceptionId) {
    const result = await worker.invokeListRequest<
      mtechnavi.api.company.IUpdateBusinessUnitRankReceptionRequest,
      void
    >(updateMethodName, {
      businessUnitRankReception: req,
    });
    return result;
  } else {
    const result = await worker.invokeListRequest<
      mtechnavi.api.company.ICreateBusinessUnitRankReceptionRequest,
      void
    >(createMethodName, {
      businessUnitRankReception: req,
    });
    return result;
  }
};
