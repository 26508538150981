import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface UpdateRemarksInEstimateResultRequest {
  actionName: 'updateRemarksInEstimateResult';
  request: mtechnavi.api.estimation.IEstimateResult;
}

export const updateRemarksInEstimateResult = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.estimation.IEstimateResult
) => {
  const updateFullMethodName =
    'mtechnavi.api.estimation.EstimateReceiverService/UpdateRemarksInEstimateResult';

  return worker.invokeListRequest<
    mtechnavi.api.estimation.IUpdateRemarksInEstimateResultRequest,
    void
  >(updateFullMethodName, {
    estimateResultId: req.estimateResultId,
    remarks: req.remarks,
    updatedAt: req.updatedAt,
  });
};
