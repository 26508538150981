import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface SendTenantInvitationRequest {
  actionName: 'sendTenantInvitation';
  request: SendTenantInvitationRequestParam;
}
interface SendTenantInvitationRequestParam {
  businessUnitManagementIds: string[];
  individualMessage: string;
  contactInformationMessage: string;
}

// 招待メール送信
export const sendTenantInvitationRequest = async (
  worker: AppSaveWorker,
  request: SendTenantInvitationRequestParam
) => {
  const fullMethodName =
    'mtechnavi.api.company.BusinessUnitManagementService/SendEmailTenantInvitation';
  const result = request.businessUnitManagementIds.map(
    (businessUnitManagementId) => {
      return worker.invokeListRequest<
        mtechnavi.api.company.ISendEmailTenantInvitationRequest,
        mtechnavi.api.company.SendEmailTenantInvitationResponse
      >(fullMethodName, {
        businessUnitManagementId: businessUnitManagementId,
        individualMessage: request.individualMessage,
        contactInformationMessage: request.contactInformationMessage,
      });
    }
  );
  return await Promise.all(result);
};
