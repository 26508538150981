import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';
import { FullMethodName_CreateTenantManagementLicense } from 'worker/fullMethodName/crud';

export const createLicense = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.admin.ICreateLicenseRequest
) => {
  return await worker.invokeListRequest<
    mtechnavi.api.admin.ICreateLicenseRequest,
    mtechnavi.api.license.ILicense
  >(FullMethodName_CreateTenantManagementLicense, req);
};
