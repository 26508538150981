import { exportOrders } from './exportOrders';
import { saveEstimateSelectionAndApprovalRequest } from './saveEstimateSelectionAndApprovalRequest';
import { saveEstimateManagementWithRelatedItems } from './saveEstimateManagementWithRelatedItems';
import { updateEstimateManagement } from './updateEstimateManagement';
import { approvalRequestEstimateManagement } from './approvalRequestEstimateManagement';
import { completeEstimateManagement } from './completeEstimateManagement';
import { deleteEstimateManagementWithRelatedItems } from './deleteEstimateManagementWithRelatedItems';
import { execEstimateManagementApproveResult } from './execEstimateManagementApproveResultRequest';
import { discardEstimateManagement } from './discardEstimateManagement';
import { sharetoEstimateRequestWithRelatedItems } from './sharetoEstimateRequestWithRelatedItems';
import { updateEstimateManagementSystemNotificationSetting } from './updateEstimateManagementSystemNotificationSetting';
import { execEstimateResultChangeRequest } from './execEstimateResultChangeRequest';
import { approveAndShareToEstimateSelection } from './approveAndShareToEstimateSelection';
import { rejectEstimateSelection } from './rejectEstimateSelection';
import { deleteEstimateRequestPlan } from './deleteEstimateRequestPlan';
import { completeEstimateRequestPlan } from './completeEstimateRequestPlan';
import { uncompleteEstimateRequestPlan } from './uncompleteEstimateRequestPlan';
import { completeEstimateResult } from './completeEstimateResult';
import { updateRemarksInEstimateResult } from './updateRemarksInEstimateResult';
import { updateEstimateResult } from './updateEstimateResult';
import { updateEstimateResultDetail } from './updateEstimateResultDetail';
import { sharetoEstimateResultWithRelatedItems } from './sharetoEstimateResultWithRelatedItems';
import { updateEstimateResultSystemNotificationSetting } from './updateEstimateResultSystemNotificationSetting';
import { openEstimateRequestUnit } from './openEstimateRequestUnit';

// actionName と function のマッピング
export const estimateCallMap = {
  saveEstimateManagementWithRelatedItems:
    saveEstimateManagementWithRelatedItems,
  updateEstimateManagement: updateEstimateManagement,
  approvalRequestEstimateManagement: approvalRequestEstimateManagement,
  exportOrders: exportOrders,
  saveEstimateSelectionAndApprovalRequest:
    saveEstimateSelectionAndApprovalRequest,
  completeEstimateManagement: completeEstimateManagement,
  deleteEstimateManagementWithRelatedItems:
    deleteEstimateManagementWithRelatedItems,
  execEstimateManagementApproveResult: execEstimateManagementApproveResult,
  discardEstimateManagement: discardEstimateManagement,
  sharetoEstimateRequestWithRelatedItems:
    sharetoEstimateRequestWithRelatedItems,
  updateEstimateManagementSystemNotificationSetting:
    updateEstimateManagementSystemNotificationSetting,
  execEstimateResultChangeRequest: execEstimateResultChangeRequest,
  approveAndShareToEstimateSelection: approveAndShareToEstimateSelection,
  rejectEstimateSelection: rejectEstimateSelection,
  deleteEstimateRequestPlan: deleteEstimateRequestPlan,
  completeEstimateRequestPlan: completeEstimateRequestPlan,
  uncompleteEstimateRequestPlan: uncompleteEstimateRequestPlan,
  completeEstimateResult: completeEstimateResult,
  updateEstimateResult: updateEstimateResult,
  updateEstimateResultDetail: updateEstimateResultDetail,
  updateRemarksInEstimateResult: updateRemarksInEstimateResult,
  sharetoEstimateResultWithRelatedItems: sharetoEstimateResultWithRelatedItems,
  updateEstimateResultSystemNotificationSetting:
    updateEstimateResultSystemNotificationSetting,
  openEstimateRequestUnit: openEstimateRequestUnit,
};
