import { AppSaveWorker } from '..';

export interface ExecEstimateManagementApproveResultRequest {
  actionName: 'execEstimateManagementApproveResult';
  request: EstimateManagementApproveResultRequestParam;
}
interface EstimateManagementApproveResultRequestParam {
  estimateManagementId: string;
  comment: string;
  decline: boolean;
}

export const execEstimateManagementApproveResult = async (
  worker: AppSaveWorker,
  req: EstimateManagementApproveResultRequestParam
) => {
  const approveRequestFullMethodName =
    'mtechnavi.api.estimation.EstimateSenderService/ApproveEstimateManagement';
  const rejectRequestFullMethodName =
    'mtechnavi.api.estimation.EstimateSenderService/RejectEstimateManagement';
  if (req.decline) {
    await worker.invokeListRequest(rejectRequestFullMethodName, {
      estimateManagementId: req.estimateManagementId,
      comment: req.comment,
    });
  } else {
    await worker.invokeListRequest(approveRequestFullMethodName, {
      estimateManagementId: req.estimateManagementId,
      comment: req.comment,
    });
  }
};
