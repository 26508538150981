import {
  Container,
  GetMessageWithIntl,
  MessageProps,
  Toast,
  error,
} from '~/shared/components';
import {
  GroupTreeEditor,
  GroupTreeItem,
} from '~/shared/components/ui/GroupTreeEditor';
import { generateMockData } from './mock';
import { useAuth } from '~/shared/contexts/AuthProvider';
import { useEffect, useRef, useState } from 'react';
import { convertGroupTree } from '~/tenant/core/pages/OcpOrganizationEdit/util';
import {
  OrganizationDetailDialog,
  OrganizationDetailDialogMode,
} from '~/shared/components/ui/Dialog/OrganizationDetailDialog';
import { OrganizationMemberDialog } from '~/shared/components/ui/Dialog/OrganizationMemberDialog';
import { useLoading } from '~/shared/contexts/LoadingProvider';
import { Checkbox, ConfirmationDialog } from '~/shared/components/ui';
import { useIntl } from 'react-intl';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { OrganizationTreeSearchDialog } from '~/shared/components/ui/Dialog/OrganizationTreeSearchDialog';
import { CaptionButton } from '~/shared/components/ui/Button';

export const OrganizationTreePage = () => {
  const auth = useAuth();
  const intl = useIntl();
  const { showLoading, hideLoading } = useLoading();
  const [originData, setOriginData] = useState<
    mtechnavi.api.company.IOrganization[]
  >([]);
  const [mockData, setMockData] = useState<
    GroupTreeItem<mtechnavi.api.company.IOrganization>[]
  >([]);
  const [isNiterra, setNiterra] = useState(false);

  const [detailMode, setDetailMode] =
    useState<OrganizationDetailDialogMode>('add');
  const [targetData, setTargetData] =
    useState<mtechnavi.api.company.IOrganization>();
  const [targetParents, setTargetParents] =
    useState<mtechnavi.api.company.IOrganization[]>();
  const [isOpenOrgDetailDialog, setOpenOrgDetailDialog] = useState(false);
  const [isOpenOrgMemberDialog, setOpenOrgMemberDialog] = useState(false);
  const [memberUserList, setMemberUserList] =
    useState<mtechnavi.api.company.IOrganizationRelation[]>();
  const [isOpenOrgSearchDialog, setOpenOrgSearchDialog] = useState(false);

  // 確認ダイアログ
  const [isOpenConfirmDialog, setOpenConfirmDialog] = useState(false);
  // 確認ダイアログメッセージ
  const [confirmMessage, setConfirmMessage] = useState<MessageProps>({});
  const confirmPromiseRef =
    useRef<(value: boolean | PromiseLike<boolean>) => void>();

  // 組織検索ダイアログ用チェック状態保持
  const [checkedIds, setCheckedIds] = useState<string[]>([]);

  const companyName = auth.tenant?.displayName || '';

  useEffect(() => {
    showLoading();
    (async () => {
      await new Promise((resolve) => setTimeout(resolve, 300));
      const flatData = generateMockData();
      setTreeData(flatData);

      hideLoading();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const traceParent = (
    target: mtechnavi.api.company.IOrganization | undefined
  ): mtechnavi.api.company.IOrganization[] => {
    if (!target || !target.parentOrganization?.organizationId) {
      return [];
    }
    const parent = originData.find(
      (item) =>
        item.organizationId === target.parentOrganization?.organizationId
    );
    if (!parent) {
      return [{ displayName: companyName }];
    }
    const parents = traceParent(parent);
    return [...parents, parent];
  };

  const isDuplicationCode = (orgCode: string): boolean => {
    return originData.some((item) => item.organizationId === orgCode);
  };

  const setTreeData = (flatData: mtechnavi.api.company.IOrganization[]) => {
    setOriginData(flatData);
    const treeData = convertGroupTree(flatData);
    setMockData(treeData);
  };

  // 追加
  const handleAddChild = (
    target: mtechnavi.api.company.IOrganization | undefined
  ) => {
    setTargetData(target);
    setTargetParents([
      ...traceParent(target),
      ...(target ? [{ displayName: target?.displayName }] : []),
    ]);
    setDetailMode('add');
    setOpenOrgDetailDialog(true);
  };
  const handleAddChildDecision = (
    newOrg: mtechnavi.api.company.IOrganization
  ) => {
    if (!newOrg.organizationId || isDuplicationCode(newOrg.organizationId)) {
      error(['使用されている組織コードです']);
      return;
    }
    const flatData = [...originData, newOrg];
    setTreeData(flatData);
    setOpenOrgDetailDialog(false);
  };

  // 詳細表示
  const handleDetail = (
    target: mtechnavi.api.company.IOrganization | undefined
  ) => {
    setTargetData(target);
    setTargetParents(traceParent(target));
    setDetailMode('show');
    setOpenOrgDetailDialog(true);
  };

  // 編集
  const handleEdit = (
    target: mtechnavi.api.company.IOrganization | undefined
  ) => {
    setTargetData(target);
    setTargetParents(traceParent(target));
    setDetailMode('edit');
    setOpenOrgDetailDialog(true);
  };
  const handleEditDecision = (editOrg: mtechnavi.api.company.IOrganization) => {
    const flatData = [
      ...originData.filter(
        (item) => item.organizationId !== editOrg.organizationId
      ),
      editOrg,
    ];
    setTreeData(flatData);
    setOpenOrgDetailDialog(false);
  };

  // 削除
  const handleDelete = async (
    target: mtechnavi.api.company.IOrganization | undefined
  ) => {
    const message = {
      id: 'C0000001',
      value: { $1: GetMessageWithIntl(intl, { id: 'delete' }) },
    };
    if (!(await confirmation(message))) {
      return;
    }
    const flatData = [
      ...originData.filter(
        (item) => item.organizationId !== target?.organizationId
      ),
    ];
    setTreeData(flatData);
    setTargetData(target);
  };

  // グループ内ユーザ編集
  const handleEditMember = async (
    target: mtechnavi.api.company.IOrganization | undefined
  ) => {
    showLoading();
    await new Promise((resolve) => setTimeout(resolve, 1000));
    hideLoading();
    setMemberUserList([]);
    setTargetData(target);
    setTargetParents(traceParent(target));
    setOpenOrgMemberDialog(true);
  };
  const handleMemberEditDecision = () => {
    setOpenOrgMemberDialog(false);
  };

  const handleDrop = ({
    targetId,
    parentId,
  }: {
    targetId: string;
    parentId: string;
  }) => {
    console.log('handleDrop', targetId, parentId);
    const targetOrg = originData.find(
      (item) => item.organizationId === targetId
    );
    if (targetOrg === undefined) {
      return;
    }
    const flatData: mtechnavi.api.company.IOrganization[] = [
      ...originData.filter(
        (item) => item.organizationId !== targetOrg.organizationId
      ),
      { ...targetOrg, parentOrganization: { organizationId: parentId } },
    ];
    setTreeData(flatData);
  };

  // 決定時の処理分岐用
  const orgDetailDecisionMap = {
    add: handleAddChildDecision,
    edit: handleEditDecision,
    show: () => {},
  };

  /**
   * 確認ダイアログ処理
   * Promise で結果を制御する。
   * 確定: true / キャンセル: false
   */
  const confirmation = (viewMessage: MessageProps) => {
    setOpenConfirmDialog(true);
    setConfirmMessage(viewMessage);
    return new Promise<boolean>((resolve) => {
      confirmPromiseRef.current = resolve;
    });
  };

  return (
    <>
      <Container>
        <div className="OrganizationTreeSamplePage">
          <div className="input-line">
            <div className="item-group-100">
              <div className="w-100">
                <CaptionButton
                  name="add"
                  buttonType="basic"
                  className="button"
                  caption="組織検索"
                  onClick={() => setOpenOrgSearchDialog(true)}
                />
              </div>
            </div>
          </div>
          <hr />
          <div className="input-line">
            <div className="item-group-100">
              <div className="w-100">
                <Checkbox
                  name="isNiterra"
                  className="group"
                  items={[
                    {
                      value: '1',
                      displayName: 'NITERRA向け',
                    },
                  ]}
                  value={isNiterra ? '1' : ''}
                  columns={['isNiterra']}
                  onChangeState={(checkedValues) =>
                    setNiterra(checkedValues.includes('1'))
                  }
                />
              </div>
            </div>
          </div>

          <GroupTreeEditor
            rootLabel={companyName}
            data={mockData}
            isEnableDnD={true}
            editActions={{
              onDetail: handleDetail,
              onEdit: handleEdit,
              onDelete: handleDelete,
              onEditMember: handleEditMember,
              onAddChild: handleAddChild,
            }}
            onDrop={handleDrop}
            isShowAddChild={(data) => !data?.procurementOption}
          />
        </div>
        <Toast />
      </Container>
      {/* 組織編集ダイアログ */}
      <OrganizationDetailDialog
        key={isOpenOrgDetailDialog ? '1' : ''} // 開閉する度に初期化する
        isOpen={isOpenOrgDetailDialog}
        isUseProcurementOption={isNiterra}
        mode={detailMode}
        parents={targetParents}
        inputOption={{
          ...targetData,
        }}
        onDecision={orgDetailDecisionMap[detailMode]}
        onCancel={() => setOpenOrgDetailDialog(false)}
      />
      {/* 組織メンバー編集ダイアログ */}
      <OrganizationMemberDialog
        isOpen={isOpenOrgMemberDialog}
        mode="edit"
        isProcurement={!!targetData?.procurementOption}
        inputOption={{
          organizations: [
            ...(targetParents || []),
            ...(targetData ? [targetData] : []),
          ],
          memberUserList,
        }}
        messageOption={{
          headerLabelId: {
            prefixId: 'DIALOG_TITLE',
            id: 'MemberEditDialog',
          },
        }}
        onDecision={handleMemberEditDecision}
        onCancel={() => setOpenOrgMemberDialog(false)}
      />
      {/* 組織検索ダイアログ (利用側のモック) */}
      <OrganizationTreeSearchDialog
        isOpen={isOpenOrgSearchDialog}
        organizationList={originData}
        checkedIds={checkedIds}
        messageOption={{
          headerLabelId: {
            prefixId: 'DIALOG_TITLE',
            id: 'OrganizationTreeSearchDialog',
          },
        }}
        onCancel={() => setOpenOrgSearchDialog(false)}
        onDecision={(checks) => {
          console.log('search Org', checks);
          setCheckedIds(checks);
          setOpenOrgSearchDialog(false);
        }}
      />
      {/* 確認ダイアログ */}
      <ConfirmationDialog
        isOpen={isOpenConfirmDialog}
        viewMessage={confirmMessage}
        onDecision={() => {
          if (confirmPromiseRef.current) {
            confirmPromiseRef.current(true);
          }
          setOpenConfirmDialog(false);
        }}
        onCancel={() => {
          if (confirmPromiseRef.current) {
            confirmPromiseRef.current(false);
          }
          setOpenConfirmDialog(false);
        }}
      />
    </>
  );
};
