import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface SurveyReplyDueDateChangeRequest {
  actionName: 'surveyReplyDueDateChangeRequest';
  request: mtechnavi.api.survey.ISurveyReplyDueDateChangeRequest;
}

// // 回答期日変更依頼
export const createSurveyReplyDueDateChangeRequest = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.survey.ISurveyReplyDueDateChangeRequest
) => {
  const createFullMethodName =
    'mtechnavi.api.survey.SurveyReceiverService/CreateSurveyReplyDueDateChangeRequest';
  const createResult = await worker.invokeListRequest(createFullMethodName, {
    surveyReplyDueDateChangeRequest: req,
  });
  if (createResult && createResult.length > 0) {
    const sharetoFullMethodName =
      'mtechnavi.api.survey.SurveyReceiverService/SharetoSurveyReplyDueDateChangeRequest';

    await worker.invokeListRequest(sharetoFullMethodName, {
      surveyReplyDueDateChangeRequest: createResult[0],
    });
  }
};
