import { saveDisasterSurvey } from './excSaveDisasterSurvey';
import { addSurveyBaseRequestIdInDisasterSurvey } from './execAddSuveyBaseRequestIdInDisasterSurvey';
import { completeDisasterSurvey } from './execCompleteDisasterSurvey';
import { deleteDisasterSurvey } from './execDeleteDisasterSurvey';
import { saveDisasterSystemNotification } from './execSaveSysytemNotificationUsers';
import { uncompleteDisasterSurvey } from './execUncompleteDisasterSurvey';

// actionName と function のマッピング
export const bcpCallMap = {
  addSurveyBaseRequestIdInDisasterSurvey:
    addSurveyBaseRequestIdInDisasterSurvey,
  completeDisasterSurvey: completeDisasterSurvey,
  deleteDisasterSurvey: deleteDisasterSurvey,
  saveDisasterSurvey: saveDisasterSurvey,
  saveDisasterSystemNotification: saveDisasterSystemNotification,
  uncompleteDisasterSurvey: uncompleteDisasterSurvey,
};
