import { getGenerallyIconElement } from '../../components/parts/Button/GenerallyIcons';
import { HelpItem } from '../../services/staticContents';
import './QAItemComponent.css';

interface qaItemComponentProps {
  qaItem: HelpItem;
}

export const QAItemComponent = (props: qaItemComponentProps) => {
  const { qaItem } = props;
  return (
    <div className="QAitem" key={qaItem.id}>
      <div className="contents">
        <div className="icon-area">{getGenerallyIconElement('help')}</div>
        <div className="qa-area">
          <p className="title">{qaItem.title}</p>
          <div className="answer">{qaItem.answer}</div>
          {qaItem.htmlId && (
            <span className="more-detail">
              詳しくは
              <a
                href={`/help/${qaItem.htmlId}`}
                target="_blank"
                rel="noreferrer"
              >
                こちら
              </a>
            </span>
          )}
        </div>
      </div>
      <hr />
    </div>
  );
};
