import { generateForm } from './generateForm';
import { bulkWriteFormValues } from './saveFormValues';
import { deleteFormWithRelatedItems } from './deleteFormWithRelatedItems';
import { saveBaseForm } from './saveBaseForm';
import { createSampleWorkForm } from './createSampleWorkForm';
import { deleteSampleWorkForm } from './deleteSampleWorkForm';
import { updateSampleWorkForm } from './updateSampleWorkForm';
import { deleteBaseFormWithRelatedItems } from './deleteBaseFormWithRelatedItems';
import { importSampleWorkForms } from './importSampleWorkForms';
import { exportSampleWorkForms } from './exportSampleWorkForm';
import { sharetoFormValue } from './sharetoFormValue';
import { bulkDeleteFormValues } from './bulkDeleteFormValues';

// actionName と function のマッピング
export const formCallMap = {
  saveBaseForm: saveBaseForm,
  deleteBaseFormWithRelatedItems: deleteBaseFormWithRelatedItems,
  generateForm: generateForm,
  deleteFormWithRelatedItems: deleteFormWithRelatedItems,
  saveFormValue: bulkWriteFormValues,
  bulkDeleteFormValues: bulkDeleteFormValues,
  createSampleWorkForm: createSampleWorkForm,
  updateSampleWorkForm: updateSampleWorkForm,
  deleteSampleWorkForm: deleteSampleWorkForm,
  importSampleWorkForms: importSampleWorkForms,
  exportSampleWorkForms: exportSampleWorkForms,
  sharetoFormValue: sharetoFormValue,
};
