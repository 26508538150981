import { mtechnavi, sharelib } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface ApprovalRequestSurveyBaseRequest {
  actionName: 'approvalRequestSurveyBaseRequest';
  request: ApprovalRequestSurveyBaseRequestParam;
}
interface ApprovalRequestSurveyBaseRequestParam {
  surveyBaseRequestId: string;
  approvalPlanStaff: sharelib.IUserReference;
  approvalPlanOrganization: sharelib.IOrganizationStructureReference;
  comment: string;
}

// 承認依頼
export const approvalRequestSurveyBaseRequest = async (
  worker: AppSaveWorker,
  req: ApprovalRequestSurveyBaseRequestParam
) => {
  const approvalRequestFullMethodName =
    'mtechnavi.api.survey.SurveySenderService/ApprovalRequestSurveyBaseRequest';

  return await worker.invokeListRequest<
    mtechnavi.api.survey.IApprovalRequestSurveyBaseRequestRequest,
    mtechnavi.api.survey.IApprovalRequestSurveyBaseRequestResponse
  >(approvalRequestFullMethodName, {
    surveyBaseRequestId: req.surveyBaseRequestId,
    approvalPlanStaff: req.approvalPlanStaff,
    approvalPlanOrganization: req.approvalPlanOrganization,
    comment: req.comment,
  });
};
