import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface ExportedSurveyResultFile {
  actionName: 'exportedSurveyResultFile';
  request: mtechnavi.api.survey.IExportedSurveyResultFileRequest;
}

// 出力済みフラグ更新
export const exportedSurveyResultFile = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.survey.IExportedSurveyResultFileRequest
) => {
  const fullMethodName =
    'mtechnavi.api.survey.SurveySenderService/ExportedSurveyResultFile';
  return await worker.invokeListRequest<
    mtechnavi.api.survey.IExportedSurveyResultFileRequest,
    mtechnavi.api.survey.IExportedSurveyResultFileResponse
  >(fullMethodName, {
    surveyResultId: req.surveyResultId,
  });
};
