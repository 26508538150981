import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface UpdateEstimateResultDetailRequest {
  actionName: 'updateEstimateResultDetail';
  request: mtechnavi.api.estimation.IEstimateResultDetail;
}

export const updateEstimateResultDetail = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.estimation.IEstimateResultDetail
) => {
  const updateFullMethodName =
    'mtechnavi.api.estimation.EstimateReceiverService/UpdateEstimateResultDetail';

  return worker.invokeListRequest<
    mtechnavi.api.estimation.IUpdateEstimateResultDetailRequest,
    void
  >(updateFullMethodName, {
    estimateResultDetail: req,
  });
};
