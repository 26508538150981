import { useState, useEffect } from 'react';
import {
  GetMessage,
  MessageProps,
  ModalDialogComponent,
  ModalDialogComponentProps,
  Radio,
  RadioItem,
} from '../..';
import { CaptionButton } from '../Button/CaptionButton';

interface RadioSelectDialogInputOption {
  radioButtonInfo: RadioItem[];
  initialValue?: string;
}

interface RadioSelectDialogMessageOption {
  headerMessageOption: MessageProps;
  descriptionMessageOption?: MessageProps;
}

interface RadioSelectDialogProps {
  isOpen: boolean;
  inputOption: RadioSelectDialogInputOption;
  messageOption: RadioSelectDialogMessageOption;
  onDecision: (v: string) => void;
  onCancel: () => void;
}

export const RadioSelectDialog = (props: RadioSelectDialogProps) => {
  const { isOpen, inputOption, messageOption, onDecision, onCancel } = props;
  const [radio, setRadio] = useState('');

  // ダイアログ表示変更時
  useEffect(() => {
    // ダイアログ起動時
    if (isOpen) {
      setRadio(inputOption.initialValue ?? '');
    }
    // isOpen変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const elements = (
    <div className="output-dialog dialog-with-description">
      {messageOption.descriptionMessageOption && (
        <div className="description-area">
          <p className="text-box">
            <span className="text">
              {GetMessage({
                id: messageOption.descriptionMessageOption.id ?? '',
                prefixId: messageOption.descriptionMessageOption.prefixId ?? '',
              })}
            </span>
          </p>
        </div>
      )}
      <div className="contents-box">
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              <Radio
                name="radio outputType"
                className="group"
                items={inputOption.radioButtonInfo}
                value={radio}
                validateOption={{ required: true }}
                onChangeState={setRadio}
              ></Radio>
            </div>
          </div>
        </div>
        <div className="button-area">
          <CaptionButton
            name="cancel"
            buttonType="cancel"
            className="button"
            caption={GetMessage({
              id: 'cancel',
            })}
            onClick={() => onCancel()}
          />
          <CaptionButton
            name="decision"
            buttonType="basic"
            className="button"
            caption={GetMessage({
              id: 'decision',
            })}
            onClick={() => onDecision(radio)}
          />
        </div>
      </div>
    </div>
  );

  const openModalProps: ModalDialogComponentProps = {
    cancel: () => {
      props.onCancel();
    },
    send: () => {},
    modalIsOpen: isOpen,
    headerLabelId: messageOption?.headerMessageOption ?? {
      id: 'radioSelectDialog',
      prefixId: 'DIALOG_TITLE',
    },
    messageLabelId: {},
    elements,
  };

  return <ModalDialogComponent {...openModalProps} />;
};
