import { useEffect, useRef, useState } from 'react';
import {
  Container,
  error,
  Toast,
  LoadingIcon,
  GetMessageWithIntl,
  success,
  GetMessage,
} from '~/shared/components';
import {
  Textbox,
  NavigationIconMenu,
  SimpleListView,
  ConfirmationDialog,
} from '~/shared/components/ui';

import { PresetItem } from '~/shared/services';
import './BlueprintBlueprintConfirmation.css';
import { usePagenator } from '~/shared/components/ui/ListView/pagenator';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { useLocation, useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import {
  ViewId,
  PageState,
  getWorkerExceptionMessage,
  getDateFormat,
  FullMethodName_ListUserAttributes,
  getViewIdPreset,
  FullMethodName_ListUserGroupAttributes,
  getMaxMainContentsHeight,
  getSortSettingFromLocalStorage,
  convertOrganizationStructureReferenceToFilterboxItem,
} from '~/shared/utils';
import { useErrorHandler } from '~/shared/components/error/ErrorBoundary';
import { PageNavigation } from '~/shared/components/ui/PageNavigation/PageNavigation';
import { CaptionButton } from '~/shared/components/parts/Button/CaptionButton';
import { FilterRequest } from '~/worker';
import {
  BelongGroupEditDialog,
  UserGroup,
} from '~/shared/components/ui/Dialog/BelongGroupEditDialog';
import { useAuth } from '~/shared/contexts/AuthProvider';

const VIEW_ID: ViewId = 'ADMIN_ACCOUNT_CONFIRMATION';

export function AdminAccountConfirmation() {
  interface JoiedUserAttributes
    extends mtechnavi.api.tenantadmin.IUserAttribute {
    mergedUserAttributes: mtechnavi.api.tenantadmin.IUserAttribute[];
  }

  // ログイン者の情報特定用email
  const myEmail = useAuth().user?.email ?? '';
  const intl = useIntl();
  const navi = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const toastSuccess = GetMessageWithIntl(intl, { id: 'I0000001' });
  const excessTargetMessage = GetMessageWithIntl(intl, { id: 'E0000083' });
  const [userGroup, setUserGroup] =
    useState<mtechnavi.api.tenantadmin.IUserGroupAttribute[]>();
  const handleError = useErrorHandler();

  // アコーディオン
  const [accordionState, setAccordionState] = useState<{
    [k: string]: boolean;
  }>({
    attributes: true,
    license: true,
  });
  const accordionAction = (type: string) => {
    setAccordionState({ ...accordionState, [type]: !accordionState[type] });
  };

  // 遷移元から値を受け取る
  const location = (useLocation().state as PageState) ?? [];
  // 例外処理用にエラー情報を取得する様修正
  const [isException, setException] = useState<boolean>(false);
  const [pagenateError, setPagenateError] = useState<unknown>({});

  // pagenatorに基本情報をセット
  const [page, dispatch] = usePagenator({
    fullMethodName: FullMethodName_ListUserAttributes,
    pageNumber: location.confirmationViewOption?.pageNumber ?? 1,
    pageSize: 1,
    maxPageNumber: 1,
    filter: {
      userAttributeId: { $in: location.ids ?? [] },
    },
    sort: getSortSettingFromLocalStorage('ADMIN_ACCOUNT_LIST', myEmail),
    items: [],
    originalItems: [],
    onError(err) {
      setException(true);
      setPagenateError(err);
      handleError(err);
    },
  });

  useEffect(() => {
    if (isException) {
      error(getWorkerExceptionMessage(intl, pagenateError));
    }
  }, [intl, isException, pagenateError]);

  // pageデータ変更時の処理
  useEffect(() => {
    const items = page.originalItems as unknown as JoiedUserAttributes[];
    setItem(items.pop());
    setFirstPage(page.pageNumber === 1);
    setMaxPage(page.pageNumber === page.maxPageNumber);
  }, [page]);

  // 初回画面用データ
  useEffect(() => {
    // viewIDに紐付くpresetを取得
    (async () => {
      const { presetItem } = await getViewIdPreset(intl, VIEW_ID);

      setPreset(presetItem);
    })();

    handleGroup();

    // 基本情報でセットした値を元に情報を取得
    handleReload();

    // 初回時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleReload = () => {
    dispatch({
      type: 'reload',
      fullMethodName: FullMethodName_ListUserAttributes,
      onChangeLoadingState: (v) => {
        setLoading(v);
      },
    });
  };

  const handleGroup = async () => {
    const option: FilterRequest = {
      action: 'reload',
      fullMethodName: FullMethodName_ListUserGroupAttributes,
      filter: {},
      sort: [],
    };
    const userGroupList = (await window.App.services.ui.worker.filter(
      option
    )) as mtechnavi.api.tenantadmin.ListUserGroupAttributesResponse;

    setUserGroup(userGroupList.items);
  };

  const [isFirstPage, setFirstPage] = useState(false);
  const [isMaxPage, setMaxPage] = useState(false);

  const [item, setItem] = useState<JoiedUserAttributes>();
  // preset
  const [preset, setPreset] = useState<PresetItem>({ name: '' });
  const [mainContentHeight, setMainContentHeight] = useState('');
  const footerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    setMainContentHeight(
      getMaxMainContentsHeight(footerRef.current?.clientHeight ?? 0)
    );
  }, [footerRef.current?.clientHeight]);
  const [isDeleteOpen, setDeleteOpen] = useState(false);
  const [isBelongEditOpen, setBelongEditOpen] = useState(false);

  const deleteMessage = {
    id: 'C0000001',
    value: { $1: '「削除」' },
  };

  // ナビゲーションメニュー
  const navigationIconItems = (): NavigationIconMenu[] => {
    const iconItems: NavigationIconMenu[] = [];
    // 編集
    iconItems.push({
      name: 'edit',
      displayName: '編集',
      func: () => {
        handleMenuInput();
      },
    });
    // 削除
    iconItems.push({
      name: 'delete',
      displayName: '削除',
      func: () => setDeleteOpen(true),
    });

    return iconItems;
  };

  // ページ戻し・送り処理
  const handleMovePage = (pageNumber: number) => {
    const n = Math.min(Math.max(1, pageNumber), page.maxPageNumber);
    dispatch({
      type: 'query',
      fullMethodName: FullMethodName_ListUserAttributes,
      pageNumber: n,
    });
  };

  // 編集
  const handleMenuInput = () => {
    const ids = [] as string[];
    ids.push(item?.userAttributeId ?? '');
    const state: PageState = {
      ids: ids ?? [],
      actionType: 'edit',
      sourceViewId: VIEW_ID,
      naviFilters: location.naviFilters,
      beforeStateIds: location.ids,
      baseViewOption: location.baseViewOption,
      confirmationViewOption: { pageNumber: page.pageNumber },
    };
    navi('/admin-account-input', { state });
  };

  // 削除
  const handleMenuDelete = async () => {
    const deleteItem: mtechnavi.api.tenantadmin.IUserAttribute[] = [];
    if (item?.userAttributeId) {
      deleteItem.push(item as mtechnavi.api.tenantadmin.IUserAttribute);
    }
    setLoading(true);
    try {
      await window.App.services.ui.worker.apiCall({
        actionName: 'deleteAdminAccountList',
        request: deleteItem,
      });
      success([toastSuccess]);
    } catch (err) {
      getWorkerExceptionMessage(intl, err);
    } finally {
      setLoading(false);
    }
    setDeleteOpen(false);
    navi('/admin-account-list');
  };

  const handleStatusChange = () => {
    (async () => {
      try {
        setLoading(true);

        if (item?.status?.status?.systemName === 'B01') {
          const enableUsers: Promise<mtechnavi.api.tenantadmin.EnableUserRequest>[] =
            [];

          enableUsers.push(
            window.App.services.tenantAdminService.enableUser({
              user: item?.user,
            })
          );
          await Promise.all(enableUsers);
        } else {
          const disableUsers: Promise<mtechnavi.api.tenantadmin.DisableUserRequest>[] =
            [];
          disableUsers.push(
            window.App.services.tenantAdminService.disableUser({
              user: item?.user,
            })
          );
          await Promise.all(disableUsers);
        }
        success([toastSuccess]);
        handleReload();
      } catch (err) {
        error(getWorkerExceptionMessage(intl, err));
        throw err;
      } finally {
        setLoading(false);
      }
    })();
  };

  const handleSave = async (ids: string[]) => {
    setLoading(true);
    try {
      const setElements = new Set(ids);
      if (setElements.size !== ids.length) {
        error([excessTargetMessage]);
        return;
      }

      const saveUserItem = item as mtechnavi.api.tenantadmin.IUserAttribute;
      saveUserItem!.user!.userGroupIds = ids;
      await window.App.services.ui.worker.apiCall({
        actionName: 'saveAdminAccount',
        request: saveUserItem,
      });
      success([toastSuccess]);
      handleReload();
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Container viewId={VIEW_ID}>
        <div className="header">
          <PageNavigation
            backpagePath="/admin-account-list"
            pageInfo={{
              isVisibleMoveNavi: true,
              isFirstPage,
              isMaxPage,
              pageNumber: page.pageNumber,
            }}
            iconItems={navigationIconItems()}
            handleMovePage={(n) => handleMovePage(n)}
            infoOption={{
              lastUpdateInfo: {
                isVisibleUpdateInfo: true,
                attributeAt: item?.userAttributeUpdatedAt ?? undefined,
                content: item?.userAttributeUpdatedBy?.displayName ?? '',
                attribute: item?.userAttributeUpdatedBy?.email ?? '',
              },
              issuerInfo: { isVisibleIssuerInfo: false },
            }}
          />
        </div>
        <div>
          <div
            className="scroll-main-contents-area"
            style={{
              maxHeight: mainContentHeight,
            }}
          >
            <div className="input-line">
              <div className="item-group-100">
                <div className="w-40">
                  <Textbox
                    name="user.email"
                    className="field"
                    value={item?.user?.email ?? ''}
                    type="text"
                    labelId="ADMIN_ACCOUNT_CONFIRMATION.user.email"
                    columns={preset.columns}
                    disabled={true}
                  />
                </div>
                <div className="w-20">
                  <Textbox
                    name="status.status.displayNameLang"
                    className="field"
                    value={item?.status?.status?.displayNameLang?.ja ?? ''}
                    type="text"
                    labelId="ADMIN_ACCOUNT_CONFIRMATION.status.status.displayNameLang"
                    columns={preset.columns}
                    disabled={true}
                  />
                </div>
                <div className="w-20">
                  <Textbox
                    name="user.lastLoginAt"
                    className="field"
                    value={getDateFormat(
                      item?.user?.lastLoginAt ?? '',
                      'YYYY/MM/DD HH:mm'
                    )}
                    type="text"
                    labelId="ADMIN_ACCOUNT_CONFIRMATION.user.lastLoginAt"
                    columns={preset.columns}
                    disabled={true}
                  />
                </div>
              </div>
            </div>
            <div className="input-line">
              <div className="item-group-100">
                <div className="w-40">
                  <Textbox
                    name="user.displayName"
                    className="field"
                    value={item?.user?.displayName ?? ''}
                    type="text"
                    labelId="ADMIN_ACCOUNT_CONFIRMATION.user.displayName"
                    columns={preset.columns}
                    disabled={true}
                  />
                </div>
              </div>
            </div>
            <div
              className={`input-blocktitle-outer ${
                accordionState.attributes ? '' : 'close'
              }`}
            >
              <h3
                className="input-blocktitle"
                onClick={() => accordionAction('attributes')}
              >
                {GetMessage({ id: 'attributes', prefixId: VIEW_ID })}
              </h3>
            </div>
            <div
              className={`input-blockbody ${
                accordionState.attributes ? '' : 'close'
              }`}
            >
              <div className="input-line">
                <div className="item-group-100">
                  <div className="w-20">
                    <Textbox
                      name="staffCode"
                      className="field"
                      value={item?.staffCode ?? ''}
                      type="text"
                      labelId="ADMIN_ACCOUNT_CONFIRMATION.staffCode"
                      columns={preset.columns}
                      disabled={true}
                    />
                  </div>
                  <div className="w-20">
                    <Textbox
                      name="approver"
                      className="field"
                      value={item?.approver ? '◯' : '-'}
                      type="text"
                      labelId="ADMIN_ACCOUNT_CONFIRMATION.approver"
                      columns={preset.columns}
                      disabled={true}
                    />
                  </div>
                  <div className="w-40">
                    <Textbox
                      name="remarks"
                      className="field"
                      value={item?.remarks ?? ''}
                      type="text"
                      labelId="ADMIN_ACCOUNT_CONFIRMATION.remarks"
                      columns={preset.columns}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
              <div className="input-line">
                <div className="item-group-100">
                  <div className="w-40">
                    <Textbox
                      name="representativeOrganization"
                      className="field"
                      labelId="ADMIN_ACCOUNT_CONFIRMATION.representativeOrganization"
                      columns={preset.columns}
                      type="text"
                      value={
                        convertOrganizationStructureReferenceToFilterboxItem(
                          item?.representativeOrganization
                        )?.displayName ?? ''
                      }
                      disabled={true}
                    />
                  </div>
                  <div className="w-40">
                    <Textbox
                      name="approvalOrganization"
                      labelId="ADMIN_ACCOUNT_CONFIRMATION.approvalOrganization"
                      columns={preset.columns}
                      type="text"
                      value={
                        convertOrganizationStructureReferenceToFilterboxItem(
                          item?.approvalOrganization
                        )?.displayName ?? ''
                      }
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`input-blocktitle-outer ${
                accordionState.license ? '' : 'close'
              }`}
            >
              <h3
                className="input-blocktitle"
                onClick={() => accordionAction('license')}
              >
                {GetMessage({ id: 'license', prefixId: VIEW_ID })}
              </h3>
            </div>
            <div
              className={`input-blockbody ${
                accordionState.license ? 'margin-bottom' : 'close'
              }`}
            >
              <div className="input-line">
                <div className="item-group-100">
                  <div className="w-40">
                    <SimpleListView
                      data={
                        userGroup
                          ?.filter((e) =>
                            item?.user?.userGroupIds?.includes(
                              e.userGroup?.userGroupId || ''
                            )
                          )
                          .map((v) => {
                            return {
                              id: v.userGroup?.userGroupId ?? '',
                              displayName: v.userGroup?.displayName,
                            };
                          }) ?? []
                      }
                      viewOptions={{
                        readonly: true,
                        previewRowCount: 5,
                        keyColumn: 'id',
                        columns: [
                          {
                            header: { id: 'belongEditGroup' },
                            propertyName: 'displayName',
                          },
                        ],
                      }}
                      actionOptions={{}}
                    />
                  </div>
                  <div className="w-10">
                    <CaptionButton
                      buttonType="basic"
                      caption={GetMessageWithIntl(intl, {
                        viewId: VIEW_ID,
                        id: 'editRole',
                      })}
                      name="add"
                      onClick={() => setBelongEditOpen(true)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ConfirmationDialog
          isOpen={isDeleteOpen}
          viewMessage={deleteMessage}
          onDecision={() => handleMenuDelete()}
          onCancel={() => setDeleteOpen(false)}
        />
        <BelongGroupEditDialog
          isOpen={isBelongEditOpen}
          inputOption={{
            userGroups: userGroup
              ?.filter((e) =>
                item?.user?.userGroupIds?.includes(
                  e.userGroup?.userGroupId || ''
                )
              )
              .map((v) => {
                return {
                  id: v.userGroup?.userGroupId ?? '',
                  displayName: v.userGroup?.displayName,
                };
              }) as UserGroup[],
          }}
          userAttribute={item as mtechnavi.api.tenantadmin.IUserAttribute}
          messageOption={{
            headerLabelId: {
              id: 'BelongGroupEdit',
              prefixId: 'DIALOG_TITLE',
            },
            messageLabelId: {
              id: 'BelongGroupEdit',
              prefixId: 'DIALOG_DESCRIPTION',
            },
          }}
          onCancel={() => {
            setBelongEditOpen(false);
          }}
          onDecision={(ids) => {
            handleSave(ids);
            setBelongEditOpen(false);
          }}
        />
        <div className="footer" ref={footerRef}>
          <div className="footer-contents">
            <div className="input-line">
              <CaptionButton
                buttonType="basic"
                caption={
                  item?.status?.status?.systemName == 'B01'
                    ? 'アカウント再開'
                    : 'アカウント停止'
                }
                name="stop"
                onClick={handleStatusChange}
              />
            </div>
          </div>
        </div>
        <Toast />
      </Container>
      {isLoading && <LoadingIcon />}
    </>
  );
}
