import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface ExecApprovalRequestWorkTaskRequest {
  actionName: 'approvalRequestWorkTask';
  request: mtechnavi.api.worktask.IApprovalRequestWorkTaskRequest;
}

// 指図案件承認依頼
export const execApprovalRequestWorkTask = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.worktask.IApprovalRequestWorkTaskRequest
) => {
  const fullMethodName =
    'mtechnavi.api.worktask.WorkTaskService/ApprovalRequestWorkTask';
  return await worker.invokeListRequest<
    mtechnavi.api.worktask.IApprovalRequestWorkTaskRequest,
    mtechnavi.api.worktask.IApprovalRequestWorkTaskResponse
  >(fullMethodName, req);
};
