import { useState, useEffect, useMemo, useRef } from 'react';
import {
  Container,
  error,
  GetMessageWithIntl,
  success,
  Toast,
} from '~/shared/components';
import {
  Preset,
  Property,
  getAltDisplaySchema,
  getBooleanDataFormetterSchema,
  ListView,
  MenuActionItem,
  ViewMenu,
  OutputDialog,
  OutputDialogOutput,
  UserGroupOutputInfoType,
  OutputVolumeType,
} from '~/shared/components/ui';
import { ImportDialogHandleFormat } from '~/shared/components/ui/Dialog/ImportDialog';

import { PresetItem, Property as PresetProperty } from '~/shared/services';
import { useIntl } from 'react-intl';
import {
  getPresetAndSchema,
  getExceptionMessage,
  autoDownloadFileWithDate,
  FullMethodName_ListUserGroupAttributeContents,
} from '~/shared/utils';
import { useLoading } from '~/shared/contexts/LoadingProvider';

const VIEW_ID = 'ADMIN_USER_GROUP_LIST';

// フラットに変換された入れ子構造オブジェクトの簡易定義
interface FlattenedListItem {
  [key: string]: boolean | string | string[];
}

export function AdminUserGroupList(): JSX.Element {
  const intl = useIntl();
  const { showLoading, hideLoading } = useLoading();
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [childrenPresetItem, setChildrenPresetItem] = useState<PresetItem[]>();
  const [schema, setSchema] = useState<Property[]>([]);
  const [preset, setPreset] = useState<Preset>({
    filter: {},
    propertyNames: [],
  });
  const [isOutputOpen, setOutputOpen] = useState(false);

  const successMessage = GetMessageWithIntl(intl, { id: 'I0000001' });
  const unselectedMessage = GetMessageWithIntl(intl, { id: 'E0000023' });

  const targetUserGroupAttributeIds = useRef<string[]>([]);
  const listAllItemRef = useRef<FlattenedListItem[]>([]);

  const dialogPreset: PresetProperty[] = useMemo(() => {
    return (
      childrenPresetItem?.find((v) => v.name === 'adminUserGroupListImport')
        ?.property ?? [{ name: '', propertyName: '', propertyValue: '' }]
    );
  }, [childrenPresetItem]);

  useEffect(() => {
    (async () => {
      try {
        const { childrenPresetItem, schemas, preset } =
          await getPresetAndSchema(VIEW_ID, [
            FullMethodName_ListUserGroupAttributeContents,
          ]);
        const jaColumn = ['status.status.displayNameLang'];
        const formatterSch = getAltDisplaySchema(schemas[0], jaColumn, 'ja');
        const booleanColumn = ['userGroupAttribute.systemGroup', 'license'];
        const booleanSch = getBooleanDataFormetterSchema(
          formatterSch,
          booleanColumn
        );
        setChildrenPresetItem(childrenPresetItem);
        setSchema(booleanSch);
        setPreset(preset);
      } catch (err) {
        error(getExceptionMessage(intl, err));
        throw err;
      }
    })();
  }, [intl]);

  const handleExport: ImportDialogHandleFormat = {
    name: 'userBelongsUserGroup',
    headerColumns: [],
  };
  const handleImport: ImportDialogHandleFormat = {
    name: 'userBelongsUserGroup',
    headerColumns: [],
  };

  // ロール出力
  const handleExportAttachedRoles = async (ids: string[]) => {
    try {
      const fileFormat = await window.App.services.ui.getFileFormat(
        'userGroupAttachedRoles',
        intl
      );
      const exportRes =
        await window.App.services.tenantAdminService.exportAttachedRoles({
          ids: ids,
          fileFormat: fileFormat,
        });
      if (!exportRes) {
        return;
      }
      autoDownloadFileWithDate(
        GetMessageWithIntl(intl, {
          prefixId: 'EXPORT_USER_GROUP_ATTACHED_ROLES_FILE_NAME',
        }),
        'csv',
        exportRes.assetId
      );
      success([successMessage]);
    } catch (err) {
      error(getExceptionMessage(intl, err));
      throw err;
    } finally {
      hideLoading();
    }
  };

  // メニュー画面出力
  const handleExportAllowedMenuItems = async (ids: string[]) => {
    try {
      const fileFormat = await window.App.services.ui.getFileFormat(
        'userGroupAllowedMenuItem',
        intl
      );
      const exportRes =
        await window.App.services.tenantAdminService.exportAllowedMenuItems({
          ids: ids,
          fileFormat: fileFormat,
        });
      if (!exportRes) {
        return;
      }
      autoDownloadFileWithDate(
        GetMessageWithIntl(intl, {
          prefixId: 'EXPORT_USER_GROUP_ALLOWED_MENU_ITEM_FILE_NAME',
        }),
        'csv',
        exportRes.assetId
      );
      success([successMessage]);
    } catch (err) {
      error(getExceptionMessage(intl, err));
      throw err;
    } finally {
      hideLoading();
    }
  };

  const setMenuActionItem = (): MenuActionItem[] => {
    const menuActionItems: MenuActionItem[] = [];
    menuActionItems.push({
      menuActionType: 'headerIconMenu',
      menu: headerIconEvent(),
    });
    return menuActionItems;
  };

  const headerIconEvent = (): ViewMenu[] => {
    const menuItems: ViewMenu[] = [];
    // アップロード
    menuItems.push({
      name: 'upload',
      func: () => setDialogOpen(true),
    });
    // 出力
    menuItems.push({
      name: 'export',
      func: (ids?: string[]) => {
        targetUserGroupAttributeIds.current = ids ?? [];
        setOutputOpen(true);
      },
    });

    return menuItems;
  };

  const handleDownload = async (result: OutputDialogOutput) => {
    let ids: string[] = [];
    if (result.outputVolume === OutputVolumeType.SelectedOnly) {
      if (targetUserGroupAttributeIds.current.length < 1) {
        error([unselectedMessage]);
        return;
      } else {
        ids = ids.concat(targetUserGroupAttributeIds.current);
      }
    } else {
      listAllItemRef.current?.map((v: FlattenedListItem) => {
        const id = v['userGroupAttribute.userGroupAttributeId'];
        ids.push(typeof id === 'string' ? id : '');
      });
    }
    showLoading();
    if (result.outputInfoType === UserGroupOutputInfoType.Role) {
      // ロール出力
      await handleExportAttachedRoles(ids);
    } else {
      // メニュー画面出力
      await handleExportAllowedMenuItems(ids);
    }

    setOutputOpen(false);
  };

  return (
    <Container viewId={VIEW_ID}>
      <div className="AdminUserGroupList">
        <ListView
          pageInfo={{
            schema: schema,
            preset: preset,
            menuItem: setMenuActionItem(),
            menuTarget: 'userGroupAttribute.userGroupAttributeId',
            headerTitle: { viewId: VIEW_ID },
            presetItems: childrenPresetItem,
            listSkipType: {
              isTotal: true,
              isOutput: true,
              isListActionMenu: true,
              isListIconMenu: true,
            },
          }}
          isReload={true}
          fullMethodName={FullMethodName_ListUserGroupAttributeContents}
          importDialogOption={{
            isDisplay: true,
            isDialogOpen,
            dialogPreset,
            headerLabelId: {
              id: 'importAdminUserGroupListDialog',
              viewId: VIEW_ID,
            },
            handleExport,
            handleImport,
            onChangeState: setDialogOpen,
          }}
          stateOption={{
            onAllItemState: (items: unknown[]) =>
              (listAllItemRef.current = items as FlattenedListItem[]),
          }}
        />

        <OutputDialog
          type="userGroup"
          isOpen={isOutputOpen}
          onDecision={(v) => {
            handleDownload(v);
          }}
          onCancel={() => {
            setOutputOpen(false);
          }}
        />
      </div>
      <Toast />
    </Container>
  );
}
