import {
  Ref,
  RefObject,
  createRef,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { GroupTreeNode } from './GroupTreeNode';
import { GroupTreeEdge } from './GroupTreeEdge';
import { GroupTreeItem } from './type';
import { getGenerallyIconElement } from '~/shared/components/parts/Button/GenerallyIcons';
import { GroupTreeEditActions, GroupTreeEditPane } from './GroupTreeEditPane';
import { GroupTreeNodeRef } from './util';

export interface GroupTreeRootRef {
  allOpen: () => void;
  allClose: () => void;
  focus: (focusId: string) => void;
}

export interface GroupTreeGlobalProps<T> {
  rootLabel?: string;
  searchWord?: string;
  data: GroupTreeItem<T>[];
  isShowDisabled?: boolean;
  isEnableDnD?: boolean;
  isEditable?: boolean;
  editActions: GroupTreeEditActions<T>;
  onDrop?: (data: { targetId: string; parentId: string }) => void;
  isShowAddChild: boolean | ((data: T | undefined) => boolean);
}
export type GroupTreeRootProps<T> = GroupTreeGlobalProps<T> & {
  onSelect?: () => void;
};

const recursiveCreateRef = <T,>(data: GroupTreeItem<T>[]) => {
  return data.flatMap((item): [string, RefObject<GroupTreeNodeRef>][] => {
    return [
      ...(item.children ? recursiveCreateRef(item.children) : []),
      [item.id || '', createRef<GroupTreeNodeRef>()],
    ];
  });
};

/**
 * ツリーのルートコンポーネント
 */
export const GroupTreeRoot = forwardRef(
  <T,>(
    {
      rootLabel,
      searchWord,
      data,
      isShowDisabled,
      editActions,
      isEnableDnD,
      isEditable,
      onDrop,
      isShowAddChild,
    }: GroupTreeRootProps<T>,
    ref: Ref<GroupTreeRootRef> | undefined
  ) => {
    const [selectedGroup, setSelectedGroup] = useState<string | null>(null);
    const level = 1;

    // 再起的に全ての子の ref を保持しておく
    const refList = useMemo(() => {
      const entries = recursiveCreateRef(data);
      return Object.fromEntries(entries);
    }, [data]);

    const handleSelect = (selectId: string | null) => {
      setSelectedGroup(selectId);
    };

    const handleDragStart = (e: React.DragEvent<HTMLDivElement>) => {
      e.stopPropagation();
      e.dataTransfer.effectAllowed = 'move';
      e.dataTransfer.setData('text/plain', '');
    };
    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
      e.stopPropagation();
      e.preventDefault();
      const targetId = e.dataTransfer.getData('text/plain');
      const parentId = '';
      if (targetId === parentId) {
        return;
      }
      onDrop && onDrop({ targetId, parentId });
    };
    const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
    };
    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
    };

    useImperativeHandle(
      ref,
      (): GroupTreeRootRef => ({
        allOpen: () => {
          data.forEach((item) => {
            const ref = refList[item.id || '']?.current;
            ref?.allOpen && ref.allOpen();
          });
        },
        allClose: () => {
          data.forEach((item) => {
            const ref = refList[item.id || '']?.current;
            ref?.allClose && ref.allClose();
          });
        },
        focus: (focusId?: string) => {
          if (!focusId) {
            return;
          }
          const ref = refList[focusId]?.current;
          ref?.focus && ref.focus();
          setSelectedGroup(focusId ?? null);

          const scrollTarget = document
            .getElementsByClassName(`id-${focusId}`)
            .item(0) as HTMLDivElement;
          window.scroll({
            top: (scrollTarget?.offsetTop || 0) - 50, // ヘッダの高さ分ずらす
            behavior: 'smooth',
          });
        },
      })
    );

    return (
      <div
        className={`GroupTreeItemRoot ${
          selectedGroup === '' ? 'selected' : ''
        }`}
        onClick={() => {
          handleSelect('');
        }}
        draggable={isEnableDnD}
        onDragStart={handleDragStart}
        onDragEnter={handleDragEnter}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <div className="GroupLabel GroupRootLabel">
          <span className="groupLabelIcon">
            {getGenerallyIconElement('company')}
          </span>
          <span>{rootLabel}</span>
          <GroupTreeEditPane
            data={{}}
            isEditable={isEditable}
            editActions={{
              onAddChild: editActions.onAddChild,
            }}
            isShowAddChild={true}
          />
        </div>
        {data.length > 0 && (
          <div className="ChildTree">
            {data.map((item) => {
              if (!isShowDisabled && item.isDisabled) {
                return;
              }
              const hasChild = item && (item.children || []).length > 0;
              if (!hasChild) {
                return (
                  <GroupTreeEdge
                    key={item.id}
                    data={item}
                    level={level}
                    searchWord={searchWord}
                    selectedGroup={selectedGroup}
                    onSelectGroup={handleSelect}
                    editActions={editActions}
                    onDrop={onDrop}
                    ref={refList[item.id || '']}
                    isEnableDnD={isEnableDnD}
                    isEditable={isEditable}
                    isShowAddChild={isShowAddChild}
                  />
                );
              }
              return (
                <GroupTreeNode
                  key={item.id}
                  data={item}
                  level={level}
                  searchWord={searchWord}
                  isShowDisabled={isShowDisabled}
                  selectedGroup={selectedGroup}
                  onSelectGroup={handleSelect}
                  editActions={editActions}
                  onDrop={onDrop}
                  ref={refList[item.id || '']}
                  refList={refList} // 子孫の ref をセットしてもらうため子ノードに渡す
                  isEnableDnD={isEnableDnD}
                  isEditable={isEditable}
                  isShowAddChild={isShowAddChild}
                />
              );
            })}
          </div>
        )}
      </div>
    );
  }
);
