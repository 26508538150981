import { approvalRequestSurveyBaseRequest } from './approvalRequestSurveyBaseRequest';
import { completeSurveyRequest } from './completeSurveyRequest';
import { createSurveyReplyDueDateChangeRequest } from './createSurveyReplyDueDateChangeRequest';
import { createSurveyResultChange } from './createSurveyResultChange';
import { deleteCatalogRequest } from './deleteCatalogRequest';
import { deleteSurveyBaseRequest } from './deleteSurveyBaseRequest';
import { discardSurveyRequest } from './discardSurveyRequest';
import { execSurveyApproveResultRequest } from './execSurveyApproveResultRequest';
import { execSurveyResult } from './execSurveyResult';
import { exportedSurveyResultFile } from './exportedSurveyResultFile';
import { saveCatalogRequest } from './saveCatalogRequest';
import { saveSurveyBaseRequest } from './saveSurveyBaseRequest';
import { sharetoSurveyRequest } from './sharetoSurveyRequest';
import { surveyAutoReminder } from './surveyAutoReminder';
import { surveyManualReminder } from './surveyManualReminder';
import { surveyReplyDueDateChange } from './surveyReplyDueDateChange';
import { updateProxyAttachments } from './updateProxyAttachments';
import { updateReceiverSystemNotificationSetting } from './updateReceiverSystemNotificationSetting';
import { updateSurveyRequestRemarks } from './updateSurveyRequestRemarks';
import { updateSystemNotificationSetting } from './updateSystemNotificationSetting';
import { updateSurveyRequestSystemNotificationSetting } from './updateSurveyRequestSystemNotificationSetting';
import { execSurveyOpenRequest } from './execSurveyOpenRequest';
import { createSurveyRequestWorkForm } from './createSurveyRequestWorkForm';
import { updateSurveyRequestWorkForm } from './updateSurveyRequestWorkForm';
import { createSurveyResultWorkForm } from './createSurveyResultWorkForm';
import { updateSurveyResultWorkForm } from './updateSurveyResultWorkForm';
import { deleteSurveyRequestWorkForm } from './deleteSurveyRequestWorkForm';
import { deleteSurveyResultWorkForm } from './deleteSurveyResultWorkForm';
import { updateSurveyReception } from './updateSurveyReception';
import { createResultFormShareManagement } from './createResultFormShareManagement';
import { updateResultFormShareManagement } from './updateResultFormShareManagement';
import { importProxySurveyResultWorkForms } from './importProxySurveyResultWorkForms';
import { importSurveyResultWorkForms } from './importSurveyResultWorkForms';
import { updateFlagInSurveyRequest } from './updateFlagInSurveyRequest';
import { deleteFlagInSurveyRequest } from './deleteFlagInSurveyRequest';

// actionName と function のマッピング
export const surveyCallMap = {
  delete: deleteCatalogRequest,
  saveCatalog: saveCatalogRequest,
  saveSurveyBaseRequest: saveSurveyBaseRequest,
  sharetoSurveyRequest: sharetoSurveyRequest,
  approvalRequestSurveyBaseRequest: approvalRequestSurveyBaseRequest,
  surveyAutoReminder: surveyAutoReminder,
  surveyManualReminder: surveyManualReminder,
  changeSurveyRequestDeadline: surveyReplyDueDateChange,
  deleteSurveyRequest: deleteSurveyBaseRequest,
  discardSurveyRequest: discardSurveyRequest,
  completeSurveyRequest: completeSurveyRequest,
  surveyReplyDueDateChangeRequest: createSurveyReplyDueDateChangeRequest,
  updateSystemNotificationSetting: updateSystemNotificationSetting,
  updateSurveyRequestSystemNotificationSetting:
    updateSurveyRequestSystemNotificationSetting,
  updateReceiverSystemNotificationSetting:
    updateReceiverSystemNotificationSetting,
  updateSurveyRequestRemarks: updateSurveyRequestRemarks,
  exportedSurveyResultFile: exportedSurveyResultFile,
  importSurveyResultWorkForms: importSurveyResultWorkForms,
  importProxySurveyResultWorkForms: importProxySurveyResultWorkForms,
  updateProxyAttachments: updateProxyAttachments,
  createSurveyResultChange: createSurveyResultChange,
  execSurveyResult: execSurveyResult,
  execSurveyApproveResult: execSurveyApproveResultRequest,
  execSurveyOpen: execSurveyOpenRequest,
  createSurveyRequestWorkForm: createSurveyRequestWorkForm,
  updateSurveyRequestWorkForm: updateSurveyRequestWorkForm,
  createSurveyResultWorkForm: createSurveyResultWorkForm,
  updateSurveyResultWorkForm: updateSurveyResultWorkForm,
  deleteSurveyRequestWorkForm: deleteSurveyRequestWorkForm,
  deleteSurveyResultWorkForm: deleteSurveyResultWorkForm,
  updateSurveyReception: updateSurveyReception,
  updateFlagInSurveyRequest: updateFlagInSurveyRequest,
  deleteFlagInSurveyRequest: deleteFlagInSurveyRequest,
  createResultFormShareManagement: createResultFormShareManagement,
  updateResultFormShareManagement: updateResultFormShareManagement,
};
