import { useState, useMemo, useEffect } from 'react';
import { useIntl } from 'react-intl';
import {
  GetMessageWithIntl,
  MessageProps,
} from '~/shared/components/parts/Message/Message';
import {
  ModalDialogComponent,
  ModalDialogComponentProps,
} from '~/shared/components/ui/ModalDialog/ModalDialog';
import {
  DataFilterbox,
  DataFilterboxItem,
  Textbox,
} from '~/shared/components/ui';
import { CaptionButton } from '~/shared/components/ui/Button/CaptionButton';
import './base.css';
import { getOrganizationFilterBoxItems } from '~/shared/utils';

export interface ContactAttributeSettingDialogMessageOption {
  headerLabelId: MessageProps;
  codeLabelId?: MessageProps;
}

export interface ContactAttributeSettingDialogInputOption {
  id?: string;
  code?: string;
  displayName?: string;
  remarks?: string;
  organization?: boolean;
}

export interface ContactAttributeSettingResult {
  id?: string;
  code: string;
  displayName: string;
  remarks: string;
}

export interface ContactAttributeSettingDialogProps {
  isOpen: boolean;
  messageOption: ContactAttributeSettingDialogMessageOption;
  inputOption?: ContactAttributeSettingDialogInputOption;
  onDecision: (result: ContactAttributeSettingResult) => void;
  onCancel: () => void;
}

export const ContactAttributeSettingDialog = ({
  isOpen,
  messageOption,
  inputOption,
  onDecision,
  onCancel,
}: ContactAttributeSettingDialogProps) => {
  const intl = useIntl();
  const [code, setCode] = useState<string>('');
  const [displayName, setDisplayName] = useState<string>('');
  const [remarks, setRemarks] = useState<string>('');
  const [organization, setOrganization] = useState<DataFilterboxItem[]>([]);
  const [organizations, setOrganizations] = useState<DataFilterboxItem[]>([]);

  const clearDisplay = () => {
    setCode('');
    setDisplayName('');
    setRemarks('');
  };

  const handleCancel = () => {
    onCancel();
    clearDisplay();
  };

  const handleDecision = () => {
    const result: ContactAttributeSettingResult = {
      id: inputOption?.id,
      code: inputOption?.organization
        ? organization.length > 0
          ? organization[0].value
          : ''
        : code,
      displayName: displayName,
      remarks: remarks,
    };

    onDecision(result);
  };

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    clearDisplay();
    setCode(inputOption?.code ?? '');
    setDisplayName(inputOption?.displayName ?? '');
    setRemarks(inputOption?.remarks ?? '');
    (async () => {
      const filterBoxItem = await getOrganizationFilterBoxItems(true);
      setOrganizations(filterBoxItem);
      setOrganization(
        filterBoxItem.filter((v) => v.value === inputOption?.code)
      );
    })();
    // isOpen変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const codeLabelId = useMemo(() => {
    const msgId: string[] = [];
    if (messageOption?.codeLabelId) {
      msgId.push(messageOption.codeLabelId.prefixId ?? '');
      msgId.push(messageOption.codeLabelId.viewId ?? '');
      msgId.push(messageOption.codeLabelId.id ?? '');
    } else {
      msgId.push('ContactAttributeSettingDialog.code');
    }
    return msgId.filter((v) => !!v).join('.');
  }, [messageOption?.codeLabelId]);

  const elements = (
    <div className="contact-request-dialog">
      <div className="detail-area">
        <div className="input-line">
          <div className="item-group-100">
            {inputOption?.organization ? (
              <div className="w-100">
                <DataFilterbox
                  name="organization"
                  labelId={codeLabelId}
                  data={organizations}
                  columns={['organization']}
                  value={organization}
                  onChangeState={setOrganization}
                />
              </div>
            ) : (
              <div className="w-50">
                <div className="input">
                  <Textbox
                    name="code"
                    labelId={codeLabelId}
                    type="text"
                    columns={['code']}
                    value={code}
                    onChangeState={setCode}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              <div className="input">
                <Textbox
                  name="displayName"
                  labelId="ContactAttributeSettingDialog.displayName"
                  type="text"
                  columns={['displayName']}
                  value={displayName}
                  onChangeState={setDisplayName}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              <div className="input">
                <Textbox
                  name="remarks"
                  labelId="ContactAttributeSettingDialog.remarks"
                  type="text"
                  columns={['remarks']}
                  value={remarks}
                  onChangeState={setRemarks}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="button-area">
          <CaptionButton
            name="cancelBtn"
            buttonType="cancel"
            className="button"
            caption={GetMessageWithIntl(intl, { id: 'cancel' })}
            onClick={() => handleCancel()}
          />
          <CaptionButton
            name="saveBtn"
            buttonType="basic"
            className="button"
            caption={GetMessageWithIntl(intl, { id: 'save' })}
            onClick={handleDecision}
          />
        </div>
      </div>
    </div>
  );

  const openModalProps: ModalDialogComponentProps = {
    cancel: () => {
      handleCancel();
    },
    send: () => {},
    modalIsOpen: isOpen,
    headerLabelId: messageOption.headerLabelId,
    messageLabelId: {},
    elements,
  };

  return <ModalDialogComponent {...openModalProps} />;
};
