import { useMemo, CSSProperties } from 'react';
import { Property } from '~/shared/services';
import { GetMessageComponent } from '~/shared/components/parts/Message/Message';
import {
  ZipCode as ZipCodeComponent,
  ZipCodeValidateOption,
} from '~/shared/components/parts/ZipCode/ZipCode';
import { getProperty } from '../common';

export interface ZipCodeProps {
  name: string;
  className?: string;
  value?: string;
  validator?: (v: string) => string[];
  validateOption?: ZipCodeValidateOption;
  disabled?: boolean;
  style?: CSSProperties;
  onChangeState?: (arg: string) => void;
  labelId?: string;
  properties?: Property[];
  columns?: string[];
}

export function ZipCode(props: ZipCodeProps) {
  //コンポーネントに渡すOnChangeState
  // const { onChangeState } = props;
  const [componentStyle, viewStyle, _disabled] = useMemo<
    [CSSProperties, CSSProperties, boolean]
  >(() => {
    return getProperty(props.name, props.columns, props.properties);
  }, [props.properties, props.columns, props.name]);
  const disabled = _disabled ? _disabled : props.disabled ?? false;

  // コンポーネントに送るOnchangeState
  // 画面から渡したデータが表示されないケースを解決するための修正
  // const onChange = useCallback(
  //   (v: string) => {
  //     onChangeState && onChangeState(v);
  //   },
  //   [onChangeState]
  // );

  return (
    <div style={viewStyle}>
      <span className={props.labelId !== '' ? 'caption' : 'caption no-name'}>
        {props.labelId && (
          <div className={props.validateOption?.required ? 'required ' : ''}>
            <div
              className={`topping-label ${
                disabled ? 'disabled-topping-label' : ''
              }`}
            >
              {props.labelId !== '' ? (
                <GetMessageComponent id={props.labelId} />
              ) : (
                ''
              )}
            </div>
          </div>
        )}
      </span>
      <ZipCodeComponent
        name={props.name}
        className={props.className}
        value={props.value}
        validator={props.validator}
        validateOption={props.validateOption}
        disabled={disabled}
        style={componentStyle}
        onChangeState={props.onChangeState}
      ></ZipCodeComponent>
    </div>
  );
}
