export const AdminDebugMenu = [
  {
    label: '旧Adminメニュー',
    children: [
      {
        label: 'Top',
        pathname: '/',
      },
      {
        label: 'テナント管理',
        pathname: '/tenant',
      },
    ],
  },
  {
    label: 'テナント',
    children: [
      {
        label: 'テナント一覧',
        pathname: '/tenant/list',
        viewId: 'OPT_TENANT_LIST',
      },
    ],
  },
  {
    label: 'お知らせ',
    children: [
      {
        label: 'お知らせ一覧',
        pathname: '/notice/notice-list',
        viewId: 'OPT_NOTICE_LIST',
      },
    ],
  },
  // debug及びダミーAPI用と実際のAPI情報との区切り表示
  {
    label: '--------',
  },
];
