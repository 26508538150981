import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface ImportProxySurveyResultWorkFormsRequest {
  actionName: 'importProxySurveyResultWorkForms';
  request: mtechnavi.api.survey.IImportProxySurveyResultWorkFormsRequest;
}
export const importProxySurveyResultWorkForms = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.survey.IImportProxySurveyResultWorkFormsRequest
) => {
  return await worker.invokeListRequest<
    mtechnavi.api.survey.IImportProxySurveyResultWorkFormsRequest,
    mtechnavi.api.survey.IImportProxySurveyResultWorkFormsResponse
  >(
    'mtechnavi.api.survey.SurveySenderService/ImportProxySurveyResultWorkForms',
    req
  );
};
