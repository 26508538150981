import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface BulkWriteFormValuesRequest {
  actionName: 'saveFormValue';
  request: BulkWriteFormValuesRequestParam;
}

export type BulkWriteFormValuesRequestParam =
  mtechnavi.api.form.IBulkWriteFormValuesRequest;

// FormValue の Upsert
export const bulkWriteFormValues = (
  worker: AppSaveWorker,
  req: BulkWriteFormValuesRequestParam
) => {
  const methodName = 'mtechnavi.api.form.FormService/BulkWriteFormValues';

  return worker.invokeListRequest<
    mtechnavi.api.form.IBulkWriteFormValuesRequest,
    mtechnavi.api.form.IFormValue
  >(methodName, req);
};
