import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface CreateSurveyRequestWorkFormRequest {
  actionName: 'createSurveyRequestWorkForm';
  request: mtechnavi.api.survey.ICreateSurveyRequestWorkFormRequest;
}

// RequestWorkForm の登録
export const createSurveyRequestWorkForm = (
  worker: AppSaveWorker,
  req: mtechnavi.api.survey.ICreateSurveyRequestWorkFormRequest
) => {
  const methodName =
    'mtechnavi.api.survey.SurveySenderService/CreateSurveyRequestWorkForm';

  return worker.invokeListRequest<
    mtechnavi.api.survey.ICreateSurveyRequestWorkFormRequest,
    mtechnavi.api.survey.SurveyRequestWorkForm
  >(methodName, req);
};
