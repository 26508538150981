import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  GetMessageWithIntl,
  MessageProps,
  ModalDialogComponent,
  ModalDialogComponentProps,
  error,
} from '~/shared/components';
import { CaptionButton } from '~/shared/components/ui/Button';
import {
  CategorizedBranchData,
  CategorizedCheckList,
  CategorizedItem,
  CheckItem,
} from '~/shared/components/ui/CategorizedCheckList';
import { mtechnavi } from '~/shared/libs/clientsdk';
import './base.css';
import './OrganizationTreeSearchDialog.css';

interface OrganizationTreeSearchDialogMessageOption {
  headerLabelId: MessageProps;
}
export interface OrganizationTreeSearchDialogProps {
  organizationList: mtechnavi.api.company.IOrganization[]; // 選択肢の組織リスト
  checkedIds?: string[]; // 初期チェック状態とする組織ID
  multiple?: boolean; // 複数選択するかどうか
  isOpen: boolean;
  messageOption: OrganizationTreeSearchDialogMessageOption;
  onDecision: (checkedIds: string[]) => void;
  onCancel: () => void;
}
export const OrganizationTreeSearchDialog = ({
  organizationList,
  checkedIds,
  multiple,
  isOpen,
  messageOption,
  onDecision,
  onCancel,
}: OrganizationTreeSearchDialogProps) => {
  const intl = useIntl();
  const [checkedItems, setCheckedItems] = useState<CheckItem[]>([]);

  const listData = useMemo((): CategorizedItem[] => {
    const categoryData = convertCategorizedBranch(
      organizationList.filter((org) => org.usable)
    );
    return categoryData;
  }, [organizationList]);

  const handleDecision = () => {
    if (checkedItems.length === 0) {
      error([GetMessageWithIntl(intl, { id: 'E0000023' })]);
      return;
    }
    if (!multiple && checkedItems.length > 1) {
      error([GetMessageWithIntl(intl, { id: 'E0000073' })]);
      return;
    }
    onDecision(checkedItems.map((item) => item.id || ''));
  };

  const handleChangeChecks = (checkedIdList: CheckItem[]) => {
    setCheckedItems(checkedIdList);
  };

  const view = (
    <div className="OrganizationTreeSearchDialog">
      <div className="detail-area">
        <CategorizedCheckList
          listData={listData}
          checkedIds={checkedIds}
          onChange={handleChangeChecks}
          inputOption={{
            isVisibleOpenAll: true,
            isVisibleCloseAll: true,
          }}
          controlOption={{
            isVerticalEdge: true,
            canBeBranchSelection: true,
            singleSelection: !multiple,
          }}
        />
      </div>
      <div className="button-area">
        <CaptionButton
          name="cancelBtn"
          buttonType="cancel"
          className="button"
          caption={GetMessageWithIntl(intl, { id: 'cancel' })}
          onClick={() => onCancel()}
        />
        <CaptionButton
          name="sendBtn"
          buttonType="basic"
          className="button"
          caption={GetMessageWithIntl(intl, { id: 'select' })}
          onClick={() => {
            handleDecision();
          }}
        />
      </div>
    </div>
  );

  const modalProps: ModalDialogComponentProps = {
    cancel: () => {
      onCancel();
    },
    send: () => {},
    modalIsOpen: isOpen,
    headerLabelId: messageOption.headerLabelId,
    messageLabelId: {},
    elements: view,
  };

  return (
    <>
      <ModalDialogComponent {...modalProps} />
    </>
  );
};

// 配列を受け取り、CategorizedItem の配列に変換する関数
export function convertCategorizedBranch(
  orgs: mtechnavi.api.company.IOrganization[]
): CategorizedItem[] {
  const itemMap = new Map<string, CategorizedItem>();
  const rootItems: CategorizedItem[] = [];

  // 出来上がりの順序に影響があるため並べ替えておく
  const sortedOrgs = orgs.sort((a, b) =>
    (a.code || '').localeCompare(b.code || '', 'ja')
  );

  // 全ての組織を CategorizedItem としてMapに格納する
  sortedOrgs.forEach((org) => {
    const item: CategorizedItem = {
      CategorizedItemId: org.organizationId || '',
      displayNameLang: {
        ja: org.displayName ? `${org.displayName}(${org.code})` : '',
      },
    };
    itemMap.set(org.organizationId!, item);
  });

  // 各組織に対して親子関係を構築する
  sortedOrgs.forEach((org) => {
    const currentItem = itemMap.get(org.organizationId!);
    if (org.parentOrganization?.organizationId) {
      const parentItem = itemMap.get(org.parentOrganization.organizationId) as
        | CategorizedBranchData
        | undefined;
      if (parentItem) {
        if (!parentItem.children) {
          parentItem.children = [];
        }
        parentItem.children.push(currentItem!);
      } else {
        // 親がMapに存在しない場合、ルートアイテムとして扱う
        rootItems.push(currentItem!);
      }
    } else {
      // 親IDが指定されていない場合、ルートアイテムとして追加
      rootItems.push(currentItem!);
    }
  });

  // ルートアイテムに親が存在しない場合のみ追加されるので、重複がない
  return rootItems;
}
