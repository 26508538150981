import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface ExportSampleWorkFormsRequest {
  actionName: 'exportSampleWorkForms';
  request: string;
}

export const exportSampleWorkForms = (worker: AppSaveWorker, req: string) => {
  const methodName =
    'mtechnavi.api.form.SampleWorkFormService/ExportSampleWorkForms';

  return worker.invokeListRequest<
    mtechnavi.api.form.IExportSampleWorkFormsRequest,
    mtechnavi.api.form.IExportSampleWorkFormsResponse
  >(methodName, { sampleWorkFormId: req });
};
