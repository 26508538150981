import { useEffect, useState, useRef } from 'react';
import { useIntl } from 'react-intl';
import './../TenantApplyRequest.css';
import { GetMessageWithIntl } from '~/shared/components';
import { ViewId } from '../../utils';
import { Textbox } from '../../components/ui';
import { IconButton } from '../../components/ui/Button';
import './Help.css';
import { QAItemComponent } from './QAItemComponent';
import { useLocation } from 'react-router-dom';
import { HelpItem, getHelpHtmlData, getHelpJson } from '../../services';

export interface ContentsProps {
  VIEW_ID?: ViewId;
}
export function Help(props: ContentsProps) {
  const intl = useIntl();
  const { pathname } = useLocation();
  const VIEW_ID = props.VIEW_ID || 'HELP';
  const viewName = GetMessageWithIntl(intl, {
    id: '',
    viewId: VIEW_ID,
    prefixId: 'HEADER_TITLE',
  });
  const mainRef = useRef<HTMLDivElement>(null);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [currentHelp, setCurrentHelp] = useState<HelpItem[]>([]);
  const [categoryName, setCategoryName] = useState('');
  const [helpList, setHelpList] = useState<HelpItem[]>([]);
  const [displayContents, setDisplayContents] = useState<
    string | JSX.Element | JSX.Element[]
  >();

  useEffect(() => {
    (async () => {
      const result = await getHelpJson();
      setHelpList(result);
    })();
  }, []);

  useEffect(() => {
    // 現在のURLから画面に合わせたQAリストを絞り込み
    const paths = pathname.split('/');
    let pathTag = '';
    if (paths.length > 2) {
      pathTag = paths.slice(2).join('');
    }
    let result = helpList.slice(0, 3);
    if (pathTag) {
      result = result.filter((v) =>
        v.tag.some((tag) => pathTag.indexOf(tag) >= 0)
      );
    }
    setCurrentHelp(result.length > 0 ? result : helpList.slice(0, 3));

    // カテゴリー名をセット
    const category = categoryNameMap.find((v) => pathTag.indexOf(v.tag) >= 0);
    setCategoryName(category ? category.categoryName : '');

    let path = '';
    if (paths.length > 2) {
      path = paths.slice(2).join('/');
    }
    if (path && isHtmlID()) {
      (async () => {
        const result = await getHelpHtmlData(path);
        setDisplayContents(result);
      })();
    }
  }, [helpList, pathname]);

  const handleSearch = () => {
    const answerItems: HelpItem[] = [];
    console.log(searchKeyword);
    helpList.forEach((v) => {
      if (
        v.title.indexOf(searchKeyword) >= 0 ||
        v.answer.indexOf(searchKeyword) >= 0
      ) {
        answerItems.push(v);
      }
    });
    console.log(helpList);
    console.log(answerItems);
    setCurrentHelp(answerItems);
  };

  const isHtmlID = () => {
    const paths = pathname.split('/');
    let path = '';
    if (paths.length > 2) {
      path = paths.slice(2).join('/');
    }
    return helpList.some((v) => v.htmlId === path);
  };

  const HelpHomeComponent = () => {
    return (
      <div className="help-home-view">
        <div className="search-area">
          <Textbox
            name="help"
            className="search-text"
            type="text"
            labelId="Help.searchText"
            value={searchKeyword}
            columns={['help']}
            onChangeState={setSearchKeyword}
          />
          <IconButton
            name="search"
            className="search-btn"
            iconType="search"
            onClick={handleSearch}
          />
        </div>
        <div className="help-list">
          <div className="category-name">{categoryName}</div>
          <hr />
          {currentHelp.map((v) => {
            return <QAItemComponent key={v.id} qaItem={v} />;
          })}
        </div>
      </div>
    );
  };

  return (
    <>
      <div id="back-ground-color-theme"></div>
      <article className="container">
        <header className="Header">
          <div>
            <div className="nav-btn"></div>
          </div>
          <div className="symbol">M-Tech Navi{` ${viewName}`}</div>
          <div className="header-btns"></div>
        </header>

        <div className="content">
          <div className="main" ref={mainRef}>
            <div className="TenantApplyRequestArea">
              {isHtmlID() && displayContents}
              {!isHtmlID() && HelpHomeComponent()}
            </div>
          </div>
        </div>
      </article>
    </>
  );
}

const categoryNameMap = [
  {
    tag: 'account',
    categoryName: 'アカウント管理',
  },
  {
    tag: 'survey',
    categoryName: '調査依頼',
  },
  {
    tag: 'worktask',
    categoryName: '指図案件',
  },
  {
    tag: 'estimate',
    categoryName: '見積管理',
  },
  {
    tag: 'programoption',
    categoryName: '名称マスタ',
  },
];
