import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface ExecInactivateBusinessUnit {
  actionName: 'execInactivateBusinessUnit';
  request: mtechnavi.api.company.IInactivateBusinessUnitRequest;
}

export const execInactivateBusinessUnit = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.company.IInactivateBusinessUnitRequest
) => {
  const execMethodName =
    'mtechnavi.api.company.BusinessUnitManagementService/InactivateBusinessUnit';

  return await worker.invokeListRequest(execMethodName, {
    companyId: req.companyId,
    comment: req.comment,
  });
};
