import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface ExecActivateBusinessUnit {
  actionName: 'execActivateBusinessUnit';
  request: mtechnavi.api.company.IActivateBusinessUnitRequest;
}

export const execActivateBusinessUnit = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.company.IActivateBusinessUnitRequest
) => {
  const execMethodName =
    'mtechnavi.api.company.BusinessUnitManagementService/ActivateBusinessUnit';

  return await worker.invokeListRequest(execMethodName, {
    companyId: req.companyId,
    comment: req.comment,
  });
};
