import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';
import { FullMethodName_DeleteTenantManagementLicense } from 'worker/fullMethodName/crud';

/** ライセンスの削除 */
export const deleteLicense = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.admin.IDeleteLicenseRequest
) => {
  return await worker.invokeListRequest<
    mtechnavi.api.admin.IDeleteLicenseRequest,
    mtechnavi.api.license.ILicense
  >(FullMethodName_DeleteTenantManagementLicense, req);
};
