import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface SharetoFormValueRequest {
  actionName: 'sharetoFormValue';
  request: mtechnavi.api.form.ISharetoFormValuesRequest;
}

export const sharetoFormValue = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.form.ISharetoFormValuesRequest
) => {
  return worker.invokeListRequest<
    mtechnavi.api.form.ISharetoFormValuesRequest,
    mtechnavi.api.form.SharetoFormValuesResponse
  >('mtechnavi.api.form.FormService/SharetoFormValues', req);
};
