import React, { PropsWithChildren, useEffect } from 'react';
import './CommentPaneContainer.css';
import {
  AssetInfo,
  useCommentPane,
  useCommentPaneDispatch,
} from './CommentPaneProvider';
import { FileList } from './FileList';
import { CommentPaneForum } from './CommentPaneForum';
import { ViewId } from '~/shared/utils';

interface CommentPaneContainerProps {
  fileList?: AssetInfo[];
  onChangeFile?: (assetInfo: AssetInfo) => void;
  resourceType: string;
  companyIds?: string[];
  viewId: ViewId;
}
export const CommentPaneContainer = ({
  fileList,
  onChangeFile,
  resourceType,
  children,
  companyIds,
  viewId,
}: PropsWithChildren<CommentPaneContainerProps>) => {
  const { showOption } = useCommentPane();
  const commentPaneDispatch = useCommentPaneDispatch();

  useEffect(() => {
    commentPaneDispatch({
      type: 'setFileList',
      fileList: fileList || [],
    });
  }, [commentPaneDispatch, fileList]);

  return (
    <div
      className={`CommentPaneContainer ${
        showOption.isShowCommentPane ? 'open-comment' : ''
      } ${showOption.isShowFileList ? 'open-file' : ''}`}
    >
      <FileList onChangeFile={onChangeFile} resourceType={resourceType} />
      {children}
      <CommentPaneForum companyIds={companyIds} viewId={viewId} />
    </div>
  );
};
