import { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  GetMessageWithIntl,
  LoadingIcon,
  Toast,
  error,
  success,
} from '~/shared/components';
import {
  ConfirmationDialog,
  ListView,
  ViewMenu,
  InitialFilter,
  MenuActionItem,
  Preset,
  Property,
  getAltDisplaySchema,
  getBooleanDataFormetterSchema,
} from '~/shared/components/ui';
import { useAuth } from '~/shared/contexts/AuthProvider';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { PresetItem } from '~/shared/services';
import {
  FullMethodName_ListBaseForms,
  PageState,
  ViewId,
  getPresetAndSchema,
  getWorkerExceptionMessage,
  saveLocalStorageCheckboxData,
} from '~/shared/utils';

const VIEW_ID: ViewId = 'IFM_FORM_DEFINITION_LIST';
type FormBase = mtechnavi.api.form.IBaseForm;
export const IfmFormDefinitionList = () => {
  const [isLoading, setLoading] = useState(false);
  const intl = useIntl();
  const navi = useNavigate();
  const myEmail = useAuth().user?.email ?? '';
  // 初期フィルタ設定
  const [initialFilter, setInitialFilter] = useState<InitialFilter>({
    info: [],
  });
  const [childrenPresetItem, setChildrenPresetItem] = useState<PresetItem[]>();
  const [schema, setSchema] = useState<Property[]>([]);
  const [preset, setPreset] = useState<Preset>({
    filter: {},
    propertyNames: [],
  });
  const [menuModalIsOpen, setMenuModalIsOpen] = useState(false);
  const deleteMessage = {
    id: 'C0000001',
    value: { $1: '削除' },
  };
  const [isReload, setReload] = useState(false);
  const formBaseList = useRef<FormBase[]>([]);
  const deleteIds = useRef<string[]>([]);
  const toastSuccess = GetMessageWithIntl(intl, { id: 'I0000001' });

  useEffect(() => {
    (async () => {
      try {
        const filter: InitialFilter = {
          info: [],
        };
        const userRepresentativeOrganization =
          await window.App.services.ui.getUserRepresentativeOrganization(
            myEmail
          );
        userRepresentativeOrganization?.organizationId &&
          filter.info.push({
            targetKey: 'formProperties.managementOrganization.organizationId',
            targetValue: userRepresentativeOrganization.organizationId,
          });

        setInitialFilter(filter);

        // スキーマ情報、preset関係の情報を取得
        const { childrenPresetItem, schemas, preset } =
          await getPresetAndSchema(VIEW_ID, [FullMethodName_ListBaseForms]);

        // ja変換
        const jaColumn: string[] = [
          'formProperties.formType1.displayNameLang',
          'formProperties.formType2.displayNameLang',
          'formProperties.formType3.displayNameLang',
        ];
        const formatterSch = getAltDisplaySchema(schemas[0], jaColumn, 'ja');
        const booleanColumn = ['usable'];
        const booleanSch = getBooleanDataFormetterSchema(
          formatterSch,
          booleanColumn
        );

        // 取得した情報をセット
        setChildrenPresetItem(childrenPresetItem);
        setSchema(booleanSch);
        setPreset(preset);
      } catch (err) {
        error(getWorkerExceptionMessage(intl, err));
        throw err;
      }
    })();
    // 初回起動時のみ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intl]);

  // メニューの「登録」処理
  const handleMenuInput = (prop?: string[]) => {
    const state: PageState = {
      ids: prop ?? [],
      sourceViewId: VIEW_ID,
      actionType: 'add',
      baseViewOption: { sourceViewId: VIEW_ID },
    };
    navi('/form/ifm-form-definition-input', { state });
  };

  // メニューの「確認」処理
  const handleMenuConfirm = (prop?: string[]) => {
    const state: PageState = {
      ids: prop ?? [],
      sourceViewId: VIEW_ID,
      baseViewOption: { sourceViewId: VIEW_ID },
    };
    navi('/form/ifm-form-definition-confirmation', { state });
  };

  // メニューの「コピー」処理
  const handleMenuCopy = (prop?: string[]) => {
    if (prop && prop.length > 1) {
      error([GetMessageWithIntl(intl, { id: 'E0000073' })]);
      return;
    }
    const state: PageState = {
      ids: prop ?? [],
      sourceViewId: VIEW_ID,
      actionType: 'copy',
      baseViewOption: { sourceViewId: VIEW_ID },
    };
    navi('/form/ifm-form-definition-input', { state });
  };

  // 2024年1月以降対応
  // const handleMenuOutput = (result: OutputDialogOutput) => {
  //   if (result.outputVolume === 'selectedOnly') {
  //     error([GetMessageWithIntl(intl, { id: 'E0000023' })]);
  //     return;
  //   }
  //   console.log('handleMenuOutput', result);
  //   setOpenOutputDialog(false);
  //   //後日API作成後実装
  // };

  const clearCheckBox = useCallback(() => {
    saveLocalStorageCheckboxData(VIEW_ID, [], myEmail);
  }, [myEmail]);

  const handleMenuDelete = async () => {
    setLoading(true);
    setReload(false);
    try {
      await window.App.services.ui.worker.apiCall({
        actionName: 'deleteBaseFormWithRelatedItems',
        request: deleteIds.current,
      });
      success([toastSuccess]);
      clearCheckBox();
      setReload(true);
    } catch (err) {
      getWorkerExceptionMessage(intl, err);
    }
    setMenuModalIsOpen(false);
    setLoading(false);
  };

  const setMenuActionItem = (): MenuActionItem[] => {
    const menuActionItems: MenuActionItem[] = [];
    menuActionItems.push({
      menuActionType: 'headerIconMenu',
      menu: headerIconEvent(),
      maxMenuColumn: 5,
    });
    menuActionItems.push({
      menuActionType: 'listIconMenu',
      menu: listIconEvent(),
    });
    return menuActionItems;
  };

  const headerIconEvent = (): ViewMenu[] => {
    const menuItems: ViewMenu[] = [];
    // 追加
    menuItems.push({
      name: 'noteadd',
      func: () => handleMenuInput(),
    });
    // 追加
    menuItems.push({
      name: 'copy',
      func: (v?: string[]) => handleMenuCopy(v),
    });
    // 確認
    menuItems.push({
      name: 'description',
      func: (v?: string[]) => handleMenuConfirm(v),
    });
    // ダウンロード 2024年1月以降対応
    // menuItems.push({
    //   name: 'export',
    //   func: (v?: string[]) => {
    //     downloads.current = v as string[];
    //     setOpenOutputDialog(true);
    //   },
    // });
    // 削除
    menuItems.push({
      name: 'delete',
      func: (v?: string[]) => {
        deleteIds.current = v as string[];
        setMenuModalIsOpen(true);
      },
    });

    return menuItems;
  };

  const listIconEvent = (): ViewMenu[] => {
    const menuItems: ViewMenu[] = [];
    // 確認
    menuItems.push({
      name: 'description',
      func: (v?: string[]) => handleMenuConfirm(v),
    });
    return menuItems;
  };

  const onOriginalItem = (items: unknown) => {
    formBaseList.current = items as unknown as FormBase[];
  };

  return (
    <>
      <Container viewId={VIEW_ID}>
        <div className="formDefinitionList">
          <ListView
            pageInfo={{
              schema: schema,
              preset: preset,
              menuItem: setMenuActionItem(),
              menuTarget: 'baseFormId',
              headerTitle: { viewId: VIEW_ID },
              presetItems: childrenPresetItem,
              listSkipType: {
                isTotal: true,
                isOutput: true,
                isListActionMenu: true,
              },
            }}
            isReload={isReload}
            fullMethodName={FullMethodName_ListBaseForms}
            stateOption={{
              onOriginalItemState: onOriginalItem,
            }}
            filterItemOption={{
              isRequestBodyFilter: true,
              initialFilterItems: initialFilter,
            }}
          />
          <ConfirmationDialog
            isOpen={menuModalIsOpen}
            viewMessage={deleteMessage}
            messageLabelId={{ prefixId: 'DIALOG_MESSAGE', viewId: VIEW_ID }}
            onDecision={() => {
              handleMenuDelete();
            }}
            onCancel={() => setMenuModalIsOpen(false)}
          />
          {/* 2024年1月以降対応 */}
          {/* <OutputDialog
            isOpen={isOpenOutputDialog}
            type="form"
            onDecision={(v) => handleMenuOutput(v)}
            onCancel={() => {
              setOpenOutputDialog(false);
            }}
          /> */}
        </div>
        <Toast />
      </Container>
      {isLoading && <LoadingIcon />}
    </>
  );
};
