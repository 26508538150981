import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface CompleteEstimateResultRequest {
  actionName: 'completeEstimateResult';
  request: string;
}

export const completeEstimateResult = async (
  worker: AppSaveWorker,
  req: string
) => {
  const fullMethodName =
    'mtechnavi.api.estimation.EstimateReceiverService/CompleteEstimateResult';

  await worker.invokeListRequest<
    mtechnavi.api.estimation.ICompleteEstimateResultRequest,
    void
  >(fullMethodName, {
    estimateResultId: req,
  });
};
