import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface SaveBusinessUnitPublicDocument {
  actionName: 'saveBusinessUnitPublicDocument';
  request: mtechnavi.api.company.IBusinessUnitPublicDocument;
}

export const saveBusinessUnitPublicDocument = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.company.IBusinessUnitPublicDocument
) => {
  const createMethodName =
    'mtechnavi.api.company.BusinessUnitManagementService/CreateBusinessUnitPublicDocument';
  const updateMethodName =
    'mtechnavi.api.company.BusinessUnitManagementService/UpdateBusinessUnitPublicDocument';

  if (req.businessUnitPublicDocumentId) {
    const result = await worker.invokeListRequest(updateMethodName, {
      businessUnitPublicDocument: req,
    });
    return result;
  } else {
    const result = await worker.invokeListRequest(createMethodName, {
      businessUnitPublicDocument: req,
    });
    return result;
  }
};
