import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface CreateSurveyResultChangeRequest {
  actionName: 'createSurveyResultChange';
  request: CreateSurveyResultChangeParam;
}

interface CreateSurveyResultChangeParam {
  surveyRequestId: string;
  surveyResultId: string;
  comment: string;
}

// 修正依頼
export const createSurveyResultChange = async (
  worker: AppSaveWorker,
  req: CreateSurveyResultChangeParam
) => {
  const FullMethodName_CreateSurveyReplyDueDateChangeResult =
    'mtechnavi.api.survey.SurveySenderService/CreateSurveyResultChangeRequest';
  const FullMethodName_SharetoSurveyReplyDueDateChangeResult =
    'mtechnavi.api.survey.SurveySenderService/SharetoSurveyResultChangeRequest';

  const request: mtechnavi.api.survey.ISurveyResultChangeRequest = {
    surveyRequestId: req.surveyRequestId,
    surveyResultId: req.surveyResultId,
    comment: req.comment,
  };
  const results = await worker.invokeListRequest<
    mtechnavi.api.survey.ICreateSurveyResultChangeRequestRequest,
    mtechnavi.api.survey.SurveyResultChangeRequest
  >(FullMethodName_CreateSurveyReplyDueDateChangeResult, {
    surveyResultChangeRequest: request,
  });
  if (results && results.length > 0) {
    await worker.invokeListRequest<
      mtechnavi.api.survey.ISharetoSurveyResultChangeRequestRequest,
      mtechnavi.api.survey.SharetoSurveyResultChangeRequestResponse
    >(FullMethodName_SharetoSurveyReplyDueDateChangeResult, {
      surveyResultChangeRequest: results[0],
    });
  }
};
