import React, { CSSProperties, useMemo } from 'react';
import { Property } from '~/shared/services';
import {
  IconButton as IconButtonComponent,
  ButtonType,
} from '~/shared/components/parts/Button/IconButton';
import { GenerallyIconType } from '~/shared/components/parts/Button/GenerallyIcons';
import { getButtonProperties } from './utils';
import { useTooltip } from '../../parts/Tooltip';

export interface IconButtonProps {
  name: string;
  iconType: GenerallyIconType;
  buttonType?: ButtonType;
  className?: string;
  onClick: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled?: boolean;
  caption?: string;
  properties?: Property[];
  tabIndex?: number;
}

export const IconButton = (props: IconButtonProps) => {
  return (
    <>
      <IconButtonContent {...props} />
    </>
  );
};

const IconButtonContent = (props: IconButtonProps) => {
  const [buttonStyle, disabled] = useMemo<[CSSProperties, boolean]>(() => {
    return getButtonProperties(props.properties, props.disabled);
  }, [props.properties, props.disabled]);

  // ツールチップ
  const { showTooltip, hideTooltip } = useTooltip();

  return (
    <>
      <IconButtonComponent
        name={props.name}
        className={props.className}
        iconType={props.iconType}
        buttonType={props.buttonType}
        style={buttonStyle}
        onClick={props.onClick}
        disabled={disabled}
        caption={props.caption}
        onMouseEnter={
          props.caption
            ? (e) => showTooltip(props.caption || '', e.currentTarget)
            : undefined
        }
        onMouseLeave={hideTooltip}
        tabIndex={props.tabIndex}
      />
    </>
  );
};
