import { useState } from 'react';
import { Checkbox } from '~/shared/components';
import { CaptionButton } from '~/shared/components/ui/Button';
import {
  OrganizationMemberDialog,
  OrganizationMemberDialogMode,
} from '~/shared/components/ui/Dialog/OrganizationMemberDialog';
import { mtechnavi } from '~/shared/libs/clientsdk';

export const UIOrganizationMemberDialogs = () => {
  const [result, setResult] = useState('');
  const [isShowDialog, setIsShowDialog] = useState(false);
  const [isUseLevel, setUseLevel] = useState(false);
  const [mode, setMode] = useState<OrganizationMemberDialogMode>('edit');

  // 所属ユーザー
  const [memberUserList, setMemberUserList] =
    useState<mtechnavi.api.company.IOrganizationRelation[]>();

  return (
    <div className="categories">
      <h3 id="dialog">所属アカウント編集ダイアログ</h3>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setResult('');
                setIsShowDialog(true);
              }}
            />
            <Checkbox
              name="isUseLevel"
              className="group"
              items={[
                {
                  value: '1',
                  displayName: 'Level 指定あり',
                },
              ]}
              value={isUseLevel ? '1' : ''}
              onChangeState={(checkedValues) =>
                setUseLevel(checkedValues.includes('1'))
              }
            />
            <Checkbox
              name="mode"
              className="group"
              items={[
                {
                  value: '1',
                  displayName: '確認モード',
                },
              ]}
              value={mode !== 'edit' ? '1' : ''}
              onChangeState={(checkedValues) =>
                setMode(checkedValues.includes('1') ? 'show' : 'edit')
              }
            />
            {/* 組織メンバー編集ダイアログ */}
            <OrganizationMemberDialog
              key={isShowDialog ? '1' : '0'}
              isOpen={isShowDialog}
              mode={mode}
              isProcurement={isUseLevel}
              inputOption={{
                organizations: [
                  { organizationId: '1', displayName: 'ルート組織' },
                  { organizationId: '1-1', displayName: 'デモ部門' },
                  { organizationId: '1-1-1', displayName: 'サンプル課' },
                ],
                memberUserList,
              }}
              messageOption={{
                headerLabelId: {
                  prefixId: 'DIALOG_TITLE',
                  id: 'MemberEditDialog',
                },
              }}
              onDecision={(result) => {
                setMemberUserList(result);
                setResult(JSON.stringify(result, null, 2));
                setIsShowDialog(false);
              }}
              onCancel={() => setIsShowDialog(false)}
            />
          </div>
          <div className="w-66">
            <pre className="output">{result}</pre>
          </div>
        </div>
      </div>
    </div>
  );
};
