import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface DeleteSampleWorkFormRequest {
  actionName: 'deleteSampleWorkForm';
  request: mtechnavi.api.form.ISampleWorkForm[];
}

export type DeleteSampleWorkFormRequestParam =
  mtechnavi.api.form.IDeleteSampleWorkFormRequest;

// FormSample の単純な削除
export const deleteSampleWorkForm = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.form.ISampleWorkForm[]
) => {
  const methodName =
    'mtechnavi.api.form.SampleWorkFormService/DeleteSampleWorkForm';

  const result = req.map((item) => {
    const target = item as mtechnavi.api.form.ISampleWorkForm;
    return worker.invokeListRequest<DeleteSampleWorkFormRequestParam, void>(
      methodName,
      { sampleWorkForm: target }
    );
  });

  await Promise.all(result);
};
