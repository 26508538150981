import { useMemo, CSSProperties, useCallback } from 'react';
import { Property } from '~/shared/services';
import { GetMessageComponent } from '~/shared/components/parts/Message/Message';
import {
  Tel as TelComponent,
  TelValidateOption,
} from '~/shared/components/parts/Tel/Tel';
import { getProperty } from '../common';

export interface TelProps {
  name: string;
  className?: string;
  value?: string;
  validator?: (v: string) => string[];
  validateOption?: TelValidateOption;
  disabled?: boolean;
  style?: CSSProperties;
  onChangeState?: (arg: string) => void;
  labelId?: string;
  properties?: Property[];
  columns?: string[];
}

export function Tel(props: TelProps) {
  const { onChangeState } = props;
  const [componentStyle, viewStyle, _disabled] = useMemo<
    [CSSProperties, CSSProperties, boolean]
  >(() => {
    return getProperty(props.name, props.columns, props.properties);
  }, [props.properties, props.columns, props.name]);
  const disabled = _disabled ? _disabled : props.disabled ?? false;

  const onChange = useCallback(
    (v: string) => {
      onChangeState && onChangeState(v);
    },
    [onChangeState]
  );

  return (
    <div style={viewStyle}>
      <span className="caption">
        {props.labelId && (
          <div className={props.validateOption?.required ? 'required ' : ''}>
            <div
              className={`topping-label ${
                disabled ? 'disabled-topping-label' : ''
              }`}
            >
              <GetMessageComponent id={props.labelId} />
            </div>
          </div>
        )}
      </span>
      <TelComponent
        name={props.name}
        className={props.className}
        value={props.value}
        validator={props.validator}
        validateOption={props.validateOption}
        disabled={disabled}
        style={componentStyle}
        onChangeState={onChange}
      ></TelComponent>
    </div>
  );
}
