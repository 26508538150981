import { useRef, useState } from 'react';
import { MessageProps } from '../components';
import { ConfirmationDialog } from '../components/ui';

/**
 * 確認ダイアログを簡潔に扱うための hook
 */
export const useConfirmation = () => {
  const [isOpen, setOpen] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState<MessageProps>({});
  const confirmPromiseRef =
    useRef<(value: boolean | PromiseLike<boolean>) => void>();

  /**
   * 確認ダイアログ処理
   * Promise で結果を制御する。
   * 確定: true / キャンセル: false
   */
  const confirmation = (viewMessage: MessageProps): Promise<boolean> => {
    setOpen(true);
    setConfirmMessage(viewMessage);
    return new Promise((resolve) => {
      confirmPromiseRef.current = resolve;
    });
  };

  /**
   * 確認ダイアログの確認処理
   */
  const handleConfirmed = () => {
    if (confirmPromiseRef.current) {
      confirmPromiseRef.current(true);
    }
    setOpen(false);
  };

  /**
   * 確認ダイアログのキャンセル処理
   */
  const handleCancel = () => {
    if (confirmPromiseRef.current) {
      confirmPromiseRef.current(false);
    }
    setOpen(false);
  };

  return {
    confirmation,
    confirmationElement: (
      <ConfirmationDialog
        isOpen={isOpen}
        viewMessage={confirmMessage}
        onDecision={handleConfirmed}
        onCancel={handleCancel}
      />
    ),
  };
};
