import { useState } from 'react';
import {
  DataFilterboxItem,
  MainContactSettingDialog,
} from '~/shared/components/ui';
import { CaptionButton } from '~/shared/components/ui/Button';
import { mtechnavi } from '~/shared/libs/clientsdk';

export const UISampleMainContactSettingDialogs = () => {
  const [result, setResult] = useState<DataFilterboxItem | undefined | null>(
    null
  );
  const [isShowConfirm, setIsShowConfirm] = useState(false);
  const sampleData: mtechnavi.api.company.IBusinessUnitContact[] = [
    { businessUnitContactId: 'id1', displayName: 'サンプル連絡先1' },
    { businessUnitContactId: 'id2', displayName: 'サンプル連絡先2' },
    { businessUnitContactId: 'id3', displayName: 'サンプル連絡先3' },
    { businessUnitContactId: 'id4', displayName: 'サンプル連絡先4' },
    { businessUnitContactId: 'id5', displayName: 'サンプル連絡先5' },
    { businessUnitContactId: 'id6', displayName: 'サンプル連絡先6' },
    { businessUnitContactId: 'id7', displayName: 'サンプル連絡先7' },
    { businessUnitContactId: 'id8', displayName: 'サンプル連絡先8' },
  ];

  return (
    <div className="categories">
      <h3 id="dialog">代表連絡先設定ダイアログ</h3>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setResult(null);
                setIsShowConfirm(true);
              }}
            />
            <MainContactSettingDialog
              isOpen={isShowConfirm}
              inputOption={{ contactList: sampleData, initialContactId: 'id1' }}
              onDecision={(v) => {
                setResult(v.mainContact);
                setIsShowConfirm(false);
              }}
              onCancel={() => {
                setResult(null);
                setIsShowConfirm(false);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">{JSON.stringify(result, null, 2)}</pre>
          </div>
        </div>
      </div>
    </div>
  );
};
