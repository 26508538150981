import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface ExecUpdateWtTicketRemarksRequest {
  actionName: 'updateWtTicketRemarks';
  request: mtechnavi.api.worktask.IWorkTask;
}

// 作業メモ登録
export const execUpdateWtTicketRemarks = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.worktask.IWorkTask
) => {
  const getFullMethodName =
    'mtechnavi.api.worktask.WorkTaskService/GetWorkTask';
  const updateFullMethodName =
    'mtechnavi.api.worktask.WorkTaskService/UpdateWorkTask';

  const items = await worker.invokeListRequest<
    mtechnavi.api.worktask.IGetWorkTaskRequest,
    mtechnavi.api.worktask.WorkTask
  >(getFullMethodName, {
    workTaskId: req.workTaskId,
  });

  if (items && items.length > 0) {
    const item = items[0];
    const ticket: mtechnavi.api.worktask.ITicket = item.ticket ?? {};
    ticket.remarks = req.ticket?.remarks;

    return await worker.invokeListRequest<
      mtechnavi.api.worktask.IUpdateWorkTaskRequest,
      mtechnavi.api.worktask.WorkTask
    >(updateFullMethodName, {
      workTask: {
        ...item,
        ticket: ticket,
      },
    });
  }
};
