import { Tabs } from '../ui/Tabs';
import './NoticeCard.css';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { NoticeListTab } from './NoticeListTab';

export const NoticeCard = () => {
  const getInformationList = (systemName: string) => {
    const list: mtechnavi.api.admin.IInformation[] = window.App.notices.filter(
      (v) => v && v.categorySystemName === systemName
    );
    return list;
  };
  return (
    <div className="NoticeCard">
      <Tabs
        tabIndex={0}
        tabItems={[
          {
            label: 'お知らせ',
            tabContent: (
              <NoticeListTab informationList={getInformationList('B00')} />
            ),
          },
          {
            label: 'アップデート',
            tabContent: (
              <NoticeListTab informationList={getInformationList('B01')} />
            ),
          },
        ]}
      />
    </div>
  );
};
