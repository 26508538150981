import { useState } from 'react';
import { CaptionButton } from '~/shared/components/ui/Button';
import {
  OrganizationDetailDialog,
  OrganizationDetailDialogMode,
} from '~/shared/components/ui/Dialog/OrganizationDetailDialog';
import { Checkbox, Radio } from '~/shared/components';
import { mtechnavi } from '~/shared/libs/clientsdk';

const parents = [
  { organizationCode: '1', displayName: 'ルート組織' },
  { organizationCode: '1-1', displayName: 'デモ部門' },
  { organizationCode: '1-1-1', displayName: 'サンプル課' },
];
export const UIOrganizationDetailDialogs = () => {
  const [result, setResult] = useState('');
  const [org, setOrg] = useState<mtechnavi.api.company.IOrganization>();
  const [isShowDialog, setIsShowDialog] = useState(false);
  const [isUseProcurementOption, setUseProcurementOption] = useState(false);
  const [mode, setMode] = useState<OrganizationDetailDialogMode>('add');

  return (
    <div className="categories">
      <h3 id="dialog">組織編集ダイアログ</h3>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setResult('');
                setIsShowDialog(true);
              }}
            />
            <Checkbox
              name="isUseLevel"
              className="group"
              items={[
                {
                  value: '1',
                  displayName: 'NITERRA 向け',
                },
              ]}
              value={isUseProcurementOption ? '1' : ''}
              onChangeState={(checkedValues) =>
                setUseProcurementOption(checkedValues.includes('1'))
              }
            />
            <Radio
              name="mode"
              className="group"
              items={[
                { value: 'add', displayName: '追加' },
                { value: 'edit', displayName: '編集' },
                { value: 'show', displayName: '表示' },
              ]}
              value={mode}
              onChangeState={(checkedValue) =>
                setMode(checkedValue as OrganizationDetailDialogMode)
              }
            />
            {/* 組織メンバー編集ダイアログ */}
            <OrganizationDetailDialog
              key={isShowDialog ? '1' : '0'}
              isOpen={isShowDialog}
              isUseProcurementOption={isUseProcurementOption}
              mode={mode}
              parents={parents}
              inputOption={mode === 'add' ? parents[parents.length - 1] : org}
              onDecision={(result) => {
                setOrg(result);
                setResult(JSON.stringify(result, null, 2));
                setIsShowDialog(false);
              }}
              onCancel={() => setIsShowDialog(false)}
            />
          </div>
          <div className="w-66">
            <pre className="output">{result}</pre>
          </div>
        </div>
      </div>
    </div>
  );
};
