import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface UpdateReceiverSystemNotificationSettingRequest {
  actionName: 'updateReceiverSystemNotificationSetting';
  request: mtechnavi.api.worker.ISystemNotificationSetting;
}

// // システム通知先更新（受信）
export const updateReceiverSystemNotificationSetting = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.worker.ISystemNotificationSetting
) => {
  const getFullMethodName =
    'mtechnavi.api.survey.SurveyReceiverService/GetSurveyReception';
  const updateFullMethodName =
    'mtechnavi.api.survey.SurveyReceiverService/UpdateSystemNotificationUsersInSurveyReception';
  // // 依頼受信情報を取得
  const items = (await worker.invokeListRequest(getFullMethodName, {
    surveyReceptionId: req.surveyReceptionId,
  })) as mtechnavi.api.survey.SurveyReception[];
  if (items && items.length > 0) {
    const item = items[0];
    const users = item.systemNotificationUsers;

    // 追加ユーザーが渡された場合
    if (req.appendUser) {
      if (!users.find((user) => user.userId === req.appendUser?.userId)) {
        users.push(req.appendUser);
      }
      item.systemNotificationUsers = users;
    }
    // 削除ユーザーが渡された場合
    if (req.removeUser) {
      const deleteUsers = users.filter(
        (user) => user.userId !== req.removeUser?.userId
      );
      item.systemNotificationUsers = deleteUsers;
    }
    // 依頼受信情報を更新
    await worker.invokeListRequest(updateFullMethodName, {
      surveyReceptionId: item.surveyReceptionId,
      systemNotificationUsers: item.systemNotificationUsers,
      updatedAt: item.updatedAt,
    });
  }
};
