import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface UpdateEstimateManagementRequest {
  actionName: 'updateEstimateManagement';
  request: mtechnavi.api.estimation.IEstimateManagement;
}

export const updateEstimateManagement = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.estimation.IEstimateManagement
) => {
  const updateFullMethodName =
    'mtechnavi.api.estimation.EstimateSenderService/UpdateEstimateManagement';

  return worker.invokeListRequest<
    mtechnavi.api.estimation.IUpdateEstimateManagementRequest,
    mtechnavi.api.estimation.IEstimateManagement
  >(updateFullMethodName, {
    estimateManagement: req,
  });
};
