import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface ExecRequestWorkTaskRequest {
  actionName: 'activateWorkTask';
  request: mtechnavi.api.worktask.IActivateWorkTaskRequest;
}

// 指図案件作業依頼
export const execRequestWorkTask = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.worktask.IActivateWorkTaskRequest
) => {
  const fullMethodName =
    'mtechnavi.api.worktask.WorkTaskService/ActivateWorkTask';
  return await worker.invokeListRequest<
    mtechnavi.api.worktask.IActivateWorkTaskRequest,
    mtechnavi.api.worktask.IActivateWorkTaskResponse
  >(fullMethodName, req);
};
