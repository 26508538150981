import { Navigate, Route, Routes } from 'react-router-dom';
import { RequireAuth } from '~/shared/contexts/AuthProvider';
import { Home as HomePage } from './pages/Home';
import { Login as LoginPage } from '~/shared/pages/Login';
import { TenantCreateRequest as TenantCreateRequestPage } from '~/shared/pages/TenantCreateRequest';
import { TenantApplyRequest as TenantApplyRequestPage } from '~/shared/pages/TenantApplyRequest';
import { ResetPassword as ResetPasswordPage } from '~/shared/pages/ResetPassword';
import { UserSetting as UserSettingPage } from './pages/UserSetting';
import { BusinessunitConfirmation as BusinessunitConfirmationPage } from './pages/BusinessunitConfirmation';
import { BusinessunitPublicCompanyList as BusinessunitPublicCompanyListPage } from './pages/BusinessunitPublicCompanyList';
import { BlueprintBlueprintConfirmation as BlueprintBlueprintConfirmationPage } from './pages/BlueprintBlueprintConfirmation';
import { BusinessUnitManagementList as BusinessunitManagementListPage } from './pages/BusinessUnitManagementList';
import { BusinessUnitManagementConfirmation as BusinessunitManagementConfirmationPage } from './pages/BusinessUnitManagementConfirmation';
import { OcpBusinessUnitProfileConfirmation as OcpBusinessUnitProfileConfirmationPage } from './pages/OcpBusinessUnitProfileConfirmation';
import { OcpBusinessUnitProfileInput as OcpBusinessUnitProfileInputPage } from './pages/OcpBusinessUnitProfileInput';
import { OcpBusinessUnitFinancialInput as OcpBusinessUnitFinancialInputPage } from './pages/OcpBusinessUnitFinancialInput';
import { OcpBusinessUnitBranchInput as OcpBusinessUnitBranchInputPage } from './pages/OcpBusinessUnitBranchInput';
import { OcpBusinessUnitFacilitiesInput as OcpBusinessUnitFacilitiesInputPage } from './pages/OcpBusinessUnitFacilitiesInput';
import { OcpBusinessUnitCertificateInput as OcpBusinessUnitCertificateInputPage } from './pages/OcpBusinessUnitCertificateInput';
import { OcpBusinessUnitStrengthInput as OcpBusinessUnitStrengthInputPage } from './pages/OcpBusinessUnitStrengthInput';
import { OcpBusinessUnitSkillInput as OcpBusinessUnitSkillInputPage } from './pages/OcpBusinessUnitSkillInput';
import { BlueprintBlueprintList as BlueprintBlueprintListPage } from './pages/BlueprintBlueprintList';
import { UserAdmin as UserAdminPage } from './pages/UserAdmin';
import { UserGroupAdmin as UserGroupAdminPage } from './pages/UserGroupAdmin';
import { CompanyInput as CompanyInputPage } from './pages/CompanyInput';
import { CompanyConfirmation as CompanyConfirmationPage } from './pages/CompanyConfirmation';
import { CompanyComponentUnit as CompanyComponentUnitPage } from './pages/CompanyComponentUnit';
import { CompanyComponentUnitList as CompanyComponentUnitListPage } from './pages/CompanyComponentUnitList';
import { MasterStaffList as MasterStaffListPage } from './pages/MasterStaffList';
import { MasterTransactionUnitList as MasterTransactionUnitListPage } from './pages/MasterTransactionUnitList';
import { MasterTransactionUnit as MasterTransactionUnitPage } from './pages/MasterTransactionUnit';
import { MasterProgramOptionList as MasterProgramOptionListPage } from './pages/MasterProgramOptionList';
import { MasterProgramOptionInput as MasterProgramOptionInputPage } from './pages/MasterProgramOptionInput';
import { MasterProgramOptionConfirmation as MasterProgramOptionConfirmationPage } from './pages/MasterProgramOptionConfirmation';
import { MasterAttributeList as MasterAttributeListPage } from './pages/MasterAttributeList';
import { FileViewer as FileViewerPage } from './pages/FileViewer';
import { CommentableViewer as CommentableViewerPage } from './pages/CommentableViewer';
import { AssetConfirmation as AssetConfirmationPage } from './pages/AssetConfirmation';
import { AssetsUnitList as AssetsUnitListPage } from './pages/AssetsUnitList';
import { AdminAccountList as AdminAccountListPage } from './pages/AdminAccountList';
import { AdminAccountConfirmation as AdminAccountConfirmationPage } from './pages/AdminAccountConfirmation';
import { AdminAccountInput as AdminAccountInputPage } from './pages/AdminAccountInput';
import { AdminUserGroupList as AdminUserGroupListPage } from './pages/AdminUserGroupList';
import { AdminTenantSettingConfirmation as AdminTenantSettingConfirmationPage } from './pages/AdminTenantSettingConfirmation';
import { OcpOrganizationEdit } from './pages/OcpOrganizationEdit';
import { PrivacyPolicy as PrivacyPolicyPage } from '~/shared/pages/PrivacyPolicy';
import { Help as HelpPage } from '~/shared/pages/Help/Help';
import { BusinessUnitPublicDocumentsList as BusinessUnitPublicDocumentsListPage } from './pages/BusinessUnitPublicDocumentsList';

// 拡張版名称一覧
import { ExtendMasterProgramOptionList as ExtendMasterProgramOptionListPage } from './pages/ExtendMasterProgramOptionList';

export const CoreRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <RequireAuth>
            <HomePage />
          </RequireAuth>
        }
      />
      <Route
        path="/user-setting"
        element={
          <RequireAuth>
            <UserSettingPage />
          </RequireAuth>
        }
      />
      <Route path="/sign-in" element={<LoginPage />} />
      <Route path="/reset-password" element={<ResetPasswordPage />} />
      <Route path="/tenant-invite/*" element={<TenantCreateRequestPage />} />
      <Route
        path="/tenant-request-apply/*"
        element={<TenantApplyRequestPage />}
      />
      <Route
        path="/businessunit-confirmation"
        element={
          <RequireAuth>
            <BusinessunitConfirmationPage />
          </RequireAuth>
        }
      />
      <Route
        path="/businessunit-public-companies-list"
        element={
          <RequireAuth>
            <BusinessunitPublicCompanyListPage />
          </RequireAuth>
        }
      />
      <Route
        path="/businessunitmanagement-list"
        element={
          <RequireAuth>
            <BusinessunitManagementListPage />
          </RequireAuth>
        }
      />
      <Route
        path="/businessunitmanagement-confirmation/*"
        element={
          <RequireAuth>
            <BusinessunitManagementConfirmationPage />
          </RequireAuth>
        }
      />
      <Route
        path="/businessunit-public-documents-list/*"
        element={
          <RequireAuth>
            <BusinessUnitPublicDocumentsListPage />
          </RequireAuth>
        }
      />
      <Route
        path="/ocp-organization-tree-confirmation"
        element={
          <RequireAuth>
            <OcpOrganizationEdit />
          </RequireAuth>
        }
      />
      <Route
        path="/opc-businessunit-profile-confirmation/*"
        element={
          <RequireAuth>
            <OcpBusinessUnitProfileConfirmationPage />
          </RequireAuth>
        }
      />
      <Route
        path="/opc-businessunit-profile-input"
        element={
          <RequireAuth>
            <OcpBusinessUnitProfileInputPage />
          </RequireAuth>
        }
      />
      <Route
        path="/opc-businessunit-financial-input"
        element={
          <RequireAuth>
            <OcpBusinessUnitFinancialInputPage />
          </RequireAuth>
        }
      />
      <Route
        path="/opc-businessunit-branch-input"
        element={
          <RequireAuth>
            <OcpBusinessUnitBranchInputPage />
          </RequireAuth>
        }
      />
      <Route
        path="/opc-businessunit-facilities-input"
        element={
          <RequireAuth>
            <OcpBusinessUnitFacilitiesInputPage />
          </RequireAuth>
        }
      />
      <Route
        path="/opc-businessunit-certificate-input"
        element={
          <RequireAuth>
            <OcpBusinessUnitCertificateInputPage />
          </RequireAuth>
        }
      />
      <Route
        path="/opc-businessunit-strength-input"
        element={
          <RequireAuth>
            <OcpBusinessUnitStrengthInputPage />
          </RequireAuth>
        }
      />
      <Route
        path="/opc-businessunit-skill-input"
        element={
          <RequireAuth>
            <OcpBusinessUnitSkillInputPage />
          </RequireAuth>
        }
      />
      <Route
        path="/blueprint-blueprint-list"
        element={
          <RequireAuth>
            <BlueprintBlueprintListPage />
          </RequireAuth>
        }
      />
      <Route
        path="/blueprint-unit"
        element={
          <RequireAuth>
            <BlueprintBlueprintConfirmationPage />
          </RequireAuth>
        }
      />

      <Route
        path="/user-admin"
        element={
          <RequireAuth>
            <UserAdminPage />
          </RequireAuth>
        }
      />
      <Route
        path="/user-group-admin"
        element={
          <RequireAuth>
            <UserGroupAdminPage />
          </RequireAuth>
        }
      />
      <Route
        path="/company-input"
        element={
          <RequireAuth>
            <CompanyInputPage />
          </RequireAuth>
        }
      />
      <Route
        path="/company-confirmation"
        element={
          <RequireAuth>
            <CompanyConfirmationPage />
          </RequireAuth>
        }
      />
      <Route
        path="/company-component-unit"
        element={
          <RequireAuth>
            <CompanyComponentUnitPage />
          </RequireAuth>
        }
      />
      <Route
        path="/company-component-unit-list"
        element={
          <RequireAuth>
            <CompanyComponentUnitListPage />
          </RequireAuth>
        }
      />
      <Route
        path="/company-staff-list"
        element={
          <RequireAuth>
            <MasterStaffListPage />
          </RequireAuth>
        }
      />

      <Route
        path="/master-transaction-unit"
        element={
          <RequireAuth>
            <MasterTransactionUnitPage />
          </RequireAuth>
        }
      />
      <Route
        path="/master-transaction-unit-list"
        element={
          <RequireAuth>
            <MasterTransactionUnitListPage />
          </RequireAuth>
        }
      />
      {/* 拡張版名称マスタ */}
      <Route
        path="/extend-master-programoption-list"
        element={
          <RequireAuth>
            <ExtendMasterProgramOptionListPage />
          </RequireAuth>
        }
      />

      <Route
        path="/master-programoption-list"
        element={
          <RequireAuth>
            <MasterProgramOptionListPage />
          </RequireAuth>
        }
      />
      <Route
        path="/master-programoption-input"
        element={
          <RequireAuth>
            <MasterProgramOptionInputPage />
          </RequireAuth>
        }
      />
      <Route
        path="/master-programoption-confirmation"
        element={
          <RequireAuth>
            <MasterProgramOptionConfirmationPage />
          </RequireAuth>
        }
      />

      <Route
        path="/master-attribute-list"
        element={
          <RequireAuth>
            <MasterAttributeListPage />
          </RequireAuth>
        }
      />

      <Route
        path="/file-viewer"
        element={
          <RequireAuth>
            <FileViewerPage />
          </RequireAuth>
        }
      />
      <Route
        path="/commentable-viewer"
        element={
          <RequireAuth>
            <CommentableViewerPage />
          </RequireAuth>
        }
      />

      <Route
        path="/assets-unit-list"
        element={
          <RequireAuth>
            <AssetsUnitListPage />
          </RequireAuth>
        }
      />
      <Route
        path="/asset-confirmation"
        element={
          <RequireAuth>
            <AssetConfirmationPage />
          </RequireAuth>
        }
      />
      <Route
        path="/admin-account-list"
        element={
          <RequireAuth>
            <AdminAccountListPage />
          </RequireAuth>
        }
      />
      <Route
        path="/admin-account-confirmation"
        element={
          <RequireAuth>
            <AdminAccountConfirmationPage />
          </RequireAuth>
        }
      />
      <Route
        path="/admin-account-input"
        element={
          <RequireAuth>
            <AdminAccountInputPage />
          </RequireAuth>
        }
      />
      <Route
        path="/admin-user-group-list"
        element={
          <RequireAuth>
            <AdminUserGroupListPage />
          </RequireAuth>
        }
      />
      <Route
        path="/admin-tenant-setting-confirmation"
        element={
          <RequireAuth>
            <AdminTenantSettingConfirmationPage />
          </RequireAuth>
        }
      />

      <Route
        path="/privacypolicy"
        element={<PrivacyPolicyPage contentsType={'privacypolicy'} />}
      />
      <Route
        path="/term"
        element={<PrivacyPolicyPage contentsType={'term'} />}
      />
      <Route path="/help/*" element={<HelpPage />} />

      <Route path="*" element={<Navigate to={{ pathname: '/' }} />} />
    </Routes>
  );
};
