import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface UnsetForumMarkerRequest {
  actionName: 'unsetForumMarker';
  request: mtechnavi.api.forum.IUnsetMarkerRequest;
}

export const unsetForumMarker = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.forum.IUnsetMarkerRequest
) => {
  return await worker.invokeListRequest<
    mtechnavi.api.forum.IUnsetMarkerRequest,
    mtechnavi.api.forum.IUnsetMarkerResponse
  >('mtechnavi.api.forum.ForumService/UnsetMarker', {
    ...req,
    mode: 1,
    typeName: 'mtechnavi.api.forum.Comment',
  });
};
