import { useState } from 'react';
import { CaptionButton } from '~/shared/components/ui/Button/CaptionButton';
import { RadioSelectDialog } from '~/shared/components/ui/Dialog/RadioSelectDialog';

export const UISampleRadioSelectDialogs = () => {
  const [attributeSelectResult, setAttributeSelectResult] = useState('');
  const [isAttributeSelectDialogShow, setAttributeSelectDialogShow] =
    useState(false);

  return (
    <div className="categories">
      <h3 id="dialog">ラジオ選択ダイアログ</h3>
      <h4>ラジオ選択ダイアログ：属性分類選択ダイアログ（属性マスタ）</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setAttributeSelectResult('');
                setAttributeSelectDialogShow(true);
              }}
            />
            <RadioSelectDialog
              isOpen={isAttributeSelectDialogShow}
              inputOption={{
                radioButtonInfo: [
                  {
                    value: 'branch',
                    displayName: '拠点属性マスタ',
                  },
                  {
                    value: 'organization',
                    displayName: '組織属性マスタ',
                  },
                ],
              }}
              messageOption={{
                headerMessageOption: {
                  id: 'selectAttributeDialog',
                  prefixId: 'DIALOG_TITLE',
                },
                descriptionMessageOption: {
                  id: 'selectAttributeDialog',
                  prefixId: 'DIALOG_CAPTION',
                },
              }}
              onDecision={(v) => {
                setAttributeSelectResult(`出力しました。${v}`);
                setAttributeSelectDialogShow(false);
              }}
              onCancel={() => {
                setAttributeSelectResult('キャンセルしました');
                setAttributeSelectDialogShow(false);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">{attributeSelectResult}</pre>
          </div>
        </div>
      </div>
    </div>
  );
};
