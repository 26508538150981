import { useEffect, useRef, useState } from 'react';
import Long from 'long';
import { useIntl } from 'react-intl';
import { PresetItem } from '~/shared/services';
import {
  Container,
  GetMessage,
  Toast,
  LoadingIcon,
  success,
  error,
  GetMessageWithIntl,
  MessageProps,
} from '~/shared/components';
import { useNavigate, useLocation } from 'react-router-dom';
import { usePagenator } from '~/shared/components/ui/ListView/pagenator';
import { mtechnavi, sharelib } from '~/shared/libs/clientsdk';
import {
  HistoryDialog,
  CommentDialog,
  ConfirmationDialog,
  SystemNotificationSettingDialog,
  ChangeStaffDialog,
  ChangeStaffDialogResult,
  DeadlineChangeDialog,
  DeadlineChangeResult,
  FileUploadWithCommentDialog,
  FileCommentDialogFormOption,
} from '~/shared/components/ui/Dialog';
import {
  SelectConnectingDialog,
  SelectConnectingDialogOutputOption,
} from '~/shared/components/ui/Dialog/SelectConnectingDialog';
import './WtWorkTaskConfirmation.css';

import {
  PageNavigation,
  NavigationIconMenu,
} from '~/shared/components/ui/PageNavigation/PageNavigation';

import {
  Textarea,
  SimpleListView,
  Property,
  Preset,
  ListView,
  InitialFilter,
  MenuActionItem,
  ViewMenu,
  getAltDisplaySchema,
  Schema,
  getCommaTypeNumberSchema,
} from '~/shared/components/ui';
import { useAuth } from '~/shared/contexts/AuthProvider';
import {
  PageState,
  getMaxMainContentsHeight,
  getSortSettingFromLocalStorage,
  getViewIdPreset,
  FullMethodName_ListWorkTasks,
  FullMethodName_ListWorkTaskRelationContents,
  getExceptionMessage,
  getWorkerExceptionMessage,
  convertUserReference,
  autoDownloadFileOnlyName,
  autoBulkDownload,
  convertDatetime,
  convertDate,
  getDateFormatWithTimezone,
  getPresetAndSchema,
  AttachmentItems,
  HistoryItems,
  getLocalStorageCheckboxData,
  saveLocalStorageCheckboxData,
  openWindowOrTab,
  createAttachmentFiles,
  getHistoryItems,
  isShowNotificationArea,
  ViewId,
  FullMethodName_ListSurveyRequestContents,
  FullMethodName_ListSurveyBaseRequestContents,
  createWorkTaskRelation,
  ConnectingType,
  convertOrganizationStructureReferenceToFilterboxItem,
  getUserIdsByOrganizationId,
  convertOrganizationStructureReferenceToDisplayName,
} from '~/shared/utils';
import { useErrorHandler } from '~/shared/components/error/ErrorBoundary';
import { CaptionButton } from '~/shared/components/ui/Button/CaptionButton';
import { IconButton } from '~/shared/components/ui/Button/IconButton';
import { ForumTypeName, useForum } from '~/shared/components/tool/Forum';
import { AggregateStage, FilterRequest } from '~/worker';
import { ConnectingDialog } from '~/shared/components/ui/Dialog/ConnectingDialog';
import { FullMethodName_ListEstimateManagementSummarys } from '~/worker';

const VIEW_ID = 'WT_WORKTASK_CONFIRMATION';
const VIEW_ID_WT_WORKTASK_RELATION_LIST = 'WT_WORKTASK_RELATION_LIST';

export function WtWorkTaskConfirmation() {
  // ログイン者の情報特定用email
  const myEmail = useAuth().user?.email ?? '';
  const userId = useAuth().user?.userId ?? '';
  const myTenantId = useAuth().tenant?.tenantId ?? '';
  const navi = useNavigate();
  const handleError = useErrorHandler();
  const intl = useIntl();
  // 遷移元から値を受け取る
  const location = (useLocation().state as PageState) ?? [];
  // 一覧制御用
  const [childrenPresetItem, setChildrenPresetItem] = useState<PresetItem[]>();
  const [schema, setSchema] = useState<Property[]>([]);
  const [listViewPreset, setListViewPreset] = useState<Preset>({
    filter: {},
    propertyNames: [],
  });
  const [isReloadListView, setReloadListView] = useState(false);
  const [isSuspendReload, setSuspendReload] = useState(true);
  const [initialFilter, setInitialFilter] = useState<InitialFilter>({
    info: [],
  });

  const getTargetId = () => {
    const urlPathName = window.location.pathname;
    const splitPath = urlPathName.split('/');
    if (location.ids) {
      return location.ids ?? [];
    } else {
      if (splitPath.length > 1) {
        const idPath = splitPath[splitPath.length - 1];
        const endpointPath = splitPath[splitPath.length - 2];
        if (
          idPath !== 'wt-worktask-confirmation' &&
          endpointPath === 'wt-worktask-confirmation'
        ) {
          return [idPath];
        }
      }
    }
    return [];
  };

  // 紐付けデータ取得用
  type SurveyRequestContentAggregated =
    mtechnavi.ui.survey.SurveyRequestContentAggregated;

  // 紐付けダイアログ用
  const aggregatedTypeForSurvey = 'SurveyRequestContentAggregated';
  const aggregateStagesForSurvey: AggregateStage[] = [
    {
      // 実績データをグループ化する
      $group: {
        _id: {
          surveyBaseRequestId: 'surveyRequest.surveyBaseRequestId',
        },
        displayName: { $first: 'surveyRequest.displayName' },
        surveyBaseRequestId: {
          $first: 'surveyRequest.surveyBaseRequestId',
        },
        surveyRequestAutoName: {
          $first: 'surveyRequest.surveyRequestAutoName',
        },
        replyDueDateDt: {
          $min: 'replyDueDateDt',
        },
        sendedAt: { $first: 'surveyRequest.sendedAt' },
        surveyRequestCatalog: {
          $first: 'surveyRequest.surveyRequestCatalog',
        },
        totalRequestUnit: { $count: {} },
        status: { $first: 'status' },
        issuerEmail: {
          $first: 'surveyRequest.issuerProperties.updatedBy.email',
        },
      },
    },
    {
      // 予定データの名前をグループ化した実績データに合わせる
      $union: {
        from: FullMethodName_ListSurveyBaseRequestContents,
        fields: [
          {
            name: 'surveyBaseRequest.surveyBaseRequestId',
            as: '_id',
          },
          {
            name: 'surveyBaseRequest.displayName',
            as: 'displayName',
          },
          {
            name: 'surveyBaseRequest.surveyBaseRequestId',
            as: 'surveyBaseRequestId',
          },
          {
            name: 'surveyBaseRequest.surveyRequestAutoName',
            as: 'surveyRequestAutoName',
          },
          {
            name: 'surveyBaseRequest.replyDueDateDt',
            as: 'replyDueDateDt',
          },
          {
            name: 'surveyBaseRequest.sendedAt',
            as: 'sendedAt',
          },
          {
            name: 'surveyBaseRequest.surveyRequestCatalog',
            as: 'surveyRequestCatalog',
          },
          'totalRequestUnit',
          'status',
          {
            name: 'surveyBaseRequest.issuerProperties.updatedBy.email',
            as: 'issuerEmail',
          },
        ],
        option: {
          useQuery: false,
        },
      },
    },
  ];

  // 例外処理用にエラー情報を取得する様修正
  const [isException, setException] = useState<boolean>(false);
  const [pagenateError, setPagenateError] = useState<unknown>({});

  // pagenatorに基本情報をセット
  const [page, dispatch] = usePagenator({
    fullMethodName: FullMethodName_ListWorkTasks,
    pageNumber: location.confirmationViewOption?.pageNumber ?? 1,
    pageSize: 1,
    maxPageNumber: 1,
    filter: {
      $or: [{ workTaskId: { $in: getTargetId() } }],
    },
    sort: getSortSettingFromLocalStorage('WT_WORKTASK_LIST', myEmail),
    items: [],
    originalItems: [],
    onError(err) {
      setException(true);
      setPagenateError(err);
      handleError(err);
    },
  });

  useEffect(() => {
    if (isException) {
      error(getWorkerExceptionMessage(intl, pagenateError));
    }
  }, [intl, isException, pagenateError]);

  // メッセージ
  const successMessage = GetMessage({ id: 'I0000001' });
  const viewMessageCancel: MessageProps = {
    id: 'confirmationDialogMessage',
    prefixId: VIEW_ID,
  };
  const viewMessageDelete: MessageProps = {
    id: 'C0000001',
    value: {
      $1: GetMessageWithIntl(intl, { id: 'delete' }),
    },
  };
  const viewMessageComplete: MessageProps = {
    id: 'C0000001',
    value: {
      $1: GetMessageWithIntl(intl, {
        id: 'complete',
        prefixId: VIEW_ID,
      }),
    },
  };
  const viewMessageUncomplete: MessageProps = {
    id: 'C0000001',
    value: {
      $1: GetMessageWithIntl(intl, {
        id: 'uncomplete',
        prefixId: VIEW_ID,
      }),
    },
  };

  // ダイアログ用State
  // 確認ダイアログ
  const [isOpenConfirmDialog, setOpenConfirmDialog] = useState(false);
  // 確認ダイアログInfo
  const [confirmDialogInfo, setConfirmDialogInfo] = useState({
    viewMessage: viewMessageCancel,
    onDecision: () => {},
  });

  // 破棄ダイアログ
  const [isOpenDiscardDialog, setOpenDiscardDialog] = useState(false);
  const [discardComment, setDiscardComment] = useState('');
  // システム通知設定ダイアログ
  const [isOpenSystemNotificationDialog, setOpenSystemNotificationDialog] =
    useState(false);
  const [systemNotificationUsers, setSystemNotificationUsers] = useState<
    sharelib.IUserReference[]
  >([]);

  // 履歴ダイアログ
  const [isOpenHistoryDialog, setOpenHistoryDialog] = useState(false);
  const [historys, setHistorys] = useState<HistoryItems[]>([]);
  const historyMessage = GetMessage({
    id: 'I0000005',
    value: { $1: historys?.length > 0 ? historys[0].slip_category : '' },
  });
  // 作業担当変更ダイアログ
  const [isOpenWorkStaffEditDialog, setOpenWorkStaffEditDialog] =
    useState(false);
  const workStaffTargetIds = useRef<string[]>([]);
  // 作業完了期日変更ダイアログ
  const [isOpenTicketDueDateEditDialog, setOpenTicketDueDateEditDialog] =
    useState(false);
  // 作業完了予定日変更ダイアログ
  const [
    isOpenTicketScheduledDateEditDialog,
    setOpenTicketScheduledDateEditDialog,
  ] = useState(false);
  // 案件管理担当変更ダイアログ
  const [isOpenManagementStaffEditDialog, setOpenManagementStaffEditDialog] =
    useState(false);
  const managementStaffTargetIds = useRef<string[]>([]);
  // 案件完了期日変更ダイアログ
  const [isOpenDueDateEditDialog, setOpenDueDateEditDialog] = useState(false);
  // 参考情報編集ダイアログ
  const [isOpenRequestReferenceEditDialog, setOpenRequestReferenceEditDialog] =
    useState(false);
  // 作業メモ登録ダイアログ
  const [isOpenTicketRemarksEditDialog, setOpenTicketRemarksEditDialog] =
    useState(false);
  const [ticketRemarks, setTicketRemarks] = useState('');
  // 紐付け分類選択ダイアログ
  const [isOpenSelectConnectingDialog, setOpenSelectConnectingDialog] =
    useState(false);
  // 紐付けダイアログ
  const [isOpenConnectingDialog, setOpenConnectingDialog] = useState(false);
  const [
    fullMethodNameForConnectingDialogExtendListView,
    setFullMethodNameForConnectingDialogExtendListView,
  ] = useState('');
  const [fullMethodNamesForConnectingDialog, setFullMethodNamesForConnecting] =
    useState<string[]>([]);
  const [keyIdForConnectingDialog, setKeyIdForConnectingDialog] = useState('');
  const [viewIdForConnectingDialog, setViewIdForConnectingDialog] =
    useState<ViewId>('');
  const [labelOptionForConnectingDialog, setLabeOptionForConnectingDialog] =
    useState<MessageProps>();
  // 作業完了ダイアログ
  const [isOpenTicketCompleteDialog, setOpenTicketCompleteDialog] =
    useState(false);
  // 作業再開ダイアログ
  const [isOpenTicketUncompleteDialog, setOpenTicketUncompleteDialog] =
    useState(false);
  const [ticketUncompleteComment, setTicketUncompleteComment] = useState('');

  const [isLoading, setLoading] = useState(false);
  const [item, setItem] = useState<mtechnavi.api.worktask.IWorkTask>();
  const [isFirstPage, setFirstPage] = useState(false);
  const [isMaxPage, setMaxPage] = useState(false);
  const [accordionState, setAccordionState] = useState<{
    [k: string]: boolean;
  }>({
    requestContent: true,
    requestReference: true,
    workStatus: true,
    workResult: true,
  });
  const [isShowNotification, setShowNotification] = useState<boolean>(false);

  // ナビゲーションエリアの表示制御
  const [isEditable, setEditable] = useState<boolean>(true);
  const [isDeletable, setDeletable] = useState<boolean>(true);
  const [isSystemNotificationEditable, setSystemNotificationEditable] =
    useState<boolean>(true);
  const [backPath, setBackPath] = useState<string>('');

  // ツールバー
  const { setForumInfo } = useForum();

  // ボタン制御
  // 作業担当変更の表示制御
  const [isVisibleWorkStaffEdit, setVisibleWorkStaffEdit] =
    useState<boolean>(true);
  // 作業完了期日変更の表示制御
  const [isVisibleTicketDueDateEdit, setVisibleTicketDueDateEdit] =
    useState<boolean>(true);
  // 作業完了予定日変更の表示制御
  const [isVisibleTicketScheduledDateEdit, setVisibleTicketScheduledDateEdit] =
    useState<boolean>(true);
  // 案件管理担当変更の表示制御
  const [isVisibleManagementStaffEdit, setVisibleManagementStaffEdit] =
    useState<boolean>(true);
  // 案件完了期日変更の表示制御
  const [isVisibleDueDateEdit, setVisibleDueDateEdit] = useState<boolean>(true);
  // 依頼参考情報登録の表示制御
  const [isVisibleRequestReferenceEdit, setVisibleRequestReferenceEdit] =
    useState<boolean>(true);
  // 作業メモの表示制御
  const [isVisibleTicketRemarksEdit, setVisibleTicketRemarksEdit] =
    useState<boolean>(true);
  // 作業案件紐付け追加の表示制御
  const [isVisibleWorkTaskRelationAdd, setVisibleWorkTaskRelationAdd] =
    useState<boolean>(true);
  // 作業案件紐付け削除の表示制御
  const [isVisibleWorkTaskRelationDelete, setVisibleWorkTaskRelationDelete] =
    useState<boolean>(true);
  // 作業完了の表示制御
  const [isVisibleTicketComplete, setVisibleTicketComplete] =
    useState<boolean>(true);
  // 案件完了の表示制御
  const [isVisibleComplete, setVisibleComplete] = useState<boolean>(true);
  // 案件再開の表示制御
  const [isVisibleUncomplete, setVisibleUncomplete] = useState<boolean>(true);
  // 作業再開の表示制御
  const [isVisibleTicketUncomplete, setVisibleTicketUncomplete] =
    useState<boolean>(true);

  // タブ制御
  type TabName = 'requestTabInfo' | 'workTabInfo';
  const tabs: TabName[] = ['requestTabInfo', 'workTabInfo'];
  const [tabPanel, setTabPanel] = useState<TabName>('requestTabInfo');
  const isCurrentTab = (target: TabName) => {
    return tabPanel === target;
  };

  // 取得データ格納用 指図案件関連データ
  const workTaskRelationContentsData = useRef<
    mtechnavi.api.worktask.IWorkTaskRelationContent[]
  >([]);
  const [
    workTaskRelationContentsOpenTasks,
    setWorkTaskRelationContentsOpenTasks,
  ] = useState(0);

  // 添付ファイル情報
  const [requestAttachmentItems, setRequestAttachmentItems] = useState<
    AttachmentItems[]
  >([]);
  const [ticketResultAttachmentItems, setTicketResultAttachmentItems] =
    useState<AttachmentItems[]>([]);

  const handleReload = () => {
    dispatch({
      type: 'reload',
      fullMethodName: FullMethodName_ListWorkTasks,
      onChangeLoadingState: (v) => {
        setLoading(v);
      },
    });
  };

  // 指図案件登録遷移
  const handleWorkTaskInput = () => {
    const state: PageState = {
      ids: [item?.workTaskId ?? ''],
      sourceViewId: VIEW_ID,
      actionType: 'edit',
      naviFilters: location.naviFilters,
      beforeStateIds: location.ids,
      baseViewOption: location.baseViewOption,
      confirmationViewOption: { pageNumber: page.pageNumber },
    };
    navi('/work-task/wt-worktask-input', { state });
  };

  // フォーラム用
  useEffect(() => {
    const items =
      page.originalItems as unknown as mtechnavi.api.worktask.IWorkTask[];
    if (!items.length) return;
    const item = items[0];
    setForumInfo({
      typeName: ForumTypeName.WorkTask,
      resourceId: item.workTaskId ?? '',
    });
  }, [page, setForumInfo, myTenantId]);

  // 初回画面用データ
  useEffect(() => {
    // viewIDに紐付くpresetを取得
    (async () => {
      const { presetItem } = await getViewIdPreset(intl, VIEW_ID);

      setViewPreset(presetItem);

      // 指図案件紐付け情報のスキーマ情報、preset関係の情報を取得
      const { childrenPresetItem, schemas, preset } = await getPresetAndSchema(
        VIEW_ID_WT_WORKTASK_RELATION_LIST,
        [FullMethodName_ListWorkTaskRelationContents]
      );

      const jaColumn: string[] = [
        'workTaskRelation.systemCategory.displayNameLang',
        'status.displayNameLang',
      ];
      const formatterSch = getAltDisplaySchema(schemas[0], jaColumn, 'ja');

      // 取得した情報をセット
      setChildrenPresetItem(childrenPresetItem);
      setSchema(formatterSch);
      setListViewPreset(preset);
    })();

    // 表示タブがセットされていればタブをセットする
    if (location.baseViewOption?.sourceTab) {
      setTabPanel(location.baseViewOption?.sourceTab as TabName);
    }

    // 戻るページを指定する
    if (location.baseViewOption?.sourceViewId) {
      switch (location.baseViewOption?.sourceViewId) {
        case 'WT_WORKTASK_LIST':
          setBackPath('/work-task/wk-worktask-list');
          break;
        case 'WT_WORKTASK_APPROVAL_LIST':
          setBackPath('/work-task/wk-worktask-approval-list');
          break;
        default:
          setBackPath('/work-task/wk-worktask-list');
          break;
      }
    } else {
      // 一覧起動ではない場合は戻るパスは空、戻るアクションは非活性
      setBackPath('');
    }

    // 基本情報でセットした値を元に情報を取得
    handleReload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 履歴データ取得
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const listHistoryData = async () => {
    try {
      // 履歴一覧を取得
      const datas = await getHistoryItems(
        intl,
        'mtechnavi.api.worktask.WorkTask',
        [item?.workTaskId ?? '']
      );
      setHistorys(datas);
    } catch (err) {
      error(getExceptionMessage(intl, err));
      throw err;
    }
  };

  // 履歴ダイアログ起動時
  useEffect(() => {
    if (isOpenHistoryDialog) {
      //履歴データを取得
      (async () => {
        await listHistoryData();
      })();
    }

    // isOpenHistoryDialog変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenHistoryDialog]);

  // 履歴データ変更時
  useEffect(() => {
    // 最新の履歴通知レベルによる制御
    setShowNotification(isShowNotificationArea(historys));
  }, [historys]);

  //指図案件データ変更時
  useEffect(() => {
    if (!item) {
      return;
    }

    (async () => {
      workStaffTargetIds.current = await getUserIdsByOrganizationId(
        item.workOrganization?.organizationId
      );
      managementStaffTargetIds.current = await getUserIdsByOrganizationId(
        item.managementOrganization?.organizationId
      );
    })();

    //履歴データを取得
    (async () => {
      await listHistoryData();
    })();

    // システム通知先データを取得
    setSystemNotificationUsers(item?.systemNotificationUsers ?? []);

    // 依頼ファイルリストを設定
    const requestAttachments: sharelib.IAttachment[] = [
      ...(item?.requestAttachments ?? []),
    ];
    putRequestAttachmentItem(requestAttachments);

    // 作業結果ファイルリストを設定
    const ticketResultAttachments: sharelib.IAttachment[] = [
      ...(item?.ticket?.resultAttachments ?? []),
    ];
    putTicketResultAttachmentItem(ticketResultAttachments);

    //指図案件関連情報のフィルタセット
    const filter: InitialFilter = {
      info: [],
    };
    filter.info.push({
      targetKey: 'linkedWorkTaskIds',
      targetValue: item?.workTaskId ?? '',
    });
    setInitialFilter(filter);
    if (filter.info.length) {
      setSuspendReload(false);
    }

    // 作業メモ
    setTicketRemarks(item?.ticket?.remarks ?? '');

    // ログインユーザーの所属部門による制御が必要な内容
    (async () => {
      const userOrganizationUnits =
        await window.App.services.ui.getUserOrganization(userId);

      // ログインユーザーの所属部門ID
      const userOrganizationUnitIds =
        userOrganizationUnits.length > 0
          ? userOrganizationUnits.map((user) => user.organizationId)
          : [];
      // ログインユーザーの所属部門が管理組織の場合 true
      const isManagementOrganization: boolean =
        (item?.managementOrganization?.organizationId &&
          userOrganizationUnitIds.some(
            (user) => user === item.managementOrganization?.organizationId
          )) ||
        false;
      // ログインユーザーの所属部門が作業組織の場合 true
      const isWorkOrganization: boolean =
        (item?.workOrganization?.organizationId &&
          userOrganizationUnitIds.some(
            (user) => user === item.workOrganization?.organizationId
          )) ||
        false;

      // ボタン制御対象を非活性化
      disableAllButton();

      // 管理部門の場合に対象になるもの
      if (isManagementOrganization) {
        // 削除/破棄の表示制御
        const visibleDeleteIcon: string[] = ['B00', 'B02', 'B04', 'B05'];
        setDeletable(
          visibleDeleteIcon.includes(item?.status?.systemName ?? '')
        );

        // 編集の表示制御
        const visibleEditIcon: string[] = ['B00', 'B02'];
        setEditable(visibleEditIcon.includes(item?.status?.systemName ?? ''));

        // 作業完了期日変更の表示制御
        const visibleTicketDueDateEdit: string[] = ['B04'];
        setVisibleTicketDueDateEdit(
          visibleTicketDueDateEdit.includes(item?.status?.systemName ?? '')
        );

        // 案件管理担当変更の表示制御
        const visibleManagementStaffEdit: string[] = ['B04', 'B05'];
        setVisibleManagementStaffEdit(
          visibleManagementStaffEdit.includes(item?.status?.systemName ?? '')
        );

        // 案件完了期日変更の表示制御
        const visibleDueDateEdit: string[] = ['B04', 'B05'];
        setVisibleDueDateEdit(
          visibleDueDateEdit.includes(item?.status?.systemName ?? '')
        );

        // 依頼参考情報登録の表示制御
        const visibleRequestReferenceEdit: string[] = ['B04', 'B05'];
        setVisibleRequestReferenceEdit(
          visibleRequestReferenceEdit.includes(item?.status?.systemName ?? '')
        );

        // 案件完了の表示制御
        const visibleComplete: string[] = ['B04', 'B05'];
        setVisibleComplete(
          visibleComplete.includes(item?.status?.systemName ?? '')
        );

        // 案件再開の表示制御
        const visibleUncomplete: string[] = ['B06'];
        setVisibleUncomplete(
          visibleUncomplete.includes(item?.status?.systemName ?? '')
        );

        // 作業再開の表示制御
        const visibleTicketUncomplete: string[] = ['B05'];
        setVisibleTicketUncomplete(
          visibleTicketUncomplete.includes(item?.status?.systemName ?? '')
        );
      }

      // 作業部門の場合に対象になるもの
      if (isWorkOrganization) {
        // 作業担当変更の表示制御
        const visibleWorkStaffEdit: string[] = ['B04'];
        setVisibleWorkStaffEdit(
          visibleWorkStaffEdit.includes(item?.status?.systemName ?? '')
        );

        // 作業完了予定日変更の表示制御
        const visibleTicketScheduledDateEdit: string[] = ['B04'];
        setVisibleTicketScheduledDateEdit(
          visibleTicketScheduledDateEdit.includes(
            item?.status?.systemName ?? ''
          )
        );

        // 作業メモの表示制御
        const visibleTicketRemarksEdit: string[] = ['B04'];
        setVisibleTicketRemarksEdit(
          visibleTicketRemarksEdit.includes(item?.status?.systemName ?? '')
        );

        // 作業案件紐付け追加の表示制御
        const visibleWorkTaskRelationAdd: string[] = ['B04'];
        setVisibleWorkTaskRelationAdd(
          visibleWorkTaskRelationAdd.includes(item?.status?.systemName ?? '')
        );

        // 作業案件紐付け削除の表示制御
        const visibleWorkTaskRelationDelete: string[] = ['B04'];
        setVisibleWorkTaskRelationDelete(
          visibleWorkTaskRelationDelete.includes(item?.status?.systemName ?? '')
        );

        // 作業完了の表示制御
        const visibleTicketComplete: string[] = ['B04'];
        setVisibleTicketComplete(
          visibleTicketComplete.includes(item?.status?.systemName ?? '')
        );
      }

      // どちらかに所属している場合に対象になるもの
      if (isManagementOrganization || isWorkOrganization) {
        // 通知設定の表示制御
        const visibleSystemNotificationIcon: string[] = ['B04', 'B05'];
        setSystemNotificationEditable(
          visibleSystemNotificationIcon.includes(item?.status?.systemName ?? '')
        );
      }
    })();
    // item変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, intl]);

  const disableAllButton = () => {
    setSystemNotificationEditable(false);
    setDeletable(false);
    setEditable(false);
    setVisibleWorkStaffEdit(false);
    setVisibleTicketDueDateEdit(false);
    setVisibleTicketScheduledDateEdit(false);
    setVisibleManagementStaffEdit(false);
    setVisibleDueDateEdit(false);
    setVisibleRequestReferenceEdit(false);
    setVisibleTicketRemarksEdit(false);
    setVisibleWorkTaskRelationAdd(false);
    setVisibleWorkTaskRelationDelete(false);
    setVisibleTicketComplete(false);
    setVisibleComplete(false);
    setVisibleUncomplete(false);
    setVisibleTicketUncomplete(false);
  };

  const putRequestAttachmentItem = (
    attachmentItems: sharelib.IAttachment[]
  ) => {
    const resultItems: AttachmentItems[] = [];
    attachmentItems.map((item) => {
      resultItems.push({
        id: item.assetId ?? '',
        category: item.category ?? {},
        assetId: item.assetId ?? '',
        filename: item.filename ?? '',
        mimeType: item.mimeType ?? '',
        remarks: item.remarks ?? '',
        linkType: item.linkType ?? {},
      });
    });
    setRequestAttachmentItems([...resultItems]);
  };

  const putTicketResultAttachmentItem = (
    attachmentItems: sharelib.IAttachment[]
  ) => {
    const resultItems: AttachmentItems[] = [];
    attachmentItems.map((item) => {
      resultItems.push({
        id: item.assetId ?? '',
        category: item.category ?? {},
        assetId: item.assetId ?? '',
        filename: item.filename ?? '',
        mimeType: item.mimeType ?? '',
        remarks: item.remarks ?? '',
        linkType: item.linkType ?? {},
      });
    });
    setTicketResultAttachmentItems([...resultItems]);
  };

  const accordionAction = (type: string) => {
    setAccordionState({ ...accordionState, [type]: !accordionState[type] });
  };

  // preset
  const [viewPreset, setViewPreset] = useState<PresetItem>({ name: '' });

  // pageデータ変更時の処理
  useEffect(() => {
    const items =
      page.originalItems as unknown as mtechnavi.api.worktask.IWorkTask[];
    if (items.length > 0) {
      setItem(items[0]);
    }
    setFirstPage(page.pageNumber === 1);
    setMaxPage(page.pageNumber === page.maxPageNumber);
    setShowNotification(false);
  }, [page]);

  // ページ戻し・送り処理
  const handleMovePage = (pageNumber: number) => {
    const n = Math.min(Math.max(1, pageNumber), page.maxPageNumber);
    setSuspendReload(true);
    dispatch({
      type: 'query',
      fullMethodName: FullMethodName_ListWorkTasks,
      pageNumber: n,
    });
  };

  const [mainContentHeight, setMainContentHeight] = useState('');
  const footerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    setMainContentHeight(
      getMaxMainContentsHeight(footerRef.current?.clientHeight ?? 0)
    );
  }, [footerRef.current?.clientHeight]);

  const getPageNavigationIconItems = (): NavigationIconMenu[] => {
    const navigationIconItems: NavigationIconMenu[] = [];
    if (isEditable) {
      navigationIconItems.push({
        name: 'edit',
        displayName: '編集',
        func: () => {
          handleWorkTaskInput();
        },
      });
    }
    if (isDeletable) {
      // 破棄 (削除 / 破棄)
      navigationIconItems.push({
        name: 'delete',
        displayName: '削除',
        func: () => {
          if (
            ['B00', 'B02'].some(
              (systemName) => (item?.status?.systemName ?? '') === systemName
            )
          ) {
            handleConfirmDelete();
          }
          if (
            ['B04', 'B05'].some(
              (systemName) => (item?.status?.systemName ?? '') === systemName
            )
          ) {
            setDiscardComment('');
            setOpenDiscardDialog(true);
          }
        },
      });
    }
    navigationIconItems.push({
      name: 'history',
      displayName: '履歴',
      func: () => {
        setOpenHistoryDialog(true);
      },
    });
    if (isSystemNotificationEditable) {
      navigationIconItems.push({
        name: 'recipients',
        displayName: '通知設定',
        func: () => {
          setOpenSystemNotificationDialog(true);
        },
      });
    }
    return navigationIconItems;
  };

  // [実行処理]削除
  const handleConfirmDelete = () => {
    setConfirmDialogInfo({
      viewMessage: viewMessageDelete,
      onDecision: handleDelete,
    });
    setOpenConfirmDialog(true);
  };
  const handleDelete = async () => {
    setLoading(true);
    try {
      await window.App.services.ui.worker.apiCall({
        actionName: 'deleteWorkTask',
        request: item ?? {},
      });
      setOpenConfirmDialog(false);
      success([successMessage]);
      const localstorageValues = getLocalStorageCheckboxData(
        'WT_WORKTASK_LIST',
        myEmail
      );
      saveLocalStorageCheckboxData(
        'WT_WORKTASK_LIST',
        localstorageValues.filter((v) => v !== item?.workTaskId),
        myEmail
      );
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
    const state: PageState = {
      ids: [item?.workTaskId ?? ''],
      sourceViewId: VIEW_ID,
      naviFilters: location.naviFilters,
      beforeStateIds: location.ids,
      baseViewOption: location.baseViewOption,
    };
    navi(backPath, { state });
  };

  // [実行処理]破棄
  const handleDiscard = async () => {
    setLoading(true);
    try {
      await window.App.services.ui.worker.apiCall({
        actionName: 'discardWorkTask',
        request: {
          workTaskId: item?.workTaskId ?? '',
          comment: discardComment,
        },
      });
      setOpenDiscardDialog(false);
      success([successMessage]);
      handleReload();
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  // [実行処理]システム通知設定
  const handleSystemNotificationSetting = async (result: string) => {
    setLoading(true);
    try {
      const userReference = await convertUserReference(myEmail);
      const req: mtechnavi.api.worker.ISystemNotificationSetting = {
        surveyReceptionId: item?.workTaskId ?? '',
        appendUser:
          result === 'setAsNotificationDestination' ? userReference : null,
        removeUser:
          result !== 'setAsNotificationDestination' ? userReference : null,
      };
      await window.App.services.ui.worker.apiCall({
        actionName: 'updateWtSystemNotificationSetting',
        request: req,
      });
      success([successMessage]);
      handleReload();
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
    } finally {
      setLoading(false);
    }
  };

  // [実行処理]作業担当者変更
  const handleWorkStaffEdit = async (result: ChangeStaffDialogResult) => {
    setLoading(true);
    try {
      // 担当者マスタから取得
      const selectedStaff =
        (await convertUserReference(result.changedStaff?.value || '')) || null;
      if (!selectedStaff) {
        return;
      }

      if (item) {
        item.workStaff = selectedStaff;
        const workStaffChangeProcess: mtechnavi.api.worktask.IProcess =
          item.workStaffChangeProcess ?? {};
        workStaffChangeProcess.processedComment = result.comment;
        item.workStaffChangeProcess = workStaffChangeProcess;
        await window.App.services.ui.worker.apiCall({
          actionName: 'updateWtWorkStaff',
          request: item,
        });
      }
      setOpenWorkStaffEditDialog(false);
      success([successMessage]);
      handleReload();
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  // [実行処理]作業完了期日変更
  const handleTicketDueDateEdit = async (result: DeadlineChangeResult) => {
    setLoading(true);
    try {
      if (item) {
        item.worktaskTicketDueDateDt = convertDatetime(
          result.deadline,
          'YYYY/MM/DD'
        );
        const worktaskTicketDueDateChangeProcess: mtechnavi.api.worktask.IProcess =
          item.worktaskTicketDueDateChangeProcess ?? {};
        worktaskTicketDueDateChangeProcess.processedComment = result.comment;
        item.worktaskTicketDueDateChangeProcess =
          worktaskTicketDueDateChangeProcess;
        await window.App.services.ui.worker.apiCall({
          actionName: 'updateWtTicketDueDate',
          request: item,
        });
        setOpenTicketDueDateEditDialog(false);
      }
      success([successMessage]);
      handleReload();
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  // [実行処理]作業完了予定日変更
  const handleTicketScheduledDateEdit = async (
    result: DeadlineChangeResult
  ) => {
    setLoading(true);
    try {
      if (item) {
        item.worktaskTicketScheduledDateDt = convertDatetime(
          result.deadline,
          'YYYY/MM/DD'
        );
        const worktaskTicketScheduledDateChangeProcess: mtechnavi.api.worktask.IProcess =
          item.worktaskTicketScheduledDateChangeProcess ?? {};
        worktaskTicketScheduledDateChangeProcess.processedComment =
          result.comment;
        item.worktaskTicketScheduledDateChangeProcess =
          worktaskTicketScheduledDateChangeProcess;
        await window.App.services.ui.worker.apiCall({
          actionName: 'updateWtTicketScheduledDate',
          request: item,
        });
      }
      setOpenTicketScheduledDateEditDialog(false);
      success([successMessage]);
      handleReload();
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  // [実行処理]案件管理担当変更
  const handleManagementStaffEdit = async (result: ChangeStaffDialogResult) => {
    setLoading(true);
    try {
      // 担当者マスタから取得
      const selectedStaff =
        (await convertUserReference(result.changedStaff?.value || '')) || null;
      if (item) {
        item.managementStaff = selectedStaff;
        const managementStaffChangeProcess: mtechnavi.api.worktask.IProcess =
          item.managementStaffChangeProcess ?? {};
        managementStaffChangeProcess.processedComment = result.comment;
        item.managementStaffChangeProcess = managementStaffChangeProcess;
        await window.App.services.ui.worker.apiCall({
          actionName: 'updateWtManagementStaff',
          request: item,
        });
      }
      setOpenManagementStaffEditDialog(false);
      success([successMessage]);
      handleReload();
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  // [実行処理]案件完了期日変更
  const handleDueDateEdit = async (result: DeadlineChangeResult) => {
    setLoading(true);
    try {
      if (item) {
        item.worktaskDueDateDt = convertDatetime(result.deadline, 'YYYY/MM/DD');
        const worktaskDueDateChangeProcess: mtechnavi.api.worktask.IProcess =
          item.worktaskDueDateChangeProcess ?? {};
        worktaskDueDateChangeProcess.processedComment = result.comment;
        item.worktaskDueDateChangeProcess = worktaskDueDateChangeProcess;
        await window.App.services.ui.worker.apiCall({
          actionName: 'updateWtDueDate',
          request: item,
        });
      }
      setOpenDueDateEditDialog(false);
      success([successMessage]);
      handleReload();
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  // [実行処理]参考情報編集
  const handleRequestReferenceEdit = async (
    result: FileCommentDialogFormOption
  ) => {
    setLoading(true);
    try {
      const tmpData = await createAttachmentFiles(result.files ?? [], 'B06');
      // 指図案件データ更新
      if (item) {
        item.requestRemarks = result.comment ?? '';
        item.requestAttachments = tmpData;
        await window.App.services.ui.worker.apiCall({
          actionName: 'updateWtRequestReference',
          request: item,
        });
      }

      setOpenRequestReferenceEditDialog(false);
      success([successMessage]);
      handleReload();
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  // [実行処理]作業メモ
  const handleTicketRemarksEdit = async () => {
    setLoading(true);
    try {
      if (item && item.ticket) {
        item.ticket.remarks = ticketRemarks;
        await window.App.services.ui.worker.apiCall({
          actionName: 'updateWtTicketRemarks',
          request: item,
        });
      }
      setOpenTicketRemarksEditDialog(false);
      success([successMessage]);
      handleReload();
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  // [実行処理]紐付け分類選択
  const handleSelectConnecting = async (
    v: SelectConnectingDialogOutputOption
  ) => {
    setLoading(true);
    if (v.newSlip) {
      switch (v.connectingCategory.systemName) {
        case 'B01':
          {
            const state: PageState = {
              sourceViewId: VIEW_ID,
              actionType: 'add',
              beforeStateIds: [item?.workTaskId ?? ''],
              baseViewOption: {
                sourceViewId: VIEW_ID,
                sourceTab: 'workTabInfo',
              },
            };
            navi('/work-task/wt-worktask-input', { state });
          }
          break;
        case 'B02':
          {
            const state: PageState = {
              sourceViewId: VIEW_ID,
              actionType: 'add',
              beforeStateIds: [item?.workTaskId ?? ''],
              baseViewOption: {
                sourceViewId: VIEW_ID,
                sourceTab: 'workTabInfo',
              },
            };
            navi('/supplier-base/survey-request-sender-input', { state });
          }
          break;
        case 'B03':
          {
            const state: PageState = {
              sourceViewId: VIEW_ID,
              actionType: 'add',
              beforeStateIds: [item?.workTaskId ?? ''],
              baseViewOption: {
                sourceViewId: VIEW_ID,
                sourceTab: 'workTabInfo',
              },
            };
            navi('/estimate/est-estimate-request-input', { state });
          }
          break;
      }
      return;
    }
    switch (v.connectingCategory.systemName) {
      case 'B01':
        {
          setViewIdForConnectingDialog('WT_LINKED_WORK_TASK');
          setFullMethodNameForConnectingDialogExtendListView(
            FullMethodName_ListWorkTasks
          );
          setFullMethodNamesForConnecting([FullMethodName_ListWorkTasks]);
          setKeyIdForConnectingDialog('workTaskId');
          setLabeOptionForConnectingDialog({
            id: 'label',
            prefixId: 'WT_LINKED_WORK_TASK',
          });
        }
        break;
      case 'B02':
        {
          setViewIdForConnectingDialog('WT_LINKED_SURVEY');
          setFullMethodNameForConnectingDialogExtendListView(
            FullMethodName_ListSurveyRequestContents
          );
          setFullMethodNamesForConnecting([
            FullMethodName_ListSurveyBaseRequestContents,
            FullMethodName_ListSurveyRequestContents,
          ]);
          setKeyIdForConnectingDialog('surveyBaseRequestId');
          setLabeOptionForConnectingDialog({
            id: 'label',
            prefixId: 'WT_LINKED_SURVEY',
          });
        }
        break;
      case 'B03':
        {
          setViewIdForConnectingDialog('WT_LINKED_ESTIMATE');
          setFullMethodNameForConnectingDialogExtendListView(
            FullMethodName_ListEstimateManagementSummarys
          );
          setFullMethodNamesForConnecting([
            FullMethodName_ListEstimateManagementSummarys,
          ]);
          setKeyIdForConnectingDialog('estimateManagementSummaryId');
          setLabeOptionForConnectingDialog({
            id: 'label',
            prefixId: 'WT_LINKED_ESTIMATE',
          });
        }
        break;
    }
    setOpenSelectConnectingDialog(false);
    setOpenConnectingDialog(true);
    setLoading(false);
  };

  // 次期対応で正式に数値を求めるまではランダム（作業依頼内容）な項目の中身を件数の固定値「１」に変換
  // 今後削除するため、指図案件確認画面内に定義
  const getReplacedValue = (sch: Schema, targetColumn: string) => {
    return sch.map((v) => {
      if (targetColumn === v.name) {
        return {
          ...v,
          formatter: replaceValue(targetColumn),
        };
      } else {
        return v;
      }
    });
  };

  // 暫定処理のためLintを無視する
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const replaceValue = (name: string) => {
    return (value: object): string => {
      return '1';
    };
  };
  /* eslint-enable @typescript-eslint/no-unused-vars */

  // 紐付け分類ダイアログ用
  const getFormmaterSchema = (schema: Schema): Schema => {
    const jaColumn: string[] = ['status.displayNameLang'];
    const formatterSch = getAltDisplaySchema(schema, jaColumn, 'ja');

    const commaSch = getCommaTypeNumberSchema(formatterSch, [
      'totalRequestUnit',
      'countEstimateRequest',
    ]);

    const replacedValueSch =
      viewIdForConnectingDialog === 'WT_LINKED_WORK_TASK'
        ? getReplacedValue(commaSch, 'contents')
        : commaSch;

    return replacedValueSch;
  };

  // [実行処理]紐付け
  const handleConnecting = async (resultIds: string[]) => {
    setLoading(true);
    setReloadListView(false);
    try {
      if (resultIds.length > 0 && item?.workTaskId) {
        let connectingType: ConnectingType;
        switch (viewIdForConnectingDialog) {
          case 'WT_LINKED_WORK_TASK':
            connectingType = 'worktask';
            break;
          case 'WT_LINKED_SURVEY':
            connectingType = 'survey';
            break;
          case 'WT_LINKED_ESTIMATE':
            connectingType = 'estimate';
            break;
          default:
            connectingType = 'worktask';
        }
        await createWorkTaskRelation(
          resultIds,
          [item.workTaskId],
          VIEW_ID,
          connectingType
        );
      }
      setOpenConnectingDialog(false);
      success([successMessage]);

      // 直後にリロードすると更新データが反映されないため遅らせる
      const delayTimeShort = 200;
      const delayTimeLong = 700;
      setTimeout(
        () => {
          setReloadListView(true);
        },
        resultIds.length > 1 ||
          viewIdForConnectingDialog === 'WT_LINKED_ESTIMATE'
          ? delayTimeLong
          : delayTimeShort
      );
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  // [実行処理]紐付け削除
  const handleConfirmConnectingDelete = (ids: string[]) => {
    if (ids.length > 0) {
      setConfirmDialogInfo({
        viewMessage: viewMessageDelete,
        onDecision: () => {
          handleConnectingDelete(ids[0]);
        },
      });
      setOpenConfirmDialog(true);
    }
  };
  const handleConnectingDelete = async (id: string) => {
    setLoading(true);
    setReloadListView(false);
    try {
      const target = getWtRelationData(id);
      if (!target) {
        return;
      }

      await window.App.services.ui.worker.apiCall({
        actionName: 'deleteWorkTaskRelation',
        request: [target?.workTaskRelation ?? {}],
      });

      setOpenConfirmDialog(false);
      success([successMessage]);

      // 直後にリロードすると更新データが反映されないため遅らせる
      setTimeout(() => {
        setReloadListView(true);
      }, 300);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const getWtRelationData = (
    id: string | null
  ): mtechnavi.api.worktask.IWorkTaskRelationContent | undefined => {
    return workTaskRelationContentsData?.current.find(
      (item) => item.workTaskRelation?.workTaskRelationId === id
    );
  };

  // [実行処理]確認画面遷移
  const handleConnectingConfirm = async (ids?: string[]) => {
    if (ids && ids.length > 0) {
      const target = getWtRelationData(ids[0]);
      if (target?.workTaskRelation?.recordId) {
        const recordId: string = target?.workTaskRelation?.recordId;
        switch (target?.workTaskRelation?.systemCategory?.systemName) {
          case 'B01': // 指図案件
            openWindowOrTab('work-task/wt-worktask-confirmation/' + recordId);
            break;
          case 'B02': {
            const aggregateSurveyListStages: AggregateStage[] = [
              {
                // 実績データをグループ化する
                $group: {
                  _id: {
                    surveyBaseRequestId: 'surveyRequest.surveyBaseRequestId',
                    'status.code': 'status.code',
                  },
                  displayName: { $first: 'surveyRequest.displayName' },
                  surveyBaseRequestId: {
                    $first: 'surveyRequest.surveyBaseRequestId',
                  },
                  surveyRequestAutoName: {
                    $first: 'surveyRequest.surveyRequestAutoName',
                  },
                  requesters: { $first: 'surveyRequest.requesters' },
                  maxReplyDueDateDt: {
                    $max: 'replyDueDateDt',
                  },
                  minReplyDueDateDt: {
                    $min: 'replyDueDateDt',
                  },
                  replyDueDateDt: {
                    $min: 'replyDueDateDt',
                  },
                  sendedAt: { $first: 'surveyRequest.sendedAt' },
                  surveyRequestCatalog: {
                    $first: 'surveyRequest.surveyRequestCatalog',
                  },
                  totalRequestUnit: { $count: {} },
                  status: { $first: 'status' },
                  guidance: { $first: 'surveyRequest.guidance' },
                  issuerEmail: {
                    $first: 'surveyRequest.issuerProperties.updatedBy.email',
                  },
                  // 実績データのみのプロパティ
                  surveyRequestIds: {
                    $addToSet: 'surveyRequest.surveyRequestId',
                  },
                  maxFlagActive: {
                    $max: 'surveyRequest.flagProperties.active',
                  },
                  requesterManagementOrganization: {
                    $first: 'surveyRequest.managementOrganization',
                  },
                },
              },
              {
                // 予定データの名前をグループ化した実績データに合わせる
                $union: {
                  from: FullMethodName_ListSurveyBaseRequestContents,
                  fields: [
                    {
                      name: 'surveyBaseRequest.surveyBaseRequestId',
                      as: '_id',
                    },
                    {
                      name: 'surveyBaseRequest.displayName',
                      as: 'displayName',
                    },
                    {
                      name: 'surveyBaseRequest.surveyBaseRequestId',
                      as: 'surveyBaseRequestId',
                    },
                    {
                      name: 'surveyBaseRequest.surveyRequestAutoName',
                      as: 'surveyRequestAutoName',
                    },
                    {
                      name: 'surveyBaseRequest.requesters',
                      as: 'requesters',
                    },
                    {
                      name: 'surveyBaseRequest.replyDueDateDt',
                      as: 'maxReplyDueDateDt',
                    },
                    {
                      name: 'surveyBaseRequest.replyDueDateDt',
                      as: 'minReplyDueDateDt',
                    },
                    {
                      name: 'surveyBaseRequest.replyDueDateDt',
                      as: 'replyDueDateDt',
                    },
                    {
                      name: 'surveyBaseRequest.sendedAt',
                      as: 'sendedAt',
                    },
                    {
                      name: 'surveyBaseRequest.surveyRequestCatalog',
                      as: 'surveyRequestCatalog',
                    },
                    'totalRequestUnit',
                    'status',
                    {
                      name: 'surveyBaseRequest.guidance',
                      as: 'guidance',
                    },
                    {
                      name: 'surveyBaseRequest.managementOrganization',
                      as: 'requesterManagementOrganization',
                    },
                    // 予定データのみのプロパティ
                    {
                      name: 'surveyBaseRequest.approvalRequest.approvalPlanStaff',
                      as: 'approvalPlanStaffRef',
                    },
                    {
                      name: 'surveyBaseRequest.approvalRequest.approvalPlanStaff.userId',
                      as: 'approvalPlanStaffId',
                    },
                    {
                      name: 'surveyBaseRequest.approvalRequest.approvalPlanOrganization',
                      as: 'approvalPlanOrganizationUnitRef',
                    },
                    {
                      name: 'surveyBaseRequest.approvalRequest.approvalPlanOrganization.organizationId',
                      as: 'approvalPlanOrganizationUnitId',
                    },
                    {
                      name: 'surveyBaseRequest.approvalRequest.requestedBy',
                      as: 'approvalRequestUpdatedBy',
                    },
                    {
                      name: 'surveyBaseRequest.approvalRequest.requestedComment',
                      as: 'approvalRequestComment',
                    },
                    {
                      name: 'surveyBaseRequest.requestUnits.businessUnitManagementId',
                      as: 'businessUnitIds',
                    },
                    {
                      name: 'surveyBaseRequest.issuerProperties.updatedBy.email',
                      as: 'issuerEmail',
                    },
                    // 予定データ削除時用
                    {
                      name: 'surveyBaseRequest.updatedAt',
                      as: 'updatedAt',
                    },
                    {
                      name: 'surveyBaseRequest.requestFormIds',
                      as: 'requestFormIds',
                    },
                    {
                      name: 'surveyBaseRequest.surveyResultWorkFormIds',
                      as: 'surveyResultWorkFormIds',
                    },
                  ],
                  option: {
                    useQuery: false,
                    requestBody: {
                      surveyBaseRequestIds: [recordId],
                    },
                  },
                },
              },
            ];
            // サプライヤベース
            const option: FilterRequest = {
              action: 'reload',
              fullMethodName: FullMethodName_ListSurveyRequestContents,
              filter: {},
              requestBody: {
                dataFilter: {
                  surveyBaseRequestIds: [recordId],
                },
              },
              sort: [],
            };
            option.aggregate = aggregateSurveyListStages;

            try {
              setLoading(true);
              const targetIds: string[] = [];

              const surveyData =
                await window.App.services.ui.worker.filter<SurveyRequestContentAggregated>(
                  option
                );
              surveyData.items.map((item) => {
                // 実績データの場合
                if (item.surveyRequestIds && item.surveyRequestIds.length > 0) {
                  item.surveyRequestIds.map((id) => {
                    targetIds.push(id);
                  });
                  // 予定データの場合
                } else {
                  item.businessUnitIds.map((id) => {
                    const aa: string[] = [];
                    aa.push(item.surveyBaseRequestId);
                    aa.push(id);
                    targetIds.push(aa.join('-'));
                  });
                }
              });
              const sendId = targetIds.join(',');

              setLoading(false);
              openWindowOrTab(
                'supplier-base/survey-request-sender-confirmation/' + sendId
              );
            } catch (err) {
              error(getWorkerExceptionMessage(intl, err));
              setLoading(false);
              throw err;
            }
            break;
          }
          case 'B03': // 見積
            openWindowOrTab(
              'estimate/est-estimate-request-confirmation/' + recordId
            );
            break;
        }
      }
    }
  };

  // [実行処理]作業完了
  const handleTicketComplete = async (result: FileCommentDialogFormOption) => {
    setLoading(true);
    try {
      const tmpData = await createAttachmentFiles(result.files ?? [], 'B06');
      await window.App.services.ui.worker.apiCall({
        actionName: 'completeWtTicket',
        request: {
          workTaskId: item?.workTaskId ?? '',
          resultRemarks: result.comment ?? '',
          resultAttachments: tmpData,
        },
      });

      setOpenTicketCompleteDialog(false);
      success([successMessage]);
      handleReload();
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  // [実行処理]案件完了
  const handleConfirmComplete = () => {
    setConfirmDialogInfo({
      viewMessage: viewMessageComplete,
      onDecision: handleComplete,
    });
    setOpenConfirmDialog(true);
  };
  const handleComplete = async () => {
    setLoading(true);
    try {
      await window.App.services.ui.worker.apiCall({
        actionName: 'completeWorkTask',
        request: {
          workTaskId: item?.workTaskId ?? '',
        },
      });

      setOpenConfirmDialog(false);
      success([successMessage]);
      handleReload();
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  // [実行処理]案件再開
  const handleConfirmUncomplete = () => {
    setConfirmDialogInfo({
      viewMessage: viewMessageUncomplete,
      onDecision: handleUncomplete,
    });
    setOpenConfirmDialog(true);
  };
  const handleUncomplete = async () => {
    setLoading(true);
    try {
      await window.App.services.ui.worker.apiCall({
        actionName: 'uncompleteWorkTask',
        request: {
          workTaskId: item?.workTaskId ?? '',
        },
      });
      setOpenConfirmDialog(false);
      success([successMessage]);
      handleReload();
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  // [実行処理]作業再開
  const handleTicketUncomplete = async () => {
    setLoading(true);
    try {
      await window.App.services.ui.worker.apiCall({
        actionName: 'uncompleteWtTicket',
        request: {
          workTaskId: item?.workTaskId ?? '',
          comment: ticketUncompleteComment,
        },
      });
      setOpenTicketUncompleteDialog(false);
      success([successMessage]);
      handleReload();
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  // 案件紐付け情報一覧の設定
  const setMenuActionItem = (): MenuActionItem[] => {
    const menuActionItems: MenuActionItem[] = [];
    menuActionItems.push({
      menuActionType: 'headerIconMenu',
      menu: headerIconEvent(),
      maxMenuColumn: 1,
    });
    menuActionItems.push({
      menuActionType: 'listIconMenu',
      menu: listIconEvent(),
    });
    return menuActionItems;
  };

  const headerIconEvent = (): ViewMenu[] => {
    const menuItems: ViewMenu[] = [];
    // 追加
    if (isVisibleWorkTaskRelationAdd) {
      menuItems.push({
        name: 'noteadd',
        func: () => {
          setOpenSelectConnectingDialog(true);
        },
      });
    }
    return menuItems;
  };

  const listIconEvent = (): ViewMenu[] => {
    const menuItems: ViewMenu[] = [];
    // 確認
    menuItems.push({
      name: 'description',
      func: (v?: string[]) => {
        handleConnectingConfirm(v ?? []);
      },
    });
    // 削除
    if (isVisibleWorkTaskRelationDelete) {
      menuItems.push({
        name: 'delete',
        func: (v?: string[]) => {
          handleConfirmConnectingDelete(v ?? []);
        },
      });
    }
    return menuItems;
  };

  const onOriginalItem = (items: unknown) => {
    const tasks =
      (items as unknown as mtechnavi.api.worktask.IWorkTaskRelationContent[]) ??
      [];
    setWorkTaskRelationContentsOpenTasks(
      tasks.filter((v) => v.status?.systemName === 'B00').length
    );
    workTaskRelationContentsData.current = tasks;
  };

  // 依頼情報エリアコンポーネント
  const renderRequestTabInfoComponent = () => {
    return (
      <>
        {/* ////依頼内容//// */}
        <div
          className={`input-blocktitle-outer ${
            accordionState.requestContent ? '' : 'close'
          }`}
        >
          <h3
            className="input-blocktitle"
            onClick={() => accordionAction('requestContent')}
          >
            {GetMessage({ id: 'requestContent', prefixId: VIEW_ID })}
          </h3>
        </div>
        <div
          className={`input-blockbody indent ${
            accordionState.requestContent ? '' : 'close'
          }`}
        >
          <div className="input-line label-margin">
            <div className="item-group-100">
              <div className="w-50">
                <IconButton
                  name=""
                  iconType="edit"
                  buttonType="basic"
                  onClick={() => {
                    setOpenManagementStaffEditDialog(true);
                  }}
                  disabled={!isVisibleManagementStaffEdit}
                />
                {/* 案件管理担当 */}
                {GetMessage({
                  id: 'managementStaff',
                  prefixId: VIEW_ID,
                })}{' '}
                : {item?.managementStaff?.displayName}
                {' ('}
                {convertOrganizationStructureReferenceToDisplayName(
                  item?.managementOrganization
                )}
                {')'}
              </div>
            </div>
          </div>
          <div className="input-line label-margin">
            <div className="item-group-100">
              <div className="w-50">
                <IconButton
                  name=""
                  iconType="edit"
                  buttonType="basic"
                  onClick={() => {
                    setOpenDueDateEditDialog(true);
                  }}
                  disabled={!isVisibleDueDateEdit}
                />
                {/* 案件完了期日 */}
                {GetMessage({
                  id: 'dueDate',
                  prefixId: VIEW_ID,
                })}{' '}
                : {`${getDateFormatWithTimezone(item?.worktaskDueDateDt)}`}
              </div>
            </div>
          </div>
          <div className="input-line label-margin">
            <div className="item-group-100">
              <div className="w-100">
                {/* 作業依頼内容 */}
                <Textarea
                  name="contents"
                  labelId="WT_WORKTASK_CONFIRMATION.contents"
                  className="w-100  mh-middle"
                  value={item?.contents ?? ''}
                  columns={viewPreset.columns}
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
        {/* ////依頼参考情報//// */}
        <div
          className={`input-blocktitle-outer ${
            accordionState.requestReference ? '' : 'close'
          }`}
        >
          <h3
            className="input-blocktitle"
            onClick={() => accordionAction('requestReference')}
          >
            {GetMessage({ id: 'requestReference', prefixId: VIEW_ID })}
          </h3>
        </div>
        <div
          className={`input-blockbody indent ${
            accordionState.requestReference ? '' : 'close'
          }`}
        >
          <div className="input-line label-margin">
            <div className="item-group-100">
              <div className="w-25-icon">
                <IconButton
                  name=""
                  iconType="edit"
                  buttonType="basic"
                  onClick={() => {
                    setOpenRequestReferenceEditDialog(true);
                  }}
                  disabled={!isVisibleRequestReferenceEdit}
                />
              </div>
            </div>
          </div>
          <div className="input-line label-margin">
            <div className="item-group-100">
              <div className="w-100">
                {/* 依頼メモ */}
                <Textarea
                  name="requestRemarks"
                  labelId="WT_WORKTASK_CONFIRMATION.requestRemarks"
                  className="w-100  mh-middle"
                  value={item?.requestRemarks ?? ''}
                  columns={viewPreset.columns}
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="input-line label-margin">
            <div className="item-group-100">
              <div className="w-33">
                {/* 添付ファイル（依頼） */}
                <SimpleListView
                  data={requestAttachmentItems}
                  viewOptions={{
                    readonly: true,
                    previewRowCount: 5,
                    keyColumn: 'id',
                    columns: [
                      {
                        header: {
                          id: 'attachmentRequest',
                          prefixId: VIEW_ID,
                        },
                        propertyName: 'filename',
                      },
                    ],
                  }}
                  actionOptions={{
                    onRowClick: (item: AttachmentItems) => {
                      autoDownloadFileOnlyName(
                        item.filename ?? '',
                        item.assetId ?? ''
                      );
                    },
                    onFullDownLoad: () => {
                      autoBulkDownload(
                        (requestAttachmentItems ?? []).map(
                          (item) => item.assetId ?? ''
                        ),
                        intl,
                        VIEW_ID
                      );
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  // 作業情報エリアコンポーネント
  const renderWorkTabInfoComponent = () => {
    return (
      <>
        {/* ////作業状況//// */}
        <div
          className={`input-blocktitle-outer ${
            accordionState.workStatus ? '' : 'close'
          }`}
        >
          <h3
            className="input-blocktitle"
            onClick={() => accordionAction('workStatus')}
          >
            {GetMessage({ id: 'workStatus', prefixId: VIEW_ID })}
          </h3>
        </div>
        <div
          className={`input-blockbody indent ${
            accordionState.workStatus ? '' : 'close'
          }`}
        >
          <div className="input-line label-margin">
            <div className="item-group-100">
              <div className="w-60">
                {/* ////案件紐付け情報//// */}
                <ListView
                  fullMethodName={FullMethodName_ListWorkTaskRelationContents}
                  pageInfo={{
                    schema: schema,
                    preset: listViewPreset,
                    menuItem: setMenuActionItem(),
                    menuTarget: 'workTaskRelation.workTaskRelationId',
                    headerTitle: { viewId: VIEW_ID_WT_WORKTASK_RELATION_LIST },
                    presetItems: childrenPresetItem,
                    listSkipType: {
                      isFilter: true,
                      isTotal: true,
                      isOutput: true,
                      isReload: true,
                      isCheckbox: true,
                      isFooterMenu: true,
                      isListActionMenu: true,
                    },
                  }}
                  isReload={isReloadListView}
                  stateOption={{
                    onOriginalItemState: onOriginalItem,
                  }}
                  isSuspendReload={isSuspendReload}
                  filterItemOption={{
                    isRequestBodyFilter: true,
                    initialRequestBodyFilterItems: initialFilter,
                  }}
                />
              </div>
              {/* 作業メモ */}
              {isVisibleTicketRemarksEdit && (
                <div className="w-40">
                  <CaptionButton
                    name=""
                    className="button-bottom-margin"
                    caption="作業メモ"
                    onClick={() => {
                      setTicketRemarks(item?.ticket?.remarks ?? '');
                      setOpenTicketRemarksEditDialog(true);
                    }}
                    buttonType="basic"
                  />
                </div>
              )}
            </div>
          </div>
          <div className="input-line label-margin">
            <div className="item-group-100">
              <div className="w-50">
                {/* 未完了作業 */}
                {GetMessage({
                  id: 'uncompleteTask',
                  prefixId: VIEW_ID,
                })}{' '}
                :{' '}
                {`${
                  workTaskRelationContentsOpenTasks > 0
                    ? `${GetMessage({
                        id: 'uncompleteTask.yes',
                        prefixId: VIEW_ID,
                      })}（${workTaskRelationContentsOpenTasks}${GetMessage({
                        id: 'uncompleteTask.unit',
                        prefixId: VIEW_ID,
                      })}）`
                    : GetMessage({ id: 'uncompleteTask.no', prefixId: VIEW_ID })
                }`}
              </div>
            </div>
          </div>
          <div className="input-line label-margin">
            <div className="item-group-100">
              {/* 作業完了 */}
              {isVisibleTicketComplete && (
                <div className="w-50">
                  <CaptionButton
                    name=""
                    className="button-bottom-margin"
                    caption="作業完了する"
                    onClick={() => {
                      setOpenTicketCompleteDialog(true);
                    }}
                    buttonType="basic"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        {/* ////作業結果//// */}
        <div
          className={`input-blocktitle-outer ${
            accordionState.workResult ? '' : 'close'
          }`}
        >
          <h3
            className="input-blocktitle"
            onClick={() => accordionAction('workResult')}
          >
            {GetMessage({ id: 'workResult', prefixId: VIEW_ID })}
          </h3>
        </div>
        <div
          className={`input-blockbody indent ${
            accordionState.workResult ? '' : 'close'
          }`}
        >
          <div className="input-line label-margin">
            <div className="item-group-100">
              <div className="w-100">
                {/* 作業結果 */}
                <Textarea
                  name="ticketResultRemarks"
                  labelId="WT_WORKTASK_CONFIRMATION.ticketResultRemarks"
                  className="w-100  mh-middle"
                  value={item?.ticket?.resultRemarks ?? ''}
                  columns={viewPreset.columns}
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="input-line label-margin">
            <div className="item-group-100">
              <div className="w-33">
                {/* 添付ファイル（結果） */}
                <SimpleListView
                  data={ticketResultAttachmentItems}
                  viewOptions={{
                    readonly: true,
                    previewRowCount: 5,
                    keyColumn: 'id',
                    columns: [
                      {
                        header: {
                          id: 'attachmentResult',
                          prefixId: VIEW_ID,
                        },
                        propertyName: 'filename',
                      },
                    ],
                  }}
                  actionOptions={{
                    onRowClick: (item: AttachmentItems) => {
                      autoDownloadFileOnlyName(
                        item.filename ?? '',
                        item.assetId ?? ''
                      );
                    },
                    onFullDownLoad: () => {
                      autoBulkDownload(
                        (ticketResultAttachmentItems ?? []).map(
                          (item) => item.assetId ?? ''
                        ),
                        intl,
                        VIEW_ID
                      );
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <Container viewId={VIEW_ID}>
        <div className="WtWorkTaskConfirmation">
          <div className="header">
            <PageNavigation
              backpagePath={backPath}
              iconItems={getPageNavigationIconItems()}
              pageInfo={{
                isDisableBackNavi: !backPath,
                isVisibleMoveNavi: true,
                isFirstPage,
                isMaxPage,
                pageNumber: page.pageNumber,
              }}
              handleMovePage={handleMovePage}
              infoOption={{
                lastUpdateInfo: { isVisibleUpdateInfo: false },
                issuerInfo: {
                  isVisibleIssuerInfo: true,
                  attribute: item?.requestedBy?.email ?? '',
                  attributeAt: item?.requestedAt ?? undefined,
                  content: item?.requestedBy?.displayName ?? '',
                },
              }}
              handleBackPage={() => {
                const state: PageState = {
                  ids: [item?.workTaskId ?? ''],
                  sourceViewId: VIEW_ID,
                  naviFilters: location.naviFilters,
                  beforeStateIds: location.ids,
                  baseViewOption: location.baseViewOption,
                };
                if (backPath) {
                  navi(backPath, { state });
                }
              }}
            />
          </div>
          <div
            className="scroll-main-contents-area"
            style={{
              maxHeight: mainContentHeight,
            }}
          >
            <div className="info">
              <div className="basic-info-body">
                {/* ////通知枠//// */}
                <div
                  className={`notification-area ${
                    isShowNotification ? '' : 'close'
                  }`}
                >
                  {/* {履歴通知エリア} */}
                  <div className="input-line">
                    <div className="item-group-100">
                      <div className="w-50">
                        <div className="notification-display-area">
                          {historyMessage}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="input-line">
                  <div className="item-group-100">
                    <div className="w-77">
                      {/* 件名 */}
                      <h3 className="strong">{item?.displayName ?? ''}</h3>
                    </div>
                  </div>
                </div>
                <div className="input-line label-margin">
                  <div className="item-group-100">
                    <div className="w-50">
                      {/* ステータス */}
                      {item?.status?.displayNameLang
                        ? item?.status?.displayNameLang.ja ?? ''
                        : ''}
                    </div>
                  </div>
                </div>
                <div className="input-line label-margin">
                  <div className="item-group-100">
                    <div className="w-50">
                      <IconButton
                        name=""
                        iconType="edit"
                        buttonType="basic"
                        onClick={() => {
                          setOpenWorkStaffEditDialog(true);
                        }}
                        disabled={!isVisibleWorkStaffEdit}
                      />
                      {/* 作業担当 */}
                      {GetMessage({
                        id: 'workStaff',
                        prefixId: VIEW_ID,
                      })}{' '}
                      : {item?.workStaff?.displayName}
                      {' ('}
                      {convertOrganizationStructureReferenceToDisplayName(
                        item?.workOrganization
                      )}
                      {')'}
                    </div>
                    <div className="w-50">
                      {/* 指図案件番号 */}
                      {GetMessage({
                        id: 'workTaskAutoName',
                        prefixId: VIEW_ID,
                      })}{' '}
                      :{' '}
                      {item?.workTaskAutoName
                        ? Long.fromValue(item?.workTaskAutoName).toNumber()
                        : ''}
                    </div>
                  </div>
                </div>
                <div className="input-line label-margin">
                  <div className="item-group-100">
                    <div className="w-50">
                      <IconButton
                        name=""
                        iconType="edit"
                        buttonType="basic"
                        onClick={() => {
                          setOpenTicketDueDateEditDialog(true);
                        }}
                        disabled={!isVisibleTicketDueDateEdit}
                      />
                      {/* 作業完了期日 */}
                      {GetMessage({
                        id: 'ticketDueDate',
                        prefixId: VIEW_ID,
                      })}{' '}
                      :{' '}
                      {item?.asap
                        ? `ASAP` +
                          (item?.asapComment ? `（${item?.asapComment}）` : ``)
                        : `${getDateFormatWithTimezone(
                            item?.worktaskTicketDueDateDt
                          )}`}
                    </div>
                    <div className="w-50">
                      {/* 指図案件カタログ */}
                      {GetMessage({
                        id: 'workTaskCatalog',
                        prefixId: VIEW_ID,
                      })}{' '}
                      : {item?.workTaskCatalog?.displayName ?? ''}
                    </div>
                  </div>
                </div>
                <div className="input-line label-margin">
                  <div className="item-group-100">
                    <div className="w-50">
                      <IconButton
                        name=""
                        iconType="edit"
                        buttonType="basic"
                        onClick={() => {
                          setOpenTicketScheduledDateEditDialog(true);
                        }}
                        disabled={!isVisibleTicketScheduledDateEdit}
                      />
                      {/* 作業完了予定日 */}
                      {GetMessage({
                        id: 'ticketScheduledDate',
                        prefixId: VIEW_ID,
                      })}{' '}
                      :{' '}
                      {`${getDateFormatWithTimezone(
                        item?.worktaskTicketScheduledDateDt
                      )}`}
                    </div>
                  </div>
                </div>
                {/* ////タブ項目//// */}
                <div className="tabPanelContents">
                  {tabs.map((v, index) => {
                    const selectPane = v === tabPanel ? 'current' : '';
                    return (
                      <div
                        key={`tab-${index}`}
                        className={`tabPanel ${selectPane}`}
                        onClick={() => setTabPanel(v)}
                      >
                        {GetMessageWithIntl(intl, { prefixId: VIEW_ID, id: v })}
                      </div>
                    );
                  })}
                </div>
                {/* ////依頼情報//// */}
                {isCurrentTab('requestTabInfo') &&
                  renderRequestTabInfoComponent()}
                {/* ////作業情報//// */}
                {isCurrentTab('workTabInfo') && renderWorkTabInfoComponent()}
              </div>
            </div>
          </div>
          <div className="footer" ref={footerRef}>
            <div className="footer-contents">
              <div className="input-line">
                {/* 案件完了 */}
                {isVisibleComplete && (
                  <CaptionButton
                    className="button-margin"
                    name=""
                    caption="案件完了"
                    onClick={handleConfirmComplete}
                    buttonType="basic"
                  ></CaptionButton>
                )}
                {/* 案件再開 */}
                {isVisibleUncomplete && (
                  <CaptionButton
                    className="button-margin"
                    name=""
                    caption="案件再開"
                    onClick={handleConfirmUncomplete}
                    buttonType="basic"
                  ></CaptionButton>
                )}
                {/* 作業再開 */}
                {isVisibleTicketUncomplete && (
                  <CaptionButton
                    className="button-margin"
                    name=""
                    caption="作業再開"
                    onClick={() => {
                      setTicketUncompleteComment('');
                      setOpenTicketUncompleteDialog(true);
                    }}
                    buttonType="basic"
                  ></CaptionButton>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* 確認ダイアログ */}
        <ConfirmationDialog
          isOpen={isOpenConfirmDialog}
          viewMessage={confirmDialogInfo.viewMessage}
          onDecision={confirmDialogInfo.onDecision}
          onCancel={() => {
            setOpenConfirmDialog(false);
          }}
        />
        {/* 破棄ダイアログ */}
        <CommentDialog
          isOpen={isOpenDiscardDialog}
          inputOption={{ comment: discardComment }}
          inputStateOption={{ onChangeComment: setDiscardComment }}
          messageOption={{
            headerLabelId: {
              id: 'worktask_discard',
              prefixId: 'DIALOG_TITLE',
            },
            messageLabelId: {
              id: 'worktask_discard',
              prefixId: 'DIALOG_DESCRIPTION',
            },
            decisionLabelId: { id: 'decision' },
          }}
          onDecision={handleDiscard}
          onCancel={() => {
            setOpenDiscardDialog(false);
          }}
        />
        {/* システム通知設定ダイアログ */}
        <SystemNotificationSettingDialog
          isOpen={isOpenSystemNotificationDialog}
          inputData={systemNotificationUsers}
          onDecision={handleSystemNotificationSetting}
          onCancel={() => {
            setOpenSystemNotificationDialog(false);
          }}
        />
        {/* 履歴ダイアログ */}
        <HistoryDialog
          isOpen={isOpenHistoryDialog}
          messageOption={{
            headerLabelId: {
              id: 'history',
              prefixId: 'DIALOG_TITLE',
            },
          }}
          inputOption={{
            data: historys ?? [],
            keyColumn: 'history_id',
            columns: [
              {
                propertyName: 'slip_category',
                width: '12rem',
                header: {
                  prefixId: 'HistoryDialog',
                  id: 'slip_category',
                },
              },
              {
                propertyName: 'occurred_at',
                width: '12rem',
                header: {
                  prefixId: 'HistoryDialog',
                  id: 'occurred_at',
                },
              },
              {
                propertyName: 'content',
                header: {
                  prefixId: 'HistoryDialog',
                  id: 'content',
                },
              },
            ],
          }}
          onCancel={() => {
            setOpenHistoryDialog(false);
          }}
        />
        {/* 作業担当変更ダイアログ */}
        <ChangeStaffDialog
          isOpen={isOpenWorkStaffEditDialog}
          targetUserIds={workStaffTargetIds.current}
          componentDisplayName={
            convertOrganizationStructureReferenceToFilterboxItem(
              item?.workOrganization
            )?.displayName ?? ''
          }
          onDecision={handleWorkStaffEdit}
          onCancel={() => {
            setOpenWorkStaffEditDialog(false);
          }}
        />
        {/* 作業完了期日変更ダイアログ */}
        <DeadlineChangeDialog
          isOpen={isOpenTicketDueDateEditDialog}
          outputOption={{
            currentDeadline: item?.asap
              ? 'ASAP'
              : convertDate(item?.worktaskTicketDueDateDt || null) || null,
          }}
          inputOption={{ buttonType: 'basic' }}
          messageOption={{
            headerLabelId: {
              id: 'wtTicketDueDateEdit',
              prefixId: 'DIALOG_TITLE',
            },
            messageLabelId: {
              id: 'wtTicketDueDateEdit',
              prefixId: 'DIALOG_DESCRIPTION',
            },
            currentLabelId: {
              id: 'current_wt_ticket_duedate',
              prefixId: 'DeadlineChangeDialog',
            },
            captionLabelId: {
              id: 'wt_ticket_duedate',
              prefixId: 'DeadlineChangeDialog',
            },
            decisionLabelId: { id: 'save' },
          }}
          onDecision={handleTicketDueDateEdit}
          onCancel={() => {
            setOpenTicketDueDateEditDialog(false);
          }}
        />
        {/* 作業完了予定日変更ダイアログ */}
        <DeadlineChangeDialog
          isOpen={isOpenTicketScheduledDateEditDialog}
          outputOption={{
            currentDeadline:
              convertDate(item?.worktaskTicketScheduledDateDt || null) || null,
          }}
          inputOption={{ buttonType: 'basic' }}
          messageOption={{
            headerLabelId: {
              id: 'wtTicketScheduledDateEdit',
              prefixId: 'DIALOG_TITLE',
            },
            messageLabelId: {
              id: 'wtTicketScheduledDateEdit',
              prefixId: 'DIALOG_DESCRIPTION',
            },
            currentLabelId: {
              id: 'current_wt_ticket_scheduleddate',
              prefixId: 'DeadlineChangeDialog',
            },
            captionLabelId: {
              id: 'wt_ticket_scheduleddate',
              prefixId: 'DeadlineChangeDialog',
            },
            decisionLabelId: { id: 'save' },
          }}
          onDecision={handleTicketScheduledDateEdit}
          onCancel={() => {
            setOpenTicketScheduledDateEditDialog(false);
          }}
        />
        {/* 案件管理担当変更ダイアログ */}
        <ChangeStaffDialog
          isOpen={isOpenManagementStaffEditDialog}
          targetUserIds={managementStaffTargetIds.current}
          componentDisplayName={
            convertOrganizationStructureReferenceToFilterboxItem(
              item?.managementOrganization
            )?.displayName ?? ''
          }
          onDecision={handleManagementStaffEdit}
          onCancel={() => {
            setOpenManagementStaffEditDialog(false);
          }}
        />
        {/* 案件完了期日変更ダイアログ */}
        <DeadlineChangeDialog
          isOpen={isOpenDueDateEditDialog}
          outputOption={{
            currentDeadline:
              convertDate(item?.worktaskDueDateDt || null) || null,
          }}
          inputOption={{ buttonType: 'basic' }}
          messageOption={{
            headerLabelId: {
              id: 'wtDueDateEdit',
              prefixId: 'DIALOG_TITLE',
            },
            messageLabelId: {
              id: 'wtDueDateEdit',
              prefixId: 'DIALOG_DESCRIPTION',
            },
            currentLabelId: {
              id: 'current_wt_duedate',
              prefixId: 'DeadlineChangeDialog',
            },
            captionLabelId: {
              id: 'wt_duedate',
              prefixId: 'DeadlineChangeDialog',
            },
            decisionLabelId: { id: 'save' },
          }}
          onDecision={handleDueDateEdit}
          onCancel={() => {
            setOpenDueDateEditDialog(false);
          }}
        />
        {/* 参考情報編集ダイアログ */}
        <FileUploadWithCommentDialog
          isOpen={isOpenRequestReferenceEditDialog}
          uploaderOption={{
            validateOption: {
              maxFileSizeInMebis: 50,
              maxFileCount: 10,
            },
          }}
          messageOption={{
            dialogTitle: {
              id: 'edit_request_reference',
              prefixId: 'DIALOG_TITLE',
            },
            commentLabelId: 'EditRequestReferenceDialog.comment',
            buttonType: { id: 'save' },
            simpleListViewHeader: {
              id: 'simpleListViewHeader',
              prefixId: 'EditRequestReferenceDialog',
            },
          }}
          inputOption={{
            comment: item?.requestRemarks ?? '',
            files: requestAttachmentItems ?? [],
          }}
          onDecision={handleRequestReferenceEdit}
          onCancel={() => {
            setOpenRequestReferenceEditDialog(false);
          }}
        />
        {/* 作業メモ登録ダイアログ */}
        <CommentDialog
          isOpen={isOpenTicketRemarksEditDialog}
          inputOption={{
            comment: ticketRemarks,
            butonType: 'basic',
          }}
          inputStateOption={{ onChangeComment: setTicketRemarks }}
          messageOption={{
            headerLabelId: {
              id: 'ticket_remarks',
              prefixId: 'DIALOG_TITLE',
            },
            captionLabelId: {
              id: 'remarks',
              prefixId: 'CommentDialog',
            },
            decisionLabelId: { id: 'save' },
          }}
          onDecision={handleTicketRemarksEdit}
          onCancel={() => {
            setOpenTicketRemarksEditDialog(false);
          }}
        />
        {/* 紐付け分類選択ダイアログ */}
        <SelectConnectingDialog
          isOpen={isOpenSelectConnectingDialog}
          onDecision={handleSelectConnecting}
          onCancel={() => {
            setOpenSelectConnectingDialog(false);
          }}
        />
        {/* 紐付けダイアログ */}
        <ConnectingDialog
          isOpen={isOpenConnectingDialog}
          FullMethodNameForExtendListView={
            fullMethodNameForConnectingDialogExtendListView
          }
          FullMethodNames={fullMethodNamesForConnectingDialog}
          viewId={viewIdForConnectingDialog}
          keyId={keyIdForConnectingDialog}
          aggregateStagesOption={
            viewIdForConnectingDialog === 'WT_LINKED_SURVEY'
              ? {
                  aggregateStages: aggregateStagesForSurvey,
                  aggregateType: aggregatedTypeForSurvey,
                }
              : undefined
          }
          messageOption={{
            connectingCategoryLabel: labelOptionForConnectingDialog,
            headerOption: {
              id: 'CONNECTING',
              prefixId: 'DIALOG_TITLE',
            },
          }}
          onDecision={(ids: string[]) => {
            if (ids.some((v) => v === item?.workTaskId)) {
              error([GetMessageWithIntl(intl, { id: 'E0000089' })]);
              return;
            }
            handleConnecting(ids);
          }}
          onCancel={() => {
            setOpenConnectingDialog(false);
          }}
          formatSchemaInfo={(schema: Schema) => getFormmaterSchema(schema)}
        />
        {/* 作業完了ダイアログ */}
        <FileUploadWithCommentDialog
          isOpen={isOpenTicketCompleteDialog}
          uploaderOption={{
            validateOption: {
              maxFileSizeInMebis: 50,
              maxFileCount: 10,
            },
          }}
          messageOption={{
            dialogTitle: {
              id: 'complete_task',
              prefixId: 'DIALOG_TITLE',
            },
            commentLabelId: 'CompleteTaskDialog.comment',
            buttonType: {
              id: 'complete',
            },
            simpleListViewHeader: {
              id: 'simpleListViewHeader',
              prefixId: 'CompleteTaskDialog',
            },
          }}
          inputOption={{
            comment: item?.ticket?.resultRemarks ?? '',
            files: ticketResultAttachmentItems ?? [],
          }}
          onDecision={handleTicketComplete}
          onCancel={() => {
            setOpenTicketCompleteDialog(false);
          }}
        />
        {/* 作業再開ダイアログ */}
        <CommentDialog
          isOpen={isOpenTicketUncompleteDialog}
          inputOption={{ comment: ticketUncompleteComment, butonType: 'basic' }}
          inputStateOption={{ onChangeComment: setTicketUncompleteComment }}
          messageOption={{
            headerLabelId: {
              id: 'ticket_uncomplete',
              prefixId: 'DIALOG_TITLE',
            },
            messageLabelId: {
              id: 'ticket_uncomplete',
              prefixId: 'DIALOG_DESCRIPTION',
            },
            decisionLabelId: {
              id: 'ticketUncomplete',
              prefixId: VIEW_ID,
            },
          }}
          onDecision={handleTicketUncomplete}
          onCancel={() => {
            setOpenTicketUncompleteDialog(false);
          }}
        />
        <Toast />
      </Container>
      {isLoading && <LoadingIcon />}
    </>
  );
}
