// UiController
export const FullMethodName_ListPresetMenus =
  'mtechnavi.api.uicontroller.UiController/ListPresetMenus';
export const FullMethodName_ListPresets =
  'mtechnavi.api.uicontroller.UiController/ListPresets';
// AssetInventory
export const FullMethodName_ListAssets =
  'mtechnavi.api.assetinventory.AssetInventory/ListAssets';
// BlueprintService
export const FullMethodName_ListBlueprints =
  'mtechnavi.api.blueprint.BlueprintService/ListBlueprints';
// TransactionUnitService
export const FullMethodName_ListTransactionUnits =
  'mtechnavi.api.blueprint.TransactionUnitService/ListTransactionUnits';
// ComponentUnitService
export const FullMethodName_ListComponentUnits =
  'mtechnavi.api.company.ComponentUnitService/ListComponentUnits';
// BusinessUnitService
export const FullMethodName_ListBusinessUnits =
  'mtechnavi.api.company.BusinessUnitService/ListBusinessUnits';
export const FullMethodName_GetBusinessUnitManagement =
  'mtechnavi.api.company.BusinessUnitManagementService/GetBusinessUnitManagement';
export const FullMethodName_ListBusinessUnitManagements =
  'mtechnavi.api.company.BusinessUnitManagementService/ListBusinessUnitManagements';
export const FullMethodName_ListBusinessUnitManagementHistorys =
  'mtechnavi.api.company.BusinessUnitManagementService/ListHistorys';
export const FullMethodName_ListBusinessUnitFinancials =
  'mtechnavi.api.company.BusinessUnitManagementService/ListBusinessUnitFinancials';
export const FullMethodName_ListBusinessUnitManagementContents =
  'mtechnavi.api.company.BusinessUnitManagementService/ListBusinessUnitManagementContents';
export const FullMethodName_ListBusinessUnitContactContents =
  'mtechnavi.api.company.BusinessUnitManagementService/ListBusinessUnitContactContents';
export const FullMethodName_ListLocalBusinessUnitContactContents =
  'mtechnavi.api.company.BusinessUnitManagementService/ListLocalBusinessUnitContactContents';
export const FullMethodName_ListSharedContactContents =
  'mtechnavi.api.company.BusinessUnitManagementService/ListSharedContactContents';
export const FullMethodName_SharedListBusinessUnitBranchs =
  'mtechnavi.api.company.BusinessUnitManagementService/SharedListBusinessUnitBranchs';
export const FullMethodName_SharedListBusinessUnitFinancials =
  'mtechnavi.api.company.BusinessUnitManagementService/SharedListBusinessUnitFinancials';
export const FullMethodName_ListLocalBusinessUnitFinancials =
  'mtechnavi.api.company.BusinessUnitManagementService/ListLocalBusinessUnitFinancials';
export const FullMethodName_SharedListBusinessUnitFacilitiess =
  'mtechnavi.api.company.BusinessUnitManagementService/SharedListBusinessUnitFacilitiess';
export const FullMethodName_ListLocalBusinessUnitFacilitiess =
  'mtechnavi.api.company.BusinessUnitManagementService/ListLocalBusinessUnitFacilitiess';
export const FullMethodName_SharedListBusinessUnitCertificates =
  'mtechnavi.api.company.BusinessUnitManagementService/SharedListBusinessUnitCertificates';
export const FullMethodName_ListLocalBusinessUnitCertificates =
  'mtechnavi.api.company.BusinessUnitManagementService/ListLocalBusinessUnitCertificates';
export const FullMethodName_SharedListBusinessUnitStrengths =
  'mtechnavi.api.company.BusinessUnitManagementService/SharedListBusinessUnitStrengths';
export const FullMethodName_ListLocalBusinessUnitStrengths =
  'mtechnavi.api.company.BusinessUnitManagementService/ListLocalBusinessUnitStrengths';
export const FullMethodName_ListBusinessUnitDocuments =
  'mtechnavi.api.company.BusinessUnitManagementService/ListBusinessUnitDocuments';
export const FullMethodName_SharedListBusinessUnitSkills =
  'mtechnavi.api.company.BusinessUnitManagementService/SharedListBusinessUnitSkills';
export const FullMethodName_ListLocalBusinessUnitSkills =
  'mtechnavi.api.company.BusinessUnitManagementService/ListLocalBusinessUnitSkills';
export const FullMethodName_ListBusinessUnitChangeNotifications =
  'mtechnavi.api.company.BusinessUnitManagementService/ListBusinessUnitChangeNotifications';
export const FullMethodName_ListBusinessUnitFacilitiess =
  'mtechnavi.api.company.BusinessUnitManagementService/ListBusinessUnitFacilitiess';
export const FullMethodName_ListBusinessUnitCertificates =
  'mtechnavi.api.company.BusinessUnitManagementService/ListBusinessUnitCertificates';
export const FullMethodName_ListBusinessUnitStrengths =
  'mtechnavi.api.company.BusinessUnitManagementService/ListBusinessUnitStrengths';
export const FullMethodName_ListBusinessUnitProfiles =
  'mtechnavi.api.company.BusinessUnitManagementService/ListBusinessUnitProfiles';
export const FullMethodName_ListBusinessUnitBranchs =
  'mtechnavi.api.company.BusinessUnitManagementService/ListBusinessUnitBranchs';
export const FullMethodName_ListBusinessUnitSkills =
  'mtechnavi.api.company.BusinessUnitManagementService/ListBusinessUnitSkills';
export const FullMethodName_ListBusinessUnitRankRequestContents =
  'mtechnavi.api.company.BusinessUnitManagementService/ListBusinessUnitRankRequestContents';
export const FullMethodName_ListLocalBusinessUnitRankRequestContents =
  'mtechnavi.api.company.BusinessUnitManagementService/ListLocalBusinessUnitRankRequestContents';
export const FullMethodName_ListBusinessUnitRankReceptionContents =
  'mtechnavi.api.company.BusinessUnitManagementService/ListBusinessUnitRankReceptionContents';
export const FullMethodName_GetCompany =
  'mtechnavi.api.company.CompanyService/GetCompany';

// StaffService
export const FullMethodName_ListStaffs =
  'mtechnavi.api.company.StaffService/ListStaffs';
export const FullMethodName_ListStaffAndComponentUnits =
  'mtechnavi.api.company.StaffService/ListStaffAndComponentUnits';
// ForumService
export const FullMethodName_CheckUsableForum =
  'mtechnavi.api.forum.ForumService/CheckUsableForum';
export const FullMethodName_ListThreads =
  'mtechnavi.api.forum.ForumService/ListThreads';
export const FullMethodName_ListComments =
  'mtechnavi.api.forum.ForumService/ListComments';
export const FullMethodName_ListMarkers =
  'mtechnavi.api.forum.ForumService/ListMarkers';
// TenantAdminService
export const FullMethodName_ListUserAttributes =
  'mtechnavi.api.tenantadmin.TenantAdminService/ListUserAttributes';
export const FullMethodName_ListUserGroupAttributes =
  'mtechnavi.api.tenantadmin.TenantAdminService/ListUserGroupAttributes';
export const FullMethodName_ListUserGroupAttributeContents =
  'mtechnavi.api.tenantadmin.TenantAdminService/ListUserGroupAttributeContents';
// ProgramOptionService
export const FullMethodName_ListProgramOptions =
  'mtechnavi.api.programoption.ProgramOptionService/ListProgramOptions';
// SurveyService
export const FullMethodName_ListHistorys =
  'mtechnavi.api.survey.SurveyService/ListHistorys';
// SurveySenderService
export const FullMethodName_ExpandedListSurveyRequestContents =
  'mtechnavi.api.survey.SurveySenderService/ExpandedListSurveyRequestContents';
export const FullMethodName_ListSurveyBaseRequestContents =
  'mtechnavi.api.survey.SurveySenderService/ListSurveyBaseRequestContents';
export const FullMethodName_ListSurveyRequestContents =
  'mtechnavi.api.survey.SurveySenderService/ListSurveyRequestContents';
export const FullMethodName_ListSurveyRequestCatalogs =
  'mtechnavi.api.survey.SurveySenderService/ListSurveyRequestCatalogs';
export const FullMethodName_DeleteSurveyRequestCatalog =
  'mtechnavi.api.survey.SurveySenderService/DeleteSurveyRequestCatalog';
export const FullMethodName_SharedListSurveyReplyDueDateChangeRequests =
  'mtechnavi.api.survey.SurveySenderService/SharedListSurveyReplyDueDateChangeRequests';
export const FullMethodName_ListSurveyReplyDueDateChangeResults =
  'mtechnavi.api.survey.SurveySenderService/ListSurveyReplyDueDateChangeResults';
export const FullMethodName_SharedListSurveyResults =
  'mtechnavi.api.survey.SurveySenderService/SharedListSurveyResults';
export const FullMethodName_ListSurveyResultChangeRequests =
  'mtechnavi.api.survey.SurveySenderService/ListSurveyResultChangeRequests';
export const FullMethodName_ExportProxySurveyResultWorkForms =
  'mtechnavi.api.survey.SurveySenderService/ExportProxySurveyResultWorkForms';
export const FullMethodName_ListSurveyRequestWorkForms =
  'mtechnavi.api.survey.SurveySenderService/ListSurveyRequestWorkForms';
export const FullMethodName_ListSurveyResultWorkForms =
  'mtechnavi.api.survey.SurveySenderService/ListSurveyResultWorkForms';
// SurveyReceiverService
export const FullMethodName_ListSurveyReceptionContents =
  'mtechnavi.api.survey.SurveyReceiverService/ListSurveyReceptionContents';
export const FullMethodName_GetSurveyReception =
  'mtechnavi.api.survey.SurveyReceiverService/GetSurveyReception';
export const FullMethodName_ListSurveyReplyDueDateChangeRequests =
  'mtechnavi.api.survey.SurveyReceiverService/ListSurveyReplyDueDateChangeRequests';
export const FullMethodName_SharedListSurveyReplyDueDateChangeResults =
  'mtechnavi.api.survey.SurveyReceiverService/SharedListSurveyReplyDueDateChangeResults';
export const FullMethodName_SharedListSurveyRequestWorkForms =
  'mtechnavi.api.survey.SurveyReceiverService/SharedListSurveyRequestWorkForms';
export const FullMethodName_SharedListSurveyResultWorkForms =
  'mtechnavi.api.survey.SurveyReceiverService/SharedListSurveyResultWorkForms';
export const FullMethodName_ListResultFormShareManagements =
  'mtechnavi.api.survey.SurveyReceiverService/ListResultFormShareManagements';
export const FullMethodName_ExportSurveyResultWorkForms =
  'mtechnavi.api.survey.SurveyReceiverService/ExportSurveyResultWorkForms';

// TaskList
export const FullMethodName_UnreadListResources =
  'mtechnavi.api.forum.ForumTaskListService/UnreadListResources';
export const FullMethodName_UncompletedListSurveyRequest =
  'mtechnavi.api.survey.SurveyTaskListService/UncompletedListSurveyRequest';
export const FullMethodName_UnchangedListSurveyReplyDueDateChangeRequest =
  'mtechnavi.api.survey.SurveyTaskListService/UnchangedListSurveyReplyDueDateChangeRequest';
// WorkTask
export const FullMethodName_ListWorkTaskCatalogs =
  'mtechnavi.api.worktask.WorkTaskService/ListWorkTaskCatalogs';
export const FullMethodName_ListWorkTasks =
  'mtechnavi.api.worktask.WorkTaskService/ListWorkTasks';
export const FullMethodName_ListWorkTaskRelationContents =
  'mtechnavi.api.worktask.WorkTaskService/ListWorkTaskRelationContents';
export const FullMethodName_UncompletedListWorkTaskTickets =
  'mtechnavi.api.worktask.WorkTaskTaskListService/UncompletedListWorkTaskTickets';
export const FullMethodName_UncompletedListWorkTasks =
  'mtechnavi.api.worktask.WorkTaskTaskListService/UncompletedListWorkTasks';
export const FullMethodName_ListWorkTaskHistorys =
  'mtechnavi.api.worktask.WorkTaskService/ListHistorys';

// EstimateService
export const FullMethodName_ListEstimationHistorys =
  'mtechnavi.api.estimation.EstimationService/ListHistorys';
export const FullMethodName_ListEstimateRequestPlans =
  'mtechnavi.api.estimation.EstimateSenderService/ListEstimateRequestPlans';
export const FullMethodName_ListEstimateManagements =
  'mtechnavi.api.estimation.EstimateSenderService/ListEstimateManagements';
export const FullMethodName_ListEstimateRequests =
  'mtechnavi.api.estimation.EstimateSenderService/ListEstimateRequests';
export const FullMethodName_ListEstimateRequestDetails =
  'mtechnavi.api.estimation.EstimateSenderService/ListEstimateRequestDetails';
export const FullMethodName_ListEstimateRequestUnits =
  'mtechnavi.api.estimation.EstimateSenderService/ListEstimateRequestUnits';
export const FullMethodName_ListEstimateManagementSummarys =
  'mtechnavi.api.estimation.EstimateSenderService/ListEstimateManagementSummarys';
export const FullMethodName_ListEstimateRequestUnitSummarys =
  'mtechnavi.api.estimation.EstimateSenderService/ListEstimateRequestUnitSummarys';
export const FullMethodName_ListEstimateSelections =
  'mtechnavi.api.estimation.EstimateSenderService/ListEstimateSelections';
export const FullMethodName_SharedListEstimateResults =
  'mtechnavi.api.estimation.EstimateSenderService/SharedListEstimateResults';
export const FullMethodName_SharedListEstimateResultDetails =
  'mtechnavi.api.estimation.EstimateSenderService/SharedListEstimateResultDetails';
export const FullMethodName_ListOrderFormats =
  'mtechnavi.api.estimation.EstimateSenderService/ListOrderFormats';
export const FullMethodName_ListEstimateResults =
  'mtechnavi.api.estimation.EstimateReceiverService/ListEstimateResults';
export const FullMethodName_ListEstimateResultDetails =
  'mtechnavi.api.estimation.EstimateReceiverService/ListEstimateResultDetails';
export const FullMethodName_ListEstimateResultSummarys =
  'mtechnavi.api.estimation.EstimateReceiverService/ListEstimateResultSummarys';
export const FullMethodName_SharedListEstimateSelections =
  'mtechnavi.api.estimation.EstimateReceiverService/SharedListEstimateSelections';

// フォーム管理
export const FullMethodName_ListBaseForms =
  'mtechnavi.api.form.BaseFormService/ListBaseForms';
export const FullMethodName_ListBaseFormSettings =
  'mtechnavi.api.form.BaseFormService/ListBaseFormSettings';
export const FullMethodName_ListBaseFormSettingItems =
  'mtechnavi.api.form.BaseFormService/ListBaseFormSettingItems';
export const FullMethodName_ListSampleWorkForms =
  'mtechnavi.api.form.SampleWorkFormService/ListSampleWorkForms';
export const FullMethodName_ListItemAutoNameManagements =
  'mtechnavi.api.form.BaseFormService/ListItemAutoNameManagements';
export const FullMethodName_ListForms =
  'mtechnavi.api.form.FormService/ListForms';
export const FullMethodName_ListFormSettings =
  'mtechnavi.api.form.FormService/ListFormSettings';
export const FullMethodName_ListFormSettingItems =
  'mtechnavi.api.form.FormService/ListFormSettingItems';
export const FullMethodName_ListFormValues =
  'mtechnavi.api.form.FormService/ListFormValues';

// POC 企業検索
export const FullMethodName_listCompanys =
  'mtechnavi.api.analysis.SearchCompanyService/ListCompanys';
export const FullMethodName_ListCompanyContents =
  'mtechnavi.api.analysis.SearchCompanyService/ListCompanyContents';

// BCP 災害調査
export const FullMethodName_ListDisaster =
  'mtechnavi.api.bcp.BcpService/ListDisasters';

export const FullMethodName_ListDisasterSystemNotifications =
  'mtechnavi.api.bcp.BcpService/ListDisasterSystemNotifications';

export const FullMethodName_ListDisasterSurveys =
  'mtechnavi.api.bcp.BcpService/ListDisasterSurveys';

// ライセンス
export const FullMethodName_ListLicenses =
  'mtechnavi.api.license.LicenseService/ListLicenses';
