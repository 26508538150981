import {
  GetMessage,
  GetMessageWithIntl,
} from '~/shared/components/parts/Message/Message';
import {
  ModalDialogComponent,
  ModalDialogComponentProps,
} from '../ModalDialog/ModalDialog';
import { MessageProps } from '../..';
import { CaptionButton } from '~/shared/components/ui/Button/CaptionButton';
import './base.css';
import { useIntl } from 'react-intl';

/** 出力対象 */
export const OrderOutputVolumeType = {
  /** 集約出力 */
  Aggregate: 'aggregate',
  /** 明細出力 */
  Detail: 'detail',
} as const;
export type OrderOutputVolume =
  typeof OrderOutputVolumeType[keyof typeof OrderOutputVolumeType];

export interface OrderOutputDialogOutput {
  outputVolume: OrderOutputVolume;
}

interface OrderOutputMessageOption {
  headerLabelId: MessageProps;
  messageLabelId: MessageProps;
}

export interface OrderOutputDialogProps {
  isOpen: boolean;
  messageOption?: OrderOutputMessageOption;
  onDecision: (v: OrderOutputDialogOutput) => void;
  onCancel: () => void;
}

export const OrderOutputDialog = (props: OrderOutputDialogProps) => {
  const { isOpen, messageOption } = props;
  const intl = useIntl();

  const elements = (
    <div className="order-output-dialog dialog-with-description">
      <div className="description-area">
        <p className="text-box">
          {messageOption?.messageLabelId &&
            GetMessageWithIntl(intl, messageOption.messageLabelId)}
        </p>
      </div>
      <div className="contents-box">
        <div className="button-area">
          <CaptionButton
            name="selectedOnlyBtn"
            buttonType="basic"
            className="button"
            caption={GetMessage({
              id: 'aggregate',
              prefixId: 'OrderOutputDialog',
            })}
            onClick={() =>
              props.onDecision({
                outputVolume: 'aggregate',
              })
            }
          />
          <CaptionButton
            name="allBtn"
            buttonType="basic"
            className="button"
            caption={GetMessage({
              id: 'detail',
              prefixId: 'OrderOutputDialog',
            })}
            onClick={() =>
              props.onDecision({
                outputVolume: 'detail',
              })
            }
          />
        </div>
        <div className="button-area">
          <CaptionButton
            name="cancelBtn"
            buttonType="cancel"
            className="button"
            caption={GetMessage({ id: 'cancel' })}
            onClick={() => props.onCancel()}
          />
        </div>
      </div>
    </div>
  );

  const openModalProps: ModalDialogComponentProps = {
    cancel: () => {
      props.onCancel();
    },
    send: () => {},
    modalIsOpen: isOpen,
    headerLabelId: props.messageOption?.headerLabelId ?? {
      id: 'orderOutput',
      prefixId: 'DIALOG_TITLE',
    },
    messageLabelId: {},
    elements,
  };

  return <ModalDialogComponent {...openModalProps} />;
};
