import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';
import { FullMethodName_BulkWriteLicenses } from 'worker/fullMethodName/crud';

/** ライセンスの追加・削除 */
export const bulkWriteLicenses = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.admin.IBulkWriteLicensesRequest
) => {
  return await worker.invokeListRequest<
    mtechnavi.api.admin.IBulkWriteLicensesRequest,
    mtechnavi.api.license.ILicense
  >(FullMethodName_BulkWriteLicenses, req);
};
