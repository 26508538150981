import { useIntl } from 'react-intl';
import {
  GetMessageWithIntl,
  MessageProps,
} from '~/shared/components/parts/Message/Message';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  convertDateToLong,
  convertLongToDate,
  getProgramOptionFilterboxData,
  includeInputValidateError,
} from '~/shared/utils';
import { CaptionButton } from '../Button';
import {
  error,
  ModalDialogComponent,
  ModalDialogComponentProps,
} from '~/shared/components';
import { mtechnavi, sharelib } from '~/shared/libs/clientsdk';
import { DataFilterbox, DataFilterboxItem } from '../Filterbox';
import {
  Textarea,
  Textbox,
  Checkbox,
  DateSuggest,
  PageNavigation,
  NavigationIconMenu,
} from '~/shared/components/ui';
import './InformationManagementDialog.css';
import { useConfirmation } from '~/shared/hooks';

export type InformationManagementDialogInputType =
  | 'add'
  | 'copy'
  | 'edit'
  | 'display';

export interface InformationManagementDialogMessageOption {
  headerLabelId: MessageProps;
}

export type Information = Pick<
  mtechnavi.api.admin.IInformation,
  | 'subject'
  | 'category'
  | 'content'
  | 'startAt'
  | 'endAt'
  | 'requiredRead'
  | 'updatedProperties'
>;

export interface InformationManagementDialogInputOption {
  inputType: InformationManagementDialogInputType;
  information: Information | null;
}

export interface InformationManagementDialogOutputOption {
  /** 入力したお知らせ情報 */
  information: Information;
  /** 削除フラグ */
  deleteFlag: boolean;
}

export interface InformationManagementDialogProps {
  isOpen: boolean;
  messageOption: InformationManagementDialogMessageOption;
  inputOption: InformationManagementDialogInputOption;
  onDecision: (result: InformationManagementDialogOutputOption) => void;
  onCancel: () => void;
}

const dialogTitle = 'informationManagementDialog';

export const InformationManagementDialog = (
  props: InformationManagementDialogProps
) => {
  const intl = useIntl();
  const { isOpen, inputOption, messageOption } = props;
  const { inputType, information } = inputOption;
  const [isEditable, setEditable] = useState(false);
  const requiredAreaAccount = useRef(null);
  const [subject, setSubject] = useState('');
  const [category, setCategory] = useState<sharelib.INameOption | null>();
  const [content, setContent] = useState('');
  const [startDt, setStartDt] = useState<Date | null>();
  const [endDt, setEndDt] = useState<Date | null>();
  const [requiredRead, setRequiredRead] = useState(false);
  const categories = useMemo<DataFilterboxItem[]>(() => {
    const categories = getProgramOptionFilterboxData('A8000001');
    return categories;
  }, []);
  const selectedCategoryItem = categories.filter(
    (item) => item.value === category?.code
  );
  const deleteFlag = useRef(false);

  // 確認ダイアログ
  const { confirmation, confirmationElement } = useConfirmation();

  // メッセージ定義
  const deleteMessage = {
    id: 'C0000001',
    value: { $1: GetMessageWithIntl(intl, { id: 'delete' }) },
  };
  const saveMessage = {
    id: 'C0000001',
    value: { $1: GetMessageWithIntl(intl, { id: 'save' }) },
  };
  const discardMessage = { id: 'discardMessage' };

  const setValues = (information: Information | null) => {
    setSubject(information?.subject || '');
    setCategory(information?.category);
    setContent(information?.content || '');
    const startDate = convertLongToDate(information?.startAt || null);
    setStartDt(startDate ?? null);
    const endDate = convertLongToDate(information?.endAt || null);
    setEndDt(endDate ?? null);
    setRequiredRead(!!information?.requiredRead);
  };

  // 表示時の初期値セット
  useEffect(() => {
    if (!isOpen) {
      return;
    }
    setValues(information);
    if (inputType === 'edit' || inputType === 'display') {
      setEditable(false);
    } else {
      setEditable(true);
    }
    // isOpen, inputType変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, inputType]);

  const isInputError = (): boolean => {
    const inputValidationCheckList = [
      { value: subject || '', ref: requiredAreaAccount },
      { value: startDt?.toString() || '', ref: requiredAreaAccount },
      { value: endDt?.toString() || '', ref: requiredAreaAccount },
    ];

    const targetElm = document.querySelector('.information-management-dialog');
    return includeInputValidateError(targetElm, intl, inputValidationCheckList);
  };

  const reset = () => {
    setSubject('');
    setCategory(null);
    setContent('');
    setStartDt(null);
    setEndDt(null);
    setRequiredRead(false);
    setEditable(false);
    deleteFlag.current = false;
  };

  const handleDiscard = async () => {
    if (!(await confirmation(discardMessage))) {
      return;
    }
    setEditable(false);
    setValues(information);
  };

  const handleDecision = async () => {
    if (isInputError()) {
      return;
    }
    if ((startDt?.getTime() ?? 0) > (endDt?.getTime() ?? 0)) {
      error([GetMessageWithIntl(intl, { id: 'E0000168' })]);
      return;
    }
    if (!(await confirmation(saveMessage))) {
      return;
    }
    const startAt = startDt ? convertDateToLong(startDt) : undefined;
    const endAt = endDt ? convertDateToLong(endDt) : undefined;
    props.onDecision({
      information: {
        subject,
        startAt,
        category,
        content,
        endAt,
        requiredRead,
      },
      deleteFlag: deleteFlag.current,
    });
    reset();
  };

  const handleDelete = async () => {
    if (!(await confirmation(deleteMessage))) {
      return;
    }
    props.onDecision({
      information: {},
      deleteFlag: true,
    });
    reset();
  };

  // ヘッダアイコン設定
  const getPageNavigationIconItems = (): NavigationIconMenu[] => {
    const iconItems: NavigationIconMenu[] = [];

    if (inputOption.inputType === 'edit') {
      // 編集
      iconItems.push({
        name: 'edit',
        displayName: '編集',
        func: () => {
          setEditable(true);
        },
        disabled: isEditable,
      });
      // 削除
      iconItems.push({
        name: 'delete',
        displayName: '削除',
        func: handleDelete,
        disabled: isEditable,
      });
    }

    return iconItems;
  };

  const view = (
    <div className="information-management-dialog dialog-with-description">
      <div className="header-navigation">
        <PageNavigation
          backpagePath="/"
          iconItems={getPageNavigationIconItems()}
          pageInfo={{
            isVisibleMoveNavi: false,
            isUnvisibleBackNavi: inputType !== 'edit' || !isEditable,
          }}
          infoOption={{
            lastUpdateInfo: {
              isVisibleUpdateInfo: true,
              attribute: information?.updatedProperties?.updatedBy?.email || '',
              attributeAt:
                information?.updatedProperties?.updatedAt ?? undefined,
              content:
                information?.updatedProperties?.updatedBy?.displayName || '',
            },
            issuerInfo: { isVisibleIssuerInfo: false },
          }}
          handleBackPage={handleDiscard}
        />
      </div>
      <div className="detail-area">
        <div className="comment-area">
          <div className="input-line">
            <div className="item-group-100">
              <div className="w-50">
                <Textbox
                  name="title"
                  labelId={`${dialogTitle}.title`}
                  columns={['title']}
                  type="text"
                  value={subject}
                  onChangeState={setSubject}
                  validateOption={{ required: true }}
                  disabled={!isEditable}
                />
              </div>
              <div className="w-25">
                <DataFilterbox
                  name="category"
                  labelId={`${dialogTitle}.category`}
                  columns={['category']}
                  data={categories}
                  value={selectedCategoryItem}
                  searchOption={{
                    targets: 'displayName',
                  }}
                  onChangeState={(selection) => {
                    if (selection.length <= 0) {
                      setCategory(null);
                      return;
                    }
                    setCategory(
                      window.App.services.ui.getNameOptionWithCode(
                        'A8000001',
                        selection[0].value
                      )
                    );
                  }}
                  disabled={!isEditable}
                />
              </div>
            </div>
          </div>
          <div className="input-line">
            <div className="item-group-100">
              <div className="w-100">
                <Textarea
                  name="contents"
                  labelId={`${dialogTitle}.contents`}
                  value={content}
                  columns={['contents']}
                  className="w-100 mh-middle"
                  onChangeState={setContent}
                  disabled={!isEditable}
                />
              </div>
            </div>
          </div>
          <p className="label">
            {GetMessageWithIntl(intl, {
              id: 'autoPublicSetting',
              prefixId: dialogTitle,
            })}
          </p>
          <div className="input-line">
            <div className="item-group-100">
              <div className="w-25">
                <DateSuggest
                  name="startDt"
                  labelId={`${dialogTitle}.startDt`}
                  value={startDt}
                  columns={['startDt']}
                  onChangeState={setStartDt}
                  validateOption={{ required: true }}
                  disabled={!isEditable}
                />
              </div>
              <div className="w-25">
                <DateSuggest
                  name="endDt"
                  labelId={`${dialogTitle}.endDt`}
                  value={endDt}
                  columns={['endDt']}
                  onChangeState={setEndDt}
                  validateOption={{ required: true }}
                  disabled={!isEditable}
                />
              </div>
              <div className="w-50 align-checkbox">
                <Checkbox
                  name="displayPopup"
                  value={requiredRead ? ['1'] : []}
                  columns={['displayPopup']}
                  onChangeState={(checked) => {
                    setRequiredRead(checked.length > 0);
                  }}
                  items={[
                    {
                      displayName: GetMessageWithIntl(intl, {
                        id: 'displayPopup',
                        prefixId: dialogTitle,
                      }),
                      value: '1',
                    },
                  ]}
                  disabled={!isEditable}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="button-area">
          <CaptionButton
            name="cancelBtn"
            buttonType="cancel"
            className="button"
            caption={GetMessageWithIntl(intl, { id: 'cancel' })}
            onClick={() => {
              props.onCancel();
              reset();
            }}
          />
          {isEditable && (
            <CaptionButton
              name="sendBtn"
              buttonType="basic"
              className="button"
              caption={GetMessageWithIntl(intl, { id: 'save' })}
              onClick={handleDecision}
            />
          )}
        </div>
      </div>
      {confirmationElement}
    </div>
  );

  const openModalProps: ModalDialogComponentProps = {
    cancel: () => {
      reset();
      props.onCancel();
    },
    send: () => {},
    modalIsOpen: isOpen,
    headerLabelId: messageOption.headerLabelId,
    messageLabelId: {},
    elements: view,
  };

  return (
    <div className="InformationManagementDialog">
      <ModalDialogComponent {...openModalProps} />
    </div>
  );
};
