import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface ExecCompleteWtTicketRequest {
  actionName: 'completeWtTicket';
  request: mtechnavi.api.worktask.ICompleteWorkTaskTicketRequest;
}

// 作業完了
export const execCompleteWtTicket = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.worktask.ICompleteWorkTaskTicketRequest
) => {
  const fullMethodName =
    'mtechnavi.api.worktask.WorkTaskService/CompleteWorkTaskTicket';

  return await worker.invokeListRequest<
    mtechnavi.api.worktask.ICompleteWorkTaskTicketRequest,
    mtechnavi.api.worktask.ICompleteWorkTaskTicketResponse
  >(fullMethodName, req);
};
