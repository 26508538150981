import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { ToolType } from '../components/tool/config';
import { ViewId } from '../utils';

interface ToolMenuContextType {
  // 現在アクティブのツールタイプ
  activeToolType: ToolType;
  setActiveToolType: Dispatch<SetStateAction<ToolType>>;
  viewId: ViewId;
  setViewId: Dispatch<SetStateAction<ViewId>>;
  // ツールに紐づく企業ID
  companyIds: string[];
  setCompanyIds: Dispatch<SetStateAction<string[]>>;
  // ツールに紐づくデータの型名
  typeName: string;
  setTypeName: Dispatch<SetStateAction<string>>;
  // ツールに紐づくデータのID
  resourceId: string;
  setResourceId: Dispatch<SetStateAction<string>>;
}

const ToolMenuContext = createContext<ToolMenuContextType>({
  activeToolType: 'None',
  setActiveToolType: () => {},
  viewId: '',
  setViewId: () => {},
  companyIds: [],
  setCompanyIds: () => {},
  typeName: '',
  setTypeName: () => {},
  resourceId: '',
  setResourceId: () => {},
});

interface ToolMenuProviderProps {
  children: React.ReactNode;
}

export function ToolMenuProvider({ children }: ToolMenuProviderProps) {
  const [activeToolType, setActiveToolType] = useState<ToolType>('None');
  const [viewId, setViewId] = useState<ViewId>('');

  const [companyIds, setCompanyIds] = useState<string[]>([]);
  const [typeName, setTypeName] = useState<string>('');
  const [resourceId, setResourceId] = useState<string>('');

  // viewId が変更された場合はリセット
  useEffect(() => {
    return () => {
      setCompanyIds([]);
      setTypeName('');
      setResourceId('');
      setActiveToolType('None');
    };
  }, [viewId]);

  const contextValue: ToolMenuContextType = {
    activeToolType,
    setActiveToolType,
    viewId,
    setViewId,
    companyIds,
    setCompanyIds,
    typeName,
    setTypeName,
    resourceId,
    setResourceId,
  };
  return (
    <ToolMenuContext.Provider value={contextValue}>
      {children}
    </ToolMenuContext.Provider>
  );
}

export function useToolContext() {
  return useContext(ToolMenuContext);
}
