import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface ExecUpdateWorkTaskCatalogRequest {
  actionName: 'saveWorkTaskCatalog';
  request: mtechnavi.api.worktask.IWorkTaskCatalog[];
}

// 指図案件カタログ登録
export const execUpdateWorkTaskCatalog = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.worktask.IWorkTaskCatalog[]
) => {
  const createFullMethodName =
    'mtechnavi.api.worktask.WorkTaskService/CreateWorkTaskCatalog';
  const updateFullMethodName =
    'mtechnavi.api.worktask.WorkTaskService/UpdateWorkTaskCatalog';

  type createRequestType = mtechnavi.api.worktask.ICreateWorkTaskCatalogRequest;
  type updateRequestType = mtechnavi.api.worktask.IUpdateWorkTaskCatalogRequest;

  const result = req.map((item) => {
    if (item.workTaskCatalogId) {
      return worker.invokeListRequest<
        updateRequestType,
        mtechnavi.api.worktask.WorkTaskCatalog
      >(updateFullMethodName, {
        workTaskCatalog: item,
      });
    }
    return worker.invokeListRequest<
      createRequestType,
      mtechnavi.api.worktask.WorkTaskCatalog
    >(createFullMethodName, {
      workTaskCatalog: item,
    });
  });

  return await Promise.all(result);
};
