import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';
import { FullMethodName_DisableTenantManagement } from 'worker/fullMethodName/crud';

/** テナントの無効化 */
export const disableTenantManagement = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.admin.IDisableTenantManagementRequest
) => {
  return await worker.invokeListRequest<
    mtechnavi.api.admin.IDisableTenantManagementRequest,
    mtechnavi.api.admin.IDisableTenantManagementResponse
  >(FullMethodName_DisableTenantManagement, req);
};
