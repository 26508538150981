import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface SharetoEstimateRequestWithRelatedItemsRequest {
  actionName: 'sharetoEstimateRequestWithRelatedItems';
  request: SharetoEstimateRequestWithRelatedItemsParam;
}
interface SharetoEstimateRequestWithRelatedItemsParam {
  estimateManagementId: string;
}

export const sharetoEstimateRequestWithRelatedItems = async (
  worker: AppSaveWorker,
  req: SharetoEstimateRequestWithRelatedItemsParam
) => {
  const shareToMethodName =
    'mtechnavi.api.estimation.EstimateSenderService/SharetoEstimateRequestWithRelatedItems';

  return await worker.invokeListRequest<
    mtechnavi.api.estimation.ISharetoEstimateRequestWithRelatedItemsRequest,
    mtechnavi.api.estimation.ISharetoEstimateRequestWithRelatedItemsResponse
  >(shareToMethodName, {
    estimateManagementId: req.estimateManagementId,
  });
};
