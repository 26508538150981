import './Notice.css';
import { IconButton } from '../ui/Button';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { getDateFormat } from '~/shared/utils';

interface NoticeAreaProps {
  disabled: boolean;
  handleOpenDialog: () => void;
  information: mtechnavi.api.admin.IInformation;
}

export const NoticeArea = (props: NoticeAreaProps) => {
  return (
    <div className={`Notice ${props.disabled ? 'disabled' : ''}`}>
      <div className="notice-header">
        <div className="notice-title">{props.information.subject ?? ''}</div>
        <div className="notice-last-update-info">
          {getDateFormat(props.information.publishedAt ?? '', 'YYYY/MM/DD')}
        </div>
      </div>
      <div className="notice-content">{props.information.content ?? ''}</div>
      <IconButton
        name="open"
        className="open-icon"
        iconType="open_in_new"
        onClick={props.handleOpenDialog}
      />
    </div>
  );
};
