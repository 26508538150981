import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface SaveAttribute {
  actionName: 'saveAttribute';
  request: mtechnavi.api.programoption.IAttribute;
}

export const saveAttribute = async (
  worker: AppSaveWorker,
  request: mtechnavi.api.programoption.IAttribute
) => {
  const SaveFullMethodName =
    'mtechnavi.api.programoption.AttributeService/SaveAttribute';

  const result = await worker.invokeListRequest(SaveFullMethodName, {
    attribute: request,
  });
  return result;
};
