import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface SaveCatalogRequest {
  actionName: 'saveCatalog';
  request: mtechnavi.api.survey.ISurveyRequestCatalog[];
}

// 依頼カタログ登録
export const saveCatalogRequest = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.survey.ISurveyRequestCatalog[]
) => {
  const createFullMethodName =
    'mtechnavi.api.survey.SurveySenderService/CreateSurveyRequestCatalog';
  const updateFullMethodName =
    'mtechnavi.api.survey.SurveySenderService/UpdateSurveyRequestCatalog';
  type createRequestType =
    mtechnavi.api.survey.ICreateSurveyRequestCatalogRequest;
  type updateRequestType =
    mtechnavi.api.survey.IUpdateSurveyRequestCatalogRequest;

  const result = req.map((item) => {
    if (item.surveyRequestCatalogId) {
      return worker.invokeListRequest<
        updateRequestType,
        mtechnavi.api.survey.SurveyRequestCatalog
      >(updateFullMethodName, {
        surveyRequestCatalog: item,
      });
    }
    return worker.invokeListRequest<
      createRequestType,
      mtechnavi.api.survey.SurveyRequestCatalog
    >(createFullMethodName, {
      surveyRequestCatalog: item,
    });
  });

  return await Promise.all(result);
};
