import { mtechnavi } from '~/shared/libs/clientsdk';
import { FullMethodName_BulkWriteOrganizationRelations } from 'worker/fullMethodName/crud';
import { AppSaveWorker } from '..';

export interface SaveOrganizationUsersAction {
  actionName: 'saveOrganizationUsers';
  request: mtechnavi.api.company.IBulkWriteOrganizationRelationsRequest;
}

export const saveOrganizationUsers = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.company.IBulkWriteOrganizationRelationsRequest
) => {
  const result = await worker.invokeListRequest<
    mtechnavi.api.company.IBulkWriteOrganizationRelationsRequest,
    void
  >(FullMethodName_BulkWriteOrganizationRelations, req);
  return result;
};
