/**
 * テナントステータスが紐付けあり(B03)かどうか判定する
 * @param systemName
 * @returns 紐付けありの場合、True
 */
export const isLinkedTenant = (systemName: string | null | undefined) => {
  return systemName === 'B03';
};

/**
 * 通知/更新ステータスが通知/更新対象(B00)かどうか判定する
 * @param systemName
 * @returns 通知/更新対象、True
 */
export const isActiveCompany = (systemName: string | null | undefined) => {
  return systemName === 'B00';
};
