import { useState } from 'react';
import { CaptionButton } from '~/shared/components/ui/Button/CaptionButton';
import { JsonFileIODialog } from '~/shared/components/ui';
import { error } from '~/shared/components';
/* eslint @typescript-eslint/no-explicit-any: 0 */
export const UISampleJsonFileIODialogs = () => {
  const [result, setResult] = useState('');
  const [isShow, setIsShow] = useState(false);
  const [data, setData] = useState<any[]>([]);

  return (
    <div className="categories">
      <h3 id="dialog">JSONデータIOダイアログ</h3>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setResult('');
                setIsShow(true);
              }}
            />
            <JsonFileIODialog
              isOpen={isShow}
              inputOption={{ data: data }}
              onDecision={(v) => {
                if (v.message.length > 0) {
                  error(v.message);
                } else {
                  setData(v.data);
                  setResult(`${JSON.stringify(v.data)}`);
                  setIsShow(false);
                }
              }}
              onCancel={() => {
                setResult('キャンセルしました');
                setIsShow(false);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">{result}</pre>
          </div>
        </div>
      </div>
    </div>
  );
};
