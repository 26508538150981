import { CSSProperties } from 'react';
import { Property } from '~/shared/services';

interface Dict {
  [key: string]: string;
}
export const getButtonProperties = (
  properties?: Property[],
  isDisabled?: boolean
): [CSSProperties, boolean] => {
  if (!properties) {
    return [{}, isDisabled || false];
  }

  const textDict: Dict = {};
  let _disabled = false;
  properties.map((v) => {
    textDict[v.propertyName] = v.propertyValue;
    if (v.propertyName === 'disabled') {
      _disabled = v.propertyValue;
    }
  });
  _disabled = isDisabled || _disabled;

  return [textDict as CSSProperties, _disabled];
};
