import Long from 'long';
import { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { FullMethodName_ListUserAttributes } from '~/worker';
import { GetMessageWithIntl, success } from '~/shared/components';
import {
  ApprovalRequestDialog,
  Checkbox,
  ConfirmationDialog,
  OutputOption,
} from '~/shared/components/ui';
import { CaptionButton } from '~/shared/components/ui/Button';
import {
  EstimateSelectionDialog,
  EstimateSelectionDialogInputOption,
} from '~/shared/components/ui/Dialog/EstimateSelectionDialog';
import { mtechnavi } from '~/shared/libs/clientsdk';

export const UISampleEstimateSelectionDialogs = () => {
  const intl = useIntl();
  const [isOpen, setOpen] = useState(false);
  const [result, setResult] = useState<
    mtechnavi.api.estimation.IEstimateSelection | null | undefined
  >(null);
  const [approvalRequestResult, setApprovalRequestResult] =
    useState<OutputOption | null>(null);
  const [isReadOnly, setReadOnly] = useState(false);

  const [isShowApprovalRequest, setShowApprovalRequest] = useState(false);
  const approvalRequestResolverRef =
    useRef<
      (value: OutputOption | null | PromiseLike<OutputOption | null>) => void
    >();

  const [isShowConfirmation, setShowConfirmation] = useState(false);
  const [confirmationMessageId, setConfirmationMessageId] = useState('');
  const confirmResolverRef =
    useRef<(value: boolean | PromiseLike<boolean>) => void>();

  const inputOption: EstimateSelectionDialogInputOption = {
    isReadOnly,
    request: {
      estimateRequestAutoName: Long.fromNumber(999),
      displayName: 'テスト見積依頼',
      deliveryPoint: {
        displayName: '株式会社XXXX',
      },
    },
    data: {
      ...result,
      notificationAt:
        isReadOnly && result?.notification
          ? Long.fromValue(new Date().getTime() * 1000)
          : null,
    },
  };
  const resultText = result ? JSON.stringify(result, null, 4) : '';
  const approvalRequestResultText = approvalRequestResult
    ? JSON.stringify(approvalRequestResult, null, 4)
    : '';

  const approvalRequest = (): Promise<OutputOption | null> => {
    setShowApprovalRequest(true);
    return new Promise((resolve) => {
      approvalRequestResolverRef.current = resolve;
    });
  };
  const handelApprovalRequest = (result: OutputOption | null) => {
    approvalRequestResolverRef.current &&
      approvalRequestResolverRef.current(result);
    setShowApprovalRequest(false);
  };

  const confirmation = (messageId: string): Promise<boolean> => {
    setConfirmationMessageId(messageId);
    setShowConfirmation(true);
    return new Promise((resolve) => {
      confirmResolverRef.current = resolve;
    });
  };
  const handleConfirm = (isConfirmed: boolean) => {
    confirmResolverRef.current && confirmResolverRef.current(isConfirmed);
    setShowConfirmation(false);
  };

  return (
    <div className="categories">
      <h3 id="dialog">選考ダイアログ</h3>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setOpen(true);
              }}
            />
            <div className="input-line" style={{ marginTop: '1rem' }}>
              <div className="item-group-100">
                <div className="w-100">
                  <Checkbox
                    name=""
                    columns={['']}
                    items={[{ displayName: '閲覧モード', value: '1' }]}
                    onChangeState={(v) => {
                      setReadOnly(!!v.length);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="w-66">
            <pre className="output">{resultText}</pre>
            <pre className="output">{approvalRequestResultText}</pre>
          </div>
        </div>
      </div>

      <EstimateSelectionDialog
        key={isOpen ? '1' : '0'}
        isOpen={isOpen}
        inputOption={inputOption}
        onDecision={async (v) => {
          if (!v.estimateSelection?.notification) {
            if (!(await confirmation('save'))) {
              return;
            }
            success(['見積選考データの保存']);
            setResult(v.estimateSelection);
            setApprovalRequestResult(null);
            setOpen(false);
          } else {
            const result = await approvalRequest();
            if (!result) {
              return;
            }
            success(['見積選考データの保存']);
            success(['承認依頼の作成']);
            setShowApprovalRequest(false);
            setResult(v.estimateSelection);
            setApprovalRequestResult(result);
            setOpen(false);
          }
        }}
        onCancel={() => {
          if (!isReadOnly) {
            setResult(null);
            setApprovalRequestResult(null);
          }
          setOpen(false);
        }}
      />

      <ApprovalRequestDialog
        key={isShowApprovalRequest ? 'a' : 'b'}
        isOpen={isShowApprovalRequest}
        inputOption={{
          organizations: [],
          organizationRelations: [],
          FullMethodName_ListUserAttributes: FullMethodName_ListUserAttributes,
          requestReciptId: '12345',
        }}
        onDecision={async (result) => {
          if (
            !(await confirmation('estimateSelectionDialog.approvalRequest'))
          ) {
            return;
          }
          handelApprovalRequest(result);
        }}
        onCancel={() => {
          handelApprovalRequest(null);
        }}
      />

      <ConfirmationDialog
        isOpen={isShowConfirmation}
        viewMessage={{
          id: 'C0000001',
          value: {
            $1: GetMessageWithIntl(intl, { id: confirmationMessageId }),
          },
        }}
        onDecision={() => handleConfirm(true)}
        onCancel={() => handleConfirm(false)}
      />
    </div>
  );
};
