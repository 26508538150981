import './ToolBar.css';
import { useToolContext } from '~/shared/contexts/ToolMenuProvider';
import { IconButton } from '../ui/Button';
import { ToolItem, ToolType, toolItems } from './config';
import { FORUM_USABLE_VIEW_IDS, ForumUsableViewId } from './Forum/utils/util';

export function ToolBar() {
  const { setActiveToolType, viewId } = useToolContext();

  const handleToolMenuView = (name: ToolType) => {
    setActiveToolType(name);
  };

  const isUsableViewId = (v: ToolItem) => {
    switch (v.name) {
      case 'Forum':
        return FORUM_USABLE_VIEW_IDS.includes(viewId as ForumUsableViewId);
      case 'Help':
        return true;
      default:
        return false;
    }
  };

  const renderToolMenuItems = (key: number[], v: ToolItem): JSX.Element => {
    return (
      <li key={key.join('-')}>
        <IconButton
          name={v.name}
          iconType={v.icon}
          buttonType="basic"
          disabled={!isUsableViewId(v)}
          onClick={() => handleToolMenuView(v.name)}
          properties={[]}
        />
      </li>
    );
  };

  return (
    <menu className="ToolBar">
      <ul>{toolItems.map((v, i) => renderToolMenuItems([i], v))}</ul>
    </menu>
  );
}
