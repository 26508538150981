import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';
import { FullMethodName_CancelReserveDeleteTenantManagement } from 'worker/fullMethodName/crud';

type CancelReserveDeleteTenantManagementParam = {
  tenantManagement?: Pick<
    mtechnavi.api.admin.ITenantManagement,
    | 'tenantManagementId'
    | 'tenantId'
    | 'tenantCode'
    | 'tenantDisplayName'
    | 'createdAt'
    | 'updatedAt'
  > | null;
};

/** テナント削除キャンセル */
export const cancelReserveDeleteTenantManagement = async (
  worker: AppSaveWorker,
  req: CancelReserveDeleteTenantManagementParam
) => {
  return await worker.invokeListRequest<
    CancelReserveDeleteTenantManagementParam,
    mtechnavi.api.admin.ICancelReserveDeleteTenantManagementResponse
  >(FullMethodName_CancelReserveDeleteTenantManagement, req);
};
