import { useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  Container,
  GetMessage,
  Toast,
  LoadingIcon,
  success,
  error,
  GetMessageWithIntl,
  MessageProps,
} from '~/shared/components';
import { useNavigate, useLocation } from 'react-router-dom';
import { usePagenator } from '~/shared/components/ui/ListView/pagenator';
import { mtechnavi, sharelib } from '~/shared/libs/clientsdk';
import {
  HistoryDialog,
  CommentDialog,
  ConfirmationDialog,
  SystemNotificationSettingDialog,
  DetailConfirmationDialog,
} from '~/shared/components/ui/Dialog';
import './EstEstimateRequestConfirmation.css';
import {
  PageNavigation,
  NavigationIconMenu,
} from '~/shared/components/ui/PageNavigation/PageNavigation';
import { useAuth } from '~/shared/contexts/AuthProvider';
import {
  PageState,
  getSortSettingFromLocalStorage,
  FullMethodName_ListEstimateManagementSummarys,
  getExceptionMessage,
  getWorkerExceptionMessage,
  HistoryItems,
  getHistoryItems,
  isShowNotificationArea,
  typeName,
  ViewId,
  getDateFormat,
  getDateFormatWithTimezone,
  getLocalStorageCheckboxData,
  saveLocalStorageCheckboxData,
  backToBaseViewPage,
} from '~/shared/utils';
import {
  convertUserReference,
  convertLongToString,
  convertLongToNumber,
} from '~/shared/utils/converter';
import { useErrorHandler } from '~/shared/components/error/ErrorBoundary';
import { SortTerm } from '~/worker';
import {
  listSharedContactContents,
  listEstimateManagements,
  listEstimateRequests,
  listEstimateRequestDetails,
  listEstimateRequestUnits,
} from '~/tenant/estimate/utils';
import {
  isCanBeEdit,
  isCanBeDelete,
  isCanBeDiscard,
  isCanBeNotificationSetting,
  isCanBeVisibleResult,
} from '~/tenant/estimate/utils/validator';
import { TabItem, Tabs, ListColumnSetting } from '~/shared/components/ui';
import { EstimateRequestTabItem } from './parts/EstimateRequestTabItem';
import { EstimateResultTabItem } from './parts/EstimateResultTabItem';
import {
  formatDisplayDesiredReceivedDt,
  formatDisplayDeliveryPoint,
  formatDisplayContactProperties,
} from './parts/formatter';
import { toCommaTypeString } from '~/shared/utils/formatter';
import {
  EstimateConfirmationHeader,
  EstimateConfirmationHeaderInfo,
  handleFileViewer,
} from '~/tenant/estimate/pages/components/EstimateConfirmationHeader';
import { ForumTypeName, useForum } from '~/shared/components/tool/Forum';

interface EstimateRequestDetailItemType {
  id: string;
  detailNumber: string;
  code: string;
  displayName: string;
  amount: string;
  assetId: string;
  assetName: JSX.Element;
}

const VIEW_ID: ViewId = 'EST_ESTIMATE_REQUEST_CONFIRMATION';

const estimateRequestHeaderColumns = [
  {
    header: {
      id: 'estimateRequestDetail.detailNumber',
      prefixId: VIEW_ID,
    },
    propertyName: 'detailNumber',
    width: '6rem',
  },
  {
    header: {
      id: 'estimateRequestDetail.code',
      prefixId: VIEW_ID,
    },
    propertyName: 'code',
    width: '10rem',
  },
  {
    header: {
      id: 'estimateRequestDetail.displayName',
      prefixId: VIEW_ID,
    },
    propertyName: 'displayName',
  },
  {
    header: {
      id: 'estimateRequestDetail.amount',
      prefixId: VIEW_ID,
    },
    propertyName: 'amount',
    width: '10rem',
    align: 'right',
  },
  {
    header: {
      id: 'estimateRequestDetail.assetName',
      prefixId: VIEW_ID,
    },
    propertyName: 'assetName',
    width: '10rem',
  },
] as ListColumnSetting[];

const tabItemIndex = {
  request: 0,
  result: 1,
};

export function EstEstimateRequestConfirmation() {
  const intl = useIntl();
  const location = (useLocation().state as PageState) ?? [];
  const navi = useNavigate();
  const handleError = useErrorHandler();
  // ログイン者の情報特定用email
  const myEmail = useAuth().user?.email ?? '';
  const myTenantId = useAuth().tenant?.tenantId ?? '';
  // 初期情報取得のローディング
  const [isInitLoading, setInitLoading] = useState(false);
  // ページ情報取得・ページ内操作のローディング
  const [isLoading, setLoading] = useState(false);
  // 最終的なローディング表示有無
  const isShowLoading = isInitLoading || isLoading;

  // コミュニケーション機能設定
  const { setForumInfo } = useForum();

  // 例外処理用のエラー情報
  const [isException, setException] = useState<boolean>(false);
  const [pagenateError, setPagenateError] = useState<unknown>({});

  // ページ情報
  const [item, setItem] =
    useState<mtechnavi.api.estimation.IEstimateManagementSummary>();
  const [isFirstPage, setFirstPage] = useState(false);
  const [isMaxPage, setMaxPage] = useState(false);
  // 履歴通知表示
  const [isNotificationShow, setNotificationShow] = useState<boolean>(false);

  //tabIndex
  const [tabIndex, setTabIndex] = useState<number>(tabItemIndex.request);

  // 連絡先コンテンツ
  const [sharedContactContents, setSharedContactContents] = useState<
    mtechnavi.api.company.ISharedContactContent[]
  >([]);
  // 管理データ
  const estimateManagementData =
    useRef<mtechnavi.api.estimation.IEstimateManagement | null>();
  // 依頼データ
  const estimateRequestData =
    useRef<mtechnavi.api.estimation.IEstimateRequest | null>();
  // 依頼明細データ
  const estimateRequestDetailsData = useRef<
    mtechnavi.api.estimation.IEstimateRequestDetail[] | null
  >();
  // 依頼送信先データ
  const estimateRequestUnitsData = useRef<
    mtechnavi.api.estimation.IEstimateRequestUnit[] | null
  >();

  // ナビゲーションエリアの表示制御
  const [isEditable, setEditable] = useState<boolean>(true);
  const [isDeletable, setDeletable] = useState<boolean>(true);
  const [isSystemNotificationEditable, setSystemNotificationEditable] =
    useState<boolean>(true);

  // 履歴
  const [histories, setHistories] = useState<HistoryItems[]>([]);
  const historyMessage = GetMessage({
    id: 'I0000005',
    value: { $1: histories?.length > 0 ? histories[0].slip_category : '' },
  });

  // システム通知先
  const [systemNotificationUsers, setSystemNotificationUsers] = useState<
    sharelib.IUserReference[]
  >([]);

  // 各種ダイアログ制御
  const [isOpenSystemNotificationDialog, setOpenSystemNotificationDialog] =
    useState(false);
  const [isOpenHistoryDialog, setOpenHistoryDialog] = useState(false);
  const [isOpenDiscard, setOpenDiscard] = useState(false);
  const [discardComment, setDiscardComment] = useState('');
  const [isOpenDetailConfirmationDialog, setOpenDetailConfirmationDialog] =
    useState(false);
  // アクションID一時管理
  const detailConfirmationDialogId = useRef<string>();

  // 確認ダイアログ
  const [isOpenConfirmDialog, setOpenConfirmDialog] = useState(false);
  // 確認ダイアログメッセージ
  const [confirmMessage, setConfirmMessage] = useState<MessageProps>({});
  const confirmPromiseRef =
    useRef<(value: boolean | PromiseLike<boolean>) => void>();

  // メッセージ
  const successMessage = GetMessage({ id: 'I0000001' });
  const viewMessageSend: MessageProps = {
    id: 'C0000001',
    value: {
      $1: GetMessageWithIntl(intl, { id: 'send' }),
    },
  };
  const viewMessageDelete: MessageProps = {
    id: 'C0000001',
    value: {
      $1: GetMessageWithIntl(intl, { id: 'delete' }),
    },
  };
  const invalidStatusMessage = GetMessageWithIntl(intl, { id: 'E0000020' });
  const invalidDiscardMessage = GetMessageWithIntl(intl, { id: 'E0000131' });

  // paginator の ID 取得処理
  const getTargetId = () => {
    const urlPathName = window.location.pathname;
    const splitPath = urlPathName.split('/');
    if (location.ids) {
      return location.ids ?? [];
    } else {
      if (splitPath.length > 1) {
        const idPath = splitPath[splitPath.length - 1];
        const endpointPath = splitPath[splitPath.length - 2];
        if (
          idPath !== 'est-estimate-request-confirmation' &&
          endpointPath === 'est-estimate-request-confirmation'
        ) {
          return [idPath];
        }
      }
    }
    return [];
  };

  // paginator のソート処理
  const getAppendSort = () => {
    const nowSort: SortTerm[] = getSortSettingFromLocalStorage(
      location.baseViewOption?.sourceViewId ?? 'EST_ESTIMATE_REQUEST_LIST',
      myEmail
    );

    nowSort.map((item) => {
      // 明細番号がなかったら追加する
      if (!item['estimateRequestAutoName']) {
        nowSort.push({
          estimateRequestAutoName: 'asc',
        });
      }
    });
    if (nowSort.length === 0) {
      nowSort.push({
        estimateRequestAutoName: 'asc',
      });
    }
    return nowSort;
  };

  // pagenatorに基本情報をセット
  const [page, dispatch] = usePagenator({
    fullMethodName: FullMethodName_ListEstimateManagementSummarys,
    pageNumber: location.confirmationViewOption?.pageNumber ?? 1,
    pageSize: 1,
    maxPageNumber: 1,
    requestBody: {
      estimateManagementIds: getTargetId(),
    },
    filter: {
      $or: [{ estimateManagementSummaryId: { $in: getTargetId() } }],
    },
    sort: getAppendSort(),
    items: [],
    originalItems: [],
    onError(err) {
      setException(true);
      setPagenateError(err);
      handleError(err);
    },
  });

  // 権限エラーの場合、トーストを出す
  useEffect(() => {
    if (isException) {
      error(getWorkerExceptionMessage(intl, pagenateError));
    }
  }, [intl, isException, pagenateError]);

  // pageデータ変更時の処理
  useEffect(() => {
    const items =
      page.originalItems as unknown as mtechnavi.api.survey.ISurveyRequestContent[];
    if (items.length > 0) {
      setItem(items[0]);
    }
    setFirstPage(page.pageNumber === 1);
    setMaxPage(page.pageNumber === page.maxPageNumber);
    setNotificationShow(false);
    clearDisplayData();
    setTabIndex(tabItemIndex.request);
  }, [page]);

  const clearDisplayData = () => {
    estimateManagementData.current = {};
    estimateRequestData.current = {};
    estimateRequestDetailsData.current = [];
    estimateRequestUnitsData.current = [];
  };

  // 初回画面用データ
  useEffect(() => {
    // 基本情報でセットした値を元に情報を取得
    handleReload();

    (async () => {
      setInitLoading(true);
      try {
        // 共通で取得するもの
        const [listSharedContactContentsRes] = await Promise.all([
          listSharedContactContents(), // 連絡先コンテンツ
        ]);
        setSharedContactContents(listSharedContactContentsRes.items || []);
      } catch (err) {
        error(getExceptionMessage(intl, err));
        throw err;
      } finally {
        setInitLoading(false);
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tabItems = (): TabItem[] => {
    const tabItems: TabItem[] = [];
    tabItems.push({
      label: GetMessageWithIntl(intl, {
        prefixId: VIEW_ID,
        id: 'request',
      }),
      tabContent: (
        <EstimateRequestTabItem
          viewId={VIEW_ID}
          sharedContactContents={sharedContactContents}
          estimateManagement={estimateManagementData.current ?? {}}
          estimateRequest={estimateRequestData.current ?? {}}
          estimateRequestUnits={estimateRequestUnitsData.current ?? []}
          onChangeLoadingState={setLoading}
          handleReload={handleReload}
        />
      ),
    });
    if (isCanBeVisibleResult(item?.status)) {
      tabItems.push({
        label: GetMessageWithIntl(intl, {
          prefixId: VIEW_ID,
          id: 'result',
        }),
        tabContent: (
          <EstimateResultTabItem
            viewId={VIEW_ID}
            estimateRequestUnits={estimateRequestUnitsData.current ?? []}
            onChangeLoadingState={setLoading}
            handleReload={handleReload}
          />
        ),
      });
    }
    return tabItems;
  };

  // ページデータの変更を受けて表示する情報を取得する
  useEffect(() => {
    if (!item) {
      return;
    }

    // 各種操作可否の制御
    const countEstimateResult =
      (convertLongToNumber(item?.countEstimateResult) ?? 0) +
      (convertLongToNumber(item?.countEstimateResultDecline) ?? 0);
    const deletable =
      isCanBeDelete(item?.status) ||
      (isCanBeDiscard(item?.status) && countEstimateResult === 0);

    setDeletable(deletable); // 削除/破棄
    setEditable(isCanBeEdit(item?.status)); // 編集
    setSystemNotificationEditable(isCanBeNotificationSetting(item?.status)); // 通知設定

    // ページデータに紐づく各種データの取得
    (async () => {
      setLoading(true);
      try {
        await Promise.all([
          listHistoryData(), //履歴データ
        ]);

        const [
          listEstimateManagementsRes,
          listEstimateRequestsRes,
          listEstimateRequestDetailsRes,
          listEstimateRequestUnitsRes,
        ] = await Promise.all([
          listEstimateManagements(
            [item.estimateManagementSummaryId ?? ''],
            true
          ),
          listEstimateRequests(
            [item.estimateManagementSummaryId ?? ''] ?? [],
            true
          ),
          listEstimateRequestDetails(
            [item.estimateManagementSummaryId ?? ''] ?? [],
            true
          ),
          listEstimateRequestUnits(
            [item.estimateManagementSummaryId ?? ''] ?? [],
            true
          ),
        ]);
        // 非表示項目のセット
        estimateManagementData.current =
          listEstimateManagementsRes.items.at(0) || {};
        estimateRequestData.current = listEstimateRequestsRes.items.at(0) || {};
        estimateRequestDetailsData.current =
          listEstimateRequestDetailsRes.items;
        estimateRequestUnitsData.current = listEstimateRequestUnitsRes.items;

        // システム通知先データを取得
        setSystemNotificationUsers(
          estimateManagementData.current.systemNotificationUsers ?? []
        );

        // フォーラム設定
        setForumInfo({
          typeName: ForumTypeName.Estimate,
          resourceId: estimateRequestData.current?.estimateRequestId ?? '',
          companyIds: (estimateRequestUnitsData.current ?? []).map(
            (item) => item.companyId ?? ''
          ),
        });
      } catch (err) {
        error(getExceptionMessage(intl, err));
        throw err;
      } finally {
        setLoading(false);
      }
    })();

    // item変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, intl]);

  /**
   * 見積依頼登録遷移(編集ボタン)
   */
  const handleEstimateRequestInput = () => {
    const state: PageState = {
      ids: [item?.estimateManagementSummaryId ?? ''],
      sourceViewId: VIEW_ID,
      actionType: 'edit',
      naviFilters: location.naviFilters,
      beforeStateIds: location.ids,
      baseViewOption: location.baseViewOption,
      confirmationViewOption: { pageNumber: page.pageNumber },
    };
    navi('/estimate/est-estimate-request-input', { state });
  };

  /**
   * リロード処理
   */
  const handleReload = () => {
    dispatch({
      type: 'reload',
      fullMethodName: FullMethodName_ListEstimateManagementSummarys,
      onChangeLoadingState: (v) => {
        setLoading(v);
      },
    });
  };

  /**
   * 履歴ダイアログの表示処理
   */
  const handleOpenHistoryDialog = async () => {
    // ダイアログ表示処理のみ
    //  履歴データ作成にラグがないためダイアログ起動時のデータ取得は行わない
    setOpenHistoryDialog(true);
  };

  /**
   * 履歴データ取得
   */
  const listHistoryData = async () => {
    try {
      const typeName: typeName = 'mtechnavi.api.estimation.EstimateManagement';
      const recordId = item?.estimateManagementSummaryId ?? '';
      // 履歴一覧を取得
      const datas = await getHistoryItems(intl, typeName, [recordId]);
      setHistories(datas);
      // 最新の履歴通知レベルによる制御
      setNotificationShow(isShowNotificationArea(datas));
    } catch (err) {
      error(getExceptionMessage(intl, err));
      throw err;
    }
  };

  // ページ戻し・送り処理
  const handleMovePage = (pageNumber: number) => {
    const n = Math.min(Math.max(1, pageNumber), page.maxPageNumber);
    dispatch({
      type: 'query',
      fullMethodName: FullMethodName_ListEstimateManagementSummarys,
      pageNumber: n,
    });
  };

  /**
   * システム通知設定ダイアログの確定処理
   */
  const handleSystemNotificationSetting = async (result: string) => {
    setLoading(true);
    try {
      const userReference = await convertUserReference(myEmail);
      const req: mtechnavi.api.worker.ISystemNotificationSetting = {
        surveyReceptionId: item?.estimateManagementSummaryId ?? '',
        appendUser:
          result === 'setAsNotificationDestination' ? userReference : null,
        removeUser:
          result !== 'setAsNotificationDestination' ? userReference : null,
      };
      await window.App.services.ui.worker.apiCall({
        actionName: 'updateEstimateManagementSystemNotificationSetting',
        request: req,
      });
      success([successMessage]);
      handleReload();
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
    }
  };

  /**
   * 削除ダイアログの確定処理
   */
  const handleDelete = async () => {
    if (!(await confirmation(viewMessageDelete))) {
      return;
    }
    setLoading(true);
    try {
      await Promise.all([
        // 見積管理関連データの削除
        window.App.services.ui.worker.apiCall({
          actionName: 'deleteEstimateManagementWithRelatedItems',
          request:
            // estimateManagementSummaryId は estimateManagementId と同一のため
            [item?.estimateManagementSummaryId ?? ''],
        }),
      ]);

      // 一覧のチェック制御処理
      const localStorageValues = getLocalStorageCheckboxData(
        location.baseViewOption?.sourceViewId ?? 'EST_ESTIMATE_REQUEST_LIST',
        myEmail
      );
      if (
        [
          'EST_ESTIMATE_REQUEST_DETAIL_LIST',
          'EST_ESTIMATE_SELECTION_APPROVAL_LIST',
        ].includes(location.baseViewOption?.sourceViewId ?? '')
      ) {
        saveLocalStorageCheckboxData(
          location.baseViewOption?.sourceViewId ??
            'EST_ESTIMATE_REQUEST_DETAIL_LIST',
          localStorageValues.filter(
            (v) => !item?.estimateRequestUnitIds?.includes(v)
          ),
          myEmail
        );
      } else {
        saveLocalStorageCheckboxData(
          location.baseViewOption?.sourceViewId ?? 'EST_ESTIMATE_REQUEST_LIST',
          localStorageValues.filter(
            (v) => v !== item?.estimateManagementSummaryId
          ),
          myEmail
        );
      }

      success([successMessage]);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
    backToBaseViewPage(navi, location, VIEW_ID);
  };

  /**
   * 破棄ダイアログの確定処理
   */
  const handleDiscard = async () => {
    if (!(await confirmation(viewMessageSend))) {
      return;
    }
    setLoading(true);
    try {
      await window.App.services.ui.worker.apiCall({
        actionName: 'discardEstimateManagement',
        request: {
          estimateManagementIds: [item?.estimateManagementSummaryId ?? ''],
          comment: discardComment,
        },
      });
      setOpenDiscard(false);
      success([successMessage]);
      handleReload();
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
    // 一覧に戻る
    backToBaseViewPage(navi, location, VIEW_ID);
  };

  /**
   * 確認ダイアログ処理
   * Promise で結果を制御する。
   * 確定: true / キャンセル: false
   */
  const confirmation = (viewMessage: MessageProps): Promise<boolean> => {
    setOpenConfirmDialog(true);
    setConfirmMessage(viewMessage);
    return new Promise((resolve) => {
      confirmPromiseRef.current = resolve;
    });
  };

  /**
   * 確認ダイアログの確認処理
   */
  const handleConfirmed = () => {
    if (confirmPromiseRef.current) {
      confirmPromiseRef.current(true);
    }
    setOpenConfirmDialog(false);
  };

  /**
   * 確認ダイアログのキャンセル処理
   */
  const handleCancel = () => {
    if (confirmPromiseRef.current) {
      confirmPromiseRef.current(false);
    }
    setOpenConfirmDialog(false);
  };

  const getPageNavigationIconItems = (): NavigationIconMenu[] => {
    const navigationIconItems: NavigationIconMenu[] = [];
    navigationIconItems.push({
      name: 'edit',
      displayName: '編集',
      func: () => {
        handleEstimateRequestInput();
      },
      disabled: !isEditable,
    });
    navigationIconItems.push({
      name: 'delete',
      displayName: '削除',
      func: () => {
        // 一時保存・公開却下なら削除
        if (
          ['B00', 'B02'].some(
            (systemName) => item?.status?.systemName === systemName
          )
        ) {
          handleDelete();
          return;
        }
        // 未完了なら破棄
        if (
          ['B04'].some((systemName) => item?.status?.systemName === systemName)
        ) {
          if (
            (convertLongToNumber(item?.countEstimateResult) ?? 0) +
              (convertLongToNumber(item?.countEstimateResultDecline) ?? 0) >
            0
          ) {
            error([invalidDiscardMessage]);
            return;
          }
          setDiscardComment('');
          setOpenDiscard(true);
          return;
        }
        // それ以外はエラー
        error([invalidStatusMessage]);
      },
      disabled: !isDeletable,
    });
    navigationIconItems.push({
      name: 'history',
      displayName: '履歴',
      func: handleOpenHistoryDialog,
    });
    navigationIconItems.push({
      name: 'recipients',
      displayName: '通知設定',
      func: () => {
        setOpenSystemNotificationDialog(true);
      },
      disabled: !isSystemNotificationEditable,
    });
    return navigationIconItems;
  };

  const estimateConfirmationHeaderInfo =
    useMemo<EstimateConfirmationHeaderInfo>(() => {
      return {
        displayName: estimateRequestData.current?.displayName ?? '',
        estimateDeadlineDt: getDateFormatWithTimezone(
          estimateRequestData.current?.estimateDeadlineDt
        ),
        requestAutoName: convertLongToString(
          estimateRequestData.current?.estimateRequestAutoName
        ),
        requestAutoNameLabelId: 'requestAutoName',
        desiredReceivedDt: formatDisplayDesiredReceivedDt(
          estimateRequestData.current
        ),
        requestedDt: getDateFormat(
          estimateRequestData.current?.sendedAt ?? '',
          'YYYY/MM/DD'
        ),
        deliveryPoint: formatDisplayDeliveryPoint(estimateRequestData.current),
        contactProperties: formatDisplayContactProperties(
          estimateRequestData.current
        ),
      };
      // estimateRequestData.currentが変更された場合に起動しているためlintから除外させる
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [estimateRequestData.current]);

  const estimateRequestDetailList = useMemo<
    EstimateRequestDetailItemType[]
  >(() => {
    return (
      estimateRequestDetailsData.current?.map((data) => {
        return {
          id: data?.estimateRequestDetailId ?? '',
          detailNumber: convertLongToString(data?.detailAutoName) ?? '',
          code: data?.transactionUnit?.transactionUnitCode ?? '',
          displayName: data?.displayName ?? '',
          amount: [
            toCommaTypeString(data.quantity?.amountNum),
            data.quantity?.unit?.displayNameLang?.ja ?? '',
          ].join(''),
          assetId: data?.attachment?.assetId ?? '',
          assetName: (
            <a
              onClick={() => {
                handleFileViewer([data?.attachment?.assetId ?? '']);
              }}
            >
              {data?.attachment?.filename ?? ''}
            </a>
          ),
        };
      }) ?? []
    );
    // estimateRequestData.currentが変更された場合に起動しているためlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estimateRequestDetailsData.current]);

  return (
    <>
      <Container viewId={VIEW_ID}>
        <div className="EstEstimateRequestConfirmation">
          <div className="header">
            <PageNavigation
              backpagePath={''}
              iconItems={getPageNavigationIconItems()}
              pageInfo={{
                isDisableBackNavi: !location.baseViewOption?.sourceViewId,
                isVisibleMoveNavi: true,
                isFirstPage,
                isMaxPage,
                pageNumber: page.pageNumber,
              }}
              handleMovePage={handleMovePage}
              infoOption={{
                lastUpdateInfo: { isVisibleUpdateInfo: false },
                issuerInfo: { isVisibleIssuerInfo: false },
              }}
              handleBackPage={() => backToBaseViewPage(navi, location, VIEW_ID)}
            />
          </div>
          <div className="scroll-main-contents-area">
            <div className="info">
              <div className="basic-info-body">
                {/* ////通知枠//// */}
                <div
                  className={`notification-area ${
                    isNotificationShow ? '' : 'close'
                  }`}
                >
                  {/* {履歴通知エリア} */}
                  <div className="input-line">
                    <div className="item-group-100">
                      <div className="w-50">
                        <div className="notification-display-area">
                          {historyMessage}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="info-body-header">
                  <EstimateConfirmationHeader
                    estimateResultHeaderInfo={estimateConfirmationHeaderInfo}
                    listIconViewInfo={{
                      viewData: estimateRequestDetailList,
                      keyColumn: 'id',
                      columnInfo: estimateRequestHeaderColumns,
                      assetIdColumn: 'assetId',
                      func: (v: EstimateRequestDetailItemType) => {
                        detailConfirmationDialogId.current = v.id;
                        setOpenDetailConfirmationDialog(true);
                      },
                    }}
                  />
                </div>
                <Tabs
                  tabItems={tabItems()}
                  onChangeSelecetedTabIndex={setTabIndex}
                />
              </div>
            </div>
          </div>
        </div>
        <Toast />
      </Container>
      {/* システム通知設定ダイアログ */}
      <SystemNotificationSettingDialog
        isOpen={isOpenSystemNotificationDialog}
        inputData={systemNotificationUsers}
        onDecision={(result) => {
          handleSystemNotificationSetting(result);
        }}
        onCancel={() => {
          setOpenSystemNotificationDialog(false);
        }}
      />
      {/* 履歴ダイアログ */}
      <HistoryDialog
        isOpen={isOpenHistoryDialog}
        messageOption={{
          headerLabelId: {
            id: 'history',
            prefixId: 'DIALOG_TITLE',
          },
        }}
        inputOption={{
          data: histories ?? [],
          keyColumn: 'history_id',
          columns: [
            {
              propertyName: 'slip_category',
              width: '12rem',
              header: {
                prefixId: 'HistoryDialog',
                id: 'slip_category',
              },
            },
            {
              propertyName: 'occurred_at',
              width: '12rem',
              header: {
                prefixId: 'HistoryDialog',
                id: 'occurred_at',
              },
            },
            {
              propertyName: 'content',
              header: {
                prefixId: 'HistoryDialog',
                id: 'content',
              },
            },
          ],
        }}
        onCancel={() => {
          setOpenHistoryDialog(false);
        }}
      />
      {/* 破棄ダイアログ */}
      <CommentDialog
        isOpen={isOpenDiscard}
        inputOption={{ comment: discardComment, butonType: 'high' }}
        inputStateOption={{ onChangeComment: setDiscardComment }}
        messageOption={{
          headerLabelId: {
            id: 'estimate_discard',
            prefixId: 'DIALOG_TITLE',
          },
          messageLabelId: {
            id: 'estimate_discard',
            prefixId: 'DIALOG_DESCRIPTION',
          },
        }}
        onDecision={handleDiscard}
        onCancel={() => {
          setOpenDiscard(false);
        }}
      />
      {/* 確認ダイアログ */}
      <ConfirmationDialog
        isOpen={isOpenConfirmDialog}
        viewMessage={confirmMessage}
        onDecision={handleConfirmed}
        onCancel={handleCancel}
      />
      {/* 明細確認ダイアログ */}
      <DetailConfirmationDialog
        isOpen={isOpenDetailConfirmationDialog}
        inputOption={{
          detailInfo:
            estimateRequestDetailsData.current?.find(
              (v) =>
                v.estimateRequestDetailId === detailConfirmationDialogId.current
            ) ?? {},
        }}
        onCancel={() => {
          setOpenDetailConfirmationDialog(false);
        }}
      />
      {isShowLoading && <LoadingIcon />}
    </>
  );
}
