import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface UpdateEstimateResultRequest {
  actionName: 'updateEstimateResult';
  request: mtechnavi.api.estimation.IEstimateResult;
}

export const updateEstimateResult = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.estimation.IEstimateResult
) => {
  const updateFullMethodName =
    'mtechnavi.api.estimation.EstimateReceiverService/UpdateEstimateResult';

  return worker.invokeListRequest<
    mtechnavi.api.estimation.IUpdateEstimateResultRequest,
    void
  >(updateFullMethodName, {
    estimateResult: req,
  });
};
