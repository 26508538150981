import { FullMethodName_SaveOrganizations } from 'worker/fullMethodName/crud';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface SaveOrganizationsAction {
  actionName: 'saveOrganizations';
  request: mtechnavi.api.company.ISaveOrganizationRequest;
}

export const saveOrganizations = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.company.ISaveOrganizationRequest
) => {
  const result = await worker.invokeListRequest<
    mtechnavi.api.company.ISaveOrganizationRequest,
    mtechnavi.api.company.IOrganization
  >(FullMethodName_SaveOrganizations, req);
  return result;
};
