import {
  Ref,
  RefObject,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { GroupTreeItem } from './type';
import { GroupTreeEdge } from './GroupTreeEdge';
import { GroupTreeEditActions, GroupTreeEditPane } from './GroupTreeEditPane';
import { getGenerallyIconElement } from '~/shared/components/parts/Button/GenerallyIcons';
import { GroupTreeNodeRef, isMatchItem } from './util';

export interface GroupTreeNodeProps<T> {
  data: GroupTreeItem<T>;
  level: number;
  searchWord?: string;
  isShowDisabled?: boolean;
  isParentDisable?: boolean;
  selectedGroup: string | null;
  onSelectGroup: (selectedGroup: string | null) => void;
  onDrop?: (data: { targetId: string; parentId: string }) => void;
  editActions: GroupTreeEditActions<T>;
  parentRef?: RefObject<GroupTreeNodeRef>;
  refList: { [k: string]: RefObject<GroupTreeNodeRef> };
  isEnableDnD?: boolean;
  isEditable?: boolean;
  isShowAddChild: boolean | ((data: T | undefined) => boolean);
}

/**
 * ツリーの枝コンポーネント。
 * 子として葉を持つか、さらに枝を持つ。
 */
export const GroupTreeNode = forwardRef(
  <T,>(
    {
      data,
      level,
      searchWord,
      isShowDisabled,
      isParentDisable,
      selectedGroup,
      onSelectGroup,
      onDrop,
      editActions,
      parentRef,
      refList,
      isEnableDnD,
      isEditable,
      isShowAddChild,
    }: GroupTreeNodeProps<T>,
    ref: Ref<GroupTreeNodeRef> | undefined
  ) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [isOpen, setOpen] = useState<boolean>(true);
    const isSelected = selectedGroup === data.id;
    const isDisabled = isParentDisable || data.isDisabled;

    const childIdList = (data.children || []).map((item) => item.id);
    const childRefList = useMemo(() => {
      const entries = Object.entries(refList).filter(([id]) =>
        childIdList.includes(id)
      );
      return Object.fromEntries(entries);
    }, [refList, childIdList]);

    const isMatch = useMemo<boolean | null>(() => {
      if (!searchWord) {
        return null;
      }
      return isMatchItem(data, searchWord);
    }, [data, searchWord]);

    const handleSelect = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation();
      onSelectGroup && onSelectGroup(data.id || null);
    };

    const handleDragStart = (e: React.DragEvent<HTMLDivElement>) => {
      e.stopPropagation();
      e.dataTransfer.effectAllowed = 'move';
      e.dataTransfer.setData('text/plain', data.id || '');
    };
    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
      e.stopPropagation();
      e.preventDefault();
      const targetId = e.dataTransfer.getData('text/plain');
      const parentId = data.id || '';
      if (targetId === parentId) {
        return;
      }
      onDrop && onDrop({ targetId, parentId });
    };
    const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
    };
    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
    };

    useImperativeHandle(
      ref,
      (): GroupTreeNodeRef => ({
        allOpen: () => {
          setOpen(true);
          (data.children || []).forEach((item) => {
            const ref = childRefList[item.id || '']?.current;
            ref?.allOpen && ref.allOpen();
          });
        },
        allClose: () => {
          setOpen(false);
          (data.children || []).forEach((item) => {
            const ref = childRefList[item.id || '']?.current;
            ref?.allClose && ref.allClose();
          });
        },
        open: () => {
          setOpen(true);
          parentRef?.current?.open && parentRef.current.open();
        },
        close: () => {
          setOpen(false);
          parentRef?.current?.close && parentRef.current.close();
        },
        focus: () => {
          containerRef.current?.focus();
          parentRef?.current?.open && parentRef.current.open();
        },
      })
    );

    return (
      <div
        className={`GroupTreeNode id-${data.id} ${
          isSelected ? 'selected' : ''
        } ${level % 2 === 0 ? 'even' : 'odd'} ${isDisabled ? 'disabled' : ''} ${
          isMatch === null ? '' : isMatch ? 'matched' : 'unmatched'
        }`}
        key={data.id}
        onClick={handleSelect}
        draggable={isEnableDnD}
        onDragStart={handleDragStart}
        onDragEnter={handleDragEnter}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        ref={containerRef}
      >
        <div className="GroupLabel" onClick={() => setOpen(!isOpen)}>
          <span className={`GroupToggle ${isOpen ? 'open' : ''}`}>
            {getGenerallyIconElement('down')}
          </span>
          <span>{`${data.displayName}(${data.code ?? ''})`}</span>
          <GroupTreeEditPane
            data={data}
            isEditable={isEditable}
            editActions={editActions}
            isDisabled={isDisabled}
            isShowAddChild={isShowAddChild}
          />
        </div>

        <div className={`ChildTree ${isOpen ? 'open' : 'close'}`}>
          {data.children?.map((item) => {
            if (!isShowDisabled && item.isDisabled) {
              return;
            }
            const hasChild = item && (item.children || []).length > 0;
            if (!hasChild) {
              return (
                <GroupTreeEdge
                  key={item.id}
                  data={item}
                  level={level + 1}
                  searchWord={searchWord}
                  isParentDisable={isDisabled}
                  selectedGroup={selectedGroup}
                  onSelectGroup={onSelectGroup}
                  editActions={editActions}
                  onDrop={onDrop}
                  ref={refList[item.id || '']}
                  parentRef={refList[data.id || '']}
                  isEnableDnD={isEnableDnD}
                  isEditable={isEditable}
                  isShowAddChild={isShowAddChild}
                />
              );
            }
            return (
              <GroupTreeNode
                key={item.id}
                data={item}
                level={level + 1}
                searchWord={searchWord}
                isShowDisabled={isShowDisabled}
                isParentDisable={isDisabled}
                selectedGroup={selectedGroup}
                onSelectGroup={onSelectGroup}
                editActions={editActions}
                onDrop={onDrop}
                ref={refList[item.id || '']}
                parentRef={refList[data.id || '']}
                refList={refList}
                isEnableDnD={isEnableDnD}
                isEditable={isEditable}
                isShowAddChild={isShowAddChild}
              />
            );
          })}
        </div>
      </div>
    );
  }
);
