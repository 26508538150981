import { mtechnavi } from '~/shared/libs/clientsdk';
import {
  FullMethodName_ExportProxySurveyResultWorkForms,
  FullMethodName_ExportSurveyResultWorkForms,
  FullMethodName_ListBaseFormSettingItems,
  FullMethodName_ListBaseFormSettings,
  FullMethodName_ListBaseForms,
  FullMethodName_ListBusinessUnitManagementContents,
  FullMethodName_ListComponentUnits,
  FullMethodName_ListFormSettingItems,
  FullMethodName_ListFormSettings,
  FullMethodName_ListFormValues,
  FullMethodName_ListForms,
  FullMethodName_ListResultFormShareManagements,
  FullMethodName_ListSharedContactContents,
  FullMethodName_ListStaffs,
  FullMethodName_ListSurveyReplyDueDateChangeRequests,
  FullMethodName_ListSurveyReplyDueDateChangeResults,
  FullMethodName_ListSurveyRequestCatalogs,
  FullMethodName_ListSurveyRequestWorkForms,
  FullMethodName_ListSurveyResultChangeRequests,
  FullMethodName_ListSurveyResultWorkForms,
  FullMethodName_ListUserAttributes,
  FullMethodName_SharedListSurveyReplyDueDateChangeRequests,
  FullMethodName_SharedListSurveyReplyDueDateChangeResults,
  FullMethodName_SharedListSurveyRequestWorkForms,
  FullMethodName_SharedListSurveyResultWorkForms,
  FullMethodName_SharedListSurveyResults,
} from '~/shared/utils';
import { FilterResult } from '~/worker';

/**
 * カタログマスタ取得
 * 1件目の ID が空の場合はエラーとする。
 */
export const listSurveyCatalog = async () => {
  const listRes = (await window.App.services.ui.worker.filter({
    action: 'reload',
    fullMethodName: FullMethodName_ListSurveyRequestCatalogs,
    filter: {},
    sort: [],
  })) as mtechnavi.api.survey.ListSurveyRequestCatalogsResponse;

  return new Promise<mtechnavi.api.survey.ListSurveyRequestCatalogsResponse>(
    (resolve, reject) => {
      const firstItem = listRes?.items.length > 0 ? listRes?.items[0] : {};
      if (firstItem?.surveyRequestCatalogId === '') {
        reject({ id: 'E0000070' });
        return;
      }
      resolve(listRes);
    }
  );
};

/**
 * 担当者マスタ取得
 * 1件目の ID が空の場合はエラーとする。
 */
export const listStaff = async () => {
  const listRes = (await window.App.services.ui.worker.filter({
    action: 'query',
    fullMethodName: FullMethodName_ListStaffs,
    filter: {},
    sort: [],
  })) as mtechnavi.api.company.ListStaffsResponse;

  return new Promise<mtechnavi.api.company.ListStaffsResponse>(
    (resolve, reject) => {
      const firstItem = listRes?.items.length > 0 ? listRes?.items[0] : {};
      if (firstItem?.staffId === '') {
        reject({ id: 'E0000070' });
        return;
      }
      resolve(listRes);
    }
  );
};

/**
 * 管理場所マスタ取得
 * 1件目の ID が空の場合はエラーとする。
 */
export const listComponentUnit = async () => {
  const listRes = (await window.App.services.ui.worker.filter({
    action: 'query',
    fullMethodName: FullMethodName_ListComponentUnits,
    filter: {},
    sort: [],
  })) as mtechnavi.api.company.ListComponentUnitsResponse;

  return new Promise<mtechnavi.api.company.ListComponentUnitsResponse>(
    (resolve, reject) => {
      const firstItem = listRes?.items.length > 0 ? listRes?.items[0] : {};
      if (firstItem?.componentUnitId === '') {
        reject({ id: 'E0000070' });
        return;
      }
      resolve(listRes);
    }
  );
};

/**
 * ユーザーマスタ取得
 * 1件目の ID が空の場合はエラーとする。
 */
export const listUser = async () => {
  const listRes = (await window.App.services.ui.worker.filter({
    action: 'reload',
    fullMethodName: FullMethodName_ListUserAttributes,
    filter: {},
    sort: [],
  })) as mtechnavi.api.tenantadmin.ListUserAttributesResponse;

  return new Promise<mtechnavi.api.tenantadmin.ListUserAttributesResponse>(
    (resolve, reject) => {
      const firstItem = listRes?.items.length > 0 ? listRes?.items[0] : {};
      if (firstItem?.userAttributeId === '') {
        reject({ id: 'E0000070' });
        return;
      }
      resolve(listRes);
    }
  );
};

/**
 * 取引先マスタ取得
 * 1件目の ID が空の場合はエラーとする。
 */
export const listBusinessUnitManagementContent = async () => {
  const listRes = (await window.App.services.ui.worker.filter({
    action: 'reload',
    fullMethodName: FullMethodName_ListBusinessUnitManagementContents,
    filter: {},
    sort: [],
  })) as mtechnavi.api.company.ListBusinessUnitManagementContentsResponse;

  return new Promise<mtechnavi.api.company.ListBusinessUnitManagementContentsResponse>(
    (resolve, reject) => {
      const firstItem = listRes?.items.length > 0 ? listRes?.items[0] : {};
      if (firstItem?.businessUnitManagement?.businessUnitManagementId === '') {
        reject({ id: 'E0000070' });
        return;
      }
      resolve(listRes);
    }
  );
};

/**
 * 回答期日変更依頼データの取得
 */
export const listLatestSurveyReplyDueDateChangeRequest = async (
  surveyRequestIds: string[]
) => {
  return (await window.App.services.ui.worker.filter({
    action: 'reload',
    fullMethodName: FullMethodName_ListSurveyReplyDueDateChangeRequests,
    filter: {},
    requestBody: {
      surveyRequestIds,
    },
    sort: [{ requestedAt: 'desc' }],
  })) as mtechnavi.api.survey.IListSurveyReplyDueDateChangeRequestsResponse;
};

/**
 * 回答期日変更依頼データの取得(最新1件)
 */
export const getLatestSurveyReplyDueDateChangeRequest = async (
  surveyRequestId: string
) => {
  const result = (await window.App.services.ui.worker.filter({
    action: 'reload',
    fullMethodName: FullMethodName_ListSurveyReplyDueDateChangeRequests,
    filter: {},
    requestBody: {
      surveyRequestIds: [surveyRequestId],
    },
    sort: [{ requestedAt: 'desc' }],
  })) as mtechnavi.api.survey.IListSurveyReplyDueDateChangeRequestsResponse;
  return result.items?.at(0);
};

/**
 * 回答期日変更データの取得(最新1件)
 */
export const getLatestSurveyReplyDueDateChangeResult = async (
  surveyRequestId: string
) => {
  const result = (await window.App.services.ui.worker.filter({
    action: 'reload',
    fullMethodName: FullMethodName_ListSurveyReplyDueDateChangeResults,
    filter: {},
    requestBody: {
      surveyRequestIds: [surveyRequestId],
    },
    sort: [{ changedAt: 'desc' }],
  })) as mtechnavi.api.survey.IListSurveyReplyDueDateChangeResultsResponse;
  return result.items?.at(0);
};

/**
 * 依頼回答データの取得(最新1件)
 */
export const getLatestSharedListSurveyResult = async (
  surveyRequestId: string
) => {
  const result = (await window.App.services.ui.worker.filter({
    action: 'reload',
    fullMethodName: FullMethodName_SharedListSurveyResults,
    filter: {},
    requestBody: {
      surveyRequestIds: [surveyRequestId],
    },
    sort: [{ sendedAt: 'desc' }],
  })) as mtechnavi.api.survey.ISharedListSurveyResultsResponse;
  return result.items?.at(0);
};

/**
 * 修正依頼データの取得(最新1件)
 */
export const getLatestSurveyResultChangeRequest = async (
  surveyRequestId: string,
  surveyResultId: string
) => {
  const result = (await window.App.services.ui.worker.filter({
    action: 'reload',
    fullMethodName: FullMethodName_ListSurveyResultChangeRequests,
    filter: {},
    requestBody: {
      surveyRequestIds: [surveyRequestId],
      surveyResultIds: [surveyResultId],
    },
    sort: [{ requestedAt: 'desc' }],
  })) as mtechnavi.api.survey.IListSurveyResultChangeRequestsResponse;
  return result.items?.at(0);
};

/**
 * 回答期日変更データ(shared)の取得
 */
export const getLatestSharedSurveyReplyDueDateChangeResult = async (
  surveyRequestId: string
) => {
  const result = (await window.App.services.ui.worker.filter({
    action: 'reload',
    fullMethodName: FullMethodName_SharedListSurveyReplyDueDateChangeResults,
    filter: {},
    requestBody: {
      surveyRequestIds: [surveyRequestId],
    },
    sort: [{ changedAt: 'desc' }],
  })) as mtechnavi.api.survey.IListSurveyReplyDueDateChangeResultsResponse;
  return result.items?.at(0);
};

/**
 * 回答期日変更依頼データの取得
 * @deprecated 旧実装時の処理
 */
export const listSharedSurveyReplyDueDateChangeRequest = async (
  surveyRequestIds: string[]
) => {
  return window.App.services.ui.worker.filter({
    action: 'reload',
    fullMethodName: FullMethodName_SharedListSurveyReplyDueDateChangeRequests,
    filter: {},
    requestBody: {
      surveyRequestIds,
    },
    sort: [{ requestedAt: 'desc' }],
  }) as mtechnavi.api.survey.IListSurveyReplyDueDateChangeRequestsResponse;
};

/**
 * 回答期日変更依頼データの取得(最新１件)
 */
export const getLatestSharedSurveyReplyDueDateChangeRequest = async (
  surveyRequestId: string
) => {
  const result = (await window.App.services.ui.worker.filter({
    action: 'reload',
    fullMethodName: FullMethodName_SharedListSurveyReplyDueDateChangeRequests,
    filter: {},
    requestBody: {
      surveyRequestIds: [surveyRequestId],
    },
    sort: [{ requestedAt: 'desc' }],
  })) as mtechnavi.api.survey.IListSurveyReplyDueDateChangeRequestsResponse;
  return result.items?.at(0);
};

/**
 * 依頼フォームデータの取得
 */
export const listSurveyRequestWorkForms = async (formIds: string[]) => {
  return (await window.App.services.ui.worker.filter({
    action: 'reload',
    fullMethodName: FullMethodName_ListSurveyRequestWorkForms,
    filter: {},
    sort: [{ order: 'asc' }],
    requestBody: {
      formIds,
    },
  })) as mtechnavi.api.survey.ListSurveyRequestWorkFormsResponse;
};

/**
 * 回答フォーム(予定)データの取得
 */
export const listPlanSurveyResultWorkForms = async (
  surveyResultWorkFormIds: string[]
) => {
  return (await window.App.services.ui.worker.filter({
    action: 'reload',
    fullMethodName: FullMethodName_ListSurveyResultWorkForms,
    filter: {},
    sort: [{ order: 'asc' }],
    requestBody: {
      surveyResultWorkFormIds,
    },
  })) as mtechnavi.api.survey.ListSurveyResultWorkFormsResponse;
};

/**
 * 回答フォーム(実績)データの取得
 */
export const listActualSurveyResultWorkForms = async (
  surveyResultWorkFormIds: string[]
) => {
  return (await window.App.services.ui.worker.filter({
    action: 'reload',
    fullMethodName: FullMethodName_ListSurveyResultWorkForms,
    filter: {},
    sort: [{ order: 'asc' }],
    requestBody: {
      formIds: surveyResultWorkFormIds,
    },
  })) as mtechnavi.api.survey.ListSurveyResultWorkFormsResponse;
};

/**
 * フォームデータの取得
 */
export const listForms = async (formIds: string[]) => {
  return (await window.App.services.ui.worker.filter({
    action: 'reload',
    fullMethodName: FullMethodName_ListForms,
    filter: {},
    sort: [],
    requestBody: {
      formIds,
    },
  })) as mtechnavi.api.form.ListFormsResponse;
};

/**
 * フォーム設定データの取得
 */
export const listFormSettings = async (formIds: string[]) => {
  return (await window.App.services.ui.worker.filter({
    action: 'reload',
    fullMethodName: FullMethodName_ListFormSettings,
    filter: {},
    sort: [],
    requestBody: {
      formIds,
    },
  })) as mtechnavi.api.form.ListFormSettingsResponse;
};

/**
 * フォーム項目データの取得
 */
export const listFormSettingItems = async (formIds: string[]) => {
  return (await window.App.services.ui.worker.filter({
    action: 'reload',
    fullMethodName: FullMethodName_ListFormSettingItems,
    filter: {},
    sort: [{ 'item.sort': 'asc' }],
    requestBody: {
      formIds,
    },
  })) as mtechnavi.api.form.ListFormSettingItemsResponse;
};

/**
 * フォーム入力値データの取得
 */
export const listFormValues = async (
  formIds: string[] | null,
  typeName?: string | null,
  recordId?: string | null
) => {
  return (await window.App.services.ui.worker.filter({
    action: 'reload',
    fullMethodName: FullMethodName_ListFormValues,
    filter: {},
    sort: [],
    requestBody: {
      formIds,
      typeName,
      recordId,
    },
  })) as mtechnavi.api.form.ListFormValuesResponse;
};

/**
 * フォームテンプレート取得
 */
export const listBaseForms = async (filter?: {
  purposes?: string[];
  baseFormIds?: string[];
}) => {
  return (await window.App.services.ui.worker.filter({
    action: 'reload',
    fullMethodName: FullMethodName_ListBaseForms,
    filter: {
      ...(filter?.purposes && {
        usable: { $eq: true },
        'formProperties.purposes.code': {
          $in: filter.purposes,
        },
      }),
      ...(filter?.baseFormIds && {
        baseFormId: { $in: filter.baseFormIds },
      }),
    },
    sort: [],
  })) as mtechnavi.api.form.ListBaseFormsResponse;
};

/**
 * フォーム設定データの取得
 */
export const listBaseFormSettings = async (baseFormIds: string[]) => {
  return (await window.App.services.ui.worker.filter({
    action: 'reload',
    fullMethodName: FullMethodName_ListBaseFormSettings,
    filter: {},
    sort: [],
    requestBody: {
      baseFormIds,
    },
  })) as mtechnavi.api.form.ListBaseFormSettingsResponse;
};

/**
 * フォーム項目データの取得
 */
export const listBaseFormSettingItems = async (baseFormIds: string[]) => {
  return (await window.App.services.ui.worker.filter({
    action: 'reload',
    fullMethodName: FullMethodName_ListBaseFormSettingItems,
    filter: {},
    sort: [{ 'item.sort': 'asc' }],
    requestBody: {
      baseFormIds,
    },
  })) as mtechnavi.api.form.ListBaseFormSettingItemsResponse;
};

/**
 * 回答フォームのダウンロード(送信側)
 */
export const exportProxySurveyResultWorkForms = async (
  surveyRequestId: string
) => {
  return (await window.App.services.ui.worker.filter({
    action: 'reload',
    fullMethodName: FullMethodName_ExportProxySurveyResultWorkForms,
    filter: {},
    sort: [],
    requestBody: { surveyRequestId },
  })) as FilterResult<mtechnavi.api.survey.IExportProxySurveyResultWorkFormsResponse>;
};

/**
 * 回答フォームのダウンロード(受信側)
 */
export const exportSurveyResultWorkForms = async (surveyRequestId: string) => {
  return (await window.App.services.ui.worker.filter({
    action: 'reload',
    fullMethodName: FullMethodName_ExportSurveyResultWorkForms,
    filter: {},
    sort: [],
    requestBody: { surveyRequestId },
  })) as FilterResult<mtechnavi.api.survey.IExportSurveyResultWorkFormsResponse>;
};

/**
 * 依頼フォームデータの取得(受信側)
 */
export const listSharedSurveyRequestWorkForms = async (formIds: string[]) => {
  return (await window.App.services.ui.worker.filter({
    action: 'reload',
    fullMethodName: FullMethodName_SharedListSurveyRequestWorkForms,
    filter: {},
    sort: [{ order: 'asc' }],
    requestBody: {
      formIds,
    },
  })) as mtechnavi.api.survey.ListSurveyRequestWorkFormsResponse;
};

/**
 * 回答フォームデータの取得(受信側)
 */
export const listSharedSurveyResultWorkForms = async (
  surveyResultWorkFormIds: string[]
) => {
  return (await window.App.services.ui.worker.filter({
    action: 'reload',
    fullMethodName: FullMethodName_SharedListSurveyResultWorkForms,
    filter: {},
    sort: [{ order: 'asc' }],
    requestBody: {
      formIds: surveyResultWorkFormIds,
    },
  })) as mtechnavi.api.survey.ListSurveyResultWorkFormsResponse;
};

/**
 * 回答フォーム送信済み管理情報の取得
 */
export const listResultFormShareManagements = async (
  surveyReceptionIds: string[]
) => {
  return (await window.App.services.ui.worker.filter({
    action: 'reload',
    fullMethodName: FullMethodName_ListResultFormShareManagements,
    filter: {},
    sort: [],
    requestBody: {
      surveyReceptionIds,
    },
  })) as mtechnavi.api.survey.ListResultFormShareManagementsResponse;
};

/**
 * 依頼フォームデータの削除
 */
export const deleteRequestFormData = async (requestFormIds: string[]) => {
  if (!requestFormIds || requestFormIds.length === 0) {
    return;
  }
  const requestForms = await listSurveyRequestWorkForms(requestFormIds);
  const deleteRequestFormRequests = requestForms.items.map((form) =>
    window.App.services.ui.worker.apiCall({
      actionName: 'deleteSurveyRequestWorkForm',
      request: {
        surveyRequestWorkForm: {
          surveyRequestWorkFormId: form.surveyRequestWorkFormId,
          formId: form.formId,
          updatedAt: form.updatedAt,
        },
      },
    })
  );
  return Promise.all([
    ...deleteRequestFormRequests,
    window.App.services.ui.worker.apiCall({
      actionName: 'deleteFormWithRelatedItems',
      request: requestForms.items.map(
        (requestForm) => requestForm?.formId || ''
      ),
    }),
  ]);
};

/**
 * 回答フォーム(予定)データの削除
 */
export const deletePlanResultFormData = async (
  surveyResultWorkFormIds: string[]
) => {
  if (!surveyResultWorkFormIds || surveyResultWorkFormIds.length === 0) {
    return;
  }
  const resultForms = await listPlanSurveyResultWorkForms(
    surveyResultWorkFormIds
  );
  const deleteResultFormRequests = resultForms.items.map((form) =>
    window.App.services.ui.worker.apiCall({
      actionName: 'deleteSurveyResultWorkForm',
      request: {
        surveyResultWorkForm: {
          surveyResultWorkFormId: form.surveyResultWorkFormId,
          updatedAt: form.updatedAt,
        },
      },
    })
  );
  return Promise.all(deleteResultFormRequests);
};

/**
 * 連絡先を全取得する
 */
export const listSharedContactContents = async () => {
  return (await window.App.services.ui.worker.filter({
    action: 'reload',
    fullMethodName: FullMethodName_ListSharedContactContents,
    filter: {},
    sort: [],
    requestBody: {},
  })) as mtechnavi.api.company.IListSharedContactContentsResponse;
};
