import React, { useEffect, useState } from 'react';
import { Container, Toast } from '~/shared/components';
import {
  CategorizedCheckList,
  CategorizedList,
  CheckItem,
} from '~/shared/components/ui/CategorizedCheckList';
import './CategorizedCheckSample.css';
import { Textarea } from '~/shared/components/ui';
import { CaptionButton } from '~/shared/components/ui/Button';
import { convertSkillTreeToCategorizedList } from '~/shared/utils';

export const CategorizedCheckSample = () => {
  const [data, setData] = useState<CategorizedList>([]);
  const [checkList, setCheckList] = useState<CheckItem[]>([]);
  const [checkText, setCheckText] = useState<string>('');
  const [checkedSkills, setCheckedSkills] = useState<string[]>([]);
  const [disabled, setDisabled] = useState<boolean>(false);

  const handleChange = (checkedIdList: CheckItem[]) => {
    setCheckList(checkedIdList);
  };

  const changeCheckText = (value: string) => {
    setCheckText(value);
  };

  const handleSetCheck = () => {
    try {
      const checkedSkills = JSON.parse(checkText);
      setCheckedSkills(checkedSkills);
    } catch {
      console.error('failed JSON.parse', checkText);
    }
  };

  const handleToggleDisabled = () => {
    setDisabled(!disabled);
  };

  useEffect(() => {
    (async () => {
      const skillTree =
        await window.App.services.businessUnitManagementService.getBusinessUnitSkillTree(
          {}
        );
      setData(convertSkillTreeToCategorizedList(skillTree));
    })();
  }, []);

  return (
    <Container>
      <div className="CategorizedCheckSamplePage">
        <div className="category-area">
          <CategorizedCheckList
            listData={data}
            checkedIds={checkedSkills}
            disabled={disabled}
            onChange={handleChange}
          ></CategorizedCheckList>
        </div>
        <div className="result-area">{JSON.stringify(checkList, null, 2)}</div>

        <div className="input-line">
          <div className="item-group-100">
            <div className="w-75">
              <Textarea
                name="textarea"
                className="w-100 mh-middle"
                onChangeState={changeCheckText}
                columns={['textarea']}
              />
            </div>
            <div className="w-25">
              <CaptionButton
                buttonType="basic"
                name=""
                caption="JSON をチェックに反映"
                onClick={handleSetCheck}
              />
              <CaptionButton
                name=""
                buttonType={disabled ? 'cancel' : 'sub'}
                caption={disabled ? '編集可能に切替' : '閲覧のみに切替'}
                onClick={handleToggleDisabled}
              />
            </div>
          </div>
        </div>
      </div>
      <Toast />
    </Container>
  );
};
