import { GenerallyIconType } from '../parts/Button/GenerallyIcons';

export type ToolType = 'Forum' | 'Help' | 'None';
export interface ToolItem {
  name: ToolType;
  icon: GenerallyIconType;
}

export const toolItems: ToolItem[] = [
  {
    name: 'Forum',
    icon: 'message_text',
  },
  {
    name: 'Help',
    icon: 'help',
  },
];
