import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';
import { ISystemNotificationSetting } from '../type';

export interface UpdateSurveyRequestSystemNotificationSettingRequest {
  actionName: 'updateSurveyRequestSystemNotificationSetting';
  request: ISystemNotificationSetting;
}

export const updateSurveyRequestSystemNotificationSetting = async (
  worker: AppSaveWorker,
  req: ISystemNotificationSetting
) => {
  const getFullMethodName =
    'mtechnavi.api.survey.SurveySenderService/GetSurveyRequest';
  const updateFullMethodName =
    'mtechnavi.api.survey.SurveySenderService/UpdateSystemNotificationUsersInSurveyRequest';
  const items = await worker.invokeListRequest<
    mtechnavi.api.survey.IGetSurveyRequestContentRequest,
    mtechnavi.api.survey.SurveyRequest
  >(getFullMethodName, {
    surveyRequestId: req.recordId,
  });
  if (items && items.length > 0) {
    const item = items[0];
    const users = item.systemNotificationUsers;

    // 追加ユーザーが渡された場合
    if (req.appendUsers?.length) {
      req.appendUsers.forEach((v) => {
        if (!users.find((user) => user.userId === v.userId)) {
          users.push(v);
        }
      });
      item.systemNotificationUsers = users;
    }
    // 削除ユーザーが渡された場合
    if (req.removeUsers?.length) {
      const deleteUserIds = req.removeUsers.map((v) => v.userId ?? '');

      const deleteUsers = users.filter(
        (user) => !deleteUserIds.includes(user.userId ?? '')
      );
      item.systemNotificationUsers = deleteUsers;
    }

    await worker.invokeListRequest<
      mtechnavi.api.survey.IUpdateSystemNotificationUsersInSurveyRequestRequest,
      mtechnavi.api.survey.SurveyRequest
    >(updateFullMethodName, {
      surveyRequestId: item.surveyRequestId,
      systemNotificationUsers: item.systemNotificationUsers,
      updatedAt: item.updatedAt,
    });
  }
};
