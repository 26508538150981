import { useState } from 'react';
import { FileItem } from '~/shared/components/file';
import { CaptionButton } from '~/shared/components/ui/Button';
import { FileUploadAppendDialog } from '~/shared/components/ui/Dialog/FileUploadAppendDialog';

export const UISampleFileUploadAppendDialogs = () => {
  const [isSingleShow, setSingleShow] = useState(false);
  const [singleList, setSingleList] = useState<FileItem[]>([]);

  const [isMultiShow, setMultiShow] = useState(false);
  const [multiList, setMultiList] = useState<FileItem[]>([]);

  const [isNonDndShow, setNonDndShow] = useState(false);
  const [nonDndList, setNonDndList] = useState<FileItem[]>([]);

  return (
    <div className="categories">
      <h3 id="dialog">ファイル追加ダイアログ(追加可能)</h3>
      <h4>
        単一ファイル D&D <small>(50MB以下、.pdf, .xlsx)</small>
      </h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setSingleShow(true);
              }}
            />
            <FileUploadAppendDialog
              isOpen={isSingleShow}
              inputOption={{
                fineNames: singleList.map((v) => {
                  return v.file.name;
                }),
              }}
              messageOption={{
                headerLabelId: {
                  id: 'file_add',
                  prefixId: 'DIALOG_TITLE',
                },
              }}
              fileUploadOption={{
                multiple: false,
                validateOption: {
                  allowedFileExtensions: ['pdf', 'xlsx'],
                  maxFileSizeInMebis: 50,
                },
              }}
              onCancel={() => {
                setSingleShow(false);
              }}
              onDecision={async (result) => {
                setSingleShow(false);
                setSingleList((prev) => {
                  return [
                    ...prev.filter(
                      (v) =>
                        !result.files.some((i) => i.file.name === v.file.name)
                    ),
                    ...result.files,
                  ];
                });
              }}
              onChangeLoadingState={(isLoading) => {
                console.info('Loading Status', isLoading);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">
              {singleList && JSON.stringify(singleList, null, 2)}
            </pre>
          </div>
        </div>
      </div>

      <h4>
        複数ファイル D&D <small>(50MB以下、10つまで、.txt .png .pdf)</small>
      </h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setMultiShow(true);
              }}
            />
            <FileUploadAppendDialog
              isOpen={isMultiShow}
              inputOption={{
                fineNames: multiList.map((v) => {
                  return v.file.name;
                }),
              }}
              messageOption={{
                headerLabelId: {
                  id: 'file_add',
                  prefixId: 'DIALOG_TITLE',
                },
              }}
              fileUploadOption={{
                multiple: true,
                validateOption: {
                  allowedFileExtensions: ['pdf', 'png', 'txt'],
                  maxFileSizeInMebis: 50,
                  maxFileCount: 10,
                },
              }}
              onCancel={() => {
                setMultiShow(false);
              }}
              onDecision={async (result) => {
                setMultiShow(false);
                setMultiList((prev) => {
                  return [
                    ...prev.filter(
                      (v) =>
                        !result.files.some((i) => i.file.name === v.file.name)
                    ),
                    ...result.files,
                  ];
                });
              }}
              onChangeLoadingState={(isLoading) => {
                console.info('Loading Status', isLoading);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">
              {multiList && JSON.stringify(multiList, null, 2)}
            </pre>
          </div>
        </div>
      </div>

      <h4>
        単一ファイル non-D&D <small>(50MB以下、.pdf, .xlsx)</small>
      </h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setNonDndShow(true);
              }}
            />
            <FileUploadAppendDialog
              isOpen={isNonDndShow}
              inputOption={{
                fineNames: nonDndList.map((v) => {
                  return v.file.name;
                }),
              }}
              messageOption={{
                headerLabelId: {
                  id: 'file_add',
                  prefixId: 'DIALOG_TITLE',
                },
                captionLabelId: {
                  id: 'upload_csv',
                  prefixId: 'BusinessUnitCsvAdditionDialog',
                },
                decisionLabelId: {
                  id: 'import',
                },
              }}
              fileUploadOption={{
                multiple: false,
                validateOption: {
                  allowedFileExtensions: ['pdf', 'xlsx'],
                  maxFileSizeInMebis: 50,
                },
                isDnd: false,
              }}
              onCancel={() => {
                setNonDndShow(false);
              }}
              onDecision={async (result) => {
                setNonDndShow(false);
                setNonDndList((prev) => {
                  return [
                    ...prev.filter(
                      (v) =>
                        !result.files.some((i) => i.file.name === v.file.name)
                    ),
                    ...result.files,
                  ];
                });
              }}
              onChangeLoadingState={(isLoading) => {
                console.info('Loading Status', isLoading);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">
              {nonDndList && JSON.stringify(nonDndList, null, 2)}
            </pre>
          </div>
        </div>
      </div>
    </div>
  );
};
