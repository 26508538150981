import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { ReactComponent as SyncIcon } from '@material-design-icons/svg/outlined/sync.svg';
import { mtechnavi, sharelib } from '~/shared/libs/clientsdk';
import { IconButton } from '../../ui/Button';
import { ConfirmationDialog } from '../../ui';
import {
  getDateFormat,
  getWorkerExceptionMessage,
  nameOptionToLocaleString,
} from '~/shared/utils';
import { GetMessageWithIntl } from '../../parts/Message/Message';
import { error } from '../../parts/Toast/Toast';
import { ForumThreadType, ForumPrefixId as prefixId } from './utils/util';
import './ForumThreadComment.css';
import { useTooltip } from '../../parts/Tooltip';
import { useAuth } from '~/shared/contexts/AuthProvider';

export interface ForumThreadCommentProps {
  comment?: mtechnavi.api.forum.IComment;
  replyComment?: mtechnavi.api.forum.IComment;
  markerList?: mtechnavi.api.forum.IMarker[];
  unreadMarker?: sharelib.INameOption;
  isEditable?: boolean;
  isParentWorking?: boolean;
  isCanReply?: boolean;
  threadType?: sharelib.INameOption | null;
  onReply: (comment: mtechnavi.api.forum.IComment) => void;
  onRead: (comment: mtechnavi.api.forum.IComment) => void;
  onUnRead: (comment: mtechnavi.api.forum.IComment) => void;
  onDelete: (comment: mtechnavi.api.forum.IComment) => void;
}

/**
 * スレッドコメントコンポーネント
 */
export const ForumThreadComment = ({
  comment,
  replyComment,
  markerList,
  unreadMarker,
  isEditable,
  isParentWorking,
  isCanReply,
  threadType,
  onReply,
  onRead,
  onUnRead,
  onDelete,
}: ForumThreadCommentProps) => {
  const intl = useIntl();
  const myEmail = useAuth().user?.email ?? '';
  const myCompanyId = useAuth().tenant?.tenantId ?? '';
  const { showTooltip, hideTooltip } = useTooltip();
  const [isWorking, setWorking] = useState(false);
  const [isShowConfirm, setShowConfirm] = useState(false);
  const [deleteComment, setDeleteComment] =
    useState<mtechnavi.api.forum.IComment | null>();
  const commentDeleteText = `-- ${GetMessageWithIntl(intl, {
    id: 'COMMENT_DELETE_TEXT',
  })} --`;
  const deleteFileMessage = {
    id: 'C0000001',
    value: {
      $1: GetMessageWithIntl(intl, { prefixId, id: 'deleteComment' }),
    },
  };
  const isDisabled = isWorking || isParentWorking;
  const isOurComment =
    threadType?.systemName === ForumThreadType.Internal
      ? comment?.contributor?.user?.email === myEmail
      : comment?.contributor?.company?.companyId === myCompanyId;
  const hasUnReadMarker =
    unreadMarker &&
    (markerList || [])
      .flatMap((mark) => mark.markers)
      .find((marker) => marker?.systemName === unreadMarker.systemName);

  // 投稿者表記
  const metaDataText =
    threadType?.systemName === ForumThreadType.Internal
      ? comment?.contributor?.user?.displayName
      : nameOptionToLocaleString(intl, comment?.contributor?.company);

  /** 未読マーカーつけ外し */
  const handleToggleMarkAsUnRead = async (
    comment?: mtechnavi.api.forum.IComment,
    unreadMarker?: sharelib.INameOption,
    isRead?: boolean
  ) => {
    if (!unreadMarker || !comment) {
      return;
    }
    setWorking(true);
    try {
      if (isRead) {
        await onRead(comment);
      } else {
        await onUnRead(comment);
      }
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setWorking(false);
    }
  };

  const handleDeleteCommentConfirm = (
    comment?: mtechnavi.api.forum.IComment | null
  ) => {
    setShowConfirm(true);
    setDeleteComment(comment);
  };

  const handleDeleteComment = async (
    comment?: mtechnavi.api.forum.IComment | null
  ) => {
    if (!comment) {
      return;
    }
    try {
      setShowConfirm(false);
      setWorking(true);

      const deleteComment = {
        ...comment,
        text: commentDeleteText,
      };
      await onDelete(deleteComment);
      setDeleteComment(null);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setWorking(false);
    }
  };

  const handleReplyComment = (comment?: mtechnavi.api.forum.IComment) => {
    if (!onReply || !comment) {
      return;
    }
    onReply(comment);
  };

  const handleCancelDeleteComment = () => {
    setShowConfirm(false);
  };

  return (
    <div className={`ForumThreadComment ${isOurComment ? 'our' : 'their'}`}>
      {isOurComment && (
        <IconButton
          className="delete-button"
          name="delete"
          iconType="close"
          buttonType="danger"
          caption={GetMessageWithIntl(intl, { id: 'delete' })}
          disabled={isDisabled || comment?.text === commentDeleteText}
          onClick={() => handleDeleteCommentConfirm(comment)}
        />
      )}
      {replyComment && <div className="reply-detail">{replyComment.text}</div>}
      <p>{comment?.text}</p>
      <div className="comment-info">
        <div className="action">
          {isEditable && (
            <>
              {isCanReply && (
                <IconButton
                  name="reply"
                  iconType="reply"
                  buttonType="basic"
                  caption={GetMessageWithIntl(intl, {
                    prefixId,
                    id: 'quoteReply',
                  })}
                  disabled={isDisabled || comment?.text === commentDeleteText}
                  onClick={() => handleReplyComment(comment)}
                />
              )}
              {!isOurComment &&
                (hasUnReadMarker ? (
                  <IconButton
                    name="unread"
                    buttonType="basic"
                    iconType="unread"
                    caption={GetMessageWithIntl(intl, {
                      prefixId,
                      id: 'ToRead',
                    })}
                    disabled={isDisabled || comment?.text === commentDeleteText}
                    onClick={() =>
                      handleToggleMarkAsUnRead(comment, unreadMarker, true)
                    }
                  />
                ) : (
                  <IconButton
                    name="read"
                    buttonType="cancel"
                    iconType="read"
                    caption={GetMessageWithIntl(intl, {
                      prefixId,
                      id: 'ToUnread',
                    })}
                    disabled={isDisabled || comment?.text === commentDeleteText}
                    onClick={() =>
                      handleToggleMarkAsUnRead(comment, unreadMarker, false)
                    }
                  />
                ))}

              {isWorking && <SyncIcon className="working-icon" />}
            </>
          )}
        </div>
        <div className="metadata">
          <span
            onMouseEnter={(e) => {
              const tipText = `${comment?.contributor?.user?.displayName}\n${
                comment?.contributor?.user?.email ?? ''
              }`;
              showTooltip(tipText, e.currentTarget);
            }}
            onMouseLeave={hideTooltip}
          >
            {`[${metaDataText ?? ''}]`}
          </span>
          <span>
            {getDateFormat(comment?.contributedAt ?? '', 'YYYY/MM/DD HH:mm')}
          </span>
        </div>
      </div>

      <div className="comment-delete-dialog">
        <ConfirmationDialog
          isOpen={isShowConfirm}
          messageLabelId={deleteFileMessage}
          viewMessage={deleteFileMessage}
          onDecision={() => handleDeleteComment(deleteComment)}
          onCancel={() => handleCancelDeleteComment()}
        />
      </div>
    </div>
  );
};
