import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface CreateAssetRequest {
  actionName: 'createAsset';
  request: mtechnavi.api.assetinventory.ICreateAssetRequest[];
}

// アセット追加
export const createAssetInventry = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.assetinventory.ICreateAssetRequest[]
) => {
  const assetFullMethodName =
    'mtechnavi.api.assetinventory.AssetInventory/CreateAsset';
  const result = req.map((item) => {
    return worker.invokeListRequest<
      mtechnavi.api.assetinventory.ICreateAssetRequest,
      mtechnavi.api.assetinventory.IAsset
    >(assetFullMethodName, {
      asset: item.asset,
      sourceUrl: item.sourceUrl,
    });
  });

  return await Promise.all(result);
};
