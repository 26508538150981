import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  GetMessageWithIntl,
  LoadingIcon,
  Toast,
  error,
} from '~/shared/components';
import {
  ListView,
  ViewMenu,
  InitialFilter,
  MenuActionItem,
  Preset,
  Property,
  SystemNotificationSettingDialog,
  getAltDisplaySchema,
  getBooleanDataFormetterSchema,
  getDayFormetterDisplaySchema,
} from '~/shared/components/ui';
import { useAuth } from '~/shared/contexts/AuthProvider';
import { mtechnavi, sharelib } from '~/shared/libs/clientsdk';
import { PresetItem } from '~/shared/services';
import {
  FullMethodName_ListDisaster,
  PageState,
  ViewId,
  convertUserReference,
  getPresetAndSchema,
  getWorkerExceptionMessage,
  saveLocalStorageCheckboxData,
} from '~/shared/utils';
import './BcpDisasterList.css';
import Long from 'long';

const VIEW_ID: ViewId = 'BCP_DISASTER_LIST';
type DisasterSystemNotification = mtechnavi.api.bcp.DisasterSystemNotification;
const KISHOCHO_LINK = 'https://xml.kishou.go.jp/xmlpull.html';
export const BcpDisasterList = () => {
  const [isLoading, setLoading] = useState(false);
  const intl = useIntl();
  const navi = useNavigate();
  const myEmail = useAuth().user?.email ?? '';
  const [childrenPresetItem, setChildrenPresetItem] = useState<PresetItem[]>();
  const [schema, setSchema] = useState<Property[]>([]);
  const [preset, setPreset] = useState<Preset>({
    filter: {},
    propertyNames: [],
  });
  // 初期フィルタ設定
  const [initialFilter, setInitialFilter] = useState<InitialFilter>({
    info: [],
  });

  const [isSystemNotificationDialogShow, setSystemNotificationDialogShow] =
    useState(false);
  const [disasterSystemNotification, setDisasterSystemNotification] =
    useState<DisasterSystemNotification>();
  const [systemNotificationUsers, setSystemNotificationUsers] = useState<
    sharelib.IUserReference[]
  >([]);

  useEffect(() => {
    (async () => {
      try {
        const filter: InitialFilter = {
          info: [],
        };
        const date = new Date();
        date.setMonth(date.getMonth() - 6);
        const initialDate = Long.fromNumber(date.getTime())
          .multiply(1000)
          .toNumber();
        filter.info.push({
          targetKey: 'occurredAt',
          targetValue: initialDate,
        });
        setInitialFilter(filter);

        const { childrenPresetItem, schemas, preset } =
          await getPresetAndSchema(VIEW_ID, [FullMethodName_ListDisaster]);
        // ja変換
        const jaColumn: string[] = [
          'category.displayNameLang',
          'regions.displayNameLang',
        ];
        const formatterSch = getAltDisplaySchema(schemas[0], jaColumn, 'ja');
        const booleanColumn = ['withSurvey'];
        const booleanSch = getBooleanDataFormetterSchema(
          formatterSch,
          booleanColumn
        );
        const dateColumn = ['occurredAt'];
        const dateSch = getDayFormetterDisplaySchema(booleanSch, dateColumn, {
          dayOpts: {
            formatType: 'YYYY/MM/DD',
          },
        });

        // 取得した情報をセット
        setChildrenPresetItem(childrenPresetItem);
        setSchema(dateSch);
        setPreset(preset);
      } catch (err) {
        error(getWorkerExceptionMessage(intl, err));
        throw err;
      }
    })();
    // 初回起動時のみ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intl]);

  const setMenuActionItem = (): MenuActionItem[] => {
    const menuActionItems: MenuActionItem[] = [];
    menuActionItems.push({
      menuActionType: 'headerIconMenu',
      menu: headerIconEvent(),
      maxMenuColumn: 5,
    });
    menuActionItems.push({
      menuActionType: 'footerMenu',
      menu: footerMenuEvent(),
    });
    menuActionItems.push({
      menuActionType: 'footerInformation',
      menu: [
        {
          name: 'text_information',
          content: () => {
            return (
              <p className="kisho-link-area">
                情報取得元：気象庁
                <a href={KISHOCHO_LINK} target="_blank" rel="noreferrer">
                  {KISHOCHO_LINK}
                </a>
              </p>
            );
          },
          func: () => {},
        },
      ],
    });
    return menuActionItems;
  };

  const headerIconEvent = (): ViewMenu[] => {
    const menuItems: ViewMenu[] = [];
    // 通知先
    menuItems.push({
      name: 'recipients',
      func: () => {
        getSystemNotificationUsers();
        setSystemNotificationDialogShow(true);
      },
    });
    return menuItems;
  };
  const footerMenuEvent = (): ViewMenu[] => {
    const menuItems: ViewMenu[] = [];
    // 災害調査登録
    menuItems.push({
      name: 'input',
      func: (v?: string[]) => handleMenuToDisasterSurveyInput(v),
    });
    return menuItems;
  };

  // メニューの「災害調査登録」処理
  const handleMenuToDisasterSurveyInput = (prop?: string[]) => {
    if (!prop || prop.length === 0) {
      error([
        GetMessageWithIntl(intl, {
          id: 'E0000023',
        }),
      ]);
      return;
    }
    if (prop.length > 1) {
      error([
        GetMessageWithIntl(intl, {
          id: 'E0000073',
        }),
      ]);
      return;
    }

    const state: PageState = {
      sourceViewId: VIEW_ID,
      ids: prop ?? [],
      actionType: 'add',
      baseViewOption: { sourceViewId: VIEW_ID },
    };
    navi('/bcp/bcp-disaster-survey-input', { state });
    clearCheckBox();
  };

  const clearCheckBox = useCallback(() => {
    saveLocalStorageCheckboxData(VIEW_ID, [], myEmail);
  }, [myEmail]);

  // システム通知設定ダイアログ
  const getSystemNotificationUsers = () => {
    (async () => {
      const systemNotificattionList = (
        await window.App.services.bcpService.listDisasterSystemNotifications({})
      ).items as mtechnavi.api.bcp.DisasterSystemNotification[];
      setSystemNotificationUsers(
        systemNotificattionList && systemNotificattionList.length > 0
          ? systemNotificattionList[0].systemNotificationUsers
          : []
      );
      setDisasterSystemNotification(systemNotificattionList![0]);
    })();
  };

  const handleSystemNotificationSetting = async (result: string) => {
    setLoading(true);
    try {
      const add = result === 'setAsNotificationDestination' ? true : false;
      const userReference = await convertUserReference(myEmail);
      const reqUser: sharelib.IUserReference[] = [];
      if (!userReference) {
        return;
      }
      // システム通知設定未設定、設定アイテムなし
      if (!disasterSystemNotification && !add) {
        return;
      }
      // システム通知設定作成済みかつユーザーリストなし、設定アイテムなし
      if (systemNotificationUsers.length === 0 && !add) {
        return;
      }
      if (systemNotificationUsers.length === 0 && add) {
        reqUser.push(userReference);
      }
      if (systemNotificationUsers.length > 0 && add) {
        if (
          !systemNotificationUsers.some(
            (user) => user.userId === userReference.userId
          )
        ) {
          reqUser.push(...systemNotificationUsers);
          reqUser.push(userReference);
        } else {
          reqUser.push(...systemNotificationUsers);
        }
      }
      if (systemNotificationUsers.length > 0 && !add) {
        reqUser.push(
          ...systemNotificationUsers.filter(
            (user) => user.userId !== userReference.userId
          )
        );
      }
      const request: mtechnavi.api.bcp.IDisasterSystemNotification = {
        disasterSystemNotificationId:
          disasterSystemNotification?.disasterSystemNotificationId ?? null,
        systemNotificationUsers: reqUser,
        createdAt: disasterSystemNotification?.createdAt ?? null,
        updatedAt: disasterSystemNotification?.updatedAt ?? null,
        deletedAt: disasterSystemNotification?.deletedAt ?? null,
      };
      await window.App.services.ui.worker.apiCall({
        actionName: 'saveDisasterSystemNotification',
        request,
      });
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
    setSystemNotificationDialogShow(false);
  };
  return (
    <>
      <Container viewId={VIEW_ID}>
        <div className="bcpDisasterList">
          <ListView
            pageInfo={{
              schema: schema,
              preset: preset,
              menuItem: setMenuActionItem(),
              menuTarget: 'disasterId',
              headerTitle: { viewId: VIEW_ID },
              presetItems: childrenPresetItem,
              listSkipType: {
                isTotal: true,
                isOutput: true,
                isListActionMenu: true,
                isListIconMenu: true,
              },
            }}
            fullMethodName={FullMethodName_ListDisaster}
            filterItemOption={{
              isRequestBodyFilter: true,
              initialFilterItems: initialFilter,
            }}
          />
        </div>
        <SystemNotificationSettingDialog
          isOpen={isSystemNotificationDialogShow}
          inputData={systemNotificationUsers}
          onDecision={(result) => {
            handleSystemNotificationSetting(result);
          }}
          onCancel={() => {
            setSystemNotificationDialogShow(false);
          }}
        />
        <Toast />
      </Container>
      {isLoading && <LoadingIcon />}
    </>
  );
};
