import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface UpdateSurveyReceptionRequest {
  actionName: 'updateSurveyReception';
  request: mtechnavi.api.survey.ISurveyReception;
}

/**
 * 回答ステータス更新
 */
export const updateSurveyReception = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.survey.ISurveyReception
) => {
  const updateFullMethodName =
    'mtechnavi.api.survey.SurveyReceiverService/UpdateSurveyReception';

  // 依頼受信情報を更新
  return worker.invokeListRequest<
    mtechnavi.api.survey.IUpdateSurveyReceptionRequest,
    mtechnavi.api.survey.ISurveyReception
  >(updateFullMethodName, {
    surveyReception: req,
  });
};
