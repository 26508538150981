import { useRef, useState } from 'react';
import { Header } from '../Header/Header';
import { SideMenu } from '~/shared/components/parts/SideMenu/SideMenu';
import './Container.css';
import { ToolMenu } from '~/shared/components/tool/ToolMenu';
import { useToolContext } from '~/shared/contexts/ToolMenuProvider';
import { useEffect } from 'react';
import { ViewId } from '~/shared/utils';
import { TooltipAreaProvider } from '../../parts/Tooltip';

export interface ContainerProps {
  children: JSX.Element | JSX.Element[];
  viewId?: ViewId;
  forceNavSwitch?: boolean;
}

export function Container(props: ContainerProps) {
  const [navSwitch, setNavSwitch] = useState(false);
  const [headerMenueSwitch, setHeaderMenueSwitch] = useState(false);
  const { setViewId } = useToolContext();
  useEffect(() => {
    setViewId(props.viewId ?? '');
  }, [props, setViewId]);

  useEffect(() => {
    if (props.forceNavSwitch) {
      setNavSwitch(props.forceNavSwitch);
    }
  }, [props.forceNavSwitch]);

  const mainRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const footerElem = document.querySelector('.footer');
    // フッターがある場合
    if (footerElem) {
      // フッターが可変でリサイズされるため、contentクラス要素のフッタ用の余白を動的に調整する
      mainRef.current &&
        (mainRef.current.style.paddingBottom = `${footerElem.clientHeight}px`);
    }
  }, []);

  const handleSideMenu = () => {
    setNavSwitch(!navSwitch);
    setHeaderMenueSwitch(false);
  };
  const handleHeaderMenu = (v: boolean) => {
    setNavSwitch(false);
    setHeaderMenueSwitch(v);
  };

  return (
    <article className="container">
      <Header
        handleSideMenu={handleSideMenu}
        handleHeaderMenu={handleHeaderMenu}
        headerMenuSwich={headerMenueSwitch}
        viewId={props.viewId}
      />
      <div className="content">
        <div className="main" ref={mainRef}>
          <TooltipAreaProvider>{props.children}</TooltipAreaProvider>
        </div>
        <div
          className={navSwitch || headerMenueSwitch ? 'click-area' : ''}
          onClick={() => {
            setNavSwitch(false);
            setHeaderMenueSwitch(false);
          }}
        ></div>
        <SideMenu
          open={navSwitch}
          close={() => {
            setNavSwitch(false);
          }}
        />
        <ToolMenu />
      </div>
    </article>
  );
}
