import companyJa from './ja_company.json'; // 企業検索PoCで使用
import commonJa from './ja_common.json'; // システム全体に関するもの（ボタン名や複数機能跨いでいる項目名など）
import messageJa from './ja_message.json'; // メッセージ設計書に記載されている情報
import dialogJa from './ja_dialog.json'; // ダイアログで使用
import csvJa from './ja_csv.json'; // CSV出力時のヘッダで設定
import surveyJa from './ja_survey.json'; // 依頼調査機能で使用
import formJa from './ja_form.json'; // 入力フォーム機能で使用
import forumJa from './ja_forum.json'; // フォーラム機能で使用
import wtJa from './ja_wt.json'; // 指図案件で使用
import adminJa from './ja_admin.json'; // 管理機能で使用
import bumJa from './ja_bum.json'; // 取引先管理で使用
import estimateJa from './ja_estimate.json'; // 見積機能で使用
import masterJa from './ja_master.json'; // マスタ機能で使用
import tenantJa from './ja_tenant.json'; // テナントで使用
import ocpJa from './ja_ocp.json'; // 自社企業で使用
import headerTitleJa from './ja_headerTitle.json'; // 画面ヘッダーで使用（ヘッダータイトル）
import taskListJa from './ja_tasklist.json'; // タスク一覧（ヘッダーの通知メニュー）で使用
import bcpJa from './ja_bcp.json'; // bcpで使用
import optJa from './ja_opt.json'; // 運営テナントで使用

// 暫定処理。今後はBuild時の処理にまとめる
export const ja = {
  ...commonJa,
  ...csvJa,
  ...surveyJa,
  ...wtJa,
  ...ocpJa,
  ...formJa,
  ...forumJa,
  ...companyJa,
  ...messageJa,
  ...dialogJa,
  ...adminJa,
  ...bumJa,
  ...estimateJa,
  ...masterJa,
  ...tenantJa,
  ...headerTitleJa,
  ...taskListJa,
  ...bcpJa,
  ...optJa,
};
