import React, { useState, useEffect, useMemo } from 'react';
import {
  Container,
  ModalDialogComponent,
  ModalDialogComponentProps,
  Toast,
  error,
  success,
  GetMessage,
  LoadingIcon,
} from '~/shared/components';
import { FileUploader, FileItem } from '~/shared/components/file';
import {
  MenuListView,
  Preset,
  MenuItem,
  Property,
  ConfirmationDialog,
  DataFilterbox,
  DataFilterboxItem,
  getAltDisplaySchema,
  getDayFormetterDisplaySchema,
} from '~/shared/components/ui';
import { PresetItem, Property as PresetProperty } from '~/shared/services';
import { useNavigate } from 'react-router-dom';
import './AssetsUnitList.css';
import { ImportDialogHandleFormat } from '~/shared/components/ui/Dialog/ImportDialog';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { useIntl } from 'react-intl';

import type { FilterRequest } from '~/worker';
import { FILE_VIEWER_LOCALE_STORAGE_PREFIX } from '~/shared/components/ui/Viewer/FileViewer';
import {
  ViewId,
  getPresetAndSchema,
  getExceptionMessage,
  PageState,
  setLocalStorage,
  openWindowOrTab,
  FullMethodName_ListAssets,
} from '~/shared/utils';
import { CaptionButton } from '~/shared/components/ui/Button/CaptionButton';

const VIEW_ID: ViewId = 'ASSET_ASSET_LIST';

type Asset = mtechnavi.api.assetinventory.IAsset;
type DataFilterOption = { displayName: string; value: string };

const listAssetsByIds = async (ids: string[]): Promise<Asset[]> => {
  const option: FilterRequest = {
    action: 'query',
    fullMethodName: FullMethodName_ListAssets,
    filter: {
      assetId: {
        $in: ids,
      },
    },
    sort: [],
  };
  const res = (await window.App.services.ui.worker.filter(option)) as {
    items: Asset[];
  };
  return res.items;
};

export function AssetsUnitList() {
  const intl = useIntl();
  // 名称マスタからアセット用途を取得
  const purposeUnits: mtechnavi.api.programoption.IProgramOption[] =
    window.App.services.ui.getProgramOption('A0000033');
  const navi = useNavigate();
  const [fileItems, setFileItems] = useState<FileItem[]>();
  const [childrenPresetItem, setChildrenPresetItem] = useState<PresetItem[]>();
  const [schema, setSchema] = useState<Property[]>([]);
  const [preset, setPreset] = useState<Preset>({
    filter: {},
    propertyNames: [],
  });
  const [baseModalIsOpen, setModalIsOpen] = useState(false);
  const [comment, setComment] = useState('');
  const [purpose, setPurpose] = useState<DataFilterboxItem[]>([]);
  const [isConfirmDialog, setConfirmDialog] = useState(false);
  const [isBluePrintConenctConfirmDialog, setBluePrintConenctConfirmDialog] =
    useState(false);
  const [isReload, setReload] = useState(false);
  const [bluePrintConenctIds, setBluePrintConenctIds] = useState<string[]>([]);
  const successMessage = GetMessage({ id: 'I0000001' });
  const deleteFileMessage = {
    id: 'C0000001',
    value: { $1: 'ファイル削除' },
  };
  const bluePrintConenctMessage = {
    id: 'C0000001',
    value: { $1: '図面紐付け' },
  };
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [menuSelectIds, setMenuSelectIds] = useState<string[]>([]);
  const [isLoading, setLoading] = useState(false);

  // 取り込みダイアログ関係
  const dialogPreset: PresetProperty[] = useMemo(() => {
    return (
      childrenPresetItem?.find((v) => v.name === 'assetsUnitImport')
        ?.property ?? []
    );
  }, [childrenPresetItem]);

  useEffect(() => {
    (async () => {
      try {
        // スキーマ情報、preset関係の情報を取得
        const { childrenPresetItem, schemas, preset } =
          await getPresetAndSchema(VIEW_ID, [FullMethodName_ListAssets]);

        // ja表示のみする処理
        const jaColumn = [
          'status.status.displayNameLang',
          'purposeUnits.displayNameLang',
        ];
        const dayCololmns = ['createdAt', 'updatedAt'];
        const formatterSch = getAltDisplaySchema(schemas[0], jaColumn, 'ja');
        const daySch = getDayFormetterDisplaySchema(formatterSch, dayCololmns, {
          dayOpts: { formatType: 'YYYY/MM/DD HH:mm' },
        });
        // 取得した情報をセット
        setChildrenPresetItem(childrenPresetItem);
        setSchema(daySch);
        setPreset(preset);
      } catch (err) {
        error(getExceptionMessage(intl, err));
        throw err;
      }
    })();
  }, [intl]);

  const reloadAction = () => {
    setReload(true);
  };

  const handleExport: ImportDialogHandleFormat = {
    name: 'assetUnit',
    headerColumns: [],
  };

  const handleImport: ImportDialogHandleFormat = {
    name: 'assetUnit',
    headerColumns: [],
  };

  // サンプルでバーガーメニューに関数を渡すためのサンプル関数(ダミー関数)
  const handleMenu = (prop?: string[], type?: string) => {
    setLoading(true);
    const state: PageState = {
      ids: prop ?? [],
    };
    switch (type ?? '') {
      case 'confirm':
        navi('/asset-confirmation', {
          state,
        });
        break;
      default:
        break;
    }
    setLoading(false);
  };

  // ビューワー処理
  const handleMenuViewer = async (v: string[]) => {
    setLoading(true);
    // blueprintIDを元にassetIDを取得
    const assetList = await listAssetsByIds(v);
    const assetIds = assetList.map((v) => v?.assetId);
    const key = setLocalStorage(
      FILE_VIEWER_LOCALE_STORAGE_PREFIX,
      (assetIds ?? []) as string[]
    );
    setLoading(false);
    openWindowOrTab('file-viewer', key);
  };

  const handleDelete = () => {
    (async () => {
      setLoading(true);
      setReload(false);
      try {
        const assetList = await listAssetsByIds(menuSelectIds);
        const deletes = assetList.map((item) => {
          return window.App.services.assetInventory.deleteAsset({
            asset: {
              assetId: item.assetId,
              createdAt: item.createdAt,
              updatedAt: item.updatedAt,
            },
          });
        });
        await Promise.all(deletes);
        success([successMessage]);
        setConfirmDialog(false);
        reloadAction();
      } catch (err) {
        console.error(err);
        error(getExceptionMessage(intl, err));
        throw err;
      } finally {
        setLoading(false);
      }
    })();
  };

  const burgerMenuEvent = (): MenuItem[] => {
    // 必要な関数を宣言
    const menuItems: MenuItem[] = [];
    // ファイル確認
    menuItems.push({
      name: 'confirm',
      func: (v?: string[]) => handleMenu(v, 'confirm'),
    });
    menuItems.push({
      name: 'delete',
      func: (v?: string[]) => {
        setLoading(true);
        setMenuSelectIds(v!);
        setConfirmDialog(true);
        setLoading(false);
      },
    });
    // タグつけ ダイアログを表示するがタグAPIが未実装のため今は処理なし
    menuItems.push({ name: 'tag', func: (v?: string[]) => handleMenu(v) });
    // アセット追加
    menuItems.push({
      name: 'import',
      func: () => setModalIsOpen(true),
    });
    // 個別情報設定
    menuItems.push({
      name: 'drawingInfoSetting',
      func: (v?: string[]) => {
        setDialogOpen(true);
        handleMenu(v);
      },
    });
    // ビューワー
    menuItems.push({
      name: 'viewer',
      func: (v?: string[]) => handleMenuViewer(v!),
    });
    // 図面紐付け
    menuItems.push({
      name: 'drawingLinkage',
      func: (v?: string[]) => {
        handleMenu(v);
        setBluePrintConenctIds(v!);
        setBluePrintConenctConfirmDialog(true);
      },
    });
    return menuItems;
  };
  const menuViewlist = burgerMenuEvent().map((v) => v.name);

  const purposeUnitOptions: DataFilterOption[] = purposeUnits.map((v) => {
    return {
      displayName: v.displayNameLang?.ja ?? '',
      value: v.code ?? '',
    };
  });

  const handleUpload = (v: FileItem[]) => {
    if (!v.length) {
      return;
    }
    setFileItems(v);
  };

  const fileUpload = () => {
    const purposeUnit = purpose?.[0]?.value
      ? purposeUnits.find((v) => v.code === purpose[0].value)
      : null;
    (async () => {
      setLoading(true);
      setReload(false);
      try {
        const creats = (fileItems ?? []).map((file) => {
          return window.App.services.assetInventory.createAsset({
            asset: {
              displayName: file!.file.name ?? '',
              filename: file!.file.name ?? '',
              purposeUnits: purposeUnit && [purposeUnit],
            },
            sourceUrl: file!.url,
          });
        });
        await Promise.all(creats);
        success([successMessage]);
        setModalIsOpen(false);
        reloadAction();
      } catch (err) {
        console.error(err);
        error(getExceptionMessage(intl, err));
        throw err;
      } finally {
        setLoading(false);
      }
    })();
  };

  const blueprintConnection = () => {
    (async () => {
      setLoading(true);
      try {
        const fileFormat = await window.App.services.ui.getFileFormat(
          'blueprint',
          intl
        );
        const res =
          await window.App.services.blueprintService.linkAssetToBlueprint({
            fileFormat: fileFormat,
            listAssetsRequest: {
              ids: bluePrintConenctIds,
            },
            dryRun: false,
          });
        if (res.errors.length === 0) {
          success([successMessage]);
          setBluePrintConenctConfirmDialog(false);
        } else {
          error(res.errors as string[]);
          setBluePrintConenctConfirmDialog(false);
        }
      } catch (err) {
        console.error(err);
        error(getExceptionMessage(intl, err));
        throw err;
      } finally {
        setLoading(false);
      }
    })();
  };

  /**
   * ファイル選択ボタンに対する説明を設定する
   */
  const fileChooserCommentArea = () => {
    return (
      <div className="description">
        <p className="text">
          アップロードしたいファイルをここにドラッグ＆ドロップ
          <span className="joinText">または</span>
        </p>
      </div>
    );
  };

  const DaialogContent = () => (
    <div className="file-upload">
      <div className="upload-content">
        <div className="upload-select">
          <div>{GetMessage({ id: 'purpose' })}</div>
          <DataFilterbox
            data={purposeUnitOptions}
            name="purpose"
            columns={['purpose']}
            onChangeState={(v) => {
              setPurpose(v);
            }}
            value={purpose}
            searchOption={{ targets: 'displayName' }}
          />
        </div>
        <div className="upload-area">
          <FileUploader
            name="fileUploader"
            className="btnType"
            multiple={true}
            dndOption={{
              enabled: true,
              style: {
                border: 'none',
                backgroundColor: '',
                width: '100%',
                height: '180px',
              },
            }}
            onUpload={(v) => {
              handleUpload(v);
            }}
            onChangeLoadingState={setLoading}
            fileChooserComment={fileChooserCommentArea()}
          />
        </div>
        <div className="upload-footer">
          <CaptionButton
            name="resetBtn"
            caption="キャンセル"
            className="btn"
            properties={[
              {
                name: 'resetBtn',
                propertyName: 'resetBtn',
                propertyValue: 'resetBtn',
              },
            ]}
            onClick={() => setModalIsOpen(false)}
            buttonType="cancel"
          />
          <CaptionButton
            name="addBtn"
            caption="確定"
            className="btn"
            properties={[
              {
                name: 'addBtn',
                propertyName: 'addBtn',
                propertyValue: 'addBtn',
              },
            ]}
            onClick={() => fileUpload()}
            buttonType="basic"
          />
        </div>
      </div>
    </div>
  );
  const openModalProps: ModalDialogComponentProps = {
    modalIsOpen: baseModalIsOpen,
    comment: comment,
    onChangeState: (v) => {
      setComment(v);
    },
    headerLabelId: { prefixId: 'DIALOG_TITLE', viewId: VIEW_ID },
    messageLabelId: { prefixId: 'DIALOG_MESSAGE', viewId: VIEW_ID },
    send: () => {},
    cancel: () => setModalIsOpen(false),
    elements: DaialogContent(),
    className: 'file-dialog',
  };

  return (
    <>
      <Container viewId={VIEW_ID}>
        <div className="AssetsList">
          <MenuListView
            fullMethodName={FullMethodName_ListAssets}
            schema={schema}
            preset={preset}
            presetItems={childrenPresetItem}
            menuEvent={burgerMenuEvent()}
            listSkipType={{
              isOutput: true,
              isTotal: true,
            }}
            headerTitle={{ viewId: VIEW_ID }}
            menuTarget={'assetId'}
            menuViewlist={menuViewlist}
            isReload={isReload}
            importDialogOption={{
              isDisplay: true,
              isDialogOpen,
              dialogPreset,
              headerLabelId: { prefixId: 'DIALOG', viewId: VIEW_ID },
              handleExport,
              handleImport,
              onChangeState: (v) => {
                setDialogOpen(v);
              },
            }}
          />
          <ModalDialogComponent {...openModalProps} />
          <ConfirmationDialog
            isOpen={isConfirmDialog}
            messageLabelId={deleteFileMessage}
            viewMessage={deleteFileMessage}
            onDecision={() => handleDelete()}
            onCancel={() => setConfirmDialog(false)}
          />
          <ConfirmationDialog
            isOpen={isBluePrintConenctConfirmDialog}
            messageLabelId={bluePrintConenctMessage}
            viewMessage={bluePrintConenctMessage}
            onDecision={() => blueprintConnection()}
            onCancel={() => setBluePrintConenctConfirmDialog(false)}
          />
        </div>
        <Toast />
      </Container>
      {isLoading && <LoadingIcon />}
    </>
  );
}
