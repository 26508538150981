import dayjs from 'dayjs';
import { useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { error, GetMessageWithIntl } from '~/shared/components';
import {
  DataFilterbox,
  DataFilterboxItem,
  DateSuggest,
  PartialLoading,
} from '~/shared/components/ui';
import { CaptionButton } from '~/shared/components/ui/Button';
import {
  getWorkerExceptionMessage,
  includeInputValidateError,
  ViewId,
} from '~/shared/utils';
import { Chart, Line, Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  ScaleOptions,
} from 'chart.js';
import {
  FullMethodName_ListAnalysisOrderPlacementCategorys,
  FullMethodName_ListAnalysisOrderPlacementDetails,
  FullMethodName_ListAnalysisOrderPlacements,
  FullMethodName_ListAnalysisOrderReceiptCategorys,
  FullMethodName_ListAnalysisOrderReceiptDetails,
  FullMethodName_ListAnalysisOrderReceipts,
} from '~/worker';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { useLoading } from '~/shared/contexts/LoadingProvider';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const chartColors = [
  '#1A237E',
  '#303F9F',
  '#3F51B5',
  '#7986CB',
  '#C5CAE9',
  '#E8EAF6',
];

/** 分析項目ごとのオプション */
const scaleOptionMap: { [attribute: string]: ScaleOptions<'linear'> } = {
  /** 発注(受注)単価 */
  unitPriceNum: {
    min: 0,
    ticks: { maxTicksLimit: 5 },
  },
  /** 発注(受注)数量 */
  quantityNum: {
    min: 0,
    ticks: { maxTicksLimit: 5 },
  },
  /** 発注(受注)金額 */
  amountNum: {
    min: 0,
    ticks: { maxTicksLimit: 5 },
  },
  /** 検収数量 */
  inspectionQuantityNum: {
    min: 0,
    ticks: { maxTicksLimit: 5 },
  },
  /** 検収金額 */
  inspectionAmountNum: {
    min: 0,
    ticks: { maxTicksLimit: 5 },
  },
  /** 不良品率 */
  defectiveRate: {
    min: 0,
    max: 100,
    ticks: {
      maxTicksLimit: 5,
      callback: (v) => `${v} %`,
    },
  },
};

type DataRequest =
  | mtechnavi.api.analysis.IListAnalysisOrderPlacementsRequest
  | mtechnavi.api.analysis.IListAnalysisOrderReceiptsRequest;

type OrderDetailItemKeys = keyof mtechnavi.api.analysis.IOrderDetailItem;

export interface CompanyConfirmationOrderAnalysisProps {
  viewId: ViewId;
  companyId: string;
  mode: 'placement' | 'receipt';
  analysisSettingItems: mtechnavi.api.analysis.IAnalysisSettingItem[];
}
export const CompanyConfirmationOrderAnalysis = ({
  viewId,
  companyId,
  mode,
  analysisSettingItems,
}: CompanyConfirmationOrderAnalysisProps) => {
  const intl = useIntl();
  const { showLoading, hideLoading } = useLoading();

  // 初期値セット用
  const now = dayjs();
  const initialDateFrom = now.subtract(11, 'month').startOf('month');
  const initialDateTo = now.endOf('month');

  // 画面選択値
  const [selectedBaseDateType, setSelectedBaseDateType] = useState<
    string | null
  >(null);
  const [selectedDateFrom, setSelectedDateFrom] = useState<Date | null>(
    initialDateFrom.toDate()
  );
  const [selectedDateTo, setSelectedDateTo] = useState<Date | null>(
    initialDateTo.toDate()
  );

  // 再取得保持用
  const [baseDateType, setBaseDateType] = useState<string | null>(null);
  const [referenceDateFrom, setReferenceDateFrom] = useState<Date | null>(null);
  const [referenceDateTo, setReferenceDateTo] = useState<Date | null>(null);

  const [analysisSettingItemId, setAnalysisSettingItemId] = useState<
    string | null
  >(null);
  const [analysisTarget1, setAnalysisTarget1] =
    useState<DataFilterboxItem | null>(null);
  const [analysisTarget2, setAnalysisTarget2] =
    useState<DataFilterboxItem | null>(null);

  // 分析軸
  const [analysis1A, setAnalysis1A] = useState<DataFilterboxItem | null>(null);
  const [analysis1B, setAnalysis1B] = useState<DataFilterboxItem | null>(null);
  const [analysis2A, setAnalysis2A] = useState<DataFilterboxItem | null>(null);
  const [analysis2B, setAnalysis2B] = useState<DataFilterboxItem | null>(null);

  // グラフ用データ
  const [orderItems, setOrderItems] = useState<
    mtechnavi.api.analysis.IOrderItem[]
  >([]);
  const [categoryItems, setCategoryItems] = useState<
    mtechnavi.api.analysis.IOrderCategoryItem[]
  >([]);
  const [detailItems1, setDetailItems1] = useState<
    mtechnavi.api.analysis.IOrderDetailItem[]
  >([]);
  const [detailItems2, setDetailItems2] = useState<
    mtechnavi.api.analysis.IOrderDetailItem[]
  >([]);

  // 部分ローディング表示
  const [isAnalysis1Loading, setAnalysis1Loading] = useState(false);
  const [isAnalysis2Loading, setAnalysis2Loading] = useState(false);

  const noopRef = useRef(null);
  const [workingBlurBasisForm, setWorkingBlurBasisForm] = useState<Date>();

  const isPlacement = mode === 'placement';

  /** 基準日選択肢 */
  const baseDateTypeItems = useMemo(() => {
    const items: DataFilterboxItem[] = [
      {
        value: 'order_date',
        displayName: GetMessageWithIntl(intl, {
          viewId,
          id: !isPlacement
            ? 'orderAnalysis.orderReceiptDate'
            : 'orderAnalysis.orderPlacementDate',
        }),
      },
      {
        value: 'delivery_date',
        displayName: GetMessageWithIntl(intl, {
          viewId,
          id: 'orderAnalysis.deliveryDate',
        }),
      },
      {
        value: 'delivery_plan_date',
        displayName: GetMessageWithIntl(intl, {
          viewId,
          id: 'orderAnalysis.deliveryPlanDate',
        }),
      },
    ];
    if (isPlacement) {
      items.push({
        value: 'inspection_date',
        displayName: GetMessageWithIntl(intl, {
          viewId,
          id: 'orderAnalysis.inspectionDate',
        }),
      });
    }
    return items;
  }, [intl, viewId, isPlacement]);

  // 属性分析選択肢
  const analysisSettingItemList = analysisSettingItems
    .filter(
      (item) =>
        item.dataType ===
        (isPlacement ? 'AnalysisOrderPlacement' : 'AnalysisOrderReceipt')
    )
    .map(
      (item): DataFilterboxItem => ({
        value: item.id ?? '',
        displayName: item.displayName ?? '',
      })
    );

  // グラフ対象選択
  const analysisTargetItems: DataFilterboxItem[] = categoryItems.map(
    (item) => ({
      displayName: item.analysisItem ?? '',
      value: item.analysisItem ?? '',
    })
  );

  // 分析用項目
  const analysisAttributes: DataFilterboxItem[] = [
    {
      value: 'unitPriceNum',
      displayName: GetMessageWithIntl(intl, {
        viewId,
        id: isPlacement
          ? 'orderAnalysis.placementUnitPriceNum'
          : 'orderAnalysis.receiptUnitPriceNum',
      }),
    },
    {
      value: 'quantityNum',
      displayName: GetMessageWithIntl(intl, {
        viewId,
        id: isPlacement
          ? 'orderAnalysis.placementQuantityNum'
          : 'orderAnalysis.receiptQuantityNum',
      }),
    },
    {
      value: 'amountNum',
      displayName: GetMessageWithIntl(intl, {
        viewId,
        id: isPlacement
          ? 'orderAnalysis.placementAmountNum'
          : 'orderAnalysis.receiptAmountNum',
      }),
    },
    {
      value: 'inspectionQuantityNum',
      displayName: GetMessageWithIntl(intl, {
        viewId,
        id: 'orderAnalysis.inspectionQuantityNum',
      }),
    },
    {
      value: 'inspectionAmountNum',
      displayName: GetMessageWithIntl(intl, {
        viewId,
        id: 'orderAnalysis.inspectionAmountNum',
      }),
    },
    {
      value: 'defectiveRate',
      displayName: GetMessageWithIntl(intl, {
        viewId,
        id: 'orderAnalysis.defectiveRate',
      }),
    },
  ];

  // 受注・発注によるエンドポイントの切り替え
  const [FullMethodName_Data, FullMethodName_Category, FullMethodName_Detail] =
    useMemo(
      () =>
        mode === 'placement'
          ? [
              FullMethodName_ListAnalysisOrderPlacements,
              FullMethodName_ListAnalysisOrderPlacementCategorys,
              FullMethodName_ListAnalysisOrderPlacementDetails,
            ]
          : [
              FullMethodName_ListAnalysisOrderReceipts,
              FullMethodName_ListAnalysisOrderReceiptCategorys,
              FullMethodName_ListAnalysisOrderReceiptDetails,
            ],
      [mode]
    );

  // 発注金額推移グラフデータ
  const dayLabels = orderItems.map((d) =>
    d.baseDate ? dayjs(d.baseDate).format('YYYY/MM') : ''
  );
  const orderAmountData = orderItems.map((d) => Number(d.amountNum));

  // 属性内訳グラフデータ
  const categoryChartData = categoryItems.map((d) => d.amountNum);
  const categoryChartLabels = categoryItems.map((d) => d.analysisItem ?? '');
  const categoryName =
    analysisSettingItemList.find((d) => d.value === analysisSettingItemId)
      ?.displayName ?? '';

  // グラフ１データ
  const analysis1AChartData = detailItems1.map((d) =>
    analysis1A?.value ? d[analysis1A.value as OrderDetailItemKeys] : null
  );
  const analysis1BChartData = detailItems1.map((d) =>
    analysis1B?.value ? d[analysis1B.value as OrderDetailItemKeys] : null
  );
  const analysis1AChartScale = analysis1A?.value
    ? scaleOptionMap[analysis1A.value]
    : {};
  const analysis1BChartScale = analysis1B?.value
    ? scaleOptionMap[analysis1B.value]
    : {};

  // グラフ２データ
  const analysis2AChartData = detailItems2.map((d) =>
    analysis2A?.value ? d[analysis2A.value as OrderDetailItemKeys] : null
  );
  const analysis2BChartData = detailItems2.map((d) =>
    analysis2B?.value ? d[analysis2B.value as OrderDetailItemKeys] : null
  );
  const analysis2AChartScale = analysis2A?.value
    ? scaleOptionMap[analysis2A.value]
    : {};
  const analysis2BChartScale = analysis2B?.value
    ? scaleOptionMap[analysis2B.value]
    : {};

  /** 基準日と期間の入力チェック */
  const isInputError = (): boolean => {
    setWorkingBlurBasisForm(new Date());
    const inputValidationCheckList = [
      { value: selectedBaseDateType || '', ref: noopRef },
      { value: selectedDateFrom?.toISOString() ?? '', ref: noopRef },
      { value: selectedDateTo?.toISOString() ?? '', ref: noopRef },
    ];
    return includeInputValidateError(document, intl, inputValidationCheckList);
  };

  /** データ更新処理 */
  const handleRefresh = async () => {
    if (isInputError()) {
      return;
    }
    if (!selectedDateFrom || !selectedDateTo) {
      return;
    }
    if (selectedDateFrom > selectedDateTo) {
      error([GetMessageWithIntl(intl, { id: 'E0000168' })]);
      return;
    }
    if (dayjs(selectedDateTo).diff(dayjs(selectedDateFrom), 'month') > 24) {
      error([GetMessageWithIntl(intl, { id: 'E0000173', value: { $1: '2' } })]);
      return;
    }

    setBaseDateType(selectedBaseDateType);
    setReferenceDateFrom(selectedDateFrom);
    setReferenceDateTo(selectedDateTo);

    showLoading();
    try {
      const result = await fetchOrderItems(
        selectedBaseDateType,
        selectedDateFrom,
        selectedDateTo
      );
      setOrderItems(result);

      const refreshItems: Promise<unknown>[] = [];
      if (analysisSettingItemId) {
        refreshItems.push(
          refreshAnalysisSettingItem(
            analysisSettingItemId,
            selectedBaseDateType,
            selectedDateFrom,
            selectedDateTo
          )
        );
      }
      if (analysisTarget1) {
        refreshItems.push(
          refreshAnalysisTarget1(
            analysisTarget1?.value ?? '',
            selectedBaseDateType,
            selectedDateFrom,
            selectedDateTo
          )
        );
      }
      if (analysisTarget2) {
        refreshItems.push(
          refreshAnalysisTarget2(
            analysisTarget2?.value ?? '',
            selectedBaseDateType,
            selectedDateFrom,
            selectedDateTo
          )
        );
      }
      if (refreshItems.length <= 0) {
        return;
      }
      await Promise.all(refreshItems);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      hideLoading();
    }
  };

  /** 発注金額推移の取得 */
  const fetchOrderItems = async (
    baseDateType: string | null,
    from: Date | null,
    to: Date | null
  ) => {
    const startDate = dayjs(from);
    const endDate = dayjs(to);
    const orderItems =
      await window.App.services.ui.worker.filter<mtechnavi.api.analysis.IOrderItem>(
        {
          action: 'reload',
          fullMethodName: FullMethodName_Data,
          requestBody: {
            dataFilter: {
              companyId,
              baseDateType,
              startDate: startDate ? startDate.format('YYYY/MM/DD') : undefined,
              endDate: endDate ? endDate.format('YYYY/MM/DD') : undefined,
            },
          } as DataRequest,
          filter: {},
          sort: [{ baseDate: 'asc' }],
        }
      );
    return orderItems.items ?? [];
  };

  /** 属性分析の内訳カテゴリの取得 */
  const fetchCategoryItems = async (
    analysisSettingItemId: string | null,
    baseDateType: string | null,
    from: Date | null,
    to: Date | null
  ) => {
    const startDate = dayjs(from);
    const endDate = dayjs(to);
    const categoryItems =
      await window.App.services.ui.worker.filter<mtechnavi.api.analysis.IOrderCategoryItem>(
        {
          action: 'reload',
          fullMethodName: FullMethodName_Category,
          requestBody: {
            dataFilter: {
              companyId,
              baseDateType,
              startDate: startDate ? startDate.format('YYYY/MM/DD') : undefined,
              endDate: endDate ? endDate.format('YYYY/MM/DD') : undefined,
              analysisSettingItemId,
            },
          } as DataRequest,
          filter: {},
          sort: [{ amountNum: 'desc' }],
        }
      );
    return (categoryItems.items ?? [])
      .filter(
        // analysisItem が空のものは除外する
        (item) => !!item.analysisItem
      )
      .sort((a, b) => Number(b.amountNum) - Number(a.amountNum));
  };

  /** 分析グラフ１・２用のデータ取得 */
  const fetchDetailItems = async (
    baseDateType: string | null,
    analysisItem: string,
    from: Date | null,
    to: Date | null
  ) => {
    const startDate = dayjs(from);
    const endDate = dayjs(to);
    const detailItems =
      await window.App.services.ui.worker.filter<mtechnavi.api.analysis.IOrderDetailItem>(
        {
          action: 'reload',
          fullMethodName: FullMethodName_Detail,
          requestBody: {
            dataFilter: {
              companyId,
              baseDateType,
              startDate: startDate ? startDate.format('YYYY/MM/DD') : undefined,
              endDate: endDate ? endDate.format('YYYY/MM/DD') : undefined,
              analysisSettingItemId,
              analysisItem,
            },
          } as DataRequest,
          filter: {},
          sort: [{ baseDate: 'asc' }],
        }
      );
    return detailItems.items ?? [];
  };

  /** 属性分析選択変更 */
  const handleChangeAnalysisSettingItem = async (v: DataFilterboxItem[]) => {
    const analysisSettingItemId = v.at(0)?.value ?? null;
    setAnalysisSettingItemId(analysisSettingItemId);
    setAnalysisTarget1(null);
    setAnalysisTarget2(null);
    setCategoryItems([]);
    setDetailItems1([]);
    setDetailItems2([]);

    if (
      !analysisSettingItemId ||
      !baseDateType ||
      !referenceDateFrom ||
      !referenceDateTo
    ) {
      return;
    }
    showLoading();
    try {
      const result = await fetchCategoryItems(
        analysisSettingItemId,
        baseDateType,
        referenceDateFrom,
        referenceDateTo
      );
      setCategoryItems(result);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      hideLoading();
    }
  };

  /** 属性分析選択のグラフ反映 */
  const refreshAnalysisSettingItem = async (
    targetId: string | null,
    baseDateType: string | null,
    from: Date | null,
    to: Date | null
  ) => {
    setCategoryItems([]);
    if (!targetId || !baseDateType || !from || !to) {
      return;
    }
    showLoading();
    try {
      const result = await fetchCategoryItems(targetId, baseDateType, from, to);
      setCategoryItems(result);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      hideLoading();
    }
  };

  /** グラフ１の対象選択 */
  const handleChangeAnalysisTarget1 = async (
    selection: DataFilterboxItem[]
  ) => {
    const target = selection.at(0) ?? null;
    setAnalysisTarget1(target);
    if (!analysis1A) {
      setAnalysis1A(
        analysisAttributes.find((item) => item.value === 'amountNum') ?? null
      );
    }
    await refreshAnalysisTarget1(
      target?.value ?? '',
      baseDateType,
      referenceDateFrom,
      referenceDateTo
    );
  };

  /** グラフ１のデータ再取得 */
  const refreshAnalysisTarget1 = async (
    targetId: string | null,
    baseDateType: string | null,
    from: Date | null,
    to: Date | null
  ) => {
    setDetailItems1([]);
    if (!targetId || !baseDateType || !from || !to) {
      return;
    }
    setAnalysis1Loading(true);
    try {
      const result = await fetchDetailItems(baseDateType, targetId, from, to);
      setDetailItems1(result);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setAnalysis1Loading(false);
    }
  };

  /** グラフ２の対象選択 */
  const handleChangeAnalysisTarget2 = async (
    selection: DataFilterboxItem[]
  ) => {
    const target = selection.at(0) ?? null;
    setAnalysisTarget2(target);
    if (!analysis2A) {
      setAnalysis2A(
        analysisAttributes.find((item) => item.value === 'amountNum') ?? null
      );
    }
    await refreshAnalysisTarget2(
      target?.value ?? '',
      baseDateType,
      referenceDateFrom,
      referenceDateTo
    );
  };

  /** グラフ２のデータ再取得 */
  const refreshAnalysisTarget2 = async (
    targetId: string | null,
    baseDateType: string | null,
    from: Date | null,
    to: Date | null
  ) => {
    setDetailItems2([]);
    if (!targetId || !baseDateType || !from || !to) {
      return;
    }
    setAnalysis2Loading(true);
    try {
      const result = await fetchDetailItems(baseDateType, targetId, from, to);
      setDetailItems2(result);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setAnalysis2Loading(false);
    }
  };

  return (
    <>
      <div className="input-line mixed-input-line">
        <div className="item-group-100">
          <div className="w-20">
            <DataFilterbox
              name="referenceType"
              labelId={`${viewId}.orderAnalysis.referenceType`}
              columns={['referenceType']}
              multiple={false}
              data={baseDateTypeItems}
              validateOption={{ required: true }}
              onChangeState={(v) =>
                setSelectedBaseDateType(v.at(0)?.value ?? null)
              }
              workingBlur={workingBlurBasisForm}
            />
          </div>
          <div className="w-40">
            <div className="date-range">
              <DateSuggest
                labelId={`${viewId}.orderAnalysis.referenceDateFrom`}
                name="referenceDateFrom"
                columns={['referenceDateFrom']}
                value={selectedDateFrom}
                validateOption={{ required: true }}
                onChangeState={setSelectedDateFrom}
              />
              <span className="aid">〜</span>
              <DateSuggest
                labelId={`${viewId}.orderAnalysis.referenceDateTo`}
                name="referenceDateTo"
                columns={['referenceDateTo']}
                value={selectedDateTo}
                validateOption={{ required: true }}
                onChangeState={setSelectedDateTo}
              />
            </div>
          </div>
          <div className="w-20">
            {/* データ更新ボタン */}
            <CaptionButton
              name="refresh"
              buttonType="basic"
              caption={GetMessageWithIntl(intl, {
                viewId,
                id: 'orderAnalysis.updateCharts',
              })}
              onClick={handleRefresh}
            />
          </div>
        </div>
      </div>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-100">
            {/* 発注(受注)金額推移 */}
            <h3>
              {GetMessageWithIntl(intl, {
                viewId,
                id: isPlacement
                  ? 'orderAnalysis.placementAmountChanges'
                  : 'orderAnalysis.receiptAmountChanges',
              })}
            </h3>
            <Line
              className="chart-area"
              data={{
                labels: dayLabels,
                datasets: [
                  {
                    data: orderAmountData,
                    borderColor: '#282ba2',
                    backgroundColor: '#282ba2',
                    yAxisID: 'amount',
                  },
                ],
              }}
              options={{
                responsive: true,
                aspectRatio: 4,
                plugins: {
                  legend: { display: false },
                  title: { display: false },
                },
                scales: {
                  amount: { min: 0 },
                },
              }}
            />
          </div>
        </div>
      </div>
      {/* 属性分析 */}
      <h3>
        {GetMessageWithIntl(intl, {
          viewId,
          id: 'orderAnalysis.attributeAnalysis',
        })}
      </h3>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-20">
            <div className="input-with-icon">
              {/* 分析項目 */}
              <DataFilterbox
                name="analysisSettingItemId"
                labelId={`${viewId}.orderAnalysis.analysisSettingItemId`}
                columns={['analysisSettingItemId']}
                multiple={false}
                data={analysisSettingItemList}
                onChangeState={handleChangeAnalysisSettingItem}
              />
            </div>
          </div>
          <div className="w-40">
            <h4>
              {GetMessageWithIntl(intl, { viewId, id: 'orderAnalysis.chart1' })}
            </h4>
            <div className="input-with-icon">
              {/* 対象 */}
              <DataFilterbox
                name="analysisItemId1"
                labelId={`${viewId}.orderAnalysis.analysisItemId1`}
                columns={['analysisItemId1']}
                multiple={false}
                value={analysisTargetItems.filter(
                  (v) => v.value === analysisTarget1?.value
                )}
                data={analysisTargetItems}
                onChangeState={handleChangeAnalysisTarget1}
              />
            </div>
            <div className="input-line">
              <div className="item-group-100">
                <div className="w-50">
                  {/* 分析１縦軸 */}
                  <DataFilterbox
                    name="axesY"
                    labelId={`${viewId}.orderAnalysis.axesY`}
                    columns={['axesY']}
                    multiple={false}
                    value={analysisAttributes.filter(
                      (v) => v.value === analysis1A?.value
                    )}
                    data={analysisAttributes}
                    onChangeState={(v) => setAnalysis1A(v.at(0) ?? null)}
                  />
                </div>
                <div className="w-50">
                  {/* 分析１横軸 */}
                  <DataFilterbox
                    name="axesX"
                    labelId={`${viewId}.orderAnalysis.axesX`}
                    columns={['axesX']}
                    multiple={false}
                    value={analysisAttributes.filter(
                      (v) => v.value === analysis1B?.value
                    )}
                    data={analysisAttributes}
                    onChangeState={(v) => setAnalysis1B(v.at(0) ?? null)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="w-40">
            <h4>
              {GetMessageWithIntl(intl, { viewId, id: 'orderAnalysis.chart2' })}
            </h4>
            <div className="input-with-icon">
              {/* 対象 */}
              <DataFilterbox
                name="analysisItemId2"
                labelId={`${viewId}.orderAnalysis.analysisItemId2`}
                columns={['analysisItemId2']}
                multiple={false}
                value={analysisTargetItems.filter(
                  (v) => v.value === analysisTarget2?.value
                )}
                data={analysisTargetItems}
                onChangeState={handleChangeAnalysisTarget2}
              />
            </div>
            <div className="input-line">
              <div className="item-group-100">
                <div className="w-50">
                  {/* 分析２縦軸 */}
                  <DataFilterbox
                    name="axesY"
                    labelId={`${viewId}.orderAnalysis.axesY`}
                    columns={['axesY']}
                    multiple={false}
                    value={analysisAttributes.filter(
                      (v) => v.value === analysis2A?.value
                    )}
                    data={analysisAttributes}
                    onChangeState={(v) => setAnalysis2A(v.at(0) ?? null)}
                  />
                </div>
                <div className="w-50">
                  {/* 分析２横軸 */}
                  <DataFilterbox
                    name="axesX"
                    labelId={`${viewId}.orderAnalysis.axesX`}
                    columns={['axesX']}
                    multiple={false}
                    value={analysisAttributes.filter(
                      (v) => v.value === analysis2B?.value
                    )}
                    data={analysisAttributes}
                    onChangeState={(v) => setAnalysis2B(v.at(0) ?? null)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-20">
            <h4>
              {GetMessageWithIntl(intl, {
                viewId,
                id: isPlacement
                  ? 'orderAnalysis.placementAmountChart'
                  : 'orderAnalysis.receiptAmountChart',
                value: { $1: categoryName },
              })}
            </h4>
            <Pie
              className="chart-area"
              data={{
                labels: categoryChartLabels,
                datasets: [
                  {
                    data: categoryChartData,
                    backgroundColor: chartColors,
                  },
                ],
              }}
              options={{
                plugins: {
                  legend: { display: false },
                },
              }}
            />
          </div>
          <div className="w-40">
            <h4>{`${categoryName}:${analysisTarget1?.displayName ?? ''}`}</h4>
            <Line
              className="chart-area"
              data={{
                labels: dayLabels,
                datasets: [
                  {
                    data: analysis1AChartData,
                    label: analysis1A?.displayName ?? '',
                    borderColor: '#282BA2',
                    backgroundColor: '#282BA2',
                    yAxisID: 'dataA',
                  },
                  {
                    data: analysis1BChartData,
                    label: analysis1B?.displayName ?? '',
                    borderColor: '#696CCB',
                    backgroundColor: '#696CCB',
                    yAxisID: 'dataB',
                  },
                ],
              }}
              options={{
                responsive: true,
                plugins: {
                  legend: { position: 'bottom' },
                  title: { display: false },
                },
                scales: {
                  dataA: {
                    position: 'left',
                    ...analysis1AChartScale,
                    ticks: { color: '#282BA2', ...analysis1AChartScale.ticks },
                  },
                  dataB: {
                    position: 'right',
                    ...analysis1BChartScale,
                    ticks: { color: '#696CCB', ...analysis1BChartScale.ticks },
                  },
                },
              }}
            />
            <PartialLoading isShow={isAnalysis1Loading} />
          </div>
          <div className="w-40">
            <h4>{`${categoryName}:${analysisTarget2?.displayName ?? ''}`}</h4>
            <Chart
              className="chart-area"
              type="bar"
              data={{
                labels: dayLabels,
                datasets: [
                  {
                    type: 'line',
                    data: analysis2AChartData,
                    label: analysis2A?.displayName ?? '',
                    borderColor: '#282BA2',
                    backgroundColor: '#282BA2',
                    yAxisID: 'dataA',
                  },
                  {
                    type: 'line',
                    data: analysis2BChartData,
                    label: analysis2B?.displayName ?? '',
                    borderColor: '#696CCB',
                    backgroundColor: '#696CCB',
                    yAxisID: 'dataB',
                  },
                ],
              }}
              options={{
                responsive: true,
                plugins: {
                  legend: { position: 'bottom' },
                  title: { display: false },
                },
                scales: {
                  dataA: {
                    position: 'left',
                    ...analysis2AChartScale,
                    ticks: { color: '#282BA2', ...analysis2AChartScale.ticks },
                  },
                  dataB: {
                    position: 'right',
                    ...analysis2BChartScale,
                    ticks: { color: '#696CCB', ...analysis2BChartScale.ticks },
                  },
                },
              }}
            />
            <PartialLoading isShow={isAnalysis2Loading} />
          </div>
        </div>
      </div>
    </>
  );
};
