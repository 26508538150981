import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface ExecUpdateWorkTaskRelationRequest {
  actionName: 'createWorkTaskRelation';
  request: mtechnavi.api.worktask.IWorkTaskRelation[];
}

// 作業案件紐付け更新
export const execUpdateWorkTaskRelation = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.worktask.IWorkTaskRelation[]
) => {
  const createFullMethodName =
    'mtechnavi.api.worktask.WorkTaskService/CreateWorkTaskRelation';

  type createRequestType =
    mtechnavi.api.worktask.ICreateWorkTaskRelationRequest;

  const result = req.map((item) => {
    if (!item.workTaskRelationId) {
      return worker.invokeListRequest(createFullMethodName, {
        workTaskRelation: item as createRequestType,
      });
    }
  });

  return await Promise.all(result);
};
