import { AppSaveWorker } from '..';
export interface DeleteBaseFormWithRelatedItemsRequest {
  actionName: 'deleteBaseFormWithRelatedItems';
  request: string[];
}

// FormDefinition の単純な削除
export const deleteBaseFormWithRelatedItems = async (
  worker: AppSaveWorker,
  req: string[]
) => {
  const methodName =
    'mtechnavi.api.form.BaseFormService/DeleteBaseFormWithRelatedItems';
  const result = req.map((item) => {
    return worker.invokeListRequest(methodName, {
      baseFormId: item,
    });
  });

  await Promise.all(result);
};
