import { useIntl } from 'react-intl';
import {
  GetMessage,
  GetMessageWithIntl,
  MessageProps,
  ModalDialogComponent,
  ModalDialogComponentProps,
} from '~/shared/components';
import { CaptionButton } from '~/shared/components/ui/Button/CaptionButton';
import { useEffect, useMemo, useState } from 'react';
import { SimpleIconListView } from '~/shared/components/ui/SimpleListView';
import { convertLongToDate, formatDate } from '~/shared/utils';
import { IconButton } from '~/shared/components/ui/Button';
import { LicenseAddDialog } from './LicenseAddDialog';
import './LicenseEditDialog.css';
import { mtechnavi } from '~/shared/libs/clientsdk';
import Long from 'long';
import { useConfirmation } from '~/shared/hooks';

export interface LicenseEditData {
  id?: string | null;
  license: mtechnavi.api.license.ILicense;
  isDeleted?: boolean;
}
export interface LicenseEditDialogMessageOption {
  headerLabelId: MessageProps;
}

export interface LicenseEditDialogInputOption {
  licenseData: mtechnavi.api.license.ILicense[];
}

// TODO API接続時にIF見直し
export interface LicenseEditDialogProps {
  isOpen: boolean;
  messageOption: LicenseEditDialogMessageOption;
  inputOption?: LicenseEditDialogInputOption;
  onDecision: (result: LicenseEditData[]) => void;
  onCancel: () => void;
}

interface ListLicenseViewType {
  id?: string;
  isValid?: string;
  licenseName?: string;
  startDt?: string;
  endDt?: string;
}

const DIALOG_TITLE = 'licenseEditDialog';

export const LicenseEditDialog = (props: LicenseEditDialogProps) => {
  const intl = useIntl();
  const { isOpen, inputOption, messageOption } = props;
  const [isOpenLicenseAddDialog, setOpenLicenseAddDialog] = useState(false);
  const [licenseEditData, setLicenseEditData] = useState<LicenseEditData[]>([]);
  const viewData: ListLicenseViewType[] = useMemo(
    () =>
      licenseEditData
        .filter((item) => !item.isDeleted)
        .map((item) => {
          const { id, license } = item;
          let validString = '';
          const today = new Date();
          const startDt = convertLongToDate(
            Long.fromValue(license.startAt ?? 0)
          );
          const endDt = license.withoutEnd
            ? null
            : convertLongToDate(Long.fromValue(license.endAt ?? 0));

          if (!startDt) {
            return {};
          }
          if (today > startDt && (!endDt || today < endDt)) {
            validString = '◯';
          }
          if (today < startDt && (!endDt || today < endDt)) {
            validString = '-';
          }
          if (!license.withoutEnd && endDt && today > endDt) {
            validString = '×';
          }
          return {
            id: id ?? '',
            isValid: validString,
            licenseName: license?.displayName ?? '',
            startDt: formatDate(startDt, 'YYYY/MM/DD'),
            endDt: license.withoutEnd
              ? GetMessageWithIntl(intl, {
                  prefixId: DIALOG_TITLE,
                  id: 'withoutEndLabel',
                })
              : formatDate(endDt, 'YYYY/MM/DD'),
          };
        }),
    [intl, licenseEditData]
  );

  // 確認ダイアログ
  const { confirmation, confirmationElement } = useConfirmation();
  // メッセージ定義
  const deleteMessage = {
    id: 'C0000005',
    value: { $1: GetMessageWithIntl(intl, { id: 'delete' }) },
  };
  // メッセージ定義
  const saveMessage = {
    id: 'C0000001',
    value: { $1: GetMessageWithIntl(intl, { id: 'save' }) },
  };

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    const data =
      inputOption?.licenseData.map(
        (item): LicenseEditData => ({
          id: item.licenseId,
          license: item,
          isDeleted: false,
        })
      ) ?? [];
    setLicenseEditData(data);
    // isOpen変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleAdd = (v: LicenseEditData) => {
    setLicenseEditData([...licenseEditData, v]);
    setOpenLicenseAddDialog(false);
  };

  const handleDelete = async (listItem: ListLicenseViewType) => {
    if (!(await confirmation(deleteMessage))) {
      return;
    }
    const targetData = licenseEditData.find((item) => item.id === listItem.id);
    if (!targetData) {
      return;
    }
    const isPersistData = !!targetData.license.licenseId;
    setLicenseEditData([
      ...licenseEditData.filter((item) => item.id !== listItem.id),
      ...(isPersistData ? [{ ...targetData, isDeleted: true }] : []),
    ]);
  };

  const handleDecision = async () => {
    if (!(await confirmation(saveMessage))) {
      return;
    }
    props.onDecision(licenseEditData);
  };

  const view = (
    <div className="license-edit-dialog dialog-with-description">
      <div className="detail-area">
        <div className="comment-area">
          <div className="input-line">
            <div className="item-group-100">
              <div className="w-75">
                <IconButton
                  name="add"
                  buttonType="basic"
                  iconType="noteadd"
                  onClick={() => {
                    setOpenLicenseAddDialog(true);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="input-line">
            <div className="item-group-100">
              <div className="w-100">
                <SimpleIconListView
                  data={viewData}
                  actionOptions={{}}
                  viewOptions={{
                    previewRowCount: 11,
                    keyColumn: 'id',
                    columns: [
                      {
                        header: { id: 'isValid', prefixId: DIALOG_TITLE },
                        propertyName: 'isValid',
                        width: '10%',
                      },
                      {
                        header: { id: 'licenseName', prefixId: DIALOG_TITLE },
                        propertyName: 'licenseName',
                        width: '45%',
                      },
                      {
                        header: { id: 'startDt', prefixId: DIALOG_TITLE },
                        propertyName: 'startDt',
                      },
                      {
                        header: { id: 'endDt', prefixId: DIALOG_TITLE },
                        propertyName: 'endDt',
                      },
                    ],
                  }}
                  iconMenuOptions={{
                    iconMenu: [
                      {
                        name: 'delete',
                        displayName: '削除',
                        func: handleDelete,
                      },
                    ],
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="button-area">
          <CaptionButton
            name="cancelBtn"
            buttonType="cancel"
            className="button"
            caption={GetMessage({ id: 'cancel' })}
            onClick={() => props.onCancel()}
          />
          <CaptionButton
            name="sendBtn"
            buttonType="basic"
            className="button"
            caption={GetMessage({
              id: 'save',
            })}
            onClick={handleDecision}
          />
        </div>
      </div>

      {confirmationElement}

      <>
        <LicenseAddDialog
          key={isOpenLicenseAddDialog ? '1' : '0'}
          messageOption={{
            headerLabelId: { id: 'License_add', prefixId: 'DIALOG_TITLE' },
          }}
          isOpen={isOpenLicenseAddDialog}
          onDecision={handleAdd}
          onCancel={() => setOpenLicenseAddDialog(false)}
        />
      </>
    </div>
  );

  const openModalProps: ModalDialogComponentProps = {
    cancel: () => {
      props.onCancel();
    },
    send: () => {},
    modalIsOpen: isOpen,
    headerLabelId: messageOption.headerLabelId,
    messageLabelId: {},
    elements: view,
  };

  return (
    <div className="LicenseEditDialog">
      <ModalDialogComponent {...openModalProps} />
    </div>
  );
};
